const NoPermission = (props) => {
 return(
    <div className="row">
        <div className="col" style={{display:'flex', alignItems: 'center', justifyContent: 'center', width: '100vh', height: '100vh'}}>
            <span style={{fontSize: '30px', fontWeight: 'bold', textAlign: 'center', backgroundColor: '#ea2746', padding: '18px',borderRadius: '22% 0%', color: 'aliceblue'}}>You do not have permission<br />to see this section</span>
        </div>
     </div>
 );
}

export default NoPermission;