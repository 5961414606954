import React, { Component } from "react";
import Loader from 'react-loader-spinner';
import DTable from './dtable.component';
import DatePicker from "react-datepicker";
import $ from 'jquery';

import AuthService from '../services/auth/auth.service';
import UtilityService from "../services/utility.service";
import IssuesService from "../services/erp/issues.service";

import UserContext from "../contexts/UserContext";
import NoPermission from "./extras/nopermission.component";
export default class Issues extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            columnsheadings: [],
            options: {
                filterType: 'dropdown',
            },
            show: false,
            error: {
                status: false,
                message: ''
            },
            loadingvalues: true,
            user: [],
            search_btn_text: 'Search',
            loader: false,
            status: '0',
            startdate: '',
            enddate: '',
            minDate: new Date("02-01-2019"),
            viewComplaintSummary : ''
        };
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    componentDidMount() {
        AuthService.checklogin().then(user => {

            this.setState({
                user: user
            });
            
           AuthService.getUserPermisions(user)
            .then(response => {
                var userpermissions = response.data;
                console.log({userpermissions});
                    if(userpermissions.includes('issue') || userpermissions.includes('all')) {
                    console.log('You are allowed!');
                    this.setState({
                        allowed: true
                    });
                    this.loadResults(true);
                } else {
                    console.log('You are not allowed!');
                    this.setState({
                        allowed: false
                    });
                }
            })

            
        }).catch(error => {
            window.location.href = '/login';
        });
    }

    loadResults = (all) => {
        this.setState({
            data: [],
            show: false,
            error: {
                status: false,
                message: ''
            },
            loadingvalues: true,
        });
        setTimeout(()=>{
            var startday = '';
            if (this.state.startdate != '' && this.state.startdate != null) {
                startday = UtilityService.format_date(this.state.startdate);
            }

            var endday = '';
            if (this.state.enddate != '' && this.state.enddate != null) {
                endday = UtilityService.format_date(this.state.enddate);
            }

            IssuesService.getIssues(startday, endday, this.state.status, this.state.user.user_email)
            .then(response => {
                // console.log({response});
                if(response.data) {

                    this.setState({
                        data: response.data,
                        columnsheadings: [
                            { title: "Date Created" },
                            { title: "Customer Name" },
                            { title: "Customer Contacts" },
                            { title: "Order No." },
                            { title: "Product Name" },
                            { title: "Booking Details" },
                            { title: "Complaint Summary" },
                            { title: "Handling Agent" },
                            { title: "Complaint Channel" },
                            { title: "Complaint Analysis" },
                            { title: "Management Decision" },
                            { title: "Status" }
                        ],
                        show: true,
                        error: {
                            status: false,
                            message: ''
                        },
                        loadingvalues: false, 
                    });
                } else {
                    this.setState({
                        data: [],
                        columnsheadings: [],
                        show: false,
                        error: {
                            status: true,
                            message: 'No data found'
                        },
                        loadingvalues: false,
                    });
                }
            })
            .catch(error => {
                this.setState({
                    data: [],
                    columnsheadings: [],
                    show: false,
                    error: {
                        status: true,
                        message: 'No data found'
                    },
                    loadingvalues: false,
                });
            });
        }, 1000);
    }

    setStartDate = (date) => {
        this.setState({
            startdate: date,
            minDate: date,
        });

        if (this.state.enddate != '' && this.state.enddate < date) {
            this.setState({
                enddate:date
            });
        }
        // console.log(this.state);
    }

    setEndDate = (date) => {

        this.setState({
            enddate: date
        });
        // console.log(this.state);
    }


    render() {
        var { data, columnsheadings, options, show, error, loadingvalues, search_btn_text } = this.state;

        if (this.state.allowed == false) {
          return <NoPermission />;
        }
        
        return (
            <>
                <div className="row">
                    <div className='col'>
                        <h2>Issues / Complaint Management:</h2>
                        <hr />
                    </div>
                </div>
                <div className="row">
                    <div className='col'>
                        {loadingvalues == true &&
                            <p style={{ color: "#df153b" }}><b><i>Loading Issues. Please Wait....</i></b></p>
                        }

                         <form action="" method="get" className="booking-reports-search-form" style={{ display: 'flex' }}>
                            <input type="hidden" name="page" value="deals-payment-report" />
                            <div>
                                <DatePicker
                                    dateFormat="dd-MM-yyyy"
                                    selected={this.state.startdate}
                                    onChange={date => this.setStartDate(date)}
                                    placeholderText="Start Date"
                                    className='form-control'
                                />
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                               
                                <DatePicker
                                    dateFormat="dd-MM-yyyy"
                                    selected={this.state.enddate}
                                    onChange={date => this.setEndDate(date)}
                                    placeholderText="End Date"
                                    className='form-control'
                                    minDate = {this.state.minDate}
                                />
                            </div>
                            
                            <div style={{ marginLeft: '10px' }}>
                                <select name="status" className="select-drops form-control" value={this.state.status} onChange={this.handleChange.bind(this)}>
                                    <option value="0">All Status</option>
                                    <option value="Pending">Pending</option>
                                    <option value="In-progress">In-progress</option>
                                    <option value="Action Pending">Action Pending</option>
                                    <option value="Completed">Completed</option>
                                </select>
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                <button type="button" className="btn btn-success btn-large booking-search-btn" onClick={this.loadResults}>{search_btn_text}</button>
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                <a href="/issues-manager" className="btn btn-large btn-warning booking-reset-btn">Reset</a>
                            </div>

                        </form>
                        <hr />
                    </div>
                </div>
                <div className="row">
                    <div className='col'>
                        {error.status == true &&
                            <div className="alert alert-danger" role="alert">
                                {error.message}
                            </div>
                        }
                        {(show == true) &&
                            <DTable data={data} columns={columnsheadings} />
                        }
                        {show == false &&
                            <Loader className='loader-center'
                                type="Grid"
                                color="#00BFFF"
                                height={100}
                                width={100}
                            />
                        }
                    </div>
                </div>

                <div className="modal" tabindex="-1" id="view-summary-modal">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Complaint Summary</h5>
                        <button type="button" className="btn-close modal-close-btn" data-bs-dismiss="modal" aria-label="Close">X</button>
                    </div>
                    <div className="modal-body">
                        <p className="view-complaint-summary-content"></p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary modal-close-btn" data-bs-dismiss="modal">Close</button>
                    </div>
                    </div>
                </div>
                </div>

                <div className="modal" tabindex="-1" id="view-booking-details-modal">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Booking Details</h5>
                        <button type="button" className="btn-close modal-close-btn" data-bs-dismiss="modal" aria-label="Close">X</button>
                    </div>
                    <div className="modal-body">
                        <p className="view-booking-details-content"></p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary modal-close-btn" data-bs-dismiss="modal">Close</button>
                    </div>
                    </div>
                </div>
                </div>


                <div className="modal" tabindex="-1" id="complaint-analysis-modal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Complaint Analysis</h5>
                            <button type="button" className="btn-close modal-close-btn" data-bs-dismiss="modal" aria-label="Close">X</button>
                        </div>
                            <form id="complaint-analysis-form">
                            <input type="hidden" id="analysis-current-id" />
                            <div className="modal-body">
                                <div className="form-group">
                                    <label for="editcomplaintanalysissummary">Complaint Summary</label>
                                    <textarea class="form-control" id="editcomplaintanalysissummary" rows="3"></textarea>
                                </div>
                                <div className="form-group">
                                    <label for="editcomplaintanalysisexpected">Client Request / Expected Outcome</label>
                                    <textarea class="form-control" id="editcomplaintanalysisexpected" rows="3"></textarea>
                                </div>
                                <div className="form-group">
                                    <label for="editcomplaintanalysisagentaction">Agent Action Plan</label>
                                    <textarea class="form-control" id="editcomplaintanalysisagentaction" rows="3"></textarea>
                                </div>
                                <div className="form-group">
                                    <label for="editcomplaintanalysissupplierreply">Supplier Reply / Feedback</label>
                                    <textarea class="form-control" id="editcomplaintanalysissupplierreply" rows="3"></textarea>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary modal-close-btn" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-success modal-close-btn" data-bs-dismiss="modal">Submit</button>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal" tabindex="-1" id="complaint-decision-modal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Management Decision</h5>
                            <button type="button" className="btn-close modal-close-btn" data-bs-dismiss="modal" aria-label="Close">X</button>
                        </div>
                            <form id="complaint-decision-form">
                            <input type="hidden" id="decision-current-id" />
                            <div className="modal-body">
                                <div className="form-group">
                                    <label for="editcomplaintdecisionfindings">Management Findings & Decision</label>
                                    <textarea class="form-control" id="editcomplaintanalysisfindings" rows="3"></textarea>
                                </div>
                                <div className="form-group">
                                    <label for="editcomplaintdecisionactionclient">Action for Client</label>
                                    <textarea class="form-control" id="editcomplaintdecisionactionclient" rows="3"></textarea>
                                </div>
                                <div className="form-group">
                                    <label for="editcomplaintdecisionactionsupplier">Action for Supplier</label>
                                    <textarea class="form-control" id="editcomplaintdecisionactionsupplier" rows="3"></textarea>
                                </div>
                                <div className="form-group">
                                    <label for="editcomplaintdecisionagentmistake">Agent Mistake (if any)</label>
                                    <textarea class="form-control" id="editcomplaintdecisionagentmistake" rows="3"></textarea>
                                </div>
                                <div className="form-group">
                                    <label for="editcomplaintdecisionnotes">Management Notes (if any)</label>
                                    <textarea class="form-control" id="editcomplaintdecisionnotes" rows="3"></textarea>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary modal-close-btn" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-success modal-close-btn" data-bs-dismiss="modal">Submit</button>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}