import React, { Component } from "react";
import DatePicker from "react-datepicker";
import Loader from 'react-loader-spinner';
import DTable from './dtable.component';
import Select from 'react-select';
import swal from 'sweetalert';
import axios from 'axios';

import AuthService from '../services/auth/auth.service';
import PaymentReportsService from '../services/erp/paymentreports.service';
import TasksReportsService from '../services/erp/tasksreports.service';
import UtilityService from '../services/utility.service';
import InvoicesService from "../services/erp/invoices.service";
import InvoicesServiceNew from "../services/erp/invoicesnew.service";
import "react-datepicker/dist/react-datepicker.css";
import { times } from "lodash";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import CKEditor from "react-ckeditor-component";

import UserContext from "../contexts/UserContext";
import NoPermission from "./extras/nopermission.component";
import SupplierDetails from "./tasklist/suppliersdetails.component";
const $ = require("jquery");

export default class TaskManager extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            showSupplierDetails: false,
            columnsheadings: [],
            options: {
                filterType: 'dropdown',
            },
            show: false,
            products: [{ ID: 0, post_title: 'Select a Product' }],
            suppliers: [{value:0, label:'Select a Supplier'}],
            companies: [{value:0, label:'Select a Company'}],
            taskcompanies: [{value:0, label:'Select a Company'}],
            tasksuppliers: [],
            startdate: '',
            suppliername: { value: 0, label: 'Select a Supplier' },
            enddate: '',
            productname: { value: 0, label: 'Select a Product' },
            companyname: { value: 0, label: 'Select a Company' },
            taskcompanyname: { value: 0, label: '' },
            tasksuppliername: [],
            taskproductname: [],
            taskproducts: [],
            tasktype: 0,
            taskstatus: 0,
            taskpriority: 0,
            createtaskpriority: 'Low',
            taskdatereceived: '',
            loadingvalues: true,
            error: {
                status: false,
                message: ''
            },
            loader: false,
            search_btn_text: 'Search',
            minDate: new Date("02-01-2019"),
            taskresourcesdetails: [
                {
                    "details" : '',
                    "link" : '',
                    "service" : 'Dropbox',
                }
            ],
            taskdepartments: {
                "data": false,
                "graphics": false,
                "content": false,
                "translation": false,
                "quality": false
            },
            createtasktype: {
                "product_extension": false,
                "grapic_update": false,
                "rate_change": false,
                "content_change_update": false,
                "validity_change": false,
            },
            createtaskcomments: '',
            createtaskfiles: [],
            createtaskfilesstring: "",
            username: null,
            elchange: '',
            editTaskID: '',
            disableTaskProduct: true,
            disableTaskSuppliers: true,
            disableTaskCompany: false,
            searchtasksept: "0",
            searchtasksdeptstatus: "0",
            allowed: true,
            allProducts: [{value:0, label:'Select a Product'}],
            supplierSearchProd: {value:0, label:'Select a Product'}
        };

        // this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
        // console.log('handle change');
    }

    componentDidMount() {

        PaymentReportsService.getAllSuppliers()
            .then(response => {
                // console.log({ response });
                if (response.data.suppliers) {
                    this.setState({
                        // suppliers: response.data.suppliers,
                        companies: response.data.companies,
                        taskcompanies: response.data.companies,
                        allProducts: response.data.products,
                        loadingvalues: false
                    });
                    // console.log('i am getsupds');
                }
            });
        var self = this;
        AuthService.checklogin().then(user => {
            // console.log({ user });
            this.setState({
                username: user.user_display_name
            });

            // setTimeout(()=>{
               AuthService.getUserPermisions(user)
                .then(response => {
                    var userpermissions = response.data;
                    console.log({userpermissions});
                        if(userpermissions.includes('taskmanager') || userpermissions.includes('all')) {
                        console.log('You are allowed!');
                        this.setState({
                            allowed: true
                        });
                        self.searchClick(null);
                    } else {
                        console.log('You are not allowed!');
                        this.setState({
                            allowed: false
                        });
                    }
                })
            // }, 3000);
            

            
        }).catch(error => {
            // console.log({ error });
            window.location.href = '/login';
        });

    //    document.getElementById('create-task-btn').addEventListener('click', function(){
    //         self.setState({
    //             disableTaskCompany: false,
    //         });
    //     });

        document.addEventListener('click',function(e){
            if(e.target && e.target.classList.contains('edit-task-btn')){
                //do something
                var taskID = e.target.getAttribute("data-id");
                self.editTask(taskID);
                // console.log("my class");
            }
        });

    }

    searchClick(e) {
        if(e==null){

        } else {
            e.preventDefault();
        }
        // console.log('The link was clicked.');
        this.setState({
            show: false,
            error: {
                status: false,
                message: ''
            },
            search_btn_text: 'Fetching..',
            loader: true
        });

        var startday = '';
        var endday = '';
        if(this.state.startdate!='' && this.state.enddate!='') {
            startday = UtilityService.format_date(this.state.startdate);
            endday = UtilityService.format_date(this.state.enddate);
        }

        // console.log(this.state);

        TasksReportsService.getAll(startday, endday, this.state.companyname.value, this.state.suppliername.value, this.state.tasktype, this.state.taskstatus, this.state.taskpriority, this.state.searchtasksept, this.state.searchtasksdeptstatus)
            .then(response => {
                // console.log({ response });
                if (typeof response.data=='undefined' ) {
                    this.setState({
                        error: {
                            status: true,
                            message: 'No data found for the selection, Please retry or change your selections.'
                        },
                        search_btn_text: 'Search',
                        loader: false
                    });
                } else if (response.data.length > 0) {
                    this.setState({
                        data: response.data,
                        columnsheadings: [
                            { title: "ID" },
                            { title: "Product" },
                            { title: "Product Link" },
                            { title: "Product Category" },
                            { title: "Supplier" },
                            { title: "Company" },
                            { title: "Task Priority" },
                            { title: "Task Created Date" },
                            { title: "Date Task Received" },
                            { title: "Task Type" },
                            { title: "Department Task & Status" },
                            { title: "Task Status" },
                            { title: "Message Status" },
                            { title: "Actions" },
                        ],
                        options: {
                            filterType: 'dropdown',
                        },
                        show: true,
                        search_btn_text: 'Search',
                        loader: false,
                        elchange: Math.random()
                    });

                    // setTimeout(function(){
                    //     var select = '<select id="deptFilter" class="form-control"><option value="">All Departments</option><option value="Content">Content</option><option value="Data">Data</option><option value="Graphics">Graphics</option><option value="Q & A">Q & A</option></select>';
                    //     $('.tasks-data-table').find(".dataTables_filter").append(select);
                    // }, 1000);
                    
                    // console.log(response.data);
                } else {
                    this.setState({
                        error: {
                            status: true,
                            message: 'No data found for the selection, Please retry or change your selections.'
                        },
                        search_btn_text: 'Search',
                        loader: false
                    });
                }
            });
    }

    supChange = (selectedOption) => {
        this.setState({ suppliername: selectedOption });
        // console.log(`Option selected:`, selectedOption);
    }

    setStartDate = (date) => {
        this.setState({
            startdate: date,
            minDate: date,
        });

        if (this.state.enddate != '' && this.state.enddate < date) {
            this.setState({
                enddate:date
            });
        }
        // console.log(this.state);
    }

    setEndDate = (date) => {

        this.setState({
            enddate: date
        });
        // console.log(this.state);
    }

    setReceivedDate = (date) => {

        this.setState({
            taskdatereceived: date
        });
        // console.log(this.state);
    }

    handleChangeProduct = selectedOption => {
        this.setState({ productname: selectedOption });
        // console.log(`Option selected:`, selectedOption);
    };

    handleTaskChangeProduct = selectedOption => {
        this.setState({ 
            taskproductname: selectedOption,
            // tasksuppliername: []
        });
        // console.log(`Option selected:`, selectedOption);
    };

    handleChangeSuplier = selectedOption => {
        this.setState({ suppliername: selectedOption });
        // console.log(`Option selected:`, selectedOption);
    };

    handleProductChangeChecks = (e, index) => {
        var productsChecks = this.state.taskproducts;
        productsChecks[index].checked = e.target.checked;

        this.setState({
            taskproducts: productsChecks
        });
    }


    handleTaskChangeSuplier = async (selectedOption) => {
        this.setState({ 
            tasksuppliername: selectedOption,
            taskproductname: [],
            taskproducts: [],
            disableTaskProduct: true
        });
        // this.setState({ commsuppliernamehidden: selectedOption });
        // this.setState({
        //     taskproductname: {value: 0, label: ''},
        // });
        // if(selectedOption.value==0 || selectedOption.value=='All') {
        if(selectedOption==null) {
            this.setState({
                taskproducts: [],
            });
        } else {
            await InvoicesServiceNew.fetchProductsPost(this.state.taskcompanyname.value, selectedOption)
            .then(response => {
                console.log(response);
                if(response.data!= null && response.data.length>0) {
                    this.setState({
                        taskproducts: response.data,
                        disableTaskProduct: false
                        // enableCommformproducts: true,
                    });
                }

                if(response.data.length<1){
                 swal("No products found!", "No products found or allocated for this supplier(s). Please check with concerned depatment!", "error");   
                }
            })
            .catch(error => {
                console.log(error);
            })
        }
        
        // console.log(`Option selected:`, selectedOption);
    };

    handleChangCompany = selectedOption => {
        this.setState({ companyname: selectedOption });
        // console.log(`Option selected:`, selectedOption);

        InvoicesService.fetchSuppliers(selectedOption.value)
            .then(response => {
                // console.log(response);
                this.setState({
                    suppliers: response.data,
                    isDisableSuppliers: false
                });
            })
            .catch(error => {
                console.log(error);
            })
    };

    handleTaskChangeCompany = selectedOption => {
        this.setState({ 
            taskcompanyname: selectedOption,
            tasksuppliername: [],
            disableTaskProduct: true,
            tasksuppliers: [],
            disableTaskSuppliers: true,
         });
        // this.setState({ tasksuppliername: [] });

        // console.log(`Option selected:`, selectedOption);

        InvoicesServiceNew.fetchSuppliers(selectedOption.value)
            .then(response => {
                // console.log(response);
                this.setState({
                    tasksuppliers: response.data,
                    disableTaskSuppliers: false,
                    // isDisableSuppliers: false
                });
            })
            .catch(error => {
                console.log(error);
            })
    };

    addResource = () => {
        var temp = {
            "details" : '',
            "link" : '',
            "service" : 'Dropbox',
        };
        var taskresourcesdetails = this.state.taskresourcesdetails;
        taskresourcesdetails.push(temp);

        this.setState({
            taskresourcesdetails: taskresourcesdetails
        });
    }

    updateResourceDetail = (e, index) => {
        var taskresourcesdetails = this.state.taskresourcesdetails;
        taskresourcesdetails[index].details = e.target.value;
        this.setState({
            taskresourcesdetails: taskresourcesdetails
        });
    }

    updateResourceLink = (e, index) => {
        var taskresourcesdetails = this.state.taskresourcesdetails;
        taskresourcesdetails[index].link = e.target.value;
        this.setState({
            taskresourcesdetails: taskresourcesdetails
        });
    }

    updateResourceService = (e, index) => {
        var taskresourcesdetails = this.state.taskresourcesdetails;
        taskresourcesdetails[index].service = e.target.value;
        this.setState({
            taskresourcesdetails: taskresourcesdetails
        });
    }

    setDepartment = (e, val) => {
        var checked = e.target.checked;
        var createtasktype = this.state.createtasktype;

        if(val == 'product_extension') {

            createtasktype.product_extension = checked;

        } else if(val == 'grapic_update') {

            createtasktype.grapic_update = checked;

        } else if(val == 'rate_change') {

            createtasktype.rate_change = checked;

        } else if(val == 'content_change_update') {

            createtasktype.content_change_update = checked;

        } else if(val == 'validity_change') {

            createtasktype.validity_change = checked;

        }

        this.setState({
            createtasktype: createtasktype,
        });

        //  console.log({createtasktype});

        // var taskdepartments = {
        //     "data": false,
        //     "graphics": false,
        //     "content": false,
        //     "translation": false,
        //     "quality": false
        // };

        // if(createtasktype.product_extension || createtasktype.rate_change || createtasktype.validity_change || this.taskdepartments.data) {
        //     taskdepartments.data = true;
        //     taskdepartments.quality = true;
        // }

        // if(createtasktype.grapic_update || this.taskdepartments.graphics) {
        //     taskdepartments.graphics = true;
        //     taskdepartments.quality = true;
        // }

        // if(createtasktype.content_change_update || this.taskdepartments.content) {
        //     taskdepartments.content = true;
        //     taskdepartments.quality = true;
        // }

        // this.setState({
        //     taskdepartments: taskdepartments,
        // });
        
    }

    setDepartmentManual = (e, val) => {
        var checked = e.target.checked;

        var taskdepartments = this.state.taskdepartments;

        // var taskdepartments = {
        //     "data": false,
        //     "graphics": false,
        //     "content": false,
        //     "translation": false,
        //     "quality": false
        // };

        if(val == 'data') {
            taskdepartments.data = checked;
            taskdepartments.quality = checked;
        }

        if(val == 'graphics') {
            taskdepartments.graphics = checked;
            taskdepartments.quality = checked;
        }

        if(val == 'content') {
            taskdepartments.content = checked;
            taskdepartments.quality = checked;
        }

        if(val == 'translation') {
            taskdepartments.content = checked;
            taskdepartments.translation = checked;
            taskdepartments.quality = checked;
        }
        
        taskdepartments.quality = true;

        this.setState({
            taskdepartments: taskdepartments,
        });

        // console.log({taskdepartments});
    }

    editTask = (taskID) => {
        if(taskID=='0' || taskID=='' ) {
            return;
        }

        // console.log({taskID});
        this.setState({
            editTaskID: taskID,
            disableTaskCompany: true,
            tasksuppliers: [],
            disableTaskSuppliers: true,
            disableTaskProduct: true,
        });
        TasksReportsService.editTask(taskID)
        .then(response => {

            if(response.data!=='undefined') {
                this.setState({
                    taskcompanyname: response.data.company,
                    tasksuppliername: response.data.supplier,
                    taskproductname: response.data.product,
                    taskproducts: response.data.product,
                    createtaskpriority: response.data.priority,
                    taskdatereceived: new Date(response.data.received * 1000),
                    taskresourcesdetails: response.data.resources,
                    taskdepartments: response.data.departments,
                    createtasktype: response.data.type,
                    createtaskcomments: response.data.notes,

                });

                document.getElementById('product_extension').checked = response.data.type.product_extension;
                document.getElementById('grapic_update').checked = response.data.type.grapic_update;
                document.getElementById('rate_change').checked = response.data.type.rate_change;
                document.getElementById('content_change_update').checked = response.data.type.content_change_update;
                document.getElementById('validity_change').checked = response.data.type.validity_change;

                document.getElementById('data').checked = response.data.departments.data;
                document.getElementById('content').checked = response.data.departments.content;
                document.getElementById('graphics').checked = response.data.departments.graphics;
                document.getElementById('translation').checked = response.data.departments.translation;

                InvoicesServiceNew.fetchSuppliers(response.data.company.value)
                .then(response => {
                    // console.log(response);
                    this.setState({
                        tasksuppliers: response.data,
                        // isDisableSuppliers: false
                    });
                })
                .catch(error => {
                    console.log(error);
                });
                InvoicesServiceNew.fetchProducts(response.data.company.value, response.data.supplier)
                .then(response => {
                    // console.log(response);
                    this.setState({
                        // taskproducts: response.data,
                        // enableCommformproducts: true,
                    });
                })
                .catch(error => {
                    console.log(error);
                });
            }
            // console.log({response});
             this.setState({
                disableTaskCompany: true,
                disableTaskSuppliers: true,
                disableTaskProduct: true,
            });
            // $('#createTaskModal').modal('show');
            // document.querySelector("#create-task-btn").click();
        })
        .catch(error => {
            console.log({error});
        });
    }

    createTask = async () => {

        if(this.state.taskcompanyname.value==0 || this.state.taskcompanyname.value=='' || this.state.tasksuppliername=='' || this.state.tasksuppliername.length<1 || this.state.taskdatereceived=='' || this.state.createtaskpriority=='' || (this.state.taskproducts.length==1 && this.state.taskproducts[0].value==0) || this.state.taskproducts.length==0) {
            swal("Incomplete Information!", "Please select or fill all mandatory feilds", "error");
            return;
        }

        var check1 = false;
        var check2 = false;
        if(!this.state.createtasktype.product_extension && !this.state.createtasktype.grapic_update && !this.state.createtasktype.rate_change && !this.state.createtasktype.content_change_update && !this.state.createtasktype.validity_change) {
            check1 = true;
        }

        if(!this.state.taskdepartments.data && !this.state.taskdepartments.content && !this.state.taskdepartments.translation && !this.state.taskdepartments.graphics) {
            check2 = true;
        }

        if(check1 && check2) {
            swal("Incomplete Information!", "Please select or fill all mandatory feilds", "error");
            return;
        }

        var resFiles = await this.uploadFile(this.state.createtaskfiles);
        this.setState({
            createtaskfilesstring: resFiles.data
        });
        // console.log({resFiles});

        // console.log("this.state.taskresourcesdetails", this.state.taskresourcesdetails);

        var recievedDate = await UtilityService.format_date(this.state.taskdatereceived);
        await TasksReportsService.saveTask(this.state.taskcompanyname, this.state.tasksuppliername, this.state.taskproducts, recievedDate, this.state.createtaskpriority, this.state.createtasktype, this.state.taskdepartments, this.state.taskresourcesdetails, this.state.createtaskfiles, this.state.createtaskcomments, this.state.username, this.state.editTaskID, resFiles.data)
        .then(response => {
            // console.log({response});
            swal("Task Saved!", "The task has been saved", "success");
            document.querySelector('.task-search-button').click();
            document.querySelector('.modal-create-task-close-btn').click();

            this.setState({
                taskcompanyname: { value: 0, label: 'Select a Company' },
                tasksuppliername: { value: 0, label: 'Select a Supplier' },
                // taskproductname: { value: 0, label: 'Select a Product' },
                taskproductname: [],
                taskproducts: [],
                createtaskpriority: 'Low',
                taskdatereceived: '',
                taskresourcesdetails: [
                    {
                        "details" : '',
                        "link" : '',
                        "service" : 'Dropbox',
                    }
                ],
                taskdepartments: {
                    "data": false,
                    "graphics": false,
                    "content": false,
                    "translation": false,
                    "quality": false
                },
                createtasktype: {
                    "product_extension": false,
                    "grapic_update": false,
                    "rate_change": false,
                    "content_change_update": false,
                    "validity_change": false,
                },
                createtaskcomments: '',
                createtaskfiles: [],
                editTaskID: '',
                createtaskfilesstring: '',
                disableTaskCompany: false,
                disableTaskProduct: true,
                disableTaskSuppliers: true,
            });

            // Reset file input control
            document.getElementById("create-task-file").value = null;
            document.getElementById('product_extension').checked = false;
            document.getElementById('grapic_update').checked = false;
            document.getElementById('rate_change').checked = false;
            document.getElementById('content_change_update').checked = false;
            document.getElementById('validity_change').checked = false;

            document.getElementById('data').checked = false;
            document.getElementById('content').checked = false;
            document.getElementById('graphics').checked = false;
            document.getElementById('translation').checked = false;

        })
        .catch(error => {
            console.log({error});
        });
    }

    uploadFile = async (file) => {

        const formData = new FormData();

        for (let i = 0; i < this.state.createtaskfiles.length; i++) {
            formData.append("file[]", this.state.createtaskfiles[i]);
        }

        formData.append('avatar',file)

        return  await axios.post('https://deals.mu/scripts/reportsapi/tasksupload.php', formData,{
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
      }

    //   old on change
    // onChangeCreatetaskComment = (e) => {
    //     this.setState({
    //         createtaskcomments: e.target.value
    //     });
    // }

    onChangeCreatetaskComment = e => {
        // console.log({createtaskcomments});
        this.setState({
            createtaskcomments: e.editor.getData()
        });
    }

    handleFilesChange = (e) => {
        // this.setState({
        //     createtaskfiles: e.target.files
        // });

        this.setState({createtaskfiles:e.target.files})
    }

    handleChangeEditTaskID = (e) => {
        this.setState({
            editTaskID: ''
        });

        $('#current-task-id').val('');
        // $('#createTaskModal').modal('show');
        // document.getElementById("createTaskModal").focus();
        // console.log(e.target.value);
        
        // if(e.target.value=="" || e.target.value=="0") {
            // setTimeout(function(){
                this.setState({
                    taskcompanyname: { value: 0, label: 'Select a Company' },
                    tasksuppliername: { value: 0, label: 'Select a Supplier' },
                    // taskproductname: 
                    taskproductname: [],
                    taskproducts: [],
                    createtaskpriority: 'Low',
                    taskdatereceived: '',
                    taskresourcesdetails: [
                        {
                            "details" : '',
                            "link" : '',
                            "service" : 'Dropbox',
                        }
                    ],
                    taskdepartments: {
                        "data": false,
                        "graphics": false,
                        "content": false,
                        "translation": false,
                        "quality": false
                    },
                    createtasktype: {
                        "product_extension": false,
                        "grapic_update": false,
                        "rate_change": false,
                        "content_change_update": false,
                        "validity_change": false,
                    },
                    createtaskcomments: '',
                    createtaskfiles: [],
                    editTaskID: '',
                    createtaskfilesstring: '',
                    disableTaskCompany: false,
                    disableTaskProduct: true,
                    disableTaskSuppliers: true,
                });
            document.getElementById("create-task-file").value = null;
            document.getElementById('product_extension').checked = false;
            document.getElementById('grapic_update').checked = false;
            document.getElementById('rate_change').checked = false;
            document.getElementById('content_change_update').checked = false;
            document.getElementById('validity_change').checked = false;

            document.getElementById('data').checked = false;
            document.getElementById('content').checked = false;
            document.getElementById('graphics').checked = false;
            document.getElementById('translation').checked = false;
            // }, 2000);
            
        // }
    }

    searchClickSupplier(e) {
        e.preventDefault();
        console.log("clicked");
        if(this.state.supplierSearchProd.value!=0 && this.state.supplierSearchProd.value!='0') {
            this.setState({
                showSupplierDetails: true
            });
        } else {
            swal("No products selected!", "Please select a product to get supplier!", "error");
        }
    }

    closeSupplierDetails(e) {
        this.setState({
            showSupplierDetails: false
        });
    }

    handleSupplierSearchProdChange(selectedOption) {
        this.setState({ 
            supplierSearchProd: selectedOption,
        });
    }

    render() {
        var { data, columnsheadings, options, show, products, suppliers, companies, loadingvalues, error, search_btn_text, loader } = this.state;

        if(this.state.allowed==false) {
            return (
                <NoPermission />
            );
        }
        
        return (
            <>
                <div className="row">
                    <div className='col'>
                        <h1>Task List</h1>
                        <span style={{display:'flex', flexDirection:'row'}}>
                            <button className="btn btn-sm btn-success" onClick={this.handleChangeEditTaskID.bind(this)} id="create-task-btn" data-bs-toggle="modal" data-bs-target="#createTaskModal">Create Task</button>
                            <button className="btn btn-sm btn-warning" onClick={this.searchClick.bind(this)} style={{display: 'none'}} id="new-tasks-socket-btn" ></button>
                            <div style={{display: "flex", flexDirection: "row", marginLeft: "10px"}}>
                                <Select
                                    value={this.state.supplierSearchProd}
                                    onChange={this.handleSupplierSearchProdChange.bind(this)}
                                    options={this.state.allProducts} 
                                    id="products-supplier"
                                />
                                <button className="btn btn-sm btn-warning" style={{marginLeft: "10px"}} onClick={this.searchClickSupplier.bind(this)} id="get-product-supplier-btn" >Get Suppliers</button>
                            </div>
                        </span>
                        <hr />

                        <div className="modal fade" id="createTaskModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Create/Update Task:</h5>
                                        <button type="button" className="btn-close btn btn-sm btn-danger modal-create-task-close-btn" data-bs-dismiss="modal" aria-label="Close">X</button>
                                    </div>
                                    <form id="create-task-form" onSubmit={this.createTask}>
                                    <div className="modal-body">
                                        <div className="form-group row">
                                            <label for="create-task-company" className="col-sm-3 col-form-label">Choose Company<b className="compulsory-input-star">*</b></label>
                                            <div className="col-sm-9">
                                            <Select
                                                value={this.state.taskcompanyname}
                                                onChange={this.handleTaskChangeCompany}
                                                options={this.state.taskcompanies} 
                                                id="create-task-company"
                                                isDisabled={this.state.disableTaskCompany}
                                            />    
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label for="create-task-supplier" className="col-sm-3 col-form-label">Choose Supplier<b className="compulsory-input-star">*</b></label>
                                            <div className="col-sm-9">
                                            <Select
                                                value={this.state.tasksuppliername}
                                                onChange={this.handleTaskChangeSuplier}
                                                options={this.state.tasksuppliers} 
                                                id="create-task-supplier"
                                                isMulti
                                                isDisabled={this.state.disableTaskSuppliers}
                                            />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label for="create-task-products" className="col-sm-12 col-form-label">Choose Products<b className="compulsory-input-star">*</b></label>
                                            <div className="col-sm-12">
                                            {/* <Select
                                                value={this.state.taskproductname}
                                                onChange={this.handleTaskChangeProduct}
                                                options={this.state.taskproducts} 
                                                isMulti
                                                isDisabled={this.state.disableTaskProduct}
                                                id="create-task-products"
                                            /> */}
                                            {/* {this.state.taskproducts.length>0 && 
                                                <table>
                                            } */}
                                            {this.state.taskproducts.length>0 && this.state.taskproducts.map((prod, index) => (
                                                
                                                <tr className="products-task-form-table" key={index}>
                                                <td style={{width: "50%"}}>{prod.label}</td>
                                                <td style={{width: "30%"}}>{prod.category}</td>
                                                <td style={{width: "10%"}}><a href={prod.link} target="_blank" rel="noreferrer">Link</a></td>
                                                <td style={{width: "10%"}}>{prod.status}</td>
                                                <td style={{width: "10%"}}><input style={{height: '10px', width: '10px'}} type="checkbox" className="form-control" onChange={e => this.handleProductChangeChecks(e, index)} checked={prod.checked} /></td>
                                                </tr>
                                            ))}
                                            {/* {this.state.taskproducts.length>0 && 
                                                </table>
                                            } */}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col" style={{borderBottom: "1px solid black", marginBottom: "10px", fontWeight: "bold", backgroundColor: "#ffff7a", paddingTop: '5px'}}>
                                                Define Task Details
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label for="create-task-date-received" className="col-sm-3 col-form-label">Date Received<b className="compulsory-input-star">*</b></label>
                                            <div className="col-sm-9">
                                            <DatePicker
                                                dateFormat="dd-MM-yyyy"
                                                selected={this.state.taskdatereceived}
                                                onChange={date => this.setReceivedDate(date)}
                                                placeholderText="Date Received"
                                                className='form-control'
                                                id="create-task-date-received"
                                            />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label for="create-task-priority" className="col-sm-3 col-form-label">Task Priority<b className="compulsory-input-star">*</b></label>
                                            <div className="col-sm-9">
                                            <select className="form-control form-control-select" name="createtaskpriority" value={this.state.createtaskpriority} onChange={this.handleChange.bind(this)}>
                                                <option value="Low">Low</option>
                                                <option value="Medium">Medium</option>
                                                <option value="High">High</option>
                                                <option value="Critical">Critical</option>
                                            </select>
                                            </div>
                                        </div>

                                        <div className="row" style={{borderBottom: "1px solid black", marginBottom: '10px', backgroundColor: "#d1e5f9", paddingTop: '5px'}}>
                                            <div className="col-4" style={{fontWeight: "bold"}}>
                                                Task Type<b className="compulsory-input-star">*</b>
                                            </div>
                                            <div className="col-3">
                                                Tick To confirm
                                            </div>
                                            <div className="col-5">
                                                Responsible Teams
                                            </div>
                                        </div>
                                        <div className="form-group row task-type-rows">
                                            <label for="create-task-priority" className="col-sm-4 col-form-label">Product Extensions:</label>
                                            <div className="col-sm-3" style={{textAlign: "center"}}>
                                                <input type="checkbox" className="form-check-input" id="product_extension" value={this.state.createtasktype.product_extension} onChange={(e)=> this.setDepartment(e, 'product_extension')} />
                                            </div>
                                            <div className="col-sm-5 task-type-rows-teams">
                                                <span style={{backgroundColor: '#e3e59d'}}>Data</span>
                                                <span style={{backgroundColor: '#bae9bc'}}>Quality Assurance</span>
                                            </div>
                                        </div>
                                        <div className="form-group row task-type-rows">
                                            <label for="create-task-priority" className="col-sm-4 col-form-label">Graphic Update:</label>
                                            <div className="col-sm-3" style={{textAlign: "center"}}>
                                                <input type="checkbox" className="form-check-input" id="grapic_update" value={this.state.createtasktype.grapic_update} onChange={(e)=> this.setDepartment(e, 'grapic_update')} />
                                            </div>
                                            <div className="col-sm-5 task-type-rows-teams">
                                               <span style={{backgroundColor: '#e0abab'}}>Graphics</span>
                                               <span style={{backgroundColor: '#bae9bc'}}>Quality Assurance</span>
                                            </div>
                                        </div>
                                        <div className="form-group row task-type-rows">
                                            <label for="create-task-priority" className="col-sm-4 col-form-label">Rate Change:</label>
                                            <div className="col-sm-3" style={{textAlign: "center"}}>
                                                <input type="checkbox" className="form-check-input" id="rate_change" value={this.state.createtasktype.rate_change} onChange={(e)=> this.setDepartment(e, 'rate_change')} />
                                            </div>
                                            <div className="col-sm-5 task-type-rows-teams">
                                                <span style={{backgroundColor: '#e3e59d'}}>Data</span>
                                                <span style={{backgroundColor: '#bae9bc'}}>Quality Assurance</span>
                                            </div>
                                        </div>
                                        <div className="form-group row task-type-rows">
                                            <label for="create-task-priority" className="col-sm-4 col-form-label">Content Change / Update:</label>
                                            <div className="col-sm-3" style={{textAlign: "center"}}>
                                                <input type="checkbox" className="form-check-input" id="content_change_update" value={this.state.createtasktype.content_change_update} onChange={(e)=> this.setDepartment(e, 'content_change_update')} />
                                            </div>
                                            <div className="col-sm-5 task-type-rows-teams">
                                                <span style={{backgroundColor: '#8abef5'}}>Content</span>
                                                <span style={{backgroundColor: '#bae9bc'}}>Quality Assurance</span>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label for="create-task-priority" className="col-sm-4 col-form-label">Validity Change:</label>
                                            <div className="col-sm-3" style={{textAlign: "center"}}>
                                                <input type="checkbox" className="form-check-input" id="validity_change" value={this.state.createtasktype.validity_change} onChange={(e)=> this.setDepartment(e, 'validity_change')} />
                                            </div>
                                            <div className="col-sm-5 task-type-rows-teams">
                                                <span style={{backgroundColor: '#e3e59d'}}>Data</span>
                                                <span style={{backgroundColor: '#bae9bc'}}>Quality Assurance</span>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="create-task-priority" className="col-sm-3 col-form-label">Manually add a Team:</label>
                                            <div className="col-sm-9">
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox" id="data" value={this.state.taskdepartments.data} onChange={(e)=> this.setDepartmentManual(e, 'data')} />
                                                <label className="form-check-label adjust-label" for="data">Data</label>
                                                </div>
                                                <div className="form-check form-check-inline" style={{marginLeft: '20px'}}>
                                                <input className="form-check-input" type="checkbox" id="content" value={this.state.taskdepartments.content} onChange={(e)=> this.setDepartmentManual(e, 'content')} />
                                                <label className="form-check-label adjust-label" for="content">Content</label>
                                                </div>
                                                <div className="form-check form-check-inline" style={{marginLeft: '20px'}}>
                                                <input className="form-check-input" type="checkbox" id="translation"  value={this.state.taskdepartments.translation} onChange={(e)=> this.setDepartmentManual(e, 'translation')} />
                                                <label className="form-check-label adjust-label" for="translation">Translations</label>
                                                </div>
                                                 <div className="form-check form-check-inline" style={{marginLeft: '20px'}}>
                                                <input className="form-check-input" type="checkbox" id="graphics"  value={this.state.taskdepartments.graphics} onChange={(e)=> this.setDepartmentManual(e, 'graphics')} />
                                                <label className="form-check-label adjust-label" for="inlineChgraphicseckbox4">Graphics</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col" style={{borderBottom: "1px solid black", marginBottom: "10px", fontWeight: "bold", backgroundColor: "#ffff7a", paddingTop: '5px'}}>
                                                Task Resources
                                            </div>
                                        </div>
                                        {this.state.taskresourcesdetails.length>0 && this.state.taskresourcesdetails.map((res, index) => (
                                        <div className="form-group row" key={index}>
                                            <label for="create-task-products" className="col-sm-3 col-form-label">Resouce Details:</label>
                                            <div className="col-sm-3">
                                                <input type="text" placeholder="Details" className="form-control" value={this.state.taskresourcesdetails[index].details} onChange={(e) => this.updateResourceDetail(e, index)} />
                                            </div>
                                            <div className="col-sm-4">
                                                <input type="text" placeholder="Enter resource Link" className="form-control"  value={this.state.taskresourcesdetails[index].link} onChange={(e) => this.updateResourceLink(e, index)} />
                                            </div>
                                            <div className="col-sm-2">
                                                <select className="form-control form-select" value={this.state.taskresourcesdetails[index].service} onChange={(e) => this.updateResourceService(e, index)}>
                                                    <option value="Dropbox">Dropbox</option>
                                                    <option value="Google Drive">Google Drive</option>
                                                    <option value="Email">Email</option>
                                                    <option value="Freshdesk">Freshdesk</option>
                                                    <option value="Bitrix">Bitrix</option>
                                                    <option value="Others">Others</option>
                                                </select>
                                            </div>
                                        </div>
                                        ))}
                                        <div className="form-group row" style={{float: "right", marginRight: "20px"}}>
                                            <button type="button" className="btn btn-sm btn-warning" onClick={this.addResource.bind(this)}>+ Add</button>
                                        </div>

                                        <div className="form-group row" style={{marginTop: '70px'}}>
                                            <label for="create-task-file" className="col-sm-3 col-form-label">Upload Files:</label>
                                            <div className="col-sm-9">
                                                <input id="create-task-file" className="form-control" type="file" multiple="multiple" onChange={this.handleFilesChange} />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col" style={{borderBottom: "1px solid black", marginBottom: "10px", fontWeight: "bold", backgroundColor: "#ffff7a", paddingTop: '5px'}}>
                                                Additional Info
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="create-task-comments" className="col-sm-3 col-form-label">Comments & Notes:</label>
                                            <div className="col-sm-9">
                                                {/* <textarea className="form-control" id="create-task-comments" name="createtaskcomments" rows="3" onChange={(e) => this.onChangeCreatetaskComment(e)} value={this.state.createtaskcomments}></textarea> */}
                                                {/* <Editor initialEditorState={this.state.createtaskcomments} editorState={this.state.createtaskcomments} onEditorStateChange={this.createtaskcomments} /> */}
                                                <CKEditor 
                                                activeClass="p10" 
                                                content={this.state.createtaskcomments} 
                                                events={{
                                                    "blur": this.onChangeCreatetaskComment,
                                                    "afterPaste": this.onChangeCreatetaskComment,
                                                    "change": this.onChangeCreatetaskComment
                                                }}
                                                />
                                            </div>
                                        </div>
                                        
                                        <div className={`form-group row ${this.state.editTaskID>0 ? "": "hideit"}`}>
                                            <label for="create-task-more-comments" className="col-sm-3 col-form-label">Additional Comments & Notes:</label>
                                            <div className="col-sm-9">
                                            <textarea class="form-control" id="miscnewcomment" rows="3"></textarea>
                                            <button type="button" className="btn btn-sm btn-success submit-comment-btn" data-dept="Misc" data-text-id="miscnewcomment">Save Comment</button>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className="modal-footer" style={{marginTop: "35px"}}>
                                        <button type="button" className="btn btn-secondary modal-create-task-close-btn" data-bs-dismiss="modal">Close</button>
                                        <button type="submit" className="btn btn-success">Create/Update Task</button>
                                    </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="row">
                    <div className='col'>
                        {loadingvalues == true &&
                            <p style={{ color: "#df153b" }}><b><i>Loading Companies. Please Wait....</i></b></p>
                        }
                        <form action="" method="get" className="booking-reports-search-form" style={{ display: 'flex' }}>
                            <input type="hidden" name="page" value="deals-payment-report" />
                            <div>
                               
                                <DatePicker
                                    dateFormat="dd-MM-yyyy"
                                    selected={this.state.startdate}
                                    onChange={date => this.setStartDate(date)}
                                    placeholderText="Start Date"
                                    className='form-control'
                                />
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                               
                                <DatePicker
                                    dateFormat="dd-MM-yyyy"
                                    selected={this.state.enddate}
                                    onChange={date => this.setEndDate(date)}
                                    placeholderText="End Date"
                                    className='form-control'
                                    minDate = {this.state.minDate}
                                />
                            </div>
                            <div style={{ marginLeft: '10px', width: '300px' }}>
                                
                                <Select
                                    value={this.state.companyname}
                                    onChange={this.handleChangCompany}
                                    options={companies}
                                />    
                            </div>
                            <div style={{ marginLeft: '10px', width: '300px' }}>
                                <Select
                                    value={this.state.suppliername}
                                    onChange={this.handleChangeSuplier}
                                    options={suppliers}
                                />
                            </div>
                            {/* <div style={{ marginLeft: '10px', width: '300px' }}>
                                <Select
                                    value={this.state.productname}
                                    onChange={this.handleChangeProduct}
                                    options={products}
                                />
                            </div> */}
                            <div style={{ marginLeft: '10px' }}>
                                <select name="tasktype" className="select-drops form-control" value={this.state.tasktype} onChange={this.handleChange.bind(this)}>
                                    <option value="0">All Task Types</option>
                                    <option value="product_extension">Product Extensions</option>
                                    <option value="grapic_update">Graphic Update</option>
                                    <option value="rate_change">Rate Change</option>
                                    <option value="content_change_update">Content Change / Update</option>
                                    <option value="validity_change">Validity Change</option>
                                </select>
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                <select name="taskstatus" className="select-drops form-control" value={this.state.taskstatus} onChange={this.handleChange.bind(this)}>
                                    <option value="0">All Task Status</option>
                                    <option value="All Pending">All Pending</option>
                                    <option value="In Progress">In Progress</option>
                                    <option value="Completed">Completed</option>
                                </select>
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                <select name="taskpriority" className="select-drops form-control" value={this.state.taskpriority} onChange={this.handleChange.bind(this)}>
                                    <option value="0">Any Task Priority</option>
                                    <option value="Low">Low</option>
                                    <option value="Medium">Medium</option>
                                    <option value="High">High</option>
                                    <option value="Critical">Critical</option>
                                </select>
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                <select name="searchtasksept" className="select-drops form-control" value={this.state.searchtasksept} onChange={this.handleChange.bind(this)}>
                                    <option value="0">All Departments</option>
                                    <option value="content">Content</option>
                                    <option value="data">Data</option>
                                    <option value="graphics">Graphics</option>
                                    <option value="quality_assurance">Q & A</option>
                                </select>
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                <select name="searchtasksdeptstatus" className="select-drops form-control" value={this.state.searchtasksdeptstatus} onChange={this.handleChange.bind(this)}>
                                    <option value="0">Department's Status</option>
                                    <option value="pending">Pending</option>
                                    <option value="inprogress">In-Progress</option>
                                    <option value="completed">Completed</option>

                                </select>
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                <button className="btn btn-success btn-large booking-search-btn task-search-button" onClick={this.searchClick.bind(this)}>{search_btn_text}</button>
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                <a href="/task-manager" className="btn btn-large btn-warning booking-reset-btn">Reset</a>
                            </div>

                        </form>

                        <hr />
                    </div>
                </div>
                <div className="row tasks-table">
                    <div className='col'>
                        <div className="container-fluid">
                            <input type="hidden" className="table-redraw-input" name="elchange" value={this.state.elchange} onChange={this.handleChange.bind(this)} />
                            {error.status == true &&
                                <div className="alert alert-danger" role="alert">
                                    {error.message}
                                </div>
                            }
                            {(show == true) &&
                                <span className="tasks-data-table">
                                <DTable data={data} columns={columnsheadings} defaultSort={[0, 'desc']} />
                                </span>
                            }
                            {loader == true &&
                                <Loader className='loader-center'
                                    type="Grid"
                                    color="#00BFFF"
                                    height={100}
                                    width={100}
                                />
                            }
                        </div>
                    </div>
                </div>

                 <div className="row">
                    <div className='col'>
                        <input type="hidden" id="current-task-id" value={this.state.editTaskID}  />
                        <div className="modal fade" id="graphicsTaskModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Graphic Department</h5>
                                    <button type="button" className="btn-close close-modal" data-bs-dismiss="modal" aria-label="Close">X</button>
                                </div>
                                <form id="graphics-task-update-form">
                                
                                <div className="modal-body">
                                    <div>
                                        <label className="modal-subheading" style={{marginTop: '10px'}}>Task Resources</label>
                                        <div id="task-resources" className="task-resources">
                                        </div>
                                    </div>

                                    <div>
                                        <label className="modal-subheading">Graphic Task Checklist</label>
                                        <span style={{display: 'flex', flexDirection: 'column'}}>
                                            <span className="flex-space-between"><span>Listing Images</span><span><input className="form-check-input" type="checkbox" value="" id="listingImagesCheck" /></span></span>
                                            <span className="flex-space-between"><span>Slider / Gallery Image</span><span><input className="form-check-input" type="checkbox" value="" id="sliderImagesCheck" /></span></span>
                                            <span className="flex-space-between"><span>Room Photos & Thumbnails</span><span><input className="form-check-input" type="checkbox" value="" id="roomPhotosCheck" /></span></span>
                                            <span className="flex-space-between"><span>Menu Items</span><span><input className="form-check-input" type="checkbox" value="" id="menuItemsCheck" /></span></span>
                                            <span className="flex-space-between"><span>Videos</span><span><input className="form-check-input" type="checkbox" value="" id="videosCheck" /></span></span>
                                        </span>
                                    </div>
                                    <div>
                                        <label className="modal-subheading">Task Submissions</label>
                                        <div className="form-group row">
                                            <label for="graphicsfilelink" className="col-sm-5 col-form-label">Graphic Files Submission (Files / Folder)</label>
                                            <div className="col-sm-7">
                                                <input type="text" className="form-control" id="graphicsfilelink" placeholder="Link of the files" />
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <label className="modal-subheading">Task Progress</label>
                                        <span style={{display: 'flex', flexDirection: 'column'}}>
                                            <span className="flex-space-between"><span>Mark as In Progress</span><span><input className="form-check-input" type="checkbox" value="" id="inProgressCheck" /></span></span>
                                            <span className="flex-space-between"><span>Mark as Complete!</span><span><input className="form-check-input" type="checkbox" value="" id="completeCheck" /></span></span>
                                            <span className="flex-space-between"><span>Mark as Not Applicable!</span><span><input className="form-check-input" type="checkbox" value="" id="naCheck" /></span></span>
                                        </span>
                                    </div>

                                    <div>
                                        <label className="modal-subheading">Messages and Comments</label>
                                        <span style={{display: 'flex', flexDirection: 'column'}} className="task-messages-popup">
                                        </span>
                                        <div class="form-group" id="graphicsnewcomment-wrapper" style={{display: "none"}}>
                                            <label for="graphicsnewcomment" className="comments-popup-label">New Comment</label>
                                            <textarea class="form-control" id="graphicsnewcomment" rows="3"></textarea>
                                            
                                        </div>
                                        <button type="button" className="btn btn-sm btn-success submit-comment-btn" id="submitgraphicscomment-btn" style={{display: "none"}} data-dept="graphics" data-text-id="graphicsnewcomment">Add</button>
                                        <button type="button" style={{float: "right"}} className='btn btn-sm btn-link add-comment-btn' data-wrapper-id="graphicsnewcomment-wrapper" data-btn-id="submitgraphicscomment-btn">Add Comment</button>
                                    </div>

                                </div>
                                <div className="modal-footer" style={{marginTop: '55px'}}>
                                    <button type="button" className="btn btn-secondary close-modal" data-bs-dismiss="modal">Cancel</button>
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </div>
                                </form>
                                </div>
                            </div>
                        </div>

                        <div className="modal fade" id="dataTaskModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Data Department</h5>
                                    <button type="button" className="btn-close close-modal" data-bs-dismiss="modal" aria-label="Close">X</button>
                                </div>
                                <form id="data-task-update-form">
                                
                               <div className="modal-body">
                                    <div>
                                        <label className="modal-subheading" style={{marginTop: '10px'}}>Task Resorces</label>
                                        <div id="task-resources" className="task-resources">
                                        </div>
                                    </div>

                                    <div>
                                        <label className="modal-subheading">Product Extensions</label>
                                        <span style={{display: 'flex', flexDirection: 'column'}}>
                                            <span className="flex-space-between"><span>Validty Period was extended</span><span><input className="form-check-input" type="checkbox" value="" id="validityPeriodCheck" /></span></span>
                                        </span>
                                    </div>
                                    <div>
                                        <label className="modal-subheading">Rate Change</label>
                                        <span style={{display: 'flex', flexDirection: 'column'}}>
                                            <span className="flex-space-between"><span>Rate Change</span><span><input className="form-check-input" type="checkbox" value="" id="rateChangeCheck" /></span></span>
                                        </span>
                                    </div>
                                    <div>
                                        <label className="modal-subheading">Validity Change</label>
                                        <span style={{display: 'flex', flexDirection: 'column'}}>
                                            <span className="flex-space-between"><span>Validity Change</span><span><input className="form-check-input" type="checkbox" value="" id="validityChangeCheck" /></span></span>
                                        </span>
                                    </div>
                                    <div>
                                        <label className="modal-subheading">Task Submissions</label>
                                        <div className="form-group row">
                                            <label for="datagraphicsfilelink" className="col-sm-5 col-form-label">Graphic Files Submission (Files / Folder)</label>
                                            <div className="col-sm-7">
                                                <a href="/" target="_blank" className="datagraphicsfilelink form-control" style={{backgroundColor: '#dadae7'}}>link</a>
                                                <input type="hidden" className="form-control" id="datagraphicsfilelink" placeholder="Link of the files" />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label for="datacontentfilelink" className="col-sm-5 col-form-label">Content Files / Folders</label>
                                            <div className="col-sm-7">
                                                <a href="/" target="_blank" className="datacontentfilelink form-control" style={{backgroundColor: '#dadae7'}}>link</a>
                                                <input type="hidden" className="form-control" id="datacontentfilelink" placeholder="Link of the files" />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label for="datatranslationfilelink" className="col-sm-5 col-form-label">Translation Files / Folders</label>
                                            <div className="col-sm-7">
                                                <a href="/" target="_blank" className="datatranslationfilelink form-control" style={{backgroundColor: '#dadae7'}}>link</a>
                                                <input type="hidden" className="form-control" id="datatranslationfilelink" placeholder="Link of the files" />
                                            </div>
                                        </div>
                                    </div>
                                    

                                    <div>
                                        <label className="modal-subheading">Task Progress</label>
                                        <span style={{display: 'flex', flexDirection: 'column'}}>
                                            <span className="flex-space-between"><span>Mark as In Progress</span><span><input className="form-check-input" type="checkbox" value="" id="datainProgressCheck" /></span></span>
                                            <span className="flex-space-between"><span>Mark as Complete!</span><span><input className="form-check-input" type="checkbox" value="" id="datacompleteCheck" /></span></span>
                                            <span className="flex-space-between"><span>Mark as Not Applicable!</span><span><input className="form-check-input" type="checkbox" value="" id="datanaCheck" /></span></span>
                                        </span>
                                    </div>

                                    <div>
                                        <label className="modal-subheading">Messages and Comments</label>
                                        <span style={{display: 'flex', flexDirection: 'column'}} className="task-messages-popup">
                                        </span>
                                        <div class="form-group" id="datanewcomment-wrapper" style={{display: "none"}}>
                                            <label for="datanewcomment" className="comments-popup-label">New Comment</label>
                                            <textarea class="form-control" id="datanewcomment" rows="3"></textarea>
                                        </div>
                                        <button type="button" className="btn btn-sm btn-success submit-comment-btn" id="submitdatacomment-btn" style={{display: "none"}} data-dept="data" data-text-id="datanewcomment">Add</button>
                                        <button type="button" style={{float: "right"}} className='btn btn-sm btn-link add-comment-btn' data-wrapper-id="datanewcomment-wrapper" data-btn-id="submitdatacomment-btn">Add Comment</button>
                                    </div>

                                </div>
                                <div className="modal-footer" style={{marginTop: '55px'}}>
                                    <button type="button" className="btn btn-secondary close-modal" data-bs-dismiss="modal">Cancel</button>
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </div>
                                </form>
                                </div>
                            </div>
                        </div>

                        <div className="modal fade" id="contentTaskModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Content Department</h5>
                                    <button type="button" className="btn-close close-modal" data-bs-dismiss="modal" aria-label="Close">X</button>
                                </div>
                                <form id="content-task-update-form">
                                
                                <div className="modal-body">
                                    <div>
                                        <label className="modal-subheading" style={{marginTop: '10px'}}>Task Resorces</label>
                                        <div id="task-resources" className="task-resources">
                                        </div>
                                    </div>

                                    <div>
                                        <label className="modal-subheading">Content Task Checklist</label>
                                        <span style={{display: 'flex', flexDirection: 'column'}}>
                                            <span className="flex-space-between"><span>Package Summary</span><span><input className="form-check-input" type="checkbox" value="" id="packageSummaryCheck" /></span></span>
                                            <span className="flex-space-between"><span>Package Conditions</span><span><input className="form-check-input" type="checkbox" value="" id="packageconditionsCheck" /></span></span>
                                            <span className="flex-space-between"><span>Package Inclusions</span><span><input className="form-check-input" type="checkbox" value="" id="packageInclusionsCheck" /></span></span>
                                            <span className="flex-space-between"><span>Additioanl content updates</span><span><input className="form-check-input" type="checkbox" value="" id="additionalContentCheck" /></span></span>
                                            <span className="flex-space-between"><span>Translation of all updates</span><span><input className="form-check-input" type="checkbox" value="" id="translationUpdatesCheck" /></span></span>
                                        </span>
                                    </div>
                                    <div>
                                        <label className="modal-subheading">Task Submissions</label>
                                        <div className="form-group row">
                                            <label for="graphicsfilelink" className="col-sm-5 col-form-label">Content Files / Folders</label>
                                            <div className="col-sm-7">
                                                <input type="text" className="form-control" id="contentfilelink" placeholder="Link of the files" />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label for="graphicsfilelink" className="col-sm-5 col-form-label">Content Support files</label>
                                            <div className="col-sm-7">
                                                <input type="text" className="form-control" id="contentsupportfilelink" placeholder="Link of the files" />
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <label className="modal-subheading">Task Progress</label>
                                        <span style={{display: 'flex', flexDirection: 'column'}}>
                                            <span className="flex-space-between"><span>Mark as In Progress</span><span><input className="form-check-input" type="checkbox" value="" id="contentinProgressCheck" /></span></span>
                                            <span className="flex-space-between"><span>Mark as Complete!</span><span><input className="form-check-input" type="checkbox" value="" id="contentcompleteCheck" /></span></span>
                                            <span className="flex-space-between"><span>Mark as Not Applicable!</span><span><input className="form-check-input" type="checkbox" value="" id="contentnaCheck" /></span></span>
                                        </span>
                                    </div>

                                    <div>
                                        <label className="modal-subheading">Messages and Comments</label>
                                        <span style={{display: 'flex', flexDirection: 'column'}} className="task-messages-popup">
                                        </span>
                                        <div class="form-group" id="contentnewcomment-wrapper" style={{display: "none"}}>
                                            <label for="contentnewcomment" className="comments-popup-label">New Comment</label>
                                            <textarea class="form-control" id="contentnewcomment" rows="3"></textarea>
                                        </div>
                                        <button type="button" className="btn btn-sm btn-success submit-comment-btn" id="submitcontentcomment-btn" style={{display: "none"}} data-dept="content" data-text-id="contentnewcomment">Add</button>
                                        <button type="button" style={{float: "right"}} className='btn btn-sm btn-link add-comment-btn' data-wrapper-id="contentnewcomment-wrapper" data-btn-id="submitcontentcomment-btn">Add Comment</button>
                                    </div>

                                </div>
                                <div className="modal-footer" style={{marginTop: '55px'}}>
                                    <button type="button" className="btn btn-secondary close-modal" data-bs-dismiss="modal">Cancel</button>
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </div>
                                </form>
                                </div>
                            </div>
                        </div>

                        <div className="modal fade" id="qaTaskModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Quality Assurance Department</h5>
                                    <button type="button" className="btn-close close-modal" data-bs-dismiss="modal" aria-label="Close">X</button>
                                </div>
                                <form id="qa-task-update-form">
                                
                                <div className="modal-body">
                                    <div>
                                        <label className="modal-subheading" style={{marginTop: '10px'}}>Task Resorces</label>
                                        <div id="task-resources" className="task-resources">
                                        </div>
                                    </div>

                                    <div>
                                        <label className="modal-subheading">Product Extentsions</label>
                                        <span style={{display: 'flex', flexDirection: 'column'}}>
                                            <span className="flex-space-between"><span>Calendar extend</span><span><input className="form-check-input" type="checkbox" value="" id="qaCalenderVerifiedCheck" /></span></span>
                                            <span className="flex-space-between"><span>Same rate extension</span><span><input className="form-check-input" type="checkbox" value="" id="qaRateExtensionCheck" /></span></span>
                                            <span className="flex-space-between"><span>Min night for the extended period</span><span><input className="form-check-input" type="checkbox" value="" id="qaMinNightsExtensionCheck" /></span></span>
                                        </span>
                                    </div>

                                    <div>
                                        <label className="modal-subheading">Rate Change</label>
                                        <span style={{display: 'flex', flexDirection: 'column'}}>
                                            <span className="flex-space-between"><span>Rates Calculations</span><span><input className="form-check-input" type="checkbox" value="" id="qaRatesCalculationsCheck" /></span></span>
                                            <span className="flex-space-between"><span>Rates Checked & Correct</span><span><input className="form-check-input" type="checkbox" value="" id="qaRatesCheck" /></span></span>
                                            <span className="flex-space-between"><span>Attributes List is Complete & Correct</span><span><input className="form-check-input" type="checkbox" value="" id="qaAttributeListCheck" /></span></span>
                                        </span>
                                    </div>

                                     <div>
                                        <label className="modal-subheading">Validity Change</label>
                                        <span style={{display: 'flex', flexDirection: 'column'}}>
                                            <span className="flex-space-between"><span>Calendar loaded as per the validity date</span><span><input className="form-check-input" type="checkbox" value="" id="qaValidityChangeCheck" /></span></span>
                                            <span className="flex-space-between"><span>Validity date updated in text</span><span><input className="form-check-input" type="checkbox" value="" id="qaValidityTextChangeCheck" /></span></span>
                                        </span>
                                    </div>

                                    <div>
                                        <label className="modal-subheading">Min nights</label>
                                        <span style={{display: 'flex', flexDirection: 'column'}}>
                                            <span className="flex-space-between"><span>Min nights applied in calendar</span><span><input className="form-check-input" type="checkbox" value="" id="qaMinNightsChangeCheck" /></span></span>
                                        </span>
                                    </div>

                                    <div>
                                        <label className="modal-subheading">Content Change / Update</label>
                                        <span style={{display: 'flex', flexDirection: 'column'}}>
                                            <span className="flex-space-between"><span>Only Flash offers / any Important Note added in text</span><span><input className="form-check-input" type="checkbox" value="" id="qaContentChangeCheck" /></span></span>
                                        </span>
                                    </div>

                                    <div>
                                        <label className="modal-subheading">Graphic Update</label>
                                        <span style={{display: 'flex', flexDirection: 'column'}}>
                                            <span className="flex-space-between"><span>Graphic Update</span><span><input className="form-check-input" type="checkbox" value="" id="qaGraphicUpdateCheck" /></span></span>
                                        </span>
                                    </div>

                                    <div>
                                        <label className="modal-subheading">Task Submissions</label>
                                        <div className="form-group row">
                                            <label for="graphicsfilelink" className="col-sm-5 col-form-label">Graphic Files Submission (Files / Folder)</label>
                                            <div className="col-sm-7">
                                                <a href="/" target="_blank" className="qagraphicsfilelink form-control" style={{backgroundColor: '#dadae7'}}>link</a>
                                                <input type="hidden" className="form-control" id="qagraphicsfilelink" placeholder="Link of the files" />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label for="graphicsfilelink" className="col-sm-5 col-form-label">Content Files / Folders</label>
                                            <div className="col-sm-7">
                                                <a href="/" target="_blank" className="qacontentfilelink form-control" style={{backgroundColor: '#dadae7'}}>link</a>
                                                <input type="hidden" className="form-control" id="qacontentfilelink" placeholder="Link of the files" />
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <label className="modal-subheading">Task Progress</label>
                                        <span style={{display: 'flex', flexDirection: 'column'}}>
                                            <span className="flex-space-between"><span>Mark as In Progress</span><span><input className="form-check-input" type="checkbox" value="" id="qainProgressCheck" /></span></span>
                                            <span className="flex-space-between"><span>Mark as Complete!</span><span><input className="form-check-input" type="checkbox" value="" id="qacompleteCheck" /></span></span>
                                            <span className="flex-space-between"><span>Mark as Not Applicable!</span><span><input className="form-check-input" type="checkbox" value="" id="qanaCheck" /></span></span>
                                        </span>
                                    </div>

                                    <div>
                                        <label className="modal-subheading">Messages and Comments</label>
                                        <span style={{display: 'flex', flexDirection: 'column'}} className="task-messages-popup">
                                        </span>
                                        <div class="form-group" id="qanewcomment-wrapper" style={{display: "none"}}>
                                            <label for="qanewcomment" className="comments-popup-label">New Comment</label>
                                            <textarea class="form-control" id="qanewcomment" rows="3"></textarea>
                                        </div>
                                        <button type="button" className="btn btn-sm btn-success submit-comment-btn" id="submitqacomment-btn" style={{display: "none"}} data-dept="Quality Assurance" data-text-id="qanewcomment">Add</button>
                                        <button type="button" style={{float: "right"}} className='btn btn-sm btn-link add-comment-btn' data-wrapper-id="qanewcomment-wrapper" data-btn-id="submitqacomment-btn">Add Comment</button>
                                    </div>

                                </div>
                                <div className="modal-footer" style={{marginTop: '55px'}}>
                                    <button type="button" className="btn btn-secondary close-modal" data-bs-dismiss="modal">Cancel</button>
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </div>
                                </form>
                                </div>
                            </div>
                        </div>

                        <div className="modal fade" id="viewTaskModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">View Task</h5>
                                    <button type="button" className="btn-close close-modal" data-bs-dismiss="modal" aria-label="Close">X</button>
                                </div>
                                
                                <div className="modal-body">
                                    <div>
                                        <label className="modal-subheading" style={{marginTop: '0px', backgroundColor: "#d1e5f9"}}>Task Supplier And Product</label>
                                    </div>
                                    <div className="row">
                                        <div className="col-4 bold-text">Company</div>
                                        <div id="view-task-company" className="col-8"></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-4 bold-text">Supplier</div>
                                        <div id="view-task-supplier" className="col-8"></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-4 bold-text">Product</div>
                                        <div id="view-task-product" className="col-8"></div>
                                    </div>

                                    <div>
                                        <label className="modal-subheading" style={{marginTop: '10px', backgroundColor: "#d1e5f9"}}>Task Details</label>
                                    </div>

                                    <div className="row">
                                        <div className="col-4 bold-text">Recieved On</div>
                                        <div id="view-task-received" className="col-8"></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-4 bold-text">Priority</div>
                                        <div id="view-task-priority" className="col-8"></div>
                                    </div>

                                    <div>
                                        <div className="row" style={{borderBottom: "1px solid black", marginBottom: '10px', marginTop: '10px', backgroundColor: "#d1e5f9", paddingTop: '5px'}}>
                                            <div className="col-6" style={{fontWeight: "bold"}}>Task Type</div>
                                            <div className="col-6">Responsible Teams</div>
                                        </div>
                                        <div id="view-task-type"></div>
                                    </div>

                                    <div>
                                        <label className="modal-subheading" style={{marginTop: '10px', backgroundColor: "#d1e5f9"}}>All Related Departments</label>
                                        <div className="row" id="view-task-departments" style={{paddingLeft: '20px', paddingRight: '20px'}}></div>
                                    </div>

                                    <div>
                                        <label className="modal-subheading" style={{marginTop: '20px', backgroundColor: "#d1e5f9"}}>Task Resources</label>
                                        <div className="row" id="view-task-resources"></div>
                                    </div>

                                    <div>
                                        <label className="modal-subheading" style={{marginTop: '20px', backgroundColor: "#d1e5f9"}}>Related Messages & Comments</label>
                                        <div className="task-messages-popup" style={{display: 'flex', flexDirection: 'column'}} id="view-task-messages"></div>
                                    </div>
                                   
                                </div>
                                <div className="modal-footer" style={{marginTop: '55px'}}>
                                    <button type="button" className="btn btn-secondary close-modal" data-bs-dismiss="modal">Cancel</button>
                                </div>
                                </div>
                            </div>
                        </div>
                        
                        {/* add html bootstrap large modal */}
                        {this.state.showSupplierDetails && 
                            <SupplierDetails label={this.state.supplierSearchProd.label} id={this.state.supplierSearchProd.value} show={this.state.showSupplierDetails} closeModal={e => this.closeSupplierDetails(e)} />
                        }
                    </div>
                </div>
            </>
        );
    }
}