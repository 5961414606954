import axios from "axios";
import { uniq } from 'lodash';

const API_URL = "https://deals.mu/scripts/reportsapi/expiryreports-new.php";

class ExpiryReportService {

    getIssues(status, age, categories, taskstatus, searchReminder, user_email) {
       return this.getResults(0, status, age, categories, taskstatus, searchReminder, user_email)
            .then(async (response) => {

                // console.log({response});

                var result = {
                    data: []
                };
                let promises = [];
                var size = parseFloat(response.data);

                var loopSize = Math.ceil(parseFloat(size / 25));

                for (var i = 1; i <= loopSize; i++) {

                    promises.push(
                        await this.getResults(i, status, age, categories, taskstatus, searchReminder, user_email)
                            .then(response1 => {

                                result.data = result.data.concat(response1.data);
                            })
                            .catch(error1 => {
                                console.log({ error1 });
                            })
                    );
                }

                return Promise.all(promises).then(() => {
                    console.log({result});
                    return result;
                });

            }).catch(error => {

                return false;
            });
    }

    getResults(page, status, age, categories, taskstatus, searchReminder, user_email) {
        var params = {
            action: 'get_all',
            page: page,
            status: status,
            age: age,
            categories: categories,
            taskstatus: taskstatus,
            user_email: user_email,
            searchReminder: searchReminder
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

}

export default new ExpiryReportService();