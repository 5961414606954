import axios from "axios";

const API_URL = "https://deals.mu/scripts/reportsapi/uploadedinvoices.php";

class UploadedInvoicesService {

    compare( a, b ) {
        if ( parseInt(a[0]) < parseInt(b[0]) ){
            return 1;
        }
        if ( parseInt(a[0]) > parseInt(b[0]) ){
            return -1;
        }
        return 0;
    }

    getAll() {
       return this.getResults(0)
            .then(response => {

                // console.log({response});

                var result = {
                    data: []
                };
                let promises = [];
                var size = parseFloat(response.data);

                var loopSize = Math.ceil(parseFloat(size / 50));
                if(loopSize<1) {
                    loopSize = 1;
                }
                for (var i = 1; i <= loopSize; i++) {

                    promises.push(
                        this.getResults(i)
                            .then(response1 => {

                                result.data = result.data.concat(response1.data);
                            })
                            .catch(error1 => {
                                console.log({ error1 });
                            })
                    );
                }

                return Promise.all(promises).then(() => {
                    console.log({result});
                    result.data.sort( this.compare );
                    return result;
                });

            }).catch(error => {

                return false;
            });
    }

    getResults(page) {
        var params = {
            action: 'get_all',
            page: page,
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }
}

export default new UploadedInvoicesService();