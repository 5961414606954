import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import swal from 'sweetalert';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import AuthService from '../services/auth/auth.service';
import Pagination from './pagination.component';
import UtilityService from '../services/utility.service';
import TransactionRow from './transactions/transactionRow.component';

const APIURL = 'https://deals.mu/scripts/reportsapi/transactions-sheet.php';

export default function TransactionSheet() {

    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [allowed, setAllowed] = useState(false);
    const [username, setUsername] = useState('');
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(30);
    const [total, setTotal] = useState(0);
    const [startDateSelected, setStartDateSelected] = useState(null);
    const [endDateSelected, setEndDateSelected] = useState(null);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [selectedAgent, setSelectedAgent] = useState({value: '0', label: 'All Agents'});
    const [allAgents, setAllAgents] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState({value: '0', label: 'All Status'});
    const [allStatus, setAllStatus] = useState([
        {value: '0', label: 'All Status'},
        {value: 'Completed', label: 'Completed'},
        {value: 'Follow-up', label: 'Follow-up'},
        {value: 'Issue', label: 'Issue'},
        {value: 'Credit', label: 'Credit'},
        {value: 'Refund', label: 'Refund'},
        {value: 'Part Payment', label: 'Part Payment'},
        {value: 'Amount for Accounting', label: 'Amount for Accounting'}
    ]);
    const [searchBtnText, setSearchBtnText] = useState('Search');
    const [searchBtnDisabled, setSearchBtnDisabled] = useState(false);
    const [showAddTransactions, setShowAddTransactions] = useState(false);
    const [pastedData, setPastedData] = useState('');
    const [saveBtnText, setSaveBtnText] = useState("Save");
    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [excelData, setExcelData] = useState([]);
    const [exportBtnText, setExportBtnText] = useState("Export");
    const [exportBtnDisabled, setExportBtnDisabled] = useState(false);
    

    useEffect(() => {
        AuthService.checklogin().then(user => {
            setUsername(user.user_nicename);
            setUser(user);

            AuthService.getUserPermisions(user)
            .then(response => {
                var userpermissions = response.data;
                // console.log({userpermissions});
                    if(userpermissions.includes('transactions') || userpermissions.includes('all')) {
                    setAllowed(true);
                    searchTransactions();
                    fetchAgents();
                } else {
                    setAllowed(false);
                }
            })

            // console.log({user});
        }).catch(error => {
            window.location.href = '/login';
        });
    }, []);


    const changePage = (page) => {
        setPage(prev => page);
        fetchList(page);
    }

    const updateDateRange = (date) => {
        setDateRange(date);
        // console.log("date", date);
        setStartDateSelected(date[0]);
        setEndDateSelected(date[1]);
    }

    const fetchAgents = () => {
        var params = {
            action: 'get_agents'
        }
        axios.post(APIURL, {params})
        .then(response => {
            // console.log("agents", response.data);
            setAllAgents(response.data);
        })
    }


    const fetchList = (curPage) => {
        setLoading(true);
        setTransactions([]);
        if(startDateSelected!=null && endDateSelected!=null) {
            var start = UtilityService.format_date_time(startDateSelected);
            var end = UtilityService.format_date_time(endDateSelected);
        }
        var params = {
            page: curPage,
            limit: limit,
            search: search,
            action: "get_all",
            start_date: start,
            end_date: end,
            agent: selectedAgent.value,
            status: selectedStatus.value
        }

        axios.post(APIURL, {params})
        .then(response => {
            setTransactions(response.data);
            setLoading(false);
            setSearchBtnText('Search');
            setSearchBtnDisabled(false);
            console.log("transactions", response.data);
        })
    }
    
    const searchTransactions = () => {
        if(startDateSelected!=null && endDateSelected!=null) {
            var start = UtilityService.format_date_time(startDateSelected);
            var end = UtilityService.format_date_time(endDateSelected);
        }
        setSearchBtnText('Searching...');
        setSearchBtnDisabled(true);
         var params = {
            page: 0,
            limit: limit,
            search: search,
            action: "get_all",
            start_date: start,
            end_date: end,
            agent: selectedAgent.value,
            status: selectedStatus.value
        }
        axios.post(APIURL, {params})
        .then(response => {
            console.log("total", response.data);
            setTotal(response.data);
            fetchList(1);
        });
    }

    const openTransactionArea = (e) => {
        setShowAddTransactions(true);
    }

    const hideAddTransaction = () => {
        setPastedData("");
        setShowAddTransactions(false);
    }

    const parseText = (inputText) => {
        // Split the input into rows based on new lines
        const rows = inputText.trim().split('\n');

        // Split each row into columns based on tabs
        const parsed = rows.map((row) => row.trim().split('\t'));

        // Save the parsed data
        // setParsedData(parsed);
        return parsed;
    };

    const submitTransactions = (e) => {
        e.preventDefault();
        var parsed = parseText(pastedData);
        // console.log("parsed", parsed);
        // return;
        if(parsed.length==0) {
            swal("Error", "No data to save", "error");
            return;
        }

        setSaveBtnText("Saving...");
        setSaveBtnDisabled(true);

        console.log("parsed", parsed);
        var params = {
            action: 'add_transactions',
            entries: parsed,
            username: user.user_nicename
        }
        axios.post(APIURL, {params})
        .then(response => {
            // console.log("response", response);
            // swal("Success", "Transactions saved successfully. please wait while we reload the page", "success");
            searchTransactions();
            hideAddTransaction();
            setSaveBtnText("Save");
            setSaveBtnDisabled(false);
            setPastedData("");
        });
    }

    const exportExcel = (e) => {
        // create array of promises to export data from all pages
        var promises = [];
        setExportBtnText("Exporting...");
        setExportBtnDisabled(true);
        for (var i = 1; i <= Math.ceil(total/limit); i++) {
            var params = {
                page: i,
                limit: limit,
                search: search,
                action: "get_all",
                start_date: startDateSelected,
                end_date: endDateSelected,
                agent: selectedAgent.value,
                status: selectedStatus.value
            }
            promises.push(axios.post(APIURL, {params}));
        }

        // resolve all promises
        Promise.all(promises)
        .then(results => {
            var exdata = [];
            results.forEach(result => {
                exdata = exdata.concat(result.data);
            });

            for (var i = 0; i < exdata.length; i++) {
                delete exdata[i].bgclass;
                 exdata[i].status = exdata[i].status.value;
            }

            // sort in ascending order based on id
            exdata.sort((a, b) => (parseInt(a.id) > parseInt(b.id)) ? 1 : -1);

            // console.log("data", data);
            setExcelData(exdata);
            setExportBtnText('Export');
            setExportBtnDisabled(false);

            if(exdata.length>0) {
                const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                const fileExtension = '.xlsx';
                const ws = XLSX.utils.json_to_sheet(exdata);
                const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const data = new Blob([excelBuffer], {type: fileType});
                FileSaver.saveAs(data, "transaction_export"+Math.random() + fileExtension);
            } else {
                swal("No Data", "No data found for selected filters", "error");
            }
        })
        .catch(error => {
            console.log(error);
        });
    }

    return (
        <>
            <div className="row mb-20">
                <div className="col-md-12">
                    <div className="flex justify-between align-center">
                        <h1 className='orders-h1'>Transactions Sheet</h1>
                        <div className='flex align-center'>
                            <DatePicker
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            value={startDate && endDate ? `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}` : ''}
                            dateFormat="dd-MM-yyyy"
                            // minDate={startDate}
                            // maxDate={new Date()}
                            onChange={(date) => updateDateRange(date)}
                            placeholderText="Select Date Range"
                            shouldCloseOnSelect={!(!startDate || Boolean(startDate && endDate))}
                            className="form-control"
                            />
                            <div style={{width: "200px"}} className='ml-10'>
                                <Select
                                    value={selectedStatus}
                                    onChange={(status) => setSelectedStatus(status)}
                                    options={allStatus} 
                                    id="status"
                                    placeholder="Select a Status"
                                />
                            </div>
                            <div style={{width: "200px"}} className='ml-10'>
                                <Select
                                    value={selectedAgent}
                                    onChange={(agent) => setSelectedAgent(agent)}
                                    options={allAgents} 
                                    id="agents"
                                    placeholder="Select an Agent"
                                />
                            </div>
                            <input type="text" className="form-control ml-10" placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)} style={{width: "200px"}} />
                            <button className="btn btn-success ml-10" disabled={searchBtnDisabled} onClick={e => searchTransactions()}>{searchBtnText}</button>
                            <button className="btn btn-primary ml-10" onClick={e=>openTransactionArea(e)}>Add Transaction</button>
                            {transactions.length>0 && 
                            <button className="btn btn-warning ml-10" onClick={e=>exportExcel(e)}>{exportBtnText}</button>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    {showAddTransactions && 
                    <div className="flex-column justify-between align-center mb-10">
                        <textarea value={pastedData} onChange={e=>setPastedData(e.target.value)} className="form-control" placeholder="Paste Data Here" rows={10}></textarea>
                        <div className='flex mt-10' style={{justifyContent: "flex-end", width: "100%"}}>
                            <button className="btn btn-danger" onClick={e=>hideAddTransaction()}>Cancel</button>
                            <button className="btn btn-success ml-10" onClick={e=>submitTransactions(e)} disabled={saveBtnDisabled}>{saveBtnText}</button>
                        </div>
                        <hr />
                    </div>
                    }
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <table className="table table-striped table-bordered dark-border-table">
                        <thead>
                            <tr>
                                <th scope="col">Account</th>
                                <th scope="col">Date</th>
                                <th scope="col">Reference</th>
                                <th scope="col">Transaction</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Agent</th>
                                <th scope="col">Orders ID(s)</th>
                                <th scope="col">Status</th>
                                <th scope="col">Comments</th>
                            </tr>
                        </thead>
                        <tbody>
                            {transactions.length>0 && transactions.map((transaction, index) => (
                                <TransactionRow key={index} row={transaction} index={index} username={username} allStatus={allStatus} />
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div className="flex justify-center align-center">
                        <Pagination current={page} limit={limit} total={total} onPageChange={(page)=>changePage(page)} />
                    </div>
                </div>
            </div>
        </>
    );
}
