import axios from "axios";
// import { uniq } from 'lodash';
// import * as firebase from 'firebase-admin';

const API_URL = "https://deals.mu/scripts/reportsapi/notifications.php";
// import * as serviceAccount from "./dealsapp-55cfa-firebase-adminsdk-5un64-e6ba56df1f.json";

class AppNotificationsService {
    getNotifications() {
        return this.getResults(0)
            .then(response => {

                var result = {
                    data: []
                };
                let promises = [];
                var size = parseFloat(response.data);

                var loopSize = Math.ceil(parseFloat(size / 15));

                for (var i = 1; i <= loopSize; i++) {

                    promises.push(
                        this.getResults(i)
                            .then(response1 => {

                                result.data = result.data.concat(response1.data);
                            })
                            .catch(error1 => {
                                console.log({ error1 });
                            })
                    );
                }

                return Promise.all(promises).then(() => {
                    // console.log({result});
                    return result;
                });

            }).catch(error => {

                return false;
            });
    }

    getResults(page) {
        var params = {
            action: 'notifications',
            page: page,
        };
        return axios
            .post(API_URL + '?key=' + Math.random(), { params })
            .then((response) => {
                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

    saveNotification(title, body, topic, image, url) {
        var params = {
            title: title,
            body: body,
            topic: topic,
            image: image,
            url: url,
            action: 'savenotification'
        };
        return axios
            .post(API_URL + '?key=' + Math.random(), { params })
            .then(response => {
                return response;
            })
            .catch(error => {
                return false;
            });
    }

    // sendtofirebase(title, body, topic, image, url) {
    //     var serviceAccount = require("../../dealsapp-55cfa-firebase-adminsdk-5un64-e6ba56df1f.json");

    //     firebase.initializeApp({
    //         credential: firebase.credential.cert(serviceAccount),
    //         databaseURL: "https://dealsapp-55cfa.firebaseio.com"
    //     });

    //     var registrationToken = "cEv7fiGRQNeR3dhEortlg3:APA91bGOq5md7dO2jTBnQO5DDTrMOlivpP-ehtxIEUCWqL4gRATI6lwUOkXMbLYK7J1hayMLnZCS17srSlg2di0JAYxzQTdX4FPBpl6GdDoeomE6s7BvCV-tIiV962KupRgeEdNl6PBG";

    //     var payload = {
    //         notification: {
    //             title: title,
    //             body: body
    //         },
    //         data: {
    //             url: url,
    //         }
    //     };

    //     var options = {
    //         priority: "high",
    //         timeToLive: 60 * 60 * 24
    //     };

    //     return firebase.messaging().sendToDevice(registrationToken, payload, options)
    //         .then(function (response) {
    //             console.log("Successfully sent message:", JSON.stringify(response));
    //             return response;
    //         })
    //         .catch(function (error) {
    //             console.log("Error sending message:", JSON.stringify(error));
    //             return error;
    //         });
    // }

}

export default new AppNotificationsService();