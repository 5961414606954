import React, { Component } from "react";
import _ from "lodash";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk, faEdit } from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CmsService from "../../services/erp/cms.service";

export default class EditSlide extends Component {
    constructor(props) {
        super(props);
        this.state = {
            slide: props.image,
            open: false,
            username: props.username,
            updateBtnDisabled: false,
            updateBtnText: "Update"
        }
    }

    openEditForm = (e) => {
        this.setState({
            open: true
        });
    } 

    handleClose = (e) => {
        this.setState({
            open: false
        });
    }

    updateSlide = (e) => {
        e.preventDefault();
        console.log("submitted!");

        this.setState({
            updateBtnDisabled: true,
            updateBtnText: "Updating..."
        });

        CmsService.updateSlide(this.state.slide.id, this.state.slide.image_alt, this.state.slide.album, this.state.slide.url, this.state.slide.link, this.state.slide.sorting, this.state.username)
        .then(res => {
            console.log({res});
            swal("Updated!", "Slide has been Updated", "success");
            this.setState({
                updateBtnDisabled: false,
                updateBtnText: "Update"
            });
            this.props.onChange(this.state.slide.album);
        })
        .catch(error => {
            console.log({error});
        });
    }

    inputChange = (e, key) => {
        var imgTemp = this.state.slide;
        imgTemp[key] = e.target.value;

        this.setState({
            slide: imgTemp
        });
    }

    render() {
        const style = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 700,
            height: "100%",
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            display: "block",
            overflow:'scroll',
        };

        return (
            <>
                <button className="btn btn-sm btn-warning" onClick={e => this.openEditForm(e)}><FontAwesomeIcon icon={faEdit} /> Edit</button>
                <Modal
                    open={this.state.open}
                    onClose={(e)=>this.handleClose(e)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{textDecoration: "underline"}}>
                        Edit Slide:
                    </Typography>
                    <br />
                    <p><b>Slide Image:</b></p>
                    <p><img src={this.state.slide.image} style={{width: "100%", height: "auto"}} /></p>
                    <form onSubmit={e => this.updateSlide(e)}>
                        <p><b>Alternate Text <FontAwesomeIcon icon={faAsterisk} color="red" /> :</b></p>
                        <p><input className="form-control" value={this.state.slide.image_alt} onChange={e => this.inputChange(e, "image_alt")} required="required" /></p>

                        <p><b>Link Behind the Image <FontAwesomeIcon icon={faAsterisk} color="red" /> :</b></p>
                        <p><input className="form-control" value={this.state.slide.link} onChange={e => this.inputChange(e, "link")} required="required" /></p>

                        <p><b>Album <FontAwesomeIcon icon={faAsterisk} color="red" /> (to group Images):</b></p>
                        <p><input className="form-control" value={this.state.slide.album} onChange={e => this.inputChange(e, "album")} required="required" /></p>

                        <p><b>URL (where the image will be shown):</b></p>
                        <p><input className="form-control" value={this.state.slide.url} onChange={e => this.inputChange(e, "url")} /></p>

                        <p><b>Sort Number <FontAwesomeIcon icon={faAsterisk} color="red" />:</b></p>
                        <p><input className="form-control" value={this.state.slide.sorting} onChange={e => this.inputChange(e, "sorting")} required="required" /></p>

                        <hr />
                        <span style={{display: "flex", justifyContent: "center"}}>
                            <button className="btn btn-sm btn-success" type="submit" disabled={this.state.updateBtnDisabled}>{this.state.updateBtnText}</button>
                            <button className="btn btn-sm btn-danger" style={{marginLeft: "20px"}} onClick={e=>this.handleClose(e)}>Close</button>
                        </span>
                    </form>
                    </Box>
                </Modal>
            </>
        );
    }
}