import axios from "axios";
import { uniq } from 'lodash';

const API_URL = "https://deals.mu/scripts/reportsapi/smsmarketing.php";

class SmsService {

    getSmsAll(startDate, endDate, status, user_email) {
       return this.getResults(0, startDate, endDate, status, user_email)
            .then(response => {

                // console.log({response});

                var result = {
                    data: []
                };
                let promises = [];
                var size = parseFloat(response.data);

                var loopSize = Math.ceil(parseFloat(size / 50));

                for (var i = 1; i <= loopSize; i++) {

                    promises.push(
                        this.getResults(i, startDate, endDate, status, user_email)
                            .then(response1 => {

                                result.data = result.data.concat(response1.data);
                            })
                            .catch(error1 => {
                                console.log({ error1 });
                            })
                    );
                }

                return Promise.all(promises).then(() => {
                    // console.log({result});
                    return result;
                });

            }).catch(error => {

                return false;
            });
    }

    getResults(page, startDate, endDate, status, user_email) {
        var params = {
            action: 'get_all',
            page: page,
            startDate: startDate,
            endDate: endDate,
            status: status,
            user_email: user_email
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

    fetchAllNumbers(rank, region, customertype, gender, age, birthdayfrom, birthdayto, orderstart, orderend) {
        return this.fetchNumber(0, rank, region, customertype, gender, age, birthdayfrom, birthdayto, orderstart, orderend)
            .then(response => {

                console.log({response});

                var result = {
                    data: []
                };
                let promises = [];
                var size = parseFloat(response.data);

                var loopSize = Math.ceil(parseFloat(size / 200));

                for (var i = 1; i <= loopSize; i++) {

                    promises.push(
                        this.fetchNumber(i, rank, region, customertype, gender, age, birthdayfrom, birthdayto, orderstart, orderend)
                            .then(response1 => {

                                result.data = result.data.concat(response1.data);
                            })
                            .catch(error1 => {
                                console.log({ error1 });
                            })
                    );
                }

                return Promise.all(promises).then(() => {
                    // console.log({result});
                    return result;
                });

            }).catch(error => {

                return false;
            });
    }


    fetchNumber(page, rank, region, customertype, gender, age, birthdayfrom, birthdayto, orderstart, orderend) {
        var params= {
            action: 'fetch_numbers',
            rank: rank,
            region: region,
            customertype: customertype,
            gender: gender,
            age: age,
            birthdayfrom: birthdayfrom,
            birthdayto: birthdayto,
            orderstart: orderstart,
            orderend: orderend,
            page: page
        };

        return axios
            .post(API_URL, { params })
            .then((response) => {
                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }
    
    saveMessage(rank, region, customertype, gender, age, birthdayfrom, birthdayto, orderstart, orderend, phonenumbers, messageToSend, sendon, created_by) {
        var params= {
            action: 'save_message',
            rank: rank,
            region: region,
            customertype: customertype,
            gender: gender,
            age: age,
            birthdayfrom: birthdayfrom,
            birthdayto: birthdayto,
            orderstart: orderstart,
            orderend: orderend,
            phonenumbers: phonenumbers,
            messageToSend: messageToSend,
            sendon: sendon,
            created_by: created_by
        };

        return axios
            .post(API_URL, { params })
            .then((response) => {
                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }
}

export default new SmsService();