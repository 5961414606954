import React, { useEffect, useState } from 'react'
import { Modal, Box } from '@mui/material';
import axios from 'axios';
import swal from 'sweetalert';

const APP_URL = "https://deals.mu/scripts/reportsapi/ordersreport.php";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const UpdateProfileData = ({orderID, phone, email, changedata, agent}) => {

    const [phoneState, setPhoneState] = useState(phone);
    const [emailState, setEmailState] = useState(email);
    const [agentState, setAgentState] = useState(agent);
    const [open, setOpen] = useState(false);
    const [disableapplybtn, setDisableapplybtn] = useState(true);
    const [applybtntext, setApplybtntext] = useState("Update");
    
    useEffect(() => {
        if(phoneState !== '' && emailState !== '') {
            setDisableapplybtn(false);
        } else {
            setDisableapplybtn(true);
        }
    }, [phoneState, emailState]);

    // useEffect(() => {
    //     getFees();
    // }, [orderID]);

    const updateData = (e) => {
        e.preventDefault();
        setApplybtntext("Updating...");
        setDisableapplybtn(true);
        var params = {
            orderID: orderID,
            phone: phoneState,
            email: emailState,
            agent: agentState,
            action: "updateProfileData"
        };
        axios.post(APP_URL, {params})
        .then(response => {
            // console.log("add fee response", response);
            setApplybtntext("Update");
            setTimeout(() => {
                setApplybtntext("Update");
                setDisableapplybtn(false);
            }, 2000);
            setOpen(false);
            changedata();
            swal('Profile Data Updated', "The profile data has been updated", "success");
        })
        .catch(error => {
            console.log("update profile", error);
            setApplybtntext("Update");
            setTimeout(() => {
                setApplybtntext("Update");
                setDisableapplybtn(false);
            }, 2000);
        });
    }


  return (
    <div>
        <button type="button" onClick={e=>setOpen(true)} className="btn btn-sm btn-primary">Change Profile Data</button>
        
        <Modal
            open={open}
            onClose={e => {setOpen(false);}}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
        >
            <Box sx={{...style, width: 800, textAlign: 'center' }}>
            <h4 id="child-modal-title">Update Order Email & Phone</h4>
            <form onSubmit={(e) => updateData(e)} style={{textAlign: "left"}}>
                <div className="form-group">
                    <label for="email" className="col-form-label"><b>Email Address</b></label>
                    <div className="">
                        <input type="email" required="required" className="form-control" onChange={e => {setEmailState(e.target.value)}} id="email" placeholder="Enter email" value={emailState} />
                    </div>
                </div>
                <div className="form-group">
                    <label for="phone" className="col-form-label"><b>Phone Number</b></label>
                    <div className="">
                        <input type="number" required="required" className="form-control" onChange={e => {setPhoneState(e.target.value)}} id="phone" placeholder="Enter phone number" value={phoneState} />
                    </div>
                </div>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <button type="submit" className="btn btn-sm btn-success" style={{marginRight: "20px"}} disabled={disableapplybtn}>{applybtntext}</button><button type="button" className="btn btn-sm btn-danger" onClick={e => {setOpen(false);}}>Close</button>
                </div>
            </form>
            </Box>
        </Modal>
    </div>
  )
}

export default UpdateProfileData;