import React, { useEffect } from 'react';
import { useState } from 'react';
import DatePicker from "react-datepicker";

import AuthService from "../services/auth/auth.service";
import Pagination from "./pagination.component";
import refundsService from '../services/erp/refunds.service';
import "../styles/Loyaltypoints.css";
import LoyaltyPointsCommentPopup from './loyalty/viewcomments.component';
import { set } from 'lodash';
import swal from 'sweetalert';
import NoPermission from "./extras/nopermission.component";

export default function Loyaltypoints() {

    const [user, setUser] = useState(null);
    const [total, setTotal] = useState(0);
    const [current, setCurrent] = useState(1);
    const [limit, setLimit] = useState(10);
    const [coupons, setCoupons] = useState([]);
    const [page, setPage] = useState(0);
    const [allowed, setAllowed] = useState(false);
    const [search, setSearch] = useState('');
    const [createCouponCustomerName, setCreateCouponCustomerName] = useState('');
    const [createCouponCode, setCreateCouponCode] = useState('');
    const [createCouponPoints, setCreateCouponPoints] = useState('');
    const [createCouponExpiry, setCreateCouponExpiry] = useState('');
    const [createCouponIndex, setCreateCouponIndex] = useState(0);
    const [createCouponID, setCreateCouponID] = useState(0);
    const [createCouponEmail, setCreateCouponEmail] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [type, setType] = useState('current');
    const [permissions, setPermissions] = useState([]);
    const [orderStatus, setOrderStatus] = useState('All');

    useEffect(() => {
        AuthService.checklogin().then(user => {
            console.log({ user });
            setUser(user);
            AuthService.getUserPermisions(user)
            .then(response => {
                var userpermissions = response.data;
                setPermissions(userpermissions);
                console.log({userpermissions});
                    if(userpermissions.includes('loyaltyreports') || userpermissions.includes('all')) {
                    console.log('You are allowed!');
                    setAllowed(true);
                    refundsService.getLoyaltyCoupons(page, limit, '', type, orderStatus)
                    .then(response => {
                        console.log(response);
                        setTotal(response.data);
                        setCurrent(1);
                        setPage(1);
                        getResults(1);
                    }).catch(error => {
                        console.log({ error });
                    });
                } else {
                    console.log('You are not allowed!');
                    setAllowed(false);
                }
            })
        }).catch(error => {
            // console.log({ error });
            window.location.href = '/login';
        });
    }, []);

    useEffect(() => {
        setCoupons([]);
        refundsService.getLoyaltyCoupons(page, limit, '', type, orderStatus)
        .then(response => {
            console.log(response);
            setTotal(response.data);
            setCurrent(1);
            setPage(1);
            refundsService.getLoyaltyCoupons(0, limit, '', type, orderStatus)
            .then(response => {
                console.log(response);
                setTotal(response.data);
                setCurrent(1);
                setPage(1);
                getResults(1);
            }).catch(error => {
                console.log({ error });
            });
        }).catch(error => {
            console.log({ error });
        });
    }, [type]);

    useEffect(() => {
        if(search.length == 0) {
            getResults(1);
        }
    }, [search]);

    useEffect(() => {
        setCoupons([]);
        console.log("order status changed", orderStatus);
        refundsService.getLoyaltyCoupons(page, limit, '', type, orderStatus)
        .then(response => {
            console.log(response);
            setTotal(response.data);
            setCurrent(1);
            setPage(1);
            getResults(1);
        }).catch(error => {
            console.log({ error });
        });
    }, [orderStatus]);

    const getResults = (currentPage) => {
        refundsService.getLoyaltyCoupons(currentPage, limit, search, type, orderStatus)
        .then(response => {
            console.log({response});
            setCoupons(response.data);
        })
        .catch(error => {
            console.log({ error });
        });
    }

    const searchReport = (e) => {
        console.log("searching", e.target.value);
        setSearch(e.target.value);
        getResults(1);
    }

    const pageChanged = (newPage) => {
        console.log("page changed", newPage);
        setPage(newPage);
        setCurrent(newPage);
        getResults(newPage);
    }

    const resetCreteCouponForm = () => { 
        setCreateCouponCustomerName('');
        setCreateCouponCode('');
        setCreateCouponPoints('');
        setCreateCouponExpiry('');
        setCreateCouponIndex(0);
        setCreateCouponID(0);
    }

    const deleteCoupon = (coupon_id, id, index) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this coupon!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
            })
            .then((willDelete) => {
            if (willDelete) {
                refundsService.deleteLoyaltyCoupon(coupon_id, id)
                .then(response => {
                    console.log(response);
                    getResults(current);

                })
                .catch(error => {
                    console.log({ error });
                });
            } else {
                swal("Your coupon is safe!");
            }
        });
    }

    const loadCouponValues = (coupon, index) => {
        resetCreteCouponForm();
        console.log("loading coupon values", coupon, index);
        setCreateCouponCustomerName(coupon.customer);
        setCreateCouponCode((coupon.customer+coupon.points).replace(/\s/g, ''));
        setCreateCouponPoints(coupon.points);
        // setCreateCouponExpiry(coupon.expiration_date);
        // set expiry date to 6 months from today
        var currentDate = new Date();
        var futureDate = new Date(currentDate);
        futureDate.setMonth(currentDate.getMonth() + 6);
        setCreateCouponExpiry(futureDate);
        setCreateCouponIndex(index);
        setCreateCouponID(coupon.id);
        setCreateCouponEmail(coupon.email);
        setIsOpen(true);

    }

    const createCoupon = (e) => {
        e.preventDefault();
        console.log("creating coupon", createCouponCustomerName, createCouponCode, createCouponPoints, createCouponExpiry, createCouponIndex, createCouponID, createCouponEmail);
        refundsService.createLoyaltyCoupon(createCouponCustomerName, createCouponCode, createCouponPoints, createCouponExpiry, createCouponIndex, createCouponID, createCouponEmail)
        .then(response => {
            console.log(response);
            resetCreteCouponForm();
            getResults(current);
            swal("Coupon created successfully!", {
                icon: "success",
            });
            setIsOpen(false);
        })
        .catch(error => {
            console.log({ error });
        });
    }

    const searchChangeReport = (e) => {
        console.log("searching", e.target.value);
        setSearch(e.target.value);
        if(e.target.value.length > 2) {
            // if(e.keyCode == 13) {
                getResults(1);
            // }
        }
    }

    return (
        <>
        {!allowed && 
            <NoPermission />
        }
        {allowed &&
        <>
            <div className="row">
                <div className='col'>
                    <div className='header-line'>
                        <h1>Loyalty Points Report</h1>
                        <div style={{display:"flex"}}>
                            <input type="text" value={search} onChange={e => searchChangeReport(e)} className="form-control" placeholder="Search" />
                            {type == "current" && 
                                <select style={{marginLeft:"10px"}} className="form-control" value={orderStatus} onChange={e=>setOrderStatus(e.target.value)}>
                                    <option value="all">All Orders</option>
                                    <option value="pending">Pending Orders</option>
                                    <option value="processing">Processing Orders</option>
                                    <option value="cancelled">Cancelled Orders</option>
                                </select>
                            }

                            <select style={{marginLeft:"10px"}} className="form-control" value={type} onChange={e=>setType(e.target.value)}>
                                <option value="current">Current Coupons</option>
                                <option value="past">Past Coupons</option>
                            </select>
                        </div>

                    </div>
                    <hr />
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Date Created</th>
                                    <th>Customer Name</th>
                                    <th>Customer Email</th>
                                    <th>Order Details</th>
                                    <th>Order Status</th>
                                    <th>Comments</th>
                                    <th>Coupon Code</th>
                                    <th>Points Value</th>
                                    <th>Processing Date</th>
                                    <th>Coupon Expiration</th>
                                    <th>Coupon Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {coupons.length > 0 ? coupons.map((coupon, index) => (
                                <tr key={index}>
                                    <td>{coupon.date_created}</td>
                                    <td>{coupon.customer}</td>
                                    <td>{coupon.email}</td>
                                    <td><a target="_blank" href={"https://deals.mu/wp-admin/post.php?post="+coupon.order_id+"&action=edit"}>#{coupon.order_id}</a></td>
                                    <td>
                                        {(coupon.order_status=="Processing" || coupon.order_status=="Completed") && <span style={{color: "green", fontWeight:"bold"}}>{coupon.order_status}</span>}
                                        {coupon.order_status=="Cancelled" && <span style={{color: "red", fontWeight:"bold"}}>{coupon.order_status}</span>}
                                        {coupon.order_status=="Pending" && <span style={{color: "blue", fontWeight:"bold"}}>{coupon.order_status}</span>}
                                    </td>
                                    <td><LoyaltyPointsCommentPopup comment={coupon.refund_reason} /></td>
                                    <td>{coupon.coupon_code}</td>
                                    <td>{coupon.points}</td>
                                    <td>{coupon.processed_refund_date}</td>
                                    <td>{coupon.expiration_date}</td>
                                    <td>
                                        {coupon.coupon_status=="Pending" && <span style={{color: "blue", fontWeight:"bold"}}>{coupon.coupon_status}</span>}
                                        {coupon.coupon_status=="Created" && <span style={{color: "purple", fontWeight:"bold"}}>{coupon.coupon_status}</span>}
                                        {coupon.coupon_status=="Claimed" && <span style={{color: "green", fontWeight:"bold"}}>{coupon.coupon_status}</span>}
                                        {coupon.coupon_status=="Expired" && <span style={{color: "red", fontWeight:"bold"}}>{coupon.coupon_status}</span>}
                                        {coupon.coupon_status=="Cancelled" && <span style={{color: "red", fontWeight:"bold"}}>{coupon.coupon_status}</span>}
                                    </td>
                                    <td>
                                        {type=="current" && (coupon.coupon_id=="" || coupon.coupon_id=="0") && coupon.order_status=="Processing" && permissions.includes("createloyaltycoupon") && 
                                            <button className="btn btn-primary btn-sm mb-10" onClick={e => loadCouponValues(coupon, index)}>Create Coupon</button>
                                        }
                                        {coupon.coupon_id!="" && coupon.coupon_id!="0" && 
                                        <>
                                            <a target="_blank" href={"https://deals.mu/scripts/send_coupon_to_customer.php?id="+coupon.coupon_id} className="btn btn-warning btn-sm mb-10">Send Customer Email</a>
                                            {type=="current" && permissions.includes("createloyaltycoupon") && <br /> }
                                            {type=="current" && permissions.includes("createloyaltycoupon") && 
                                                <button className="btn btn-danger btn-sm" onClick={e => deleteCoupon(coupon.coupon_id, coupon.id, index)}>Delete Coupon</button>
                                            }
                                        </>
                                        }
                                    </td>
                                </tr>
                                )) : <tr><td colSpan="12"></td></tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Pagination total={total} current={current} limit={limit} onPageChange={e => pageChanged(e)} />

            {/* create popup form for the create coupon */}
            {isOpen && (
            <div className="custom-popup">
                <div className="custom-popup-content">
                    <span className="close" onClick={e => {resetCreteCouponForm(); setIsOpen(false)}}>
                        &times;
                    </span>
                    <h4>Create Coupon</h4>
                    <hr />
                    <form onSubmit={e => createCoupon(e)}>
                        <div className="form-group">
                            <label>Customer Name</label>
                            <input type="text" disabled={true} className="form-control" value={createCouponCustomerName} onChange={e => setCreateCouponCustomerName(e.target.value)} required />
                        </div>
                        <div className="form-group">
                            <label>Coupon Code</label>
                            <input type="text" className="form-control" value={createCouponCode} onChange={e => setCreateCouponCode(e.target.value)} required />
                        </div>
                        <div className="form-group">
                            <label>Points Value</label>
                            <input type="text" disabled={true} className="form-control" value={createCouponPoints} onChange={e => setCreateCouponPoints(e.target.value)} required />
                        </div>
                        <div className="form-group">
                            <label>Expiration Date</label>
                            <DatePicker
                                dateFormat="dd-MM-yyyy"
                                selected={createCouponExpiry}
                                onChange={date => setCreateCouponExpiry(date)}
                                placeholderText="Expiry Date"
                                className='form-control'
                                minDate={new Date()}
                                style={{width: "100%"}}
                            />
                        </div>
                        <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                            <button type="submit" className="btn btn-success popup-button">Create Coupon</button>
                            <button type="button" className="btn btn-danger bg-red" onClick={e => {resetCreteCouponForm(); setIsOpen(false)}}>Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
            )}
        </>
        }
        </>
    )
}
