import axios from "axios";

const API_URL = "https://deals.mu/scripts/reportsapi/crm/user_info.php";

class CRMService {
    searchCustomers(searchText) {
        var params = {
            action: 'search_customers',
            search: searchText,
        };
        return axios
            .get('https://deals.mu/scripts/reportsapi/crm/user_info_test.php', { params })
            .then((response) => {
                console.log('searchCustomers', response);
                return response;
            }).catch(error => {
                return false;
            });
    }

    getCustomerBasicDetails(user_id) {
        var params = {
            action: 'customer_basic_details',
            user_id: user_id,
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                console.log('getCustomerBasicDetails', response);
                return response;
            }).catch(error => {
                return false;
            });
    }

    getCustomerPreferences(user_id) {
        var params = {
            action: 'purchase_preferences',
            user_id: user_id,
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                // console.log('getCustomerPreferences', response);
                return response;
            }).catch(error => {
                return false;
            });
    }

    getCustomerMoreInfo(user_id) {
        var params = {
            action: 'more_customer_info',
            user_id: user_id,
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                // console.log('getCustomerMoreInfo', response);
                return response;
            }).catch(error => {
                return false;
            });
    }

    getCustomerCoupons(user_id) {
        var params = {
            action: 'customer_coupons',
            user_id: user_id,
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                // console.log('getCustomerCoupons', response);
                return response;
            }).catch(error => {
                return false;
            });
    }

    getCustomerPoints(user_id) {
        var params = {
            action: 'customer_points',
            user_id: user_id,
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                // console.log('getCustomerPoints', response);
                return response;
            }).catch(error => {
                return false;
            });
    }

    getCustomerOrders(user_id) {
        var params = {
            action: 'customer_orders',
            user_id: user_id,
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                // console.log('getCustomerOrders', response);
                return response;
            }).catch(error => {
                return false;
            });
    }

    createAccount(email, firstname, lastname, phone, password) {
        var params = {
            action: 'create_account',
            email: email,
            firstname: firstname,
            lastname: lastname,
            phone: phone,
            password: password,
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                // console.log('createAccount', response);
                return response;
            }).catch(error => {
                return false;
            });
    }

    saveValues(id, key, value) {
        var params = {
            action: 'save_values',
            user_id: id,
            key: key,
            value: value,
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                // console.log('saveValues', response);
                return response;
            }).catch(error => {
                return false;
            });
    }

    saveMoreInfo(id, adults, children, others, facebookurl, profession, corporate, childrenages) {
        var params = {
            action: 'save_more_info',
            user_id: id,
            adults: adults,
            children: children,
            others: others,
            facebookurl: facebookurl,
            profession: profession,
            corporate: corporate,
            childrenages: childrenages
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                // console.log('saveMoreInfo', response);
                return response;
            }).catch(error => {
                return false;
            });
    }

    saveSpecialDates(id, birthday, anniversary) {
        var params = {
            action: 'save_special_dates',
            user_id: id,
            birthday: birthday,
            anniversary: anniversary,
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                // console.log('saveSpecialDates', response);
                return response;
            }).catch(error => {
                return false;
            });
    }

    saveLimitations(id, foodallergies, wheelchair, limitations, frequentrequests) {
        var params = {
            action: 'save_limitations',
            user_id: id,
            foodallergies: foodallergies,
            wheelchair: wheelchair,
            limitations: limitations,
            frequentrequests: frequentrequests,
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                // console.log('saveLimitations', response);
                return response;
            }).catch(error => {
                return false;
            });
    }

    saveAdditionalInfo(id, region, country, oftenbooking) {
        var params = {
            action: 'save_additional_info',
            user_id: id,
            region: region,
            country: country,
            oftenbooking: oftenbooking,
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                // console.log('saveAdditionalInfo', response);
                return response;
            }).catch(error => {
                return false;
            });
    }

}

export default new CRMService();