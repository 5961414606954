import axios from "axios";

const API_URL = "https://deals.mu/scripts/reportsapi/corporatesaccounts.php";

class CorporateNewService {
    compare( a, b ) {
        if ( (a[1]) < (b[1]) ){
            return 1;
        }
        if ( (a[1]) > (b[1]) ){
            return -1;
        }
        return 0;
    }

    getAll(start, end, search, status) {
       return this.getResults(0)
            .then(response => {

                console.log({response});

                var result = {
                    data: []
                };
                let promises = [];
                var size = parseFloat(response.data);

                var loopSize = Math.ceil(parseFloat(size / 50));
                if(loopSize<1) {
                    loopSize = 1;
                }
                for (var i = 1; i <= loopSize; i++) {

                    promises.push(
                        this.getResults(i, start, end, search, status)
                            .then(response1 => {

                                result.data = result.data.concat(response1.data);
                            })
                            .catch(error1 => {
                                console.log({ error1 });
                            })
                    );
                }

                return Promise.all(promises).then(() => {
                    console.log({result});
                    result.data.sort( this.compare );
                    return result;
                });

            }).catch(error => {

                return false;
            });
    }

    getResults(page, start, end, search, status) {
        var params = {
            action: 'get_all',
            page: page,
            start: start,
            end: end,
            search: search,
            status: status
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

    createCorporateCustomer(companyName, landLine, address, email, couponCode) {
        var params = {
            companyName: companyName,
            landLine: landLine,
            address: address,
            email: email,
            couponCode: couponCode,
            action: "create_corporate_customer"
        };

        return axios.post(API_URL, {params})
        .then(response => {
            return response;
        })
        .catch(error => {
            return error;
        })
    }

    getCorporateDetails(userID) {
        var params = {
            userID: userID,
            action: "get_corporate_details"
        };

        return axios.post(API_URL, {params})
        .then(response => {
            return response;
        })
        .catch(error => {
            return error;
        })
    }

    saveCorporateDetails(userID, companyName, landLine, address, couponCode, noOfStaff, requirements, contacts, contactsInfo, agent ) {
        var params = {
            userID: userID,
            companyName: companyName,
            landLine: landLine,
            address: address,
            noOfStaff: noOfStaff,
            couponCode: couponCode,
            contacts: contacts,
            requirements: requirements,
            contactsInfo: contactsInfo,
            agent: agent,
            action: "save_corporate_details"
        };

        return axios.post(API_URL, {params})
        .then(response => {
            return response;
        })
        .catch(error => {
            return error;
        })
    }

    updateCorporateOverview(userID, companyName, landLine, address, noOfStaff, requirements, lineofBusiness, secondaryEmail, website, agent ) {
        var params = {
            userID: userID,
            companyName: companyName,
            landLine: landLine,
            address: address,
            noOfStaff: noOfStaff,
            requirements: requirements,
            action: "update_corporate_details",
            lineofBusiness: lineofBusiness,
            secondaryEmail: secondaryEmail,
            website: website,
            agent: agent
        };

        return axios.post(API_URL, {params})
        .then(response => {
            return response;
        })
        .catch(error => {
            return error;
        })
    }

    corporateBreakdownByCategory(userID, period) {
        var params = {
            userID: userID,
            action: "corporate_breakdown_by_category",
            period: period
        };

        return axios.post(API_URL, {params})
        .then(response => {
            return response;
        })
        .catch(error => {
            return error;
        })
    }

    corporateCreditEarnedBalance(userID) {
        var params = {
            userID: userID,
            action: "corporate_credit_earned_balance"
        };

        return axios.post(API_URL, {params})
        .then(response => {
            return response;
        })
        .catch(error => {
            return error;
        })
    }

    getCorporateLogs(userID) {
        var params = {
            userID: userID,
            action: "get_corporate_logs"
        };

        return axios.post(API_URL, {params})
        .then(response => {
            return response;
        })
        .catch(error => {
            return error;
        })
    }

    updateCorporateMetaValue(userID, meta_key, meta_value, agent) {
        var params = {
            userID: userID,
            meta_key: meta_key,
            meta_value: meta_value,
            agent: agent,
            action: "updateCorporateMetaValue"
        };

        return axios.post(API_URL, {params})
        .then(response => {
            return response;
        })
        .catch(error => {
            return error;
        })
    }

    getCorporateMeta(userID) {
        var params = {
            userID: userID,
            action: "getCorporateMetaValue"
        };

        return axios.post(API_URL, {params})
        .then(response => {
            return response;
        })
        .catch(error => {
            return error;
        })
    }

    getOrderHistory(userID, page, limit) {
        var params = {
            userID: userID,
            page: page,
            limit: limit,
            action: "getOrderHistory"
        };

        return axios.post(API_URL, {params})
        .then(response => {
            return response;
        })
        .catch(error => {
            return error;
        })

    }

    getCorporateCharts(userID) {
        var params = {
            userID: userID,
            action: "getCorporateCharts"
        };

        return axios.post(API_URL, {params})
        .then(response => {
            return response;
        })
        .catch(error => {
            return error;
        })

    }
}

export default new CorporateNewService();