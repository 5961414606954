import axios from "axios";
import chalk from 'chalk';

const API_URL = "https://deals.mu/scripts/reportsapi/bookingreports.php";

class BookingReportsService {

    getReport(startdate, enddate, product, supplier, company, status, order_id) {
        var params = {
            page: 0,
            action: 'report',
            startdate: startdate,
            enddate: enddate,
            productname: product,
            suppliername: supplier,
            companyname: company,
            orderstatus: status,
            order_id: order_id,
            rand: Math.random()
        };
        return axios
            .get(API_URL, { params })
            .then(async (response) => {
                console.log('response: ', response);
                var result = {
                    data : []
                };
                let promises = [];
                var size = response.data;
                // console.log({size});
                var loopSize = Math.ceil(parseFloat(size/50));
                // console.log({loopSize});

                for (var i=1; i<=loopSize; i++) {
                    // console.log({i});
                    promises.push(
                        await this.getReportPage(startdate, enddate, product, supplier, company, status, order_id, i)
                        .then(response1 => {
                            console.log('response: '+i, response1);
                            result.data = result.data.concat(response1.data);
                        })
                        .catch(error1 => {
                            console.log({error1});
                        })
                    );
                }

                return Promise.all(promises).then(() => {
                    // console.log({result});
                    return result;
                });

                // console.log(response);
                // return response;
                // return result;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

    getReportPage(startdate, enddate, product, supplier, company, status, order_id, page) {
        var params = {
            page: page,
            action: 'report',
            startdate: startdate,
            enddate: enddate,
            productname: product,
            suppliername: supplier,
            companyname: company,
            orderstatus: status,
            order_id: order_id,
            rand: Math.random()
        };
        return axios
            .get(API_URL, { params })
            .then((response) => {
                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

}



export default new BookingReportsService();