import axios from "axios";
import chalk from 'chalk';

const API_URL = "https://deals.mu/scripts/reportsapi/invoices.php";

class InvoicesService {
    fetchSuppliers(company) {
        var params = {
            action: 'fetch_suppliers',
            company: company,
        };
        return axios
            .get(API_URL, { params })
            .then((response) => {

                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

    fetchProducts(company, supplier) {
        var params = {
            action: 'fetch_products',
            company: company,
            supplier: supplier
        };

        return axios
            .get(API_URL, { params })
            .then((response) => {

                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

    createInvoice(company, supplier, invoicedate, invoicenumber, from, to, file, amount, type, external, invoiceType, invoiceduedate, username, isItSoa, externalfilelinktosheet) {
        var params = {
            action: 'create_invoice',
            company: company,
            supplier: JSON.stringify(supplier),
            invoicedate: invoicedate,
            invoicenumber: invoicenumber,
            from: from,
            to: to,
            amount: amount,
            file: file,
            type: type,
            external: external,
            invoicetype: invoiceType,
            invoiceduedate: invoiceduedate,
            username: username,
            isItSoa: isItSoa,
            externalfilelinktosheet: externalfilelinktosheet
        };
        return axios
            .get(API_URL, { params })
            .then((response) => {

                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

    deleteInvoice(id) {
        var params = {
            action: 'delete_invoice',
            id: id,
        };
        return axios
            .get(API_URL, { params })
            .then((response) => {

                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

    loadReportPage(startday, endday, company, supplier, orderstatus, all, user_email=null, page) {
        var params = {
            action: 'get_report',
            page: page,
            startday: startday, 
            endday: endday, 
            company: company, 
            supplier: supplier, 
            orderstatus: orderstatus, 
            all:all,
            user_email: user_email
        };
        return axios
            .get(API_URL, { params })
            .then((response) => {

                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

    loadReport(startday, endday, company, supplier, orderstatus, all, user_email=null) {
        var params = {
            page: 0,
            action: 'get_report',
            startday: startday, 
            endday: endday, 
            company: company, 
            supplier: supplier, 
            orderstatus: orderstatus, 
            all:all,
            user_email: user_email
        };
        return axios
            .get(API_URL, { params })
            .then(async (response) => {
                console.log('response: ', response);
                var result = {
                    data : []
                };
                let promises = [];
                var size = response.data;
                // console.log({size});
                var loopSize = Math.ceil(parseFloat(size/800));
                // console.log({loopSize});

                for (var i=1; i<=loopSize; i++) {
                    // console.log({i});
                    promises.push(
                        await this.loadReportPage(startday, endday, company, supplier, orderstatus, all, user_email, i)
                        .then(response1 => {
                            console.log('response: '+i, response1);
                            result.data = result.data.concat(response1.data);
                        })
                        .catch(error1 => {
                            console.log({error1});
                        })
                    );
                }

                return Promise.all(promises).then(() => {
                    // console.log({result});
                    return result;
                });

                // console.log(response);
                // return response;
                // return result;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

    getInvoiceNumbers(startday, endday, company, supplier) {
        var params = {
            action: 'get_invoice_numbers',
            startday: startday,
            endday: endday,
            company: company,
            supplier: JSON.stringify(supplier),
        };
        return axios
            .get(API_URL, { params })
            .then((response) => {

                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

    getInvoiceNumber() {
        var params = {
            action: 'get_invoice_number',
        };
        return axios
            .get(API_URL, { params })
            .then((response) => {

                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

    uploadFile(data) {
        var params = {
            action: 'upload_file',
            data: data
        };
        return axios
            .post(API_URL, { params }, {headers: { 'Content-Type': 'multipart/form-data' }})
            .then((response) => {

                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

    getEmailData(invoicenumber) {
        var params = {
            action: 'email_data',
            invoicenumber: invoicenumber
        };
        return axios
            .get(API_URL, { params })
            .then((response) => {

                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

    sendEmail(values) {
        var params = {
            action: 'send_email',
            invoiceNumber: values.invoiceNumber,
            amount_paid: values.amount_paid,
            payment_ref: values.payment_ref,
            mode_of_pay: values.mode_of_pay,
            date_of_payment: values.date_of_payment,
            selected_email_address: values.selected_email_address,
            email_text: values.email_text,
            table_data: JSON.stringify(values.table_data),
        };
        return axios
            .post('https://deals.mu/scripts/reportsapi/send_invoice_email.php', params)
            .then((response) => {

                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

    deleteEmail(email, id) {
        var params = {
            action: 'delete_email',
            email: email,
            id: id,
        };
        return axios
            .post('https://deals.mu/scripts/reportsapi/send_invoice_email.php', params)
            .then((response) => {

                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

    checkInvoiceNumber(invoice_number) {
        var params = {
            action: 'check_invoice_number',
            invoice_number: invoice_number,
        };
        return axios
            .get(API_URL, { params })
            .then((response) => {

                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

    fetchCreditFacilities(company) {
        var params = {
            action: 'fetch_credit_facility',
            company: company,
        };
        return axios
            .get(API_URL, { params })
            .then((response) => {

                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

}

export default new InvoicesService();