import React, { Component } from "react";
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { style } from "@mui/system";
import swal from "sweetalert";

import OrdersReportService from "../../services/orders/ordersreport.service";

export default class AllComplaints extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderid: props.orderid,
            rerender: props.rerender,
            allComplaints: []
        }
    }

    componentDidMount() {
        this.getComplaints();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.rerender!=this.props.rerender) {
            this.getComplaints();
        }
    }

    getComplaints = () => {
        OrdersReportService.getAllComplaints(this.state.orderid)
        .then(response => {
            // console.log("all complaints", response);
            this.setState({
                allComplaints: response.data
            })
        })
        .catch(error => console.log({error}));
    }

    render() {
        const style = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        };
        return (
            <>
            {this.state.allComplaints.length>0 && 
            <div style={{marginTop: "35px"}}>
            <div className="row">
                <div className="col">
                    <h6>All Complaints:</h6>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <table class="table table-striped table-bordered">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Date</th>
                                <th>Summary</th>
                                <th>Status</th>
                                <th>Handling Agent</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.allComplaints!=null && this.state.allComplaints.map((complaint, index) => (
                            <tr key={index}>
                                <td scope="row">{complaint.id}</td>
                                <td>{complaint.created_date}</td>
                                <td>{complaint.complaint_summary}</td>
                                <td>{complaint.status}</td>
                                <td>{complaint.handling_agent}</td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            </div>
            }
            </>
        );
    }
}