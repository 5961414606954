import axios from "axios";

const API_URL = "https://deals.mu/scripts/reportsapi/crm/influencer_info.php";

class InfluencerService {
    compare( a, b ) {
        if ( (a[1]) < (b[1]) ){
            return 1;
        }
        if ( (a[1]) > (b[1]) ){
            return -1;
        }
        return 0;
    }

    getAll() {
       return this.getResults(0)
            .then(response => {

                console.log({response});

                var result = {
                    data: []
                };
                let promises = [];
                var size = parseFloat(response.data);

                var loopSize = Math.ceil(parseFloat(size / 50));
                if(loopSize<1) {
                    loopSize = 1;
                }
                for (var i = 1; i <= loopSize; i++) {

                    promises.push(
                        this.getResults(i)
                            .then(response1 => {

                                result.data = result.data.concat(response1.data);
                            })
                            .catch(error1 => {
                                console.log({ error1 });
                            })
                    );
                }

                return Promise.all(promises).then(() => {
                    console.log({result});
                    result.data.sort( this.compare );
                    return result;
                });

            }).catch(error => {

                return false;
            });
    }

    getResults(page) {
        var params = {
            action: 'get_all',
            page: page,
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

    createInfluencerCustomer(customerName, relavancy, mobile, email, couponCode) {
        var params = {
            customerName: customerName,
            relavancy: relavancy,
            mobile: mobile,
            email: email,
            couponCode: couponCode,
            action: "create_influencer_customer"
        };

        return axios.post(API_URL, {params})
        .then(response => {
            return response;
        })
        .catch(error => {
            return error;
        })
    }

    getInfluencerDetails(userID) {
        var params = {
            userID: userID,
            action: "get_influencer_details"
        };

        return axios.post(API_URL, {params})
        .then(response => {
            return response;
        })
        .catch(error => {
            return error;
        })
    }

    saveInfluencerDetails(userID, customerName, mobile, mobile2, relavancy, couponCode, requirements, contactsInfo, fblink, fblikes, instalink, instafollowers, youtubelink, youtubesubscribers ) {
        var params = {
            userID: userID,
            customerName: customerName,
            mobile: mobile,
            mobile2: mobile2,
            relavancy: relavancy,
            couponCode: couponCode,
            requirements: requirements,
            contactsInfo: contactsInfo,
            fblink: fblink,
            fblikes: fblikes,
            instalink: instalink,
            instafollowers: instafollowers,
            youtubelink: youtubelink,
            youtubesubscribers: youtubesubscribers,
            action: "save_influencer_details"
        };

        return axios.post(API_URL, {params})
        .then(response => {
            return response;
        })
        .catch(error => {
            return error;
        })
    }
}

export default new InfluencerService();