import axios from "axios";
import chalk from 'chalk';

const API_URL = "https://deals.mu/scripts/reportsapi/productlogs.php";

class ProductLogsService {
    get_all_logs(product_id) {
        var params = {
            action: 'alllogs',
            product_id: product_id,
        };
        return axios.get(API_URL, { params })
        .then((response) => {

            // console.log(response);
            return response;
        }).catch(error => {
            // localStorage.removeItem("user");
            return false;
        });
    }

    get_log_details(id) {
        var params = {
            action: 'logdetails',
            id: id,
        };

        return axios.get(API_URL, { params })
        .then(response => {
            return response;
        }).catch(error => {
            return false;
        });
    }

}

export default new ProductLogsService();