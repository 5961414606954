import React, { Component } from "react";
import Loader from 'react-loader-spinner';
import Select from 'react-select';
import DTable from './dtable.component';

import AuthService from '../services/auth/auth.service';
import CommissionsService from '../services/erp/commissions.service';
import PaymentReportsService from '../services/erp/paymentreports.service';
import UtilityService from '../services/utility.service';
import InvoicesService from '../services/erp/invoices.service';

import UserContext from "../contexts/UserContext";
import NoPermission from "./extras/nopermission.component";

export default class CommissionSheet extends Component {
    static contextType = UserContext
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            data: [],
            columnsheadings: [],
            suppliers: [{ value: 0, label: 'Loading..' }],
            companies: [{ value: 0, label: 'Loading..' }],
            suppliername: { value: 0, label: 'Select a Supplier' },
            companyname: { value: 0, label: 'Select a Company' },
            loadingvalues: true,
            error: {
                status: false,
                message: ''
            },
            search_btn_text: 'Search',
            loader: false,
            commcompanyname: { value: 0, label: 'Select a Company' },
            commcompanynamehidden: { value: 0, label: 'Select a Company' },
            commsuppliername: { value: 0, label: 'Select a Supplier' },
            commsuppliernamehidden: { value: 0, label: 'Select a Supplier' },
            productname: {value: 0, label: 'Select a product'},
            productnamehidden: {value: 0, label: 'Select a product'},
            invoicesuppliers: { value: 0, label: 'Select a Supplier' },
            invoiceproducts: { value: 0, label: 'Select a product' },
            invoiceprices: { value: 'NET Rates', label: 'NET Rates' },
            invoicespricesoptions: [{ value: 'NET Rates', label: 'NET Rates' }, { value: "Public Rates (commisionable)", label: "Public Rates (commisionable)" }],
            enableCommformsuppliers: false,
            enableCommformproducts: false,
            allowed: true
        };
    }

    componentDidMount() {

        this.getAll();

        PaymentReportsService.getAllSuppliers()
            .then(response => {
                if (response.data.suppliers) {
                    this.setState({
                        suppliers: response.data.suppliers,
                        companies: response.data.companies,
                        loadingvalues: false,
                    });
                }
            })
            .catch(error => {
                console.log({ error });
            });

        AuthService.checklogin().then(user => {
            AuthService.getUserPermisions(user)
            .then(response => {
                var userpermissions = response.data;
                console.log({userpermissions});
                    if(userpermissions.includes('commissions') || userpermissions.includes('all')) {
                    console.log('You are allowed!');
                    this.setState({
                        allowed: true
                    });
                } else {
                    console.log('You are not allowed!');
                    this.setState({
                        allowed: false
                    });
                }
            })
        }).catch(error => {
            window.location.href = '/login';
        });
    }

    getAll = () => {
        CommissionsService.getAll()
            .then(response => {
                console.log({ response });
                if (response.data.length > 0) {
                    this.setState({
                        data: response.data,
                        columnsheadings: [
                            { title: "#" },
                            { title: "Created On" },
                            { title: "Company" },
                            { title: "Supplier" },
                            { title: "Product" },
                            { title: "Type" },
                            { title: "Commission" },
                            // { title: "Prices Type" },
                            { title: "Price List" },
                            { title: "From" },
                            { title: "To" },
                            { title: "Comments" },
                        ],
                        show: true,
                        loader: false,
                        error: {
                            status: false,
                            message: ''
                        },
                    });
                } else {
                    this.setState({
                        show: false,
                        loader: false,
                        error: {
                            status: true,
                            message: 'No records found'
                        },
                    });
                }
            })
            .catch(error => {
                console.log({ error });
            });
    }

    resetCommission = () => {
        this.setState({
            commcompanyname: { value: 0, label: 'Select a Company' },
            commcompanynamehidden: { value: 0, label: 'Select a Company' },
            commsuppliername: { value: 0, label: 'Select a Supplier' },
            commsuppliernamehidden: { value: 0, label: 'Select a Supplier' },
            productname: {value: 0, label: 'Select a product'},
            productnamehidden: {value: 0, label: 'Select a product'},
            invoiceprices: { value: 'NET Rates', label: 'NET Rates' },
        });
    }

    searchClick = () => {
        this.setState({
            loader: true,
            show: false,
            error: {
                status: false,
                message: ''
            },
        });

        if (this.state.suppliername.value == 0 && this.state.companyname.value==0){
            this.getAll();
            return;
        }
        console.log('i am clicked');
        CommissionsService.getSingle(this.state.suppliername.value, this.state.companyname.value)
        .then(response => {
            console.log({response});
            if (response.data.length>0) {
                this.setState({
                    data: response.data,
                    columnsheadings: [
                        { title: "#" },
                        { title: "Created On" },
                        { title: "Company" },
                        { title: "Supplier" },
                        { title: "Product" },
                        { title: "Type" },
                        { title: "Commission" },
                        // { title: "Prices Type" },
                        { title: "Price List" },
                        { title: "From" },
                        { title: "To" },
                        { title: "Comments" },
                    ],
                    show:true,
                    loader: false,
                    error: {
                        status: false,
                        message: ''
                    },
                });
            } else {
                this.setState({
                    show: false,
                    loader: false,
                    error: {
                        status: true,
                        message: 'No records found'
                    },
                });
            }
        })
        .catch(error => {
            console.log({ error });
        });
    };

    handleChangeSuplier = (selected) => {
        console.log({selected});
        this.setState({
            suppliername: selected
        });
    }

    handleChangCompany = (selected) => {
        console.log({ selected });
        this.setState({
            companyname: selected,
            suppliers: [{ value: 0, label: 'Loading..' }],
        });

        InvoicesService.fetchSuppliers(selected.value)
            .then(response => {
                console.log(response);
                this.setState({
                    suppliers: response.data,
                });
            })
            .catch(error => {
                console.log(error);
            })
    }

    handleChangCommCompany = selectedOption => {
        this.setState({ commcompanyname: selectedOption });
        this.setState({ commcompanynamehidden: selectedOption });
        this.setState({
            productname: {value: 0, label: 'Select a product'},
            productnamehidden: {value: 0, label: 'Select a product'},
            invoiceproducts: {value: 0, label: 'Select a product'},
            commsuppliername: { value: 0, label: 'Select a Supplier' },
            commsuppliernamehidden: { value: 0, label: 'Select a Supplier' },
            invoicesuppliers: { value: 0, label: 'Select a Supplier' },
            enableCommformsuppliers: false,
        });
        InvoicesService.fetchSuppliers(selectedOption.value)
            .then(response => {
                console.log(response);
                this.setState({
                    invoicesuppliers: response.data,
                    enableCommformsuppliers: true,
                });
            })
            .catch(error => {
                console.log(error);
            })
    }

    handleChangeCommSuplier = selectedOption => {
        this.setState({ commsuppliername: selectedOption });
        this.setState({ commsuppliernamehidden: selectedOption });
        if(selectedOption.value==0 || selectedOption.value=='All') {
            this.setState({
                productname: {value: 0, label: 'Select a product'},
                productnamehidden: {value: 0, label: 'Select a product'},
                invoiceproducts: {value: 0, label: 'Select a product'}
            });
        } else {
            InvoicesService.fetchProducts(this.state.commcompanyname.value, selectedOption.value)
            .then(response => {
                console.log(response);
                this.setState({
                    invoiceproducts: response.data,
                    enableCommformproducts: true,
                });
            })
            .catch(error => {
                console.log(error);
            })
        }
        
        // console.log(`Option selected:`, selectedOption);
    };

    handleChangeCommProducts = selectedOption => {
        this.setState({ productname: selectedOption });
        this.setState({ productnamehidden: JSON.stringify(selectedOption) });
        console.log(`Option selected:`, selectedOption);
    };

    handleChangeCommPricesType = selectedOption => {
        this.setState({ invoiceprices: selectedOption });
        // console.log(`Option selected:`, selectedOption);
    };

    render() {

        let { show, suppliers, companies, loadingvalues, search_btn_text, loader, data, columnsheadings, error } = this.state;

        if (this.state.allowed == false) {
          return <NoPermission />;
        }
        
        return (
            <>
            <div className="row">
                <div className='col'>
                        <h2>Deals Commission Rates.</h2>
                        <p><i>Please select supplier and Company to load and edit the Commission Rates</i></p>
                    <hr />
                </div>
            </div>
                <div className="row">
                    <div className='col'>
                        {loadingvalues == true &&
                            <p style={{ color: "#df153b" }}><b><i>Loading Companies/Suppliers. Please Wait....</i></b></p>
                        }
                        <div className="booking-reports-search-form" style={{ display: 'flex' }}>
                            <div style={{ marginLeft: '10px', width: '300px' }}>
                                <Select
                                    value={this.state.companyname}
                                    onChange={this.handleChangCompany}
                                    options={companies}
                                />
                            </div>

                            <div style={{ marginLeft: '10px', width: '300px' }}>
                                <Select
                                    value={this.state.suppliername}
                                    onChange={this.handleChangeSuplier}
                                    options={suppliers}
                                />
                            </div>
                            
                            <div style={{ marginLeft: '10px' }}>
                                <button className="btn btn-success booking-search-btn comm-search-btn" onClick={this.searchClick}>{search_btn_text}</button>
                            </div>

                            <div style={{ marginLeft: '10px' }}>
                                <button className="btn btn-warning booking-search-btn add_commission">Add Commission</button>
                                <div className="modal fade add-commission-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                                    <div className="modal-dialog modal-lg">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title">Add New Commission</h5>
                                                <button type="button" onClick={this.resetCommission} className="close commission-modal-close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div className='row'>
                                                    <div className='col-12 formSwitcher'>
                                                        <div data-btn-comm-type="commission" className="formSwitcherButtons formSwitcherButtonsSelected">% Commission</div>
                                                        <div data-btn-comm-type="pricelist" className="formSwitcherButtons">Price List</div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <div className="alert alert-danger comm-error" style={{ display: 'none' }} role="alert">
                                                            Fill all mandatory fields marked as <b style={{ color: 'red' }}>*</b>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='row' style={{marginBottom: '20px'}}>
                                                    <div className='col-12'>
                                                        <b>Commission Type = <span id="addcomm-type-text">% Commission</span></b>
                                                    </div>
                                                </div>
                                                
                                                <div className="form-group row">
                                                    <label for="company" className="col-sm-3 col-form-label">Company<b style={{ color: 'red' }}>*</b></label>
                                                    <div className="col-sm-9">
                                                        <input type='hidden' id='commcompanynamehidden' value={this.state.commcompanynamehidden.value} />
                                                        <Select
                                                            value={this.state.commcompanyname}
                                                            onChange={this.handleChangCommCompany}
                                                            options={companies}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label for="supplier" className="col-sm-3 col-form-label">Supplier<b style={{ color: 'red' }}>*</b></label>
                                                    <div className="col-sm-9">
                                                        <input type='hidden' id='commsuppliernamehidden' value={this.state.commsuppliernamehidden.value } />
                                                        <Select
                                                            value={this.state.commsuppliername}
                                                            onChange={this.handleChangeCommSuplier}
                                                            options={this.state.invoicesuppliers}
                                                            isDisabled={!this.state.enableCommformsuppliers}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label for="supplier" className="col-sm-3 col-form-label">Products<b style={{ color: 'red' }}>*</b></label>
                                                    <div className="col-sm-9">
                                                        <input type='hidden' id='productnamehidden' value={this.state.productnamehidden } />
                                                        <input type='hidden' id='productlabelhidden' value={this.state.productnamehidden } />
                                                        <Select
                                                            value={this.state.productname}
                                                            onChange={this.handleChangeCommProducts}
                                                            options={this.state.invoiceproducts}
                                                            isDisabled={!this.state.enableCommformproducts}
                                                            isMulti={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row" style={{display: "none"}}>
                                                    <label for="commtype" className="col-sm-3 col-form-label">Commission Type<b style={{ color: 'red' }}>*</b></label>
                                                    <div className="col-sm-9">
                                                        <select id="commtype" className='form-control'>
                                                            <option value='Commission' selected="selected">Commission</option>
                                                            <option value='Price List'>Price List</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label for="commfrom" className="col-sm-3 col-form-label">Commission Period<b style={{ color: 'red' }}>*</b></label>
                                                    <div className="col-sm-9" style={{ display:'flex' }}>
                                                        <input type="text" className="form-control date-picker" id="commfrom" placeholder="From Date" />
                                                        <input type="text" className="form-control date-picker" id="commto" placeholder="To Date" />
                                                    </div>
                                                </div>
                                                <div className="form-group row" id="add-comm-pricelist-upload" style={{display: "none"}}>
                                                    <label for="commfile" className="col-sm-3 col-form-label">Price List File Upload</label>
                                                    <div className="col-sm-9">
                                                        <input type='hidden' id="commfileuploaded" />
                                                        <input style={{marginBottom: "10px"}} type="file" className="form-control form-control-file" id="commfile" placeholder="Price list file" />
                                                        <input type="text" className="form-control form-control" id="commfilelink" placeholder="Price list file External Link" />
                                                    </div>
                                                </div>
                                                <div className="form-group row" id="commamount-container">
                                                    <label for="commamount" className="col-sm-3 col-form-label">Commission(%)</label>
                                                    <div className="col-sm-9">
                                                        <input type="number" className="form-control" id="commamount" placeholder="Commission Percentage" />
                                                    </div>
                                                </div>

                                                <div className="form-group row create-comission-invoice-prices" style={{display: "none"}}>
                                                    <label for="supplier" className="col-sm-3 col-form-label">Invoice Prices</label>
                                                    <div className="col-sm-9">
                                                        <input type='hidden' id='commsupplierpricetypehidden' value={this.state.invoiceprices.value} />
                                                        <Select
                                                            value={this.state.invoiceprices}
                                                            onChange={this.handleChangeCommPricesType}
                                                            options={this.state.invoicespricesoptions}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label for="commsource" className="col-sm-3 col-form-label">Commission Source</label>
                                                    <div className="col-sm-9">
                                                        {/* <input type="text" className="form-control form-control" id="commsource" placeholder="Commission Source" /> */}
                                                        <select className="form-control form-control-select" id="commsource">
                                                            <option>Contract</option>
                                                            <option>Email</option>
                                                            <option>Others</option>
                                                        </select>
                                                    </div>
                                                </div>
                                               
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-danger" data-dismiss="modal">Cancel</button>
                                                <button type="button" className="btn btn-success comm-save-btn">Add Commission</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <hr />
                    </div>
                </div>
            <div className='row'>
                <div className='col-12'>
                    
                    {error.status == true &&
                        <div className="alert alert-danger" role="alert">
                            {error.message}
                        </div>
                    }
                    {loader == true &&
                        <Loader className='loader-center'
                            type="Grid"
                            color="#00BFFF"
                            height={100}
                            width={100}
                        />
                    }
                    
                    {show==true && 
                        <DTable data={data} columns={columnsheadings} paginationTotalRows={50} className="comm-report-table" />
                    }
                </div>
            </div>

            <div className="modal fade bd-example-modal-lg viewcommentscommission" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Supplier Comments/Notes</h5>
                            <button type="button" className="close closecommentcommisionviewmodal" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">X</span>
                            </button>
                        </div>
                        <div className="modal-body" id="viewcommissioncommentmodalbody">
                            
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary closecommentcommisionviewmodal" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            
            </>
        );
    }
}