import React from 'react';
import styled from 'styled-components';



const Pagination = ({ total, current, limit, onPageChange }) => {
  const totalPages = Math.ceil(total / limit); // Assuming 5 items per page, adjust as needed

  const generatePageNumbers = () => {
    const pages = [];
    const start = Math.max(1, current - 2);
    const end = Math.min(totalPages, start + 4);

    for (let i = start; i <= end; i++) {
      pages.push(i);
    }

    return pages;
  };

  const pageChange = (page) => {
    if (page < 1 || page > totalPages) {
      return;
    }

    onPageChange(page);
  }

  const TotalPagesWrapper = styled.p`
    text-align: center;
    color: #adadad;
    margin-top: 10px;
    font-size: 14px;
  `;

  return (
    <div className="pagination flex-col" style={{justifyContent: "center", alignItems: "center", width: "100%"}}>
      <div>
        <button style={buttonStyle} onClick={() => pageChange(1)} disabled={current === 1}>
          First
        </button>
        <button style={{marginLeft: "10px", ...buttonStyle}} onClick={() => pageChange(current - 1)} disabled={current === 1}>
          Previous
        </button>

        {generatePageNumbers().map((page) => (
          <button style={{marginLeft: "10px", ...buttonStyle}} key={page} onClick={() => pageChange(page)} className={page === current ? 'btnactive' : ''}>
            {page}
          </button>
        ))}

        <button style={{marginLeft: "10px", ...buttonStyle}} onClick={() => pageChange(current + 1)} disabled={current === totalPages}>
          Next
        </button>
        <button  style={buttonStyle} onClick={() => pageChange(totalPages)} disabled={current === totalPages}>
          Last
        </button>
      </div>
      <TotalPagesWrapper>
        Page {current} of {totalPages}
      </TotalPagesWrapper>
    </div>
  );
};

const buttonStyle = {
  padding: '3px 8px',
  margin: '0 5px',
  cursor: 'pointer',
  borderRadius: '5px',
  border: '1px solid #ddd',
  background: '#fff',
  color: '#333',
  fontSize: '14px',
};

export default Pagination;
