import React, { Component } from "react";
import Loader from 'react-loader-spinner';
import Button from '@material-ui/core/Button';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import DateRangePicker, { DateRange } from '@material-ui/lab/DateRangePicker';
// import DatePicker from '@material-ui/lab/DatePicker';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import Box from '@material-ui/core/Box';
import DTable from '../dtable.component';
import swal from 'sweetalert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
// import 'react-tooltip/dist/react-tooltip.css'
// import * as Tooltip from 'react-tooltip';
import Pagination from '../pagination.component';
import Corporatecharts from './corporatecharts.component';

import AuthService from '../../services/auth/auth.service';
// import CorporateNewService from '../../services/crm/corporatereports.service';
import CorporateNewService from '../../services/crm/corporatereportsnew.service';

import UserContext from "../../contexts/UserContext";
import NoPermission from "../extras/nopermission.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

export default class CorporateCustomerDetailsNew extends Component {

    static contextType = UserContext;
    constructor(props){
        super(props);

        this.state = {
            user: [],
            data: [],
            show: false,
            showloader: true,
            user_id: 0,
            detailsCompany: '',
            detailsLandline: '',
            detailsAddress: '',
            detailsEmail: '',
            detailsEmailSecondary: '',
            detailsAccountLineOfBusiness: '',
            detailsWebsite: '',
            detailsNotes: '',
            detailsAccountCreationDate: '',
            detailsNoOfStaff: '',
            detailsDiscountCode: '',
            detailsTotalSalesAllTime: '',
            detailsRequirements: '',
            contacts: [
                {
                    type: 'Main Contact',
                    fullname: '',
                    role: '',
                    mobile: '',
                    landline: '',
                    email: ''
                },
                {
                    type: 'Secondary Contact',
                    fullname: '',
                    role: '',
                    mobile: '',
                    landline: '',
                    email: ''
                }
            ],
            contactInfo: '',
            lastorderdate: '',
            totalbalance: 0,
            totalOrders: 0,
            total30days: 0,
            total12months: 0,
            totalorders30days: 0,
            totalorders12months: 0,
            all30daysmedian: 0,
            all12monthsmedian: 0,
            alltimemedian: 0,
            history: [],
            allowed: true,
            categoryBreakdown: null,
            creditEarnedBalance: {
                "earnedpast30days": 0,
                "earnedpast12days": 0,
                "earnedalltime": 0,
                "balance": 0,
                "creditUsageHistory": [],
                "totalCreditUsed": "Rs 0"
            },
            log: [],
            monthly_overview_emails: "",
            corporate_account_notifications: "",
            send_annual_subscription_reminders: "",
            corporate_newsletters: "",
            special_corporate_offers: "",
            corporate_coupons: "",
            notifications_emails: [],
            add_notification_email: '',
            applicableForExtension: '',
            expirationActivated: '',
            corporate_status: '',
            corporate_expiration_date: '',
            readonlyExpirationActivated: false,
            corporate_expiration_date_overide: '',
            orderHistoryPage : 1,
            orderHistoryLimit : 20,
            orderHistoryTotal : 0,
        };
    }

    componentDidMount() {
        AuthService.checklogin().then(user => {
            this.setState({
                user: user
            });

            // console.log({ user });
            if(user.user_nicename!='uri') {
                this.setState({
                    readonlyExpirationActivated: true
                });
            }
            AuthService.getUserPermisions(user)
            .then(response => {
                var userpermissions = response.data;
                // console.log({userpermissions});
                    if(userpermissions.includes('corporatenew') || userpermissions.includes('all')) {
                    // console.log('You are allowed!');
                    this.setState({
                        allowed: true
                    });
                    setTimeout(() => {
                        if (typeof this.props.params.id == 'undefined') {
                            this.setState({
                                user_id: 0,
                            });
                        } else {
                            this.setState({
                                user_id: this.props.params.id,
                            });
                            if (this.state.user_id != 0) {
                                this.getData();
                                // change value of #corporate-gen-password-user-id to this.state.user_id
                            }
                        }
                    }, 2000);
                } else {
                    console.log('You are not allowed!');
                    this.setState({
                        allowed: false
                    });
                }
            })
        }).catch(error => {
            window.location.href = '/login';
        });
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
        // console.log('handle change');
    }
    getOrderHistoryPage = (page) => {
        this.setState({
            orderHistoryPage: page
        });
        CorporateNewService.getOrderHistory(this.state.user_id, page, this.state.orderHistoryLimit)
        .then(response => {
            // console.log("getOrderHistoryPage", response);
            this.setState({
                history: response.data.history,
            });
        })
        .catch(error => {
            console.log({error});
        });
    }

    getOrderHistory = () => {
        CorporateNewService.getOrderHistory(this.state.user_id, 0, this.state.orderHistoryLimit)
        .then(response => {
            // console.log("getOrderHistory", response);
            this.setState({
                orderHistoryTotal: response.data.count,
                orderHistoryPage: 1,
            });

            if(response.data.count>0) {
                this.getOrderHistoryPage(1);
            }
        })
        .catch(error => {
            console.log({error});
        });
    }

    getCorporateLogs = () => {
        this.setState({
            log: []
        });

        CorporateNewService.getCorporateLogs(this.state.user_id)
        .then(response => {
            // console.log({response});
            this.setState({
                log: response.data
            });
        })
        .catch(error => {
            console.log({error});
        });
    }

    getData = () => {
        this.setState({
            showloader: true
        });
        // console.log(this.state);
        CorporateNewService.getCorporateDetails(this.state.user_id)
        .then(response => {
            // console.log({response});
            if(response.data!=null) {
                this.setState({
                    detailsCompany: response.data.basic.companyName,
                    detailsLandline: response.data.basic.landline,
                    detailsAddress: response.data.basic.address,
                    detailsEmail: response.data.basic.email,
                    detailsAccountCreationDate: response.data.basic.accountdate,
                    detailsNoOfStaff: response.data.basic.staff,
                    detailsDiscountCode: response.data.basic.couponCode,
                    detailsTotalSalesAllTime: response.data.basic.totalsales,
                    detailsRequirements: response.data.basic.requirements,
                    contactInfo: response.data.contactInfo,
                    lastorderdate: response.data.basic.lastorderdate,
                    totalbalance: response.data.totalbalance,
                    totalOrders: response.data.totalOrders,
                    total30days: response.data.total30days,
                    total12months: response.data.total12months,
                    totalorders30days: response.data.totalorders30days,
                    totalorders12months: response.data.totalorders12months,
                    all30daysmedian: response.data.all30daysmedian,
                    all12monthsmedian: response.data.all12monthsmedian,
                    alltimemedian: response.data.alltimemedian,
                    // history: response.data.history,
                    detailsAccountLineOfBusiness: response.data.basic.lineofBusiness,
                    detailsSecondaryEmail: response.data.basic.secondaryEmail,
                    detailsWebsite: response.data.basic.website,
                    applicableForExtension: response.data.basic.applicableForExtension,
                    expirationActivated: response.data.basic.expirationActivated,
                    corporate_status: response.data.basic.corporate_status,
                    corporate_expiration_date: response.data.basic.corporate_expiration_date,
                    // convert response.data.basic.corporate_expiration_date_overide to date from Y-m-d
                    // corporate_expiration_date_overide: response.data.basic.corporate_expiration_date_overide==null ? '' : new Date(response.data.basic.corporate_expiration_date_overide),
                });

                if(response.data.contacts.length>0) {
                    this.setState({
                        contacts: response.data.contacts    
                    });
                }

                this.getCategoryBreakdown('alltime');
                this.getOrderHistory();
                this.getCorporateCreditEarnedBalance();
                this.getCorporateMeta();
                this.getCorporateLogs();

                this.setState({
                    showloader: false
                });
            }
        })
        .catch(error => {
            console.log({error});
            this.setState({
                showloader: false
            });
        });
    }

    

    getCategoryBreakdown = (period = 'alltime') => {
        this.setState({
            categoryBreakdown: null
        });
        CorporateNewService.corporateBreakdownByCategory(this.state.user_id, period)
        .then(response => {
            // console.log("breakdown", response);
            this.setState({
                categoryBreakdown: response.data
            });
            // this.getCorporateCreditEarnedBalance();
        })
        .catch(error => {
            console.log({error});
        });
    }

    getCorporateCreditEarnedBalance = () => {
        
        // this.setState({
        //     creditEarnedBalance: null
        // });

        CorporateNewService.corporateCreditEarnedBalance(this.state.user_id)
        .then(response => {
            // console.log("creditEarnedBalance1", response);
            this.setState({
                creditEarnedBalance: response.data
            });
            // setTimeout(() => {
            // console.log("creditEarnedBalance2", this.state.creditEarnedBalance);
            // }, 2000);
        })
        .catch(error => {
            console.log({error});
        });
    }

    changeContact = (index, key, value) => {
        var contactsTemp = this.state.contacts;

        if(key=='name') {
            contactsTemp[index].fullname = value;
        } else if(key=='role') {
            contactsTemp[index].role = value;
        } else if(key=='mobile') {
            contactsTemp[index].mobile = value;
        } else if(key=='landline') {
            contactsTemp[index].landline = value;
        } else if(key=='email') {
            contactsTemp[index].email = value;
        }

        this.setState({
            contacts: contactsTemp
        });
    }

    addMoreContact = () => {
        var contactsTemp = this.state.contacts;
        contactsTemp.push({
            type: 'Secondary Contact',
            fullname: '',
            role: '',
            mobile: '',
            landline: '',
            email: ''
        });
        contactsTemp.push({
            type: 'Secondary Contact',
            fullname: '',
            role: '',
            mobile: '',
            landline: '',
            email: ''
        });

        this.setState({
            contacts: contactsTemp
        });
    }

    saveDetails = () => {
        // console.log('clicked');

        CorporateNewService.saveCorporateDetails(this.state.user_id, this.state.detailsCompany, this.state.detailsLandline, this.state.detailsAddress, this.state.detailsDiscountCode, this.state.detailsNoOfStaff, this.state.detailsRequirements, this.state.contacts, this.state.contactInfo, this.state.user.user_nicename)
        .then(response => {
            // console.log({response});
            // swal('Details Updated!', 'The company details have been updated', 'info');
            toast("The details have been updated");
            this.getCorporateLogs();
        })
        .catch(error => {
            console.log({error});
        });
    }

    updateCorporateOverview = (e) => {
        e.preventDefault();

        CorporateNewService.updateCorporateOverview(this.state.user_id, this.state.detailsCompany, this.state.detailsLandline, this.state.detailsAddress, this.state.detailsNoOfStaff, this.state.detailsRequirements, this.state.detailsAccountLineOfBusiness, this.state.detailsSecondaryEmail, this.state.detailsWebsite, this.state.user.user_nicename)
        .then(response => {
            // console.log({response});
            toast("Corporate Overview Updated!");
            this.getCorporateLogs();
        })
        .catch(error => {
            console.log({error});
        });
    }

    updateCorporateMetaValue = (key, value) => {
        this.setState({
            [key]: value
        });
        CorporateNewService.updateCorporateMetaValue(this.state.user_id, key, value, this.state.user.user_nicename)
        .then(response => {
            // console.log({response});
            toast("Data Updated!");
            this.getCorporateLogs();
        })
        .catch(error => {
            console.log({error});
        });
    }

    getCorporateMeta = () => {
        CorporateNewService.getCorporateMeta(this.state.user_id)
        .then(response => {
            // console.log("getCorporateMeta",response);
            if(response.data!=null) {
                this.setState({
                    monthly_overview_emails: response.data.monthly_overview_emails,
                    corporate_account_notifications: response.data.corporate_account_notifications,
                    send_annual_subscription_reminders: response.data.send_annual_subscription_reminders,
                    corporate_newsletters: response.data.corporate_newsletters,
                    special_corporate_offers: response.data.special_corporate_offers,
                    corporate_coupons: response.data.corporate_coupons,
                    notifications_emails: response.data.notifications_emails
                });
            }
        })
        .catch(error => {
            console.log({error});
        });
    }

    removeNotificationsEmail = (index, email) => {
        var notifications_emails = this.state.notifications_emails;
        notifications_emails.splice(index, 1);
        this.setState({
            notifications_emails: notifications_emails
        });
        this.updateCorporateMetaValue('notifications_emails', notifications_emails);
    }

    addNotificationsEmail = () => {
        var notifications_emails = this.state.notifications_emails;
        notifications_emails.push(this.state.add_notification_email);
        this.setState({
            notifications_emails: notifications_emails,
            add_notification_email: ''
        });
        this.updateCorporateMetaValue('notifications_emails', notifications_emails);
    }

    setCorporateExpirationDate = (date) => { 
        // format date to yyyy-mm-dd
        var date = new Date(date);
        // date = date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate();
        this.setState({
            corporate_expiration_date_overide: date
        });
        console.log({date});
        this.updateCorporateMetaValue('corporate_expiration_date_overide', date);
        // this.updateCorporateMetaValue('corporate_expiration_date', date);
        // toast("Expiration Date Updated!");
    }

    render() {

        if(this.state.allowed==false) {
            return (
                <NoPermission />
            );
        }

        var creditEarnedBalanceRaw = this.state.creditEarnedBalance;
        
        return (
            <>
            <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
            />
            {/* <Tooltip id="corporate-balance-tooltip" /> */}
            <div className="row">
                <div className='col-12'>
                    <h3><AccountBoxIcon fontSize="large" /> Corporate Customer Details</h3>
                    <hr />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    {(this.state.showloader == true) &&
                        <Loader className='loader-center'
                            type="Grid"
                            color="#00BFFF"
                            height={100}
                            width={100}
                        />
                    }
                </div>
            </div>
            {this.state.showloader==false &&    
            <div>           
            <div className="row" style={{marginBottom: '20px'}}>
                <div className="col" style={{display: 'flex'}}>
                    <span style={{backgroundColor: '#ffe7ba', padding: '5px 10px', fontWeight: 'bold', border: '1px solid grey', flexGrow: '1', width: "100%", textAlign:"center"}}>Corporate Overview:</span>    
                </div>    
            </div>
            <div className="row">
                <div className="col">
                    <div className="form-group row">
                        <label for="detailsCompany" className="col-sm-3 col-form-label">Company Name:</label>
                        <div className="col-sm-9">
                        <input type="text" className="form-control" id="detailsCompany" name="detailsCompany" value={this.state.detailsCompany} onChange={this.handleChange.bind(this)} placeholder="Company Name" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label for="detailsAddress" className="col-sm-3 col-form-label">Company Location (address):</label>
                        <div className="col-sm-9">
                        <input type="text" className="form-control" id="detailsAddress" name="detailsAddress" value={this.state.detailsAddress} onChange={this.handleChange.bind(this)} placeholder="Address" />
                        </div>
                    </div>
                    
                    <div className="form-group row">
                        <label for="detailsEmail" className="col-sm-3 col-form-label">Main Email:</label>
                        <div className="col-sm-9">
                        <input type="text" className="form-control" id="detailsEmail" name="detailsEmail" value={this.state.detailsEmail} onChange={this.handleChange.bind(this)} placeholder="Email"  readOnly />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label for="detailsSecondaryEmail" className="col-sm-3 col-form-label">Secondary Email:</label>
                        <div className="col-sm-9">
                        <input type="text" className="form-control" id="detailsSecondaryEmail" name="detailsSecondaryEmail" value={this.state.detailsSecondaryEmail} onChange={this.handleChange.bind(this)} placeholder="Secondary Email" />
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="form-group row">
                        <label for="detailsAccountLineOfBusiness" className="col-sm-3 col-form-label">Corporate Line of Business:</label>
                        <div className="col-sm-9">
                        <input type="text" className="form-control" id="detailsAccountLineOfBusiness" name="detailsAccountLineOfBusiness" value={this.state.detailsAccountLineOfBusiness} onChange={this.handleChange.bind(this)} placeholder="Corporate Line of Business"  />
                        </div>
                    </div>
                    {/* <div className="form-group row">
                        <label for="detailsAccountCreationDate" className="col-sm-3 col-form-label">Account Creation Date:</label>
                        <div className="col-sm-9">
                        <input type="text" className="form-control" id="detailsAccountCreationDate" name="detailsAccountCreationDate" value={this.state.detailsAccountCreationDate} onChange={this.handleChange.bind(this)} placeholder="Account Creation Date"  readOnly />
                        </div>
                    </div> */}
                    <div className="form-group row">
                        <label for="detailsNoOfStaff" className="col-sm-3 col-form-label">No. of Staff</label>
                        <div className="col-sm-9">
                        <input type="number" className="form-control" id="detailsNoOfStaff" name="detailsNoOfStaff" value={this.state.detailsNoOfStaff} onChange={this.handleChange.bind(this)} placeholder="No. of Staff" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label for="detailsLandline" className="col-sm-3 col-form-label">Landline:</label>
                        <div className="col-sm-9">
                        <input type="number" className="form-control" id="detailsLandline" name="detailsLandline" value={this.state.detailsLandline} onChange={this.handleChange.bind(this)} placeholder="Landline" />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label for="detailsWebsite" className="col-sm-3 col-form-label">Website:</label>
                        <div className="col-sm-9">
                        <input type="text" className="form-control" id="detailsWebsite" name="detailsWebsite" value={this.state.detailsWebsite} onChange={this.handleChange.bind(this)} placeholder="Website" />
                        </div>
                    </div>
                    
                    {/* <div className="form-group row">
                        <label for="detailsDiscountCode" className="col-sm-3 col-form-label">Discount Coupon Code</label>
                        <div className="col-sm-9">
                        <input type="text" className="form-control" id="detailsDiscountCode" name="detailsDiscountCode" value={this.state.detailsDiscountCode} onChange={this.handleChange.bind(this)} placeholder="Discount Coupon Code" readOnly />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label for="detailsTotalSalesAllTime" className="col-sm-3 col-form-label">Total Sales (all time)</label>
                        <div className="col-sm-9">
                        <input type="text" className="form-control" id="detailsTotalSalesAllTime" name="detailsTotalSalesAllTime" value={this.state.detailsTotalSalesAllTime} onChange={this.handleChange.bind(this)} placeholder="Total Sales (all time)"  readOnly />
                        </div>
                    </div> */}
                </div>  
            </div>
            <div className="row" style={{marginBottom: '20px'}}>
                <div className="col">
                    <label>Main Requirement / Overview on the Corporate and Services Required:</label>
                    <textarea className="form-control" style={{width: '100%', height: '110px'}} name="detailsRequirements" value={this.state.detailsRequirements} onChange={this.handleChange.bind(this)} placeholder="Enter additional information or requirement, such as what type of services they require from Deals.mu, expected booking frequency, booking for management or staff etc"></textarea>

                    <button className="btn btn-sm btn-success" style={{float: "right", marginTop:"10px"}} onClick={e => this.updateCorporateOverview(e)}>Update Corporate Overview</button>
                </div>
            </div>

            {/* Account Overview */}
            <div className="row" style={{marginBottom: '20px'}}>
                <div className="col" style={{display: 'flex'}}>
                    <span style={{backgroundColor: '#adffad', padding: '5px 10px', fontWeight: 'bold', border: '1px solid grey', flexGrow: '1', width: "100%", textAlign:"center"}}>Account Overview:</span>    
                </div>    
            </div>
            <div className="row" style={{marginBottom: '20px'}}>
                <div className="col">
                    <div className="overview-item" style={{display: "flex", alignItems: "center", justifyContent: "flex-start"}}>
                        <div className="overview-item-left" style={{width: "50%"}}><b>Account Status</b></div>
                        <div className="overview-item-right">
                            {(this.state.corporate_status=="Active" || this.state.corporate_status=="") && 
                                <span style={{fontWeight:"bold", color:"green"}}>Active</span>
                            }
                            {(this.state.corporate_status=="About to Expire") && 
                                <span style={{fontWeight:"bold", color:"purple"}}>About to Expire</span>
                            }
                            {(this.state.corporate_status=="Expired") && 
                                <span style={{fontWeight:"bold", color:"red"}}>Expired</span>
                            }
                        </div>
                    </div>
                    <div className="overview-item" style={{display: "flex", alignItems: "center", justifyContent: "flex-start", marginBottom:"20px"}}>
                        <div className="overview-item-left" style={{width: "50%"}}><b>Account Expiration Activated</b></div>
                        <div className="overview-item-right">
                            <select className="form-control" value={this.state.expirationActivated} onChange={e => this.updateCorporateMetaValue("expiration_activated", e.target.value)}>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                        </div>
                    </div>
                    <div className="overview-item" style={{display: "flex", alignItems: "center", justifyContent: "flex-start"}}>
                        <div className="overview-item-left" style={{width: "50%"}}><b>Account Creation Date</b></div>
                        <div className="overview-item-right">{this.state.detailsAccountCreationDate}</div>
                    </div>
                    <div className="overview-item" style={{display: "flex", alignItems: "center", justifyContent: "flex-start", marginBottom:"20px"}}>
                        <div className="overview-item-left" style={{width: "50%"}}><b>Account Expiration Date</b></div>
                        <div className="overview-item-right">{this.state.corporate_expiration_date}</div>
                    </div>
                    {(this.state.readonlyExpirationActivated==false || 1==1) && 
                    <div className="overview-item" style={{display: "flex", alignItems: "center", justifyContent: "flex-start", marginBottom:"20px"}}>
                        <div className="overview-item-left" style={{width: "50%"}}><b>Override Account Expiration Date</b></div>
                        <div className="overview-item-right" style={{display: "flex", flexDirection:"column"}}>
                            <DatePicker
                                dateFormat="dd-MM-yyyy"
                                selected={this.state.corporate_expiration_date_overide}
                                onChange={date => this.setState({corporate_expiration_date_overide:date})}
                                placeholderText="Expiry Date"
                                className='form-control'
                            />
                            {this.state.corporate_expiration_date_overide!="" && 
                            <>
                                <button className="btn btn-sm btn-success" onClick={e => this.setCorporateExpirationDate(this.state.corporate_expiration_date_overide)} style={{marginTop:"10px"}}>Update Custom Expiry Date</button>
                                <button className="btn btn-sm btn-danger" onClick={e => this.updateCorporateMetaValue("corporate_expiration_date_overide", "")} style={{marginTop:"10px"}}>Remove Custom Expiry Date</button>
                            </>
                            }
                            {/* <input className="form-control" value={this.state.corporate_expiration_date_overide} /> */}
                        </div>
                    </div>
                    }
                    <div className="overview-item" style={{display: "flex", alignItems: "center", justifyContent: "flex-start"}}>
                        <div className="overview-item-left" style={{width: "50%"}}><b>Total Order Amount (past 12 months)</b></div>
                        <div className="overview-item-right">{this.state.total12months}</div>
                    </div>
                    <div className="overview-item" style={{display: "flex", alignItems: "center", justifyContent: "flex-start"}}>
                        <div className="overview-item-left" style={{width: "50%"}}><b>Account Applicable for Extension</b></div>
                        <div className="overview-item-right">
                            {(this.state.applicableForExtension=="Yes" || this.state.applicableForExtension=="") && 
                                <span style={{fontWeight:"bold", color:"green"}}>Yes</span>
                            }
                            {this.state.applicableForExtension=="No" && 
                                <span style={{fontWeight:"bold", color:"red"}}>No</span>
                            }
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="section-title" style={{marginBottom:"20px"}}><b>Actions:</b></div>
                    <button className="btn btn-sm btn-primary corporate-gen-password-btn">Reset Password</button>   
                </div>
            </div>
            <div className="row" style={{marginBottom: '20px'}}>
                <div className="col" style={{display: 'flex', flexDirection: "column"}}>
                    <div style={{fontWeight: "bold", textDecoration:"underline"}}>Notes:</div>
                    <textarea className="form-control" style={{width: '100%', height: '110px'}} name="detailsNotes" value={this.state.detailsNotes} onChange={this.handleChange.bind(this)} placeholder="Enter additional notes"></textarea>
                </div>    
            </div>
            {/* End Account Overview */}

            {/* Sales Report */}
            <div className="row" style={{marginBottom: '20px'}}>
                <div className="col" style={{display: 'flex'}}>
                    <span style={{backgroundColor: '#eda3a3', padding: '5px 10px', fontWeight: 'bold', border: '1px solid grey', flexGrow: '1', width: "100%"}}>Sales Report:</span>    
                </div>    
            </div>
            <div className="row" style={{marginBottom: '20px'}}>
                <div className="col" style={{display: 'flex', justifyContent:"space-between", alignItems: "center"}}>
                    <div style={{fontWeight: 'bold'}}>Sales Summary:</div>
                    <div style={{border: "1px solid grey", padding:"2px 5px", display: "flex"}}>
                        <div style={{marginRight:"40px", fontWeight: "600"}}>Last Order Date:</div>
                        <div style={{color: "blue", fontWeight: "600"}}>{this.state.lastorderdate}</div>
                    </div>
                </div>    
            </div>
            <div className="row" style={{marginBottom: '20px'}}>
                <div className="col">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Period</th>
                                <th>Number of Orders</th>
                                <th>Total Order Amount</th>
                                <th>Average Order Amount</th>
                                <th>Median</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td scope="row" style={{color: "blue"}}>Past 30 days</td>
                                <td>{this.state.totalorders30days}</td>
                                <td>{this.state.total30days}</td>
                                <td>Rs {(parseFloat(this.state.total30days.replace("Rs ", "").replace(",", ""))/parseFloat(this.state.totalorders30days)).toLocaleString()}</td>
                                <td>Rs {this.state.all30daysmedian}</td>
                            </tr>
                            <tr>
                                <td scope="row" style={{color: "blue"}}>Past 12 months</td>
                                <td>{this.state.totalorders12months}</td>
                                <td>{this.state.total12months}</td>
                                <td>Rs {(parseFloat(this.state.total12months.replace("Rs ", "").replace(",", ""))/parseFloat(this.state.totalorders12months)).toLocaleString()}</td>
                                <td>Rs {this.state.all12monthsmedian}</td>
                            </tr>
                            <tr>
                                <td scope="row" style={{color: "blue"}}>All Time Sales</td>
                                <td>{this.state.totalOrders}</td>
                                <td>{this.state.detailsTotalSalesAllTime}</td>
                                <td>Rs {(parseFloat(this.state.detailsTotalSalesAllTime.replace("Rs ", "").replace(",", ""))/parseFloat(this.state.totalOrders)).toLocaleString()}</td>
                                <td>Rs {this.state.alltimemedian}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="row" style={{marginBottom: '20px'}}>
                <div className="col" style={{display: 'flex', justifyContent:"space-between", alignItems: "center"}}>
                    <div style={{fontWeight: 'bold'}}>Breakdown by Category:</div>
                    <div>
                        <button className="btn btn-sm btn-primary" onClick={e => this.getCategoryBreakdown('alltime')} style={{marginRight: "20px"}}>All Time</button>
                        <button className="btn btn-sm btn-primary" onClick={e => this.getCategoryBreakdown('12months')} style={{marginRight: "20px"}}>Past 12 Months</button>
                        <button className="btn btn-sm btn-primary" onClick={e => this.getCategoryBreakdown('30days')}>Past 30 Days</button>
                    </div>
                </div>    
            </div>
            <div className="row" style={{marginBottom: '20px'}}>
                <div className="col">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Category</th>
                                <th>Number of Orders/Items</th>
                                <th>Total Order Amount</th>
                                <th>Average Order Amount</th>
                            </tr>
                        </thead>
                        {this.state.categoryBreakdown!=null && 
                        <tbody>
                            <tr>
                                <td scope="row" style={{color: "blue"}}>Accommodation</td>
                                <td>{this.state.categoryBreakdown.accommodation.orders}</td>
                                <td>Rs {this.state.categoryBreakdown.accommodation.total}</td>
                                <td>Rs
                                    {this.state.categoryBreakdown.accommodation.orders>0 &&
                                        " "+Math.round(this.state.categoryBreakdown.accommodation.total/this.state.categoryBreakdown.accommodation.orders)
                                    } 
                                    {this.state.categoryBreakdown.accommodation.orders==0 && 
                                        " 0"
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td scope="row" style={{color: "blue"}}>Day Packages</td>
                                <td>{this.state.categoryBreakdown.daypackages.orders}</td>
                                <td>Rs {this.state.categoryBreakdown.daypackages.total}</td>
                                <td>Rs
                                    {this.state.categoryBreakdown.daypackages.orders>0 &&
                                        " "+Math.round(this.state.categoryBreakdown.daypackages.total/this.state.categoryBreakdown.daypackages.orders)
                                    } 
                                    {this.state.categoryBreakdown.daypackages.orders==0 && 
                                        " 0"
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td scope="row" style={{color: "blue"}}>Spa</td>
                                <td>{this.state.categoryBreakdown.spa.orders}</td>
                                <td>Rs {this.state.categoryBreakdown.spa.total}</td>
                                <td>Rs
                                    {this.state.categoryBreakdown.spa.orders>0 &&
                                        " "+Math.round(this.state.categoryBreakdown.spa.total/this.state.categoryBreakdown.spa.orders)
                                    } 
                                    {this.state.categoryBreakdown.spa.orders==0 && 
                                        " 0"
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td scope="row" style={{color: "blue"}}>Activities</td>
                                <td>{this.state.categoryBreakdown.activities.orders}</td>
                                <td>Rs {this.state.categoryBreakdown.activities.total}</td>
                                <td>Rs
                                    {this.state.categoryBreakdown.activities.orders>0 &&
                                        " "+Math.round(this.state.categoryBreakdown.activities.total/this.state.categoryBreakdown.activities.orders)
                                    } 
                                    {this.state.categoryBreakdown.activities.orders==0 && 
                                        " 0"
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td scope="row" style={{color: "blue"}}>Restaurant</td>
                                <td>{this.state.categoryBreakdown.restaurants.orders}</td>
                                <td>Rs {this.state.categoryBreakdown.restaurants.total}</td>
                                <td>Rs
                                    {this.state.categoryBreakdown.restaurants.orders>0 &&
                                        " "+Math.round(this.state.categoryBreakdown.restaurants.total/this.state.categoryBreakdown.restaurants.orders)
                                    } 
                                    {this.state.categoryBreakdown.restaurants.orders==0 && 
                                        " 0"
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td scope="row" style={{color: "blue"}}>Rodrigues</td>
                                <td>{this.state.categoryBreakdown.rodrigues.orders}</td>
                                <td>Rs {this.state.categoryBreakdown.rodrigues.total}</td>
                                <td>Rs
                                    {this.state.categoryBreakdown.rodrigues.orders>0 &&
                                        " "+Math.round(this.state.categoryBreakdown.rodrigues.total/this.state.categoryBreakdown.rodrigues.orders)
                                    } 
                                    {this.state.categoryBreakdown.rodrigues.orders==0 && 
                                        " 0"
                                    }
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">Total</th>
                                <th>{this.state.categoryBreakdown.total.orders}</th>
                                <th>Rs {this.state.categoryBreakdown.total.total}</th>
                                <th>
                                    Rs
                                    {this.state.categoryBreakdown.total.orders>0 &&
                                        " "+Math.round(this.state.categoryBreakdown.total.total/this.state.categoryBreakdown.total.orders)
                                    } 
                                    {this.state.categoryBreakdown.total.orders==0 && 
                                        " 0"
                                    }
                                </th>
                            </tr>
                        </tbody>
                        }
                    </table>
                </div>
            </div>
            
            {/* Add charts here */}
            {/* {this.state.user_id>0 &&  */}
                <Corporatecharts userID={this.state.user_id} />
            {/* } */}
            {/* End Sales Report */}

            {/* Corporate credit overview */}
            <div className="row" style={{marginBottom: '20px'}}>
                <div className="col" style={{display: 'flex'}}>
                    <span style={{backgroundColor: '#eda3a3', padding: '5px 10px', fontWeight: 'bold', border: '1px solid grey', flexGrow: '1', width: "100%"}}>Corporate Credit Overview:</span>    
                </div>    
            </div>
            <div className="row" style={{marginBottom: "20px"}}>
                <div className="col" style={{display: "flex", alignItems: "center"}}>
                    <div style={{border: "1px solid black", color:"blue", display: "flex", justifyContent:"space-between", padding:"2px 5px", width: "50%", fontWeight: "bold"}}>
                        <div>Current Corporate Incentive Credit Balance:</div>
                        <div>Rs {creditEarnedBalanceRaw.balance}</div>
                    </div>
                    <div style={{marginLeft: "10px"}} data-text="This is the credit balance available for the Corporate on their corporate account for the bookigns done using the corporate code. It indicates the existing balance as of today and now" className="tooltipn right"><FontAwesomeIcon icon={faQuestionCircle} /></div>
                </div>
            </div>
            <div className="row" style={{marginBottom: "20px"}}>
                <div className="col">
                    <table class="table table-bordered" style={{width: "30%"}}>
                        <thead>
                            <tr>
                                <th>Period</th>
                                <th>Credit Amount Earned</th>
                            </tr>
                        </thead>
                        {this.state.creditEarnedBalance!=null && 
                        <tbody>
                            <tr>
                                <td>Past 30 days</td>
                                <td>{"Rs "+this.state.creditEarnedBalance.earnedpast30days}</td>
                            </tr>
                            <tr>
                                <td>Past 12 months</td>
                                <td>{"Rs "+this.state.creditEarnedBalance.earnedpast12days}</td>
                            </tr>
                            <tr>
                                <td>All Time Sales</td>
                                <td>{"Rs "+this.state.creditEarnedBalance.earnedalltime}</td>
                            </tr>
                        </tbody>
                        }
                    </table>
                </div>
            </div>
            <div className="row">
                <div className="col" style={{display: "flex", flexDirection: "column"}}>
                    <div style={{fontWeight: "bold", display:"flex"}}>Credit Usage history: <div style={{marginLeft: "10px"}} data-text="In the table below you will find all the transactions processed by the Corporate in which the corporate utilized their corporate incentive credit" className="tooltipn right"><FontAwesomeIcon icon={faQuestionCircle} /></div></div>
                    <div>
                        <table class="table table-bordered" style={{width: "100%"}}>
                            <thead>
                                <tr>
                                    <th>Claimed Date</th>
                                    <th>Usage Date</th>
                                    <th>Order ID</th>
                                    <th>Total Order Amount</th>
                                    <th>Credit Amount Used</th>
                                    <th>Net Amount Paid</th>
                                    <th>Product Purchased</th>
                                    <th>Coupons Used</th>
                                </tr>
                            </thead>
                            {this.state.creditEarnedBalance!=null && 
                            <tbody>
                                {this.state.creditEarnedBalance.creditUsageHistory.length>0 && this.state.creditEarnedBalance.creditUsageHistory.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.claimeddate}</td>
                                    <td>{item.orderdate}</td>
                                    <td><a href={`/edit-order/${item.orderID}`} target="_blank">{item.orderID}</a></td>
                                    <td>{item.ordertotal}</td>
                                    <td>{item.discount}</td>
                                    <td>{item.subtotal}</td>
                                    <td>{item.products}</td>
                                    <td>{item.coupons}</td>
                                </tr>
                                ))}
                                
                                <tr>
                                    <td colSpan={3}><b>Total Credit Used</b></td>
                                    <td></td>
                                    <td>{this.state.creditEarnedBalance.totalCreditUsed}</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                            }
                        </table>
                    </div>
                </div>
            </div>
            {/* End Corporate credit overview */}

            {/* Order details and history */}
            <div className="row" style={{marginBottom: '20px'}}>
                <div className="col" style={{display: 'flex'}}>
                    <span style={{backgroundColor: '#f7d7ff', padding: '5px 10px', fontWeight: 'bold', border: '1px solid grey', flexGrow: '1', width: "100%"}}>Order Details & History:</span>    
                </div>    
            </div>
            <div className="row" style={{marginBottom: '20px'}}>
                <div className="col">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>Order ID</th>
                                <th>Order Date</th>
                                <th>Customer Name</th>
                                <th>Category</th>
                                <th>Order Amount</th>
                                <th>Discount Amount</th>
                                <th>Corporate Incentive</th>
                                <th>Order Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.history.map((order, index) => (
                                <tr key={index}>
                                    <td><a href={`/edit-order/${order.order_id}`} target="_blank">{order.order_id}</a></td>
                                    <td>{order.order_date}</td>
                                    <td>{order.customer}</td>
                                    <td>{order.categories}</td>
                                    <td>{order.ordertotal}</td>
                                    <td>{order.discount}</td>
                                    <td>{order.amount}</td>
                                    <td>
                                        {order.status=='Pending' && 
                                            <span style={{color: "blue"}}>Pending</span>
                                        }
                                        {(order.status=='Processing' || order.status=='Completed') && 
                                            <span style={{color: "green"}}>Processing</span>
                                        }
                                        {(order.status=='Cancelled' || order.status=='Refunded') &&
                                            <span style={{color: "red"}}>Cancelled</span>
                                        }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <Pagination total={this.state.orderHistoryTotal} current={this.state.orderHistoryPage} limit={this.state.orderHistoryLimit} onPageChange={e => this.getOrderHistoryPage(e)} />
                </div>
            </div>
            {/* End order details and history */}

            {/* Corporate Notifications */}
            <div className="row" style={{marginBottom: '20px'}}>
                <div className="col" style={{display: 'flex'}}>
                    <span style={{backgroundColor: '#d7fffb', padding: '5px 10px', fontWeight: 'bold', border: '1px solid grey', flexGrow: '1', width: "100%"}}>Corporate Notification:</span>    
                </div>    
            </div>
            <div className="row" style={{marginBottom: "20px"}}>
                <div className="col">
                    <table class="table" style={{width: "100%"}}>
                        <thead>
                            <tr>
                                <th>Email Type</th>
                                <th>Enable Notification</th>
                                <th>Email Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Monthly Overview Emails:</td>
                                <td>
                                    <select className="form-control" style={{width: "100%"}} onChange={e => this.updateCorporateMetaValue("monthly_overview_emails", e.target.value)} value={this.state.monthly_overview_emails}>
                                        <option value="">Select an Option</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </td>
                                <td>Auto Sent</td>
                            </tr>
                            <tr>
                                <td>Corporate Account Notifications:</td>
                                <td>
                                    <select className="form-control" style={{width: "100%"}} onChange={e => this.updateCorporateMetaValue("corporate_account_notifications", e.target.value)} value={this.state.corporate_account_notifications}>
                                        <option value="">Select an Option</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </td>
                                <td>Auto Sent</td>
                            </tr>
                            <tr>
                                <td>Send Annual Subscription Reminders:</td>
                                <td>
                                    <select className="form-control" style={{width: "100%"}} onChange={e => this.updateCorporateMetaValue("send_annual_subscription_reminders", e.target.value)} value={this.state.send_annual_subscription_reminders}>
                                        <option value="">Select an Option</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </td>
                                <td>Auto Sent</td>
                            </tr>
                            <tr>
                                <td>Corporate Newsletters:</td>
                                <td>
                                    <select className="form-control" style={{width: "100%"}} onChange={e => this.updateCorporateMetaValue("corporate_newsletters", e.target.value)} value={this.state.corporate_newsletters}>
                                        <option value="">Select an Option</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </td>
                                <td>Manual</td>
                            </tr>
                            <tr>
                                <td>Special Corporate Offers (discount/last minute):</td>
                                <td>
                                    <select className="form-control" style={{width: "100%"}} onChange={e => this.updateCorporateMetaValue("special_corporate_offers", e.target.value)} value={this.state.special_corporate_offers}>
                                        <option value="">Select an Option</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </td>
                                <td>Manual</td>
                            </tr>
                            <tr>
                                <td>Corporate Coupons:</td>
                                <td>
                                    <select className="form-control" style={{width: "100%"}} onChange={e => this.updateCorporateMetaValue("corporate_coupons", e.target.value)} value={this.state.corporate_coupons}>
                                        <option value="">Select an Option</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </td>
                                <td>Manual</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="col">
                    <div className="section-title" style={{marginBottom:"20px"}}><b>Actions:</b></div>
                    <div style={{display: "flex"}}>
                        <div><b>Main Email Address:</b></div>
                        <div style={{marginLeft:"10px"}}>{this.state.detailsEmail}</div>
                        <div></div>
                    </div>
                    <div style={{marginTop:"10px"}}>
                        <div><b>Additional Emails:</b></div>
                        <div></div>
                        <div></div>
                    </div>
                    {this.state.notifications_emails.length>0 && this.state.notifications_emails.map((email, index) => (
                    <div style={{display: "flex", marginTop:"10px"}} key={index}>
                        <div></div>
                        <div style={{width:"300px"}}>{email}</div>
                        <div style={{marginLeft:"10px"}}><button className="btn btn-sm btn-danger" onClick={e => this.removeNotificationsEmail(index, email)}>Remove this Email</button></div>
                    </div>
                    ))}

                    <div style={{display: "flex", marginTop:"10px"}}>
                        <div></div>
                        <div style={{width:"300px"}}><input className="form-control" name="add_notification_email" value={this.state.add_notification_email} onChange={this.handleChange.bind(this)}  placeholder="Add new Email" /></div>
                        <div style={{marginLeft:"10px"}}><button className="btn btn-sm btn-success" onClick={e => this.addNotificationsEmail()}>Add this Email</button></div>
                    </div>
                </div>
            </div>
            <div className="row" style={{marginBottom: "20px"}}>
                <div className="col">
                    <div>Notes:</div>
                    <div>- we are to define the notification for emails / newsletters etc... to be sent to corporates, if yes, means that automaitcally sent. It is defined per each type. The agent choose from a dropdown. no manual input</div>
                    <div>- Automatically all emails are sent to the main email address, but also (same like Otayo), our agents can add additional email addresss for each corporate. </div>
                    <div>- The main email address can't be edited or removed. The 'additional emails' can't be edited but we can remove or add more such emails as per requirements</div>
                </div>
            </div>
            {/* End Corporate Notifications */}

            <div className="row" style={{marginBottom: '20px'}}>
                <div className="col" style={{display: 'flex'}}>
                    <span style={{backgroundColor: '#adffad', padding: '5px 10px', fontWeight: 'bold', border: '1px solid grey', flexGrow: '1'}}>Corporate Contacts:</span>    
                </div>    
            </div>
            <div className="row" style={{marginBottom: '20px'}}>
                {this.state.contacts.map((contact, index) => (
                <div className="col-6" key={index}>
                    <div className="form-group row"><div className="col" style={{textDecoration: "underline"}}>{contact.type}:</div></div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label">Full Name:</label>
                        <div className="col-sm-9">
                        <input type="text" className="form-control" value={contact.fullname} onChange={e => this.changeContact(index, 'name', e.target.value)} placeholder="Full Name:" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label">Role:</label>
                        <div className="col-sm-9">
                        <input type="text" className="form-control" value={contact.role} onChange={e => this.changeContact(index, 'role', e.target.value)} placeholder="Role" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label">Mobile:</label>
                        <div className="col-sm-9">
                        <input type="text" className="form-control" value={contact.mobile} onChange={e => this.changeContact(index, 'mobile', e.target.value)} placeholder="Mobile" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label">Landline:</label>
                        <div className="col-sm-9">
                        <input type="text" className="form-control" value={contact.landline} onChange={e => this.changeContact(index, 'landline', e.target.value)} placeholder="Landline" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label">Email:</label>
                        <div className="col-sm-9">
                        <input type="email" className="form-control" value={contact.email} onChange={e => this.changeContact(index, 'email', e.target.value)} placeholder="Email" />
                        </div>
                    </div>
                </div> 
                ))}    
            </div>
            <div className="row" style={{marginBottom: '20px'}}>
                <div className="col" style={{textAlign: 'right'}}>
                    <button className="btn btn-warning btn-sm" onClick={this.addMoreContact.bind(this)}>Add More Contacts</button>
                </div>
            </div>

            <div className="row" style={{marginBottom: '20px'}}>
                <div className="col">
                    <label>Additional Info:</label>
                    <textarea className="form-controm" style={{width: '100%', height: '110px'}} name="contactInfo" value={this.state.contactInfo} onChange={this.handleChange.bind(this)} placeholder="Enter additional information or requirements"></textarea>
                </div>
            </div>

            <div className="row" style={{marginBottom: '20px'}}>
                <div className="col" style={{width: "100%", display: "flex", justifyContent: "flex-end"}}>
                    <button className="btn btn-success btn-sm" style={{float: 'right'}} onClick={this.saveDetails.bind(this)}>Save / Update Details</button>
                </div>
            </div>

            {/* Logs */}
            <div className="row" style={{marginBottom: '20px'}}>
                <div className="col" style={{display: 'flex'}}>
                    <span style={{backgroundColor: '#d7fffb', padding: '5px 10px', fontWeight: 'bold', border: '1px solid grey', flexGrow: '1', width: "100%"}}>Logs:</span>    
                </div>    
            </div>
            <div className="row" style={{marginBottom: "20px"}}>
                <div className="col">
                    <table class="table table-bordered" style={{width: "100%"}}>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Agent</th>
                                <th>Section</th>
                                <th>Action</th>
                                <th>Data</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.log.length>0 && this.state.log.map((log, index) => (
                            <tr key={index}>
                                <td>{log.date_added}</td>
                                <td>{log.agent}</td>
                                <td>{log.section}</td>
                                <td>{log.action}</td>
                                <td>{log.data}</td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {/* End Logs */}
            

            {/* <div className="row" style={{marginBottom: '20px'}}>
                <div className="col" style={{display: 'flex'}}>
                    <span style={{backgroundColor: 'rgb(254 210 210)', padding: '10px', fontWeight: 'bold', border: '1px solid grey', flexGrow: '1'}}>Orders & Booking Summary:</span>    
                </div>    
            </div>
            <div className="row" style={{marginBottom: '20px'}}>
                <div className="col">
                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label">Discount Coupon Code:</label>
                        <div className="col-sm-8">
                        <input type="text" className="form-control" value={this.state.detailsDiscountCode}  placeholder="Discount Coupon Code" readOnly />
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label">Corporate Credit Balance:</label>
                        <div className="col-sm-8">
                        <input type="text" className="form-control" value={this.state.totalbalance} placeholder="Corporate Credit Balance" readOnly />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label">Last Order Date:</label>
                        <div className="col-sm-8">
                        <input type="text" className="form-control" value={this.state.lastorderdate} placeholder="Last Order Date" readOnly />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" style={{marginBottom: '20px'}}>
                <div className="col">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Order Details:</th>
                                <th>No. of Orders</th>
                                <th>Total Order Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">Past 30 days</th>
                                <td>{this.state.totalorders30days}</td>
                                <td>{this.state.total30days}</td>
                            </tr>
                            <tr>
                                <th scope="row">Past 12 months</th>
                                <td>{this.state.totalorders12months}</td>
                                <td>{this.state.total12months}</td>
                            </tr>   
                            <tr>
                                <th scope="row">All Time</th>
                                <td>{this.state.totalOrders}</td>
                                <td>{this.state.detailsTotalSalesAllTime}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div> */}

            {/* <div className="row">
                <div className="col">
                    <h5>Corporate Transaction & Credit History</h5>
                </div>
            </div>
            <div className="row" style={{marginBottom: '20px'}}>
                <div className="col">
                    <table class="table" style={{textAlign: "center"}}>
                        <thead>
                            <tr>
                                <th>Order ID</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Order Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.history.length>0 && this.state.history.map((entry, index) => (
                            <tr key={index}>
                                <th scope="row">{entry.order_id}</th>
                                <td>{entry.amount}</td>
                                <td>{entry.status}</td>
                                <td>{entry.order_date}</td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div> */}

            <div className="modal" tabindex="-1" role="dialog" id="corporate-gen-password-modal">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Generate / Update Password</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form className="corporate-gen-password-form" action="#">
                    <div className="modal-body">
                        <input type="hidden" data-user-id={this.state.user_id} value={this.state.user_id} id="corporate-gen-password-user-id" />
                        <input type="hidden" value="0" class="corporate-gen-password-notify" />
                        <div className="form-group">
                            <label for="corporate-gen-password-password">New Password</label>
                            <input type="text" className="form-control" id="corporate-gen-password-password" aria-describedby="passwordHelp" placeholder="Enter password" required="required" />
                            <small id="passwordHelp" className="form-text text-muted">Please enter the password and also save the password on your side as well as the password will be encrypted and can only be changed not seen later.</small>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-success save-password">Save & Exit</button>
                        <button type="button" className="btn btn-warning save-password-notify">Save & Notify Customer</button>
                    </div>
                    </form>
                    </div>
                </div>
            </div>

            </div>
            }
            </>
        );
    }
}