import React, { Component } from "react";
import Loader from 'react-loader-spinner';
import DTable from './dtable.component';
import swal from 'sweetalert';

import AuthService from '../services/auth/auth.service';
import UtilityService from '../services/utility.service';
import AppNotificationsService from '../services/erp/appnotifications.service';
import { responsiveFontSizes } from "@material-ui/core";
import UserContext from '../contexts/UserContext';
import NoPermission from "./extras/nopermission.component";

export default class Notifications extends Component {
    // static contextType = UserContext
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            columnsheadings: [],
            options: {
                filterType: 'dropdown',
            },
            show: false,
            error: {
                status: false,
                message: ''
            },
            loadingvalues: true,
            user: [],
            title: '',
            body: '',
            topic: '',
            image: '',
            url: '',
            success: '',
            allowed: true,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        console.log('state', this.state);

        this.setState({
            success: ''
        });

        AppNotificationsService.saveNotification(this.state.title, this.state.body, this.state.topic, this.state.image, this.state.url)
            .then(response => {
                if (response.data.length > 0) {
                    this.setState({
                        title: '',
                        body: '',
                        topic: '',
                        image: '',
                        url: '',
                        success: 'Notification Saved, Now sending notifications to Devices'
                    });

                    // AppNotificationsService.sendtofirebase(this.state.title, this.state.body, this.state.topic, this.state.image, this.state.url)
                    //     .then(response => {
                    //         console.log({ response });
                    //         this.setState({
                    //             success: 'Notification Sent!'
                    //         });
                    //     })
                    //     .catch(error => {
                    //         console.log({ error });
                    //         this.setState({
                    //             success: 'Error sending Notification. Please contact Admin.'
                    //         });
                    //     });
                }
            })
            .catch(error => {
                console.log({ error });

                this.setState({
                    success: 'There is an error Saving message. Try again.'
                });
            });
    }

    componentDidMount() {
        AuthService.checklogin().then(user => {
            this.setState({
                user: user
            });
            
            AuthService.getUserPermisions(user)
            .then(response => {
                var userpermissions = response.data;
                console.log({userpermissions});
                    if(userpermissions.includes('appnotifications') || userpermissions.includes('all')) {
                    console.log('You are allowed!');
                    this.loadNotifications(true);
                    this.setState({
                        allowed: true
                    });
                } else {
                    console.log('You are not allowed!');
                    this.setState({
                        allowed: false
                    });
                }
            })

            console.log({ user });
        }).catch(error => {
            window.location.href = '/login';
        });
    }

    loadNotifications = (all) => {
        this.setState({
            data: [],
            show: false,
            error: {
                status: false,
                message: ''
            },
            loadingvalues: true,
        });

        AppNotificationsService.getNotifications()
            .then(response => {
                // console.log({ response });
                if (response.data) {

                    this.setState({
                        data: response.data,
                        columnsheadings: [
                            { title: "ID" },
                            { title: "Title" },
                            { title: "Body" },
                            { title: "Topic" },
                            { title: "Image" },
                            { title: "Url" },
                            { title: "Sent On" },
                            { title: "Status" },
                        ],
                        show: true,
                        error: {
                            status: false,
                            message: ''
                        },
                        loadingvalues: false,
                    });
                } else {
                    this.setState({
                        data: [],
                        columnsheadings: [],
                        show: false,
                        error: {
                            status: true,
                            message: 'No data found'
                        },
                        loadingvalues: false,
                    });
                }
            })
            .catch(error => {
                console.log({ error });
                this.setState({
                    data: [],
                    columnsheadings: [],
                    show: false,
                    error: {
                        status: true,
                        message: 'No data found'
                    },
                    loadingvalues: false,
                });
            });
    }


    render() {
        var { data, columnsheadings, show, error, loadingvalues } = this.state;
        
        if(this.state.allowed==false) {
            return (
                <NoPermission />
            );
        }
        return (
            <>
                <div className="row">
                    <div className='col'>
                        <h2>All Notifications</h2>
                        <button type="button" className="btn btn-primary" data-toggle="modal" data-target=".create-notification-modal">Create Notification</button>
                        <hr />
                    </div>
                </div>
                <div className="row">
                    <div className='col'>
                        {loadingvalues == true &&
                            <p style={{ color: "#df153b" }}><b><i>Loading notifications. Please Wait....</i></b></p>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className='col'>
                        {error.status == true &&
                            <div className="alert alert-danger" role="alert">
                                {error.message}
                            </div>
                        }
                        {(show == true) &&
                            <DTable data={data} columns={columnsheadings} />
                        }
                        {show == false &&
                            <Loader className='loader-center'
                                type="Grid"
                                color="#00BFFF"
                                height={100}
                                width={100}
                            />
                        }
                    </div>
                </div>

                {/* Create coupon modal */}
                <div className="modal fade create-notification-modal" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Create Notification</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <form className="refund-create-notification-modal-form" onSubmit={this.handleSubmit}>
                                <div className="modal-body">
                                    <h4>Enter the details below:</h4>
                                    <div className="form-group">
                                        <label for="notification-form-title" className="col-form-label"><b>Title:</b></label>
                                        <input type="text" className="form-control" name="title" value={this.state.title} id="notification-form-title" required="required" placeholder="Enter Title" onChange={this.handleChange} />
                                    </div>
                                    <div className="form-group">
                                        <label for="notification-form-body" className="col-form-label"><b>Body:</b></label>
                                        <input type="text" className="form-control" name="body" value={this.state.body} id="notification-form-body" required="required" placeholder="Enter Description" onChange={this.handleChange} />
                                    </div>
                                    <div className="form-group">
                                        <label for="notification-form-topic" className="col-form-label"><b>Topic:</b></label>
                                        <input type="text" className="form-control" name="topic" value={this.state.topic} id="notification-form-topic" required="required" placeholder="Enter Topic" onChange={this.handleChange} />
                                    </div>
                                    <div className="form-group">
                                        <label for="notification-form-image" className="col-form-label"><b>Custom Image Link:</b></label>
                                        <input type="text" className="form-control" name="image" value={this.state.image} id="notification-form-image" required="required" placeholder="Enter Image Link" onChange={this.handleChange} />
                                    </div>
                                    <div className="form-group">
                                        <label for="notification-form-url" className="col-form-label"><b>Action URL:</b></label>
                                        <input type="text" className="form-control" value={this.state.url} id="notification-form-url" required="required" placeholder="Enter action url" onChange={this.handleChange} name="url" />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <span style={{ color: "red", fontWeight: "bold" }}>{this.state.success}</span><br />
                                    <button type="button" className="btn btn-secondary create-notification-close-btn" data-dismiss="modal">Close</button>
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}