import React, { useState } from 'react';

const LoyaltyPointsCommentPopup = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

    const handleOpen = () => {
        setIsOpen(true);
    }

  return (
    <div>
    <button className="btn btn-primary btn-sm" onClick={e => handleOpen()}>View</button>
      {isOpen && (
        <div className="custom-popup">
          <div className="custom-popup-content">
            <span className="close" onClick={e => handleClose()}>
              &times;
            </span>
            <h5>Refund Reason / Comments:</h5>
            <hr />
            <p dangerouslySetInnerHTML={{__html: props.comment}}></p>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoyaltyPointsCommentPopup;