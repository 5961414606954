import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { set } from 'lodash';
import {useParams} from 'react-router-dom';
import { useForm } from "react-hook-form";
import Select from "react-select";

import "../../styles/EditProduct.css";

const APIURL = "https://deals.mu/scripts/reportsapi/productscms.php";

export default function EditProduct() {

    // const idParam = match.params.id;
    const params = useParams();
    // console.log({params});
    const idParam = params.id;
    const [id, setID] = useState(idParam);
    const [basics, setBasics] = useState(null);
    const [loading, setloading] = useState(true);
    const [showFeedback, setshowFeedback] = useState(false);
    const [basections, setBasections] = useState({
      "ba-periods": true,
      "ba-minnights": false,
      "ba-meals": false,
      "ba-rooms": false,
      "ba-prices": false,
      "ba-rules": false,
      "ba-abparents": false,
      "ba-aboptions": false,
    });

    const switchSections = (section) => {
      var newSections = basections;
      for (var key in newSections) {
        newSections[key] = false;
        if (key == section) newSections[key] = true;
      }

      setBasections({...newSections});
    };

    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm();

    const onSubmit = (data) => {
      setshowFeedback(false);
      console.log({data});
      setshowFeedback(true);
    };

    const getBasics = () => {
        setloading(true);
        var param = {
          action: "productBasics",
          id: id,
        };

        axios.post(APIURL, param)
        .then((response) => {
            console.log(response.data);
            setBasics(response.data);
            setloading(false);
        })
        .catch((error) => {
            console.log(error);
            setloading(false);
        });
    };

    useEffect(() => {
      getBasics();
    }, [id]);

    return (
      <>
        <div className="row">
          <div className="col">
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%"}}>
                <h3>
                {loading && <b style={{ color: "red" }}>Loading...</b>}
                {basics!=null && !loading && <b>{basics.id} - {basics.title}</b>}
                </h3>
            {basics!=null && !loading && 
                <a href={basics.link} target={"_blank"} className="btn btn-primary btn-sm" rel="noreferrer">View Product</a>
            }
            </div>
            <hr />
          </div>
        </div>
        {basics!=null && !loading &&  
        <div className="row">
          <div className="col">
            <div style={{width: "100%"}}>
                <h4>Product Details</h4>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="detailsForm">
                    <div className='section33'>  
                      <div>
                        <label className='bold'>Regular Base Price</label>
                        <input
                          type="number"
                          className="form-control"
                          defaultValue={basics!=null && basics.regularBasePrice}
                          {...register("regularBasePrice")}
                        />
                      </div>
                      <div>
                        <label className='bold'>Regular Price Percentage</label>
                        <input
                          type="number"
                          className="form-control"
                          defaultValue={basics!=null && basics.regularPricePercentage}
                          {...register("regularPricePercentage")}
                        />
                      </div>
                      <div>
                        <label className='bold'>Selling Base Price</label>
                        <input
                          type="number"
                          className="form-control"
                          defaultValue={basics!=null && basics.sellingBasePrice}
                          {...register("sellingBasePrice")}
                        />
                      </div>
                      
                    </div>

                    <div className='section33'>  
                      <div>
                        <label className='bold'>Selling Price Start From</label>
                        <input
                          type="date"
                          className="form-control"
                          defaultValue={basics!=null && basics.sellingPriceStartFrom}
                          {...register("sellingPriceStartFrom", {valueAsDate: true})}
                        />
                      </div>
                      <div>
                        <label className='bold'>Selling Price End On</label>
                        <input
                          type="date"
                          className="form-control"
                          defaultValue={basics!=null && basics.sellingPriceEndOn}
                          {...register("sellingPriceEndOn", {valueAsDate: true})}
                        />
                      </div>
                    </div>

                    <div className='section33'>  
                      <div>
                        <label className='bold'>Disabled Product Calendar Heading</label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={basics!=null && basics.disabledProductCalendarHeading}
                          {...register("disabledProductCalendarHeading")}
                        />
                      </div>
                      <div>
                        <label className='bold'>Disabled Product Calendar Text</label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={basics!=null && basics.disabledProductCalendarText}
                          {...register("disabledProductCalendarText")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='section100 displayFlex'>
                    <div className='section90'>
                      {showFeedback && 
                        <div className="alert alert-success" role="alert">
                          The data has been updated successfully.
                        </div>
                      }
                    </div>
                    <div className=''>
                      <button type="submit" className="btn btn-primary">Update</button>
                    </div>
                  </div>

                </form>
            </div>
            <hr />
          </div>
        </div>
        }
        
        <div className="row">
          <div className="col">
            <h4>Product Prices</h4>
          </div>
        </div>
        {basics!=null && !loading && 
          <div className="row">
            <div className="col" style={{display: "flex"}}>
              <div className='sidebar'>
                <div className={"sidebar-item "+ (basections["ba-periods"] ? "selected":"")} onClick={e => switchSections("ba-periods")}>Periods</div>
                {basics.productType=="accommodation-booking" &&
                <>
                <div className={"sidebar-item "+ (basections["ba-minnights"] ? "selected":"")} onClick={e => switchSections("ba-minnights")}>Min Nights</div>
                <div className={"sidebar-item "+ (basections["ba-meals"] ? "selected":"")} onClick={e => switchSections("ba-meals")}>Meals</div>
                <div className={"sidebar-item "+ (basections["ba-rooms"] ? "selected":"")} onClick={e => switchSections("ba-rooms")}>Rooms</div>
                <div className={"sidebar-item "+ (basections["ba-prices"] ? "selected":"")} onClick={e => switchSections("ba-prices")}>Prices (Persons)</div>
                <div className={"sidebar-item "+ (basections["ba-rules"] ? "selected":"")} onClick={e => switchSections("ba-rules")}>Price Rules</div>
                </>
                }
                {basics.productType=="booking" &&
                <>
                <div className={"sidebar-item "+ (basections["ba-abparents"] ? "selected":"")} onClick={e => switchSections("ba-abparents")}>Advanced Booking Parents</div>
                <div className={"sidebar-item "+ (basections["ba-aboptions"] ? "selected":"")} onClick={e => switchSections("ba-aboptions")}>Advanced Booking Options</div>
                </>
                }
              </div>
              <div className='content'>
                {basections["ba-periods"] && 
                  <div id="ba-periods">
                    <div style={{display:"flex", justifyContent:"space-between", alignItems: "center"}}>
                      <h5>Booking Periods</h5>
                      <button className='btn btn-sm btn-success'>Save Periods</button>
                    </div>
                    <hr />
                    <div style={{display:"flex", justifyContent:"space-between", alignItems: "center"}}>
                      <div className='content-item' style={{width: "10%"}}>Period ID</div>
                      <div className='content-item' style={{width: "20%"}}>Type</div>
                      <div className='content-item' style={{width: "10%"}}>Starting</div>
                      <div className='content-item' style={{width: "10%"}}>Ending</div>
                      <div className='content-item' style={{width: "10%"}}>Sub-Days Periods</div>
                      <div className='content-item' style={{width: "10%"}}>Min Nights</div>
                      <div className='content-item' style={{width: "10%"}}>Actions</div>
                    </div>
                    <hr />
                    {basics.prices._wc_booking_pricing.length>0 && basics.prices._wc_booking_pricing.map((period, index) => (
                      <div key={index} className={index%2 == 0 ? "evenbackground contentrow":"oddbackground contentrow"} style={{display:"flex", justifyContent:"space-between", alignItems: "center", marginBottom: "15px"}}>
                      <div className='content-item-text' style={{width: "10%"}}>{period.id}</div>
                      <div className='content-item-text' style={{width: "20%"}}>
                        <select className='form-control' value={period.type}>
                          <option value="custom">Range of Certain Nights</option>
                        </select>
                      </div>
                      <div className='content-item-text' style={{width: "10%"}}><input type="date" value={period.from} className='form-control' /></div>
                      <div className='content-item-text' style={{width: "10%"}}><input type="date" value={period.to} className='form-control' /></div>
                      <div className='content-item-text' style={{width: "10%"}}><input type="text" value={period.subdays} className='form-control' /></div>
                      <div className='content-item-text' style={{width: "10%"}}><input type="number" value={period.minimumnights} className='form-control' /></div>
                      <div className='content-item-text' style={{width: "10%"}}><button className='btn btn-sm btn-danger' type="button">X</button></div>
                      </div>
                    ))}
                    <hr />
                    <div style={{display:"flex", justifyContent:"space-between", alignItems: "center"}}>
                      <div className='content-item-text' style={{width: "10%"}}>-</div>
                      <div className='content-item-text' style={{width: "20%"}}>
                        <select className='form-control'>
                          <option value="custom">Range of Certain Nights</option>
                        </select>
                      </div>
                      <div className='content-item-text' style={{width: "10%"}}><input type="date"  className='form-control' /></div>
                      <div className='content-item-text' style={{width: "10%"}}><input type="date" className='form-control' /></div>
                      <div className='content-item-text' style={{width: "10%"}}><input type="text" className='form-control' /></div>
                      <div className='content-item-text' style={{width: "10%"}}><input type="number" className='form-control' /></div>
                      <div className='content-item-text' style={{width: "10%"}}><button className='btn btn-sm btn-success' type="button">Add</button></div>
                    </div>
                    <hr />
                    <button className='btn btn-sm btn-success'>Save Periods</button>
                  </div>
                }
              
                {basections["ba-minnights"] && 
                <div id="ba-minnights">
                    <div style={{display:"flex", justifyContent:"space-between", alignItems: "center"}}>
                      <h5>Minimum Nights</h5>
                      <button className='btn btn-sm btn-success'>Save Minimum Nights</button>
                    </div>
                    <hr />
                    <div style={{display:"flex", justifyContent:"space-between", alignItems: "center"}}>
                      <div className='content-item' style={{width: "10%"}}>MinNights ID</div>
                      <div className='content-item' style={{width: "10%"}}>Starting</div>
                      <div className='content-item' style={{width: "10%"}}>Ending</div>
                      <div className='content-item' style={{width: "10%"}}>Sub-Days Periods</div>
                      <div className='content-item' style={{width: "10%"}}>Min Nights</div>
                      <div className='content-item' style={{width: "15%"}}>Meals</div>
                      <div className='content-item' style={{width: "15%"}}>Rooms</div>
                      <div className='content-item' style={{width: "10%"}}>Actions</div>
                    </div>
                    <hr />
                    {basics.prices._wc_booking_min_nights.length>0 && basics.prices._wc_booking_min_nights.map((night, index) => (
                      <div key={index} className={index%2 == 0 ? "evenbackground contentrow":"oddbackground contentrow"} style={{display:"flex", justifyContent:"space-between", alignItems: "center", marginBottom: "15px"}}>
                        <div className='content-item-text' style={{width: "10%"}}>{night.id}</div>
                        <div className='content-item-text' style={{width: "10%"}}><input type="date" value={night.date_range_start} className='form-control' /></div>
                        <div className='content-item-text' style={{width: "10%"}}><input type="date" value={night.date_range_end} className='form-control' /></div>
                        <div className='content-item-text' style={{width: "10%"}}><input type="text" value={night.days_range} className='form-control' /></div>
                        <div className='content-item-text' style={{width: "10%"}}><input type="number" value={night.minnights} className='form-control' /></div>
                        <div className='content-item-text' style={{width: "15%"}}>
                          <select className='form-control' multiple="multiple" value={night.meals.split(",")}>
                            {basics.prices._wc_booking_meals.length>0 && basics.prices._wc_booking_meals.map((meal, index) => (
                              <option key={index} value={meal.id}>{meal.name}</option>
                            ))}
                          </select>
                        </div>
                        <div className='content-item-text' style={{width: "15%"}}>
                          <select className='form-control' multiple="multiple" value={night.rooms.split(",")}>
                            {basics.prices._wc_booking_rooms.length>0 && basics.prices._wc_booking_rooms.map((room, index) => (
                              <option key={index} value={room.id}>{room.name}</option>
                            ))}
                          </select>
                        </div>
                        <div className='content-item-text' style={{width: "10%"}}><button className='btn btn-sm btn-danger' type="button">X</button></div>
                      </div>
                    ))}
                    <hr />
                    <div style={{display:"flex", justifyContent:"space-between", alignItems: "center"}}>
                      <div className='content-item-text' style={{width: "10%"}}>-</div>
                      <div className='content-item-text' style={{width: "10%"}}><input type="date"  className='form-control' /></div>
                      <div className='content-item-text' style={{width: "10%"}}><input type="date" className='form-control' /></div>
                      <div className='content-item-text' style={{width: "10%"}}><input type="text" className='form-control' /></div>
                      <div className='content-item-text' style={{width: "10%"}}><input type="number" className='form-control' /></div>
                      <div className='content-item-text' style={{width: "15%"}}>
                        <select className='form-control' multiple="multiple">
                          {basics.prices._wc_booking_meals.length>0 && basics.prices._wc_booking_meals.map((meal, index) => (
                            <option key={index} value={meal.id}>{meal.name}</option>
                          ))}
                        </select>
                      </div>
                      <div className='content-item-text' style={{width: "15%"}}>
                        <select className='form-control' multiple="multiple">
                            {basics.prices._wc_booking_rooms.length>0 && basics.prices._wc_booking_rooms.map((room, index) => (
                              <option key={index} value={room.id}>{room.name}</option>
                            ))}
                          </select>
                      </div>
                      <div className='content-item-text' style={{width: "10%"}}><button className='btn btn-sm btn-success' type="button">Add</button></div>
                    </div>
                    <hr />
                    <button className='btn btn-sm btn-success'>Save Minimum Nights</button>
                  </div>
                }
                
                {basections["ba-meals"] && 
                <div id="ba-meals">
                    <div style={{display:"flex", justifyContent:"space-between", alignItems: "center"}}>
                      <h5>Meal Plans</h5>
                      <button className='btn btn-sm btn-success'>Save Meals</button>
                    </div>
                    <hr />
                    <div style={{display:"flex", justifyContent:"space-between", alignItems: "center"}}>
                      <div className='content-item' style={{width: "10%"}}>Meal ID</div>
                      <div className='content-item' style={{width: "10%"}}>Name</div>
                      <div className='content-item' style={{width: "10%"}}>Actions</div>
                    </div>
                    <hr />
                    {basics.prices._wc_booking_meals.length>0 && basics.prices._wc_booking_meals.map((meal, index) => (
                      <div key={index} className={index%2 == 0 ? "evenbackground contentrow":"oddbackground contentrow"} style={{display:"flex", justifyContent:"space-between", alignItems: "center", marginBottom: "15px"}}>
                        <div className='content-item-text' style={{width: "10%"}}>{meal.id}</div>
                        <div className='content-item-text' style={{width: "10%"}}><input type="text" value={meal.name} className='form-control' /></div>
                        <div className='content-item-text' style={{width: "10%"}}><button className='btn btn-sm btn-danger' type="button">X</button></div>
                      </div>
                    ))}
                    <hr />
                    <div style={{display:"flex", justifyContent:"space-between", alignItems: "center"}}>
                      <div className='content-item-text' style={{width: "10%"}}>-</div>
                      <div className='content-item-text' style={{width: "10%"}}><input type="text"  className='form-control' /></div>
                      <div className='content-item-text' style={{width: "10%"}}><button className='btn btn-sm btn-success' type="button">Add</button></div>
                    </div>
                    <hr />
                    <button className='btn btn-sm btn-success'>Save Meals</button>
                  </div>
                }
                
                {basections["ba-rooms"] && 
                <div id="ba-rooms">
                    <div style={{display:"flex", justifyContent:"space-between", alignItems: "center"}}>
                      <h5>Meal Plans</h5>
                      <button className='btn btn-sm btn-success'>Save Rooms</button>
                    </div>
                    <hr />
                    <div style={{display:"flex", justifyContent:"space-between", alignItems: "center"}}>
                      <div className='content-item' style={{width: "10%"}}>Meal ID</div>
                      <div className='content-item' style={{width: "20%"}}>Name</div>
                      <div className='content-item' style={{width: "50%"}}>Occupancy</div>
                      <div className='content-item' style={{width: "10%"}}>Actions</div>
                    </div>
                    <hr />
                    {basics.prices._wc_booking_rooms.length>0 && basics.prices._wc_booking_rooms.map((room, index) => (
                      <div key={index} className={index%2 == 0 ? "evenbackground contentrow":"oddbackground contentrow"} style={{display:"flex", justifyContent:"space-between", alignItems: "center", marginBottom: "15px"}}>
                        <div className='content-item-text' style={{width: "10%"}}>{room.id}</div>
                        <div className='content-item-text' style={{width: "20%"}}><input type="text" value={room.name} className='form-control' /></div>
                        <div className='content-item-text' style={{width: "50%"}}>
                          <textarea type="text" value={room.occupancy} className='form-control' />
                        </div>
                        <div className='content-item-text' style={{width: "10%"}}><button className='btn btn-sm btn-danger' type="button">X</button></div>
                      </div>
                    ))}
                    <hr />
                    <div style={{display:"flex", justifyContent:"space-between", alignItems: "center"}}>
                      <div className='content-item-text' style={{width: "10%"}}>-</div>
                      <div className='content-item-text' style={{width: "20%"}}><input type="text"  className='form-control' /></div>
                      <div className='content-item-text' style={{width: "50%"}}>
                          <textarea type="text" className='form-control' />
                        </div>
                      <div className='content-item-text' style={{width: "10%"}}><button className='btn btn-sm btn-success' type="button">Add</button></div>
                    </div>
                    <hr />
                    <button className='btn btn-sm btn-success'>Save Rooms</button>
                  </div>
                }
                
                {basections["ba-rules"] && 
                <div id="ba-rules">
                    <div style={{display:"flex", justifyContent:"space-between", alignItems: "center"}}>
                      <h5>Price Rules</h5>
                      <button className='btn btn-sm btn-success'>Save Price Rules</button>
                    </div>
                    <hr />
                    <div style={{display:"flex", justifyContent:"space-between", alignItems: "center"}}>
                      {/* <div className='content-item' style={{width: "10%"}}>ID</div> */}
                      <div className='content-item' style={{width: "10%"}}>Period</div>
                      <div className='content-item' style={{width: "10%"}}>Min Nights</div>
                      <div className='content-item' style={{width: "10%"}}>%/$ OFF</div>
                      <div className='content-item' style={{width: "10%"}}>Meals</div>
                      <div className='content-item' style={{width: "15%"}}>Rooms</div>
                      <div className='content-item' style={{width: "10%"}}>Rule Type</div>
                      <div className='content-item' style={{width: "10%"}}>Expires on</div>
                      <div className='content-item' style={{width: "5%"}}>Actions</div>
                    </div>
                    <hr />
                    {basics.prices._wc_booking_price_rules.length>0 && basics.prices._wc_booking_price_rules.map((rule, index) => (
                      <div key={index} className={index%2 == 0 ? "evenbackground contentrow":"oddbackground contentrow"} style={{display:"flex", justifyContent:"space-between", alignItems: "center", marginBottom: "20px"}}>
                        {/* <div className='content-item-text' style={{width: "10%"}}>{night.id}</div> */}
                        <div className='content-item-text' style={{width: "10%"}}>
                          <input type="date" value={rule.date_range_start} className='form-control mb10' />
                          <input type="date" value={rule.date_range_end} className='form-control mb10' />
                          <input type="text" value={rule.days_range} className='form-control' />
                        </div>
                        <div className='content-item-text' style={{width: "10%"}}><input type="number" value={rule.minnights} className='form-control' /></div>
                        <div className='content-item-text' style={{width: "10%"}}><input type="text" value={rule.off} className='form-control' /></div>
                        <div className='content-item-text' style={{width: "10%"}}>
                          <select className='form-control' multiple="multiple" value={rule.meals.split(",")}>
                            {basics.prices._wc_booking_meals.length>0 && basics.prices._wc_booking_meals.map((meal, index) => (
                              <option key={index} value={meal.id}>{meal.name}</option>
                            ))}
                          </select>
                        </div>
                        <div className='content-item-text' style={{width: "15%"}}>
                          <select className='form-control' multiple="multiple" value={rule.rooms.split(",")}>
                            {basics.prices._wc_booking_rooms.length>0 && basics.prices._wc_booking_rooms.map((room, index) => (
                              <option key={index} value={room.id}>{room.name}</option>
                            ))}
                          </select>
                        </div>
                        <div className='content-item-text' style={{width: "10%"}}>
                          <select className='form-control' value={rule.type}>
                            <option value="last">Last Night</option>
                            <option value="all">All Nights</option>
                          </select>
                        </div>
                        <div className='content-item-text' style={{width: "10%"}}><input type="date" value={rule.expires} className='form-control' /></div>
                        <div className='content-item-text' style={{width: "5%"}}><button className='btn btn-sm btn-danger' type="button">X</button></div>
                      </div>
                    ))}
                    <hr />
                    <div style={{display:"flex", justifyContent:"space-between", alignItems: "center"}}>
                      <div className='content-item-text' style={{width: "10%"}}>
                        <input type="date"  className='form-control mb10' />
                        <input type="date" className='form-control mb10' />
                        <input type="text" className='form-control' />
                      </div>
                      <div className='content-item-text' style={{width: "10%"}}><input type="number" className='form-control' /></div>
                      <div className='content-item-text' style={{width: "10%"}}><input type="text" className='form-control' /></div>
                      <div className='content-item-text' style={{width: "10%"}}>
                        <select className='form-control' multiple="multiple">
                          {basics.prices._wc_booking_meals.length>0 && basics.prices._wc_booking_meals.map((meal, index) => (
                            <option key={index} value={meal.id}>{meal.name}</option>
                          ))}
                        </select>
                      </div>
                      <div className='content-item-text' style={{width: "15%"}}>
                        <select className='form-control' multiple="multiple">
                            {basics.prices._wc_booking_rooms.length>0 && basics.prices._wc_booking_rooms.map((room, index) => (
                              <option key={index} value={room.id}>{room.name}</option>
                            ))}
                          </select>
                      </div>
                      <div className='content-item-text' style={{width: "10%"}}>
                        <select className='form-control'>
                          <option value="last">Last Night</option>
                          <option value="all">All Nights</option>
                        </select>
                      </div>
                      <div className='content-item-text' style={{width: "10%"}}><input type="date" className='form-control' /></div>
                      <div className='content-item-text' style={{width: "5%"}}><button className='btn btn-sm btn-success' type="button">Add</button></div>
                    </div>
                    <hr />
                    <button className='btn btn-sm btn-success'>Save Price Rules</button>
                  </div>
                }

                {basections["ba-abparents"] && 
                <div id="ba-abparents">
                  <h5>Add Booking Parent Options Here:</h5>
                  <hr />
                  <div className='content-item'>Regular Base prices</div>
                  <div className='content-item'>Regular Price prices</div>
                  <div className='content-item'>Selling Base prices</div>
                  <div className='content-item'>Selling Price Start From</div>
                  <div className='content-item'>Selling Price End On</div>
                  <div className='content-item'>Disabled Product Calendar Heading</div>
                  <div className='content-item'>Disabled Product Calendar Text</div>
                </div>
                }

                {basections["ba-aboptions"] && 
                <div id="ba-aboptions">
                  <h5>Add Booking Options Here:</h5>
                  <hr />
                  <div className='content-item'>Regular Base prices</div>
                  <div className='content-item'>Regular Price prices</div>
                  <div className='content-item'>Selling Base prices</div>
                  <div className='content-item'>Selling Price Start From</div>
                  <div className='content-item'>Selling Price End On</div>
                  <div className='content-item'>Disabled Product Calendar Heading</div>
                  <div className='content-item'>Disabled Product Calendar Text</div>
                </div>
                }

                {basections["ba-prices"] && 
                <div id="ba-prices">
                  <h5>Prices</h5>
                  <hr />
                  {basics.prices.persons_new.length>0 && basics.prices.persons_new.map((person, index) => (
                  <div style={{display:"flex", flexDirection:"column"}}>
                    <div className='price-section'>
                      <div className='price-header' style={{display: "flex", justifyContent: "space-between"}}>
                        <select className='form-control' style={{width: "30%"}} multiple value={person.period.split(",")}>
                          {basics.prices._wc_booking_pricing.length>0 && basics.prices._wc_booking_pricing.map((period, index) => (
                            <option key={index} value={period.id}>{period.from} -- {period.to}</option>
                          ))}
                        </select>
                        <div style={{display:"flex", flexDirection:"column", width: "25%"}}>
                          <select className='form-control' value={person.meal} style={{marginBottom:"15px"}}>
                            {basics.prices._wc_booking_meals.length>0 && basics.prices._wc_booking_meals.map((meal, index) => (
                              <option key={index} value={meal.id}>{meal.name}</option>
                            ))}
                          </select>
                          <select className='form-control' value={person.room}>
                            {basics.prices._wc_booking_rooms.length>0 && basics.prices._wc_booking_rooms.map((room, index) => (
                              <option key={index} value={room.id}>{room.name}</option>
                            ))}
                          </select>
                        </div>
                        <div style={{display:"flex", flexDirection:"column", width: "25%"}}>
                          <input type="number" className="form-control" placeholder='Minimum Nights' value={person.nimnights} style={{marginBottom: "15px"}} />
                          <button className='btn btn-primary'>Generate</button>
                        </div>
                        <div style={{display:"flex", flexDirection:"column", width: "10%"}}>
                          <label style={{marginBottom: "15px", fontWeight: "bold"}}>Sort Order</label>
                          <input type="number" className="form-control" placeholder='Sort Order' value={person.sort} />
                        </div>
                      </div>
                      <div className='price-content'>
                        {person.prices.length>0 && person.prices.map((price, index) => (
                          <div key={index} style={{display: "flex", marginBottom: "10px"}}>
                            <input type="text" className='form-control' value={price.id} />
                            <input type="text" className='form-control' value={price.name} />
                            <input type="number" className='form-control' value={price.base_price} />
                            <button className='btn btn-danger'>X</button>
                          </div>
                        ))}
                      </div>
                      <hr style={{border: "3px solid #f3f3f3", marginBottom: "30px"}} />
                    </div>
                  </div>
                  ))}
                </div>
                }

              </div>
            </div>
          </div>
        }
      </>
    );
}
