import React, { Component } from "react";
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { style } from "@mui/system";
import swal from "sweetalert";

import OrdersReportService from "../../services/orders/ordersreport.service";
import NewRefundReportsService from '../../services/erp/refunds-new.service';

export default class AddManualRefund extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderid: "",
            rerender: false,
            amountalreadyrefunded: 0,
            items: [],
            chosenitems: [],
            refundType: "Refund",
            refundamount:'',
            handlingAgentName: "",
            refundStatus: "Pending",
            refundReason: "",
            refundUrgency: "Low",
            explaination: "",
            customerbankdetails: false,
            bankName: "",
            accountowner: "",
            accountNumber: "",
            addrefuntbtntext: 'Add Refund',
            disableaddrefundbtn: false,
            user: props.user,
            customerName: "",
            paymentMethod: "",
            paymentReference: "",
            totalAmountPaid: ""
        }

        this.timer = null;
    }

    openPopup = (e) => {
        this.setState({
            isopen: true
        });
    }

    addRefund = (e) => {
        e.preventDefault();
        this.setState({
            addrefuntbtntext: 'Adding Refund...',
            disableaddrefundbtn: true
        });
        e.preventDefault();
        OrdersReportService.createRefundManual(this.state.orderid, this.state.chosenitems, this.state.refundType, this.state.refundamount, this.state.handlingAgentName, this.state.refundReason, this.state.refundUrgency, this.state.explaination, this.state.customerbankdetails, this.state.bankName, this.state.accountowner, this.state.accountNumber, this.state.user.user_nicename, "Manual", this.state.customerName, this.state.paymentMethod, this.state.paymentReference, this.state.totalAmountPaid)
        .then(response => {
            // console.log('Refund response', {response});
            this.setState({
                addrefuntbtntext: 'Add Refund',
                disableaddrefundbtn: false,
                isopen: false
            });
            // this.props.onChange();
            swal('Refund Added', "The refund has been submitted", "success");
            window.location.reload();
        })
        .catch(err => console.log({err}));
    }

    showHideBankDetails = (e) => {
        if(!this.state.customerbankdetails) {
            this.setState({
                bankName: "",
                accountowner: "",
                accountNumber: "",
            });
        }
        this.setState({
            customerbankdetails: !this.state.customerbankdetails
        });
    }

    setChosenItems = (e) => {

        var chosenItems = Array.from(e.target.selectedOptions, option => option.value);
        // console.log({chosenItems});
        this.setState({chosenitems: chosenItems});
    }

    orderIDChanged = (e) => {
        this.setState({
            orderid: e.target.value
        });

        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            NewRefundReportsService.getOrderVeryBasic(this.state.orderid)
            .then(response => {
                console.log({response});
                this.setState({
                    customerName: response.data.customername,
                    paymentMethod: response.data.paymentmethod,
                    paymentReference: response.data.paymentreference,
                    totalAmountPaid: response.data.totalamount
                });
            }).catch(err => 
                console.log({err})
            );
        }, 1000);
    }

    render() {
        const style = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            height: "90%",
            overflowY: "scroll"
        };
        return (
            <>
            <button type="button" className="btn btn-s btn-primary" style={{width: "1000px"}} onClick={e => this.openPopup(e)}>Create Manual Refund</button>
            <Modal
                open={this.state.isopen}
                onClose={e => {this.setState({isopen: false})}}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{...style, width: 800, textAlign: 'center' }}>
                <form onSubmit={(e) => this.addRefund(e)} style={{textAlign: "left"}}>
                    <div className="row">
                        <div className="col supplier-confirm-phone-heading">
                            Create Manual Refund
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="orderid" className="col-4 col-form-label"><b>Order ID</b></label>
                        <div className="col-8"> 
                            <input type="text" className="form-control" id="orderid" placeholder="Enter Order ID" onChange={e => this.orderIDChanged(e)} value={this.state.orderid} />
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="customerName" className="col-4 col-form-label"><b>Customer Name</b></label>
                        <div className="col-8"> 
                            <input type="text" className="form-control" id="customerName" placeholder="Enter Customer Name" onChange={e => {this.setState({customerName: e.target.value})}} value={this.state.customerName} />
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="paymentMethod" className="col-4 col-form-label"><b>Payment Method</b></label>
                        <div className="col-8"> 
                            <input type="text" className="form-control" id="paymentMethod" placeholder="Enter Payment Method" onChange={e => {this.setState({paymentMethod: e.target.value})}} value={this.state.paymentMethod} />
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="paymentReference" className="col-4 col-form-label"><b>Payment Reference</b></label>
                        <div className="col-8"> 
                            <input type="text" className="form-control" id="paymentReference" placeholder="Enter Payment Reference" onChange={e => {this.setState({paymentReference: e.target.value})}} value={this.state.paymentReference} />
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="totalAmountPaid" className="col-4 col-form-label"><b>Total Amount Paid</b></label>
                        <div className="col-8"> 
                            <input type="number" className="form-control" id="totalAmountPaid" placeholder="Enter Total Amount Paid" onChange={e => {this.setState({totalAmountPaid: e.target.value})}} value={this.state.totalAmountPaid} />
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="bankdetails" style={{backgroundColor: "bisque"}} className="col-12 col-form-label"><b>Refund Details</b></label>
                    </div>

                     {/* <div className="form-group row">
                        <label for="chosenitems" className="col-4 col-form-label"><b>Choose Products</b></label>
                        <div className="col-8">
                            <select className="form-control" multiple onChange={e => this.setChosenItems(e)} id="chosenitems" required="required" value={this.state.chosenitems}>
                                {this.state.items.length>0 && this.state.items.map((item, index)=>(
                                    <option key={index} value={item.id+"|"+item.name}>{item.name}</option>
                                ))}
                            </select>
                        </div>
                    </div> */}
                    {/* <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="refundType" className="col-4 col-form-label"><b>Choose Refund / Credit</b></label>
                        <div className="col-8"> 
                            <select className="form-control" id="refundType" required="required" onChange={e => {this.setState({refundType: e.target.value})}} value={this.state.refundType} >
                                <option value="">Choose Refund Type</option>
                                <option value="Refund">Refund</option>
                                <option value="Credit">Credit</option>
                            </select>
                        </div>
                    </div> */}
                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="refundamount" className="col-4 col-form-label"><b>Refund Amount</b><b style={{marginLeft:"5px", fontWeight: "bold", color: "red"}}>*</b></label>
                        <div className="col-8"> 
                            <input type="number" className="form-control" id="refundamount" placeholder="Enter refund amount" required="required" onChange={e => {this.setState({refundamount: e.target.value})}} value={this.state.refundamount} />
                        </div>
                    </div>
                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="handlingAgentName" className="col-4 col-form-label"><b>Handling Agent Name</b><b style={{marginLeft:"5px", fontWeight: "bold", color: "red"}}>*</b></label>
                        <div className="col-8"> 
                            <input type="text" className="form-control" id="handlingAgentName" placeholder="Enter handling agent name" required="required" onChange={e => {this.setState({handlingAgentName: e.target.value})}} value={this.state.handlingAgentName} />
                        </div>
                    </div>
                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="refundReason" className="col-4 col-form-label"><b>Refund Reason</b><b style={{marginLeft:"5px", fontWeight: "bold", color: "red"}}>*</b></label>
                        <div className="col-8"> 
                            <select className="form-control" id="refundReason" required="required" onChange={e => {this.setState({refundReason: e.target.value})}} value={this.state.refundReason} >
                                <option value="">Choose Refund Reason</option>
                                <option value="No Availablity">No Availablity</option>
                                <option value="Customer Cancelled">Customer Cancelled</option>
                                <option value="Bad Weather">Bad Weather</option>
                                <option value="Cancelled by Supplier">Cancelled by Supplier</option>
                                <option value="Complaint">Complaint</option>
                                <option value="Others">Others</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="refundReason" className="col-4 col-form-label"><b>Refund Urgency</b><b style={{marginLeft:"5px", fontWeight: "bold", color: "red"}}>*</b></label>
                        <div className="col-8"> 
                            <select className="form-control" id="refundReason" required="required" onChange={e => {this.setState({refundUrgency: e.target.value})}} value={this.state.refundUrgency} >
                                <option value="">Choose Refund Urgency</option>
                                <option value="Low">Low</option>
                                <option value="Medium">Medium</option>
                                <option value="High">High</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="explaination" className="col-4 col-form-label"><b>Explanation & Comments</b><b style={{marginLeft:"5px", fontWeight: "bold", color: "red"}}>*</b></label>
                        <div className="col-8"> 
                            <textarea rows="4" className="form-control" id="explaination" placeholder="Enter explaination" required="required" onChange={e => {this.setState({explaination: e.target.value})}} value={this.state.explaination}></textarea>
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="bankdetails" style={{backgroundColor: "bisque", cursor: "pointer"}} className="col-12 col-form-label" onClick={e => this.showHideBankDetails(e)}><b>Customer Bank Details</b></label>
                    </div>
                    {this.state.customerbankdetails==true &&
                        <>
                        <div className="form-group row" style={{marginBottom: "20px"}}>
                            <label for="bankName" className="col-4 col-form-label"><b>Bank Name</b><b style={{marginLeft:"5px", fontWeight: "bold", color: "red"}}>*</b></label>
                            <div className="col-8"> 
                                {/* <input type="text" className="form-control" id="bankName" placeholder="Enter bank name" required="required" onChange={e => {this.setState({bankName: e.target.value})}} value={this.state.bankName} /> */}
                                <select class="form-control" id="bankName" value={this.state.bankName} onChange={e => this.setState({bankName: e.target.value})} required="required">
                                    <option value="">Choose Bank Name</option>
                                    <option value="ABC Banking Corporation Ltd">ABC Banking Corporation Ltd</option>
                                    <option value="Absa Bank (Mauritius) Limited">Absa Bank (Mauritius) Limited</option>
                                    <option value="AfrAsia Bank Limited">AfrAsia Bank Limited</option>
                                    <option value="BCP Bank (Mauritius) Ltd">BCP Bank (Mauritius) Ltd</option>
                                    <option value="Bank One Limited">Bank One Limited</option>
                                    <option value="Bank Of Baroda">Bank Of Baroda</option>
                                    <option value="Bank Of China">Bank Of China</option>
                                    <option value="HSBC">HSBC</option>
                                    <option value="Habib Bank Limited">Habib Bank Limited</option>
                                    <option value="Investec Bank (Mauritius) Limited">Investec Bank (Mauritius) Limited</option>
                                    <option value="Mauritius Commercial Bank (MCB)">Mauritius Commercial Bank (MCB)</option>
                                    <option value="MauBank Ltd">MauBank Ltd</option>
                                    <option value="SBI (Mauritius) Ltd">SBI (Mauritius) Ltd</option>
                                    <option value="SBM Bank (Mauritius) Ltd">SBM Bank (Mauritius) Ltd</option>
                                    <option value="Silver Bank Limited">Silver Bank Limited</option>
                                    <option value="Standard Bank (Mauritius) Ltd">Standard Bank (Mauritius) Ltd</option>
                                    <option value="Standard Chartered Bank (Mauritius) Ltd">Standard Chartered Bank (Mauritius) Ltd</option>
                                </select>
                            </div>
                        </div>

                        <div className="form-group row" style={{marginBottom: "20px"}}>
                            <label for="handlingAgentName" className="col-4 col-form-label"><b>Name of Account Owner</b><b style={{marginLeft:"5px", fontWeight: "bold", color: "red"}}>*</b></label>
                            <div className="col-8"> 
                                <input type="text" className="form-control" id="accountowner" placeholder="Enter account owner name" required="required" onChange={e => {this.setState({accountowner: e.target.value})}} value={this.state.accountowner} />
                            </div>
                        </div>
                        
                        <div className="form-group row" style={{marginBottom: "20px"}}>
                            <label for="accountNumber" className="col-4 col-form-label"><b>Account Number</b><b style={{marginLeft:"5px", fontWeight: "bold", color: "red"}}>*</b></label>
                            <div className="col-8"> 
                                <input type="text" className="form-control" id="accountNumber" placeholder="Enter account number" required="required" onChange={e => {this.setState({accountNumber: e.target.value})}} value={this.state.accountNumber} />
                            </div>
                        </div>
                        </>
                    }

                    <div style={{display: "flex", justifyContent: "space-between", marginTop: "40px"}}>
                        <button type="submit" className="btn btn-sm btn-success" style={{marginRight: "20px"}} disabled={this.state.disableaddrefundbtn}>{this.state.addrefuntbtntext}</button><button type="button" className="btn btn-sm btn-danger" onClick={e => {this.setState({isopen: false})}}>Close</button>
                    </div>
                </form>
                </Box>
            </Modal>
            </>
        );
    }
}