import React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Loader from "react-loader-spinner";
import Select from "react-select";

import AuthService from "../../services/auth/auth.service";
import NoPermission from "../extras/nopermission.component";
import Pagination from "../pagination.component";
import { set } from 'lodash';
import { Link } from '@mui/material';

const APPURL = "https://deals.mu/scripts/reportsapi/productscms.php";

export default function ProductsList() {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(20);
    const [totalPages, setTotalPages] = useState(0);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [allowed, setAllowed] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState({
      value: "any",
      label: "Any",
    });
    const [search, setSearch] = useState("");
    const [btnSearch, setBtnSearch] = useState("Search");
    const [disableBtnSearch, setDisableBtnSearch] = useState(false);
    
    const categories = [
      { value: "all", label: "All" },
      { value: "24", label: "Activities" },
      { value: "28", label: "Events" },
      { value: "27", label: "Hotel Day Packages" },
      { value: "23", label: "Hotels" },
      { value: "30", label: "Restaurants" },
      { value: "98", label: "Rodrigues Accommodation" },
      { value: "99", label: "Rodrigues Activities" },
      { value: "105", label: "Rodrigues Spa" },
      { value: "26", label: "Spa" },
    ];

    const allStatus = [
      { value: "any", label: "Any" },
      { value: "draft", label: "Draft" },
      { value: "publish", label: "Published" },
      { value: "private", label: "Private" }
    ];

    const fetchData = () => {
        var params = {
            action: "allProducts",
            page: page,
            limit: limit,
            search: search,
            categories: selectedCategory.map((cat) => cat.value),
            status: selectedStatus.value,
        };

        setBtnSearch("Searching...");
        setDisableBtnSearch(true);
        setData([]);

        // setPage(newpage);
        axios.post(APPURL, params)
        .then(res => {
            console.log(res.data);
            if(page==0){
                setTotalPages(res.data);
                setPage(1);
                // fetchData();    
            } else {
                setData(res.data);
                setBtnSearch("Search");
                setDisableBtnSearch(false);
            }
        })
        .catch(err => {
            console.log(err);
        });
    }

    useEffect(() => {
        fetchData();
    }, [page]);

    useEffect(() => {
        AuthService.checklogin()
        .then(userdata => {
            setUser(userdata);
            AuthService.getUserPermisions(userdata).then((response) => {
              var userpermissions = response.data;
              if (
                userpermissions.includes("productsediting") ||
                userpermissions.includes("all")
              ) {
                  console.log("You are allowed!");
                  setAllowed(true);
                  fetchData();
              } else {
                console.log("You are not allowed!");
                setAllowed(false);
              }
            });
        });
    }, []);

    const changePage = (n) => {
        setPage(prev => setPage(n));
        fetchData();
    }

    const submitForm = (e) => {
        e.preventDefault();
        setPage((prev) => setPage(0));
        fetchData();
    }

    return (
      <>
        <div className="row">
          <div className="col">
            <h3>All Products/Packages</h3>
            <hr />
          </div>
        </div>

        <div className="row">
          <div className="col">
            {/* TODO: Add filters here */}
            <form style={{ display: "flex", justifyContent: "flex-end" }} onSubmit={e => submitForm(e)}>
              <div style={{ width: "100%" }}>
                <Select
                  value={selectedCategory}
                  onChange={(selected) => setSelectedCategory(selected)}
                  options={categories}
                  placeholder="Select a category"
                  isMulti={true}
                />
              </div>
              <div style={{ width: "100%", marginLeft: "20px" }}>
                <Select
                  value={selectedStatus}
                  onChange={(selected) => setSelectedStatus(selected)}
                  options={allStatus}
                  placeholder="Select a Status"
                />
              </div>
              <div style={{ width: "100%", marginLeft: "20px" }}>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Products"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <div style={{ width: "100%", marginLeft: "20px" }}>
                <button className="btn btn-success" disabled={disableBtnSearch} type="submit">
                  {btnSearch}
                </button>
              </div>
            </form>
            <hr />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Name</th>
                  <th scope="col">Categories</th>
                  <th scope="col">Last Modified</th>
                  <th scope="col">Status</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 &&
                  data.map((product, index) => (
                    <tr key={index}>
                      <th scope="row">{product.id}</th>
                      <td>{product.title}</td>
                      <td>
                        <b>{product.categories}</b>
                      </td>
                      <td>{product.lastModified}</td>
                        <td>{product.status}</td>
                      <td>
                        <a href={`/edit-product/${product.id}`} className="btn btn-primary btn-sm">Edit</a>
                        <br />
                        <a href={`https://deals.mu/wp-admin/post.php?post=${product.id}&action=edit&lang=en&classic-editor`} className="btn btn-info btn-sm" style={{marginTop: "15px"}} target="_blank" rel="noreferrer">Edit in WP</a>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div className="row">
              <div className="col">
                <Pagination
                  total={totalPages}
                  current={page}
                  limit={limit}
                  onPageChange={(n) => changePage(n)}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
}
