import React, { useEffect, useState } from 'react'
import { Modal, Box } from '@mui/material';
import axios from 'axios';
import swal from 'sweetalert';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import AuthService from '../services/auth/auth.service';
import NoPermission from './extras/nopermission.component';

const APP_URL = "https://deals.mu/scripts/reportsapi/corporatesaccounts.php";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const htmlTemplate = (content) => {
    return `
    <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml">

<head>
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
    <!-- Google Fonts -->
    <!-- <link href="https://fonts.googleapis.com/css?family=Roboto" rel="stylesheet"/> -->
    <link rel="preconnect" href="https://fonts.gstatic.com">
    <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap" rel="stylesheet">
    <title>Your Special Deals.mu Corporate Code</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <style type="text/css">
        .links a {
            color: white;
            text-decoration: none;
        }

        .g-links a {
            color: #373737;
            text-decoration: none;
        }

        .g-links.website a {
            color: #2765cf;
            text-decoration: none;
        }
    </style>
</head>

<body style="margin: 0; padding: 0; font-family: \'Roboto\', Arial, sans-serif;">
    <table border="0" cellpadding="15" cellspacing="0" width="100%" style="background-color: #f1f1f1;">
        <tbody>
            <tr>
                <td>
                    <table align="center" border="0" cellpadding="0" cellspacing="0" width="700"
                        style="border-collapse: collapse;">
                        <tbody>
                            <tr id="header">
                                <td bgcolor="#fff" align="center" style="padding-bottom: 0;">
                                    <img src="https://deals.mu/wp-content/themes/hassdev/assets/images/emails/bars.png"
                                        alt="deals.mu" style="display: block;width: 100%;height: 4px;" />
                                    <img src="https://deals.mu/wp-content/themes/hassdev/assets/images/logo.png"
                                        alt="deals.mu" style="display: block;margin: 9px 0 11px;" width="146" />
                                    <img src="https://deals.mu/wp-content/themes/hassdev/assets/images/emails/bars.png"
                                        alt="deals.mu" style="display: block;width: 100%;height: 4px;" />
                                </td>
                            </tr>
                            <tr id="body">
                                <td bgcolor="#ffffff" style="padding-top: 0;padding-bottom: 0;">
                                    <table border="0" cellpadding="0" cellspacing="0" width="100%">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <table align="center" border="0" cellpadding="0" cellspacing="0"
                                                        width="565" style="border-collapse: collapse;">
                                                        <tbody>
                                                            <tr>
                                                                <td valign="top">
                                                                    <div id="body_content_inner"
                                                                        style="padding: 15px 15px 0;">
                                                                        <div id="container"
                                                                            style="display: inline-block;width: 565px;">
                                                                            ${content}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr id="footer">
                                <td style="background-color: #f9f9f9;text-align: center;">
                                    <div style="display: block;margin: 0 60px;padding-bottom: 15px; padding-top:15px;">
                                        <p style="font-size: 14px;color: #333333;margin: 0 0 0;text-align: center;display: block;">
                                            Email us: <a href="mailto:info@deals.mu" style="font-size: 14px;color: #ec1f46;font-weight: 700;">corporates@deals.mu</a> | Contact us: <a href="tel:+2302691500" style="font-size: 14px;color: #ec1f46;font-weight: 700;">5252-3300</a>
                                        </p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style="background: #fff;text-align: center;">
                                    <p style="font-size: 14px;color: #333333;margin: 11px 0 4px;text-align: center;display: block;">Best regards,</p>

                                    <p style="font-size: 14px;color: #333333;margin: 0px 0 9px;text-align: center;display: block;">Deals.mu Corporate Team</p>

                                    <div style="display: block;margin-bottom: 16px;">
                                        <a href="https://www.instagram.com/deals.mu_mauritius/"><img
                                                style="vertical-align: middle;margin-right: 5px;height: 20px;width: 20px;"
                                                src="https://deals.mu/wp-content/themes/hassdev/assets/images/emails/insta.png"
                                                alt=""></a>
                                        <a href="https://www.facebook.com/DealsMauritius"><img
                                                style="vertical-align: middle;margin-right: 5px;height: 20px;width: 20px;"
                                                src="https://deals.mu/wp-content/themes/hassdev/assets/images/emails/fb.png"
                                                alt=""></a>
                                        <a href="https://deals.mu/"><img
                                                style="vertical-align: middle;margin-right: 5px;height: 20px;width: 20px;"
                                                src="https://deals.mu/wp-content/themes/hassdev/assets/images/emails/web.png"
                                                alt=""></a>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        </tbody>
    </table>
</body>

</html>
    `;
}

const CreateCorporateNotifications = () => {
    const [emails, setEmails] = useState([]);
    const [subject, setSubject] = useState("");
    const [content, setContent] = useState("");
    const [preview, setPreview] = useState(htmlTemplate(""));
    const [type, setType] = useState("Corporate Newsletters");
    const [user, setUser] = useState(null);
    const [allowed, setAllowed] = useState(false);
    const [saveBtnText, setSaveBtnText] = useState("Save");
    const [saveBtnEnable, setSaveBtnEnable] = useState(true);
    const [sendEmailBtnText, setSendEmailBtnText] = useState("Send Email");
    const [sendEmailBtnEnable, setSendEmailBtnEnable] = useState(false);
    const [savedID, setSavedID] = useState(0);

    // useEffect(() => {
    //     setPreview(htmlTemplate(content));
    // }, [content]);

    const saveEmail = (e) => {
        e.preventDefault();
        console.log("saveEmail", e);
        setSaveBtnText("Saving...");
        setSaveBtnEnable(false);
        
        var params = {
            action: "saveCorporateNotifications",
            emails: emails,
            subject: subject,
            type: type,
            content: htmlTemplate(content)
        };
        axios.post(APP_URL, {params})
        .then((response) => {
            console.log("saveCorporateNotification", response.data);
            if (response.data.status === "success") {
                swal("Success", response.data.message, "success");
                setSaveBtnText("Save");
                setSaveBtnEnable(true);
                setSendEmailBtnEnable(true);
                setSavedID(response.data.id);
            } else {
                swal("Error", response.data.message, "error");
                setSaveBtnText("Save");
                setSaveBtnEnable(true);
            }
        })
        .catch((error) => {
            swal("Error", error.message, "error");
            setSaveBtnText("Save");
            setSaveBtnEnable(true);
        });
    }

    const previewTemplate = (e) => {
        setPreview(htmlTemplate(content));
    }

    const sendEmail = (e) => {
        e.preventDefault();
        console.log("sendEmail", e);
        setSendEmailBtnText("Sending...");
        setSendEmailBtnEnable(false);

        var params = {
            action: "sendCorporateNotifications",
            id: savedID
        };
        axios.post(APP_URL, {params})
        .then((response) => {
            console.log("sendCorporateNotifications", response.data);
            if (response.data.status === "success") {
                swal("Success", response.data.message, "success");
                setSendEmailBtnText("Send Email");
                setSendEmailBtnEnable(true);
            } else {
                swal("Error", response.data.message, "error");
                setSendEmailBtnText("Send Email");
                setSendEmailBtnEnable(true);
            }
        })
    }

    useEffect(() => {
        AuthService.checklogin().then(user => {
            setUser(user);

            console.log({ user });
            AuthService.getUserPermisions(user)
            .then(response => {
                var userpermissions = response.data;
                console.log({userpermissions});
                    if(userpermissions.includes('corporate') || userpermissions.includes('all')) {
                    console.log('You are allowed!');
                    setAllowed(true);
                } else {
                    console.log('You are not allowed!');
                    setAllowed(false);
                }
            })
        }).catch(error => {
            window.location.href = '/login';
        });
    }, []);

    return (
        <>
        {allowed == false && 
            <NoPermission />
        }
        {allowed == true && 
        <div style={{marginBottom: "30px"}}>
            <div className="row" style={{width: "100%"}}>
                <div className='col' style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <h1 className="orders-h1" style={{marginBottom: "0px"}}>Create Corporate Notification</h1>
                    </div>
                </div>
                <div style={{ marginLeft: '10px' }}>
                    <a href="/corporate-notifications" className="btn btn-large btn-success">All Notifications</a> 
                </div>
            </div>

            <div className="row" style={{width: "100%"}}>
                <div className="col">
                    <hr />
                </div>
            </div>

            <div className="row" style={{width: "100%"}}>
                <div className="col">
                    <form onSubmit={e => {saveEmail(e)}}>

                        <div className="row" style={{width: "100%"}}>
                            <div className="col">
                                <div className="form-group">
                                    <label htmlFor="subject" style={{fontWeight: "bold"}}>Email Type</label>
                                    <select className='form-control' value={type} onChange={e=>setType(e.target.value)}>
                                        <option value="Corporate Newsletters">Corporate Newsletters</option>
                                        <option value="Special Corporate Offers (discount/last minute)">Special Corporate Offers (discount/last minute)</option>
                                        <option value="Corporate Coupons">Corporate Coupons</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="row" style={{width: "100%"}}>
                            <div className="col">
                                <div className="form-group">
                                    <label htmlFor="subject" style={{fontWeight: "bold"}}>Emails</label>
                                    <textarea type="text" className="form-control" id="emails" placeholder="Corporate Emails" value={emails} onChange={(e) => setEmails(e.target.value)} required="required" rows={7} />
                                </div>
                            </div>
                        </div>

                        <div className="row" style={{width: "100%"}}>
                            <div className="col">
                                <div className="form-group">
                                    <label htmlFor="subject" style={{fontWeight: "bold"}}>Subject</label>
                                    <input type="text" className="form-control" id="subject" placeholder="Subject" value={subject} onChange={(e) => setSubject(e.target.value)} required="required" />
                                </div>
                            </div>
                        </div>

                        <div className="row" style={{width: "100%"}}>
                            <div className="col">
                                <div className="form-group">
                                    <label htmlFor="content" style={{fontWeight: "bold"}}>Content</label>
                                    {/* <textarea className="form-control" id="content" placeholder="Content" value={content} onChange={(e) => setContent(e.target.value)} required="required" rows={20} /> */}
                                    <CKEditor
                                        editor={ ClassicEditor }
                                        data={content}
                                        height={10}
                                        onReady={ editor => {
                                            // You can store the "editor" and use when it is needed.
                                            // console.log( 'Editor is ready to use!', editor );
                                        } }
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            setContent(data);
                                            // console.log( { event, editor, data } );
                                        } }
                                        onBlur={ ( event, editor ) => {
                                            // console.log( 'Blur.', editor );
                                        } }
                                        onFocus={ ( event, editor ) => {
                                            // console.log( 'Focus.', editor );
                                        } }
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row" style={{width: "100%", marginBottom: "20px"}}>
                            <div className="col" style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
                                <button type="button" className="btn btn-primary" onClick={e => previewTemplate(e)}>Preview</button>
                                <div>
                                    
                                    <button type="submit" className="btn btn-success" disabled={!saveBtnEnable}>{saveBtnText}</button>
                                    <button type="button" className="btn btn-success" style={{marginLeft: "20px"}} disabled={!sendEmailBtnEnable}>{sendEmailBtnText}</button>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div className="row" style={{width: "100%"}}>
                <div className="col">
                    <p style={{padding: "5px 10px", background:"#cecece", color: "black", fontWeight: "bold", textAlign: "center"}}>Preview</p>
                </div>
            </div>

            <div dangerouslySetInnerHTML={{__html: preview}}>
            </div>

        </div>
        }
        </>
    )
}

export default CreateCorporateNotifications;