import React, { Component } from "react";
import DatePicker from "react-datepicker";
import Loader from 'react-loader-spinner';
import DTable from './dtable.component';
import Select from 'react-select';
import swal from 'sweetalert';

import AuthService from '../services/auth/auth.service';
import UtilityService from "../services/utility.service";
import PaymentReportsService from "../services/erp/paymentreports.service";
import InvoicesService from "../services/erp/invoices.service";
import suppliercontractsService from "../services/erp/suppliercontracts.service";

import UserContext from "../contexts/UserContext";
import NoPermission from "./extras/nopermission.component";
export default class SupplierContracts extends Component {

    static contextType = UserContext;

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            columnsheadings: [],
            suppliers: [{ value: 0, label: 'Loading..' }],
            companies: [{ value: 0, label: 'Loading..' }],
            suppliername: { value: 0, label: 'Select a Supplier' },
            companyname: { value: 0, label: 'Select a Company' },
            allstatus: [{ value: 0, label: 'All Status' }, { value: 'Active', label: 'Active' }, { value: 'Expired', label: 'Expired' }],
            status: { value: 0, label: 'All Status' },
            allexpiring: [{ value: 0, label: 'Expiring Age' }, { value: 0, label: 'All' }, { value: '60-p-e', label: 'Expired 60+ Days' }, { value: '0-60-e', label: 'Expired 0-60 Days' }, { value: '0-30-w', label: 'Will expire in 0-30 Days' }, { value: '31-90-w', label: 'Will expire in 31-90 Days' }, { value: '91-p-w ', label: 'Will expire in 91+ Days' }],
            expiring: { value: 0, label: 'Expiring Age' },
            options: {
                filterType: 'dropdown',
            },
            show: false,
            error: {
                status: false,
                message: ''
            },
            loadingvalues: true,
            loader: false,
            user: [],
            search_btn_text: 'Search',
            isDisableSuppliers: true,
            searchButtonDisable: true,
            allowed: true
        };
    }

    componentDidMount() {
        PaymentReportsService.getAllSuppliers()
        .then(response => {
            if (response.data.suppliers) {
                this.setState({
                    suppliers: response.data.suppliers,
                    companies: response.data.companies,
                    loadingvalues: false,
                });
            }
        })
        .catch(error => {
            console.log({ error });
        });

        AuthService.checklogin().then(user => {

            this.setState({
                user: user
            });

            AuthService.getUserPermisions(user)
            .then(response => {
                var userpermissions = response.data;
                console.log({userpermissions});
                    if(userpermissions.includes('supplierscontract') || userpermissions.includes('all')) {
                    console.log('You are allowed!');
                    this.setState({
                        allowed: true
                    });
                    this.searchClick(true);
                } else {
                    console.log('You are not allowed!');
                    this.setState({
                        allowed: false
                    });
                }
            })
            
        }).catch(error => {
            window.location.href = '/login';
        });
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleChangCompany = (selected) => {

        this.setState({
            isDisableSuppliers: true,
            searchButtonDisable: true,
            suppliername: { value: 0, label: 'Select a Supplier' },
        });

        this.setState({
            companyname: selected,
            suppliers: [{ value: 0, label: 'Loading..' }],
        });

        InvoicesService.fetchSuppliers(selected.value)
            .then(response => {
                console.log(response);
                this.setState({
                    suppliers: response.data,
                    isDisableSuppliers: false
                });
            })
            .catch(error => {
                console.log(error);
            })
    }

    handleChangeSuplier = (selected) => {
        this.setState({
            suppliername: selected
        });
    }

    handleChangeStatus = (selected) => {
        this.setState({
            status: selected
        });
    }

    handleChangeExpiring = (selected) => {
        this.setState({
            expiring: selected
        });
    }

    searchClick = (e) => {

        if(e!==true) {
            e.preventDefault();
        }

        this.setState({
            data: [],
            show: false,
            error: {
                status: false,
                message: ''
            },
            loader: true,
            search_btn_text: 'Fetching...',
        });

        setTimeout(() => {
            suppliercontractsService.getAll(this.state.companyname.value, this.state.suppliername.value, this.state.status.value, this.state.expiring.value)
            .then(response => {
                console.log({response});
                if(response.data) {
                    console.log({response});
                    this.setState({
                        data: response.data,
                        columnsheadings: [
                            {title: 'Company Name'},
                            { title: "Supplier Name" },
                            { title: "Commission Rate" },
                            { title: "Payment Term" },
                            { title: "Comments" },
                            { title: "Commercial Comments" },
                            { title: "Valid from" },
                            { title: "Expiration Date" },
                            { title: "Indefinite Period" },
                            { title: "Expiration Age" },
                            { title: "Status" },
                            { title: "Actions" },
                        ],
                        show: true,
                        error: {
                            status: false,
                            message: ''
                        },
                        loader: false,
                        search_btn_text: 'Search',
                    });
                }
            })
            .catch(error => {
                console.log({error});
            });
        });
    }

    render() {

        if (this.state.allowed == false) {
          return <NoPermission />;
        }
        
        return (
            <>
            <div className="row">
                <div className='col'>
                    <h2>Commercial Contracts</h2>
                    <hr />
                </div>
            </div>
            <div className="row">
                <div className='col'>
                    {this.state.loadingvalues == true &&
                        <p style={{ color: "#df153b" }}><b><i>Loading suppliers. Please Wait....</i></b></p>
                    }
                    <div className="booking-reports-search-form" style={{ display: 'flex' }}>
                        <div style={{ marginLeft: '10px', width: '300px' }}>
                            <Select
                                value={this.state.companyname}
                                onChange={this.handleChangCompany}
                                options={this.state.companies}
                            />
                        </div>

                        <div style={{ marginLeft: '10px', width: '300px' }}>
                            <Select
                                value={this.state.suppliername}
                                onChange={this.handleChangeSuplier}
                                options={this.state.suppliers}
                                isDisabled= {this.state.isDisableSuppliers}
                            />
                        </div>

                        <div style={{ marginLeft: '10px', width: '300px' }}>
                            <Select
                                value={this.state.status}
                                onChange={this.handleChangeStatus}
                                options={this.state.allstatus}
                            />
                        </div>

                        <div style={{ marginLeft: '10px', width: '300px' }}>
                            <Select
                                value={this.state.expiring}
                                onChange={this.handleChangeExpiring}
                                options={this.state.allexpiring}
                            />
                        </div>
                        
                        <div style={{ marginLeft: '10px' }}>
                            <button className="btn btn-success booking-search-btn comm-search-btn" onClick={(e) => this.searchClick(e)}>{this.state.search_btn_text}</button>
                        </div>
                    </div>

                    <hr />
                </div>
            </div>

            <div className="row">
                <div className='col'>
                    {this.state.error.status == true &&
                        <div className="alert alert-danger" role="alert">
                            {this.state.error.message}
                        </div>
                    }
                    {(this.state.show == true) &&
                        <div className="contract-table"><DTable data={this.state.data} columns={this.state.columnsheadings} sorting={[]} /></div>
                    }
                    {this.state.loader == true &&
                        <Loader className='loader-center'
                            type="Grid"
                            color="#00BFFF"
                            height={100}
                            width={100}
                        />
                    }
                </div>
            </div>
            </>
        );
    }
}