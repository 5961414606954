import React, { Component } from "react";
import DatePicker from "react-datepicker";
import Loader from 'react-loader-spinner';
import Select from 'react-select';
import DTable from './dtable.component';
import axios from "axios";
import swal from 'sweetalert';

import AuthService from '../services/auth/auth.service';
import PaymentReportsService from '../services/erp/paymentreports.service';
import UtilityService from '../services/utility.service';
import InvoicesService from '../services/erp/invoices.service';
import CommissionsService from "../services/erp/commissions.service";

import "react-datepicker/dist/react-datepicker.css";

import UserContext from "../contexts/UserContext";
import NoPermission from "./extras/nopermission.component";

export default class PaymentReports extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            columnsheadings: [],
            options: {
                filterType: 'dropdown',
            },
            show: false,
            username: null,
            products: [{ ID: 0, post_title: 'Loading..'}],
            suppliers: [{ value: 0, label: 'Loading..' }],
            companies: [{ value: 0, label: 'Loading..' }],
            startdate: '',
            suppliername: null,
            enddate: '',
            productname: {value: 0, label: 'Select a product'},
            companyname: { value: 0, label: 'Select a Company' },
            orderstatus: 0,
            paymentstatus: 0,
            loadingvalues : true,
            error: {
                status: false,
                message: ''
            },
            search_btn_text: 'Search',
            loader: false,
            invoicecompanyname: { value: 0, label: 'Select a Company' },
            invoicesuppliername: null,
            invoicesuppliers: { value: 0, label: 'Select a Supplier' },
            invoicedate: '',
            invoiceduedate: '',
            invoicenumber: '',
            invoicefromdate: '',
            invoicetodate: '',
            invoicefile: '',
            invoiceexternal: '',
            externalfilelinktosheet: '',
            invoiceamount: 0,
            invoiceprices: { value: 'NET Rates', label: 'NET Rates' },
            invoicespricesoptions: [{ value: 'NET Rates', label: 'NET Rates' }, { value: "Public Rates (commisionable)", label: "Public Rates (commisionable)" }],
            all_invoices: [{ value: 0, label: 'Select Invoice' }],
            selected_bulk_invoice: { value: 0, label: 'Select Invoice' },
            selectedFile: '',
            filterInvoice: 0,
            minDate: new Date("02-01-2019"),
            minDateInvoice: new Date("02-01-2019"),
            invoiceType: { value: 'E-Invoice', label: 'E-Invoice' },
            invoiceTypeAll: [{ value: 'E-Invoice', label: 'E-Invoice' }, { value: "Physical Invoice", label: "Physical Invoice" }],
            allowed: true,
            relatedCredits: [],
            disableDuedate: false,
            isItSoa: false,
            allocate: false,
            invoiceData: false,
            fetchedCommissions: [],
            commissionHeadings: [],
            showCommissions: false
        };

        // this.handleChange = this.handleChange.bind(this);
    }

    resetInvoice = () => {
        this.setState({
            invoicecompanyname: this.state.companies,
            invoicesuppliername: null,
            invoicesuppliers: { value: 0, label: 'Select a Supplier' },
            invoicedate: '',
            invoiceduedate: '',
            invoicenumber: '',
            invoicefromdate: '',
            invoicetodate: '',
            invoicefile: '',
            invoiceamount: 0,
            invoiceprices: { value: 'NET Rates', label: 'NET Rates' },
            selectedFile: '',
            isItSoa: false
        });
    }

    reset = (e) => {
        e.preventDefault();

        this.setState({
            show: false,
            error: {
                status: false,
                message: ''
            },
            search_btn_text: 'Search',
            companyname: { value: 0, label: 'Select a Company' },
            orderstatus: 0,
            paymentstatus: 0,
            startdate: '',
            suppliername: null,
            productname: { value: 0, label: 'Select a product' },
            enddate: '',
            loader: false,
            error_msg: false,
            filterInvoice: 0,
            allocate: false,
            invoiceData: false,
            fetchedCommissions: [],
            showCommissions: false
        });

        this.resetInvoice();

        window.location.href = '/payment-report';
    }

    handleChange(event) {
        this.setState({ 
            [event.target.name] : event.target.value 
        });
    }

    viewCommissions(e) {
        this.setState({
            showCommissions: !this.state.showCommissions
        });
    }

    handleSOAChange(event) {
        this.setState({
            isItSoa: !this.state.isItSoa
        });
    }

    componentDidMount() {
        console.log(this.props.match.params.invoiceid);
        setTimeout(async () => {
            if (typeof this.props.match.params.invoiceid == 'undefined') {
                this.setState({
                    filterInvoice: 0
                });
            } else {

                var filterInvoiceTemp = this.props.match.params.invoiceid;
                if(this.props.match.params.invoiceid.includes('all|')) {
                    this.setState({
                        filterInvoice: this.props.match.params.invoiceid.split('|')[1],
                        allocate: true,
                        selected_bulk_invoice: { value: this.props.match.params.invoiceid.split('|')[1], label: this.props.match.params.invoiceid.split('|')[1] },
                    });
                    filterInvoiceTemp = this.props.match.params.invoiceid.split('|')[1];
                    
                } else {
                    this.setState({
                        filterInvoice: this.props.match.params.invoiceid
                    });
                    
                }

                await UtilityService.getSingleInvoiceData(filterInvoiceTemp)
                .then(res => {
                    console.log("invoice data", res);
                    this.setState({
                        invoiceData: res.data,
                        companyname: { value: res.data.company, label: res.data.company },
                        suppliername: [{ value: res.data.supplier, label: res.data.supplier }],
                        startdate: new Date(res.data.booking_period_from),
                        enddate: new Date(res.data.booking_period_to),
                        orderstatus: 'wc-processing'
                    });

                    CommissionsService.getSingle(res.data.supplier, res.data.company)
                    .then(response => {
                        console.log('commissions', response);
                        this.setState({
                            fetchedCommissions: response.data,
                            commissionHeadings: [
                                { title: "#" },
                                { title: "Created On" },
                                { title: "Company" },
                                { title: "Supplier" },
                                { title: "Product" },
                                { title: "Type" },
                                { title: "Commission" },
                                { title: "Price List" },
                                { title: "From" },
                                { title: "To" },
                                { title: "Comments" },
                            ]
                        });
                    })
                    .catch(err => {
                        console.log({err});
                    });

                })
                .catch(err => {
                    console.log({err});
                });

                if (this.state.filterInvoice != 0) {
                    await this.searchClick(null);
                }
            }
        }, 1000);
        

        PaymentReportsService.getAllSuppliers()
        .then(response => {
            if (response.data.suppliers) {
                this.setState({
                    suppliers: response.data.suppliers,
                    companies: response.data.companies,
                    products: response.data.products,
                    loadingvalues : false,
                });
            }
        })
        .catch(error => {
            console.log({error});
        });

        // InvoicesService.getInvoiceNumber()
        // .then(response => {
        //     this.setState({
        //         all_invoices: response.data
        //     });
        // })
        // .catch(error => {
        //     console.log(error);
        // });
        
        AuthService.checklogin().then(user => {
            // console.log({ user });
            this.setState({
                username: user.user_display_name
            });

            AuthService.getUserPermisions(user)
            .then(response => {
                var userpermissions = response.data;
                console.log({userpermissions});
                    if(userpermissions.includes('paymentreports') || userpermissions.includes('all')) {
                    console.log('You are allowed!');
                    this.setState({
                        allowed: true
                    });
                } else {
                    console.log('You are not allowed!');
                    this.setState({
                        allowed: false
                    });
                }
            })
        }).catch(error => {
            window.location.href = '/login';
        });
    }

    searchClick(e) {
        if(e!=null) {
            e.preventDefault();
        }

        this.setState({
            show: false,
            error: {
                status: false,
                message: ''
            },
            search_btn_text: 'Fetching..',
            loader: true,
        });
        var startday = '';
        var endday = '';

        if (this.state.startdate != '' && this.state.enddate!='') {
            startday = UtilityService.format_date(this.state.startdate);
            endday = UtilityService.format_date(this.state.enddate);
        }
        PaymentReportsService.getReport(startday, endday, this.state.productname.value, this.state.suppliername, this.state.companyname.value, this.state.orderstatus, this.state.paymentstatus, this.state.filterInvoice, this.state.allocate)
        .then(response => {
            console.log({response});
            if (typeof response.data=='undefined') {
                this.setState({
                    error: {
                        status: true,
                        message: 'No data found for the selection, Please retry or check if dates were selected.'
                    },
                    loader: false,
                    search_btn_text: 'Search',
                });
            } else if (response.data.length > 0) {
                this.setState({
                    data: response.data,
                    columnsheadings: [
                        {title: "<input type='checkbox' value='allselect' class='checkboxall' />"},
                        {title: "Order Status"},
                        {title: "Product"},
                        {title: "Customer"},
                        {title: "Order Date"},
                        {title: "Booking Dates"},
                        {title: "Payment Method & Reference"},
                        {title: "Voucher"},
                        {title: "Booking Details"},
                        {title: "Total Selling Price"},
                        {title: "Refund"},
                        {title: "Coupons"},
                        {title: "Fee"},
                        {title: "Com Rate"},
                        {title: "Cost Price"},
                        { title: "Payable" },
                        {title: "Supplier Claim"},
                        { title: "Invoice Details" },
                        {title: "Comments"},
                        {title: "Supplier Comments"},
                    ],
                    options: {
                        filterType: 'dropdown',
                    },
                    show: true,
                    loader: false,
                    search_btn_text: 'Search',
                    error: {
                        status: false,
                        message: ''
                    },
                });

                // document.querySelector('.checkboxall').click();

                InvoicesService.getInvoiceNumbers('', '', this.state.companyname.value, this.state.suppliername)
                .then(result => {
                    console.log({result});
                    this.setState({
                        all_invoices : result.data
                    });
                })
                .catch(error => {
                    console.log({error});
                    this.setState({
                        error: {
                            status: true,
                            message: 'No data found for the selection, Please retry or check if dates were selected.'
                        },
                        loader: false,
                        search_btn_text: 'Search',
                    });
                });
                
            } else {
                this.setState({
                    error: {
                        status: true,
                        message: 'No data found for the selection, Please retry or check if dates were selected.'
                    },
                    search_btn_text: 'Search',
                    loader: false,
                    show: false,
                });
            }
        });
    }

    supChange = (selectedOption) => {
        this.setState({ suppliername: selectedOption });
        console.log({selectedOption});
    }

    voucherStatusChange = (element) => {
    }

    setStartDate = (date) => {
        this.setState({
            startdate: date,
            minDate: date
        });

        if (this.state.enddate != '' && this.state.enddate < date) {
            this.setState({
                enddate: date
            });
        }
    }

    setEndDate = (date) => {
        
        this.setState({
            enddate: date
        });
    }

    setInvoiceDate = (date) => {

        InvoicesService.fetchCreditFacilities(this.state.invoicecompanyname.value)
        .then(response => {
            console.log({response});
            if(response.data.length<1) {
                this.setState({
                    disableDuedate: false
                })
            } 
            this.setState({
                relatedCredits: response.data
            });
        })

        this.setState({
            invoicedate: date
        });
    }

    setInvoiceDueDate = (date) => {

        this.setState({
            invoiceduedate: date
        });
    }

    setInvoiceFromDate = (date) => {
        this.setState({
            invoicefromdate: date,
            minDateInvoice: date
        });
        if (this.state.invoicetodate != '' && this.state.invoicetodate < date) {
            this.setState({
                invoicetodate: date
            });
        }
    }

    setInvoiceToDate = (date) => {

        this.setState({
            invoicetodate: date,
        }); 
    }

    handleChangeProduct = selectedOption => {
        this.setState({ productname: selectedOption });
    };

    handleChangeSuplier = selectedOption => {
        this.setState({ suppliername: selectedOption });

        // if (this.state.suppliername.length > 1 && this.state.suppliername[0].value==0) {
        //     this.setState({ 
        //         suppliername: [{ value: 0, label: 'Select a Supplier' }] 
        //     }); 
        // }
        console.log({ selectedOption});
    };

    handleChangeInvoiceType = selectedOption => {
        this.setState({ invoiceType: selectedOption });

        // if (this.state.suppliername.length > 1 && this.state.suppliername[0].value==0) {
        //     this.setState({ 
        //         suppliername: [{ value: 0, label: 'Select a Supplier' }] 
        //     }); 
        // }
        console.log({ selectedOption});
    };

    handleChangCompany = selectedOption => {
        this.setState({ companyname: selectedOption });
        InvoicesService.fetchSuppliers(selectedOption.value)
            .then(response => {
                console.log(response);
                this.setState({
                    suppliers: response.data
                });
            })
            .catch(error => {
                console.log(error);
            })
    };

    handleChangeInvoiceSuplier = selectedOption => {
        this.setState({ invoicesuppliername: selectedOption });
        // if (selectedOption == null) {
        //     this.setState({
        //         invoicesuppliername: [{ value: 0, label: 'Select a Supplier' }]
        //     });
        // }
    };

    invoicePriceTypeChange = selectedOption => {
        this.setState({ invoiceprices: selectedOption });
    };

    handleChangInvoiceCompany = selectedOption => {
        this.setState({ invoicecompanyname: selectedOption });
        InvoicesService.fetchSuppliers(selectedOption.value)
        .then(response=>{
            console.log(response);
            this.setState({
                invoicesuppliers : response.data
            });

        })
        .catch(error=>{
            console.log(error);
        })
    };

    handleInvoiceFileChange = (e) => {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);

        axios.post('https://deals.mu/scripts/reportsapi/invoicesfileupload.php', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                if(response.data.file!=''){
                    this.setState({
                        selectedFile: response.data.file
                    });
                } else {
                    this.setState({
                        selectedFile: ''
                    });
                }
                // console.log(response);
            }).catch(error => {
                console.log(error);
            });

        console.log(e.target.files[0]);        

    };

    showinvoices = (e) => {
        this.searchClick(null);
    }

    handleChangeBulkInvoice = selectedOption => {
        this.setState({ selected_bulk_invoice: selectedOption });
    };

    createInvoice = () => {
        console.log('clicked');
        this.setState({
            error_msg: false
        });

        if (this.state.invoicecompanyname.value == 0 || this.state.invoicesuppliername == null || this.state.invoicedate == '' || this.state.invoicenumber == '' || this.state.invoicefromdate == '' || this.state.invoicetodate == '' || this.state.invoiceamount == '' || this.state.invoiceamount == 0 || this.state.invoiceduedate == '') {
            this.setState({
                error_msg: true
            });
        } else {

             InvoicesService.checkInvoiceNumber(this.state.invoicenumber)
            .then(response => {
                console.log({response});
                if(response.data=='exists') {
                    swal('Invoice Number Exists!', 'The invoice number exists already please use another one', 'error');
                } else {
                    InvoicesService.createInvoice(this.state.invoicecompanyname.value, this.state.invoicesuppliername, UtilityService.format_date(this.state.invoicedate), this.state.invoicenumber, UtilityService.format_date(this.state.invoicefromdate), UtilityService.format_date(this.state.invoicetodate), this.state.selectedFile, this.state.invoiceamount, this.state.invoiceprices.value, this.state.invoiceexternal, this.state.invoiceType.value, UtilityService.format_date(this.state.invoiceduedate), this.state.username, this.state.isItSoa, this.state.externalfilelinktosheet)
                    .then(response => {
                        console.log(response);
                        InvoicesService.getInvoiceNumber()
                        .then(response => {
                            this.setState({
                                all_invoices: response.data
                            });
                        })
                        .catch(error => {
                            console.log(error);
                        });

                        document.querySelector('.close').click();
                        this.resetInvoice();
                    })
                    .catch(error => {
                        console.log(error);
                    });
                }
            })
            .catch(error => {
                console.log({error});
            });
        }
    }

    setAutoDueDate = (e, days, type) => {
        console.log("setAutoDate");
        var value  = e.target.checked;
        // console.log({value});
        console.log({days});

        if(value==true) {
            if(this.state.invoicedate!='') {

                var invoiceDueDate = new Date();
                if(type.includes('Pre-')) {
                    invoiceDueDate.setDate(this.state.invoicedate.getDate());
                } else {
                    invoiceDueDate.setDate(this.state.invoicedate.getDate() + parseInt(days));
                }

                console.log({invoiceDueDate});

                this.setState({
                    invoiceduedate: invoiceDueDate
                });
            } else {
                this.setState({
                    invoiceduedate: ''
                });
            }
        } else {
            this.setState({
                invoiceduedate: ''
            });
        }
    }

    render() {
        var { data, columnsheadings, options, show, products, suppliers, companies, loadingvalues, error, search_btn_text, loader, invoicesuppliers } = this.state;

        if (this.state.allowed == false) {
          return <NoPermission />;
        }
        
        return ( 
            <>  
                <div className="row">
                    <div className='col'>
                            <h2>{this.state.filterInvoice!=0 ? "Invoice":"Deals"} Payment Report 
                                {this.state.filterInvoice!=0 && 
                                " (Invoice #" + this.state.filterInvoice+ ")"
                                }
                            </h2>
                            <hr />
                    </div>
                </div>
                <div className="row">
                    <div className='col'>
                        {loadingvalues==true && this.state.filterInvoice==0 &&
                            <p style={{ color: "#df153b" }}><b><i>Loading Products/Suppliers. Please Wait....</i></b></p>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className='col'>
                        {(this.state.filterInvoice==0 || this.state.allocate==true) && 
                        <>
                        <form action="" method="get" className="booking-reports-search-form" style={{ display: 'flex', marginBottom: '20px' }}>
                            <input type="hidden" name="page" value="deals-payment-report" />
                            <input type='hidden'  value={this.state.filterInvoice} onChange={this.showinvoices} />
                            <div style={{ width: '230px' }}>
                                <span style={{ color: "red" }}>*</span>Choose Booking Period:
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                <DatePicker
                                    dateFormat="dd-MM-yyyy"
                                    selected={this.state.startdate}
                                    onChange={date => this.setStartDate(date)}
                                    placeholderText="Start Date"
                                    className='form-control'
                                />
                            </div>
                            <div style={{ marginLeft: '10px' }}>      
                                <DatePicker
                                    dateFormat="dd-MM-yyyy"
                                    selected={this.state.enddate}
                                    onChange={date => this.setEndDate(date)}
                                    placeholderText="End Date"
                                    className='form-control'
                                    minDate={this.state.minDate}
                                />
                            </div>
                            <div style={{ marginLeft: '10px', cursor: 'help' }}><i className="far fa-question-circle" data-placement="right" data-toggle="tooltip" data-html="true" title="Always enter the date and then choose either Supplier or Company."></i></div>
                        </form>
                        {this.state.allocate==false && 
                        <form action="" method="get" className="booking-reports-search-form" style={{ display: 'flex', marginBottom: '20px' }}>
                            <div style={{ width: '230px' }}>
                                <span style={{ color: "red" }}>*</span>Choose Filtering Option:
                            </div>
                            <div style={{marginLeft : '10px', width:'300px'}}>
                                <Select
                                    value={this.state.companyname}
                                    onChange={this.handleChangCompany}
                                    options={companies}
                                />   
                                
                            </div>
                            <div style={{ marginLeft: '10px', width: '300px' }}>
                                <Select
                                    value={this.state.suppliername}
                                    onChange={this.handleChangeSuplier}
                                    options={suppliers}
                                    isMulti={true}
                                    placeholder={"Select Suppliers"}
                                />
                                
                            </div>
                            <div style={{ marginLeft: '10px', width: '300px' }}>              
                                <Select
                                    value={this.state.productname}
                                    onChange={this.handleChangeProduct}
                                    options={products}
                                />
                            </div>
                        </form>
                        }
                        <form action="" method="get" className="booking-reports-search-form" style={{ display: 'flex' }}>
                            <div style={{ width: '230px' }}>
                                Choose Payment / Order status 
                            </div>
                            <div style={{ marginLeft : '10px' }}>
                                <select name="orderstatus" className="select-drops form-control" value={this.state.orderstatus} onChange={this.handleChange.bind(this)}>
                                    <option value="0">All Order Status</option>
                                    <option value="wc-completed">Completed</option>
                                    <option value="wc-processing">Processing</option>
                                    <option value="wc-pending">Pending</option>
                                    <option value="wc-on-hold">On-hold</option>
                                    <option value="wc-cancelled">Cancelled</option>
                                    <option value="wc-refunded">Refunded</option>
                                    <option value="wc-failed">Failed</option>
                                    <option value="refunded">Refunded</option>
                                </select>
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                <select name="paymentstatus" value={this.state.paymentstatus} className="form-select select-drops form-control" onChange={this.handleChange.bind(this)}>
                                    <option value='0'>Select Payment Status</option>
                                    <option value="Payable">Payable</option>
                                    <option value="Partially Paid">Partially Paid</option>
                                    <option value="Pending">Pending</option>
                                    <option value="Rejected">Rejected</option>
                                    
                                </select>
                            </div>
                            <div style={{ marginLeft : '10px' }}>
                                <button className="btn btn-success booking-search-btn" onClick={this.searchClick.bind(this)}>{search_btn_text}</button>
                            </div>
                            <div style={{ marginLeft : '10px' }}>
                                <a href="/payment-report" className="btn btn-warning booking-reset-btn" onClick={this.reset}>Reset</a>
                            </div>
	
                        </form>

                        <hr />
                        </>
                        }
                        <div className='bulk-actions'>
                            {(this.state.filterInvoice==0 || this.state.allocate==true) && 
                            <>
                            <h6><u>Bulk Actions:</u></h6><br />
                            <div style={{ marginLeft: '10px', display: 'flex', height: '38px' }}>
                                <input type="text" id="bulk-comm-rate" className='form-control' placeholder='   Enter Commission Rate' autocomplete='off' />
                                <button style={{ marginLeft: '0px'}} id="bulk-comm-rate-btn" className="btn btn-success">Apply</button>
                            </div>
                            {/* {this.state.allocate==false &&  */}
                            <div style={{ marginLeft: '10px', display: 'flex', height: '38px' }}>
                                <input type="hidden" id="bulk-invoice-number" value={this.state.selected_bulk_invoice.value} className='form-control' placeholder='Allocate to Invoice ' autocomplete='off' />
                                <span style={{display: this.state.allocate ? "none": 'block'}}>
                                <Select
                                    value={this.state.selected_bulk_invoice}
                                    onChange={this.handleChangeBulkInvoice}
                                    options={this.state.all_invoices}
                                    className='invoicebulkselect' 
                                />   
                                </span>
                                <button style={{ marginLeft: '0px' }} id="bulk-invoice-number-btn" className="btn btn-success">{this.state.allocate==true ? "Allocate to Invoice": 'Apply'}</button>
                            </div>
                            {/* } */}

                            {this.state.allocate==false && 
                            <div style={{ marginLeft: '20px' }}>
                                <button id="new-invoice-btn" className="btn btn-success" data-toggle="modal" data-target=".create-invoice-modal">Create New Invoice</button>
                                <div className="modal fade create-invoice-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                                    <div className="modal-dialog modal-lg">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title">Create New Invoice</h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                {this.state.error_msg && 
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <div className="alert alert-danger" role="alert">
                                                            Fill all mandatory fields marked as <b style={{ color: 'red' }}>*</b>
                                                        </div>
                                                    </div>
                                                </div>
                                                }
                                                <div className="form-group row">
                                                    <label for="company" className="col-sm-3 col-form-label">Company<b style={{ color: 'red' }}>*</b></label>
                                                    <div className="col-sm-9">
                                                        <Select
                                                            value={this.state.invoicecompanyname}
                                                            onChange={this.handleChangInvoiceCompany}
                                                            options={companies}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label for="supplier" className="col-sm-3 col-form-label">Supplier<b style={{ color: 'red' }}>*</b></label>
                                                    <div className="col-sm-9">
                                                        <Select
                                                            value={this.state.invoicesuppliername}
                                                            onChange={this.handleChangeInvoiceSuplier}
                                                            options={invoicesuppliers}
                                                            isMulti={true}
                                                            placeholder={"Select Suppliers"}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label for="invoicedate" className="col-sm-3 col-form-label">Invoice Date<b style={{ color: 'red' }}>*</b></label>
                                                    <div className="col-sm-9">
                                                        <DatePicker
                                                            dateFormat="dd-MM-yyyy"
                                                            selected={this.state.invoicedate}
                                                            onChange={date => this.setInvoiceDate(date)}
                                                            placeholderText="Invoice Date"
                                                            className='form-control'
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label for="" className="col-sm-3 col-form-label">Related Credit Facilities</label>
                                                    <div className="col-sm-9">
                                                        {this.state.relatedCredits.length>0 && 
                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Select</th>
                                                                        <th>Supplier</th>
                                                                        <th>Credit Facility</th>
                                                                        <th>Starts From</th>
                                                                        <th>Expires On</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.relatedCredits.map((credit, index) => (
                                                                    <tr key={index}>
                                                                        <td><input type="radio" onClick={(e) => this.setAutoDueDate(e, credit.payment_terms_days, credit.payment_terms)} /></td>
                                                                        <td>{credit.supplier}</td>
                                                                        <td>{credit.payment_terms} / {credit.payment_terms_days} days</td>
                                                                        <td>{credit.valid_from}</td>
                                                                        <td>{credit.expiration_date}</td>
                                                                    </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label for="invoiceduedate" className="col-sm-4 col-form-label">Is It SOA?</label>
                                                    <div className="col-sm-8">
                                                        <input class="form-check-input" type="checkbox" checked={this.state.isItSoa} onChange={this.handleSOAChange.bind(this)}></input>
                                                    </div>
                                                </div>
                                                
                                                <div className="form-group row">
                                                    <label for="invoiceduedate" className="col-sm-3 col-form-label">Due Date<b style={{ color: 'red' }}>*</b></label>
                                                    <div className="col-sm-9">
                                                        <DatePicker
                                                            dateFormat="dd-MM-yyyy"
                                                            selected={this.state.invoiceduedate}
                                                            onChange={date => this.setInvoiceDueDate(date)}
                                                            placeholderText="Invoice Due Date"
                                                            className='form-control'
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label for="invoicenumber" className="col-sm-3 col-form-label">Invoice Number<b style={{ color: 'red' }}>*</b></label>
                                                    <div className="col-sm-9">
                                                        <input type="text" className="form-control" value={this.state.invoicenumber} name='invoicenumber' onChange={this.handleChange.bind(this)} id="invoicenumber" placeholder="Invoice Number" />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label for="bookingperiod" className="col-sm-3 col-form-label">Booking Period<b style={{ color: 'red' }}>*</b></label>
                                                    <div className="col-sm-9">
                                                        <DatePicker
                                                            dateFormat="dd-MM-yyyy"
                                                            selected={this.state.invoicefromdate}
                                                            onChange={date => this.setInvoiceFromDate(date)}
                                                            placeholderText="From Date"
                                                            className='form-control'
                                                        />
                                                        <DatePicker
                                                            dateFormat="dd-MM-yyyy"
                                                            selected={this.state.invoicetodate}
                                                            onChange={date => this.setInvoiceToDate(date)}
                                                            placeholderText="To Date"
                                                            className='form-control'
                                                            minDate={this.state.minDateInvoice}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label for="supplier" className="col-sm-3 col-form-label">Invoice Type</label>
                                                    <div className="col-sm-9">
                                                        <Select
                                                            value={this.state.invoiceType}
                                                            onChange={this.handleChangeInvoiceType}
                                                            options={this.state.invoiceTypeAll}
                                                            placeholder={"Select Invoice Type"}
                                                        />
                                                    </div>
                                                </div>
                                                
                                                <div className="form-group row" style={{display: "none"}}>
                                                    <label for="invoicefile" className="col-sm-3 col-form-label">Invoice File Upload</label>
                                                    <div className="col-sm-9">
                                                        <input type="file" className="form-control" value={this.state.invoicefile} name='invoicefile' onChange={this.handleInvoiceFileChange} id="invoicefile" placeholder="Invoice file" />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label for="external_link_working_sheet" className="col-sm-3 col-form-label">Link to Working Sheet</label>
                                                    <div className="col-sm-9">
                                                        <textarea className="form-control" value={this.state.externalfilelinktosheet} name='externalfilelinktosheet' onChange={this.handleChange.bind(this)} id="externalfilelinktosheet" placeholder="Link to Working Sheet"></textarea>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label for="external_link" className="col-sm-3 col-form-label">Link to E-Invoice</label>
                                                    <div className="col-sm-9">
                                                        <input type="text" className="form-control" value={this.state.invoiceexternal} name='invoiceexternal' onChange={this.handleChange.bind(this)} id="invoiceexternal" placeholder="Invoice file URL" />
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label for="invoiceprices" className="col-sm-3 col-form-label">Invoice Prices<b style={{ color: 'red' }}>*</b></label>
                                                    <div className="col-sm-9">
                                                        <Select
                                                            value={this.state.invoiceprices}
                                                            onChange={this.invoicePriceTypeChange}
                                                            options={this.state.invoicespricesoptions}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label for="invoiceamount" className="col-sm-3 col-form-label">Invoice Amount<b style={{ color: 'red' }}>*</b></label>
                                                    <div className="col-sm-9">
                                                        <input type="number" className="form-control" value={this.state.invoiceamount} name='invoiceamount' onChange={this.handleChange.bind(this)} id="invoiceamount" placeholder="Invoice Amount" />
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-danger" data-dismiss="modal">Cancel</button>
                                                <button type="button" className="btn btn-success invoice-save-btn" onClick={this.createInvoice}>Create Invoice</button>
                                            </div>
                                    </div>
                                    </div>
                                </div>

                            </div>
                            }
                            </>
                            }
                            <div style={{ marginLeft: '20px' }}>
                                <table className='table table-responsive table-bordered'>
                                    <thead>
                                        <tr>
                                            <th>Total Selling Amount</th>
                                            <th>Comission Amount</th>
                                            <th>Total Cost</th>
                                            <th>Total Discounts/Coupons</th>
                                            <th>Total Payable</th>
                                            <th>Total Undercharge</th>
                                            <th>Total Overcharge</th>
                                            <th>Total Supplier Claim</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Rs <span id='payment-report-total'>0</span></td>
                                            <input type='hidden' id='totalselling' value='0' />
                                            <td>Rs <span id='payment-report-commission-amount'>0</span></td>
                                            <input type='hidden' id='totalcomission' value='0' />
                                            <td>Rs <span id='payment-report-total-cost'>0</span></td>
                                            <td>Rs <span id='payment-report-total-discounts'>0</span></td>
                                            <td>Rs <span id='payment-report-total-payable'>0</span></td>
                                            <td>Rs <span id='payment-report-total-undercharge'>0</span></td>
                                            <td>Rs <span id='payment-report-total-overcharge'>0</span></td>
                                            <input type='hidden' id='totalpayable' value='0' />
                                            <td>Rs <span id='payment-report-total-supplier-claim'>0</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                                {/* <h5><u>Calculations: </u></h5>
                                <div style={{ marginLeft: '10px' }}><b>Total:</b> Rs <span id='payment-report-total'>0</span></div>
                                <div style={{ marginLeft: '10px' }}> <b>|| Comission Amount:</b> Rs <span id='payment-report-commission-amount'>0</span></div>
                                <div style={{ marginLeft: '10px' }}> <b>|| Total Cost:</b> Rs <span id='payment-report-total-cost'>0</span></div>
                                <div style={{ marginLeft: '10px' }}> <b>|| Total Supplier Claim:</b> Rs <span id='payment-report-total-supplier-claim'>0</span></div> */}
                            </div>
                        </div>
                        <hr />
                    </div>
                </div>
                {this.state.filterInvoice!=0 && this.state.fetchedCommissions.length>0 &&  
                <div className="row">
                    <div className="col">
                        <div className="container-fluid">
                            <h5 style={{backgroundColor: "beige", padding: '10px', cursor: 'pointer'}} onClick={this.viewCommissions.bind(this)}>View Related Commisions</h5>
                            {this.state.showCommissions==true && 
                            <div className="payment-report-table">
                                <DTable data={this.state.fetchedCommissions} columns={this.state.commissionHeadings} paginationTotalRows={50} />
                            </div>
                            }
                        </div>
                        <br /><br />
                    </div>
                </div>
                }
                <div className="row">
                    <div className='col'>
                        <div className="container-fluid">
                            {(show==true) && 
                            <div className="payment-report-table">
                                <DTable data={data} columns={columnsheadings} paginationTotalRows={50} />
                            </div>
                            }
                            {error.status==true && 
                                <div className="alert alert-danger" role="alert">
                                    {error.message}
                                </div>
                            }
                            {loader == true &&
                                <Loader className='loader-center'
                                    type="Grid"
                                    color="#00BFFF"
                                    height={100}
                                    width={100}
                                />
                            }
                        </div>
                    </div>
                </div>
                <div className="modal fade bd-example-modal-lg viewcommentscommission" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Supplier Comments/Notes</h5>
                            <button type="button" className="close closecommentcommisionviewmodal" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">X</span>
                            </button>
                        </div>
                        <div className="modal-body" id="viewcommissioncommentmodalbody">
                            
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary closecommentcommisionviewmodal" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            </>
        );
    }
}