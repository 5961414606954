import React, { Component } from "react";
import DTable from './dtable.component';
import AuthService from '../services/auth/auth.service';
import UtilityService from "../services/utility.service";
import UploadedinvoicesService from "../services/erp/uploadedinvoices.service";
import Spinner from "../Spinner.gif";

import UserContext from "../contexts/UserContext";
import NoPermission from "./extras/nopermission.component";
export default class UploadedInvoices extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            columnsheadings: [],
            options: {
                filterType: 'dropdown'
            },
            loadingvalues: true,
            show: false,
            company: '',
            error: {
                status: false,
                message: ''
            },
            allowed: true
        }
    }

    componentDidMount() {
        AuthService.checklogin()
            .then(response => {
                if (response) {
                    this.setState({
                        company: response.company,
                    });
                    // window.location.href = '/booking-report';
                    setTimeout(() => {
                        this.getProducts();
                    }, 2000);
                }

                AuthService.getUserPermisions(response)
                .then(response => {
                    var userpermissions = response.data;
                    console.log({userpermissions});
                        if(userpermissions.includes('uploadedinvoices') || userpermissions.includes('all')) {
                        console.log('You are allowed!');
                        this.setState({
                            allowed: true
                        });
                    } else {
                        console.log('You are not allowed!');
                        this.setState({
                            allowed: false
                        });
                    }
                })
            })
            .catch(error => {
                window.location.href = '/login';
            });
    }

    getProducts = () => {
        this.setState({
            loadingvalues: true,
            show: false,
            error: {
                status: false,
                message: ''
            },
        });

        UploadedinvoicesService.getAll()
        .then(response => {
            console.log({response});
            if (response.data) {

                this.setState({
                    data: response.data,
                    columnsheadings: [
                        { title: "Voucher" },
                        { title: "Company (Supplier)" },
                        { title: "View Order" },
                        { title: "File" },
                        {title: "Uploaded On"},
                        {title: "Status"}
                    ],
                    loadingvalues: false,
                    show: true,
                });
            } else {
                this.setState({
                    loadingvalues: false,
                    show: false,
                    error: {
                        status: true,
                        message: 'No uploaded invoices found'
                    },
                });
            }
        })
        .catch(error => {
            console.log({error});
            this.setState({
                loadingvalues: false,
                show: false,
                error: {
                    status: true,
                    message: 'No uploaded invoices found'
                },
            });
        });
    }

    render() {
        var { loadingvalues, show, error, data, columnsheadings } = this.state;

        if(this.state.allowed==false) {
            return (
                <NoPermission />
            );
        }
        
        return(
            <div style={{ padding: '20px' }}>
                <div className="row">
                    <div className="col-12">
                        <h3>Invoices Uploaded by Suppliers</h3>
                        {/* <p>Here you will find the Availablity calendars for all your products featured on Deals.mu.</p>
                        <p>You will have a detailed overview on all open / closed dates and room types.</p> */}
                        <hr />
                    </div>
                </div>
                <div className="row">
                    <div className='col'>
                        {loadingvalues == true &&
                            <p style={{ color: "#df153b" }}><b><i>Loading Uploaded Invoices. Please Wait....</i></b></p>
                        }
                        {show == true &&
                            <DTable data={data} columns={columnsheadings} searchText={"Search a Specific File: "} exportButtons={[]} defaultSort={[3, 'desc']} />
                        }
                        {loadingvalues == true &&
                            <img src={Spinner} style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
                        }
                        {error.status == true &&
                            <div className="alert alert-danger" role="alert">
                                {error.message}
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}