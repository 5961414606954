import axios from "axios";

const API_URL = 'https://deals.mu/scripts/partners-interface/partnersapi/utility.php';

class StopSalesService {
    getProducts(company) {
        console.log({company});
        var params = {
            action: 'get_all_products',
            company: company,
            rand: Math.random()
        };
        return axios
            .get(API_URL, { params })
            .then((response) => {
                // console.log({ response });
                // console.log('I am here in service');
                return response;
            }).catch(error => {
                return false;
            });
    }

}

export default new StopSalesService();