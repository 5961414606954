import React, { Component } from "react";
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import ReactTooltip from "react-tooltip";
import Modal from '@mui/material/Modal';
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";
import { style } from "@mui/system";

import AuthService from '../services/auth/auth.service';
import OrdersReportService from "../services/orders/ordersreport.service";

import NoPermission from "./extras/nopermission.component";
import AddReminder from "./orders/addreminder.component";
import OrderNotes from "./orders/ordernotes.component";
import OrderLogs from "./orders/orderlogs.component";
import SendVoucherToCustomer from "./orders/sendvouchertocustomer.component";
import SendVoucherToSupplier from "./orders/sendvouchertosupplier.component";
import SupplierConfirmed from "./orders/supplierconfirmed.component";
import UpdateVoucherDetails from "./orders/updatevoucherdetails.component";
import AddRefund from "./orders/addrefund.component";
import AllRefunds from "./orders/allrefunds.component";
import AddComplaint from "./orders/addcomplaint.component";
import AllComplaints from "./orders/allcomplaints.component";
import TransferPoints from "./orders/trasnferorder.component";
import Emails from "./orders/emails.component";
import EditOrderItemModal from "./orders/editorderitem.component";
import AddFee from "./orders/addFee.component";
import OrderPointsCoupon from "./orders/orderpointscoupon.component";
import PointsCouponsList from "./orders/pointsCouponsList.component";
import UpdateProfileData from "./orders/updateProfileData.component";

export default class OrderEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderid : 0,
            headingText: "Checking login ...",
            data: null,
            loading: true,
            orderstatus: 'wc-pending',
            oldorderstatus: 'wc-pending',
            items: null,
            opencheckintimesmodal: false,
            newcheckintime: '',
            newcheckouttime: '',
            updatecheckintimesbuttontext: "Update Check-in/Check-out Time >>",
            disablecheckintimesbutton: false,
            couponToApply: "",
            disableapplycouponbtn: false,
            opencouponmodal: false,
            applycouponbtntext: "Apply Coupon",
            rerenderIssues: false,
            rerenderFollowup: false,
            user: null,
            updateorderstatusbtntext: "Update Status",
            updateorderstatusbtndisabled: false,
            updatedetailsbtntext: "Click to Update Details",
            updatedetailsbtndisabled: false,
            cash_reference: '',
            paymentstatusnote: '',
            transactionref : '',
            voucher_note: '',
            rerenderAllRefunds: false,
            rerenderOrderLogs: false,
            showcancelbutton: true,
            loadPointsCoupons: 0,
            rerenderPointCouponButton: true,
        };
    }

    onChangeRerenderNotes = () => {
        this.setState({
            rerenderOrderLogs: !this.state.rerenderOrderLogs
        });
    }

    componentDidMount() {
        AuthService.checklogin().then(user => {
            this.setState({
                headingText: "Checking permissions ...",
                user: user
            });
            AuthService.getUserPermisions(user)
            .then(response => {
                var userpermissions = response.data;
                // console.log({userpermissions});
                    if(userpermissions.includes('orders') || userpermissions.includes('all')) {
                    // console.log('You are allowed!');
                    this.setState({
                        allowed: true,
                        headingText: "Loading data ..."
                    });
                    if (typeof this.props.params.id == 'undefined') {
                            this.setState({
                                orderid: 0,
                            });
                        } else {
                            this.setState({
                                orderid: this.props.params.id,
                                headingText: "#"+this.props.params.id+" Order Details"
                            });
                            if (this.state.orderid != 0) {
                                this.getData();
                            }
                        }
                } else {
                    // console.log('You are not allowed!');
                    this.setState({
                        allowed: false
                    });
                }
            })
        }).catch(error => {
            window.location.href = '/login';
        });
    }

    onChangeRerender() {
        this.getData();
    }

    onChangeRerenderIssues() {
        this.setState({
            rerenderIssues: !this.state.rerenderIssues,
            rerenderOrderLogs: !this.state.rerenderOrderLogs
        });
    }

    onChangeRerenderFollowup() {
        this.setState({
            rerenderFollowup: !this.state.rerenderFollowup,
            rerenderOrderLogs: !this.state.rerenderOrderLogs
        });
    }

    changedata = (e) => {
        this.getData();
    }

    getData = () => {
        this.setState({
            rerenderAllRefunds: null,
        });
        OrdersReportService.getOrderDetails(this.state.orderid)
        .then(response => {
            // console.log("order details", response);
            this.setState({
                data: response.data,
                loading: false,
                orderstatus: response.data.orderData.status,
                oldorderstatus: response.data.orderData.status,
                cash_reference: response.data.payment.cash_reference,
                paymentstatusnote: response.data.payment.paymentstatusnote,
                transactionref : response.data.payment.transactionref,
                voucher_note: response.data.payment.voucher_note,
                rerenderAllRefunds: true
            });
        })
        .catch(error => console.log({error}));

        OrdersReportService.getOrderDetailsProducts(this.state.orderid)
        .then(response => {
            console.log("order details items", response);
            this.setState({
                items: response.data,
                loading: false,
            });
        })
        .catch(error => console.log({error}));
    }

    handleStatusChange = (e) => {
        this.setState({
            orderstatus: e.target.value,
            rerenderOrderLogs: !this.state.rerenderOrderLogs,
            showcancelbutton: false
        });

        swal('Click on "Update Status" button to confirm the change!');
    }

    onChangeRefundsRerender = () => {
        // console.log("rerender refunds");
        this.setState({
            rerenderAllRefunds: !this.state.rerenderAllRefunds,
            rerenderOrderLogs: !this.state.rerenderOrderLogs
        });
    }

    showcheckintimesmodal = (e, itemID) => {
        this.setState({
            opencheckintimesmodal: true,
            itemtoupdate: itemID
        });
    }

    updateCheckinTimes = (e) => {
        this.setState({
            updatecheckintimesbuttontext: "Updating ....",
            disablecheckintimesbutton: true
        });
        e.preventDefault();
        if(this.state.itemtoupdate!=0) {
            OrdersReportService.updateItemCheckinTimes(this.state.orderid, this.state.itemtoupdate, this.state.newcheckintime, this.state.newcheckouttime, this.state.user.user_nicename)
            .then(response => {
                swal('Updated!', 'Check-in/out times updated!', 'success');
                this.setState({
                    opencheckintimesmodal: false,
                    itemtoupdate: 0,
                    newcheckintime: '',
                    newcheckouttime: '',
                    updatecheckintimesbuttontext: "Update Check-in/Check-out Time >>",
                    disablecheckintimesbutton: false,
                    rerenderOrderLogs: !this.state.rerenderOrderLogs
                })
            })
            .catch(error => console.log({error}));
        }
    } 

    applyCoupon = (e) => {
        e.preventDefault();
        this.setState({
            applycouponbtntext: "Applying Coupon....",
            disableapplycouponbtn: true,
        });

        OrdersReportService.applyCoupon(this.state.orderid, this.state.couponToApply, this.state.user.user_nicename)
        .then(res => {
            // console.log("applyCoupon", res);
            this.setState({
                couponToApply: "",
                disableapplycouponbtn: false,
                opencouponmodal: false,
                applycouponbtntext: "Apply Coupon",
                rerenderOrderLogs: !this.state.rerenderOrderLogs
            });

            if(res.data.includes("Error")) { 
                swal('Error', res.data, 'error');
            } else {
                swal('Discount Applied', "Coupon has been applied to the order!", "success");
                this.getData();
            }
        })
        .catch(error => {console.log({error})});
    }

    removeCoupon = (e, code) => {
        e.preventDefault();
        swal({
        title: "Are you sure?",
        text: "Once removed, The discount will be reomved from the order!",
        icon: "warning",
        buttons: ["No, Keep the coupon!", "Yes, Remove Coupon!"],
        dangerMode: true,
        })
        .then((willDelete) => {
        if (willDelete) {
            e.target.disabled=true;
            e.target.innerText = "Deleting..."
            OrdersReportService.removeCoupon(this.state.orderid, code, this.state.user.user_nicename)
            .then(response => {
                swal('Deleted', 'The coupon "'+code+'" has been removed from the order', 'success');
                this.getData();
                 e.target.disabled=false;
                e.target.innerText = "Remove Coupon";
                this.setState({
                    rerenderOrderLogs: !this.state.rerenderOrderLogs
                });
            })
           .catch(err => console.log({err}));
        } else {
            swal("Coupon Not Removed", "Your coupon is safe!", "success");
        }
        });
    }

    updateOrderStatus = (e) => {
        e.preventDefault();
        this.setState({
            updateorderstatusbtntext: "Updating Status...",
            updateorderstatusbtndisabled: true,
            rerenderPointCouponButton: false,
        });

        OrdersReportService.updateStatus(this.state.orderid, this.state.orderstatus, this.state.user.user_nicename)
        .then(response => {
            this.setState({
                updateorderstatusbtntext: "Update Status",
                updateorderstatusbtndisabled: false,
                oldorderstatus: this.state.orderstatus,
                rerenderOrderLogs: !this.state.rerenderOrderLogs,
                showcancelbutton: true,
                rerenderPointCouponButton: true
            });
            swal("Status Updated!", "The order status has been updated!", "success");
            this.getData();
        })
        .catch(err => console.log({err}));
    }

    updateOrderDetails = (e) => {

        if(e!=null) {
            e.preventDefault();
        }

        this.setState({
            updatedetailsbtntext: "Updating Details...",
            updatedetailsbtndisabled: true,
        });

        OrdersReportService.updateDetails(this.state.orderid, this.state.transactionref, this.state.cash_reference, this.state.paymentstatusnote, this.state.voucher_note, this.state.user.user_nicename)
        .then(response => {
            this.setState({
                updatedetailsbtntext: "Click to Update Details",
                updatedetailsbtndisabled: false,
                rerenderOrderLogs: !this.state.rerenderOrderLogs
            });
            swal("Details Updated!", "The order payment details has been updated!", "success");
        })
        .catch(err => console.log({err}));
    }

    updateOrderDetailsWaitAuto() {

        setTimeout(() => {
            this.updateOrderDetails(null);
        }, 1000);
    }

    enableDisableButtons = (e, index) => {
        var items = this.state.items;
        // console.log({items});
        if(typeof items.day!='undefined' && items.day.length>0) {
            // console.log("item to update", items.day[index]);
            items.day[index].buttons_active = true;
             items.day[index].buttons_active_sup = true;
        }
        this.setState({
            items: items,
            rerenderOrderLogs: !this.state.rerenderOrderLogs
        });
    }

    transferOrder = () => {
        this.getData();
    }

    rerenderCouponsList = (val) => {
        this.setState({
            loadPointsCoupons: 0
        });
        setTimeout(() => {
            this.setState({
                loadPointsCoupons: val
            });
        }, 1000);
    }

    render() {
        var {data, items} = this.state;
        const style = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        };
        return(
            <div style={{backgroundClor: "#fbfbfb"}}>
            <div className="row">
                <div className="col">
                    <h1 className="orders-h1">{this.state.headingText}</h1>
                    <hr />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Paper className="orderdetails-paper" elevation={3}>
                        <h5>Customer Details</h5>
                        {this.state.data!=null && 
                        <div className="orderdetails-paper-content-wraper">
                            <div className="orderdetails-paper-content-row">
                                <label>Booked By ID/Name:</label>
                                <span>{data.user.ID} / {data.userData.first_name} {data.userData.last_name}</span>
                            </div>
                            <div className="orderdetails-paper-content-row">
                                <label>Customer/Order Name:</label>
                                <span>{data.orderData.billing.first_name} {data.orderData.billing.last_name}</span>
                            </div>
                            <div className="orderdetails-paper-content-row">
                                <label>Email:</label>
                                <span>{data.orderData.billing.email}</span>
                            </div>
                            <div className="orderdetails-paper-content-row">
                                <label>Phone:</label>
                                <span>{data.orderData.billing.phone}</span>
                            </div>
                            <div className="orderdetails-paper-content-row">
                                <label>Edit Profile Details:</label>
                                <span><UpdateProfileData orderID={this.state.orderid} phone={data.orderData.billing.phone} email={data.orderData.billing.email} changedata={() => this.changedata()} agent={this.state.user.user_nicename} /></span>
                            </div>
                            <div className="orderdetails-paper-content-row">
                                <label>Account Type:</label>
                                <span>{data.userData.account_type}</span>
                            </div>
                            <div className="orderdetails-paper-content-row">
                                <label>Member Rank:</label>
                                <span>{data.userData.rank}</span>
                            </div>
                            <div className="orderdetails-paper-content-row">
                                <label>Account Create On:</label>
                                <span>{data.userData.registerdate}</span>
                            </div>
                            <div className="orderdetails-paper-content-row">
                                <label>Link to CRM:</label>
                                <span><a className="btn btn-sm btn-link" target="_blank" href={"/user-profile/"+data.orderData.customer_id}>View on CRM</a></span>
                            </div>
                            <div className="orderdetails-paper-content-row">
                                <label>View Order on Old System:</label>
                                <span><a className="btn btn-sm btn-link" target="_blank" href={"https://deals.mu/wp-admin/post.php?post="+this.state.orderid+"&action=edit"}>View Order</a></span>
                            </div>
                            <div className="orderdetails-paper-content-row">
                                <label>Transfer Order to Customer:</label>
                                <span><TransferPoints orderid={this.state.orderid} userid={data.user.ID} customerid={data.orderData.customer_id} ownerName={data.userData.first_name+" "+data.userData.last_name} user={this.state.user} onChange={() => this.transferOrder()} /></span>
                            </div>
                        </div>
                        }
                        {this.state.data==null && 
                            <>
                            <Typography variant="h3"><Skeleton /></Typography>
                            <Typography variant="h3"><Skeleton /></Typography>
                            <Typography variant="h3"><Skeleton /></Typography>
                            <Typography variant="h3"><Skeleton /></Typography>
                            <Typography variant="h3"><Skeleton /></Typography>
                            <Typography variant="h3"><Skeleton /></Typography>
                            </>
                        }
                    </Paper>
                </div>
                <div className="col">
                    <Paper className="orderdetails-paper" elevation={3}>
                        <h5>Order Summary</h5>
                        {this.state.data!=null && 
                        <div className="orderdetails-paper-content-wraper">
                             <div className="orderdetails-paper-content-row">
                                <label>Order Date:</label>
                                <span>
                                    {this.state.data.orderData.order_date}
                                </span>
                            </div>
                            <div className="orderdetails-paper-content-row">
                                <label>Order Status:</label>
                                <span>
                                    <select className="form-control form-control-select" name='orderstatus' value={this.state.orderstatus} onChange={e=>this.handleStatusChange(e)}>
                                        <option value={'pending'}>Pending Payment</option>
                                        <option value={'processing'}>Processing</option>
                                        <option value={'cancelled'}>Cancelled</option>
                                        <option value={'on-hold'}>On Hold</option>
                                        <option value={'completed'}>Completed</option>
                                        <option value={'refunded'}>Refunded</option>
                                        <option value={'failed'}>Failed</option>
                                    </select>
                                </span>
                            </div>

                            <div className="orderdetails-paper-content-row">
                                <label>Update Status:</label>
                                <span>
                                    <button type="button" className="btn btn-sm btn-success" onClick={e => this.updateOrderStatus(e)} disabled={this.state.updateorderstatusbtndisabled || this.state.orderstatus==this.state.oldorderstatus}>{this.state.updateorderstatusbtntext}</button>
                                </span>
                            </div>

                            {this.state.orderstatus=='cancelled' && this.state.showcancelbutton &&
                            <div className="orderdetails-paper-content-row">
                                <label>Send Cancellation Email:</label>
                                <span>
                                    <a className="btn btn-sm btn-danger" target="_blank" href={`https://deals.mu/scripts/cancelled_order_notify.php?order_id=${this.state.orderid}`} rel="noreferrer">Send Cancellation Email</a>
                                </span>
                            </div>
                            }
                            {data.coupons.length>0 && data.coupons.map((coupon, index) => ( 
                            <div className="orderdetails-coupons-loop" key={index}>
                                <div className="orderdetails-paper-content-row">
                                    <label>Coupon Code:</label>
                                    <span><b>{coupon.code}</b></span>
                                    <ReactTooltip id={"couponTip"+index} place="top" effect="solid">
                                        {coupon.desc+" - Rs"+coupon.amount}
                                    </ReactTooltip>
                                </div>
                                <div className="orderdetails-paper-content-row">
                                    <label>Coupon Type:</label>
                                    <span>{coupon.type}</span>
                                </div>
                                <div className="orderdetails-paper-content-row">
                                    <label>Coupon Discount:</label>
                                    <span>Rs {coupon.amount}</span>
                                </div>
                                <div className="orderdetails-paper-content-row">
                                    <label>Action:</label>
                                    <span><button type="button" className="btn btn-sm btn-danger" onClick={e => this.removeCoupon(e, coupon.code)}>Remove Coupon</button></span>
                                </div>
                            </div>
                            ))}
                            <div className="orderdetails-paper-content-row">
                                <label>Other Orders by This Customer:</label>
                                <span><a href={"/orders/"+this.state.data.orderData.customer_id} target="_blank" className="btn btn-sm btn-link">View Orders</a></span>
                            </div>
                        </div>
                        }
                        {this.state.data==null && 
                            <>
                            <Typography variant="h3"><Skeleton /></Typography>
                            <Typography variant="h3"><Skeleton /></Typography>
                            <Typography variant="h3"><Skeleton /></Typography>
                            <Typography variant="h3"><Skeleton /></Typography>
                            <Typography variant="h3"><Skeleton /></Typography>
                            <Typography variant="h3"><Skeleton /></Typography>
                            </>
                        }
                    </Paper>
                </div>
                <div className="col">
                    <Paper className="orderdetails-paper" elevation={3}>
                        <h5>Payment References</h5>
                        {this.state.data!=null && 
                        <div className="orderdetails-paper-content-wraper">
                            <div className="orderdetails-paper-content-row">
                                <label>Payment Method:</label>
                                <span>{data.payment.paymentmethod}</span>
                            </div>
                            <div className="orderdetails-paper-content-row">
                                <label>Payment Date:</label>
                                <span>{data.payment.paid_date}</span>
                            </div>
                            <div className="orderdetails-paper-content-row">
                                <label>Transaction Referrence:</label>
                                <span><input className="form-control" name="transactionref" value={this.state.transactionref} onChange={e => {this.setState({transactionref: e.target.value});}} /></span>
                            </div>
                            <div className="orderdetails-paper-content-row">
                                <label>Cash Reference:</label>
                                <span><input className="form-control" name="cash_reference" value={this.state.cash_reference} onChange={e => {this.setState({cash_reference: e.target.value});}} /></span>
                            </div>
                            <div className="orderdetails-paper-content-row" style={{flexDirection: "column", alignItems: "flex-start"}}>
                                <label>Payment Status Note:</label>
                                <textarea className="form-control" name="paymentstatusnote" rows="3" value={this.state.paymentstatusnote} style={{width:"100%"}}  onChange={e => {this.setState({paymentstatusnote: e.target.value});}}></textarea>
                            </div>
                            <div className="orderdetails-paper-content-row" style={{flexDirection: "column", alignItems: "flex-start"}}>
                                <label>Voucher Note:</label>
                                <textarea className="form-control" name="voucher_note" rows="3" value={this.state.voucher_note} style={{width:"100%"}}  onChange={e => {this.setState({voucher_note: e.target.value});}}></textarea>
                            </div>
                            <div className="orderdetails-paper-content-row" style={{flexDirection: "row", alignItems: "center", justifyContent: "space-between", backgroundColor: "cornsilk", padding: "5px", fontSize: "14px"}}>
                                <label>Update Details:</label>
                                {/* <span>{this.state.updatedetailsbtntext}</span> */}
                                <button type="button" className="btn btn-sm btn-success" disabled={this.state.updatedetailsbtndisabled} onClick={e => this.updateOrderDetails(e)}>{this.state.updatedetailsbtntext}</button>
                            </div>
                        </div>
                        }
                        {this.state.data==null && 
                            <>
                            <Typography variant="h3"><Skeleton /></Typography>
                            <Typography variant="h3"><Skeleton /></Typography>
                            <Typography variant="h3"><Skeleton /></Typography>
                            <Typography variant="h3"><Skeleton /></Typography>
                            <Typography variant="h3"><Skeleton /></Typography>
                            <Typography variant="h3"><Skeleton /></Typography>
                            </>
                        }
                    </Paper>
                </div>
            </div>
            {items!=null && items.overnight.length>0 && 
            <div className="row orderdetails-items-table-wrapper">
                <div className="col">
                    <table className="table table-striped table-bordered items-table">
                        <thead className="thead-dark">
                            <tr>
                            <th scope="col">Product Name</th>
                            <th scope="col">Booking Date/s</th>
                            <th scope="col">Nights Duration</th>
                            <th scope="col">Number of Persons</th>
                            <th scope="col">Room Type</th>
                            <th scope="col">Meal Plan</th>
                            <th scope="col">Guest Name</th>
                            <th scope="col">Special Request</th>
                            <th scope="col" style={{width: "12%"}}>Prices</th>
                            <th scope="col">Voucher Action</th>
                            <th scope="col" style={{width: "12%"}}>Voucher Status</th>
                            <th scope="col">Supplier Confirmed</th>
                            <th scope="col">Supplier Payment Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items!=null && items.overnight.length>0 && items.overnight.map((item, index) => ( 
                            <tr key={index}>
                            <th scope="row">
                                <h6>{item.product_name}</h6>
                                {item.product_categories.length>0 && item.product_categories.map((cat, catindex) => (
                                    <Chip label={cat} color="warning" key={catindex} style={{marginBottom: "5px"}} />
                                ))}
                                {item.pernightprice!="" && 
                                    <div style={{fontWeight: "500"}}>
                                        <label>Hotel Booking / Night</label>
                                        <p>{item.pernightprice}</p>
                                    </div>
                                }
                            </th>
                            <td>
                                <p><b>Checkin: </b> {item.start_date}</p>
                                <p><b>Checkout: </b> {item.end_date}</p>
                                <p><Chip label={item.item_status} /></p>
                            </td>
                            <td>{item.duration}</td>
                            <td>{item.persons}</td>
                            <td>{item.room_type}</td>
                            <td>{item.meal_plan}</td>
                            <td>{item.guest_name}</td>
                            <td>{item.special_request}</td>
                            <td>
                                <p style={{display: "flex", justifyContent: "space-between"}}><b>Selling Price: </b><span>Rs {item.item_Line_subtotal}</span></p>
                                {item.item_discount_raw>0 && 
                                    <p style={{color: "red", display: "flex", justifyContent: "space-between", borderBottom: "1px solid black"}}><b>Discount: </b><span>Rs {item.item_discount}</span></p>
                                }
                                <p style={{display: "flex", justifyContent: "space-between"}}><b>Total: </b><span>Rs {item.item_Line_total}</span></p>
                            </td>
                            <td className="table-actions-button">
                                <EditOrderItemModal key={item.item_id} orderid={this.state.orderid} itemID={item.item_id} changedata={() => this.changedata()} />
                                {/* <a className="btn btn-sm btn-success" target="_blank" href={"https://deals.mu/phantom/gen.php?ajax=1&order_id="+this.state.orderid+"&item="+item.item_id} rel="noreferrer">Generate Voucher</a> */}
                                {this.state.orderstatus== "processing" && this.state.oldorderstatus== "processing" && 
                                    <a className="btn btn-sm btn-primary" target="_blank" href={"https://deals.mu/phantom/gen.php?ajax=1&order_id="+this.state.orderid+"&item="+item.item_id} rel="noreferrer">Download Voucher</a>
                                }
                                <button className="btn btn-sm btn-warning" onClick={(e) => this.showcheckintimesmodal(e, item.item_id)}>Check-in/out Times</button>
                            </td>
                            <td className="table-actions-button">
                                {this.state.orderstatus== "processing" && this.state.oldorderstatus== "processing" && 
                                <>
                                    <SendVoucherToSupplier orderid={this.state.orderid} item={item} user={this.state.user} travelpackage={item.travel_package_nights} itemid={item.item_id} voucher_email_sent={item.voucher_email_sent} multiplesuppliers={item.multiplesuppliers} btnActive={true} />
                                    <hr />
                                    <SendVoucherToCustomer orderid={this.state.orderid} user={this.state.user} itemid={item.item_id} voucher_email_sent_to_cus={item.voucher_email_sent_to_cus} btnActive={true} />
                                </>
                                }
                            </td>
                            <td>
                                {this.state.orderstatus== "processing" && this.state.oldorderstatus== "processing" && 
                                <SupplierConfirmed orderid={this.state.orderid} itemid={item.item_id} user={this.state.user} travelpackage={item.travel_package_nights} multiplesuppliers={item.multiplesuppliers} supplier_confirmed={item.supplier_confirmed} confirmed_by={item.confirmed_by} confirmation_comments={item.confirmation_comments}  supplier_confirmed_time={item.supplier_confirmed_time} total_suppliers={item.totalSuppliers} />
                                 }
                            </td>
                            <td>
                                {item.invoice_number!=false && (item.payment_status=='Payable' || item.payment_status=='Partially Paid') && 
                                <div className="orderitem-payment-details">
                                    <Chip label={"Paid"} color="warning" />
                                    <p className="first" style={{display: "flex", justifyContent: "space-between", flexDirection: "column"}}><b>Payable: </b><span>Rs {item.payable}</span></p>
                                    <p style={{display: "flex", justifyContent: "space-between", flexDirection: "column"}}><b>Claim: </b><span>Rs {item.supplier_claims}</span></p>
                                    <p style={{display: "flex", justifyContent: "space-between", flexDirection: "column"}}><b>Invoice #: </b><span>{item.invoice_number}</span></p>
                                </div>
                                }
                                {item.invoice_number==false && 
                                    <Chip label={"Unpaid"} /> 
                                }
                            </td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            }

            {items!=null && items.day.length>0 && 
            <div className="row orderdetails-items-table-wrapper">
                <div className="col">
                    <table className="table table-striped table-bordered items-table">
                        <thead className="thead-dark">
                            <tr>
                            <th scope="col">Product Name</th>
                            <th scope="col">Booking Date/s</th>
                            <th scope="col">Voucher Type</th>
                            <th scope="col">Booking Specification</th>
                            <th scope="col">Guest Name</th>
                            <th scope="col">Special Request</th>
                            <th scope="col" style={{width: "12%"}}>Prices</th>
                            <th scope="col">Update Voucher Details</th>
                            <th scope="col">Voucher Actions</th>
                            <th scope="col" style={{width: "12%"}}>Voucher Status</th>
                            <th scope="col">Supplier Confirmed</th>
                            <th scope="col">Supplier Payment Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items!=null && items.day.length>0 && items.day.map((item2, index) => (
                            <tr key={index}>
                            <th scope="row">
                                <h6>{item2.product_name}</h6>
                                {item2.product_categories.length>0 && item2.product_categories.map((cat, catindex) => (
                                    <Chip label={cat} color="warning" key={catindex} style={{marginBottom: "5px"}} />
                                ))}
                            </th>
                            <td>
                                {item2.open_voucher==true && 
                                    <Chip label={"Open Voucher"} color="primary" />
                                }
                                {item2.open_voucher==false && 
                                    <>
                                        <p>{item2.start_date}</p>
                                        <p><Chip label={item2.item_status} /></p>
                                    </>
                                }
                            </td>
                            <td>
                                {item2.open_voucher==true && 
                                    <>
                                    <Chip label={"Open Voucher"} color="primary" />
                                    <p>Expiration: {item2.open_voucher_expiration}</p>
                                    </>
                                }
                                {item2.open_voucher==false && 
                                    <Chip label={"Specific Date"} color="secondary" />
                                }
                            </td>
                            <td>
                                {item2.bookings.length>0 && item2.bookings.map((attr, attrindex) => (
                                    <span key={attrindex}>
                                    <p><b>{attr.key}:</b></p>
                                    <p>{attr.value}</p>
                                    </span>
                                ))}
                            </td>
                            <td>{item2.guest_name}</td>
                            <td>{item2.special_request}</td>
                            <td>
                                <p style={{display: "flex", justifyContent: "space-between"}}><b>Selling Price: </b><span>Rs {item2.item_Line_subtotal}</span></p>
                                {item2.item_discount_raw>0 && 
                                    <p style={{color: "red", display: "flex", justifyContent: "space-between", borderBottom: "1px solid black"}}><b>Discount: </b><span>Rs {item2.item_discount}</span></p>
                                }
                                <p style={{display: "flex", justifyContent: "space-between"}}><b>Total: </b><span>Rs {item2.item_Line_total}</span></p>
                            </td>
                            <td>
                                <UpdateVoucherDetails orderid={this.state.orderid} user={this.state.user} itemid={item2.item_id} itemIndex={index} time={item2.activity_time} location={item2.location_details} contacts={item2.contacts_details} onChange={e => {this.enableDisableButtons(e, index)}} />
                            </td>
                            <td className="table-actions-button">
                                <EditOrderItemModal key={item2.item_id} orderid={this.state.orderid} itemID={item2.item_id} changedata={() => this.changedata()} />
                                {/* {item2.buttons_active==false && item2.open_voucher==false && 
                                    <button className="btn btn-sm btn-success" disabled={true}>Generate Voucher</button>
                                } */}
                                {/* {(item2.buttons_active==true || item2.open_voucher==true) && 
                                    <a className="btn btn-sm btn-success" target="_blank" href={(!item2.buttons_active && item2.open_voucher==false) ? "#":"https://deals.mu/phantom/gen.php?ajax=1&order_id="+this.state.orderid+"&item="+item2.item_id}>Generate Voucher</a>
                                } */}

                                {item2.buttons_active==false && item2.open_voucher==false && this.state.orderstatus== "processing" && this.state.oldorderstatus== "processing" &&  
                                    <button className="btn btn-sm btn-primary" disabled={true}>Download Voucher</button>
                                }
                                {(item2.buttons_active==true || item2.open_voucher==true) && this.state.orderstatus== "processing" && this.state.oldorderstatus== "processing" &&  
                                    <a className="btn btn-sm btn-primary" target="_blank" href={(!item2.buttons_active && item2.open_voucher==false) ? "#":"https://deals.mu/phantom/gen.php?ajax=1&order_id="+this.state.orderid+"&item="+item2.item_id} rel="noreferrer">Download Voucher</a>
                                }
                                <button className="btn btn-sm btn-warning" disabled={!item2.buttons_active} onClick={(e) => this.showcheckintimesmodal(e, item2.item_id)}>Package Times</button>
                            </td>
                            <td className="table-actions-button">
                                {this.state.orderstatus== "processing" && this.state.oldorderstatus== "processing" && 
                                <>
                                    <SendVoucherToSupplier orderid={this.state.orderid} item={item2} itemid={item2.item_id} user={this.state.user} voucher_email_sent={item2.voucher_email_sent} multiplesuppliers={item2.multiplesuppliers} btnActive={item2.buttons_active_sup} />
                                    <hr />
                                    <SendVoucherToCustomer orderid={this.state.orderid} itemid={item2.item_id} user={this.state.user} voucher_email_sent_to_cus={item2.voucher_email_sent_to_cus} btnActive={item2.buttons_active} />
                                </>
                                }
                            </td>
                            <td>
                                {this.state.orderstatus== "processing" && this.state.oldorderstatus== "processing" && 
                                    <SupplierConfirmed orderid={this.state.orderid} itemid={item2.item_id} user={this.state.user} travelpackage={item2.travel_package_nights} multiplesuppliers={item2.multiplesuppliers} supplier_confirmed={item2.supplier_confirmed} confirmed_by={item2.confirmed_by} confirmation_comments={item2.confirmation_comments} supplier_confirmed_time={item2.supplier_confirmed_time} total_suppliers={item2.totalSuppliers} />
                                }
                            </td>
                            <td>
                                {item2.invoice_number!=false && (item2.payment_status=='Payable' || item2.payment_status=='Partially Paid') && 
                                <div className="orderitem-payment-details">
                                    <Chip label={"Paid"} color="warning" />
                                    <p className="first" style={{display: "flex", justifyContent: "space-between", flexDirection: "column"}}><b>Payable: </b><span>Rs {item2.payable}</span></p>
                                    <p style={{display: "flex", justifyContent: "space-between", flexDirection: "column"}}><b>Claim: </b><span>Rs {item2.supplier_claims}</span></p>
                                    <p style={{display: "flex", justifyContent: "space-between", flexDirection: "column"}}><b>Invoice #: </b><span>{item2.invoice_number}</span></p>
                                </div>
                                }
                                {item2.invoice_number==false && 
                                    <Chip label={"Unpaid"} /> 
                                }
                            </td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            }

            {items!=null && items.other.length>0 && 
                <div className="row orderdetails-items-table-wrapper">
                    <div className="col">
                        <table className="table table-striped table-bordered items-table">
                            <thead className="thead-dark">
                                <tr>
                                <th scope="col">Product Name</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Sender</th>
                                <th scope="col">Recipient</th>
                                <th scope="col">Message</th>
                                <th scope="col">Delivery date</th>
                                <th scope="col">Special Requests</th>
                                <th>Voucher</th>
                                <th>Send Voucher</th>
                                <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.other.map((item, index) => (
                                <tr key={index}>
                                    <th scope="row">
                                        <h6>{item.product_name}</h6>
                                        {/* {item.product_categories.length>0 && item.product_categories.map((cat, catindex) => (
                                            <Chip label={cat} color="warning" key={catindex} style={{marginBottom: "5px"}} />
                                        ))} */}
                                    </th>
                                    <td>
                                        <p style={{display: "flex", justifyContent: "space-between"}}><b>Selling Price: </b><span>Rs {item.item_Line_subtotal}</span></p>
                                        {item.item_discount_raw>0 && 
                                            <p style={{color: "red", display: "flex", justifyContent: "space-between", borderBottom: "1px solid black"}}><b>Discount: </b><span>Rs {item.item_discount}</span></p>
                                        }
                                        <p style={{display: "flex", justifyContent: "space-between"}}><b>Total: </b><span>Rs {item.item_Line_total}</span></p>
                                    </td>
                                    <td>
                                        <b>{item._ywgc_sender_name}</b>
                                    </td>
                                    <td>
                                        <>
                                        <p><b>{item._ywgc_recipient_name}</b></p>
                                        <p>{item._ywgc_recipients[0]}</p>
                                        </>
                                    </td>
                                    <td>
                                        <b>{item._ywgc_message}</b>
                                    </td>
                                    <td>
                                        <b>{item._ywgc_delivery_date}</b>
                                    </td>
                                    <td>
                                        <b>{item.special_request}</b>
                                    </td>
                                    <td>
                                        {this.state.orderstatus== "processing" && this.state.oldorderstatus== "processing" && 
                                            <a className="btn btn-sm btn-primary" target="_blank" href={(!item.buttons_active && item.open_voucher==false) ? "#":"https://deals.mu/phantom/gen.php?ajax=1&order_id="+this.state.orderid+"&item="+item.item_id} rel="noreferrer">Download Voucher</a>
                                        }
                                    </td>
                                    <td>
                                        {this.state.orderstatus== "processing" && this.state.oldorderstatus== "processing" && 
                                        <>
                                            <SendVoucherToCustomer orderid={this.state.orderid} itemid={item.item_id} user={this.state.user} voucher_email_sent_to_cus={item.voucher_email_sent_to_cus} btnActive={true} />
                                        </>
                                        }
                                    </td>
                                    <td>
                                        <EditOrderItemModal key={item.item_id} orderid={this.state.orderid} itemID={item.item_id} changedata={() => this.changedata()} />
                                    </td>
                                </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            
            }
            
            {this.state.data!=null && 
            <div className="row order-totals-bar">
                
                <div className={"col-10 actions"} style={{display: "flex", flexDirection:"column", alignItems:"flex-start"}}>
                    <AddFee orderID={this.state.orderid} changedata={() => this.changedata()} />
                    <div style={{display: "flex", flexDirection:"row"}}>
                    <button type="button" onClick={e=>this.setState({opencouponmodal: true})} className="btn">Apply Coupon</button>
                    {/* <button type="button" className="btn">Refund / Credit</button> */}
                    <AddRefund orderid={this.state.orderid} paymentMethod={this.state.data.payment.paymentMethodRaw} items={this.state.data.items} user={this.state.user} onChange={e => {this.getData();}} />
                    <AddComplaint orderid={this.state.orderid} items={this.state.data.items} user={this.state.user} onChange={e => this.onChangeRerenderIssues()} />
                    {this.state.rerenderPointCouponButton==true && 
                        <OrderPointsCoupon orderID={this.state.orderid} agent={this.state.user.user_nicename} rerender={this.state.rerenderPointCouponButton} orderStatus={this.state.orderstatus} email={data.orderData.billing.email} onAddCoupon={(value) => this.rerenderCouponsList(value)} />
                    }
                    </div>
                </div>
                <div className="col-2 totals">
                    <p><b>Sub-Total: </b><b>Rs {data.payment.subtotal}</b></p>
                    <p style={{color: "blue", borderBottom: "1px solid black"}}><b>Total Fee: </b><b>Rs {data.payment.fee_total}</b></p>
                    <p style={{color: "red", borderBottom: "1px solid black"}}><b>Total Discount: </b><b>Rs {data.payment.discount_total}</b></p>
                    {data.payment.refund_total!='' &&
                    <>
                        <p><b>Paid by Customer: </b><b>Rs {data.payment.total}</b></p>
                        <p style={{color: "red", borderBottom: "1px solid black"}}><b>Total Refund: </b><b>-Rs {data.payment.refund_total}</b></p>
                        <p><b>Net Total: </b><b>Rs {data.payment.total_after_refund}</b></p>
                    </>
                    }
                    {data.payment.refund_total=='' && 
                        <p><b>Total: </b><b>Rs {data.payment.total}</b></p>
                    }
                </div>
            </div>
            }
            {this.state.orderid>0 && 
                <PointsCouponsList orderID={this.state.orderid} reRender={this.state.loadPointsCoupons} />
            }
            {this.state.data!=null && this.state.rerenderAllRefunds!=null &&
                <AllRefunds orderid={this.state.orderid} user={this.state.user} email={data.orderData.billing.email} onChange={e => {this.getData();}} />
            }
            {this.state.data!=null && 
            <div className="row">
                <div className="col">
                    <AllComplaints orderid={this.state.orderid} onChange={e => this.onChangeRerender()} rerender={this.state.rerenderIssues} />
                    <OrderLogs orderid={this.state.orderid} render={this.state.rerenderOrderLogs} />
                </div>
                <div className="col">
                    <OrderNotes orderid={this.state.orderid} user={this.state.user} onAddNote={e => this.onChangeRerenderNotes()} />
                </div>
            </div>
            }
            {this.state.data!=null && 
            <>
                <AddReminder orderid={this.state.orderid} username={this.state.user.user_nicename} onChange={(e) => {this.setState({rerenderOrderLogs: !this.state.rerenderOrderLogs})}} />
            </>
            }

            {this.state.data!=null && 
            <div className="row">
                <div className="col">
                    <Emails orderid={this.state.orderid} email={this.state.data.orderData.billing.email} />
                </div>
            </div>
            }

            <Modal
                open={this.state.opencheckintimesmodal}
                onClose={e => {this.setState({opencheckintimesmodal: false, itemtoupdate: 0})}}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{...style, width: 600, textAlign: 'center' }}>
                <h2 id="child-modal-title">Check-in/Check-out Time Override</h2>
                <p id="child-modal-description" style={{marginBottom: "0px"}}>Enter the Check-in/Check-out time to display on this voucher.</p>
                <p id="child-modal-description">(Leave empty to use default from Product)</p>
                <form onSubmit={(e) => this.updateCheckinTimes(e)} style={{textAlign: "left"}}>
                     <div className="form-group row">
                        <label for="newcheckintime" className="col-sm-8 col-form-label">Check-in Time</label>
                        <div className="col-sm-4">
                            <input type="text" className="form-control" onChange={e => {this.setState({newcheckintime: e.target.value})}} id="newcheckintime" placeholder="13h00" value={this.state.newcheckintime} />
                        </div>
                    </div>
                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="newcheckouttime" className="col-sm-8 col-form-label">Check-Out Time</label>
                        <div className="col-sm-4"> 
                            <input type="text" className="form-control" id="newcheckouttime" placeholder="16h00" onChange={e => {this.setState({newcheckouttime: e.target.value})}} value={this.state.newcheckouttime} />
                        </div>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <button type="submit" className="btn btn-sm btn-success" style={{marginRight: "20px"}} disabled={this.state.disablecheckintimesbutton}>{this.state.updatecheckintimesbuttontext}</button><button type="button" className="btn btn-sm btn-danger" onClick={e => {this.setState({opencheckintimesmodal: false, itemtoupdate: 0})}}>Close</button>
                    </div>
                </form>
                </Box>
            </Modal>

            <Modal
                open={this.state.opencouponmodal}
                onClose={e => {this.setState({opencouponmodal: false, couponToApply: ""})}}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{...style, width: 800, textAlign: 'center' }}>
                <h2 id="child-modal-title">Apply Discount Coupon to this Order</h2>
                <p id="child-modal-description" style={{marginBottom: "0px"}}>Enter the coupon code below.</p>
                <form onSubmit={(e) => this.applyCoupon(e)} style={{textAlign: "left"}}>
                     <div className="form-group">
                        <label for="couponToApply" className="col-form-label"><b>Coupon Code</b></label>
                        <div className="">
                            <input type="text" className="form-control" onChange={e => {this.setState({couponToApply: e.target.value})}} id="couponToApply" placeholder="Enter coupon code" value={this.state.couponToApply} />
                        </div>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <button type="submit" className="btn btn-sm btn-success" style={{marginRight: "20px"}} disabled={this.state.disableapplycouponbtn}>{this.state.applycouponbtntext}</button><button type="button" className="btn btn-sm btn-danger" onClick={e => {this.setState({opencouponmodal: false, couponToApply: ""})}}>Close</button>
                    </div>
                </form>
                </Box>
            </Modal>
            </div>
        );
    }
}