import React, { useEffect, useState } from 'react'
import { Modal, Box } from '@mui/material';
import axios from 'axios';
import swal from 'sweetalert';

const APP_URL = "https://deals.mu/scripts/reportsapi/ordersreport.php";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const OrderPointsCoupon = ({orderID, agent, orderStatus, email, onAddCoupon, rerender}) => {

    const [canCreateCoupon, setCanCreateCoupon] = useState(false);
    const [open, setOpen] = useState(false);
    const [whymanualpoints, setWhymanualpoints] = useState("");
    const [customeremail, setCustomeremail] = useState(email);
    const [pointsamount, setPointsamount] = useState(0);
    const [whywrongpoints, setWhywrongpoints] = useState("");
    const [disableapplybtn, setDisableapplybtn] = useState(true);
    const [applybtntext, setApplybtntext] = useState("Add Points Coupon");
    const [orderStatusState, setOrderStatusState] = useState(orderStatus);
    const [orderIDState, setOrderIDState] = useState(orderID);
    const [rerenderState, setRerenderState] = useState(rerender);

    useEffect(() => {
        if(canCreateCoupon == true) {
            setDisableapplybtn(false);
        } else {
            setDisableapplybtn(true);
        }
    }, [canCreateCoupon, rerenderState]);

    const getCanCreateCoupon = () => {
        var params = {
            orderID: orderID,
            action: "canCreatePointsCoupon"
        };
        axios.post(APP_URL, {params})
        .then(response => {
            // console.log("get fees response", response);
            if(response.data.allowed == true) {
                if(orderStatus == "completed" || orderStatus== "processing") {
                    setCanCreateCoupon(response.data.allowed);
                    setPointsamount(response.data.pointsamount);
                }
            }
        })
        .catch(error => {
            console.log("get fees error", error);
        });
    }

    useEffect(() => {
        getCanCreateCoupon();
    }, [orderIDState, orderStatusState, rerenderState]);

    useEffect(() => {
        if(whymanualpoints != "" && customeremail != "" && pointsamount != 0 && agent != "") {
            setApplybtntext("Add Points Coupon");
            setDisableapplybtn(false);
        } else {
            setApplybtntext("Add Points Coupon");
            setDisableapplybtn(true);
        }
        
    }, [whymanualpoints, customeremail, pointsamount, agent]);

    const addCoupon = (e) => {
        // onAddCoupon();
        e.preventDefault();
        setApplybtntext("Adding...");
        setDisableapplybtn(true);

        var params = {
            order_id: orderID,
            action: "add_points_coupon",
            summary: whymanualpoints,
            amount: pointsamount,
            pointsamount: pointsamount,
            amount_wrong: whywrongpoints,
            agent: agent,
            email: customeremail
        };
        axios.post(APP_URL, {params})
        .then(response => {
            console.log("add coupon response", response);
            // if(response.data!=null) {
                swal("Success", "Points coupon request submitted successfully", "success");
                setOpen(false);
                setCanCreateCoupon(false);
                onAddCoupon(Math.random());
            // } else {
            //     swal("Error", response.data.message, "error");
            // }
            setApplybtntext("Add Points Coupon");
            setDisableapplybtn(false);
        })
    }

  return (
    <div>
        {canCreateCoupon == true && 
        <>
        <button type="button" onClick={e=>setOpen(true)} style={{marginLeft: "15px"}} className="btn">Points Coupon</button>
        <Modal
            open={open}
            onClose={e => {setOpen(false);}}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
        >
            <Box sx={{...style, width: 800, textAlign: 'center' }}>
            <h2 id="child-modal-title">Add Fee to this Order</h2>
            <p id="child-modal-description" style={{marginBottom: "0px"}}>Enter the fee below.</p>
            <form onSubmit={(e) => addCoupon(e)} style={{textAlign: "left"}}>
                <div className="form-group">
                    <label for="handlingagent" className="col-form-label"><b><span style={{color: "red"}}>*</span> Handling Agent:</b></label>
                    <div className="">
                        <input type="text" className="form-control" disabled={true} id="handlingagent" value={agent} required="required" />
                    </div>
                </div>
                <div className="form-group">
                    <label for="whymanualpoints" className="col-form-label"><b><span style={{color: "red"}}>*</span> Explanation why Manual Input of Points:</b></label>
                    <div className="">
                       <textarea className="form-control" id="whymanualpoints" value={whymanualpoints} onChange={e=>setWhymanualpoints(e.target.value)} required="required" />
                    </div>
                </div>
                <div className="form-group">
                    <label for="customeremail" className="col-form-label"><b><span style={{color: "red"}}>*</span> Customer Email</b></label>
                    <div className="">
                        <input type="text" className="form-control" id="customeremail" value={customeremail} onChange={e=>setCustomeremail(e.target.value)} required="required" />
                    </div>
                </div>
                <div className="form-group">
                    <label for="pointsamount" className="col-form-label"><b><span style={{color: "red"}}>*</span> Points Amount</b></label>
                    <div className="">
                        <input type="text" className="form-control" id="pointsamount" disabled={true} value={pointsamount} onChange={e=>setPointsamount(e.target.value)} required="required" />
                    </div>
                </div>
                <div className="form-group">
                    <label for="whywrongpoints" className="col-form-label"><b>If points amount wrong please explain:</b></label>
                    <div className="">
                       <textarea className="form-control" id="whywrongpoints" value={whywrongpoints} onChange={e=>setWhywrongpoints(e.target.value)} />
                    </div>
                </div>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <button type="submit" className="btn btn-sm btn-success" style={{marginRight: "20px"}} disabled={disableapplybtn}>{applybtntext}</button><button type="button" className="btn btn-sm btn-danger" onClick={e => {setOpen(false);}}>Close</button>
                </div>
            </form>
            </Box>
        </Modal>
        </>
        }
    </div>
  )
}

export default OrderPointsCoupon;