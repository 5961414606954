import axios from "axios";
const API_URL = 'https://deals.mu/scripts/reportsapi/crm/user_reports.php';
class UserReportsService {
    searchCustomersReport(search) {
        var params = {
            action: 'search_report_customers_total',
            ...search
        };
        return axios
            .post(API_URL + '?rand='+Math.random(), { params })
            .then(async (response) => {
                console.log({ response });
                var result = {
                    data: []
                };
                let promises = [];
                var size = response.data;
                // console.log({size});
                var loopSize = Math.ceil(parseFloat(size / 30));
                console.log({loopSize});

                for (var i = 1; i <= loopSize; i++) {
                    // console.log({i});
                    promises.push(
                        await this.getUsers(search, i)
                            .then(response1 => {
                                // console.log('response: '+i, response1);
                                result.data = result.data.concat(response1.data);
                            })
                            .catch(error1 => {
                                console.log({ error1 });
                            })
                    );
                }

                return Promise.all(promises).then(() => {
                    console.log({result});
                    return result;
                });

                // console.log(response);
                // return response;
                // return result;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

    getUsers(search, page) {
        var params = {
            action: 'search_report_customers',
            page: page,
            ...search
        };
        return axios
            .post(API_URL + '?rand=' + Math.random(), { params })
            .then((response) => {
                console.log({page});
                console.log('searchCustomersReport', response.data);
                return response;
            }).catch(error => {
                return false;
            });
    }
}

export default new UserReportsService();