import React, { Component } from "react";
import DatePicker from "react-datepicker";
import Loader from 'react-loader-spinner';
import DTable from './dtable.component';
import Select from 'react-select';

import "react-datepicker/dist/react-datepicker.css";

import AuthService from '../services/auth/auth.service';
import RefundReportsService from '../services/erp/refunds.service';
import PaymentReportsService from '../services/erp/paymentreports.service';
import UtilityService from '../services/utility.service';

import UserContext from "../contexts/UserContext";
import NoPermission from "./extras/nopermission.component";
export default class RefundsReport extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            columnsheadings: [],
            options: {
                filterType: 'dropdown',
            },
            show: false,
            error: {
                status: false,
                message: ''
            },
            loadingvalues: true,
            products: [{ ID: 0, post_title: 'Select a Product' }],
            suppliers: [{value:0, label:'Select a Supplier'}],
            companies: [{value:0, label:'Select a Company'}],
            startdate: '',
            suppliername: { value: 0, label: 'Select a Supplier' },
            enddate: '',
            productname: { value: 0, label: 'Select a Product' },
            companyname: { value: 0, label: 'Select a Company' },
            minDate: new Date("02-01-2019"),
            search_btn_text: 'Search',
            loader: false,
            refundstatus: 0,
            user: [],
            allowed: true
        };
    }

    // supChange = (selectedOption) => {
    //     this.setState({ suppliername: selectedOption });
    //     // console.log(`Option selected:`, selectedOption);
    // }

    setStartDate = (date) => {
        this.setState({
            startdate: date,
            minDate: date,
        });

        if (this.state.enddate != '' && this.state.enddate < date) {
            this.setState({
                enddate:date
            });
        }
        // console.log(this.state);
    }

    setEndDate = (date) => {

        this.setState({
            enddate: date
        });
        // console.log(this.state);
    }
    handleChangeProduct = selectedOption => {
        this.setState({ productname: selectedOption });
        // console.log(`Option selected:`, selectedOption);
    };

    handleChangeSuplier = selectedOption => {
        this.setState({ suppliername: selectedOption });
        // console.log(`Option selected:`, selectedOption);
    };

    handleChangCompany = selectedOption => {
        this.setState({ companyname: selectedOption });
        // console.log(`Option selected:`, selectedOption);
    };

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    componentDidMount() {

        PaymentReportsService.getAllSuppliers()
            .then(response => {
                // console.log({ response });
                if (response.data.suppliers) {
                    this.setState({
                        suppliers: response.data.suppliers,
                        companies: response.data.companies,
                        products: response.data.products,
                        loadingvalues: false
                    });
                    // console.log('i am getsupds');
                }
            });

        AuthService.checklogin().then(user => {
            // this.loadRefunds(true);
            this.setState({
                user: user
            });

            AuthService.getUserPermisions(user)
            .then(response => {
                var userpermissions = response.data;
                console.log({userpermissions});
                    if(userpermissions.includes('refundsreport') || userpermissions.includes('all')) {
                    console.log('You are allowed!');
                    this.setState({
                        allowed: true
                    });
                } else {
                    console.log('You are not allowed!');
                    this.setState({
                        allowed: false
                    });
                }
            })
        }).catch(error => {
            window.location.href = '/login';
        });
    }

    loadRefunds = (e) => {

        e.preventDefault();
        this.setState({
            data: [],
            show: false,
            error: {
                status: false,
                message: ''
            },
            loadingvalues: false,
            loader: true,
            search_btn_text: 'Fetching...',
        });

        setTimeout(()=>{
    
            var startday = '';
            if (this.state.startdate != '' && this.state.startdate != null) {
                startday = UtilityService.format_date(this.state.startdate);
            }
    
            var endday = '';
            if (this.state.enddate != '' && this.state.enddate != null) {
                endday = UtilityService.format_date(this.state.enddate);
            }
    
            RefundReportsService.getRefunds('all-refunds', startday, endday, this.state.companyname.value, this.state.suppliername.value, this.state.productname.value, this.state.refundstatus, this.state.user.user_email)
            .then(response => {
                console.log({response});
                if(response.data) {
    
                    this.setState({
                        data: response.data,
                        columnsheadings: [
                            {title: '<input type="checkbox" id="selectall" />'},
                            { title: "Date Created" },
                            { title: "Customer Name" },
                            { title: "Order Details" },
                            { title: "Product Name" },
                            { title: "Payment Method & Reference" },
                            { title: "Booking Details" },
                            { title: "Total Selling Amount" },
                            { title: "Fees / Discounts" },
                            { title: "Refund Amount" },
                            { title: "Refund Reason" },
                            { title: "Explanation & Comments" },
                            { title: "Reservation Agent Name" },
                            { title: "Refund Status" },
                            { title: "Date Processed" },
                        ],
                        show: true,
                        error: {
                            status: false,
                            message: ''
                        },
                        loader: false,
                        search_btn_text: 'Search',
                    });
                } else {
                    this.setState({
                        data: [],
                        columnsheadings: [],
                        show: false,
                        error: {
                            status: true,
                            message: 'No data found'
                        },
                        loader: false,
                        search_btn_text: 'Search',
                    });
                }
            })
            .catch(error => {
                this.setState({
                    data: [],
                    columnsheadings: [],
                    show: false,
                    error: {
                        status: true,
                        message: 'No data found'
                    },
                    loader: false,
                    search_btn_text: 'Search',
                });
                console.log({error});
            });
        }, 1000);
        
    }



    render() {
        var { data, columnsheadings, options, show, error, loadingvalues, products, suppliers, companies, search_btn_text, loader } = this.state;

        if (this.state.allowed == false) {
          return <NoPermission />;
        }
        
        return (
            <>
                <div className="row">
                    <div className='col'>
                        <h2>Refunds Report</h2>
                        <hr />
                    </div>
                </div>
                <div className="row">
                    <div className='col'>
                        {loadingvalues == true &&
                            <p style={{ color: "#df153b" }}><b><i>Loading Products/Suppliers. Please Wait....</i></b></p>
                        }
                        <form action="" method="get" className="booking-reports-search-form" style={{ display: 'flex' }}>
                            <input type="hidden" name="page" value="deals-payment-report" />
                            <div>
                                <DatePicker
                                    dateFormat="dd-MM-yyyy"
                                    selected={this.state.startdate}
                                    onChange={date => this.setStartDate(date)}
                                    placeholderText="Start Processing Date"
                                    className='form-control'
                                />
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                               
                                <DatePicker
                                    dateFormat="dd-MM-yyyy"
                                    selected={this.state.enddate}
                                    onChange={date => this.setEndDate(date)}
                                    placeholderText="End Processing Date"
                                    className='form-control'
                                    minDate = {this.state.minDate}
                                />
                            </div>
                            <div style={{ marginLeft: '10px', width: '300px' }}>
                                
                                <Select
                                    value={this.state.companyname}
                                    onChange={this.handleChangCompany}
                                    options={companies}
                                />    
                            </div>
                            <div style={{ marginLeft: '10px', width: '300px' }}>
                                <Select
                                    value={this.state.suppliername}
                                    onChange={this.handleChangeSuplier}
                                    options={suppliers}
                                />
                            </div>
                            <div style={{ marginLeft: '10px', width: '300px' }}>
                                <Select
                                    value={this.state.productname}
                                    onChange={this.handleChangeProduct}
                                    options={products}
                                />
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                <select name="refundstatus" className="select-drops form-control" value={this.state.refundstatus} onChange={this.handleChange.bind(this)}>
                                    <option value="0">All Refund Status</option>
                                    <option value="Pending">Pending</option>
                                    <option value="Approved">Approved</option>
                                    <option value="Rejected">Rejected</option>
                                    <option value="Processed">Processed</option>
                                </select>
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                <button className="btn btn-success btn-large booking-search-btn" onClick={this.loadRefunds}>{search_btn_text}</button>
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                <a href="/refunds-report" className="btn btn-large btn-warning booking-reset-btn">Reset</a>
                            </div>

                        </form>

                        <hr />
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <b>Total Refund:</b> Rs <span className='total-refund'>0</span>
                        <hr />
                    </div>
                </div>
                <div className="row">
                    <div className='col'>
                        {error.status == true &&
                            <div className="alert alert-danger" role="alert">
                                {error.message}
                            </div>
                        }
                        {(show == true) &&
                            <DTable data={data} columns={columnsheadings} sorting={[[1, "desc"]]} />
                        }
                        {loader == true &&
                            <Loader className='loader-center'
                                type="Grid"
                                color="#00BFFF"
                                height={100}
                                width={100}
                            />
                        }
                    </div>
                </div>
            </>
        );
    }
}