import axios from "axios";
import chalk from 'chalk';

const API_URL = "https://deals.mu/scripts/reportsapi/getcommisions.php";

class CommissionsService {
    getAll() {

        var params = {
            action: 'all_commissions',
        };

        return axios
            .get(API_URL, { params })
            .then((response) => {

                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

    getSingle(supplier, company) {
        var params = {
            action: 'single_details',
            suppliername: supplier,
            companyname: company,
        };

        return axios
        .get(API_URL, {params})
        .then(response => {
            // console.log({response});
            return response;
        })
        .catch(error => {
            // console.log({error});
            return true;
        });
    }

    getSingleMeta(id) {
        var params = {
            action: 'single_details_meta',
            id: id,
        };

        return axios
            .get(API_URL, { params })
            .then(response => {
                console.log({ response });
            })
            .catch(error => {
                console.log({ error });
            });
    }

}

export default new CommissionsService();