import React, { Component } from "react";
import DatePicker from "react-datepicker";
import Loader from 'react-loader-spinner';
import { DataGrid } from '@mui/x-data-grid';
import Chip from '@mui/material/Chip';
import Select from 'react-select';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import swal from "sweetalert";

import AuthService from '../services/auth/auth.service';
import OrdersReportService from "../services/orders/ordersreport.service";
import InvoicesServiceNew from "../services/erp/invoicesnew.service";

import "react-datepicker/dist/react-datepicker.css";
import NoPermission from "./extras/nopermission.component";
import FollowUpList from "./orders/followuplist.component";
import OrderPaymentDetails from "./orders/orderpaymentdetails.component";
import OrderRefundsDetails from "./orders/orderrefundsdetails.component";
import UtilityService from "../services/utility.service";
import PaymentReportsService from '../services/erp/paymentreports.service';
import SupplierDetails from "./tasklist/suppliersdetails.component";

export default class OrdersReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
          data: [],
          columnsheadings: [
            { field: 'id', headerName: 'Order ID', flex: 1 },
            { field: 'customerName', headerName: 'Customer Name', flex: 2 },
            { field: 'customerContacts', headerName: 'Customer Contacts', flex: 2, 
                renderCell: (cellValues) => {
                    return (
                    <div style={{display: "flex", flexDirection: "column", justifyContent: 'center', width: '100%', alignItems: "center"}}>
                        <span>{cellValues.row.customerContactsEmail}</span>
                        <span>{cellValues.row.customerContactsPhone}</span>
                        <a target="_blank" className="btn btn-sm btn-link" href={"/user-profile/"+cellValues.row.userid} rel="noreferrer">View on CRM</a>
                    </div>
                    )
                }
            },
            { field: 'orderDate', headerName: 'Order Date', flex: 1, 
                renderCell: (cellValues) => {
                    var orderDateN = UtilityService.formatDateDifference(cellValues.row.orderDate);
                    return (
                        <>{orderDateN}</>
                    )
                } 
            },
            { field: 'orderStatus', headerName: 'Order Status', flex: 1,
                renderCell: (cellValues) => {
                    var color = "black";
                    var bg = "#cdcbcb";
                    if(cellValues.row.orderStatus=='Processing') {
                        color = "white";
                        bg = "#0fab0f";
                    } else if(cellValues.row.orderStatus=='Cancelled') {
                        color = "white";
                        bg = "#ff6767";
                    }
                    return (
                        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                        <span style={{background: bg, color: color, padding: "10px", fontWeight: "bold"}}>{cellValues.row.orderStatus}</span>
                        </div>
                    )
                } 
            },
            { field: 'orderTags', headerName: 'Order Tags', flex: 1, sortable: false, disableColumnFilter: true, 
                renderCell: (cellValues) => {
                    return(
                        <div className="orderreport-order-tags-wrapper" style={{display: "flex", flexDirection: "column", justifyContent:"space-between", alignItems: "flex-start"}}>
                        {cellValues.row.tags.superurgent==true && 
                            <p><Chip label="Super Urgent" color="error" /></p>
                        }
                        {cellValues.row.tags.urgent==true && 
                            <p><Chip label="Urgent" color="error" /></p>
                        }
                        {cellValues.row.tags.big_booking==true && 
                            <p><Chip label="Big Booking" color="success" /></p>
                        }
                        {cellValues.row.tags.followup==true && 
                            <p><Chip label="Follow up" /></p>
                        }
                        {cellValues.row.tags.open_voucher==true && 
                            <p><Chip label="Open Voucher" color="info" /></p>
                        }
                        {cellValues.row.tags.instant==true && 
                            <p><Chip label="Instant Product" color="warning" /></p>
                        }
                        {cellValues.row.tags.corporate==true && 
                            <p><Chip label="Corporate" color="secondary" /></p>
                        }
                        </div>
                    );
                }
            },
            { field: 'bookingVoucherStatus', headerName: 'Booking / Voucher Status', flex: 2, sortable: false, disableColumnFilter: true, 
                renderCell: (cellValues) => {
                    return(
                        <div style={{display: "flex", flexDirection: "column",}}>
                        {cellValues.row.operations.supplierSent.total==cellValues.row.operations.supplierSent.sent && 
                            <p><Chip label="Sent to Supplier" color="success" /></p>
                        }

                        {cellValues.row.operations.supplierSent.total>cellValues.row.operations.supplierSent.sent && cellValues.row.operations.supplierSent.sent>0 && 
                            <p><Chip label="Partially Sent to Supplier" color="warning" /></p>
                        }

                        {cellValues.row.operations.supplierSent.sent==0 && 
                            <p><Chip label="Not Sent to Supplier" color="error" /></p>
                        }
                        {cellValues.row.operations.customerSent.total==cellValues.row.operations.customerSent.sent && 
                            <p><Chip label="Sent to Customer" color="success" /></p>
                        }

                        {cellValues.row.operations.customerSent.total>cellValues.row.operations.customerSent.sent && cellValues.row.operations.customerSent.sent>0 && 
                            <p><Chip label="Partially Sent to Customer" color="warning" /></p>
                        }

                        {cellValues.row.operations.customerSent.sent==0 && 
                            <p><Chip label="Not Sent to Customer" color="error" /></p>
                        }
                        {cellValues.row.operations.supplierConfirm.total==cellValues.row.operations.supplierConfirm.done && 
                            <p><Chip label="Supplier Confirmed" color="success" /></p>
                        }

                        {cellValues.row.operations.supplierConfirm.total>cellValues.row.operations.supplierConfirm.done && cellValues.row.operations.supplierConfirm.done>0 && 
                            <p><Chip label="Supplier Confirmed Partially" color="warning" /></p>
                        }

                        {cellValues.row.operations.supplierConfirm.done==0 && 
                            <p><Chip label="Supplier Not Confirmed" color="error" /></p>
                        }
                        </div>
                    );
                }
            },
            { field: 'paymentMethod', headerName: 'Payment Method', flex: 1 },
            { field: 'followUp', headerName: 'Follow Up', flex: 1.1, sortable: false, disableColumnFilter: true, 
                renderCell : (cellValues) => {
                    if(cellValues.row.followup==true) {
                        return <div style={{display: "flex", flexDirection: "column", justifyContent: 'space-between', width: '100%', alignItems: "center"}}>
                                    <Chip label="Followup Required" style={{fontWeight: "bold"}} />
                                </div>
                    } else {
                        return "";
                    }
                }
            },
            { field: 'comments', headerName: 'Comments', flex: 1 },
            // { field: 'order_total', headerName: 'Total', flex: 1, sortable: false, disableColumnFilter: true, 
            //     renderCell: (cellValues) => {
            //         var total = cellValues.row.order_total;
            //         var subtotal = cellValues.row.order_subtotal;

            //         if(total==subtotal) {
            //             return (<span style={{fontWeight: "bold"}}>Rs {total.toLocaleString()}</span>);
            //         } else {
            //             return (<div style={{display: "flex", flexDirection: "column"}}><p style={{textDecoration: "line-through"}}>Rs {subtotal.toLocaleString()}</p><p style={{fontWeight: "bold"}}>Rs {total.toLocaleString()}</p></div>);
            //         }
            //     }
            // },
            { field: 'paymentDetails', headerName: 'Payment Details', flex: 1, sortable: false, disableColumnFilter: true, 
                renderCell: (cellValues) => {
                    var total = cellValues.row.order_total;
                    var subtotal = cellValues.row.order_subtotal;

                    
                    return(
                        <div style={{display: "flex", flexDirection: "column", justifyContent: 'space-between', width: '100%', alignItems: "center"}}>
                            {total==subtotal &&
                                <span style={{fontWeight: "bold"}}>Rs {total.toLocaleString()}</span>
                            } 
                            {total!=subtotal && 
                                <div style={{display: "flex", flexDirection: "column"}}><p style={{textDecoration: "line-through"}}>Rs {subtotal.toLocaleString()}</p><p style={{fontWeight: "bold"}}>Rs {total.toLocaleString()}</p></div>
                            }
                            <OrderPaymentDetails orderid={cellValues.row.id} />
                            {cellValues.row.haverefunds &&
                                <OrderRefundsDetails orderid={cellValues.row.id} />
                            }
                        </div>
                    );
                }
            },
            { field: 'orderDetails', headerName: 'Order Details', flex: 1, sortable: false, disableColumnFilter: true, 
                renderCell: (cellValues) => {
                    return (
                        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                        <a style={{color: "red", textDecoration: "underline", fontWeight: "bold"}} href={"/edit-order/"+cellValues.row.id}>View All Details</a>
                        </div>
                    );
                }
            },
          ],
          options: {
            filterType: "dropdown",
          },
          show: false,
          products: [{ value: '0', label: "Select a Product" }],
          enableproducts: false,
          suppliers: [{ value: '0', label: "Select a Supplier" }],
          enablesuppliers: false,
          companies: [{ value: '0', label: "Select a Company" }],
          startdate: "",
          suppliername: { value: '0', label: "Select a Supplier" },
          enddate: "",
          productname: { value: '0', label: "Select a Product" },
          companyname: { value: '0', label: "Select a Company" },
          orderstatus: 0,
          loadingvalues: true,
          error: {
            status: false,
            message: "",
          },
          loader: true,
          search_btn_text: "Apply",
          searchbtndisabled: false,
          minDate: new Date("02-01-2019"),
          allowed: true,
          showFollowup: false,
          page: 1,
          limit: 20,
          rowCount: 0,
          allOrders: 0,
          pendingOrders: 0,
          processingOrders: 0,
          cancelledOrders: 0,
          completedOrders: 0,
          paymentmethods: {
            juice: 0,
            internetbanking: 0,
            banktransfer: 0,
            migs: 0,
            mips: 0,
            cash: 0,
          },
          dataloader: false,
          statusFilter: {
              all: true,
              processing : false, 
              pending: false, 
              cancelled: false,
              completed: false
          },
          paymentMethodFilter: {
              all: true,
              juice : false, 
              internetbanking: false, 
              creditcard: false,
              cash: false
          },
          voucherStatusFilter: {
              all: true,
              notSentSupplier : false, 
              notSentCustomer: false, 
              notConfirmedSupplier: false,
          },
          specialCasesFilter: {
            all: true,
            complaint : false, 
            refunded: false, 
            credit: false,
            partpayment: false,
          },
          orderTagsFilter: {
            all: true,
            superurgent : false, 
            urgent: false, 
            corporate: false,
            bigbooking: false,
            followup: false,
            openvoucher: false,
            instantproduct: false,
          },
          opensearch: "",
          filtersapplied: "No filters applied",
          userID: 0,
          filterValue: {
              key: "",
              value: ""
          },
          exportExcelBtnText: "Export To Excel",
          exportExcelBtnDisabled: false,
          exportCsvBtnText: "Export To CSV",
          exportCsvBtnDisabled: false,
          sortValue: {
              field: "",
              sort: ""
          },
          allProducts: [{value:0, label:'Select a Product'}],
          supplierSearchProd: {value:0, label:'Select a Product'},
          showSupplierDetails: false
        };
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    changeSearchValue = (e) => {
        // check if the character is an enter key
        if(e.keyCode==13) {
            this.searchClick(true);
        } else {
            this.setState({
                opensearch: e.target.value
            });
        }
    }

    changeSearchValueKeyDown = (e) => {
        // check if the character is an enter key
        // console.log(e.keyCode);
        if(e.keyCode==13) {
            this.searchClick(true);
        }
    }

    handleStatusChange(e, value) {
        if(value=='all' && e.target.checked) {
            this.setState({
                statusFilter: {
                    all: true,
                    processing : false, 
                    pending: false, 
                    cancelled: false,
                    completed: false
                }
            });
        } else {
            var statusFilter = this.state.statusFilter;
            statusFilter['all'] = false;
            statusFilter[value] = e.target.checked;
            this.setState({
                statusFilter: statusFilter
            });
        }
    }

    handlePaymentMethodChange(e, value) {
        if(value=='all' && e.target.checked) {
            this.setState({
                paymentMethodFilter: {
                    all: true,
                    juice : false, 
                    internetbanking: false, 
                    creditcard: false,
                    cash: false
                }
            });
        } else {
            var paymentMethodFilter = this.state.paymentMethodFilter;
            paymentMethodFilter['all'] = false;
            paymentMethodFilter[value] = e.target.checked;
            this.setState({
                paymentMethodFilter: paymentMethodFilter
            });
        }
    }

    handleVoucherStatusChange(e, value) {
        if(value=='all' && e.target.checked) {
            this.setState({
                voucherStatusFilter: {
                    all: true,
                    notSentSupplier : false, 
                    notSentCustomer: false, 
                    notConfirmedSupplier: false,
                }
            });
        } else {
            var voucherStatusFilter = this.state.voucherStatusFilter;
            voucherStatusFilter['all'] = false;
            voucherStatusFilter[value] = e.target.checked;
            this.setState({
                voucherStatusFilter: voucherStatusFilter
            });
        }
    }

    handleSpecialCasesChange(e, value) {
        if(value=='all' && e.target.checked) {
            this.setState({
                specialCasesFilter: {
                    all: true,
                    complaint : false, 
                    refunded: false, 
                    credit: false,
                    partpayment: false,
                }
            });
        } else {
            var specialCasesFilter = this.state.specialCasesFilter;
            specialCasesFilter['all'] = false;
            specialCasesFilter[value] = e.target.checked;
            this.setState({
                specialCasesFilter: specialCasesFilter
            });
        }
    }

    handleOrderTagsChange(e, value) {
        if(value=='all' && e.target.checked) {
            this.setState({
                orderTagsFilter: {
                    all: true,
                    superurgent : false, 
                    urgent: false, 
                    corporate: false,
                    bigbooking: false,
                    followup: false,
                    openvoucher: false,
                    instantproduct: false,
                }
            });
        } else {
            var orderTagsFilter = this.state.orderTagsFilter;
            orderTagsFilter['all'] = false;
            orderTagsFilter[value] = e.target.checked;
            this.setState({
                orderTagsFilter: orderTagsFilter
            });
        }
    }

    componentDidMount() {

        PaymentReportsService.getAllSuppliers()
        .then(response => {
            // console.log({ response });
            if (response.data.suppliers) {
                this.setState({
                    // suppliers: response.data.suppliers,
                    allProducts: response.data.products,
                });
                // console.log('i am getsupds');
            }
        });

        OrdersReportService.getcompanies()
            .then(response => {
                console.log({ response });
                if (response.data) {
                    this.setState({
                        companies: response.data,
                        loadingvalues: false
                    });
                }
            });
        
        AuthService.checklogin().then(user => {
            AuthService.getUserPermisions(user)
            .then(response => {
                var userpermissions = response.data;
                console.log({userpermissions});
                    if(userpermissions.includes('orders') || userpermissions.includes('all')) {
                    console.log('You are allowed!');
                    this.setState({
                        allowed: true
                    });
                    setTimeout(() => {
                        if (typeof this.props.params.userid == 'undefined') {
                            this.setState({
                                userID: 0,
                            });
                            this.searchClick();
                        } else {
                            this.setState({
                                userID: this.props.params.userid,
                            });
                            if (this.state.userID != 0) {
                                this.searchClick();
                            }
                        }
                    }, 1000);
                    
                } else {
                    console.log('You are not allowed!');
                    this.setState({
                        allowed: false
                    });
                }
            })
        }).catch(error => {
            window.location.href = '/login';
        });
    }

    supChange = (selectedOption) => {
        this.setState({ suppliername: selectedOption });
    }

    setStartDate = (date) => {
        this.setState({
            startdate: date,
            minDate: date,
        });

        if (this.state.enddate != '' && this.state.enddate < date) {
            this.setState({
                enddate:date
            });
        }
    }

    setEndDate = (date) => {

        this.setState({
            enddate: date
        });
    }
    handleChangeProduct = selectedOption => {
        this.setState({ productname: selectedOption });
    };

    handleChangeSuplier = selectedOption => {
        this.setState({ 
            suppliername: selectedOption,
            enableproducts: false,
            products: [{value: 0, label: "Select a Product"}],
            productname: {value: 0, label: "Select a Product"}
        });

        if(selectedOption.value!=0 && this.state.companyname.value!=0) {
            OrdersReportService.getproducts(this.state.companyname.value, selectedOption.value)
            .then((response) => {
                this.setState({
                    products: response.data,
                    enableproducts: true,
                });
            })
            .catch(error => console.log(error));
        }
    };

    handleChangCompany = selectedOption => {
        this.setState({ 
            companyname: selectedOption,
            enablesuppliers: false,
            suppliers: [{value: 0, label: "Select a Supplier"}],
            suppliername: {value: 0, label: "Select a Supplier"},
            enableproducts: false,
            products: [{value: 0, label: "Select a Product"}],
            productname: {value: 0, label: "Select a Product"}
        });

        if(selectedOption.value!==0) {
            this.setState({ 
            companyname: selectedOption,
            suppliers: [{value: 0, label: "Select a Supplier"}]
        });
        InvoicesServiceNew.fetchSuppliers(selectedOption.value)
            .then(response => {

                this.setState({
                    suppliers: response.data,
                    enablesuppliers: true,
                });
            })
            .catch(error => {
                console.log(error);
            })
        }
    }

    updateData = () => {
        this.setState({
            page: this.state.page+1
        });
        this.searchClick(false);
    }

    searchClick = async (resetPage=false) => {
        var startday = '';
        var endday = '';

        this.setState({
            data: [],
            // show: false,
            // loader: true,
            dataloader: true,
            search_btn_text: "Fetching....",
            searchbtndisabled: true,
            allOrders: 0,
            pendingOrders: 0,
            processingOrders: 0,
            cancelledOrders: 0,
            completedOrders: 0,
            paymentmethods: {
                juice: 0,
                internetbanking: 0,
                banktransfer: 0,
                migs: 0,
                mips: 0,
                cash: 0,
            },
        });

        if(resetPage) {
            await this.setState({
                page: 1
            });
        }

        this.filtersapplied();

        if (this.state.startdate != '' && this.state.enddate!='') {
            startday = UtilityService.format_date(this.state.startdate);
            endday = UtilityService.format_date(this.state.enddate);
        }

        OrdersReportService.getOrders(this.state.page, this.state.limit, startday, endday, this.state.companyname.value, this.state.suppliername.value, this.state.productname.value, this.state.statusFilter, this.state.specialCasesFilter, this.state.orderTagsFilter, this.state.paymentMethodFilter, this.state.voucherStatusFilter, this.state.opensearch, this.state.userID, this.state.filterValue, this.state.sortValue)
        .then(response => {
            console.log("get orders", response);
            this.setState({
                data: response.data.orders,
                show: true,
                loader: false,
                rowCount: response.data.count,
                allOrders: response.data.allorders,
                pendingOrders: response.data.pending,
                processingOrders: response.data.processing,
                cancelledOrders: response.data.cancelled,
                completedOrders: response.data.completed,
                dataloader: false,
                search_btn_text: "Apply",
                searchbtndisabled: false,
                paymentmethods: {
                    juice: response.data.paymentmethods.juice,
                    internetbanking: response.data.paymentmethods.internetbanking,
                    banktransfer: response.data.paymentmethods.banktransfer,
                    migs: response.data.paymentmethods.migs,
                    mips: response.data.paymentmethods.mips,
                    cash: response.data.paymentmethods.other,
                },
            });
        })
        .catch(error => console.log({error}));
        
        console.log("search clicked");
    }

    fetchAndShowFollowupList = (e) => {
        this.setState({
            showFollowup: !this.state.showFollowup
        });
    }

    filtersapplied = () => {
        if(this.state.statusFilter.processing==true || this.state.statusFilter.pending==true || this.state.statusFilter.cancelled==true || this.state.paymentMethodFilter.juice==true || this.state.paymentMethodFilter.internetbanking==true || this.state.statusFilter.creditcard==true || this.state.paymentMethodFilter.cash==true || this.state.voucherStatusFilter.notSentSupplier==true || this.state.voucherStatusFilter.notSentCustomer==true || this.state.voucherStatusFilter.notConfirmedSupplier==true || this.state.specialCasesFilter.complaint==true || this.state.specialCasesFilter.refunded==true || this.state.specialCasesFilter.credit==true || this.state.specialCasesFilter.partpayment==true || this.state.orderTagsFilter.processing==true || this.state.orderTagsFilter.processing==true || this.state.orderTagsFilter.processing==true || this.state.orderTagsFilter.processing==true || this.state.orderTagsFilter.processing==true || this.state.orderTagsFilter.processing==true || this.state.orderTagsFilter.processing==true || this.state.opensearch!="") {
            this.setState({
                filtersapplied: "Filters Applied"
            });
        }
    }

    submitForm = (e) => {
        e.preventDefault();
        return false;
        this.searchClick();
    }

    onFilterChange = (filterModel) => {
        console.log("filter value", filterModel.items[0].value);
        console.log("filter Item", filterModel.items[0].columnField);

        this.setState({
            filterValue: {
              key: filterModel.items[0].columnField,
              value: filterModel.items[0].value
          }
        });

        if(typeof filterModel.items[0].value!='undefined') {
            this.searchClick();
        } else {
            this.setState({
                filterValue: {
                    key: "",
                    value: ""
                }
            });
            this.searchClick();
        }
    }

    exportResults = (e, type) => {
        e.preventDefault();

        if(type=='csv') {
            this.setState({
                exportCsvBtnText: "Exporting...",
                exportCsvBtnDisabled: true,
            });
        } else {
            this.setState({
                exportExcelBtnText: "Exporting...",
                exportExcelBtnDisabled: true,
            });
        }

        var startday = '';
        var endday = '';
        if (this.state.startdate != '' && this.state.enddate!='') {
            startday = UtilityService.format_date(this.state.startdate);
            endday = UtilityService.format_date(this.state.enddate);
        }

        OrdersReportService.getOrderExport(0, this.state.limit, startday, endday, this.state.companyname.value, this.state.suppliername.value, this.state.productname.value, this.state.statusFilter, this.state.specialCasesFilter, this.state.orderTagsFilter, this.state.paymentMethodFilter, this.state.voucherStatusFilter, this.state.opensearch, this.state.userID, this.state.filterValue, this.state.sortValue)
        .then(response => {
            console.log("export", response);
            
            this.setState({
                exportExcelBtnText: "Export To Excel",
                exportExcelBtnDisabled: false,
                exportCsvBtnText: "Export To CSV",
                exportCsvBtnDisabled: false,
            });

            if(response.data.length>0) {
                const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                const fileExtension = '.xlsx';
                const ws = XLSX.utils.json_to_sheet(response.data);
                const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const data = new Blob([excelBuffer], {type: fileType});
                FileSaver.saveAs(data, "orderexport"+Math.random() + fileExtension);
            } else {
                swal("No Data", "No data found for selected filters", "error");
            }

        })
        .catch(error => console.log({error}));
    }

    handleSortModelChange = (newModel) => {
        console.log({newModel});
        this.setState({
            sortValue: newModel[0]
        });
        this.searchClick();
    }

    setPast24Hours = (e) => {
        e.preventDefault();
        var today = new Date();
        var yesterday = new Date(today.setDate(today.getDate() - 1));
        this.setState({
            startdate: yesterday,
            enddate: new Date(),
        });

        setTimeout(() => {
            this.searchClick();
        }, 500);
    }

    setPast48Hours = (e) => {
        e.preventDefault();
        var today = new Date();
        var yesterday = new Date(today.setDate(today.getDate() - 2));
        this.setState({
            startdate: yesterday,
            enddate: new Date(),
        });

        setTimeout(() => {
            this.searchClick();
        }, 500);
    }

    searchClickSupplier(e) {
        e.preventDefault();
        console.log("clicked");
        if(this.state.supplierSearchProd.value!=0 && this.state.supplierSearchProd.value!='0') {
            this.setState({
                showSupplierDetails: true
            });
        } else {
            swal("No products selected!", "Please select a product to get supplier!", "error");
        }
    }

    closeSupplierDetails(e) {
        this.setState({
            showSupplierDetails: false
        });
    }

    handleSupplierSearchProdChange(selectedOption) {
        this.setState({ 
            supplierSearchProd: selectedOption,
        });
    }

    render() {
        var { data, columnsheadings, options, show, products, suppliers, companies, loadingvalues, error, search_btn_text, loader } = this.state;

        if (this.state.allowed == false) {
          return <NoPermission />;
        }

        return (
            <>
                <div className="row" style={{width: "100%"}}>
                    <div className='col' style={{display: "flex", justifyContent: "space-between", marginBottom:"0px", width: "100%"}}>
                        <h1 className="orders-h1">Deals Orders Report</h1>
                        <div style={{ marginLeft: '10px' }}>
                            {/* <a href="/automation" className="btn btn-large btn-warning booking-reset-btn">Juice / Bank Automation</a> */}
                            {this.state.showSupplierDetails && 
                                <SupplierDetails label={this.state.supplierSearchProd.label} id={this.state.supplierSearchProd.value} show={this.state.showSupplierDetails} closeModal={e => this.closeSupplierDetails(e)} />
                            }
                            <div style={{display: "flex", flexDirection: "row", marginLeft: "10px"}}>
                                <Select
                                    value={this.state.supplierSearchProd}
                                    onChange={this.handleSupplierSearchProdChange.bind(this)}
                                    options={this.state.allProducts} 
                                    id="products-supplier"
                                />
                                <button className="btn btn-sm btn-warning" style={{marginLeft: "10px"}} onClick={this.searchClickSupplier.bind(this)} id="get-product-supplier-btn" >Get Suppliers</button>
                                <a href="/transactions-sheet" className="btn btn-primary" style={{marginLeft: "10px"}}>Transactions Sheet</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className='col'>
                        <hr />
                        {loadingvalues == true &&
                            <p style={{ color: "#df153b" }}><b><i>Loading Suppliers. Please Wait....</i></b></p>
                        }
                        <div onSubmit={e => e.preventDefault()} className="order-reports-search-form" style={{ display: 'flex' }}>
                            <input type="hidden" name="page" value="deals-payment-report" />
                            <div>
                               
                                <DatePicker
                                    dateFormat="dd-MM-yyyy"
                                    selected={this.state.startdate}
                                    onChange={date => this.setStartDate(date)}
                                    placeholderText="Start Date"
                                    className='form-control'
                                />
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                               
                                <DatePicker
                                    dateFormat="dd-MM-yyyy"
                                    selected={this.state.enddate}
                                    onChange={date => this.setEndDate(date)}
                                    placeholderText="End Date"
                                    className='form-control'
                                    minDate = {this.state.minDate}
                                />
                            </div>
                            <div style={{ marginLeft: '10px', width: '140px' }}>
                                <button className="btn btn-success" onClick={e=>this.setPast24Hours(e)}>Past 24 Hours</button>
                            </div>
                            <div style={{ marginLeft: '10px', width: '140px' }}>
                                <button className="btn btn-success" onClick={e=>this.setPast48Hours(e)}>Past 48 Hours</button>
                            </div>
                            <div style={{ marginLeft: '10px', width: '300px' }}>
                                
                                <Select
                                    value={this.state.companyname}
                                    onChange={this.handleChangCompany}
                                    options={companies}
                                />    
                            </div>
                            <div style={{ marginLeft: '10px', width: '300px' }}>
                                <Select
                                    value={this.state.suppliername}
                                    onChange={this.handleChangeSuplier}
                                    options={suppliers}
                                    isDisabled = {!this.state.enablesuppliers}
                                />
                            </div>
                            <div style={{ marginLeft: '10px', width: '300px' }}>
                                <Select
                                    value={this.state.productname}
                                    onChange={this.handleChangeProduct}
                                    options={products}
                                    isDisabled = {!this.state.enableproducts}
                                />
                            </div>
                            
                            <div style={{ marginLeft: '10px' }}>
                                <button type="button" className="btn btn-success btn-large booking-search-btn" disabled={this.state.searchbtndisabled} onClick={(e) => this.searchClick(true)}>{search_btn_text}</button>
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                <a href="/orders" className="btn btn-large btn-warning booking-reset-btn">Reset</a>
                            </div>
                        </div>

                        <hr />
                    </div>
                </div>
                <Accordion style={{marginBottom: "20px"}}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" style={{backgroundColor: "#ebebeb"}}>
                <Typography style={{fontWeight: "bold"}}>Filters & Export 
                {this.state.userID!=0 && 
                    <span style={{color: "red", marginLeft: "10px"}}>
                        (Filtered Result for User ID: {this.state.userID})
                    </span>
                }
                </Typography>
                </AccordionSummary>
                <AccordionDetails>
                <div className="row">
                    <div className='col'>
                        <div onSubmit={e => e.preventDefault()} className="order-reports-filter-search-form filter-form-container">
                            <FormGroup>
                                <label className="filterform-label">Order Status</label>
                                <FormControlLabel className="filter-form-checkbox" checked={this.state.statusFilter.all} onChange={(e) => this.handleStatusChange(e, 'all')} control={<Checkbox />} label={`All (${this.state.allOrders})`} />
                                <FormControlLabel className="filter-form-checkbox" checked={this.state.statusFilter.processing} onChange={(e) => this.handleStatusChange(e, 'processing')} control={<Checkbox />} label={`Processing (${this.state.processingOrders})`} />
                                <FormControlLabel className="filter-form-checkbox" checked={this.state.statusFilter.completed} onChange={(e) => this.handleStatusChange(e, 'completed')} control={<Checkbox />} label={`completed (${this.state.completedOrders})`} />
                                <FormControlLabel className="filter-form-checkbox" checked={this.state.statusFilter.pending} onChange={(e) => this.handleStatusChange(e, 'pending')} control={<Checkbox />} label={`Pending Payment (${this.state.pendingOrders})`} />
                                <FormControlLabel className="filter-form-checkbox" checked={this.state.statusFilter.cancelled} onChange={(e) => this.handleStatusChange(e, 'cancelled')} control={<Checkbox />} label={`Cancelled (${this.state.cancelledOrders})`} />
                            </FormGroup>
                            <FormGroup>
                                <label className="filterform-label">Special Cases</label>
                                <FormControlLabel className="filter-form-checkbox" checked={this.state.specialCasesFilter.all} onChange={(e) => this.handleSpecialCasesChange(e, 'all')} control={<Checkbox />} label="All" />
                                <FormControlLabel className="filter-form-checkbox" checked={this.state.specialCasesFilter.complaint} onChange={(e) => this.handleSpecialCasesChange(e, 'complaint')} control={<Checkbox />} label="Complaint" />
                                <FormControlLabel className="filter-form-checkbox" checked={this.state.specialCasesFilter.refunded} onChange={(e) => this.handleSpecialCasesChange(e, 'refunded')} control={<Checkbox />} label="Refunded" />
                                <FormControlLabel className="filter-form-checkbox" checked={this.state.specialCasesFilter.credit} onChange={(e) => this.handleSpecialCasesChange(e, 'credit')} control={<Checkbox />} label="Credit" />
                                <FormControlLabel className="filter-form-checkbox" checked={this.state.specialCasesFilter.partpayment} onChange={(e) => this.handleSpecialCasesChange(e, 'partpayment')} control={<Checkbox />} label="Part Payment" />
                            </FormGroup>
                            <FormGroup>
                                <label className="filterform-label">Order Tags</label>
                                <FormControlLabel className="filter-form-checkbox" checked={this.state.orderTagsFilter.all} onChange={(e) => this.handleOrderTagsChange(e, 'all')} control={<Checkbox />} label="All" />
                                <FormControlLabel className="filter-form-checkbox" checked={this.state.orderTagsFilter.superurgent} onChange={(e) => this.handleOrderTagsChange(e, 'superurgent')} control={<Checkbox />} label="Super Urgent" />
                                <FormControlLabel className="filter-form-checkbox" checked={this.state.orderTagsFilter.urgent} onChange={(e) => this.handleOrderTagsChange(e, 'urgent')} control={<Checkbox />} label="Urgent" />
                                <FormControlLabel className="filter-form-checkbox" checked={this.state.orderTagsFilter.corporate} onChange={(e) => this.handleOrderTagsChange(e, 'corporate')} control={<Checkbox />} label="Corporate " />
                                <FormControlLabel className="filter-form-checkbox" checked={this.state.orderTagsFilter.bigbooking} onChange={(e) => this.handleOrderTagsChange(e, 'bigbooking')} control={<Checkbox />} label="Big Booking" />
                                <FormControlLabel className="filter-form-checkbox" checked={this.state.orderTagsFilter.followup} onChange={(e) => this.handleOrderTagsChange(e, 'followup')} control={<Checkbox />} label="Follow Up" />
                                <FormControlLabel className="filter-form-checkbox" checked={this.state.orderTagsFilter.openvoucher} onChange={(e) => this.handleOrderTagsChange(e, 'openvoucher')} control={<Checkbox />} label="Open Voucher" />
                                <FormControlLabel className="filter-form-checkbox" checked={this.state.orderTagsFilter.instantproduct} onChange={(e) => this.handleOrderTagsChange(e, 'instantproduct')} control={<Checkbox />} label="Instant Product" />
                            </FormGroup>
                            <FormGroup>
                                <label className="filterform-label">Payment Method</label>
                                <FormControlLabel className="filter-form-checkbox" checked={this.state.paymentMethodFilter.all} onChange={(e) => this.handlePaymentMethodChange(e, 'all')} control={<Checkbox />} label={`All (${this.state.allOrders})`} />
                                <FormControlLabel className="filter-form-checkbox" checked={this.state.paymentMethodFilter.juice} onChange={(e) => this.handlePaymentMethodChange(e, 'juice')} control={<Checkbox />} label={`MCB Juice (${this.state.paymentmethods.juice})`} />
                                <FormControlLabel className="filter-form-checkbox" checked={this.state.paymentMethodFilter.internetbanking} onChange={(e) => this.handlePaymentMethodChange(e, 'internetbanking')} control={<Checkbox />} label={`Internet Banking (${parseInt(this.state.paymentmethods.banktransfer)+parseInt(this.state.paymentmethods.internetbanking)})`} />
                                <FormControlLabel className="filter-form-checkbox" checked={this.state.paymentMethodFilter.creditcard} onChange={(e) => this.handlePaymentMethodChange(e, 'creditcard')} control={<Checkbox />} label={`Credit Card (${this.state.paymentmethods.migs})`} />
                                <FormControlLabel className="filter-form-checkbox" checked={this.state.paymentMethodFilter.cash} onChange={(e) => this.handlePaymentMethodChange(e, 'cash')} control={<Checkbox />} label={`Cash (${this.state.paymentmethods.cash})`} />
                                <FormControlLabel className="filter-form-checkbox" checked={this.state.paymentMethodFilter.mips} onChange={(e) => this.handlePaymentMethodChange(e, 'mips')} control={<Checkbox />} label={`MIPS (pop) (${this.state.paymentmethods.mips})`} />
                            </FormGroup>
                            <FormGroup>
                                <label className="filterform-label">Booking/Voucher Status</label>
                                <FormControlLabel className="filter-form-checkbox" checked={this.state.voucherStatusFilter.all} onChange={(e) => this.handleVoucherStatusChange(e, 'all')} control={<Checkbox />} label="All" />
                                <FormControlLabel className="filter-form-checkbox" checked={this.state.voucherStatusFilter.notSentSupplier} onChange={(e) => this.handleVoucherStatusChange(e, 'notSentSupplier')} control={<Checkbox />} label="Not Sent to Supplier" />
                                <FormControlLabel className="filter-form-checkbox" checked={this.state.voucherStatusFilter.notSentCustomer} onChange={(e) => this.handleVoucherStatusChange(e, 'notSentCustomer')} control={<Checkbox />} label="Not Sent to Customer" />
                                <FormControlLabel className="filter-form-checkbox" checked={this.state.voucherStatusFilter.notConfirmedSupplier} onChange={(e) => this.handleVoucherStatusChange(e,'notConfirmedSupplier')} control={<Checkbox />} label="Not Confirmed by Supplier" />
                            </FormGroup>
                            <FormGroup>
                                <label className="filterform-label">Actions</label>
                                 <Box
                                component="form"
                                sx={{
                                    '& > :not(style)': { m: 1, width: '25ch' },
                                }}
                                noValidate
                                autoComplete="off"
                                >
                                <TextField id="outlined-basic" name="opensearch" value={this.state.opensearch} label="Search" variant="outlined" onKeyDown={(e)=>this.changeSearchValueKeyDown(e)} onChange={(e)=>this.changeSearchValue(e)} />
                                </Box>
                                <button type="button" style={{marginTop:"10px"}} disabled={(this.state.startdate=="" && this.state.enddate=="") || this.state.exportCsvBtnDisabled} className="btn btn-success btn-sm" onClick={e => this.exportResults(e, 'csv')}>{this.state.exportCsvBtnText}</button>
                                <button type="button" style={{marginTop:"10px"}} disabled={(this.state.startdate=="" && this.state.enddate=="") || this.state.exportExcelBtnDisabled} className="btn btn-success btn-sm" onClick={e => this.exportResults(e, 'excel')}>{this.state.exportExcelBtnText}</button>
                                <button type="button" style={{marginTop:"10px"}} className="btn btn-primary btn-sm" onClick={e => this.fetchAndShowFollowupList(e)}>View Followup List</button>
                                
                            </FormGroup>
                        </div>

                        <hr />
                    </div>
                </div>
                </AccordionDetails>
                </Accordion>
                {this.state.showFollowup==true && 
                    <FollowUpList loadnow={this.state.showFollowup} orderid="0" />
                }
                <div className="row">
                    <div className='col'>
                        <h5 className="orders-h1">Orders</h5>
                    </div>
                </div>
                <div className="row">
                    <div className='col'>
                        {/* <div className="container-fluid"> */}
                            {error.status == true &&
                                <div className="alert alert-danger" role="alert">
                                    {error.message}
                                </div>
                            }
                            {(show == true) &&
                                <div style={{ height: '100%', width: '100%' }}>
                                <DataGrid
                                    rows={data}
                                    columns={columnsheadings}
                                    pageSize={this.state.limit}
                                    rowsPerPageOptions={[20]} 
                                    autoHeight={true}
                                    rowHeight={200}
                                    pagination
                                    rowCount={this.state.rowCount}
                                    paginationMode="server"
                                    filterMode="server"
                                    onFilterModelChange={this.onFilterChange}
                                    loading={this.state.dataloader}
                                    onPageChange={async (data) => {
                                        console.log({data});
                                        await this.setState({page: data+1});
                                        this.searchClick(false);
                                    }}
                                    page={this.state.page - 1}
                                    onPageSizeChange={(newPage) => this.setState({page: newPage})} 
                                    sortingMode="server" 
                                    onSortModelChange={this.handleSortModelChange}
                                />
                                </div>
                            }
                            {loader == true &&
                                <Loader className='loader-center'
                                    type="Grid"
                                    color="#00BFFF"
                                    height={100}
                                    width={100}
                                />
                            }
                        {/* </div> */}
                    </div>
                </div>
            </>
        );
    }
}