import React, { Component } from "react";
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { style } from "@mui/system";
import swal from "sweetalert";
import DatePicker from "react-datepicker";

import OrdersReportService from "../../services/orders/ordersreport.service";
import NewRefundReportsService from '../../services/erp/refunds-new.service';

export default class RefundsBulkUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            refundItems: props.refundItems,
            allRefunds: [],
            disablesubmitbtn: false,
            submitbtntext: "Update",
            agentName: "",
            refundMethod: "",
            refundDate: "",
            refundReference: "",
            comments: "",
            newRefundItems: [],
            notapproved: 0
        }

        this.timer = null;
        // console.log("props", props);
    }

    componentDidMount() {
        var allRefundsTemp = this.props.allRefunds;
        // console.log(allRefundsTemp)
        this.setState({
            allRefunds: [...allRefundsTemp]
        });
    }

    openPopup = (e) => {
        // console.log("refundItems", this.state.refundItems);
        // console.log("allRefunds", this.state.allRefunds);
        if(this.state.refundItems.length > 0){
            var newRefunds = this.state.refundItems;
            var notapproved = 0;
            // for(var i = 0; i < this.state.allRefunds.length; i++) {
            //     var row = this.state.allRefunds[i];
            //     if(this.state.refundItems.includes(row[30]) && row[23] == "Approved"){
            //         newRefunds.push(row[30]);
            //     } else {
            //         notapproved++;
            //     }
            // }

            if(newRefunds.length == 0){
                swal("Error", "Please select at least one Approved refund to process", "error");
                return false;
            }

            // console.log({newRefunds});
            this.setState({
                newRefundItems: [...newRefunds],
                isopen: true
            });
        } else {
            swal("Error", "Please select at least one refund to process", "error");
        }
        
    }

    processRefunds = (e) => {
        e.preventDefault();
        this.setState({
            disablesubmitbtn: true,
            submitbtntext: "Updating..."
        });

        NewRefundReportsService.bulkProcessRefunds(this.state.newRefundItems, this.state.agentName, this.state.refundMethod, this.state.refundDate, this.state.refundReference, this.state.comments)
        .then(response => {
            // console.log(response.data);
            var notapproved = parseInt(response.data);
            this.props.onChange();
            if(notapproved > 0){
                swal("Success", "Refunds processed successfully. However, "+notapproved+" refunds were not processed because they are not approved.", "success");
            } else {
                swal("Success", "Refunds processed successfully", "success");
            }

            this.setState({
                disablesubmitbtn: false,
                submitbtntext: "Update",
                notapproved: 0,
                isopen: false
            });
        }).catch(error => {
            console.log(error);
        });
    }

    render() {
        const style = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            overflowY: "scroll"
        };
        return (
            <>
            <button type="button" className="btn btn-s btn-primary" onClick={e => this.openPopup(e)}>Bulk Processing</button>
            <Modal
                open={this.state.isopen}
                onClose={e => {this.setState({isopen: false})}}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{...style, width: 800, textAlign: 'center' }}>
                <form onSubmit={(e) => this.processRefunds(e)} style={{textAlign: "left"}}>
                    <div className="row">
                        <div className="col supplier-confirm-phone-heading">
                            Refund Processing will be executed on "{this.state.newRefundItems.length}" Items
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="agent_name" className="col-4 col-form-label"><b>Agent Name</b></label>
                        <div className="col-8"> 
                            <input type="text" className="form-control" id="agent_name" placeholder="Enter Agent Name" value={this.state.agentName} onChange={e => this.setState({agentName: e.target.value})} required="required" />
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="refund_method" className="col-4 col-form-label"><b>Refund Method</b></label>
                        <div className="col-8"> 
                            <input type="text" className="form-control" id="refund_method" placeholder="Enter Refund Method" value={this.state.refundMethod} onChange={e => this.setState({refundMethod: e.target.value})} required="required" />
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="refund_date" className="col-4 col-form-label"><b>Refund Date</b></label>
                        <div className="col-8"> 
                            <DatePicker
                                dateFormat="dd-MM-yyyy"
                                selected={this.state.refundDate}
                                onChange={date => this.setState({refundDate: date})}
                                placeholderText="Processing Date"
                                className='form-control'
                                style={{width: "100%"}}
                            />
                            {/* <input type="text" className="form-control date-picker" id="refund_date" placeholder="Enter Refund Date" value={this.state.refundDate} onChange={e => this.setState({refundDate: e.target.value})} required="required" /> */}
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="refund_reference" className="col-4 col-form-label"><b>Refund Reference</b></label>
                        <div className="col-8"> 
                            <input type="text" className="form-control" id="refund_reference" placeholder="Enter Refund Reference" value={this.state.refundReference} onChange={e => this.setState({refundReference: e.target.value})} required="required" />
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="totalAmountPaid" className="col-4 col-form-label"><b>Comments</b></label>
                        <div className="col-8"> 
                            <textarea className="form-control" placeholder="Enter any additional information" value={this.state.comments} onChange={e => this.setState({comments: e.target.value})} />
                        </div>
                    </div>

                    <div style={{display: "flex", justifyContent: "space-between", marginTop: "40px"}}>
                        <button type="submit" className="btn btn-sm btn-success" style={{marginRight: "20px"}} disabled={this.state.disablesubmitbtn}>{this.state.submitbtntext}</button><button type="button" className="btn btn-sm btn-danger" onClick={e => {this.setState({isopen: false})}}>Close</button>
                    </div>
                </form>
                </Box>
            </Modal>
            </>
        );
    }
}