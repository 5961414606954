import React, { Component } from "react";
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { style } from "@mui/system";
import swal from "sweetalert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExchangeAlt, faTicketAlt, faTimes } from "@fortawesome/free-solid-svg-icons";

import OrdersReportService from "../../services/orders/ordersreport.service";

export default class TransferPoints extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderid: props.orderid,
            orderOwner: props.userid,
            customerid: props.customerid,
            isopen: false,
            userRoles: [],
            newEmailAddress: "",
            user: props.user,
            ownerName: props.ownerName,
            btnTransferText: 'Transfer Order',
            btnTransferDisabled: false
        }
    }

    componentDidMount() {
        this.getUserRoles();
    }

    getUserRoles = () => {
        OrdersReportService.getOrderUserRoles(this.state.orderOwner)
        .then(response => {
            // console.log("user roles", response);
            this.setState({
                userRoles: response.data
            });
        })
        .catch(error => console.log({error}));
    }

    checkRoles = () => {
        if(this.state.userRoles.length>0) {
            if(this.state.userRoles.includes("administrator") || this.state.userRoles.includes("customerserviceagents")) {
                return true;
            }
        } else {
            return false;
        }
    }

    openPopup = (e) => {
        this.setState({
            isopen: true
        });
    }

    closePopup = (e) => {
        this.setState({
            isopen: false
        });
    }

    transferToCustomer = (e) => {
        e.preventDefault();
        if(this.state.newEmailAddress=="") {
            swal('Email Required!', "Please enter the email for the customer", "error");
        } else {
            this.setState({
                btnTransferText: 'Transfering...',
                btnTransferDisabled: true
            });
            OrdersReportService.transferOrder(this.state.orderid, this.state.newEmailAddress, this.state.user.user_nicename, this.state.orderOwner, this.state.ownerName)
            .then(response => {
                // console.log("transferToCustomer", response);
                if(response.data!='error') {
                    swal("Order Transfered", "Order has been transfered to the customer: "+this.state.newEmailAddress, "success");
                    this.setState({
                        orderOwner: response.data,
                        customerid: response.data,
                        newEmailAddress: "",
                        isopen: false,
                        btnTransferText: 'Transfer Order',
                        btnTransferDisabled: false
                    });
                    this.getUserRoles();
                    this.props.onChange();
                } else {
                    swal("Invalid Customer", "Please enter a valid customer email or create a new customer on CRM screen", "error");
                }
            })
            .catch(error => console.log({error}));
        }
    }

    render() {
        const style = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        };
        return (
            <>
            {this.checkRoles() && this.state.userRoles.length>0 && 
            <>
            <button type="button" className="btn btn-sm btn-success" onClick={e => this.openPopup(e)}>Transfer Order</button>
            <Modal
                open={this.state.isopen}
                onClose={e => {this.setState({isopen: false})}}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{...style, width: 800, textAlign: 'center' }}>
                    <div className="row">
                        <div className="col supplier-confirm-phone-heading">
                            Transfer Order to a Customer Account
                        </div>
                    </div>

                    <div className="form-group">
                        <label for="newEmailAddress"><b>Customer's Email Address</b></label>
                        <input type="email" className="form-control" vlaue={this.state.newEmailAddress} onChange={e => {this.setState({newEmailAddress: e.target.value})}} id="newEmailAddress" aria-describedby="emailHelp" placeholder="Enter Customer Email" />
                        <small id="emailHelp" className="form-text text-muted">Please enter the email of customer account to transfer the order</small>
                    </div>

                    <div className="row">
                        <div className="col" style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <button className="btn btn-sm btn-success" disabled={this.state.newEmailAddress=="" || this.state.btnTransferDisabled} onClick={e => this.transferToCustomer(e)}><FontAwesomeIcon icon={faExchangeAlt} /> {this.state.btnTransferText}</button>
                            <button className="btn btn-sm btn-danger" onClick={e => this.closePopup(e)}><FontAwesomeIcon icon={faTimes} /> Close</button>
                        </div>
                    </div>
                </Box>
            </Modal>
            </>
            }
            {!this.checkRoles() && this.state.userRoles.length>0 &&
            <b>
                Cannot Transfer this Order
            </b>
            }

            {this.state.userRoles.length<1 && 
                <b>Loading....</b>
            }
            </>
        );
    }
}