import React, { Component } from "react";
import Loader from 'react-loader-spinner';
import swal from 'sweetalert';
import readXlsxFile from 'read-excel-file';
import axios from 'axios';

import AuthService from '../services/auth/auth.service';

import UserContext from "../contexts/UserContext";
import NoPermission from "./extras/nopermission.component";

const APIURL = "https://deals.mu/scripts/reportsapi/juiceautomation.php";

export default class JuiceAutomation extends Component {
    static contextType = UserContext
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            show: false,
            user: [],
            allowed: true,
            loading: '',
        };
    }

    processData() {
        const input = document.getElementById('input')
        input.addEventListener('change', () => {
            readXlsxFile(input.files[0]).then((rows) => {
                // setloading('Loading...');
                this.setState({
                    loading: 'Loading...',
                    loadingvalues: true
                });
                console.log(rows);

                var action = "juiceautomation";
                var params = {
                    action: action,
                    rows: rows
                }
                axios.post(APIURL+"?rand="+Math.random(), {params})
                .then(response => {
                    console.log(response.data);
                    this.setState({
                        data: response.data,
                        loading: '',
                        loadingvalues: false
                    });
                    // setloading('');
                    // setData(response.data);
                })
                .catch(err => console.log(err));
            });
        });
    }

    componentDidMount() {
        AuthService.checklogin().then(user => {
            this.setState({
                user: user
            });
           AuthService.getUserPermisions(user)
            .then(response => {
                var userpermissions = response.data;
                console.log({userpermissions});
                    if(userpermissions.includes('orders') || userpermissions.includes('all')) {
                    console.log('You are allowed!');
                    this.setState({
                        allowed: true
                    });
                    this.processData();
                } else {
                    console.log('You are not allowed!');
                    this.setState({
                        allowed: false
                    });
                }
            })

            console.log({user});
        }).catch(error => {
            window.location.href = '/login';
        });
    }


    render() {
        var { data, columnsheadings, options, show, error, loadingvalues } = this.state;

        if (this.state.allowed == false) {
          return <NoPermission />;
        }
        
        return (
            <>
                <div className="row">
                    <div className='col'>
                        <div style={{display:"flex", justifyContent:"space-between"}}>
                            <h2>Juice / Bank Automation</h2>
                            <div>
                                <small style={{marginRight: "15px", fontSize: "18px", color: "red", fontWeight:"bold"}}>{this.state.loading}</small>
                                <input className="btn btn-primary bg-blue-500 hover:bg-blue-600 rounded-none font-semibold" type="file" id="input" />
                                <button className="btn btn-warning ml-4 bg-orange-500 hover:bg-orange-600 rounded-none font-semibold" onClick={(e) => window.location.reload()}>Reset</button>
                            </div>
                        </div>
                        <hr />
                    </div>
                </div>
                <div className="row">
                    <div className='col'>
                        {loadingvalues == true &&
                            <p style={{ color: "#df153b" }}><b><i>Loading Orders. Please Wait....</i></b></p>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className='col'>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Order ID</th>
                                    <th scope="col">Sheet Amount</th>
                                    <th scope="col">Order Total</th>
                                    <th scope="col" className="text-center">Method</th>
                                    <th scope="col" className="text-center">Order Status</th>
                                    <th scope="col" className="text-center">Customer</th>
                                    <th scope="col" className="text-center">Email</th>
                                    <th scope="col" className="text-center">Action</th>
                                    <th scope="col" className="text-center">Message</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.length>0 && data.map((item, index) => (
                                <tr key={index}>
                                    <td><a className="color-orange break-words font-bold" href={"/edit-order/"+item.order_id} taget="_blank">#{item.order_id}</a></td>
                                    <td>Rs {item.amount}</td>
                                    <td>Rs {item.order_total}</td>
                                    <td>{item.payment_method}</td>
                                    <td>{item.order_status}</td>
                                    <td>{item.customer_name}</td>
                                    <td>{item.email}</td>
                                    <td>
                                        {item.order_status == "Pending" && item.order_total == item.amount && <a href={"/edit-order/"+item.order_id} target="_blank" className="btn btn-sm btn-danger">View</a>}
                                        {(item.order_status == "Processing" || item.order_status == "Completed") && item.order_total == item.amount && <span className="text-green-600 font-bold" style={{color: "green", fontWeight:"bold"}}>Processing</span>}
                                        {(item.order_status == "Cancelled") && <span className="text-red-600 font-bold" style={{color: "red", fontWeight:"bold"}}>Cancelled</span>}
                                        {(item.order_total != item.amount && item.order_status != "" && item.order_status != "Cancelled") && <span className="text-fuchsia-600 font-bold" style={{color: "purple", fontWeight:"bold"}}>Invalid</span>}
                                        {(item.order_status == "") && <span className="text-blue-600 font-bold" style={{color: "blue", fontWeight:"bold"}}>Not Found</span>}
                                    </td>
                                    <td>{item.transaction} --- {item.message}</td>
                                </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

            </>
        );
    }
}