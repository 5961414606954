import axios from "axios";
import chalk from 'chalk';

const API_URL = "https://deals.mu/wp-json/jwt-auth/v1/token";

class AuthService {
    login(username, password) {
        return axios
            .post(API_URL, { username, password })
            .then((response) => {
                console.log({ response});

                if (response.data.token && (this.checkExists(response.data.user_role, 'administrator') || this.checkExists(response.data.user_role, 'erp'))) {
                    localStorage.removeItem("user");
                    console.log(response.data);
                    localStorage.setItem("user", JSON.stringify(response.data));
                    console.log('I am here in service');
                } else {
                    localStorage.removeItem("user");
                    return false;
                }
                // console.log('I am here in service');
                return response;
            }).catch( error => {
                localStorage.removeItem("user");
                return false;
            });
    }

    checkExists(haystack, needle) {
        for (let k in haystack) {
            if (haystack[k] === needle) {
                return true;
            }
        }

        return false;
    }

    logout() {
        localStorage.removeItem("user");
    }

    register(username, email, password) {
        return axios.post(API_URL, {
            username,
            email,
            password,
        });
    }

    checklogin() {
       return new Promise(function (resolve, reject) {
           var user = localStorage.getItem("user");
        //    console.log({user});
           if(user) {
               resolve(JSON.parse(user));
           } else{
               reject(false);
           }
        });
    }

    getUserPermisions(user) {

        return axios.post('https://deals.mu/scripts/reportsapi/userpermissions.php', {
            username : user.user_nicename,
            action: 'get_permissions'
        })
        .then(response => {
            return response;
        })
        .catch(error => {
            console.log({error});
        });
    }

    async getUserPermisionsList(user) {

        return axios
          .post("https://deals.mu/scripts/reportsapi/userpermissions.php", {
            username: user.user_nicename,
            action: "get_permissions",
          })
          .then((response) => response.data);
    }
}

export default new AuthService();