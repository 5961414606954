import React, { Component } from "react";
import swal from 'sweetalert';
import AuthService from '../services/auth/auth.service';
import InvoicesService from '../services/erp/invoices.service';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import UserContext from "../contexts/UserContext";
import NoPermission from "./extras/nopermission.component";
export default class InvoiceEmails extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);

        this.state = {
            invoiceNumber: 0,
            amount_paid: 0,
            payment_ref: '',
            mode_of_pay: '',
            date_of_payment: '',
            email_addresses: [],
            selected_email_address: [],
            email_text:'',
            email_addresses_options: '',
            table_data: [],
            email_address_id: 0,
            allowed: true
        }
    }

    componentDidMount() {
        setTimeout(() => {
            if (typeof this.props.params.invoicenumber == 'undefined') {
                this.setState({
                    invoiceNumber: 0
                });
            } else {
                this.setState({
                    invoiceNumber: this.props.params.invoicenumber
                });
                if (this.state.invoiceNumber != 0) {
                    this.fetchInvoiceData();
                }
            }
        }, 2000);

        AuthService.checklogin().then(user => {
            AuthService.getUserPermisions(user)
            .then(response => {
                var userpermissions = response.data;
                console.log({userpermissions});
                    if(userpermissions.includes('invoiceemails') || userpermissions.includes('all')) {
                    console.log('You are allowed!');
                    this.setState({
                        allowed: true
                    });
                } else {
                    console.log('You are not allowed!');
                    this.setState({
                        allowed: false
                    });
                }
            })
        }).catch(error => {

            window.location.href = '/login';
        });
    }

    fetchInvoiceData() {
        InvoicesService.getEmailData(this.state.invoiceNumber)
        .then(response => {
            console.log({ response});
            var email_data = response.data;
            console.log(email_data.emails);
            this.setState({
                amount_paid: email_data.invoice.amount_paid,
                payment_ref: email_data.invoice.payment_referrence,
                mode_of_pay: email_data.invoice.mode_of_payment,
                date_of_payment: email_data.invoice.payment_date,
                email_addresses: email_data.emails,
                email_addresses_options: email_data.emails.map(item => {
                                            return <option key={item} value={item}>{item}</option>;
                                        }),
                email_text: email_data.email_text,
                table_data: email_data.table_data,
                email_address_id: email_data.email_addresses_id,
            });
 
        })
        .catch(error => {
            console.log({error});
        });
    }

    handleEmailsChange = (e) => {
        let value = Array.from(e.target.selectedOptions, option => option.value);
        this.setState({ selected_email_address: value });
        // console.log(this.state.selected_email_address);
    }

    onChangeEditor = editorState => this.setState({ email_text: editorState });

    deleteEmail = (email, id) => {
        // console.log({email});
        InvoicesService.deleteEmail(email, id)
            .then(response => {
                this.fetchInvoiceData();
                console.log({response});
                swal({
                    title: "Emails Deleted!",
                    text: "The email has been deleted! Please wait while emails are refreshing....",
                    icon: "success",
                });
            })
            .catch(error => {
                console.log({error});
            });
    }

    sendemail = () => {
        if(this.state.selected_email_address.length<1) {
            swal({
                title: "Select Emails!",
                text: "Select Email Addresses to send emails to!",
                icon: "error",
            });
        } else {

            InvoicesService.sendEmail(this.state)
            .then(response => {
                console.log({response});
                swal({
                    title: "Emails Sent!",
                    text: "The email has been sent to selected Email Addresses!",
                    icon: "success",
                });
            })
            .catch(error => {
                console.log({error});
            });
        }
    }

    render() {
        let removeemails = this.state.email_addresses.map((email, i) => {

        if (this.state.allowed == false) {
          return <NoPermission />;
        }
        
        return (
            <div style={{display:"flex", justifyContent: 'space-between', marginBottom: "20px"}} key={i}>
                <span>{email}</span>
                <button className="btn btn-sm btn-danger remove-emails" data-id={this.state.email_address_id} onClick={() => this.deleteEmail(email, this.state.email_address_id)}>Delete</button>
            </div>
        );
        });
        return(
            <>
                <div className='row'>
                    <div className='col-9'>
                        <h4>Review Email for invoice # {this.state.invoiceNumber}</h4>
                    </div>
                    <div className='col-3'>
                        <button className='btn btn-success' onClick={this.sendemail}>I Reviewed and Approve, Send Email</button>
                    </div>
                    <div className='col-12'> <hr /></div>
                </div>
                <div className='row' style={{ padding: '20px 49px', backgroundColor: 'rgb(242 243 229)', width: '90%', margin: '0 auto' }} >
                    <div className='col-12'>
                        <p><b>Important:</b> Please review email before sending.</p>
                        <h3><u>Payment Details:</u></h3>
                        <p><b>Amount Paid :</b> {this.state.amount_paid} MUR</p>

                        <p><b>Payment Reference :</b> {this.state.payment_ref} </p>

                        <p><b>Mode of Payment :</b> {this.state.mode_of_pay }</p>

                        <p><b>Date of Payment :</b> {this.state.date_of_payment }</p>

                        <p><b>Email Addresses</b> (Use Ctrl+click to select multiples):</p>
                        <p>
                            <select className='form-control' onChange={this.handleEmailsChange} multiple='multiple' style={{ width: '500px', height:'200px' }}>
                                {this.state.email_addresses_options}
                            </select>

                            <br />
                            <button type='button' className='btn btn-primary btn-sm' data-toggle="modal" data-target="#addemailmodal">Add Email Address</button>
                            <button style={{marginLeft: "20px"}} type='button' className='btn btn-danger btn-sm' data-toggle="modal" data-target="#removeemailmodal">Remove Email Address</button>

                            <div className="modal" id="addemailmodal">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                         <div className="modal-header">
                                            <h4 className="modal-title">Add Email Address</h4>
                                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                                        </div>
                                        <form className='addemailform'> 
                                            <div className="modal-body">  
                                                <div className="form-group">
                                                    <label for="email">Email address:</label>
                                                    <input type="email" className="form-control" id="addemail-email" placeholder='Enter email address' 
                                                    required='required' />
                                                    <input type="hidden" className="form-control" id="addemail-invoice" 
                                                        value={this.state.invoiceNumber} />
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="submit" className="btn btn-success saveemail">Save</button>
                                                <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div className="modal" id="removeemailmodal">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                         <div className="modal-header">
                                            <h4 className="modal-title">Remove Email Address</h4>
                                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                                        </div>
                                        <div className="modal-body">  
                                            <div className="form-group">
                                                {removeemails}
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </p>
                        <p><b>Email to Send:</b></p>
                        <p>
                            <ReactQuill value={this.state.email_text}
                                onChange={this.onChangeEditor} />
                            {/* <textarea className='form-control' value={this.state.email_text } style={{ width: '800px', height: '200px' }}>{this.state.email_text}</textarea> */}
                        </p>

                        <p><b>Following CSV will be attached:</b></p>
                        <p>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Invoice No</th>
                                        <th>Voucher</th>
                                        <th>Booking Dates</th>
                                        <th>Customer</th>
                                        <th>Product</th>
                                        <th>Booking</th>
                                        <th>Payable</th>
                                        <th>SOA Sub-Invoice Number</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.table_data.map((listValue, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{listValue[0]}</td>
                                                <td>{listValue[1]}</td>
                                                <td>{listValue[2]}</td>
                                                <td>{listValue[3]}</td>
                                                <td>{listValue[4]}</td>
                                                <td>{listValue[5]}</td>
                                                <td>{listValue[6]}</td>
                                                <td>{listValue[7]}</td>
                                                <td>{listValue[8]}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </p>
                    </div>
                </div>
            </>
        );
    }
}