import React, { Component } from "react";
import swal from "sweetalert";
import FollowUpList from "./followuplist.component";
import OrdersReportService from "../../services/orders/ordersreport.service";

export default class AddReminder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderid: props.orderid,
            datetime: "",
            reminder: "",
            addReminderBtnText: "Add",
            addReminderBtnDisable: false,
            rerender: false,
            username: props.username,
            // mainrerender: props.render,
        }
    }

    submitReminder = (e) => {
        e.preventDefault();
        this.setState({
            addReminderBtnText: "Adding...",
            addReminderBtnDisable: true
        });

        OrdersReportService.addFollowup(this.state.orderid, this.state.datetime, this.state.reminder, this.state.username)
        .then(response => {
            this.setState({
                addReminderBtnText: "Add",
                addReminderBtnDisable: false,
                datetime: "",
                reminder: "",
                rerender: !this.state.rerender
            });
            this.props.onChange();
            swal('Reminder Added', "A followup reminder has been added!", "success");
            // this.props.onChange();
        })
        .catch(err => console.log({err}));
    }

    render() {
        return (
            <div className="add-reminder-row">
                <div className="row">
                    <div className="col">
                        <h5 style={{marginBottom: "10px", textDecoration: "underline"}}>Add a Reminder</h5>

                        <form onSubmit={e=>this.submitReminder(e)} className="form-inline reminder-form">
                            <div className="form-group" style={{width: "500px"}}>
                                <textarea className="form-control" rows="4" value={this.state.reminder} onChange={e=>{this.setState({reminder: e.target.value})}} placeholder="Add the reminder description here" required="required" style={{width: "100%"}}></textarea>
                            </div>
                            <div className="form-group">
                                <input type="datetime-local" value={this.state.datetime}  onChange={e=>{this.setState({datetime: e.target.value})}} className="form-control" placeholder="select date and time" required="required" />
                            </div>
                            <div className="form-group">
                                <button type="submit" className="btn btn-success" disabled={this.state.addReminderBtnDisable}>{this.state.addReminderBtnText}</button>
                            </div>
                        </form>
                    </div>
                </div>

                <FollowUpList loadnow={true} rerender={this.state.rerender} username={this.state.username} orderid={this.state.orderid} />
            </div>
            
        );
    }
}