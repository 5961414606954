import axios from "axios";
import { uniq } from 'lodash';

const API_URL = "https://deals.mu/scripts/reportsapi/refundreports-new.php";

class NewRefundReportsService {
    getRefunds(type, startDate, endDate, company, supplier, product, status, user_email, creationtype="", urgency="", search="", selectedRows=[]) {
       return this.getResults(0, type, startDate, endDate, company, supplier, product, status, user_email, creationtype, urgency, search, selectedRows)
            .then(response => {
                // console.log({response});

                var result = {
                    data: []
                };
                let promises = [];
                var size = parseFloat(response.data);

                var loopSize = Math.ceil(parseFloat(size / 20));
                console.log({ loopSize })

                for (var i = 1; i <= loopSize; i++) {

                    promises.push(
                        this.getResults(i, type, startDate, endDate, company, supplier, product, status, user_email, creationtype, urgency, search, selectedRows)
                            .then(response1 => {

                                result.data = result.data.concat(response1.data);
                            })
                            .catch(error1 => {
                                console.log({ error1 });
                            })
                    );
                }

                return Promise.all(promises).then(() => {
                    // console.log({result});
                    return result;
                });

            }).catch(error => {

                return false;
            });
    }

    getResults(page, type, startDate, endDate, company, supplier, product, status, user_email, creationtype="all", urgency="all", search="", selectedRows=[] ) {
        var params = {
            action: 'refunds',
            page: page,
            type: type,
            startDate: startDate,
            endDate: endDate,
            company: company,
            supplier: supplier,
            product: product,
            status: status,
            user_email: user_email,
            creationtype: creationtype,
            urgency: urgency,
            search: search,
            selectedRows: selectedRows
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

    getLoyaltyCoupons(page, limit, search, type, orderStatus) {
        var params = {
            action: 'loyaltyReports',
            page: page,
            limit: limit,
            search: search,
            type: type,
            orderStatus: orderStatus
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

    createLoyaltyCoupon(createCouponCustomerName, createCouponCode, createCouponPoints, createCouponExpiry, createCouponIndex, createCouponID, createCouponEmail) {
        var params = {
            action: 'createLoyaltyCoupon',
            createCouponCustomerName: createCouponCustomerName,
            createCouponCode: createCouponCode,
            createCouponPoints: createCouponPoints,
            createCouponExpiry: createCouponExpiry,
            createCouponIndex: createCouponIndex,
            createCouponID: createCouponID,
            createCouponEmail: createCouponEmail
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

    deleteLoyaltyCoupon(deleteCouponID, refund_id) {
        var params = {
            action: 'deleteLoyaltyCoupon',
            deleteCouponID: deleteCouponID,
            refund_id: refund_id
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

    getOrderVeryBasic(order_id) {
        var params = {
            action: 'getOrderBasics',
            order_id: order_id
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

    updateBankDetailsN(refundid, bank, account_name, account_num) {
        var params = {
            action: 'updateBankDetails',
            refundid: refundid,
            bank: bank,
            account_name: account_name,
            account_num: account_num
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

    sendBankDetailsRequestN(refund_id, order_id, email) {
        var params = {
            action: 'sendBankDetailsRequest',
            refund_id: refund_id,
            order_id: order_id,
            email: email
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

    bulkProcessRefunds(newRefundItems, agentName, refundMethod, refundDate, refundReference, comments) {
        var params = {
            action: 'bulkProcessRefunds',
            refund_ids: newRefundItems,
            agentName: agentName,
            refundMethod: refundMethod,
            refundDate: refundDate,
            refundReference: refundReference,
            comments: comments
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });        
    }

    getRefundComments(refundID) {
        var params = {
            action: 'getRefundComments',
            refund_id: refundID,
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });        
    }

    addRefundComment(refundID, comment, agent) {
        var params = {
            action: 'addRefundComment',
            refund_id: refundID,
            comment: comment,
            agent: agent
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });        
    }

}

export default new NewRefundReportsService();