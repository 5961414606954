import React, { Component } from "react";
import OrdersReportService from "../../services/orders/ordersreport.service";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

export default class OrderNotes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderid: props.orderid,
            notes: null,
            newNote: "",
            btnText: "Add Note",
            disabled: false,
            user: props.user
        }
    }

    componentDidMount() {
        this.getNotes();
    }

    getNotes = () => {
        OrdersReportService.getOrderNotes(this.state.orderid)
        .then(response => {
            // console.log("order notes", response);
            this.setState({
                notes: response.data
            })
        })
        .catch(err => console.log({err}));
    }

    submitNote = (e) => {
        e.preventDefault();
        this.setState({
            disabled: true,
            btnText: "Adding Note....",
        });

        // console.log("add note state", this.state);

        OrdersReportService.addOrderNote(this.state.orderid, this.state.newNote, this.state.user.user_nicename)
        .then(response => {
            this.setState({
                disabled: false,
                btnText: "add Note",
            });
            this.setState({newNote: ""});
            this.getNotes();
            this.props.onAddNote();
        })
        .catch(error=>console.log(error));
    }

    render() {
        return (
            <div className="add-reminder-row">
                <div className="row">
                    <div className="col">
                        <h5 style={{marginBottom: "10px", textDecoration: "underline"}}>Order Notes</h5>

                        <form onSubmit={e=>this.submitNote(e)} className="form-inline reminder-form">
                            <div className="form-group" style={{width: "500px"}}>
                                <textarea className="form-control" value={this.state.newNote} onChange={e => {this.setState({newNote: e.target.value})}} rows="4" placeholder="Add the note here" required="required" style={{width: "100%"}}></textarea>
                            </div>
                            <div className="form-group">
                                <button type="submit" className="btn btn-success" disabled={this.state.disabled}>{this.state.btnText}</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <List>
                            {this.state.notes!=null && this.state.notes.map((note, index) => (
                            <ListItem disablePadding key={index} style={{ backgroundColor: "#f3f3f3", marginBottom: "5px", padding: "0px 10px" }}>
                                <ListItemText primary={<div dangerouslySetInnerHTML={{__html: note.comment_content}} />} secondary={"By: "+note.comment_author_email+ " - at: "+ note.comment_date} />
                            </ListItem>
                            ))}
                        </List>
                    </div>
                </div>
            </div>
            
        );
    }
}