import React, { Component } from "react";
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { style } from "@mui/system";
import swal from "sweetalert";

import OrdersReportService from "../../services/orders/ordersreport.service";

export default class UpdateVoucherDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderid: props.orderid,
            itemid: props.itemid,
            rerender: false,
            btntext: "Submit",
            disablebtn: false,
            isopen: false,
            time: props.time,
            location: props.location,
            contacts: props.contacts,
            user: props.user
        };
    }

    openPopup(e) {
        e.preventDefault();
        this.setState({
            isopen: true
        });
    }

    updateVoucherDetailsForm = (e) => {
        e.preventDefault();
        this.setState({
            btntext: "Submiting..",
            disablebtn: true,
        });
        OrdersReportService.updateVoucherDetails(this.state.orderid, this.state.itemid, this.state.time, this.state.location, this.state.contacts, this.state.user.user_nicename)
        .then(response => {
            // console.log({response});
            this.setState({
                isopen: false,
                btntext: "Submit",
                disablebtn: false,
            });

            this.props.onChange(null, this.props.itemIndex);

            swal("Voucher Details Updated!", "The voucher details has been updated!", "success");
        })
        .catch(error => {
            console.log({error});
        });
    }

    render() {
        const style = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        };
        return (
            <>
            <button type="button" className="btn btn-link btn-sm" onClick={e => this.openPopup(e)}>Update Voucher Details</button>
            <p style={{fontSize: "11px"}}>( Activity Times, Location Details, Contact Details )</p>
            <Modal
                open={this.state.isopen}
                onClose={e => {this.setState({isopen: false})}}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{...style, width: 600, textAlign: 'center' }}>
                <form onSubmit={(e) => this.updateVoucherDetailsForm(e)} style={{textAlign: "left"}}>
                    <div className="row">
                        <div className="col supplier-confirm-phone-heading">
                            Update Voucher Details
                        </div>
                    </div>
                     <div className="form-group">
                        <label for="time" className="col-form-label"><b>Activity Time</b></label>
                        <div className="">
                            <input type="time" className="form-control" onChange={e => {this.setState({time: e.target.value})}} id="time" placeholder="Choose Time" required="required" value={this.state.time} />
                        </div>
                    </div>
                    <div className="form-group" style={{marginBottom: "20px"}}>
                        <label for="location" className="col-form-label"><b>Location Details</b></label>
                        <div className=""> 
                            <textarea className="form-control" rows="5" id="location" placeholder="Enter location details" required="required" onChange={e => {this.setState({location: e.target.value})}} value={this.state.location} ></textarea>
                        </div>
                    </div>
                    <div className="form-group" style={{marginBottom: "20px"}}>
                        <label for="contacts" className="col-form-label"><b>Contact Details</b></label>
                        <div className=""> 
                            <textarea className="form-control" rows="5" id="contacts" placeholder="Enter contact details" required="required" onChange={e => {this.setState({contacts: e.target.value})}} value={this.state.contacts} ></textarea>
                        </div>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", marginTop: "40px"}}>
                        <button type="submit" className="btn btn-sm btn-success" style={{marginRight: "20px"}} disabled={this.state.disablebtn}>{this.state.btntext}</button><button type="button" className="btn btn-sm btn-danger" onClick={e => {this.setState({isopen: false})}}>Close</button>
                    </div>
                </form>
                </Box>
            </Modal>
            </>
        );
    }
}