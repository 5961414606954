import React, { Component } from "react";
import Loader from 'react-loader-spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGift, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import swal from 'sweetalert';
import Papa from 'papaparse';
import axios from "axios";
import { SketchPicker   } from 'react-color';

import DTable2 from './dtable2.component';
import NoPermission from "./extras/nopermission.component";
import AuthService from '../services/auth/auth.service';
import ChooseGiftCardImage from "./giftcards/chooseimage.component";
import GiftCardsService from "../services/cms/giftcards.service";
export default class GiftCardGenerator extends Component {

    constructor(props){
        super(props);

        this.state = {
            user: [],
            data: [],
            show: false,
            showloader: true,
            user_id: 0,
            allowed: true,
            columnsheadings: [
                { title: "ID" },
                { title: "Created On" },
                { title: "Order Details" },
                { title: "Receipient Name" },
                { title: "Rceipient Email Address" },
                { title: "Gift Card Code" },
                { title: "Gift Card Amount" },
                { title: "Gift Card Balance" },
                { title: "Expiration Date" },
                { title: "Status" },
                { title: "Actions" },
            ],
            generatecurrentstep : 1,
            uploadcsvfile: '',
            uploadedcsvdata: [],
            genGroupName: '',
            genTotalRecipients: '',
            genTotalAmount: '',
            genCustomerPhone: '',
            genCustomerName: '',
            genCustomerEmail: '',
            genChooseImage: null,
            genUploadLogo: null,
            genCardTerms: '',
            genTotalAmountPaid: '',
            selectedImage: '',
            selectedLogo: '',
            genAmountPaid: '',
            searchText: '',
            searchStatus: "0",
            step1error: false,
            step2error: false,
            step3error: false,
            step4error: false,
            step5error: false,
            selectedLogoSrc: "",
            selectedImgSrc: "",
            nameBackgroundColor: "0079de",
            codeBackgroundColor: "ffffff",
            expiryBackgroundColor: "ea2749"
        };
    }

    componentDidMount() {
        AuthService.checklogin().then(user => {
            this.setState({
                user: user
            });
            // console.log({ user });

            AuthService.getUserPermisions(user)
            .then(response => {
                var userpermissions = response.data;
                console.log({userpermissions});
                if(userpermissions.includes('giftcardsgenerator') || userpermissions.includes('all')) {
                    console.log('You are allowed!');
                    this.setState({
                        allowed: true
                    });
                    this.searchCards(null);
                } else {
                    console.log('You are not allowed!');
                    this.setState({
                        allowed: false
                    });
                }
            })
        }).catch(error => {
            window.location.href = '/login';
        });
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleImageChange(event) {
        this.setState({
            [event.target.name]: event.target.files[0],
            uploadedcsvdata: []
        });
    }

    handleFileChange(event) {
        this.setState({
            [event.target.name]: event.target.files[0],
            uploadedcsvdata: []
        });

        let self = this;

        Papa.parse(event.target.files[0], {
            header: true,
            dynamicTyping: true,
            complete: function(results) {
                console.log(results);
                var totalamount = 0;
                var totalRecipients = 0;

                if(results.data.length>0) {
                    for(var i = 0; i<results.data.length; i++) {
                        if('Gift Card Amount' in results.data[i]) {
                            if(parseFloat(results.data[i]['Gift Card Amount'])>0) {
                                totalamount = totalamount + parseFloat(results.data[i]['Gift Card Amount']);
                                totalRecipients = totalRecipients+1;
                            }

                            if(results.data[i]['Gift Card Code']=="" || results.data[i]['Gift Card Code']==null) {
                                results.data[i]['Gift Card Code'] = results.data[i]['Receipient Name'] + Math.floor(Math.random() * 90 + 10);
                            }
                        }
                    }
                }

                self.setState({
                    genTotalAmount: totalamount,
                    genTotalRecipients: totalRecipients,
                    uploadedcsvdata: results.data
                });
            }
        });

    }

    async uploadImagesStep(e) {
        e.preventDefault();

        if((this.state.genChooseImage==null && this.state.selectedImage=="")) {
            this.setState({
                step3error: true
            });
        } else {
            await this.uploadFile(this.state.genUploadLogo)
            .then(resp => {
                console.log("Uploaded image", resp);
                this.setState({
                    selectedLogo: resp.data,
                    selectedLogoSrc: resp.data,
                    step3error: false
                });
                this.goToStepFour();
            })
            .catch(error => console.log({error}));
    
            await this.uploadFile(this.state.genChooseImage)
            .then(resp => {
                console.log('uploaded background', resp);
                this.setState({
                    selectedImage: resp.data,
                    selectedImgSrc: resp.data,
                    step3error: false
                });
    
                this.goToStepFour();
            })
            .catch(error => console.log({error}));
        }
    }

    goToStepFour() {
        if(this.state.selectedImage!='' || this.state.genChooseImage!=null) {
            this.setState({
                generatecurrentstep: 4,
                step3error: false
            });
        } else {
            this.setState({
                generatecurrentstep: 3,
                step3error: true
            });
        }
    }

    async generateGiftCards(e) {
        e.preventDefault();
        console.log('Submittted');

        this.setState({
            generatecurrentstep: 5
        });

        await GiftCardsService.createOrder(this.state.genGroupName, this.state.genTotalRecipients, this.state.genTotalAmount, this.state.uploadedcsvdata, this.state.genCustomerEmail, this.state.genCustomerName, this.state.genCustomerPhone, this.state.genAmountPaid, this.state.selectedImage, this.state.selectedLogo, this.state.genCardTerms, this.state.nameBackgroundColor, this.state.codeBackgroundColor, this.state.expiryBackgroundColor)
        .then(res => {
            console.log({res});
        })
        .catch(err => console.log({err}));

    }

    uploadFile = async (file) => {

        const formData = new FormData();

        for (let i = 0; i < file.length; i++) {
            formData.append("file[]", file[i]);
        }

        formData.append('avatar',file)

        return  await axios.post('https://deals.mu/scripts/reportsapi/giftcardupload.php', formData,{
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
    }

    handleFilesChange(event) {

        console.log(event.target.files[0]);
        this.setState({
            [event.target.name]: event.target.files
        });

        var reader = new FileReader();
        var url = reader.readAsDataURL(event.target.files[0]);

        reader.onloadend = function (e) {

            if([event.target.name]=='genChooseImage') {
                this.setState({
                    selectedImgSrc: [reader.result]
                })
            } else {
                this.setState({
                    selectedLogoSrc: [reader.result]
                })
            }
        }.bind(this);
    }

    searchCards = (e) => {
        if(e!=null) {
            e.preventDefault();
        }

        this.setState({
            data: [],
            showloader: true,
            show: false
        });

        GiftCardsService.getAllCards(this.state.searchText, this.state.searchStatus)
        .then(resp => {
            console.log("All cards", resp);
            this.setState({
                data: resp.data,
                showloader: false,
                show: true
            });
        })
        .catch(err => console.log({err}));
        // console.log("search cards");
    }

    setSelectedBackground = (image) => {
        this.setState({
            selectedImage: image,
            selectedImgSrc: image
        });
    }

    goToStepTow(e) {
        e.preventDefault();

        if(this.state.uploadedcsvdata.length>0) {
            this.setState({
                generatecurrentstep: 2,
                step1error: false
            });
        } else {
            this.setState({
                step1error: true
            });
        }
    }

    goToStepThree(e) {
        e.preventDefault();

        if(this.state.genCustomerName!='' && this.state.genCustomerEmail!='' && this.state.genCustomerPhone!='' && this.state.genAmountPaid!='') {
            this.setState({
                generatecurrentstep: 3,
                step2error: false
            });
        } else {
            this.setState({
                step2error: true
            });
        }
    }

    getFormatedDate(dated) { 
        var expiry  = dated.split('.');
        var options = { year: 'numeric', month: 'short' };

        const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
        return monthNames[parseInt(expiry[1])-1]+" "+expiry[2];
    }

    handleExpiryColorChange = (color) => {
        this.setState({ expiryBackgroundColor: color.hex.replace('#', '') });
    };

    handleNameColorChange = (color) => {
        this.setState({ nameBackgroundColor: color.hex.replace('#', '') });
    };

    handleCodeColorChange = (color) => {
        this.setState({ codeBackgroundColor: color.hex.replace('#', '') });
        console.log({color});
    };

    render() {
        if(this.state.allowed==false) {
            return (
                <NoPermission />
            );
        }

        return (
            <>
             <div className="row" style={{ borderBottom: "1px solid #e7e7e7", paddingBottom: "10px" }}>
                <div className='col-6'>
                    <h3><FontAwesomeIcon icon={faGift} size="2" /> Bulk Gift Cards Summary</h3>
                </div>
                <div className='col-6'>
                    <button className="btn btn-success" data-toggle="modal" data-target="#createBulkGiftCardsModal" style={{float:"right"}}><FontAwesomeIcon icon={faPlus} /> Create Bulk Gift Cards</button>
                </div>
            </div>
            <div className="row" style={{ borderBottom: "1px solid #e7e7e7", paddingBottom: "10px", marginTop:"10px", display: "flex", alignItems: "center"}}>
                <div className='col-1'>
                    <strong>Search: </strong>
                </div>
                <div className='col-3'>
                    <input className="form-control" placeholder="search" value={this.state.searchText} name="searchText" onChange={this.handleChange.bind(this)} />
                </div>
                <div className='col-1'>
                    <strong>Status: </strong>
                </div>
                <div className='col-2'>
                    <select className="form-control" value={this.state.searchStatus} onChange={this.handleChange.bind(this)} name="searchStatus">
                        <option value="0">All</option>
                        <option value="Pending">Pending</option>
                        <option value="Redeemed">Redeemed</option>
                        <option value="Used in Full">Used in Full</option>
                        <option value="Disabled">Disabled</option>
                        <option value="UnRedeemed">UnRedeemed</option>
                    </select>
                </div>
                <div className='col-1'>
                    <button className="btn btn-success search-gift-cards-btn" type="button" onClick={this.searchCards.bind(this)}>Search</button>
                </div>
            </div>
            <div className="row">
                    <div className="col">
                        {(this.state.show == true) &&
                            <DTable2 data={this.state.data} columns={this.state.columnsheadings} />
                        }

                        {(this.state.showloader == true) &&
                            <Loader className='loader-center'
                                type="Grid"
                                color="#00BFFF"
                                height={100}
                                width={100}
                            />
                        }
                    </div>
                </div>

                <div className="modal fade" data-backdrop="static" data-keyboard="false" id="createBulkGiftCardsModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Create Bulk Gift Cards</h5>
                            <button type="button" className="close" onClick={(e)=> this.setState({generatecurrentstep: 1})} data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form id="creatInfluencerCustomerForm" onSubmit={this.generateGiftCards.bind(this)}>
                        <div className="modal-body">
                            {this.state.generatecurrentstep==1 && 
                            <div className="step1">
                                <div className="row">
                                    <div className="col-12 steps-heading">
                                        Step 1 - Upload list of Gift Card Receipents:
                                    </div>
                                </div>
                                {this.state.step1error==true &&
                                <div className="row">
                                    <div className="col-12">
                                        <div class="alert alert-danger" role="alert">
                                            Please upload the CSV
                                        </div>
                                    </div>
                                </div>
                                }
                                <div className="form-group row">
                                    <label for="uploadcsvfile" className="col-sm-2 col-form-label">Upload Customer List CSV:</label>
                                    <div className="col-sm-10">
                                        <input type="file" className="form-control" id="uploadcsvfile" name="uploadcsvfile" placeholder="Click to Upload" required="required" onChange={this.handleFileChange.bind(this)} />
                                        <p><a href="https://deals.mu/scripts/reportsapi/gift-cards-uploads/testcsvgiftcardtemplate.csv" target="_blank" className="btn btn-sm btn-link">Get Template CSV file</a></p>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label for="uploadcsvfile" className="col-sm-2 col-form-label">Group Name:</label>
                                    <div className="col-sm-10">
                                        <input type="text" className="form-control" value={this.state.genGroupName} id="genGroupName" name="genGroupName" placeholder="Enter group name" required="required" onChange={this.handleChange.bind(this)} />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <hr />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <h5>Preview:</h5>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group row">
                                            <label for="genTotalRecipients" className="col-sm-2 col-form-label">Total Number of Recipents:</label>
                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" value={this.state.genTotalRecipients} id="genTotalRecipients" name="genTotalRecipients" placeholder="Total number of recipents" disabled="disabled" />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label for="genTotalAmount" className="col-sm-2 col-form-label">Total Gift Card Amount:</label>
                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" value={this.state.genTotalAmount} id="genTotalAmount" name="genTotalAmount" placeholder="Total Gift Card amount" disabled="disabled" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <h5>List of Recipents:</h5>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12" style={{height:'500px', overflow:'auto'  }}>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Receipient Name</th>
                                                    <th>Gift Card Code</th>
                                                    <th>Gift Card Amount</th>
                                                    <th>Gift Card Validity</th>
                                                    <th>Email Address</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.uploadedcsvdata.length>0 && this.state.uploadedcsvdata.map((row, index)=>(
                                                <tr key={index} className={('Gift Card Code' in row) ? '':'hideit' }>
                                                    <td scope="row">{index+1}</td>
                                                    <td>{row['Receipient Name']}</td>
                                                    <td>{row['Gift Card Code']}</td>
                                                    <td>{row['Gift Card Amount']}</td>
                                                    <td>{row['Gift Card Validity']}</td>
                                                    <td>{row['Email Address']}</td>
                                                </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            }

                            {this.state.generatecurrentstep==2 && 
                            <div className="step1">
                                <div className="row">
                                    <div className="col-12 steps-heading">
                                        Step 2 - Order Details:
                                    </div>
                                </div>

                                {this.state.step2error==true &&
                                <div className="row">
                                    <div className="col-12">
                                        <div class="alert alert-danger" role="alert">
                                            Please input all the below details
                                        </div>
                                    </div>
                                </div>
                                }

                                <div className="row">
                                    <div className="col-12">
                                        <h5>Add Order and Customer Details</h5>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group row">
                                            <label for="genCustomerEmail" className="col-sm-2 col-form-label">Order Customer Email:</label>
                                            <div className="col-sm-10">
                                                <input type="email" className="form-control" value={this.state.genCustomerEmail} id="genCustomerEmail" name="genCustomerEmail" placeholder="Order Customer Email" onChange={this.handleChange.bind(this)} required="required" />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label for="genCustomerName" className="col-sm-2 col-form-label">Customer Name:</label>
                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" value={this.state.genCustomerName} id="genCustomerName" name="genCustomerName" placeholder="Order Customer Name" onChange={this.handleChange.bind(this)} required="required" />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label for="genCustomerPhone" className="col-sm-2 col-form-label">Customer Phone:</label>
                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" value={this.state.genCustomerPhone} id="genCustomerPhone" name="genCustomerPhone" placeholder="Order Customer Phone Number" onChange={this.handleChange.bind(this)} required="required" />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="genAmountPaid" className="col-sm-2 col-form-label">Amount Paid:</label>
                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" value={this.state.genAmountPaid} id="genAmountPaid" name="genAmountPaid" placeholder="Order Amount Paid" onChange={this.handleChange.bind(this)} required="required" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }

                            {this.state.generatecurrentstep==3 && 
                            <div className="step1">
                                <div className="row">
                                    <div className="col-12 steps-heading">
                                        Step 3 - Gift Card Design		
                                    </div>
                                </div>

                                {this.state.step3error==true &&
                                <div className="row">
                                    <div className="col-12">
                                        <div class="alert alert-danger" role="alert">
                                            Please select or upload a backgound image and a logo
                                        </div>
                                    </div>
                                </div>
                                }

                                <div className="row">
                                    <div className="col-12">
                                        <h5>Choose Background Image</h5>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group row">
                                            <label for="genChooseImage" className="col-sm-2 col-form-label">Choose Your Image:</label>
                                            <div className="col-sm-10">
                                                <ChooseGiftCardImage onChange={this.setSelectedBackground} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label for="genChooseImage" className="col-sm-2 col-form-label">OR Upload Your Image (2480 X 1748):</label>
                                            <div className="col-sm-10">
                                                <input type="file" className="form-control" id="genChooseImage" name="genChooseImage" placeholder="Upload Your Image" onChange={this.handleFilesChange.bind(this)} required="required" />
                                            </div>  
                                        </div>

                                        {this.state.selectedImgSrc!="" && 
                                        <div className="form-group row">
                                            <label className="col-sm-2 col-form-label">Selected Image:</label>
                                            <div className="col-sm-1" style={{position: "relative"}}>
                                                <img src={this.state.selectedImgSrc} className="choosimage-image" alt="selected-background" style={{width: "150px", height: "auto"}} />
                                            </div>
                                            <div className="col-sm-9" style={{display: "flex", alignItems:"center", justifyContent: "center"}}>
                                                <span onClick={e => {this.setState({selectedImage: "", selectedImgSrc: "", genChooseImage: null})}} style={{position:"absolute", cursor: "pointer", backgroundColor: "red", color: "white", padding: "6px 12px", borderRradius: "100%"}}><FontAwesomeIcon icon={faTimes} /></span>
                                            </div>
                                        </div>
                                        }

                                        <div className="form-group row">
                                            <label for="genUploadLogo" className="col-sm-2 col-form-label">Upload Logo (1024 X 1024):</label>
                                            <div className="col-sm-10">
                                                <input type="file" className="form-control" id="genUploadLogo" name="genUploadLogo" placeholder="Upload Logo" onChange={this.handleFilesChange.bind(this)} required="required" />
                                            </div>
                                        </div>

                                        {this.state.selectedLogoSrc!="" && 
                                        <div className="form-group row">
                                            <label className="col-sm-2 col-form-label">Selected Logo:</label>
                                            <div className="col-sm-1" style={{position: "relative"}}>
                                                <img src={this.state.selectedLogoSrc} className="choosimage-image" alt="selected-background" style={{width: "150px", height: "auto"}} />
                                            </div>
                                            <div className="col-sm-9" style={{display: "flex", alignItems:"center", justifyContent: "center"}}>
                                                <span onClick={e => {this.setState({selectedLogo: "", selectedLogoSrc: "", genUploadLogo: null})}} style={{position:"absolute", cursor: "pointer", backgroundColor: "red", color: "white", padding: "6px 12px", borderRradius: "100%"}}><FontAwesomeIcon icon={faTimes} /></span>
                                            </div>
                                        </div>
                                        }

                                        <div className="form-group row">
                                            <label for="genCardTerms" className="col-sm-2 col-form-label">Terms & Conditions / Text:</label>
                                            <div className="col-sm-10">
                                                <textarea className="form-control" value={this.state.genCardTerms} rows="5" id="genCardTerms" name="genCardTerms" placeholder="Terms & Conditions - if any" onChange={this.handleChange.bind(this)}></textarea>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="" className="col-sm-2 col-form-label">Name Background Color:</label>
                                            <div className="col-sm-10">
                                                <SketchPicker  
                                                    color={ this.state.nameBackgroundColor }
                                                    onChangeComplete={ this.handleNameColorChange }
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="" className="col-sm-2 col-form-label">Code Background Color:</label>
                                            <div className="col-sm-10">
                                                <SketchPicker  
                                                    color={ this.state.codeBackgroundColor }
                                                    onChangeComplete={ this.handleCodeColorChange }
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="" className="col-sm-2 col-form-label">Validity Background Color:</label>
                                            <div className="col-sm-10">
                                                <SketchPicker  
                                                    color={ this.state.expiryBackgroundColor }
                                                    onChangeComplete={ this.handleExpiryColorChange }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }

                            {this.state.generatecurrentstep==4 && 
                            <div className="step1">
                                <div className="row">
                                    <div className="col-12 steps-heading">
                                        Step 4 - Gift Card Preview		
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        {this.state.selectedImage!='' && 
                                            <div style={{width: "100%", height: "700px", position: "relative"}}>
                                                <img src={this.state.selectedImage} style={{width: "100%", height: "100%", position: "absolute", top: "0", left: "0"}} />
                                                {this.state.selectedLogo!="" && 
                                                    <img src={this.state.selectedLogo} style={{width: "100px", height: "100px", position: "relative", top: "50px", left: "50px"}} />
                                                }

                                                <p style={{position: "absolute", backgroundColor: `${"#"+this.state.nameBackgroundColor}`, color: "white", textAlign: "center", padding: "4px", fontSize: "23px", fontWeight: "bold", width: "300px", margin: "0 auto", bottom: "23.4%", left: "36.8%"}}>{this.state.uploadedcsvdata[0]['Receipient Name']}</p>
                                                <p style={{position: "absolute", backgroundColor: `${"#"+this.state.codeBackgroundColor}`, color: "black", textAlign: "center", padding: "4px", fontSize: "23px", fontWeight: "bolder", width: "300px", margin: "0 auto", bottom: "16.4%", left: "36.8%"}}>{this.state.uploadedcsvdata[0]['Gift Card Code']}</p>
                                                <p style={{position: "absolute", backgroundColor: `${"#"+this.state.expiryBackgroundColor}`, color: "white", textAlign: "center", padding: "0px", fontSize: "33px", fontWeight: "bolder", width: "391px", margin: "0 auto", bottom: "6.4%", left: "32.8%"}}>Rs {this.state.uploadedcsvdata[0]['Gift Card Amount']} - {this.getFormatedDate(this.state.uploadedcsvdata[0]['Gift Card Validity'])}</p>
                                            </div>
                                        }
                                        
                                    </div>
                                </div>
                            </div>
                            }

                            {this.state.generatecurrentstep==5 && 
                            <div className="step1">
                                <div className="row">
                                    <div className="col-12 steps-heading">
                                        Generating Gift Cards
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <div className="steps-progress">
                                        <div>Creating Order</div>
                                        <div>Saving Customer Details</div>
                                        <div>Adding Gift Cards to Order</div>
                                        <div>Updating Order to Paid</div>
                                        <div>Generating PDF(s)</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }

                        </div>
                        <div className="modal-footer" style={{justifyContent: 'space-between'}}>
                            {this.state.generatecurrentstep==1 && 
                            <>
                                <button type="button" className="btn btn-danger" id="close-modal" onClick={(e)=> this.setState({generatecurrentstep: 1})} data-dismiss="modal">Cancel</button>
                                <button type="button" disabled={(this.state.uploadedcsvdata<1) ? 'disabled': false} className="btn btn-success" onClick={(e)=> this.goToStepTow(e)}>Next Step</button>
                            </>
                            }

                            {this.state.generatecurrentstep==2 && 
                            <>
                                <button type="button" className="btn btn-warning" onClick={(e)=> this.setState({generatecurrentstep: 1})}>Back</button>
                                <button type="button" disabled={(this.state.uploadedcsvdata<1) ? 'disabled': false} className="btn btn-success" onClick={(e)=> this.goToStepThree(e)}>Submit</button>
                            </>
                            }

                            {this.state.generatecurrentstep==3 && 
                            <>
                                <button type="button" className="btn btn-warning" onClick={(e)=> this.setState({generatecurrentstep: 2})}>Back</button>
                                <button type="button" disabled={(this.state.uploadedcsvdata<1) ? 'disabled': false} className="btn btn-success" onClick={(e)=> this.uploadImagesStep(e)}>Preview Gift Card</button>
                            </>
                            }

                            {this.state.generatecurrentstep==4 && 
                            <>
                                <button type="button" className="btn btn-warning" onClick={(e)=> this.setState({generatecurrentstep: 3})}>Back</button>
                                <button type="submit" disabled={(this.state.uploadedcsvdata<1) ? 'disabled': false} className="btn btn-success">Generate Gift Cards</button>
                            </>
                            }

                            {this.state.generatecurrentstep==5 && 
                            <>
                                <button type="button" className="btn btn-secondary" onClick={(e)=> {this.setState({generatecurrentstep: 1}); this.searchCards(e)}} id="close-modal" data-dismiss="modal">Close</button>
                            </>
                            }
                        </div>
                        </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="editCardsModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <form id="editCardDetailsForm">
                            <input type="hidden" id="editCardsDetailsFormId" value='0' />
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Edit Gift Cards Details</h5>
                                    <button type="button" className="btn-close close-modal" data-bs-dismiss="modal" aria-label="Close">X</button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group row">
                                        <label for="editCardsDetailsFormName" className="col-sm-2 col-form-label">Recipient Name:</label>
                                        <div className="col-sm-10">
                                        <input type="text" className="form-control" id="editCardsDetailsFormName" required="required" />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label for="editCardsDetailsFormEmail" className="col-sm-2 col-form-label">Recipient Email:</label>
                                        <div className="col-sm-10">
                                        <input type="email" className="form-control" id="editCardsDetailsFormEmail" required="required" />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label for="editCardsDetailsFormExpiry" className="col-sm-2 col-form-label">Expiration Date:</label>
                                        <div className="col-sm-10">
                                        <input type="text" className="form-control date-picker" id="editCardsDetailsFormExpiry" required="required" />
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger close-modal" data-bs-dismiss="modal">Cancel</button>
                                    <button type="submit" className="btn btn-success" id='submiteditcardbtn'>Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </>
        )
    }

}