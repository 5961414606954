import moment from 'moment';
import axios from "axios";

const API_URL = "https://deals.mu/scripts/reportsapi/general_helper.php";
class UtilityService {
    format_date(d) {
        var datestring = ("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" +d.getFullYear();
        return datestring;
    }

    format_date_time(d) {
        var datestring = moment(d).format('YYYY-MM-DD HH:mm:ss');
        // var datestring = ("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" +d.getFullYear();
        return datestring;
    }

    makeDate(str) {
        if(str=='' || str=="N/A") {
            return null;
        }
        var parts = str.split('-');
        var mydate = new Date(parts[2], parts[1] - 1, parts[0]); 
        return mydate;
    }

    capitalize = (str, lower = false) => {
        (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());
    };

    getSingleInvoiceData(invoiceNumber) {
        var params = {
            action: 'get_single_invoice_data',
            invoiceNumber: invoiceNumber,
        };
        return axios
            .post(API_URL, {params})
            .then((response) => {

                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }    

    getSuppliersBankDetails() {
        var params = {
            action: 'get_suppliers_bank_details',
        };
        return axios
            .post(API_URL, {params})
            .then((response) => {

                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

    formatDateDifference(dateString) {
        const [day, month, year, time] = dateString.split(/[- ]/); 
        const [hours, minutes] = time.split(":");

        const inputDate = new Date(year, month - 1, day, hours, minutes);
        const currentDate = new Date();
        const diffMs = currentDate - inputDate;

        const diffMins = Math.round(diffMs / (1000 * 60)); // Difference in minutes
        const diffHours = Math.round(diffMs / (1000 * 60 * 60)); // Difference in hours

        // console.log(diffMins, diffHours);

        if (diffHours < 24) {
            if (diffMins < 60) {
                if (diffMins === 1) {
                    return `${diffMins} min ago`;
                }
                return `${diffMins} mins ago`;
            } else {
                if (diffHours === 1) {
                    return `${diffHours} hour ago`;
                }
                return `${diffHours} hours ago`;
            }
        } else {
            return dateString;
        }
    }
}

export default new UtilityService();