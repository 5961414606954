import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import swal from 'sweetalert';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import AuthService from '../services/auth/auth.service';
import Pagination from './pagination.component';
import UtilityService from '../services/utility.service';
import BookingUpdatesRow from './transactions/bookingUpdatesRow.component';

const APIURL = 'https://deals.mu/scripts/reportsapi/booking-updates.php';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function BookingUpdatesSheet() {

    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [allowed, setAllowed] = useState(false);
    const [username, setUsername] = useState('');
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(30);
    const [total, setTotal] = useState(0);
    const [startDateSelected, setStartDateSelected] = useState(null);
    const [endDateSelected, setEndDateSelected] = useState(null);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [selectedAgent, setSelectedAgent] = useState({value: '0', label: 'All Agents'});
    const [allAgents, setAllAgents] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState({value: '0', label: 'All Status'});
    const [allStatus, setAllStatus] = useState([
        {value: '0', label: 'All Status'},
        {value: 'Pending', label: 'Pending'},
        {value: 'Completed', label: 'Completed'},
    ]);
    const [searchBtnText, setSearchBtnText] = useState('Search');
    const [searchBtnDisabled, setSearchBtnDisabled] = useState(false);
    const [showAddRecordForm, setShowAddRecordForm] = useState(false);
    const [addRecordForm, setAddRecordForm] = useState({
        order: '',
        customer: "",
        comments: ""
    });
    const [saveBtnText, setSaveBtnText] = useState("Save");
    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [excelData, setExcelData] = useState([]);
    const [exportBtnText, setExportBtnText] = useState("Export");
    const [exportBtnDisabled, setExportBtnDisabled] = useState(false);
    

    useEffect(() => {
        AuthService.checklogin().then(user => {
            setUsername(user.user_nicename);
            setUser(user);

            AuthService.getUserPermisions(user)
            .then(response => {
                var userpermissions = response.data;
                // console.log({userpermissions});
                    if(userpermissions.includes('bookingupdates') || userpermissions.includes('all')) {
                    setAllowed(true);
                    searchTransactions();
                    fetchAgents();
                } else {
                    setAllowed(false);
                }
            })

            // console.log({user});
        }).catch(error => {
            window.location.href = '/login';
        });
    }, []);


    const changePage = (page) => {
        setPage(prev => page);
        fetchList(page);
    }

    const updateDateRange = (date) => {
        setDateRange(date);
        // console.log("date", date);
        setStartDateSelected(date[0]);
        setEndDateSelected(date[1]);
    }

    const fetchAgents = () => {
        var params = {
            action: 'get_agents'
        }
        axios.post(APIURL, {params})
        .then(response => {
            // console.log("agents", response.data);
            setAllAgents(response.data);
        })
    }


    const fetchList = (curPage) => {
        setLoading(true);
        setTransactions([]);
        if(startDateSelected!=null && endDateSelected!=null) {
            var start = UtilityService.format_date_time(startDateSelected);
            var end = UtilityService.format_date_time(endDateSelected);
        }
        var params = {
            page: curPage,
            limit: limit,
            search: search,
            action: "get_all",
            start_date: start,
            end_date: end,
            agent: selectedAgent.value,
            status: selectedStatus.value
        }

        axios.post(APIURL, {params})
        .then(response => {
            setTransactions(response.data);
            setLoading(false);
            setSearchBtnText('Search');
            setSearchBtnDisabled(false);
            console.log("transactions", response.data);
        })
    }
    
    const searchTransactions = () => {
        if(startDateSelected!=null && endDateSelected!=null) {
            var start = UtilityService.format_date_time(startDateSelected);
            var end = UtilityService.format_date_time(endDateSelected);
        }
        setSearchBtnText('Searching...');
        setSearchBtnDisabled(true);
         var params = {
            page: 0,
            limit: limit,
            search: search,
            action: "get_all",
            start_date: start,
            end_date: end,
            agent: selectedAgent.value,
            status: selectedStatus.value
        }
        axios.post(APIURL, {params})
        .then(response => {
            console.log("total", response.data);
            setTotal(response.data);
            fetchList(1);
        });
    }

    const submitRecord = (e) => {
        e.preventDefault();

        setSaveBtnText("Saving...");
        setSaveBtnDisabled(true);

        var params = {
            action: 'addRecord',
            username: user.user_nicename,
            agent: user.user_nicename,
            form: addRecordForm,
        }
        axios.post(APIURL, {params})
        .then(response => {
            swal("Success", "Record saved successfully. please wait while we reload the page", "success");
            searchTransactions();
            setSaveBtnText("Save");
            setSaveBtnDisabled(false);
        });
    }

    const exportExcel = (e) => {
        // create array of promises to export data from all pages
        var promises = [];
        setExportBtnText("Exporting...");
        setExportBtnDisabled(true);
        for (var i = 1; i <= Math.ceil(total/limit); i++) {
            var params = {
                page: i,
                limit: limit,
                search: search,
                action: "get_all",
                start_date: startDateSelected,
                end_date: endDateSelected,
                agent: selectedAgent.value,
                status: selectedStatus.value
            }
            promises.push(axios.post(APIURL, {params}));
        }

        // resolve all promises
        Promise.all(promises)
        .then(results => {
            var exdata = [];
            results.forEach(result => {
                exdata = exdata.concat(result.data);
            });

            for (var i = 0; i < exdata.length; i++) {
                delete exdata[i].bgclass;
                 exdata[i].status = exdata[i].status.value;
            }

            // sort in ascending order based on id
            exdata.sort((a, b) => (parseInt(a.id) > parseInt(b.id)) ? 1 : -1);

            // console.log("data", data);
            setExcelData(exdata);
            setExportBtnText('Export');
            setExportBtnDisabled(false);

            if(exdata.length>0) {
                const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                const fileExtension = '.xlsx';
                const ws = XLSX.utils.json_to_sheet(exdata);
                const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const data = new Blob([excelBuffer], {type: fileType});
                FileSaver.saveAs(data, "transaction_export"+Math.random() + fileExtension);
            } else {
                swal("No Data", "No data found for selected filters", "error");
            }
        })
        .catch(error => {
            console.log(error);
        });
    }

    const openRecordForm = (e) => {
        setShowAddRecordForm(true);
    }

    return (
        <>
            <div className="row mb-20">
                <div className="col-md-12">
                    <div className="flex justify-between align-center">
                        <h1 className='orders-h1'>Rodrigues Bookings Record</h1>
                        <div className='flex align-center'>
                            <DatePicker
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            value={startDate && endDate ? `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}` : ''}
                            dateFormat="dd-MM-yyyy"
                            // minDate={startDate}
                            // maxDate={new Date()}
                            onChange={(date) => updateDateRange(date)}
                            placeholderText="Select Date Range"
                            shouldCloseOnSelect={!(!startDate || Boolean(startDate && endDate))}
                            className="form-control"
                            />
                            {/* <div style={{width: "200px"}} className='ml-10'>
                                <Select
                                    value={selectedStatus}
                                    onChange={(status) => setSelectedStatus(status)}
                                    options={allStatus} 
                                    id="status"
                                    placeholder="Select a Status"
                                />
                            </div> */}
                            <div style={{width: "200px"}} className='ml-10'>
                                <Select
                                    value={selectedAgent}
                                    onChange={(agent) => setSelectedAgent(agent)}
                                    options={allAgents} 
                                    id="agents"
                                    placeholder="Select an Agent"
                                />
                            </div>
                            <input type="text" className="form-control ml-10" placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)} style={{width: "200px"}} />
                            <button className="btn btn-success ml-10" disabled={searchBtnDisabled} onClick={e => searchTransactions()}>{searchBtnText}</button>
                            <button className="btn btn-primary ml-10" onClick={e=>openRecordForm(e)}>Add Record</button>
                            {transactions.length>0 && 
                            <button className="btn btn-warning ml-10" onClick={e=>exportExcel(e)}>{exportBtnText}</button>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <table className="table table-striped table-bordered dark-border-table">
                        <thead>
                            <tr>
                                <th scope="col">Agent</th>
                                <th scope="col">Order</th>
                                <th scope="col">Guest Name</th>
                                <th scope="col">Comments</th>
                                <th scope="col">Checked By</th>
                                <th scope="col">Supervisor Comments</th>
                                <th scope="col">Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {transactions.length>0 && transactions.map((transaction, index) => (
                                <BookingUpdatesRow key={index} row={transaction} index={index} username={username} />
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div className="flex justify-center align-center">
                        <Pagination current={page} limit={limit} total={total} onPageChange={(page)=>changePage(page)} />
                    </div>
                </div>
            </div>

            <Modal
                open={showAddRecordForm}
                onClose={e => {setAddRecordForm({order: '', customer: '', comments: ''}); setShowAddRecordForm(false)}}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{...style, width: 800, textAlign: 'center' }}>
                <form onSubmit={(e) => submitRecord(e)} style={{textAlign: "left"}}>
                    <div className="row">
                        <div className="col supplier-confirm-phone-heading">
                            Add Record
                        </div>
                    </div>
                    
                    <div className="form-group row">
                        <label for="chosenitems" className="col-4 col-form-label"><b>Order</b></label>
                        <div className="col-8">
                            <input type="text" className="form-control" id="order" placeholder="Enter order" required="required" onChange={e => setAddRecordForm({...addRecordForm, order: e.target.value})} value={addRecordForm.order} />
                        </div>
                    </div>
                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="customer" className="col-4 col-form-label"><b>Customer Name</b></label>
                        <div className="col-8"> 
                            <input type="text" className="form-control" id="customer" placeholder="Enter customer name" required="required" onChange={e => setAddRecordForm({...addRecordForm, customer: e.target.value})} value={addRecordForm.customer} />
                        </div>
                    </div>
                    
                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="comments" className="col-4 col-form-label"><b>Comments</b></label>
                        <div className="col-8"> 
                            <textarea className="form-control" id="comments" placeholder="Enter comments" onChange={e => setAddRecordForm({...addRecordForm, comments: e.target.value})} value={addRecordForm.comments}></textarea>
                        </div>
                    </div>

                    <div style={{display: "flex", justifyContent: "space-between", marginTop: "40px"}}>
                        <button type="submit" className="btn btn-sm btn-success" style={{marginRight: "20px"}} disabled={saveBtnDisabled}>{saveBtnText}</button><button type="button" className="btn btn-sm btn-danger" onClick={e => {setAddRecordForm({order: '', customer: '', comments: ''}); setShowAddRecordForm(false)}}>Close</button>
                    </div>
                </form>
                </Box>
            </Modal>
        </>
    );
}
