import React, { Component } from "react";
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { style } from "@mui/system";
import swal from "sweetalert";

import OrdersReportService from "../../services/orders/ordersreport.service";
import NewRefundReportsService from "../../services/erp/refunds-new.service";

export default class AllRefunds extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderid: props.orderid,
            rerender: props.render,
            email: props.email,
            allRefunds: [],
            user: props.user,
            bankdetails: null,
            open: false,
            bankname: '',
            bankaccountowner: '',
            bankaccountnumber: '',
            updateRefundID: 0,
        }
    }

    componentDidMount() {
        this.getRefunds();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.render!=this.props.render) {
            this.getRefunds();
        }
    }

    getRefunds = () => {
        OrdersReportService.getAllRefunds(this.state.orderid)
        .then(response => {
            console.log("all refunds", response);
            this.setState({
                allRefunds: response.data,
                rerender: false
            })
        })
        .catch(error => console.log({error}));
    }

    removeRefund = (e, id, amount) => {
        e.preventDefault();
        e.target.disabled = true;
        e.target.innerText = "Deleting...";
        OrdersReportService.deleteRefund(this.state.orderid, id, this.state.user.user_nicename, amount)
        .then(response => {
            swal('Refund Deleted!', 'The refund has been deleted', 'success');
            this.getRefunds();
            this.props.onChange();
        })
        .catch(error=>{
            console.log({error});
        })
    }

    updateOpenRefund = (e, id, b_name, b_account_owner, b_account_number) => {
        e.preventDefault();
        this.setState({
            bankname: b_name,
            bankaccountowner: b_account_owner,
            bankaccountnumber: b_account_number,
            updateRefundID: id,
            open: true
        });

    }
    
    updateRefund = (e) => {
        e.preventDefault();
        OrdersReportService.updateRefund(this.state.orderid, this.state.updateRefundID, this.state.bankname, this.state.bankaccountowner, this.state.bankaccountnumber, this.state.user.user_nicename)
        .then(response => {
            swal('Refund Updated!', 'The refund has been updated', 'success');
            this.setState({
                bankname: "",
                bankaccountowner: "",
                bankaccountnumber: "",
                updateRefundID: 0,
                open: false
            });
            this.getRefunds();
            this.props.onChange();
        })
        .catch(error=>{
            console.log({error});
        })
    }

    sendBankDetailsRequest = (refund_id, order_id, email) => {
        // swal are you sure

        swal({
            title: "Are you sure?",
            text: "Once sent, the customer will receive an email to input their bank details.",
            icon: "warning",
            buttons: [
                'No, I am not sure!',
                'Yes, I am sure!'
            ],
            dangerMode: true,
        })
        .then((willSend) => {
            if (willSend) {
                NewRefundReportsService.sendBankDetailsRequestN(refund_id, order_id, email)
                .then(response => {
                    // console.log({response});
                    swal("Success", "Bank Details Request Sent", "success");
                    // this.loadRefunds1(this.state.currentPage);
                    this.getRefunds();
                }).catch(error => {
                    console.log({error});
                    swal("Error", error, "error");
                });
            } else {
                swal("Bank Details Request Cancelled");
            }
        });

    }

    render() {
        const style = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        };
        return (
            <>
            <Modal
                open={this.state.open}
                onClose={e => {this.setState({open: false})}}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{...style, width: 800, textAlign: 'center' }}>
                    <form onSubmit={(e) => this.updateRefund(e)} style={{textAlign: "left"}}>
                        <div className="row">
                            <div className="col supplier-confirm-phone-heading">Customer Bank Details</div>
                        </div>
                        <div className="form-group row">
                            <label for="bankName" className="col-4 col-form-label"><b>Bank Name:</b></label>
                            <div className="col-8">
                                {/* <input type="text" class="form-control" value={this.state.bankname} onChange={e => this.setState({bankname: e.target.value})} /> */}
                                <select class="form-control" id="bankName" value={this.state.bankname} onChange={e => this.setState({bankname: e.target.value})} required="required">
                                    <option value="">Choose Bank Name</option>
                                    <option value="ABC Banking Corporation Ltd">ABC Banking Corporation Ltd</option>
                                    <option value="Absa Bank (Mauritius) Limited">Absa Bank (Mauritius) Limited</option>
                                    <option value="AfrAsia Bank Limited">AfrAsia Bank Limited</option>
                                    <option value="BCP Bank (Mauritius) Ltd">BCP Bank (Mauritius) Ltd</option>
                                    <option value="Bank One Limited">Bank One Limited</option>
                                    <option value="Bank Of Baroda">Bank Of Baroda</option>
                                    <option value="Bank Of China">Bank Of China</option>
                                    <option value="HSBC">HSBC</option>
                                    <option value="Habib Bank Limited">Habib Bank Limited</option>
                                    <option value="Investec Bank (Mauritius) Limited">Investec Bank (Mauritius) Limited</option>
                                    <option value="Mauritius Commercial Bank (MCB)">Mauritius Commercial Bank (MCB)</option>
                                    <option value="MauBank Ltd">MauBank Ltd</option>
                                    <option value="SBI (Mauritius) Ltd">SBI (Mauritius) Ltd</option>
                                    <option value="SBM Bank (Mauritius) Ltd">SBM Bank (Mauritius) Ltd</option>
                                    <option value="Silver Bank Limited">Silver Bank Limited</option>
                                    <option value="Standard Bank (Mauritius) Ltd">Standard Bank (Mauritius) Ltd</option>
                                    <option value="Standard Chartered Bank (Mauritius) Ltd">Standard Chartered Bank (Mauritius) Ltd</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label for="accountname" className="col-4 col-form-label"><b>Account Owner Name:</b></label>
                            <div className="col-8"><input type="text" class="form-control" value={this.state.bankaccountowner} onChange={e => this.setState({bankaccountowner: e.target.value})} required="required" /></div>
                        </div>
                        <div className="form-group row">
                            <label for="accountnumber" className="col-4 col-form-label"><b>Account Number:</b></label>
                            <div className="col-8"><input type="number" class="form-control" value={this.state.bankaccountnumber} onChange={e => this.setState({bankaccountnumber: e.target.value})} required="required" /></div>
                        </div>

                        <div style={{display: "flex", justifyContent: "space-between", marginTop: "40px"}}>
                            <button type="submit" className="btn btn-sm btn-success" style={{marginRight: "20px"}} >Update Bank Details</button><button type="button" className="btn btn-sm btn-danger" onClick={e => {this.setState({open: false})}}>Close</button>
                        </div>
                    </form>
                </Box>
            </Modal>
            {this.state.allRefunds.length>0 && 
            <div style={{marginTop: "35px"}}>
            <div className="row">
                <div className="col">
                    <h6>All Refunds:</h6>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <table class="table table-striped table-bordered">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>By</th>
                                <th>Date</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Urgency</th>
                                <th>Products</th>
                                <th>Refund Type</th>
                                <th>Handling Agent</th>
                                <th>Reason</th>
                                <th>Explanation & Comments</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.allRefunds!=null && this.state.allRefunds.map((refund, index) => (
                            <tr key={index}>
                                <td scope="row">{refund.id}</td>
                                <td>{refund.by}</td>
                                <td>{refund.date}</td>
                                <td>Rs {refund._refund_amount}</td>
                                <td>{refund.refund_status}</td>
                                <td>
                                    {typeof refund.refund_urgency!='undefined' && refund.refund_urgency=='Low' && 
                                        <Chip label={refund.refund_urgency} color="primary" />
                                    }
                                    {typeof refund.refund_urgency!='undefined' && refund.refund_urgency=='Medium' && 
                                        <Chip label={refund.refund_urgency} color="warning" />
                                    }
                                    {typeof refund.refund_urgency!='undefined' && refund.refund_urgency=='High' && 
                                        <Chip label={refund.refund_urgency} color="error" />
                                    }
                                </td>   
                                <td>{refund.refund_products}</td>
                                <td>{refund.refund_type}</td>
                                <td>{refund.handling_agent}</td>
                                <td>{refund.refund_reason_drop}</td>
                                <td>{refund.refund_reason}</td>
                                <td>
                                    {refund.refund_status=='Pending' && 
                                    <>
                                        <button type="button" className="btn btn-sm btn-danger" onClick={e => this.removeRefund(e, refund.id, refund._refund_amount)}>Remove</button>
                                        <br /> 
                                        <button type="button" className="btn btn-sm btn-warning" style={{marginTop:"5px"}} onClick={e => this.updateOpenRefund(e, refund.id, refund.bank_name, refund.account_owner_name, refund.account_number)}>Update Bank Details</button>
                                        <br /> 
                                        <button type="button" className="btn btn-sm btn-danger" style={{marginTop:"5px"}} onClick={e => this.sendBankDetailsRequest(refund.id, this.state.orderid, this.state.email)}>Send Bank Details Request</button>
                                        {refund.bank_details_request_sent!="" && 
                                            <p style={{marginTop: "5px"}}>Bank details request already sent <b>{refund.bank_details_request_sent}</b> times</p>
                                        }
                                    </>
                                    }
                                    {refund.refund_status=='Processed' && 
                                    <>
                                        <p>The refund has been processed already.</p>
                                        {refund.refund_type=="Refund" && 
                                            <>
                                                <p><b>Processed by:</b> {refund.processed_refund_agent_name}</p>
                                                <p><b>Processed Date:</b> {refund.processed_refund_date}</p>
                                                <p><b>Processed Method:</b> {refund.processed_refund_method}</p>
                                                <p><b>Processed Reference:</b> {refund.processed_refund_ref}</p>
                                                <p><b>Processed Comment:</b> {refund.processed_comment}</p>
                                            </>
                                        }
                                    </>
                                    }
                                    {refund.refund_status=='Approved' && 
                                    <p>The refund has been approved already.</p>
                                    }
                                    {refund.refund_status=='Rejected' && 
                                    <p>The refund has been rejected already.</p>
                                    }
                                </td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            </div>
            }
            </>
        );
    }
}