import React, { Component } from "react";
import Loader from 'react-loader-spinner';
import DTable from '../dtable.component';
import swal from 'sweetalert';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import DatePicker from "react-datepicker";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import AuthService from '../../services/auth/auth.service';
import CorporateNewService from '../../services/crm/corporatereportsnew.service';
import UtilityService from '../../services/utility.service';

import UserContext from "../../contexts/UserContext";
import NoPermission from "../extras/nopermission.component";
import { set } from "lodash";
export default class Corporate extends Component {
    static contextType = UserContext;
    constructor(props){
        super(props);

        var daypast30date = new Date();
        daypast30date.setDate(daypast30date.getDate() - 30);

        this.state = {
            createCompanyName: "",
            createLandline: "",
            createAddress: "",
            createEmail: "",
            createCouponCode: "",
            user: [],
            data: [],
            show: false,
            columnsheadings: [
                { title: "ID" },
                { title: "Account Creation" },
                { title: "Corporate Name" },
                { title: "Corporate Discount Code" },
                { title: "Corporate Email" },
                { title: "Contact Person" },
                { title: "Average Order Amt." },
                { title: "No. of Orders" },  
                { title: "Sales Amount" },  
                { title: "Last Order" },
                { title: "Account Status" },
                { title: "Account Expiration" },
                // { title: "Corporate Profile" },
            ],
            showloader: false,
            allowed: true,
            minDate: new Date("02-01-2019"),
            // startdate: daypast30date,
            startdate: "",
            // enddate: new Date(),
            enddate: "",
            dataRendered: [],
            totalOrderAmount: 0,
            totalNoOfOrder: 0,
            searchValue: "",
            accountStatus: "",
            allTimeActive: false,
            last12MonthsActive: false,
            last30DaysActive: false,
            exportCsvBtnDisabled: false,
            exportExcelBtnText: "Export",
        };
        
    }

    searchChanged = (e) => {
        var searchText = e.target.value;
        this.setState({
            searchValue: searchText
        });

        // this.filterList(searchText, this.state.accountStatus);

        if(searchText=='' || searchText.length==0 || searchText.length>3) {
            setTimeout(() => {
                this.getAll();
            }, 500);
        }
        
        // find an input as .dataTables_filter input andd insert value
        // var elements = document.getElementsByClassName("dataTables_filter");
        // if(elements.length>0) {
        //     elements[0].getElementsByTagName("input")[0].value = searchText;
        //     elements[0].getElementsByTagName("input")[0].dispatchEvent(new Event('input', { bubbles: true }));
        // }
    }
    
    accountStatusChanged = (e) => {
        var status = e.target.value;
        this.setState({
            accountStatus: status
        });

        // this.filterList(this.state.searchValue, status);
        setTimeout(() => {
            this.getAll();
        }, 500);
    }

    filterList = (search, status) => {
        var dataRenderedTemp = [];
        var searchTextLower = search.toString().toLowerCase();
        console.log({searchTextLower})

        for(var i=0; i<this.state.dataRendered.length; i++) {
            if(this.state.dataRendered[i][0].toString().toLowerCase().includes(searchTextLower) || this.state.dataRendered[i][2].toString().toLowerCase().includes(searchTextLower) || this.state.dataRendered[i][3].toString().toLowerCase().includes(searchTextLower) || this.state.dataRendered[i][4].toString().toLowerCase().includes(searchTextLower) || this.state.dataRendered[i][5].toString().toLowerCase().includes(searchTextLower) || this.state.dataRendered[i][10].toString().toLowerCase().includes(searchTextLower) || this.state.dataRendered[i][11].toString().toLowerCase().includes(searchTextLower)) {
                if(status=='' || status==this.state.dataRendered[i][10]) {
                    dataRenderedTemp.push(this.state.dataRendered[i]);
                }
            }
        }

        this.setState({
            dataRendered: [...dataRenderedTemp]
        });
    }

    componentDidMount() {
        AuthService.checklogin().then(user => {
            this.setState({
                user: user
            });

            console.log({ user });

            AuthService.getUserPermisions(user)
            .then(response => {
                var userpermissions = response.data;
                console.log({userpermissions});
                    if(userpermissions.includes('corporate') || userpermissions.includes('all')) {
                    console.log('You are allowed!');
                    this.setState({
                        allowed: true
                    });
                    this.getAll();
                } else {
                    console.log('You are not allowed!');
                    this.setState({
                        allowed: false
                    });
                }
            })
        }).catch(error => {
            window.location.href = '/login';
        });
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
        // console.log('handle change');
    }

    createCorporateCustomer = (e) => {
        e.preventDefault();
        CorporateNewService.createCorporateCustomer(this.state.createCompanyName, this.state.createLandline, this.state.createAddress, this.state.createEmail, this.state.createCouponCode)
        .then(response => {
            console.log({response});
            this.setState({
                createCompanyName: "",
                createLandline: "",
                createAddress: "",
                createEmail: "",
                createCouponCode: "",
            });

            document.getElementById('close-modal').click();
            swal('Customer Created!', 'The customer has been created and the coupon has been created as well', 'info');
            this.getAll();
        })
        .catch(error => {
            console.log({error});
        });
        console.log('Submitted');
    }

    getAll = () => {
        this.setState({
            data: [],
            show: false,
            showloader: true,
            exportCsvBtnDisabled: true,
        });
         var startday ='';
        var endday = '';

        if(this.state.startdate!='' && this.state.enddate!='' && this.state.startdate!=null && this.state.enddate!=null) {

            startday = UtilityService.format_date(this.state.startdate);
            endday = UtilityService.format_date(this.state.enddate);
        }
        CorporateNewService.getAll(startday, endday, this.state.searchValue, this.state.accountStatus)
        .then(response => {
            // console.log({response});
            if(response.data.length>0) {
                var dataRenderedTemp = [];
                var totalOrderAmountTemp = 0;
                var totalNoOfOrderTemp = 0;
                // sort response data in desc by index 14
                response.data.sort(function(a, b) {
                    return b[14] - a[14];
                });
                for(var i=0; i<response.data.length; i++) {
                    var row = [];
                    row.push(response.data[i][0]);
                    row.push(response.data[i][1]);
                    row.push(response.data[i][2]);
                    row.push(response.data[i][3]);
                    row.push(response.data[i][4]);
                    row.push(response.data[i][5]);
                    row.push(response.data[i][6]);
                    row.push(response.data[i][7]);
                    row.push(response.data[i][8]);
                    row.push(response.data[i][9]);
                    row.push(response.data[i][10]);
                    row.push(response.data[i][11]);

                    if(response.data[i][13]!=null) { 
                        totalOrderAmountTemp = totalOrderAmountTemp + parseFloat(response.data[i][14]);
                        totalNoOfOrderTemp = totalNoOfOrderTemp + parseInt(response.data[i][13]);
                        
                    }
                    dataRenderedTemp.push(row);
                    console.log(response.data[i][0]+" = "+response.data[i][14]);
                }

                this.setState({
                    dataRendered: dataRenderedTemp,
                    data: response.data,
                    show: true,
                    showloader: false,
                    totalOrderAmount: totalOrderAmountTemp,
                    totalNoOfOrder: totalNoOfOrderTemp,
                    exportCsvBtnDisabled: false,
                });
            }
        })
        .catch(error => {
            console.log({error});
            this.setState({
                data: [],
                show: false,
                showloader: false
            });
        });
    }

    setStartDate = (date) => {
        this.setState({
            startdate: date,
            minDate: date,
        });

        if (this.state.enddate != '' && this.state.enddate < date) {
            this.setState({
                enddate:date
            });
        }
        // console.log(this.state);
    }

    setEndDate = (date) => {

        this.setState({
            enddate: date
        });
        // console.log(this.state);
    }

    setAllTime = (e) => {
        this.setState({
            startdate: '',
            enddate: '',
            allTimeActive: true,
            last12MonthsActive: false,
            last30DaysActive: false,
        });
        setTimeout(() => {
            this.getAll();
        }, 700);
    }

    setLast12Months = (e) => {
        var today = new Date();
        var last12Months = new Date();
        last12Months.setMonth(today.getMonth() - 12);
        this.setState({
            startdate: last12Months,
            enddate: today,
            last12MonthsActive: true,
            last30DaysActive: false,
            allTimeActive: false,
        });
        setTimeout(() => {
            this.getAll();
        }, 700);
    }

    setLast30Days = (e) => {
        var today = new Date();
        var last30Days = new Date();
        last30Days.setDate(today.getDate() - 30);
        this.setState({
            startdate: last30Days,
            enddate: today,
            last30DaysActive: true,
            last12MonthsActive: false,
            allTimeActive: false,
        });
        setTimeout(() => {
            this.getAll();
        }, 700);
    }

     exportResults = (e) => {
        e.preventDefault();

        this.setState({
            exportExcelBtnText: "Exporting...",
            exportExcelBtnDisabled: true,
        });
    
        if(this.state.data.length>0) {
            var dataRenderedTemp = [];
            for(var i=0; i<this.state.data.length; i++) {
                var row = {
                    "ID": this.state.data[i][0],
                    "Account Creation": this.state.data[i][17],
                    "Corporate Name": this.state.data[i][16],
                    "Corporate Discount Code": this.state.data[i][15],
                    "Corporate Email": this.state.data[i][4],
                    "Contact Person": this.state.data[i][5],
                    "Average Order Amount": this.state.data[i][6],
                    "No of Orders": this.state.data[i][7],
                    "Sales Amount": this.state.data[i][8],
                    "Last Order": this.state.data[i][18],
                    "Account Status": this.state.data[i][10],
                    "Account Expiration": this.state.data[i][11],
                }
                
                dataRenderedTemp.push(row);
            }
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx';
            const ws = XLSX.utils.json_to_sheet(dataRenderedTemp);
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], {type: fileType});
            FileSaver.saveAs(data, "corporate-export-"+Math.random() + fileExtension);
        } else {
            swal("No Data", "No data found for selected filters", "error");
        }

        this.setState({
            exportExcelBtnText: "Export",
            exportExcelBtnDisabled: true,
        });
    }

    render() {

        if(this.state.allowed==false) {
            return (
                <NoPermission />
            );
        }
        
        return(
            <div className="corporate-new">
            <div className="row">
                <div className='col-3'>
                    <h3><AccountBoxIcon fontSize="large" /> Corporate Summary</h3>
                </div>
                <div className='col-9' style={{textAlign: "right"}}>
                    <div style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
                        <div style={{fontWeight: "bold"}}>Sales Report Period: </div>
                        <button className={"btn btn-sm "+(this.state.allTimeActive ? "btn-warning": "btn-primary")} onClick={e => this.setAllTime(e)} style={{marginLeft: "10px"}}>All Time</button>
                        <button className={"btn btn-sm "+(this.state.last12MonthsActive ? "btn-warning": "btn-primary")} onClick={e => this.setLast12Months(e)} style={{marginLeft: "10px"}}>Past 12 Months</button>
                        <button className={"btn btn-sm "+(this.state.last30DaysActive ? "btn-warning": "btn-primary")} onClick={e => this.setLast30Days(e)} style={{marginLeft: "10px"}}>Past 30 Days</button>
                        <div style={{marginLeft: "10px"}}>
                            <DatePicker
                                dateFormat="dd-MM-yyyy"
                                selected={this.state.startdate}
                                onChange={date => this.setStartDate(date)}
                                placeholderText="Start Date"
                                className='form-control'
                            />
                        </div>
                        <div style={{ marginLeft: '10px' }}>
                            <DatePicker
                                dateFormat="dd-MM-yyyy"
                                selected={this.state.enddate}
                                onChange={date => this.setEndDate(date)}
                                placeholderText="End Date"
                                className='form-control'
                                minDate = {this.state.minDate}
                            />
                        </div>
                        <button className="btn btn-sm btn-success" onClick={e => this.getAll()} style={{marginLeft: "10px"}}>Search</button>
                        <button className="btn btn-sm btn-warning" onClick={e => window.location.reload()} style={{marginLeft: "10px"}}>Reset</button>
                    </div>
                </div>
                <div className="modal fade" id="createCorporateCustomerModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Add New Corporate Customer</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form id="creatCorporateCustomerForm" onSubmit={this.createCorporateCustomer.bind(this)}>
                        <div className="modal-body">
                            <div className="form-group">
                                <label for="createCompanyName">Company Name:</label>
                                <input type="text" className="form-control" value={this.state.createCompanyName} id="createCompanyName" name="createCompanyName" placeholder="Enter Company Name" required="required" onChange={this.handleChange.bind(this)} />
                            </div>

                            <div className="form-group">
                                <label for="createLandLine">Landline</label>
                                <input type="text" className="form-control" value={this.state.createLandline} id="createLandline" name="createLandline" placeholder="Enter Landline Number" required="required" onChange={this.handleChange.bind(this)} />
                            </div>

                            <div className="form-group">
                                <label for="createAddress">Address:</label>
                                <input type="text" className="form-control" value={this.state.createAddress} id="createAddress" name="createAddress" placeholder="Enter Address" required="required" onChange={this.handleChange.bind(this)} />
                            </div>

                            <div className="form-group">
                                <label for="createEmail">Email:</label>
                                <input type="text" className="form-control" value={this.state.createEmail} id="createEmail" name="createEmail" placeholder="Enter Email" required="required" onChange={this.handleChange.bind(this)} />
                            </div>

                            <div className="form-group">
                                <label for="createCouponCode">Discount Coupon Code:</label>
                                <input type="text" className="form-control" value={this.state.createCouponCode} id="createCouponCode" name="createCouponCode" placeholder="Enter Coupon Code" required="required" onChange={this.handleChange.bind(this)} />
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" id="close-modal" data-dismiss="modal">Close</button>
                            <button type="submit" className="btn btn-success">Create Corporate Customer</button>
                        </div>
                        </form>
                        </div>
                    </div>
                </div>
                <div className='col-12'>
                    <hr />
                </div>
                <div className='col-12' style={{display:"flex", alignItems: "center", justifyContent:"space-around"}}>
                    <div><span style={{color: "black", marginRight: "10px", fontWeight: "bold"}}>Total Order Amount:</span><span style={{color: "red", fontWeight: "bold"}}>Rs {this.state.totalOrderAmount}</span></div>
                    <div><span style={{color: "black", marginRight: "10px", fontWeight: "bold"}}>Total Number of Orders:</span><span style={{color: "red", fontWeight: "bold"}}>{this.state.totalNoOfOrder}</span></div>
                    <div><span style={{color: "black", marginRight: "10px", fontWeight: "bold"}}>{this.state.exportExcelBtnText}:</span><button disabled={this.state.exportCsvBtnDisabled} className="btn btn-sm btn-primary" onClick={e => this.exportResults(e)}>Excel</button></div>
                    <div style={{display:"flex", alignItems: "center", justifyContent:"space-between"}}>
                        <span style={{color: "black", marginRight: "10px", fontWeight: "bold"}}>Account Status:</span>
                        <select value={this.state.accountStatus} onChange={e => this.accountStatusChanged(e)} className="form-control">
                            <option value="">All</option>
                            <option value="Active">Active</option>
                            <option value="About to Expire">About to Expire</option>
                            <option value="Expired">Expired</option>
                        </select>
                    </div>
                    <div><span style={{color: "black", marginRight: "10px", fontWeight: "bold"}}>Search:</span><input onChange={e=>this.searchChanged(e)} value={this.state.searchValue} placeholder="Search..." /></div>
                    <button className="btn btn-success btn-sm" data-toggle="modal" data-target="#createCorporateCustomerModal" style={{float:"right", marginLeft: "10px"}}>Create Corporate Customer</button>
                </div>
                <div className='col-12'>
                    <hr />
                </div>
            </div>
            <div className="row">
                    <div className="col">
                        {(this.state.show == true) &&
                            <DTable data={this.state.dataRendered} columns={this.state.columnsheadings} />
                        }

                        {(this.state.showloader == true) &&
                            <Loader className='loader-center'
                                type="Grid"
                                color="#00BFFF"
                                height={100}
                                width={100}
                            />
                        }
                    </div>
                </div>

                {/* <div className="modal" tabindex="-1" role="dialog" id="corporate-gen-password-modal">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Generate / Update Password</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form className="corporate-gen-password-form">
                        <div className="modal-body">
                            <input type="hidden" value="0" id="corporate-gen-password-user-id" />
                            <div className="form-group">
                                <label for="corporate-gen-password-password">New Password</label>
                                <input type="text" className="form-control" id="corporate-gen-password-password" aria-describedby="passwordHelp" placeholder="Enter password" required="required" />
                                <small id="passwordHelp" className="form-text text-muted">Please enter the password and also save the password on your side as well as the password will be encrypted and can only be changed not seen later.</small>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success">Save</button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                        </form>
                        </div>
                    </div>
                </div> */}
            </div>
        );
    }
}