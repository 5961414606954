import React, { Component } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import OrdersReportService from "../../services/orders/ordersreport.service";

export default class OrderPaymentDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            orderid : props.orderid,
            open: false,
            data: {
                paymentmethod: '',
                subtotal: 0,
                transactionref: '',
                discount_total: 0,
                total: 0,
                paymentstatusnote: '',
            },
            buttonText : "Payment Details"
        }
    }

    handleOpen = (e) => {
        if(this.state.orderid!='') {
            this.setState({
                buttonText: "loading..."
            })
            OrdersReportService.getOrderPaymentDetails(this.state.orderid)
            .then(response => {
                // console.log("payment details", response);
                this.setState({
                    open: true,
                    data: response.data,
                    buttonText : "Payment Details"
                });
            })
            .catch(error => console.log({error}));
        }
    }

    handleClose = (e) => {
        this.setState({
            open: false
        });
    }

    render() {
        const style = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        };
        return (
            <>
            {/* {this.state.buttonText} */}
            <Button onClick={(e)=> this.handleOpen(e)} style={{color: "blue", width: "max-content"}}>Payment<br />Details</Button>
            <Modal open={this.state.open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" onClose={()=>this.handleClose}>
                <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2" style={{textDecoration: "underline"}}>
                    Payment Details for #{this.state.orderid}
                </Typography> 
                <Typography id="modal-modal-description" className="payment-details-wrapper" sx={{ mt: 2 }}>
                    <div>
                        <label>Payment Method</label>
                        <span>{this.state.data.paymentmethod}</span>
                    </div>
                    <div>
                        <label>Order Sub-Total</label>
                        <span>Rs {this.state.data.subtotal}</span>
                    </div>
                    <div>
                        <label>Total Discount</label>
                        <span>Rs {this.state.data.discount_total}</span>
                    </div>
                    <div>
                        <label>Order Total</label>
                        <span>Rs {this.state.data.total}</span>
                    </div>
                    <div>
                        <label>Transaction Reference</label>
                        <span>{this.state.data.transactionref}</span>
                    </div>
                    <div>
                        <label>Payment Notes</label>
                        <span>{this.state.data.paymentstatusnote}</span>
                    </div>
                    <div style={{textAlign: "center"}}>
                    <hr /> 
                    <button type="button" onClick={e => this.handleClose(e)} className="btn btn-sm btn-danger">Close</button>
                    </div>
                </Typography>
                </Box>
            </Modal>
            </>
        )
    }
}