import React, { Component } from "react";
import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net-buttons-dt/css/buttons.dataTables.css'
const $ = require("jquery");
// require('datatables.net-buttons')(window, $);
// require('datatables.net-dt')(window, $);
$.DataTable = require('datatables.net');
require( 'datatables.net-fixedheader' )

require('datatables.net-buttons/js/dataTables.buttons.min');
const jzip = require('jzip');
window.JSZip = jzip;
require('datatables.net-buttons/js/buttons.html5.min');
require('datatables.net-select/js/dataTables.select.min');


export default class DTable extends Component {
    constructor(props) {
        super(props);

        // console.log({props});

        var sorting = [];
        if(props.sorting !== 'undefined') {
            sorting = props.sorting;
        }

        var sortingTarget = [];
    
        this.state = {
            data: this.props.data,
            columns: this.props.columns,
            sorting : [],
            fixedHeader: false,
            sortingTarget: sortingTarget
        };

        if(props.sortTarget !== 'undefined') {
            this.setState({
                sortTarget: props.sortTarget
            });
        }

        if(props.fixedHeader!==undefined) {
            this.setState({
                fixedHeader: props.fixedHeader
            });
        }
    }

    componentDidMount() {
        // console.log("I am here now");
        if (typeof this.props.data != 'undefined' && this.props.data.length>0) {
            this.$el = $(this.el);
            this.$el.DataTable({
                dom: 'Bfrtip',
                pageLength: 500,
                data: this.state.data,
                columns: this.state.columns,
                buttons: ['csv', 'excel', 'pdf'],
                aaSorting: [],
                // order: this.state.sorting,
                fixedHeader: (typeof this.props.fixedHeader!=='undefined') ? true : false,
                // select: true
            });
        }
    }

    componentWillUnmount() {
        // if (typeof this.props.data != 'undefined' && this.props.data.length > 0) {
        //     this.$el = $(this.el);
        //     this.$el.DataTable.destroy(true);
        // }
    }

    render() {
        return (
            <div>
                <table className="table table-striped table-bordered data-custom-table" ref={el => this.el=el}>

                </table>
            </div>
        );
    }
}