import React, { Component } from "react";
import Loader from 'react-loader-spinner';
import Button from '@material-ui/core/Button';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import DateRangePicker, { DateRange } from '@material-ui/lab/DateRangePicker';
import DatePicker from '@material-ui/lab/DatePicker';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import Box from '@material-ui/core/Box';
import DTable from '../dtable.component';
import swal from 'sweetalert';

import AuthService from '../../services/auth/auth.service';
import InfluencerService from '../../services/crm/influencerreports.service';

import UserContext from "../../contexts/UserContext";
import NoPermission from "../extras/nopermission.component";
export default class Influencers extends Component {
    static contextType = UserContext;
    constructor(props){
        super(props);

        this.state = {
            createCustomerName: "",
            createRelevancy: "Low",
            createMobile: "",
            createEmail: "",
            createCouponCode: "",
            user: [],
            data: [],
            show: false,
            columnsheadings: [
                { title: "ID" },
                { title: "Influencer Name" },
                { title: "Influencer Discount Code" },
                { title: "Influencer Email" },
                { title: "Mobile" },
                { title: "Influencer Ranking (this month)" },
                { title: "All time" },
                { title: "Past 30 Days" },
                { title: "Past 12 months" },
                { title: "Last Order" },
                { title: "Account creaion date" },
                { title: "Influencer Profile" }
            ],
            showloader: false,
            allowed: true
        };
    }

    componentDidMount() {
        AuthService.checklogin().then(user => {
            this.setState({
                user: user
            });

            console.log({ user });

            AuthService.getUserPermisions(user)
            .then(response => {
                var userpermissions = response.data;
                console.log({userpermissions});
                    if(userpermissions.includes('influencers') || userpermissions.includes('all')) {
                    console.log('You are allowed!');
                    this.setState({
                        allowed: true
                    });
                    this.getAll();
                } else {
                    console.log('You are not allowed!');
                    this.setState({
                        allowed: false
                    });
                }
            })
        }).catch(error => {
            window.location.href = '/login';
        });
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
        // console.log('handle change');
    }

    createInfuencerCustomer = (e) => {
        e.preventDefault();
        InfluencerService.createInfluencerCustomer(this.state.createCustomerName, this.state.createRelevancy, this.state.createMobile, this.state.createEmail, this.state.createCouponCode)
        .then(response => {
            console.log({response});
            this.setState({
                createCustomerName: "",
                createRelevancy: "",
                createMobile: "",
                createEmail: "",
                createCouponCode: "",
            });

            document.getElementById('close-modal').click();
            swal('Customer Created!', 'The customer has been created and the coupon has been created as well', 'info');
            this.getAll();
        })
        .catch(error => {
            console.log({error});
        });
        console.log('Submitted');
    }

    getAll = () => {
        this.setState({
            data: [],
            show: false,
            showloader: true
        });
        InfluencerService.getAll()
        .then(response => {
            console.log({response});
            if(response.data.length>0) {
                this.setState({
                    data: response.data,
                    show: true,
                    showloader: false
                });
            }
        })
        .catch(error => {
            console.log({error});
            this.setState({
                data: [],
                show: false,
                showloader: false
            });
        });
    }

    render() {

        if(this.state.allowed==false) {
            return (
                <NoPermission />
            );
        }
        
        return(
            <>
            <div className="row">
                <div className='col-6'>
                    <h3><AccountBoxIcon fontSize="large" /> Influencers Summary</h3>
                </div>
                <div className='col-6'>
                    <button className="btn btn-success" data-toggle="modal" data-target="#createInfluencerCustomerModal" style={{float:"right"}}>Create Infuencer Customer</button>
                </div>
                <div className="modal fade" id="createInfluencerCustomerModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Add New Influencer Customer</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form id="creatInfluencerCustomerForm" onSubmit={this.createInfuencerCustomer.bind(this)}>
                        <div className="modal-body">
                            <div className="form-group">
                                <label for="createCustomerName">Influencer Name:</label>
                                <input type="text" className="form-control" value={this.state.createCustomerName} id="createCustomerName" name="createCustomerName" placeholder="Enter Customer Name" required="required" onChange={this.handleChange.bind(this)} />
                            </div>

                            <div className="form-group">
                                <label for="createRelevancy">Relavancy</label>
                                {/* <input type="text" className="form-control" value={this.state.createRelevancy} id="createRelevancy" name="createRelevancy" placeholder="Choose Relevancy" required="required" onChange={this.handleChange.bind(this)} /> */}
                                <select name="createRelevancy" id="createRelevancy" placeholder="Choose Relevancy" className="form-control form-control-select" required="required" onChange={this.handleChange.bind(this)}>
                                    <option value="Low">Low</option>
                                    <option value="Medium">Medium</option>
                                    <option value="High">High</option>
                                </select>
                            </div>

                            <div className="form-group">
                                <label for="createMobile">Mobile Number:</label>
                                <input type="text" className="form-control" value={this.state.createMobile} id="createMobile" name="createMobile" placeholder="Enter Mobile Number" required="required" onChange={this.handleChange.bind(this)} />
                            </div>

                            <div className="form-group">
                                <label for="createEmail">Email:</label>
                                <input type="text" className="form-control" value={this.state.createEmail} id="createEmail" name="createEmail" placeholder="Enter Email" required="required" onChange={this.handleChange.bind(this)} />
                            </div>

                            <div className="form-group">
                                <label for="createCouponCode">Influencer Coupon Code:</label>
                                <input type="text" className="form-control" value={this.state.createCouponCode} id="createCouponCode" name="createCouponCode" placeholder="Enter Coupon Code" required="required" onChange={this.handleChange.bind(this)} />
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" id="close-modal" data-dismiss="modal">Close</button>
                            <button type="submit" className="btn btn-success">Create Influencer Account</button>
                        </div>
                        </form>
                        </div>
                    </div>
                </div>
                <div className='col-12'>
                    <hr />
                </div>
            </div>
            <div className="row">
                    <div className="col">
                        {(this.state.show == true) &&
                            <DTable data={this.state.data} columns={this.state.columnsheadings} />
                        }

                        {(this.state.showloader == true) &&
                            <Loader className='loader-center'
                                type="Grid"
                                color="#00BFFF"
                                height={100}
                                width={100}
                            />
                        }
                    </div>
                </div>

                <div className="modal" tabindex="-1" role="dialog" id="influencer-gen-password-modal">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Generate / Update Password</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form className="influencer-gen-password-form">
                        <div className="modal-body">
                            <input type="hidden" value="0" id="influencer-gen-password-user-id" />
                            <div className="form-group">
                                <label for="influencer-gen-password-password">New Password</label>
                                <input type="text" className="form-control" id="influencer-gen-password-password" aria-describedby="passwordHelp" placeholder="Enter password" required="required" />
                                <small id="passwordHelp" className="form-text text-muted">Please enter the password and also save the password on your side as well as the password will be encrypted and can only be changed not seen later.</small>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success">Save</button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                        </form>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}