import axios from "axios";
import chalk from 'chalk';

const API_URL = "https://deals.mu/scripts/reportsapi/invoicesnew.php?rand="+Math.random();

class InvoicesServiceNew {
    fetchSuppliers(company) {
        var params = {
            action: 'fetch_suppliers',
            company: company,
        };
        return axios
            .get(API_URL, { params })
            .then((response) => {

                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

    fetchProducts(company, supplier) {
        var params = {
            action: 'fetch_products',
            company: company,
            supplier: supplier
        };

        return axios
            .get(API_URL, { params })
            .then((response) => {

                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

    fetchProductsPost(company, supplier) {
        var params = {
            action: 'fetch_products_post',
            company: company,
            supplier: supplier
        };

        return axios
            .post(API_URL, { params })
            .then((response) => {

                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

}

export default new InvoicesServiceNew();