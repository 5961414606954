import React, { Component } from "react";
import Iframe from 'react-iframe';
import DTable from './dtable.component';
import AuthService from '../services/auth/auth.service';
import UtilityService from "../services/utility.service";
import StopSalesService from "../services/erp/stopsales.service";

import UserContext from "../contexts/UserContext";
import NoPermission from "./extras/nopermission.component";
export default class StopSales extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            product_id:'',
            product_name: '',
            loadingvalues: true,
            show: false,
            error: {
                status: false,
                message: ''
            },
            url:'',
            allowed: true
        }
    }

    componentDidMount() {
        AuthService.checklogin()
            .then(response => {
                if (response) {
                    setTimeout(() => {
                        if (typeof this.props.params.id == 'undefined') {
                            this.setState({
                                product_id: 0,
                            });
                        } else {
                            this.setState({
                                product_id: this.props.params.id,
                                loadingvalues: false,
                                show: true,
                                url: "https://deals.mu/scripts/stopsales/index.php?product_id=" + this.props.params.id + "&rand=346937499&iframe=lkff&iframefrom=erp"
                            });
                            if (this.state.product_id != 0) {
                                this.getData();
                            }
                        }
                    }, 2000);
                    // window.location.href = '/booking-report';

                    AuthService.getUserPermisions(response)
                    .then(response => {
                        var userpermissions = response.data;
                        console.log({userpermissions});
                            if(userpermissions.includes('stopsales') || userpermissions.includes('all')) {
                            console.log('You are allowed!');
                            this.setState({
                                allowed: true
                            });
                        } else {
                            console.log('You are not allowed!');
                            this.setState({
                                allowed: false
                            });
                        }
                    })
                }
            })
            .catch(error => {
                window.location.href = '/login';
            });
    }

    getData = () => {
        console.log(this.state);
    }

    render(){
        var {data, product_id, product_name, loadingvalues, show, error, url} = this.state;

        if (this.state.allowed == false) {
          return <NoPermission />;
        }
        
        return(
            <>
                {/* <div className="row">
                    <div className="col-12">
                        <h3>Stop Sales</h3>
                        <p>Dear Partner, Following are the stop sales for the product.</p>
                        <hr />
                    </div>
                </div> */}
                <div className="row">
                    <div className='col'>
                        {loadingvalues == true &&
                            <p style={{ color: "#df153b", textAlign: "center", marginTop:"20px" }}><b><i>Loading Stop Sales. Please Wait....</i></b></p>
                        }
                        {show == true && 
                        <>
                            <Iframe url={url}
                                width="100%"
                                height="1000px"
                                id="myId"
                                className="myClassname"
                                display="initial"
                                position="relative"
                                frameBorder ="0" 
                                scrolling="no" 
                            />
                        </>
                        }
                        {error.status == true &&
                            <div className="alert alert-danger" role="alert">
                                {error.message}
                            </div>
                        }
                    </div>
                </div>
            </>
        );
    }
}