import React, { Component } from "react";
import Chip from '@mui/material/Chip';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import OrdersReportService from "../../services/orders/ordersreport.service";

export default class SendVoucherToSupplier extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderid: props.orderid,
            itemid: props.itemid,
            voucher_email_sent: props.voucher_email_sent,
            rerender: false,
            btntext: "Send to Supplier",
            disablesenttosupplierbtn: false,
            sendagainbtntext: "Supplier - Send Again",
            disablesendagainbtn: false,
            user: props.user,
            multipleSuppliers: props.multiplesuppliers,
            multipleSelectedCompany: "",
            multipleSelectedSupplier: "",
            btnActive: props.btnActive,
            item: props.item,
            travelpackage: props.travelpackage,
            opentravelmodal: false,
            selectedItems: [],
            sendEmailTravelBtnTxt: 'Send to Supplier',
            sendEmailTravelBtnDisbled: false,
            travelAlreadySent: [],
            previewTravelVoucherBtnTxt: 'Preview',
            previewTravelVoucherBtnDisabled: false,
            sendEmailTravelCusBtnTxt: "Send to Customer",
            sendEmailTravelCusBtnDisbled: false,
            totalSuppliers: props.total_suppliers
        }

        // console.log("multipleSuppliers", props.multipleSuppliers)
    }

    componentDidMount() {
        // if(("suppliers" in this.state.multipleSuppliers) && this.state.multipleSuppliers.suppliers.length==1) {
        //     var multipleSelectedSupplierTemp = [];
        //     multipleSelectedSupplierTemp[0] = this.state.multipleSuppliers.suppliers;
        //     this.setState({
        //         multipleSelectedSupplier: multipleSelectedSupplierTemp
        //     });
        // }
        console.log("SendVoucherToSupplier", this.state);
        
        if(("suppliers" in this.state.multipleSuppliers) && this.state.multipleSuppliers.suppliers.length>0 && this.state.multipleSuppliers.suppliers[0].length>1) {
            this.setState({
                disablesenttosupplierbtn: true
            });
        }

        if(this.state.travelpackage!=false) {
            this.getAlreadySent();
            var allSelected = [];
            allSelected.push({
                selected: false,
                key: "Checkin",
                value: this.state.item.start_date
            });
            allSelected.push({
                selected: false,
                key: "Checkout",
                value: this.state.item.end_date
            });
            allSelected.push({
                selected: false,
                key: "Duration",
                value: this.state.item.duration
            });
            allSelected.push({
                selected: false,
                key: "Persons",
                value: this.state.item.persons
            });
            allSelected.push({
                selected: false,
                key: "Room Type",
                value: this.state.item.room_type
            });
            allSelected.push({
                selected: false,
                key: "Meal Plan",
                value: this.state.item.meal_plan
            });
            allSelected.push({
                selected: false,
                key: "Guest Name",
                value: this.state.item.guest_name
            });
            allSelected.push({
                selected: false,
                key: "Special Requests",
                value: this.state.item.special_request
            });

            this.setState({
               selectedItems: allSelected
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.btnActive!=prevProps.btnActive) {
            this.setState({
                btnActive: this.props.btnActive
            });
        }
    }

    checkMulti = () => {
        if(("suppliers" in this.state.multipleSuppliers) && this.state.multipleSuppliers.suppliers[0].length>1) {
            if(this.state.multipleSelectedCompany=="" || this.state.multipleSelectedSupplier=='') {
                this.setState({
                    disablesenttosupplierbtn: true
                });
            } else {
                this.setState({
                    disablesenttosupplierbtn: false
                });
            }
        }
    }

    updateMultiSuppliers = (e, type) => {
        e.preventDefault();
        if(type=='company') {
            this.setState({
                multipleSelectedCompany: e.target.value,
                disablesenttosupplierbtn: true
            })
        } else if(type=='supplier') {
            this.setState({
                multipleSelectedSupplier: e.target.value,
                disablesenttosupplierbtn: true
            })
        }

        this.checkMulti();

        OrdersReportService.updateMultiSupplier(this.state.orderid, this.state.itemid, type, e.target.value, this.state.user.user_nicename)
        .then(res => {
            this.checkMulti();
        })
        .catch(error => console.log(error));
    }

    sendToSupplier = (e, again) => {
        e.preventDefault();
        if(again) {
            this.setState({
                sendagainbtntext: "Sending again...",
                disablesendagainbtn: true
            });
        } else {
            this.setState({
                btntext: "Sending voucher...",
                disablesenttosupplierbtn: true,
            });
        }

        OrdersReportService.sendVoucherToSupplier(this.state.orderid, this.state.itemid)
        .then(response => {
            // console.log("SendVoucherToCustomer", response);
            this.setState({
               btntext: "Send to Supplier",
                disablesenttosupplierbtn: false,
                sendagainbtntext: "Supplier - Send Again",
                disablesendagainbtn: false,
                voucher_email_sent: true
            });

            OrdersReportService.addLog(this.state.orderid, this.state.itemid, this.state.user.user_nicename, "Voucher Sent", "Voucher Sent to Suppier", "Sent Voucher# "+this.state.orderid+"-"+this.state.itemid)
            .then(response => {
                // console.log({response})
            })
            .catch(error => {console.log(error)});
        })
        .catch(error => console.log({error}));
    }

    openModalForTravelSending = (e) => {
        e.preventDefault();
        this.setState({
            opentravelmodal: true
        });
    }

    changeSelected = (e, index) => {
        var checked = e.target.checked;
        var tempSelectedItems = this.state.selectedItems;
        tempSelectedItems[index].selected = checked;
        this.setState({selectedItems: tempSelectedItems});
    }
    changeKey = (e, index) => {
        var key = e.target.value;
        var tempSelectedItems = this.state.selectedItems;
        tempSelectedItems[index].key = key;
        this.setState({selectedItems: tempSelectedItems});
    }
    changeValue = (e, index) => {
        var value = e.target.value;
        var tempSelectedItems = this.state.selectedItems;
        tempSelectedItems[index].value = value;
        this.setState({selectedItems: tempSelectedItems});
    }

    getAlreadySent = () => {
        OrdersReportService.getAlreadySentTravel(this.state.orderid, this.state.itemid)
        .then(res => {
            // console.log({res});
            this.setState({
                travelAlreadySent: res.data
            });
        })
        .catch(err => {
            console.log({err});
        });
    }

    SendToSupplierTravel = (e) => {
        this.setState({
            sendEmailTravelBtnTxt: 'Sending...',
            sendEmailTravelBtnDisbled: true
        });
        e.preventDefault();
        var randomName = Math.floor(Math.random() * (10000 - 10 + 1)) + 10;
        OrdersReportService.sendVoucherToSupplierTravel(this.state.orderid, this.state.itemid, this.state.multipleSelectedCompany, this.state.multipleSelectedSupplier, this.state.selectedItems, randomName, 'sup')
        .then(response => {
            // console.log("sendToSupplierTravel", response);
            this.getAlreadySent();
            this.setState({
                sendEmailTravelBtnTxt: 'Send to Supplier',
                sendEmailTravelBtnDisbled: false
            });
            OrdersReportService.addLog(this.state.orderid, this.state.itemid, this.state.user.user_nicename, "Voucher Sent", "Travel Voucher Sent to Suppier", "Sent Voucher# "+this.state.orderid+"-"+this.state.itemid+" to supplier "+this.state.multipleSelectedSupplier+" ("+this.state.multipleSelectedCompany+")")
            .then(response => {
                // console.log({response})
            })
            .catch(error => {console.log(error)});
        })
        .catch(error => console.log({error}));
    }

    sendToSupplierTravelCus = (e) => {
        this.setState({
            sendEmailTravelCusBtnTxt: 'Sending...',
            sendEmailTravelCusBtnDisbled: true
        });
        e.preventDefault();
        var randomName = Math.floor(Math.random() * (10000 - 10 + 1)) + 10;
        OrdersReportService.sendVoucherToSupplierTravel(this.state.orderid, this.state.itemid, this.state.multipleSelectedCompany, this.state.multipleSelectedSupplier, this.state.selectedItems, randomName, 'cus')
        .then(response => {
            // console.log("sendToSupplierTravel", response);
            // this.getAlreadySent();
            this.setState({
                sendEmailTravelCusBtnTxt: 'Send to Customer',
                sendEmailTravelCusBtnDisbled: false
            });
            OrdersReportService.addLog(this.state.orderid, this.state.itemid, this.state.user.user_nicename, "Voucher Sent", "Travel Voucher Sent to Customer", "Sent Voucher# "+this.state.orderid+"-"+this.state.itemid+" to supplier "+this.state.multipleSelectedSupplier+" ("+this.state.multipleSelectedCompany+")")
            .then(response => {
                // console.log({response})
            })
            .catch(error => {console.log(error)});
        })
        .catch(error => console.log({error}));
    }

    previewTravelVoucher = (e) => {
        this.setState({
            previewTravelVoucherBtnTxt: 'Generating...',
            previewTravelVoucherBtnDisabled: true
        });
        e.preventDefault();
        var randomName = Math.floor(Math.random() * (10000 - 10 + 1)) + 10;
        OrdersReportService.previewVoucherToSupplierTravel(this.state.orderid, this.state.itemid, this.state.multipleSelectedCompany, this.state.multipleSelectedSupplier, this.state.selectedItems, randomName, 'sup')
        .then(response => {
            // console.log("previewTravelVoucher", response);
            window.open("https://deals.mu/phantom/"+this.state.orderid+"-"+this.state.itemid+randomName+".pdf", '_blank');
            this.setState({
                previewTravelVoucherBtnTxt: 'Preview',
                previewTravelVoucherBtnDisabled: false
            });
        })
        .catch(error => console.log({error}));
    }

    render() {
        const style = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        };
        return (
            <>
            {(this.state.travelpackage==false || (("suppliers" in this.state.multipleSuppliers) && this.state.multipleSuppliers.suppliers.length>0 && this.state.multipleSuppliers.suppliers[0].length<2)) && 
                <>
                {this.state.voucher_email_sent==false && 
                <>
                    {("suppliers" in this.state.multipleSuppliers) && this.state.multipleSuppliers.suppliers[0].length>1 && 
                    <p style={{display: "flex", flexDirection: "column"}}>
                        <select className="form-control" value={this.state.multipleSelectedCompany} onChange={e=>{this.updateMultiSuppliers(e,'company')}}>
                            <option value="">Select Company</option>
                            {("companies" in this.state.multipleSuppliers) && this.state.multipleSuppliers.companies[0].map((company, index)=>(
                                <option key={index} value={company}>{company}</option>
                            ))}
                        </select>

                        <select className="form-control" value={this.state.multipleSelectedSupplier} onChange={e=>{this.updateMultiSuppliers(e,'supplier')}}>
                            <option value="">Select Supplier</option>
                            {("suppliers" in this.state.multipleSuppliers) && this.state.multipleSuppliers.suppliers[0].map((supplier, index2)=>(
                                <option key={index2} value={supplier}>{supplier}</option>
                            ))}
                        </select>
                    </p>
                    }
                    <button type="button" className="btn btn-sm btn-danger" disabled={this.state.disablesenttosupplierbtn || !this.state.btnActive} onClick={(e) => this.sendToSupplier(e, false)}>{this.state.btntext}</button>
                </>
                }
                {this.state.voucher_email_sent!=false && 
                <>
                    <Chip label="Supplier - Voucher Sent" color="success" style={{marginBottom: "5px"}} />
                    <button type="button" className="btn btn-sm btn-link" disabled={this.state.disablesendagainbtn} onClick={(e) => this.sendToSupplier(e, true)}>{this.state.sendagainbtntext}</button>
                </>
                } 
                </>
            }
            {(this.state.travelpackage!=false && ("suppliers" in this.state.multipleSuppliers) && this.state.multipleSuppliers.suppliers.length>0 && this.state.multipleSuppliers.suppliers[0].length>1) && 
                <>

                    {(this.state.travelAlreadySent.length==0 && this.state.voucher_email_sent!=false) && 
                    <>
                        <button type="button" className="btn btn-sm btn-danger" disabled={true} onClick={(e) => this.openModalForTravelSending(e)}>Already Sent to Suppliers</button>

                        <button type="button" className="btn btn-sm btn-link" onClick={(e) => this.openModalForTravelSending(e)}>Send Again or to more suppliers</button>
                    </>
                    }
                    
                    {this.state.travelAlreadySent.length==this.state.totalSuppliers && this.state.voucher_email_sent!=false && 
                        <button type="button" className="btn btn-sm btn-link" onClick={(e) => this.openModalForTravelSending(e)}>Send Again or to more suppliers</button>
                    }

                    {(this.state.travelAlreadySent.length!=this.state.totalSuppliers || this.state.voucher_email_sent==false) && 
                        <button type="button" className="btn btn-sm btn-danger" onClick={(e) => this.openModalForTravelSending(e)}>Send to Suppliers</button>
                    }

                    {this.state.travelAlreadySent.length>0 && this.state.travelAlreadySent.map((entry, index) => (
                        <p key={index} style={{fontSize: "0.8rem"}}>Already sent to {entry.supplier+" ("+entry.company+")"}</p>
                    ))}
                </>
            }

            <Modal
                open={this.state.opentravelmodal}
                onClose={e => {this.setState({opentravelmodal: false})}}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                style={{overflow: "scroll"}}
            >
                <Box sx={{...style, width: 800, textAlign: 'center', overflow: "scroll" }}>
                <h2 id="child-modal-title">Select Supplier and Attributes to Send</h2>
                <p id="child-modal-description" style={{marginBottom: "0px"}}>Please select relevant attributes per supplier.</p>
                <form onSubmit={(e) => this.SendToSupplierTravel(e)} style={{textAlign: "left"}}>
                    <div className="form-group">
                        <label for="couponToApply" className="col-form-label"><b>Select Company / Supplier</b></label>
                        <div className="">
                            <select className="form-control" value={this.state.multipleSelectedCompany} onChange={e=>{this.updateMultiSuppliers(e,'company')}}>
                            <option value="">Select Company</option>
                                {("companies" in this.state.multipleSuppliers) && this.state.multipleSuppliers.companies.length>0 && this.state.multipleSuppliers.companies[0].length>0 && this.state.multipleSuppliers.companies[0].map((company, index)=>(
                                    <option key={index} value={company}>{company}</option>
                                ))}
                            </select>
                            <select className="form-control" value={this.state.multipleSelectedSupplier} onChange={e=>{this.updateMultiSuppliers(e,'supplier')}} style={{marginTop: "10px"}}>
                                <option value="">Select Supplier</option>
                                {("suppliers" in this.state.multipleSuppliers) && this.state.multipleSuppliers.suppliers.length>0 && this.state.multipleSuppliers.suppliers[0].length>0 && typeof this.state.multipleSuppliers.suppliers[0]!='undefined' && this.state.multipleSuppliers.suppliers[0].map((supplier, index2)=>(
                                    <option key={index2} value={supplier}>{supplier}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                     <div className="form-group">
                        <div className="">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Include?</th>
                                        <th>Attribute</th>
                                        <th>Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.selectedItems.length>0 && this.state.selectedItems.map((attr, index) => (
                                    <tr key={index}>
                                        <td><input type="checkbox" checked={attr.selected} onChange={(e) => this.changeSelected(e, index)} /></td>
                                        <td><input type="text" className="form-control" value={attr.key} onChange={(e) => this.changeKey(e, index)} /></td>
                                        <td><input type="text" className="form-control" value={attr.value} onChange={(e) => this.changeValue(e, index)} /></td>
                                    </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <button type="button" className="btn btn-sm btn-warning" onClick={e=> this.previewTravelVoucher(e)} style={{marginRight: "20px"}} disabled={this.state.multipleSelectedSupplier=='' || this.state.multipleSelectedCompany=='' || this.state.previewTravelVoucherBtnDisabled}>{this.state.previewTravelVoucherBtnTxt}</button>
                        <button type="submit" className="btn btn-sm btn-success" style={{marginRight: "20px"}} disabled={this.state.multipleSelectedSupplier=='' || this.state.multipleSelectedCompany=='' || this.state.sendEmailTravelBtnDisbled}>{this.state.sendEmailTravelBtnTxt}</button>
                        <button type="button" className="btn btn-sm btn-primary" style={{marginRight: "20px"}} disabled={this.state.multipleSelectedSupplier=='' || this.state.multipleSelectedCompany=='' || this.state.sendEmailTravelCusBtnDisbled} onClick={(e)=>this.sendToSupplierTravelCus(e)}>{this.state.sendEmailTravelCusBtnTxt}</button>
                        <button type="button" className="btn btn-sm btn-danger" onClick={e => {this.setState({opentravelmodal: false})}}>Close</button>
                    </div>
                </form>
                </Box>
            </Modal>
            </>
        );
    }
}