import React, { Component } from "react";
import Loader from 'react-loader-spinner';
import DTable from './dtable.component';
import DatePicker from "react-datepicker";
import $ from 'jquery';

import AuthService from '../services/auth/auth.service';
import UtilityService from "../services/utility.service";
import ExpiryReportService from "../services/erp/expiryreport.service"
import swal from "sweetalert";

import UserContext from "../contexts/UserContext";
import NoPermission from "./extras/nopermission.component";
export default class ExpiryReport extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            columnsheadings: [],
            options: {
                filterType: 'dropdown',
            },
            show: true,
            error: {
                status: false,
                message: ''
            },
            loadingvalues: false,
            user: [],
            search_btn_text: 'Search',
            loader: false,
            status: '0',
            searchStatus : '0',
            searchAge : 'NA',
            searchCategories: [],
            searchTaskStatus: '0',
            allowed: true,
            searchReminder: "All"
        };
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleChangeMultiple(e) {
        var options = e.target.options;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
            value.push(options[i].value);
            }
        }
        this.setState({
            searchCategories: value
        });
    }

    componentDidMount() {
        AuthService.checklogin().then(user => {

            this.setState({
                user: user
            });
            AuthService.getUserPermisions(user)
            .then(response => {
                var userpermissions = response.data;
                console.log({userpermissions});
                    if(userpermissions.includes('expiryreport') || userpermissions.includes('all')) {
                    console.log('You are allowed!');
                    this.setState({
                        allowed: true
                    });
                } else {
                    console.log('You are not allowed!');
                    this.setState({
                        allowed: false
                    });
                }
            })
            // this.loadResults(true);
        }).catch(error => {
            window.location.href = '/login';
        });
    }

    loadResults = (all) => {
        
        if(this.state.searchStatus=='0' && this.state.searchAge=='NA' && this.state.searchCategories.length<1 && this.state.searchTaskStatus=='0' && this.state.searchTaskStatus=='All' ) {
            swal('Please Select Filter', "Please select atleast one filter to search", 'error');
        } else {

            this.setState({
                data: [],
                show: false,
                error: {
                    status: false,
                    message: ''
                },
                loadingvalues: true,
            });
            console.log(this.state);

            ExpiryReportService.getIssues(this.state.searchStatus, this.state.searchAge, this.state.searchCategories, this.state.searchTaskStatus, this.state.searchReminder, this.state.user.user_email)
            .then(response => {
                console.log({response});
                if(response.data) {

                    this.setState({
                        data: response.data,
                        columnsheadings: [
                            { title: "Product Name" },
                            { title: "Category" },
                            { title: "Supplier (Company)" },
                            { title: "Status" },
                            { title: "Expiration Date" },
                            { title: "Expiration Age" },
                            { title: "Actions" },
                            { title: "Reminders" },
                            { title: "Task Status" },
                        ],
                        show: true,
                        error: {
                            status: false,
                            message: ''
                        },
                        loadingvalues: false, 
                    });
                } else {
                    this.setState({
                        data: [],
                        columnsheadings: [],
                        show: false,
                        error: {
                            status: true,
                            message: 'No data found'
                        },
                        loadingvalues: false,
                    });
                }
            })
            .catch(error => {
                this.setState({
                    data: [],
                    columnsheadings: [],
                    show: false,
                    error: {
                        status: true,
                        message: 'No data found'
                    },
                    loadingvalues: false,
                });
            });
        }
    }


    render() {
        var { data, columnsheadings, options, show, error, loadingvalues, search_btn_text } = this.state;

        if (this.state.allowed == false) {
          return <NoPermission />;
        }
        
        return (
            <>
                <div className="row">
                    <div className='col'>
                        <h2>Expiration Reports:</h2>
                        <hr />
                    </div>
                </div>
                <div className="row">
                    <div className='col'>
                        {loadingvalues == true &&
                            <p style={{ color: "#df153b" }}><b><i>Loading Report. Please Wait....</i></b></p>
                        }

                        {loadingvalues == false && 
                            <p style={{ color: "black" }}><b>Please choose the actions below to generate report</b></p>
                        }

                         <form action="" method="get" className="booking-reports-search-form" style={{ display: 'flex' }}>
                            <input type="hidden" name="page" value="deals-expiry-report" />
                            
                            <div style={{ marginLeft: '10px' }}>
                                <select name="searchStatus" className="select-drops form-control" value={this.state.searchStatus} onChange={this.handleChange.bind(this)}>
                                    <option value="0">All Status</option>
                                    <option value="active">Active</option>
                                    <option value="expired">Expired</option>
                                    <option value="archived">Archived</option>
                                </select>
                            </div>

                            <div style={{ marginLeft: '10px' }}>
                                <select name="searchAge" className="select-drops form-control" value={this.state.searchAge} onChange={this.handleChange.bind(this)}>
                                    <option value="NA">Expiration Age</option>
                                    <option value="p-31">Expired 31+ days</option>
                                    <option value="30-0">Expired 0-30 days </option>
                                    <option value="0-30">0 - 30 days</option>
                                    <option value="0-60">0-60 days</option>
                                    <option value="0-90">0-90 days</option>
                                </select>
                            </div>

                            <div style={{ marginLeft: '10px' }}>
                                <select name="searchCategories" className="select-drops form-control" value={this.state.searchCategories} onChange={this.handleChangeMultiple.bind(this)} multiple="multiple">
                                    <option value="24">Activities</option>
                                    <option value="28">Events</option>
                                    <option value="27">Hotel Day Packages</option>
                                    <option value="23">Hotels</option>
                                    <option value="30">Restaurants</option>
                                    <option value="98">Rodrigues Accommodation</option>
                                    <option value="99">Rodrigues Activities</option>
                                    <option value="105">Rodrigues Spa</option>
                                    <option value="26">Spa</option>
                                </select>
                            </div>

                            <div style={{ marginLeft: '10px' }}>
                                <select name="searchTaskStatus" className="select-drops form-control" value={this.state.searchTaskStatus} onChange={this.handleChange.bind(this)}>
                                    <option value="0">All Task Status</option>
                                    <option value="pending">Pending</option>
                                    <option value="completed">Completed</option>
                                </select>
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                <select name="searchReminder" className="select-drops form-control" value={this.state.searchReminder} onChange={this.handleChange.bind(this)}>
                                    <option value="All">Show All With/Without Reminder</option>
                                    <option value="Yes">Show only with Reminders</option>
                                    {/* <option value="completed">Completed</option> */}
                                </select>
                            </div>

                            <div style={{ marginLeft: '10px' }}>
                                <button type="button" className="btn btn-success btn-large booking-search-btn expiry-search-btn" onClick={this.loadResults}>{search_btn_text}</button>
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                <a href="/expiry-report" className="btn btn-large btn-warning booking-reset-btn">Reset</a>
                            </div>

                        </form>
                        <hr />
                    </div>
                </div>
                <div className="row">
                    <div className='col'>
                        {error.status == true &&
                            <div className="alert alert-danger" role="alert">
                                {error.message}
                            </div>
                        }
                        {(show == true) &&
                            <DTable data={data} columns={columnsheadings} />
                        }
                        {show == false &&
                            <Loader className='loader-center'
                                type="Grid"
                                color="#00BFFF"
                                height={100}
                                width={100}
                            />
                        }
                    </div>
                </div>
                

                <div className="modal fade" id="create-task-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Create Task:</h5>
                        <button type="button" className="btn-close modal-close-btn" data-bs-dismiss="modal" aria-label="Close">X</button>
                    </div>
                    <form id="create-task-form" method="post">
                        <input type="hidden" id="create_task_product_id" name="create_task_product_id" value="" />
                        <input type="hidden" id="create_task_have_reminder" name="create_task_have_reminder" value="no" />
                        <div className="modal-body">
                             <div className="mb-3">
                                <a href="" id="create-task-product-view" target="_blank">View Product</a>
                            </div>
                            <div className="mb-3">
                                <label for="create_task_task_details" className="form-label"><b>Task Details</b></label>
                                <textarea className="form-control" name="create_task_task_details" id="create_task_task_details" aria-describedby="linkDetailsHelp" required="required"></textarea>
                                <div id="linkDetailsHelp" className="form-text">Enter Task Details</div>
                            </div>
                            <div className="mb-3" style={{display: "none"}}>
                                <label for="create_task_link_to_task" className="form-label">Link to Task</label>
                                <input type="text" className="form-control" name="create_task_link_to_task" id="create_task_link_to_task" aria-describedby="linkToTaskHelp" />
                                <div id="linkToTaskHelp" className="form-text">Add the link to the task</div>
                            </div>
                            <div className="mb-3">
                                <label for="create_task_future" className="form-label"><b>Is it a Future Task?</b></label>
                                <select id="create_task_future" name="create_task_future" className="form-select">
                                    <option value="">No</option>
                                    <option value="Yes">Yes</option>
                                </select>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary modal-close-btn" data-bs-dismiss="modal">Close</button>
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </div>
                    </form>
                    </div>
                </div>
                </div>

                <div className="modal fade" id="edit-task-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Edit Task:</h5>
                        <button type="button" className="btn-close modal-close-btn" data-bs-dismiss="modal" aria-label="Close">X</button>
                    </div>
                    <form id="edit-task-form" method="post">
                        <input type="hidden" id="edit_task_product_id" name="edit_task_product_id" value="" />
                        <input type="hidden" id="edit_task_task_id" name="edit_task_task_id" value="" />
                        <div className="modal-body">
                            <div className="mb-3">
                                <label for="edit_task_task_details" className="form-label">Task Details</label>
                                <textarea className="form-control" name="edit_task_task_details" id="edit_task_task_details" aria-describedby="linkDetailsHelp" required="required" disabled="disabled"></textarea>
                                <div id="linkDetailsHelp" className="form-text">Enter Task Details</div>
                            </div>
                            <div className="mb-3">
                                <label for="edit_task_link_to_task" className="form-label">Link to Task</label>
                                <input type="text" className="form-control" name="edit_task_link_to_task" id="edit_task_link_to_task" aria-describedby="linkToTaskHelp"  disabled="disabled" />
                                <div id="linkToTaskHelp" className="form-text">Add the link to the task</div>
                            </div>
                            <div className="mb-3">
                            <label for="edit_task_status" className="form-label">Task Status</label>
                            <select id="edit_task_status" name="edit_task_status" className="form-select">
                                <option value="pending">Pending</option>
                                <option value="completed">Completed</option>
                            </select>
                            </div>
                            <div className="mb-3">
                            <label for="edit_task_future" className="form-label">Is it a Future Task?</label>
                            <select id="edit_task_future" name="edit_task_future" className="form-select">
                                <option value="">No</option>
                                <option value="Yes">Yes</option>
                            </select>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <hr />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12" style={{display: "flex", justifyContent: 'center', marginTop: '10px'}}>
                                    <button type="button" className="btn btn-warning btn-sm expiry-view-history-btn">View History</button>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 expiry-report-history-wrapper" style={{display: "flex", marginTop: '10px'}}>
                                    
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary modal-close-btn" data-bs-dismiss="modal">Close</button>
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </div>
                    </form>
                    </div>
                </div>
                </div>

                <div className="modal fade" id="add-comment-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Add Comment:</h5>
                        <button type="button" className="btn-close modal-close-btn" data-bs-dismiss="modal" aria-label="Close">X</button>
                    </div>
                    <form id="add-comment-form" method="post">
                        <input type="hidden" id="add_comment_product_id" name="add_comment_product_id" value="" />
                        <div className="modal-body">
                            <div className="mb-3">
                                <label for="add_comment_comment" className="form-label">Comment</label>
                                <textarea className="form-control" name="add_comment_comment" id="add_comment_comment" aria-describedby="addCommentHelp" required="required"></textarea>
                                <div id="addCommentHelp" className="form-text">Add Comment</div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary modal-close-btn" data-bs-dismiss="modal">Close</button>
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </div>
                    </form>
                    </div>
                </div>
                </div>

                <div className="modal fade" id="view-comments-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">View Comments:</h5>
                        <button type="button" className="btn-close modal-close-btn" data-bs-dismiss="modal" aria-label="Close">X</button>
                    </div>
                    <div className="modal-body" id="view-comments-modal-body">
                    </div>
                    </div>
                </div>
                </div>

                <div className="modal fade" id="expiry-report-add-reminder-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Add Reminder:</h5>
                        <button type="button" className="btn-close modal-close-btn" data-bs-dismiss="modal" aria-label="Close">X</button>
                    </div>
                    <form id="add-expiry-reminder-form" method="post">
                        <div className="modal-body">
                            <input type="hidden" name="add_expiry_reminder_prod_id" id="add_expiry_reminder_prod_id" value="0" />
                            <div className="mb-3">
                                <label for="add_expiry_reminder_details" className="form-label"><b>Details</b></label>
                                <textarea className="form-control" name="add_expiry_reminder_details" id="add_expiry_reminder_details" required="required"></textarea>
                            </div>
                            <div className="mb-3">
                                <label for="add_expiry_reminder_show_from" className="form-label"><b>Reminder me</b></label>
                                <input type="text" className="form-control date-picker" name="add_expiry_reminder_show_from" id="add_expiry_reminder_show_from" placeholder="When to show this reminder"  required="required" />
                            </div>
                            {/* <div className="mb-3">
                                <label for="add_expiry_reminder_show_to" className="form-label">Show Reminder Till:</label>
                                <input type="text" className="form-control date-picker" name="add_expiry_reminder_show_to" id="add_expiry_reminder_show_to" placeholder="Till when to show this reminder"  required="required" />
                            </div> */}
                            <div className="mb-3">
                                <label for="add_expiry_reminder_status" className="form-label"><b>Status</b></label>
                                <select id="add_expiry_reminder_status" name="add_expiry_reminder_status" className="form-select">
                                    <option value="pending">Pending</option>
                                    <option value="completed">Completed</option>
                                </select>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary modal-close-btn" data-bs-dismiss="modal">Close</button>
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </div>
                    </form>
                    </div>
                </div>
                </div>

                <div className="modal fade" id="expiry-report-update-reminder-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Update Reminder:</h5>
                        <button type="button" className="btn-close modal-close-btn" data-bs-dismiss="modal" aria-label="Close">X</button>
                    </div>
                    <form id="update-expiry-reminder-form" method="post">
                        <div className="modal-body">
                            <input type="hidden" name="update_expiry_reminder_id" id="update_expiry_reminder_id" value="0" />
                            <div className="mb-3">
                                <label for="update_expiry_reminder_details" className="form-label"><b>Details</b></label>
                                <textarea className="form-control" name="update_expiry_reminder_details" id="update_expiry_reminder_details" required="required"></textarea>
                            </div>
                            <div className="mb-3">
                                <label for="update_expiry_reminder_show_from" className="form-label"><b>Reminder me</b></label>
                                <input type="text" className="form-control date-picker" name="update_expiry_reminder_show_from" id="update_expiry_reminder_show_from" placeholder="When to show this reminder" required="required" />
                            </div>
                            {/* <div className="mb-3">
                                <label for="update_expiry_reminder_show_to" className="form-label">Show Reminder Till:</label>
                                <input type="text" className="form-control date-picker" name="update_expiry_reminder_show_to" id="update_expiry_reminder_show_to" placeholder="Till when to show this reminder"  required="required" />
                            </div> */}
                            <div className="mb-3">
                                <label for="update_expiry_reminder_status" className="form-label"><b>Status</b></label>
                                <select id="update_expiry_reminder_status" name="update_expiry_reminder_status" className="form-select" required="required">
                                    <option value="pending">Pending</option>
                                    <option value="completed">Completed</option>
                                </select>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary modal-close-btn" data-bs-dismiss="modal">Close</button>
                            <button type="submit" className="btn btn-primary">Update</button>
                        </div>
                    </form>
                    </div>
                </div>
                </div>
                {typeof this.state.user.user_nicename!='undefined' && 
                    <input id="expr-username" type="hidden" value={this.state.user.user_nicename} />
                }
            </>
        );
    }
}