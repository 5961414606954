import React, { useEffect, useState } from 'react'
import { Modal, Box } from '@mui/material';
import axios from 'axios';
import swal from 'sweetalert';

import Pagination from './pagination.component';
import AuthService from '../services/auth/auth.service';
import NoPermission from './extras/nopermission.component';

const APP_URL = "https://deals.mu/scripts/reportsapi/corporatesaccounts.php";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const CorporateNotifications = () => {

    const [open, setOpen] = useState(false);
    const [disableapplybtn, setDisableapplybtn] = useState(true);
    const [applybtntext, setApplybtntext] = useState("Apply");
    const [pageState, setPageState] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState("Loading...");
    const [content, setContent] = useState("");
    const [limit, setLimit] = useState(20);
    const [user, setUser] = useState({});
    const [allowed, setAllowed] = useState(false);

    useEffect(() => {
       getNotifications(pageState);
    }, [pageState]);

    useEffect(() => {
        AuthService.checklogin().then(user => {
            setUser(user);

            console.log({ user });
            AuthService.getUserPermisions(user)
            .then(response => {
                var userpermissions = response.data;
                console.log({userpermissions});
                    if(userpermissions.includes('corporate') || userpermissions.includes('all')) {
                    console.log('You are allowed!');
                    setAllowed(true);
                    getNotifications(pageState);
                } else {
                    console.log('You are not allowed!');
                    setAllowed(false);
                }
            })
        }).catch(error => {
            window.location.href = '/login';
        });
    }, []);

    const getNotifications = () => {
        setData([]);

        var params = {
            page: pageState,
            limit: limit,
            action: "getCorporateNotifications"
        }
        axios.post(APP_URL, {params})
        .then((response) => {
            console.log("getcorporatenotifications", response.data);
            if (response.data.status === "success") {
                setPageCount(response.data.count);
                setData(response.data.notifications);

                if(response.data.notifications.length === 0) {
                    setLoading("No Notifications Found");
                }
            } 
        })
        .catch((error) => {
            swal("Error", error.message, "error");
        });
    }

    const showContent = (e, content, id) => {
        e.preventDefault();
        setContent(content);
        setOpen(true);
    }

  return (
    <>
    {allowed == false &&  
        <NoPermission />
    }
    {allowed == true &&  
    <div>
        <div className="row" style={{width: "100%"}}>
            <div className='col' style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <h1 className="orders-h1" style={{marginBottom: "0px"}}>Corporate Notifications</h1>
                    <p style={{marginBottom: "0px"}}>send manual emails, notifications and messages to our corporate customers</p>
                </div>
                <div style={{ marginLeft: '10px' }}>
                    <a href="/create-corporate-notification" className="btn btn-large btn-success">Send a New Email</a> 
                </div>
            </div>
        </div>

        <div className="row" style={{width: "100%"}}>
            <div className="col">
                <hr />
            </div>
        </div>

        <div className="row" style={{width: "100%"}}>
            <div className="col">
                <table className="table table-bordered table-hover">
                    <thead>
                        <tr>
                            <th>Date Sent</th>
                            <th>Notification Subject</th>
                            <th>Email Type</th>
                            <th>Number of contacts</th>
                            <th>Opening Rate</th>
                            <th>Number of Clicks</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.length > 0 ? data.map((item, index) => (
                            <tr key={index}>
                                <td style={{fontWeight: "bold"}}>{item.date_sent}</td>
                                <td>{item.subject}</td>
                                <td><a href="#" onClick={e => showContent(e, item.content, item.id)}>{item.type}</a></td>
                                <td>{item.no_of_contacts}</td>
                                <td>{item.opening_rate}%</td>
                                <td>{item.no_of_clicks}</td>
                            </tr>
                        )) : 
                            <tr><td colSpan="6"><b style={{color: "red"}}>{loading}</b></td></tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>

        <div className="row" style={{width: "100%"}}>
            <div className="col">
                <Pagination current={pageState} onPageChange={(page) => setPageState(page)} total={pageCount} limit={limit} />
            </div>
        </div>

        <Modal
            open={open}
            onClose={e => {setOpen(false);}}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
        >
            <Box sx={{...style, width: 800, textAlign: 'center' }}>
            <h4 id="child-modal-title">Email Content</h4>
            <div dangerouslySetInnerHTML={{__html: content}}></div> 
            <button className="btn btn-large btn-danger" style={{marginTop: "30px", borderRadius: "0px"}} onClick={e => {setOpen(false);}}>Close</button>
            </Box>
        </Modal>
    </div>
    }
    </>
  )
}

export default CorporateNotifications;