import axios from "axios";
import { uniq } from 'lodash';

const API_URL = "https://deals.mu/scripts/reportsapi/receivables.php";

class ReceivablesService {
    getAll(type, startDate, endDate) {
       return this.getResults(0, type, startDate, endDate)
            .then(response => {

                // console.log({response});

                var result = {
                    data: []
                };
                let promises = [];
                var size = parseFloat(response.data);

                var loopSize = Math.ceil(parseFloat(size / 20));
                if(loopSize<1) {
                    loopSize = 1;
                }
                for (var i = 1; i <= loopSize; i++) {

                    promises.push(
                        this.getResults(i, type, startDate, endDate)
                            .then(response1 => {

                                result.data = result.data.concat(response1.data);
                            })
                            .catch(error1 => {
                                console.log({ error1 });
                            })
                    );
                }

                return Promise.all(promises).then(() => {
                    // console.log({result});
                    return result;
                });

            }).catch(error => {

                return false;
            });
    }

    getResults(page, type, startDate, endDate) {
        var params = {
            action: 'get_all',
            page: page,
            type: type,
            startDate: startDate,
            endDate: endDate
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

    saveInvoice(invoiceType, companyName, companyBrn, companyVat, customerName, customerEmail, customerPhone, customerAddress, invoiceDate, dueDate, paymentDate, productDetails, paymentReference, paymentMethod, comment, editID) {
        var params = {
            action: 'save_invoice',
            invoiceType: invoiceType,
            companyName: companyName,
            companyBrn: companyBrn,           
            companyVat: companyVat,
            customerName: customerName,
            customerEmail: customerEmail,
            customerPhone: customerPhone,
            customerAddress: customerAddress,
            invoiceDate: invoiceDate,
            dueDate: dueDate,
            paymentDate: paymentDate,
            productDetails: productDetails,
            paymentReference: paymentReference,
            paymentMethod: paymentMethod,
            comment: comment,
            editID: editID,
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }
}

export default new ReceivablesService();