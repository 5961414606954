import React, { Component } from "react";
import Chip from '@mui/material/Chip';
import OrdersReportService from "../../services/orders/ordersreport.service";

export default class SendVoucherToCustomer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderid: props.orderid,
            itemid: props.itemid,
            voucher_email_sent_to_cus: props.voucher_email_sent_to_cus,
            rerender: false,
            btntext: "Send to Customer",
            disablesenttocustomerbtn: false,
            sendagainbtntext: "Customer - Send Again",
            disablesendagainbtn: false,
            user: props.user,
            btnActive: props.btnActive
        }
    }

    sendToCustomer = (e, again) => {
        e.preventDefault();
        if(again) {
            this.setState({
                sendagainbtntext: "Sending again...",
                disablesendagainbtn: true
            });
        } else {
            this.setState({
                btntext: "Sending voucher...",
                disablesenttocustomerbtn: true,
            });
        }

        OrdersReportService.sendVoucherToCustomer(this.state.orderid, this.state.itemid)
        .then(response => {
            // console.log("SendVoucherToCustomer", response);
            this.setState({
               btntext: "Send to Customer",
                disablesenttocustomerbtn: false,
                sendagainbtntext: "Customer - Send Again",
                disablesendagainbtn: false,
                voucher_email_sent_to_cus: true
            });

            OrdersReportService.addLog(this.state.orderid, this.state.itemid, this.state.user.user_nicename, "Voucher Sent", "Voucher Sent to Customer", "Sent Voucher# "+this.state.orderid+"-"+this.state.itemid)
            .then(response => {
                // console.log({response})
            })
            .catch(error => {console.log(error)});
        })
        .catch(error => console.log({error}));
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.btnActive!=prevProps.btnActive) {
            this.setState({
                btnActive: this.props.btnActive
            });
        }
    }

    render() {
        return (
            <>
            {this.state.voucher_email_sent_to_cus==false && 
                <button type="button" className="btn btn-sm btn-danger" disabled={this.state.disablesenttocustomerbtn || !this.state.btnActive} onClick={(e) => this.sendToCustomer(e, false)}>{this.state.btntext}</button>
            }
            {this.state.voucher_email_sent_to_cus!=false && 
            <>
                <Chip label="Customer - Voucher Sent" color="success" style={{marginBottom: "5px"}} />
                <br />
                <button type="button" className="btn btn-sm btn-link" disabled={this.state.disablesendagainbtn} onClick={(e) => this.sendToCustomer(e, true)}>{this.state.sendagainbtntext}</button>
            </>
            } 
            </>
        );
    }
}