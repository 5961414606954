import React, { Component } from "react";
import { useHistory } from 'react-router-dom';

import DTable from './dtable.component';
import UserService from "../services/auth/user.service";
import AuthService from '../services/auth/auth.service';

export default class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            content: "",
            loggedin:false
        };

        
    }

    componentDidMount() {
        // UserService.getPublicContent().then(
        //     response => {
        //         this.setState({
        //             content: response.data
        //         });
        //     },
        //     error => {
        //         this.setState({
        //             content:
        //                 (error.response && error.response.data) ||
        //                 error.message ||
        //                 error.toString()
        //         });
        //     }
        // );

        

        this.setState({
            content: "<h1>Welcome to the dashboard for deals.mu</h1><p>Please select an option from above menu to continue</p>"
        });

        AuthService.checklogin().then(user => {
            // console.log({ user });
        }).catch(error => {
            // console.log({ error });
            window.location.href = '/login';
        });
    }
    logmein = () => {
        window.location.href = '/bookings-report';
    }

    render() {
        return (
            <>
            <div className="container-fluid">
                    <header className="jumbotron" dangerouslySetInnerHTML={{ __html: this.state.content }}>
                    {/* {this.state.content} */}
                </header>
                    {/* <button className='btn btn-primary' onClick={this.logmein}>Log Me In</button> */}
            </div>
            <div className='row'>
                <div className='col-12'>
                    <DTable />
                </div>
            </div>
            </>
        );
    }
}