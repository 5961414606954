import React, { Component } from "react";
import Loader from 'react-loader-spinner';
import DTable from './dtable.component';

import AuthService from '../services/auth/auth.service';
import UtilityService from "../services/utility.service";
import UserContext from "../contexts/UserContext";
import NoPermission from "./extras/nopermission.component";

export default class SuppliersBankDetails extends Component {
    static contextType = UserContext
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            data: [],
            columnsheadings: [],
            allowed: true,
             error: {
                status: false,
                message: ''
            },
            loader: true,
        };
    }

    componentDidMount() {

        AuthService.checklogin().then(user => {
            AuthService.getUserPermisions(user)
            .then(response => {
                var userpermissions = response.data;
                console.log({userpermissions});
                    if(userpermissions.includes('suppliersbanksdetails') || userpermissions.includes('all')) {
                    console.log('You are allowed!');
                    this.setState({
                        allowed: true
                    });
                    this.getAll();
                } else {
                    console.log('You are not allowed!');
                    this.setState({
                        allowed: false
                    });
                }
            })
        }).catch(error => {
            window.location.href = '/login';
        });
    }

    getAll = () => {
        this.setState({
            loader: true
        });
        UtilityService.getSuppliersBankDetails()
            .then(response => {
                console.log({ response });
                if (response.data.length > 0) {
                    this.setState({
                        data: response.data,
                        columnsheadings: [
                            { title: "#" },
                            { title: "Company" },
                            { title: "Supplier" },
                            { title: "Account No" },
                            { title: "Bank" },
                            { title: "Branch" },
                            { title: "Holder Name" },
                            { title: "Active?" },
                            { title: "Created" },
                        ],
                        show: true,
                        loader: false,
                        error: {
                            status: false,
                            message: ''
                        },
                    });
                } else {
                    this.setState({
                        show: false,
                        loader: false,
                        error: {
                            status: true,
                            message: 'No records found'
                        },
                    });
                }
            })
            .catch(error => {
                console.log({ error });
            });
    }


    render() {

        let { show, loader, data, columnsheadings, error } = this.state;

        if (this.state.allowed == false) {
          return <NoPermission />;
        }
        
        return (
            <>
            <div className="row">
                <div className='col'>
                        <h2>Deals Suppliers' Bank Details</h2>
                    <hr />
                </div>
            </div>
            <div className='row'>
                <div className='col-12'>
                    
                    {error.status == true &&
                        <div className="alert alert-danger" role="alert">
                            {error.message}
                        </div>
                    }
                    {loader == true &&
                        <Loader className='loader-center'
                            type="Grid"
                            color="#00BFFF"
                            height={100}
                            width={100}
                        />
                    }
                    
                    {show==true && 
                        <DTable data={data} columns={columnsheadings} paginationTotalRows={50} className="comm-report-table" />
                    }
                </div>
            </div>
            
            </>
        );
    }
}