import React, { Component } from "react";
import DatePicker from "react-datepicker";
import Loader from 'react-loader-spinner';
import DTable from './dtable.component';
import Select from 'react-select';

import AuthService from '../services/auth/auth.service';
import PaymentReportsService from '../services/erp/paymentreports.service';
import BookingReportsService from '../services/erp/bookingreports.service';
import UtilityService from '../services/utility.service';

import "react-datepicker/dist/react-datepicker.css";
import UserContext from "../contexts/UserContext";
import NoPermission from "./extras/nopermission.component";

export default class BookingReports extends Component {
    static contextType = UserContext
    constructor(props) {
        super(props);

        this.state = {
          data: [],
          columnsheadings: [],
          options: {
            filterType: "dropdown",
          },
          show: false,
          products: [{ ID: 0, post_title: "Select a Product" }],
          suppliers: [{ value: 0, label: "Select a Supplier" }],
          companies: [{ value: 0, label: "Select a Company" }],
          startdate: "",
          suppliername: { value: 0, label: "Select a Supplier" },
          enddate: "",
          productname: { value: 0, label: "Select a Product" },
          companyname: { value: 0, label: "Select a Company" },
          orderstatus: 0,
          loadingvalues: true,
          error: {
            status: false,
            message: "",
          },
          loader: false,
          search_btn_text: "Search",
          minDate: new Date("02-01-2019"),
          allowed: true,
          order_id: ''
        };

        // this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
        // console.log('handle change');
    }

    componentDidMount() {

        PaymentReportsService.getAllSuppliers()
            .then(response => {
                // console.log({ response });
                if (response.data.suppliers) {
                    this.setState({
                        suppliers: response.data.suppliers,
                        companies: response.data.companies,
                        products: response.data.products,
                        loadingvalues: false
                    });
                    // console.log('i am getsupds');
                }
            });
        
        AuthService.checklogin().then(user => {
            // console.log({ user });
            AuthService.getUserPermisions(user)
            .then(response => {
                var userpermissions = response.data;
                console.log({userpermissions});
                    if(userpermissions.includes('bookingreports') || userpermissions.includes('all')) {
                    console.log('You are allowed!');
                    this.setState({
                        allowed: true
                    });
                } else {
                    console.log('You are not allowed!');
                    this.setState({
                        allowed: false
                    });
                }
            })
        }).catch(error => {
            // console.log({ error });
            window.location.href = '/login';
        });
    }

    searchClick(e) {
        e.preventDefault();
        // console.log('The link was clicked.');
        this.setState({
            show: false,
            error: {
                status: false,
                message: ''
            },
            search_btn_text: 'Fetching..',
            loader: true
        });

        var startday ='';
        var endday = '';

        if(this.state.startdate!='' && this.state.enddate!='') {

            startday = UtilityService.format_date(this.state.startdate);
            endday = UtilityService.format_date(this.state.enddate);
        }

        console.log(this.state);

        BookingReportsService.getReport(startday, endday, this.state.productname.value, this.state.suppliername.value, this.state.companyname.value, this.state.orderstatus, this.state.order_id)
            .then(response => {
                console.log({ response });
                if (typeof response.data=='undefined' ) {
                    this.setState({
                        error: {
                            status: true,
                            message: 'No data found for the selection, Please retry or change your selections.'
                        },
                        search_btn_text: 'Search',
                        loader: false
                    });
                } else if (response.data.length > 0) {

                    var totalNights = 0;
                    var totalRefunds = 0;
                    var totalAmount = 0;

                    for(var i=0; i<response.data.length; i++) {
                        if(response.data[i][7]!='') {
                            totalNights += parseInt(response.data[i][7]);
                        }

                        // console.log(parseFloat(response.data[i][10].replace('Rs ', '')));
                        totalRefunds += parseFloat(response.data[i][10].replace('Rs ', ''));
                        // console.log(parseFloat(response.data[i][8].replace('Rs ', '')));
                        totalAmount += parseFloat(response.data[i][8].replace('Rs ', ''));
                    }

                    var totalsArray = ['<b>Totals</b>', '', '', '', '', '', '', totalNights, 'Rs ' + totalAmount, '', 'Rs ' + totalRefunds, '', '', '', ''];
                    response.data.push(totalsArray);

                    this.setState({
                        data: response.data,
                        columnsheadings: [
                            { title: "Order" },
                            { title: "Product" },
                            { title: "Customer" },
                            { title: "Email" },
                            { title: "Order Date" },
                            { title: "Booking Dates" },
                            { title: "Booking Details" },
                            { title: "Nights" },
                            { title: "Amount" },
                            { title: "Order Total" },
                            { title: "Refunds" },
                            { title: "Status" },
                            { title: "Supplier Confirmed" },
                            { title: "Payment Method" },
                            { title: "View Order" },
                        ],
                        options: {
                            filterType: 'dropdown',
                        },
                        show: true,
                        search_btn_text: 'Search',
                        loader: false
                    });
                    console.log(response.data);
                } else {
                    this.setState({
                        error: {
                            status: true,
                            message: 'No data found for the selection, Please retry or change your selections.'
                        },
                        search_btn_text: 'Search',
                        loader: false
                    });
                }
            });
    }

    supChange = (selectedOption) => {
        this.setState({ suppliername: selectedOption });
        // console.log(`Option selected:`, selectedOption);
    }

    setStartDate = (date) => {
        this.setState({
            startdate: date,
            minDate: date,
        });

        if (this.state.enddate != '' && this.state.enddate < date) {
            this.setState({
                enddate:date
            });
        }
        // console.log(this.state);
    }

    setEndDate = (date) => {

        this.setState({
            enddate: date
        });
        // console.log(this.state);
    }
    handleChangeProduct = selectedOption => {
        this.setState({ productname: selectedOption });
        // console.log(`Option selected:`, selectedOption);
    };

    handleChangeSuplier = selectedOption => {
        this.setState({ suppliername: selectedOption });
        // console.log(`Option selected:`, selectedOption);
    };

    handleChangCompany = selectedOption => {
        this.setState({ companyname: selectedOption });
        // console.log(`Option selected:`, selectedOption);
    };

    render() {
        var { data, columnsheadings, options, show, products, suppliers, companies, loadingvalues, error, search_btn_text, loader } = this.state;

        if (this.state.allowed == false) {
          return <NoPermission />;
        }

        return (
            <>
                <div className="row">
                    <div className='col'>
                        <h1>Deals Booking Report</h1>
                        <p><strong><i>Please select the options below to retrieve Bookings</i></strong> <i> If you select a booking date range, it is compulsory/highly recommended to select either product or supplier name or company name. If you select product or supplier or company you can leave the the date empty. Only date search is not recommended. (Always select either supplier or product or campany - one of them - not all)</i></p>
                        <hr />
                    </div>
                </div>
                <div className="row">
                    <div className='col'>
                        {loadingvalues == true &&
                            <p style={{ color: "#df153b" }}><b><i>Loading Products/Suppliers. Please Wait....</i></b></p>
                        }
                        <form action="" method="get" className="booking-reports-search-form" style={{ display: 'flex' }}>
                            <input type="hidden" name="page" value="deals-payment-report" />
                            <div>
                                <input className="form-control" placeholder="Order ID" value={this.state.order_id} name="order_id" onChange={this.handleChange.bind(this)} />
                            </div>
                            <div>
                               
                                <DatePicker
                                    dateFormat="dd-MM-yyyy"
                                    selected={this.state.startdate}
                                    onChange={date => this.setStartDate(date)}
                                    placeholderText="Start Date"
                                    className='form-control'
                                />
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                               
                                <DatePicker
                                    dateFormat="dd-MM-yyyy"
                                    selected={this.state.enddate}
                                    onChange={date => this.setEndDate(date)}
                                    placeholderText="End Date"
                                    className='form-control'
                                    minDate = {this.state.minDate}
                                />
                            </div>
                            <div style={{ marginLeft: '10px', width: '300px' }}>
                                
                                <Select
                                    value={this.state.companyname}
                                    onChange={this.handleChangCompany}
                                    options={companies}
                                />    
                            </div>
                            <div style={{ marginLeft: '10px', width: '300px' }}>
                                <Select
                                    value={this.state.suppliername}
                                    onChange={this.handleChangeSuplier}
                                    options={suppliers}
                                />
                            </div>
                            <div style={{ marginLeft: '10px', width: '300px' }}>
                                <Select
                                    value={this.state.productname}
                                    onChange={this.handleChangeProduct}
                                    options={products}
                                />
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                <select name="orderstatus" className="select-drops form-control" value={this.state.orderstatus} onChange={this.handleChange.bind(this)}>
                                    <option value="0">All Order Status</option>
                                    <option value="wc-completed">Completed</option>
                                    <option value="wc-processing">Processing</option>
                                    <option value="wc-pending">Pending</option>
                                    <option value="wc-on-hold">On-hold</option>
                                    <option value="wc-cancelled">Cancelled</option>
                                    <option value="wc-refunded">Refunded</option>
                                    <option value="wc-failed">Failed</option>
                                </select>
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                <button className="btn btn-success btn-large booking-search-btn" onClick={this.searchClick.bind(this)}>{search_btn_text}</button>
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                <a href="/bookings-report" className="btn btn-large btn-warning booking-reset-btn">Reset</a>
                            </div>

                        </form>

                        <hr />
                    </div>
                </div>
                <div className="row">
                    <div className='col'>
                        <div className="container-fluid">
                            {error.status == true &&
                                <div className="alert alert-danger" role="alert">
                                    {error.message}
                                </div>
                            }
                            {(show == true) &&
                                <DTable data={data} columns={columnsheadings} />
                            }
                            {loader == true &&
                                <Loader className='loader-center'
                                    type="Grid"
                                    color="#00BFFF"
                                    height={100}
                                    width={100}
                                />
                            }
                        </div>
                    </div>
                </div>
            </>
        );
    }
}