import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import styled from 'styled-components';

const APP_URL = "https://deals.mu/scripts/reportsapi/ordersreport.php";

const DelButton = styled.button`
    color: #BF4F74 !important;
    font-size: 1em !important;
    padding: 0.05em 0.5em !important;
    border: 2px solid #BF4F74 !important;
    border-radius: 0px !important;
    background: white !important;
    height: 38px !important;
    &:hover {
        background: #BF4F74 !important;
        color: white !important;
        cursor: pointer !important;
        border: 2px solid #fff !important;
    }
`;

const EditOrderItemModal = ({ orderid, itemID, changedata }) => {
    // const orderItemData = orderItem;
    const [orderID, setOrderID] = useState(orderid);
    const [orderItemID, setOrderItemID] = useState(itemID);
    const [orderItem, setOrderItem] = useState(null);
    const [orderItemName, setOrderItemName] = useState("");
    const [orderItemPrice, setOrderItemPrice] = useState("");
    const [orderItemTotal, setOrderItemTotal] = useState(0);
    const [startdate, setStartDate] = useState("");
    const [enddate, setEndDate] = useState("");
    const [quantity, setQuantity] = useState(1);
    const [mealplan, setMealPlan] = useState("");
    const [room, setRoom] = useState("");
    const [persons , setPersons] = useState("");
    const [specialrequests, setSpecialRequests] = useState("");
    const [productType, setProductType] = useState("");
    const [booking, setBooking] = useState([]);
    const [rerender, setRerender] = useState(false);
    const [guestName, setGuestName] = useState("");
    const [openVoucher, setOpenVoucher] = useState(false);
    const editButtonRef = useRef(null);
    const [toRemove, setToRemove] = useState([]);
    const [itemStatus, setItemStatus] = useState("");

    // Reset state when the orderItem prop changes
    const loadData = () => {
        setOrderItem(null);
        setOrderItemName("");
        setOrderItemPrice("");
        setOrderItemTotal(0);
        setStartDate("");
        setEndDate("");
        setQuantity(1);
        setMealPlan("");
        setRoom("");
        setPersons("");
        setSpecialRequests("");
        setProductType("");
        setBooking([]);
        setGuestName("");
        setOpenVoucher("0");
        setToRemove([]);
        setItemStatus("");
        
        if(orderItemID>0) {
            const params = {
                orderid: orderID,
                itemid: orderItemID,
                action: "getOrderDetailsProductsByID"
            };
            axios.post(APP_URL, {params})
            .then((response) => {
                console.log("getOrderDetailsProductsByID", response);
                if(response.data != null){
                    var data = response.data;
                    setOrderItem(response.data);
                    setOrderItemName(data.product_name);
                    setOrderItemPrice(data.item_Line_subtotal);
                    setOrderItemTotal(data.item_Line_total);
                    setStartDate(data.start_date);
                    setEndDate(data.end_date);
                    setQuantity(1);
                    setMealPlan("");
                    setRoom("");
                    setPersons("");
                    setSpecialRequests(data.special_request);
                    setProductType(data.product_type);
                    setBooking([]);
                    setGuestName(data.guest_name);
                    setItemStatus(data.item_status);
                    if(typeof data._qty !== 'undefined'){
                        setQuantity(data._qty);
                    }
                    if(typeof data.item_Line_subtotal !== 'undefined'){
                        setOrderItemPrice(parseInt(data.item_Line_subtotal.replace(',', '')));
                    }
                    if(typeof data.item_Line_total !== 'undefined'){
                        setOrderItemTotal(parseInt(data.item_Line_total.replace(',', '')));
                    }
                    if(typeof data.meal_plan !== 'undefined'){
                        setMealPlan(data.meal_plan);
                    }
                    if(typeof data.room_type !== 'undefined'){
                        setRoom(data.room_type);
                    }
                    if(typeof data.persons !== 'undefined'){
                        setPersons(data.persons);
                    }
                    if(typeof data.open_voucher !== 'undefined'){
                        if(data.open_voucher==true) {
                            setOpenVoucher("1");
                        }
                    }
                    if(typeof data.bookings !== 'undefined'){
                        // setBooking([...data.bookings]);

                        var tempBooking = [];
                        data.bookings.map((booking, index) => {
                            tempBooking.push({key: booking.key, value: booking.value});
                        });
                        setBooking([...tempBooking]);
                    }
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    };

    const handleSubmitOrderItem = (e) => {
        // console.log("handleSubmitOrderItem");
        e.preventDefault();

        const params = {
            order_id: orderItem.order_id,
            order_item_id: orderItem.item_id,
            product_id: orderItem.product_id,
            product_name: orderItemName,
            product_type: productType,
            item_Line_subtotal: orderItemPrice,
            item_Line_total: orderItemTotal,
            start_date: startdate,
            end_date: enddate,
            quantity: quantity,
            meal_plan: mealplan,
            room_type: room,
            persons: persons,
            // special_request: specialrequests,
            booking: booking,
            guest_name: guestName,
            open_voucher: openVoucher,
            toRemove: toRemove,
            itemStatus: itemStatus,
            action: "saveOrderItem"
        };
        axios.post(APP_URL, {params})
        .then((response) => {
            // console.log(response);
            if(response.data.status == "success"){
                swal("Success!", response.data.message, "success");
                closePopup();
                // window.location.reload();
                changedata();
            }
        }).catch((error) => {
            console.log(error);
        });
    }


    const openPopup = () => {
        const popup = document.getElementById("popup"+orderItemID);
        popup.classList.toggle("show");
        setRerender(!rerender);
        loadData();
    };

    const closePopup = () => {
        const popup = document.getElementById("popup"+orderItemID);
        popup.classList.remove("show");
        setOrderItem(null);
        setOrderItemName("");
        setOrderItemPrice("");
        setOrderItemTotal(0);
        setStartDate("");
        setEndDate("");
        setQuantity(1);
        setMealPlan("");
        setRoom("");
        setPersons("");
        setSpecialRequests("");
        setProductType("");
        setBooking([]);
        setGuestName("");
        setOpenVoucher("0");
        setToRemove([]);
        setItemStatus("");
    };

    const updateBooking = (index, key, value) => {
        // console.log({index, key, value});
        var newBooking = [...booking];
        newBooking[index] = {key: key, value: value};
        setBooking(newBooking);
        // console.log({newBooking});
    }

    const updateBookingKey = (index, key) => {
        var newBooking = [...booking];
        newBooking[index]["key"] = key;
        setBooking(newBooking);
    }

    const removeAttribute = (e, key, value) => {
        e.preventDefault();
        var toRemoveLocal = toRemove;
        toRemoveLocal.push({key: key, value: value});
        setToRemove([...toRemoveLocal]);

        var newBooking = booking;
        newBooking = newBooking.filter((item) => item.key !== key && item.value !== value);
        setBooking([...newBooking]);
    }

    return (
        <div key={itemID}>
        <button ref={editButtonRef} onClick={e => openPopup()} type="button" className="btn btn-danger btn-sm">
            Edit Item
        </button>
        <div className="popup" id={"popup"+orderItemID}>
            <div className="popup-content">
                <span className="close" onClick={e => closePopup()} id="closePopup">&times;</span>
                <h4>Edit Order Item</h4>
                <hr />
                <form onSubmit={e => handleSubmitOrderItem(e)}>
                    <input type="hidden" name="order_id" value={orderItemID} />
                <div className='form-group' style={{display: "flex", justifyContent: "flex-start"}}>
                    <label for="productName"><b>Product Name:</b></label>
                    <input type="text" className='form-control' placeholder="Enter product name" value={orderItemName} onChange={e => setOrderItemName(e.target.value)} />
                </div>
                <div className='form-group' style={{display: "flex", justifyContent: "flex-start"}}>
                    <label for="productName"><b>Sub Total:</b></label>
                    <input type="number" className='form-control' placeholder="Enter product sub-total" value={orderItemPrice} onChange={e => setOrderItemPrice(e.target.value)} />
                </div>
                <div className='form-group' style={{display: "flex", justifyContent: "flex-start"}}>
                    <label for="productName"><b>Total:</b></label>
                    <input type="number" className='form-control' placeholder="Enter product total" value={orderItemTotal} onChange={e => setOrderItemTotal(e.target.value)} />
                </div>
                <div className='form-group' style={{display: "flex", justifyContent: "flex-start"}}>
                    <label for="quantity"><b>Quantity:</b></label>
                    <input type="number" className='form-control' disabled={true} placeholder="Enter product Quantity" value={quantity} onChange={e => setQuantity(e.target.value)} />
                </div>
                <div className='form-group' style={{display: "flex", justifyContent: "flex-start"}}>
                    <label for="productName"><b>{(productType=="booking") ? "Booking Date":"Start Date"}:</b></label>
                    <input type="text" className='form-control' placeholder="Enter Start Date" value={startdate} onChange={e => setStartDate(e.target.value)} />
                </div>
               

                {productType=="accommodation-booking" && 
                <>
                    <div className='form-group' style={{display: "flex", justifyContent: "flex-start"}}>
                        <label for="productName"><b>End Date:</b></label>
                        <input type="text" className='form-control' placeholder="Enter End Date" value={enddate} onChange={e => setEndDate(e.target.value)} />
                    </div>
                    <div className='form-group' style={{display: "flex", justifyContent: "flex-start"}}>
                        <label for="productName"><b>Room Type:</b></label>
                        <input type="text" className='form-control' placeholder="Enter Room Type" value={room} onChange={e => setRoom(e.target.value)} />
                    </div>
                    <div className='form-group' style={{display: "flex", justifyContent: "flex-start"}}>
                        <label for="productName"><b>Meal Plan:</b></label>
                        <input type="text" className='form-control' placeholder="Enter Meal Plan" value={mealplan} onChange={e => setMealPlan(e.target.value)} />
                    </div>
                    <div className='form-group' style={{display: "flex", justifyContent: "flex-start"}}>
                        <label for="productName"><b>Persons:</b></label>
                        <input type="text" className='form-control' placeholder="Enter Persons" value={persons} onChange={e => setPersons(e.target.value)} />
                    </div>
                </>
                }

                {productType=="booking" && 
                <>
                {booking.length>0 && booking.map((bookings, index) => (
                    <>
                    <div key={index} className='form-group' style={{display: "flex", justifyContent: "flex-start"}}>
                        {/* {bookings.key!="" && 
                            <label for="productName"><b>{bookings.key}:</b></label>
                        }
                        {bookings.key=="" && */}
                            <input type="text" style={{marginRight: "10px"}} className='form-control' placeholder="Enter Attribute" value={bookings.key} onChange={e => updateBookingKey(index, e.target.value)} />
                        {/* } */}
                        <input type="text" className='form-control' style={{marginRight: "10px"}} placeholder={"Enter value"} value={bookings.value} onChange={e => updateBooking(index, bookings.key, e.target.value)}  />
                        <DelButton type="button" className='' onClick={e => removeAttribute(e, bookings.key, bookings.value)}>Delete</DelButton>
                    </div>
                    </>
                ))}
                <button style={{marginBottom: "20px", background: "orange"}} className='btn btn-sm btn-warning' type="button" onClick={e => setBooking([...booking, {key: "", value: ""}])}>Add More Attributes</button>

                <div  className='form-group' style={{display: "flex", justifyContent: "flex-start"}}>
                    <label for="productName"><b>Open Voucher:</b></label>
                    <select className='form-control' value={openVoucher} onChange={e => setOpenVoucher(e.target.value)}>
                        <option value="0">No</option>
                        <option value="1">Yes</option>
                    </select>
                </div>
                </>
                }

                <div className='form-group' style={{display: "flex", justifyContent: "flex-start"}}>
                    <label for="productName"><b>Guest Name:</b></label>
                    <input type="text" className='form-control' placeholder="Enter Guest Name" value={guestName} onChange={e => setGuestName(e.target.value)} />
                </div>

                {(productType=="booking" || productType=="accommodation-booking") && 
                    <div className='form-group' style={{display: "flex", justifyContent: "flex-start"}}>
                        <label for="productName"><b>Booking Status:</b></label>
                        <select className='form-control' value={itemStatus} onChange={e => setItemStatus(e.target.value)}>
                            <option value="unpaid">Unpaid</option>
                            <option value="pending-confirmation">Pending Confirmation</option>
                            <option value="confirmed">Confirmed</option>
                            <option value="paid">Paid</option>
                            <option value="complete">Complete</option>
                            {/* <option value="in-cart">In Cart</option> */}
                            <option value="cancelled">Cancelled</option>
                        </select>
                    </div>
                }

                <button type="submit" id="saveChanges">Save Changes</button>
                <br />
                <button type="button" onClick={e=>closePopup(e)} className="btn btn-danger btn-sm" style={{backgroundColor: "#dc3545"}}>Close</button>
                </form>
            </div>
        </div>
        </div>
    );

};

export default EditOrderItemModal;
