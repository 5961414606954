import React, { Component } from "react";
import swal from 'sweetalert';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import GiftCardsService from "../../services/cms/giftcards.service";

export default class ChooseGiftCardImage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            images: [],
            selectedImage: "", 
            imagesToDisplay: 9,
            disableViewMoreBtn: false
        }
    }

    componentDidMount() {
        this.getImages();
    }

    getImages = () => {
        this.setState({
            disableViewMoreBtn: true
        });
        GiftCardsService.getBackgroundImages()
        .then(response => {
            console.log("get images", response);
            this.setState({
                images: response.data,
                disableViewMoreBtn: false
            });
        })
        .catch(error => {
            console.log({error});
        });
    }

    viewMore = (e) => {
        e.preventDefault();
        this.setState({
            imagesToDisplay: this.state.images.length-1
        })
        console.log("view more +");
    }

    viewLess = (e) => {
        e.preventDefault();
        this.setState({
            imagesToDisplay: 9
        })
        console.log("view less -");
    }

    selectImage = (e, image) => {
        e.preventDefault();
        this.setState({
            selectedImage: image
        });

        this.props.onChange(image);
    }

    render() {
        return (
            <>
            <div style={{display: "flex", flexWrap: "wrap"}}>
                {this.state.images.length>0 && this.state.images.slice(0, this.state.imagesToDisplay).map((image, index) => (
                    <img key={index} src={image} className={"choosimage-image "+((this.state.selectedImage==image) ? "choosimage-image-selected": "")} alt="giftcard generated" onClick={e => this.selectImage(e, image)} />
                ))}
                {(this.state.imagesToDisplay<(this.state.images.length-1) || this.state.images.length==0) && 
                    <button className="viewmoregiftcard" onClick={(e) => this.viewMore(e)} disabled={this.state.disableViewMoreBtn}>
                    {this.state.images.length>0 &&  
                        <>View More <FontAwesomeIcon icon={faArrowRight} /></>
                    }
                    {this.state.images.length<1 &&  
                        <>Loading...</>
                    }
                    </button>
                }

                {(this.state.imagesToDisplay==(this.state.images.length-1)) && 
                    <button className="viewmoregiftcard" onClick={(e) => this.viewLess(e)}>
                    {this.state.images.length>0 &&  
                        <>View Less <FontAwesomeIcon icon={faArrowLeft} /></>
                    }
                    </button>
                }
            </div>
            </>
        );
    }
}