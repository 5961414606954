import React, { useState } from 'react';
import Select from 'react-select';
import axios from 'axios';
import AsyncSelect from 'react-select/async';

const APIURL = 'https://deals.mu/scripts/reportsapi/transactions-sheet.php';

export default function TransactionRow({key, row, index, username, allStatus}) {

    const [currentRow, setCurrentRow] = useState(row);
    const [updating, setUpdating] = useState(false);
    const [allOrderIDs, setAllOrderIDs] = useState([]);
    const [bgClass, setBgClass] = useState(row.status.value.toLowerCase().replace(/\s+/g, '-'));
    // console.log({currentRow});

    // remove first index in allStatus
    allStatus = allStatus.slice(1);

    const highlightNumber = (input) => {
        // Trim extra spaces and full stops
        const text = input.trim().replace(/[.]+$/, "");
        // Regular expression to match numbers with space on both sides or at the beginning/end
        const regex = /\b\d+\b/;
        // Find the number using regex
        const match = text.match(regex);
        if (match) {
            const highlighted = text.replace(regex, `<span style="background-color: yellow">${match[0]}</span>`);
            return highlighted;
        }
        return text;
    };

    const loadOptions = (inputValue, callback) => {
        if (!inputValue) {
        callback([]); // No input, no options
        return;
        }

        var params = {
            action: 'get_order_ids',
            search: inputValue
        }

        // Simulate API call
        axios
        .post(APIURL, {params})
        .then((response) => {
            console.log(response.data);
            const filteredOptions = response.data;
            // .filter((item) => item.name.toLowerCase().includes(inputValue.toLowerCase())).map((order) => ({
            //     label: order.label,
            //     value: order.value,
            // }));
            callback(filteredOptions); // Provide the options back to AsyncSelect
        })
        .catch((error) => {
            console.error('Error fetching data:', error);
            callback([]);
        });
    };

    
    // const fetchAllOrderIDs = (input) => {
    //     var params = {
    //         action: 'get_order_ids',
    //         search: input
    //     }
    //     axios.post(APIURL, {params})
    //     .then(response => {
    //         setAllOrderIDs(response.data);
    //     });
    // }

    const handleEdit = (id, field, value) => {
        setUpdating(true);
        if(field === 'status') {
            var bgColor = value.value.toLowerCase().replace(/\s+/g, '-');
            setBgClass(bgColor);
        }       

        console.log({id, field, value});
        var params = {
            action: 'editRow',
            id: id,
            field: field,
            value: value,
            username: username
        }
        axios.post(APIURL, {params})
        .then(response => {
            console.log(response.data);
            if (response.data.status === 'success') {
                if(field === 'status') {
                    bgColor = value.value.toLowerCase().replace(/\s+/g, '-');
                    setBgClass(bgColor);
                }
                var tempRow = currentRow;
                tempRow[field] = value;
                tempRow.agent = username;
                tempRow.bgclass = bgColor;
                setCurrentRow(prev => tempRow);
                console.log({tempRow});
                setUpdating(false);
            }
        })
        .catch(error => {
            console.log(error);
        });
    }

    const processAgent = (agent) => {
        const lowercased = agent.toLowerCase();
        const replaced = lowercased.replace(/abz/g, "").replace(/-/g, "");
        const ucfirst = (str) => {
            if (str.length === 0) {
                return str;
            }

            return str.charAt(0).toUpperCase() + str.slice(1);
        };

        return ucfirst(replaced);
    }

    return (
        <>
        <tr key={index} className={(updating===true) ? "row-bg-updating" : "row-bg-"+bgClass}>
            <td>
                <select className="form-control" value={currentRow.account} onChange={e => handleEdit(currentRow.id, 'account', e.target.value)}>
                    <option value="DEALS SIMPLY">DEALS SIMPLY</option>
                    <option value="ABZ ACC">ABZ ACC</option>
                </select>
                <br />
                <div style={{fontSize: "12px"}}>
                    <span><i>Added On: {currentRow.added_on}</i></span><br />
                    <span><i>Added By: {currentRow.added_by}</i></span>
                </div>
            </td>
            <td>{currentRow.date}</td>
            <td>{currentRow.reference}</td>
            <td>
                {currentRow.order_ids=="" && 
                    <div dangerouslySetInnerHTML={{__html: highlightNumber(currentRow.transactiontext)}}></div>
                }
                {currentRow.order_ids!="" && 
                    <div>{currentRow.transactiontext}</div>
                }
            </td>
            <td><b>Rs.{currentRow.amount}</b></td>
            <td>{processAgent(currentRow.agent)}<br />{(updating===true) && <span className="text-success"><b>Updating...</b></span>}</td>
            <td>
                <textarea className="form-control mb-10" placeholder='comma separated order IDs' rows={2} value={currentRow.order_ids} onChange={e => setCurrentRow({...currentRow, "order_ids": e.target.value})}></textarea>
                {currentRow.order_ids=="" && 
                    <button className="btn btn-primary btn-sm" onClick={e => handleEdit(currentRow.id, 'order_ids', currentRow.order_ids)}>Update</button>
                }
                {currentRow.order_ids!="" && 
                    <button className="btn btn-warning btn-sm" onClick={e => handleEdit(currentRow.id, 'order_ids', currentRow.order_ids)}>Update</button>
                }
                {/* {currentRow.order_ids} */}
                {/* <div style={{width: "250px"}}>
                {currentRow.order_ids.length==0 && 
                <AsyncSelect 
                    // cacheOptions
                    loadOptions={loadOptions}
                    defaultOptions={[]}
                    onChange={e => handleEdit(currentRow.id, 'order_ids', e)}
                    isMulti
                    placeholder="Type to search..."
                />
                }
                {currentRow.order_ids.length>0 && 
                <AsyncSelect 
                    // cacheOptions
                    loadOptions={loadOptions}
                    defaultOptions={[]}
                    value={currentRow.order_ids}
                    onChange={e => handleEdit(currentRow.id, 'order_ids', e)}
                    isMulti
                    placeholder="Typing to search..."
                />
                }
                </div> */}
            </td>
            <td>
                <div style={{width: "200px"}}>
                    <Select
                        options={allStatus}
                        value={currentRow.status}
                        onChange={e => handleEdit(currentRow.id, 'status', e)}
                    />
                </div>
            </td>
            <td>
                <textarea className="form-control mb-10" rows={2} value={currentRow.comment} onChange={e => setCurrentRow({...currentRow, "comment": e.target.value})}></textarea>
                {currentRow.comment=="" && 
                    <button className="btn btn-primary btn-sm" onClick={e => handleEdit(currentRow.id, 'comment', currentRow.comment)}>Update</button>
                }
                {currentRow.comment!="" && 
                    <button className="btn btn-warning btn-sm" onClick={e => handleEdit(currentRow.id, 'comment', currentRow.comment)}>Update</button>
                }
            </td>
        </tr>
        </>
    );
}
