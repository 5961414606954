import PaymentReportsService from '../../services/erp/paymentreports.service';
import InvoicesServiceNew from "../../services/erp/invoicesnew.service";
import React, { useState, useEffect } from "react";
import swal from 'sweetalert';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '5%',
    left: '10%',
    right: 'auto',
    bottom: 'auto',
    // marginRight: '-50%',
    // transform: 'translate(-50%, -50%)',
    width: "80%",
    overflow: "scroll",
    // marginTop:"200px"
  },
};

function SupplierDetails(props) {
    const [product, setProduct] = useState(props.id);
    const [details, setDetails] = useState([]);
    const [texttoshow, setTexttoshow] = useState("");
    const [modalIsOpen, setIsOpen] = useState(false);
    const [productName, setProductName] = useState(props.label);
    const [relatedProducts, setRelatedProducts] = useState([]);
    const [showRelatedProducts, setShowRelatedProducts] = useState(false);

    let subtitle;

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    useEffect(() => {
        PaymentReportsService.getAllSuppliersPerProduct(product)
        .then(resp => {
            console.log("Productssuppliers", resp);
            setDetails(resp.data);
            openModal();
            if(resp.data.companies.length>0 && resp.data.companies.length<2){
               InvoicesServiceNew.fetchProductsPost(resp.data.companies[0], resp.data.suppliers[0])
                .then(resp => {
                    console.log("Products", resp);
                    setRelatedProducts(resp.data);
                })
                .catch(err => {
                    console.log({err});
                });
            }
            // swal("Product Suppliers!", textToShow, "info");
        })
        .catch(err => {
            console.log({err});
        })
    }, [product]);

    const closeModal = () => {
        props.closeModal();
        setIsOpen(false);
    } 

    function openModal() {
        setIsOpen(true);
    }

    return (
        <>
        <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h3 style={{textAlign: "center", textDecoration: "underline"}} ref={(_subtitle) => (subtitle = _subtitle)}>{productName}</h3>
        
        <h5>Products Suppliers:</h5>
        <table className="table table-striped table-bordered">
            <thead>
                <tr>
                    <th>Company</th>
                    <th>Supplier</th>
                </tr>
            </thead>
            <tbody>
                {typeof details.companies!="undefined" && details.companies.map((company, index) => (
                    <tr key={index}>
                        <td>{company}</td>
                        <td>{details.suppliers[index]}</td>
                    </tr>
                ))}
            </tbody>
        </table>
        {typeof details.departurePoint!="undefined" && details.departurePoint!="" && 
            <>
                <h6>Departure Point:</h6>
                <p dangerouslySetInnerHTML={{__html: details.departurePoint}}></p>
                <hr />
            </>
        }
        <h5>More Details:</h5>
        <table className="table table-striped table-bordered">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Company</th>
                    <th>Supplier</th>
                    <th>BRN</th>
                    <th>Vat</th>
                    <th>Emails</th>
                    <th>Phone</th>
                </tr>
            </thead>
            <tbody>
                {typeof details.details!="undefined" && details.details.map((detail, index) => (
                    <tr key={index}>
                        <td>{detail.ID}</td>
                        <td>{detail.company}</td>
                        <td>{detail.supplier}</td>
                        <td>{detail.brn}</td>
                        <td>{detail.vat}</td>
                        <td>{detail.emails.join(", ")}</td>
                        <td>{detail.phones.join(", ")}</td>
                    </tr>
                ))}
            </tbody>
        </table>

        {/* <h5>Commissions:</h5>
        <table className="table table-striped table-bordered">
            <thead>
                <tr>
                    <th>Company</th>
                    <th>Supplier</th>
                    <th>Commission</th>
                    <th>Price Type</th>
                    <th>From</th>
                    <th>To</th>
                    <th>Products</th>
                </tr>
            </thead>
            <tbody>
                {typeof details.commisions!="undefined" && details.commisions.map((commision, index) => (
                    <tr key={index}>
                        <td>{commision.company}</td>
                        <td>{commision.supplier}</td>
                        <td>{commision.commission} ({commision.type})</td>
                        <td>{commision.prices_type}</td>
                        <td>{commision.from}</td>
                        <td>{commision.to}</td>
                        <td>{commision.product_label}</td>
                    </tr>
                ))}
            </tbody>
        </table> */}
        <div style={{display: "flex", justifyContent:"flex-start", alignItems: "center"}}>
            <h5>Related Products:</h5>
            <button className='btn btn-warning btn-sm' style={{fontWeight: "bold", marginLeft:"20px"}} onClick={e=>setShowRelatedProducts(!showRelatedProducts)}>{(showRelatedProducts) ? "Hide Products": "Show Products"}</button>
        </div>
        {showRelatedProducts && 
        <table className="table table-striped table-bordered">
            <thead>
                <tr>
                    <th>Product</th>
                    <th>Category</th>
                    <th>Link</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                {relatedProducts.length>0 && relatedProducts.map((product, index) => (
                    <tr key={index}>
                        <td>{product.label}</td>
                        <td>{product.category}</td>
                        <td><a href={product.link} target="_blank">Link</a></td>
                        <td>{product.status}</td>
                    </tr>
                ))}
            </tbody>
        </table>
        }
            
        <button className='btn btn-danger btn' style={{marginTop:"10px", float: "right", fontWeight: "bold"}} onClick={closeModal}>Close</button>
      </Modal>
        </>
    );
}

export default SupplierDetails;