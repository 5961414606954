import React, { Component } from "react";
import { Chart as ChartJS, LinearScale, CategoryScale, BarElement, PointElement, LineElement, Legend, Tooltip, LineController, BarController, Title } from 'chart.js';
import { Chart, Bar } from 'react-chartjs-2';

import CorporateNewService from '../../services/crm/corporatereportsnew.service';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

export default class Corporatecharts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userID: props.userID,
            chart1Data: null,
            chart2Data: null,
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        CorporateNewService.getCorporateCharts(this.state.userID)
        .then(response => {
            // console.log("chart data", response.data);
            const chart1Labels = response.data.chartOneLabels;
            const chart1data = {
                chart1Labels,
                datasets: [
                    {
                        type: 'line',
                        label: 'Number of Orders',
                        borderColor: 'rgb(255, 99, 132)',
                        borderWidth: 2,
                        fill: false,
                        data: response.data.chartOneData2,
                    },
                    {
                        type: 'bar',
                        label: 'Order Amount',
                        backgroundColor: 'rgb(75, 192, 192)',
                        data:  response.data.chartOneData1,
                        borderColor: 'white',
                        borderWidth: 2,
                    },
                ],
            };

            const chart2Labels = response.data.chartTwoLabels;
            const chart2data = {
                chart2Labels,
                datasets: [
                    {
                        label: 'Last Year Sales',
                        backgroundColor: 'rgb(255, 99, 132)',
                        data: response.data.chartTwoData2,
                    },
                    {
                        label: 'This Year Sales',
                        backgroundColor: 'rgb(75, 192, 192)',
                        data:  response.data.chartTwoData1,
                    },
                ],
            };

            this.setState({
                chart1Data: chart1data,
                chart2Data: chart2data,
            })
        })
        .catch(error => {
            console.log("chart data error", error);
        });
    }

    
    render() {
        const options = {
            responsive: true,
            plugins: {
                legend: {
                position: 'top',
                },
            },
        };
        return (
            <div className='row' style={{marginBottom: "20px"}}>
                <div className="col">
                {this.state.chart1Data!=null && 
                    <Chart type='bar' data={this.state.chart1Data} />
                }
                </div>
                <div className="col">
                {this.state.chart2Data!=null && 
                    <Bar options={options} data={this.state.chart2Data} />
                }
                </div>
            </div>
        )
    }
}