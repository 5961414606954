/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Router, Switch, Route, Link } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import "./App.css";

import Login from "./components/login.component";
import Home from "./components/home.component";
import CommissionSheet from "./components/commission_sheet";
import PaymentReports from "./components/payment_reports";
import BookingReports from "./components/bookingreport.component";
import ProductLogs from "./components/productlogs.component";
import Invoices from "./components/invoices.component";
import InvoiceEmails from "./components/invoiceemails.component";
import Refunds from "./components/refunds.component";
import Credits from "./components/credits.component";
import RefundsReport from "./components/refundsreport.component";
import CreditsReport from "./components/creditsreport.component";
import UserProfile from "./components/crm/profile.component";
import UserReports from "./components/crm/userreports.component";
import Notifications from "./components/appnotifications.component";
import Suppliers from "./components/suppliers.componet";
import Receivables from "./components/receivables.component";
import SupplierContracts from "./components/suppliercontracts.component";
import TaskManager from "./components/taskmanager.component";
import Products from "./components/products"
import StopSales from "./components/stopsales";
import CorporateCustomers from "./components/crm/corporatecustomers.component";
import CorporateCustomerDetails from "./components/crm/corporatecustomerdetails.component";
import Influencers from "./components/crm/influencers.component";
import InfluencerCustomerDetails from "./components/crm/influencerdetails.component";
import Issues from "./components/issues.component";
import SmsMarketing from "./components/smsmarketing.component";
import UploadedInvoices from "./components/uploadedinvoices.component";
import ExpiryReport from "./components/expiryreports.component";
import SlidersCovers from "./components/sliderscovers.component";
import GiftCardGenerator from "./components/giftcardsgenerator.component";
import OrdersReport from "./components/ordersreport.component";
import OrderEdit from "./components/orderedit.component";
import SuppliersBankDetails from "./components/suppliersbankdetails.component";
import JuiceAutomation from "./components/juiceautomation.component";
import Loyaltypoints from "./components/loyaltypoints.component";
import Corporate from "./components/crm/corporate.component";
import CorporateCustomerDetailsNew from "./components/crm/corporatecustomerdetailsnew.component";
import CorporateNotifications from "./components/corporatenotifications.component";
import CreateCorporateNotifications from "./components/createcorporatenotification.component";
import AllFollowUpList from "./components/allfollowups.component";
import ProductsList from "./components/cms/productsList.component";
import EditProduct from "./components/cms/editProduct.component";
import TransactionSheet from "./components/transactionsheet.component";
import RodriguesBookingsSheet from "./components/rodrigues_bookings.component";
import BookingUpdatesSheet from "./components/booking_updates.component";
// import {socket} from "./services/socket";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";

import { history } from './helpers/history';
import { UserProvider } from './contexts/UserContext';
import AuthService from "./services/auth/auth.service";

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: false,
      showtoast: false,
      userPermissions: []
    };

    console.log(this.state);

    history.listen((location) => {
      props.dispatch(clearMessage()); // clear message when changing location
    });
  }

  async componentDidMount() {
    var user = this.props.user;

    if (user) {
      this.setState({
        currentUser: user,
        showModeratorBoard: '',
        showAdminBoard: '',
      });
      console.log({user});
      // await AuthService.getUserPermisions(user)
      // .then(response => {
      //   console.log('permissions response', response);
      //   if(response.data.length>0) {
      //     this.setState({
      //       userPermissions : response.data
      //     });
      //   }
      // })
      // .catch(error => {
      //   console.log({error});
      // });

    }

  //   socket.on('connect', () => {
  //     console.log("connected!");
  //     socket.on("taskupdated", (message) => {
  //       // toast(message.message);

  //       console.log({message});
  //     });
  //  });

    // socket.on('taskupdated', (message)=>{
    //   console.log({message});
    //   this.setState({
    //     showtoast: true
    //   });
    // });
  }



  logOut() {
    this.props.dispatch(logout());
  }

  render() {
    const { currentUser, showModeratorBoard, showAdminBoard } = this.state;

    return (
      <UserProvider value={this.state.userPermissions}>
        <Router history={history}>
          <div className="">
            <nav className="navbar navbar-expand navbar-dark bg-dark">
              <Link to={"/"} className="navbar-brand">
                <img
                  src="https://deals.mu/wp-content/themes/hassdev/assets/images/logo.png"
                  alt="logo-img"
                  className="profile-img-card-header"
                />
              </Link>
              {currentUser ? (
                <div className="navbar-nav mr-auto">
                  <li className="nav-item">
                    <Link to={"/home"} className="nav-link">
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to={"/orders"} className="nav-link">
                      Orders
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to={"/followups"} className="nav-link">
                      Follow Ups
                    </Link>
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Sheets
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <Link to={"/transactions-sheet"} className="dropdown-item">
                        Transaction Sheet
                      </Link>
                      <Link to={"/rodrigues-booking-records"} className="dropdown-item">
                        Rodrigues Booking Records
                      </Link>
                      <Link to={"/booking-updates"} className="dropdown-item">
                        Booking Updates
                      </Link>
                    </div>
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Reports
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <Link to={"/bookings-report"} className="dropdown-item">
                        Bookings Report
                      </Link>
                      <Link to={"/expiry-report"} className="dropdown-item">
                        Expiry Report
                      </Link>
                    </div>
                  </li>

                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Accounting
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <Link
                        to={"/payment-report"}
                        className="dropdown-item payment-report-link"
                      >
                        Payment Reports
                      </Link>
                      <Link to={"/invoices"} className="dropdown-item">
                        Invoices
                      </Link>
                      <Link
                        to={"/invoices-uploaded-by-suppliers"}
                        className="dropdown-item"
                      >
                        Invoices Uploaded by Suppliers
                      </Link>
                      <Link to={"/commissions-sheet"} className="dropdown-item">
                        Commissions
                      </Link>
                      <Link to={"/suppliers-details"} className="dropdown-item">
                        Suppliers
                      </Link>
                      <Link
                        to={"/suppliers-bank-details"}
                        className="dropdown-item"
                      >
                        Suppliers Bank Details
                      </Link>
                      <Link
                        to={"/commercial-contracts"}
                        className="dropdown-item"
                      >
                        Commercial Contracts
                      </Link>
                      <Link to={"/receivables"} className="dropdown-item">
                        Invoice Receivables
                      </Link>
                    </div>
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Refunds
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <Link to={"/refunds"} className="dropdown-item">
                        Refunds
                      </Link>
                      <Link to={"/refunds-credit"} className="dropdown-item">
                        Credits
                      </Link>
                      <Link to={"/refunds-report"} className="dropdown-item">
                        Refunds Report
                      </Link>
                      <Link to={"/credits-report"} className="dropdown-item">
                        Credits Report
                      </Link>
                      <Link
                        to={"/loyalty-points-report"}
                        className="dropdown-item"
                      >
                        Loyalty Points
                      </Link>
                    </div>
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      CRM
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <Link to={"/user-profile"} className="dropdown-item">
                        Customer Profile
                      </Link>
                      <Link to={"/user-reports"} className="dropdown-item">
                        Customer Reports
                      </Link>
                      <Link to={"/corporate-summary"} className="dropdown-item">
                        Corporate Summary
                      </Link>
                      <Link
                        to={"/influencer-summary"}
                        className="dropdown-item"
                      >
                        Influencer Summary
                      </Link>
                      <Link to={"/corporate"} className="dropdown-item">
                        Corporate
                      </Link>
                      <Link
                        to={"/corporate-notifications"}
                        className="dropdown-item"
                      >
                        Corporate Notifications
                      </Link>
                    </div>
                  </li>
                  <li className="nav-item">
                    <Link to={"/task-manager"} className="nav-link">
                      Task List
                    </Link>
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      CMS
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <Link
                        to={"/gift-cards-generator"}
                        className="dropdown-item"
                      >
                        Gift Cards Generator
                      </Link>
                      <Link to={"/sliders-covers"} className="dropdown-item">
                        Sliders / Covers
                      </Link>

                      {/* <Link to={"/products-list"} className="dropdown-item">
                        Products Updates
                      </Link> */}
                    </div>
                  </li>

                  <li className="nav-item">
                    <Link to={"/issues-manager"} className="nav-link">
                      Issues / Complaints
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to={"/sms-marketing"} className="nav-link">
                      SMS Marketing
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to={"/stop-sales"} className="nav-link">
                      Stop Sales
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to={"/product-logs"} className="nav-link">
                      Products Logs
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link to={"/app-notifications"} className="nav-link">
                      App Notifications
                    </Link>
                  </li>
                </div>
              ) : (
                <div className="navbar-nav mr-auto">
                  <li className="nav-item">
                    <b>
                      <Link to={"/"} className="nav-link">
                        Deals ERP System
                      </Link>
                    </b>
                  </li>
                </div>
              )}

              {currentUser ? (
                <div className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <a href="/login" className="nav-link" onClick={this.logOut}>
                      LogOut
                    </a>
                  </li>
                </div>
              ) : (
                <div className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <Link to={"/login"} className="nav-link">
                      Login
                    </Link>
                  </li>
                </div>
              )}
            </nav>

            <div className="container-fluid mt-3">
              <Switch>
                <Route exact path={["/", "/login"]} component={Login} />
                <Route exact path={["/", "/home"]} component={Home} />
                <Route exact path="/login" component={Login} />
                <Route
                  exact
                  path="/commissions-sheet"
                  component={CommissionSheet}
                />
                {/* <Route exact path="/payment-report" component={PaymentReports} /> */}
                <Route
                  exact
                  path="/payment-report/:invoiceid?"
                  render={(props) => <PaymentReports {...props} />}
                />
                <Route
                  exact
                  path="/bookings-report"
                  component={BookingReports}
                />
                <Route exact path="/product-logs" component={ProductLogs} />
                <Route exact path="/invoices" component={Invoices} />
                <Route exact path="/refunds" component={Refunds} />
                <Route exact path="/refunds-credit" component={Credits} />
                <Route exact path="/refunds-report" component={RefundsReport} />
                <Route exact path="/credits-report" component={CreditsReport} />
                <Route
                  exact
                  path="/app-notifications"
                  component={Notifications}
                />
                <Route exact path="/suppliers-details" component={Suppliers} />
                <Route
                  exact
                  path="/user-profile/:userid?"
                  render={({ match }) => <UserProfile params={match.params} />}
                />
                <Route exact path="/user-reports" component={UserReports} />
                <Route exact path="/task-manager" component={TaskManager} />
                <Route exact path="/receivables" component={Receivables} />
                <Route
                  exact
                  path="/suppliers-bank-details"
                  component={SuppliersBankDetails}
                />
                <Route
                  exact
                  path="/commercial-contracts"
                  component={SupplierContracts}
                />
                <Route exact path="/stop-sales" component={Products} />
                <Route
                  exact
                  path="/stop-sale/:id"
                  render={({ match }) => <StopSales params={match.params} />}
                />
                <Route
                  exact
                  path="/invoice-email/:invoicenumber?"
                  render={({ match }) => (
                    <InvoiceEmails params={match.params} />
                  )}
                />
                <Route
                  exact
                  path="/corporate-summary"
                  component={CorporateCustomers}
                />
                <Route exact path="/corporate" component={Corporate} />
                <Route
                  exact
                  path="/influencer-summary"
                  component={Influencers}
                />
                <Route exact path="/issues-manager" component={Issues} />
                <Route exact path="/sms-marketing" component={SmsMarketing} />
                <Route
                  exact
                  path="/invoices-uploaded-by-suppliers"
                  component={UploadedInvoices}
                />
                <Route exact path="/expiry-report" component={ExpiryReport} />
                <Route exact path="/sliders-covers" component={SlidersCovers} />
                <Route
                  exact
                  path="/gift-cards-generator"
                  component={GiftCardGenerator}
                />
                <Route
                  exact
                  path="/corporate-customer-details/:id"
                  render={({ match }) => (
                    <CorporateCustomerDetails params={match.params} />
                  )}
                />
                <Route
                  exact
                  path="/corporate-customer/:id"
                  render={({ match }) => (
                    <CorporateCustomerDetailsNew params={match.params} />
                  )}
                />
                <Route
                  exact
                  path="/influencer-customer-details/:id"
                  render={({ match }) => (
                    <InfluencerCustomerDetails params={match.params} />
                  )}
                />
                <Route
                  exact
                  path="/orders/:userid?"
                  render={({ match }) => <OrdersReport params={match.params} />}
                />
                <Route
                  exact
                  path="/edit-order/:id"
                  render={({ match }) => <OrderEdit params={match.params} />}
                />
                <Route
                  exact
                  path="/edit-product/:id"
                  render={({ match }) => <EditProduct params={match.params} />}
                />
                <Route exact path="/automation" component={JuiceAutomation} />
                <Route exact path="/loyalty-points-report" component={Loyaltypoints} />
                <Route exact path="/corporate-notifications" component={CorporateNotifications} />
                <Route exact path="/create-corporate-notification" component={CreateCorporateNotifications} />
                <Route exact path="/followups" component={AllFollowUpList} />
                <Route
                  exact
                  path="/loyalty-points-report"
                  component={Loyaltypoints}
                />
                <Route
                  exact
                  path="/corporate-notifications"
                  component={CorporateNotifications}
                />
                <Route
                  exact
                  path="/create-corporate-notification"
                  component={CreateCorporateNotifications}
                />
                <Route exact path="/products-list" component={ProductsList} />
                <Route exact path="/transactions-sheet" component={TransactionSheet} />
                <Route exact path="/rodrigues-booking-records" component={RodriguesBookingsSheet} />
                <Route exact path="/booking-updates" component={BookingUpdatesSheet} />
              </Switch>
              {/* <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                limit={1}
              /> */}
              {this.props.children}
            </div>
          </div>
        </Router>
      </UserProvider>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(App);