import React, { Component } from "react";
import Loader from 'react-loader-spinner';
import Button from '@material-ui/core/Button';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import DateRangePicker, { DateRange } from '@material-ui/lab/DateRangePicker';
import DatePicker from '@material-ui/lab/DatePicker';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import Box from '@material-ui/core/Box';
import DTable from '../dtable.component';
import swal from 'sweetalert';

import AuthService from '../../services/auth/auth.service';
import UserReportsService from '../../services/crm/userreports.service';

import UserContext from "../../contexts/UserContext";
import NoPermission from "../extras/nopermission.component";
export default class UserReports extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);

        this.state = {
            birthdatestart: null,
            birthdateend: null,
            orderdatestart: null,
            orderdateend: null,
            rank: 0,
            region: ['0'],
            customer_type: 0,
            gender: 0,
            age: '0-0',
            user: [],
            data: [],
            show: false,
            columnsheadings: [
                { title: "<input type='checkbox' class='check_all_ur' />" },
                { title: "Customer Name" },
                { title: "Gender" },
                { title: "Birthday" },
                { title: "Age" },
                { title: "Region" },
                { title: "Customer Type" },
                { title: "No. of Orders" },
                { title: "Total Spend" },
                { title: "Loyalty Rank" },
                { title: "Last Order" },
                { title: "Actions" },
            ],
            showloader: false,
            allowed: true
        };
    }

    componentDidMount() {
        AuthService.checklogin().then(user => {
            this.setState({
                user: user
            });

            AuthService.getUserPermisions(user)
            .then(response => {
                var userpermissions = response.data;
                console.log({userpermissions});
                    if(userpermissions.includes('userreports') || userpermissions.includes('all')) {
                    console.log('You are allowed!');
                    this.setState({
                        allowed: true
                    });
                } else {
                    console.log('You are not allowed!');
                    this.setState({
                        allowed: false
                    });
                }
            })

            console.log({ user });
        }).catch(error => {
            window.location.href = '/login';
        });
    }

    birthdateChange(newValue) {
        this.setState({
            birthdate: newValue
        });
    }

    orderdateChange(newValue) {
        this.setState({
            orderdate: newValue
        });
    }

    handleChangeAge(event) {
        this.setState({
            age: event.target.value
        });
    }

    handleChangeRank(event) {
        this.setState({
            rank: event.target.value
        });
    }
    handleChangeRegion(event) {
        console.log(event.target.value);
        this.setState({
            region: event.target.value
        });
    }
    handleChangeCustomerType(event) {
        this.setState({
            customer_type: event.target.value
        });
    }
    handleChangeGender(event) {
        this.setState({
            gender: event.target.value
        });
    }
    
    searchUsers() {

        if (this.state.birthdatestart == null && this.state.birthdateend == null && this.state.orderdatestart == null && this.state.orderdateend == null && this.state.rank == 0 && this.state.region == 0 && this.state.customer_type == 0 &&
            this.state.gender == 0 && this.state.age == '0-0') {
            swal({
                title: "Select FIlters!",
                text: "Please Select Atleast One Filter!",
                icon: "info",
            });
        } else {
            this.setState({
                show: false,
                showloader: true
            });
            UserReportsService.searchCustomersReport(this.state)
                .then(response => {
                    console.log(response.data);
                    this.setState({
                        data: response.data,
                        show: true,
                        showloader: false
                    });
                })
                .catch(error => {
                    console.log({ error });
                });
        }
        
        console.log('search users');
        console.log(this.state);
    }

    render() {
        var { data, columnsheadings, show, showloader, birthdatestart, birthdateend, orderdatestart, orderdateend } = this.state;

        if(this.state.allowed==false) {
            return (
                <NoPermission />
            );
        }
        
        return (
            <>
                <div className="row">
                    <div className='col'>
                        <h3><AccountBoxIcon fontSize="large" /> User Reports</h3>
                        <hr />
                    </div>
                </div>
                <div className="row">
                    <div className="col" style={{
                        display: 'grid',
                        justifyContent: 'space-around',
                        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
                        // gridTemplateAreas: `
                        // 'a b c d e'
                        // 'f g h'
                        // `
                    }}>
                        <FormControl variant="outlined" style={{ width: '70%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Loyalty Rank</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={this.state.rank}
                                onChange={this.handleChangeRank.bind(this)}
                            >
                                <MenuItem value={0}>
                                    <em>All</em>
                                </MenuItem>
                                <MenuItem value={'Diamond'}>Diamond</MenuItem>
                                <MenuItem value={'Platinum'}>Platinum</MenuItem>
                                <MenuItem value={'Gold'}>Gold</MenuItem>
                                <MenuItem value={'Silver'}>Silver</MenuItem>
                                <MenuItem value={'Bronze'}>Bronze</MenuItem>
                            </Select>
                            <FormHelperText>Select User Rank</FormHelperText>
                        </FormControl>

                        <FormControl variant="outlined" style={{ width: '70%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Region</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={this.state.region}
                                onChange={this.handleChangeRegion.bind(this)}
                                multiple
                            >
                                <MenuItem value={'0'}>All</MenuItem>
                                <MenuItem value={'black-river'}>Black River (Flic en Flac)</MenuItem>
                                <MenuItem value={'flacq'}>Flacq</MenuItem>
                                <MenuItem value={'grand-port'}>Grand Port</MenuItem>
                                <MenuItem value={'moka'}>Moka</MenuItem>
                                <MenuItem value={'pamplemousses'}>Pamplemousses</MenuItem>
                                <MenuItem value={'plaines-wilhems'}>Plaines Wilhems</MenuItem>
                                <MenuItem value={'port-louis'}>Port Louis</MenuItem>
                                <MenuItem value={'riviere-du-rempart'}>Rivière du Rempart</MenuItem>
                                <MenuItem value={'savanne'}>Savanne</MenuItem>
                                <MenuItem value={'rodrigues'}>Rodrigues</MenuItem>
                            </Select>
                            <FormHelperText>Select User Region</FormHelperText>
                        </FormControl>

                        <FormControl variant="outlined" style={{ width: '70%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Customer Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={this.state.customer_type}
                                onChange={this.handleChangeCustomerType.bind(this)}
                            >
                                <MenuItem value={0}>
                                    <em>All</em>
                                </MenuItem>
                                <MenuItem value={'Corporate Customer'}>Corporate Customer</MenuItem>
                                <MenuItem value={'Has Children'}>Has Children</MenuItem>
                            </Select>
                            <FormHelperText>Select User Type</FormHelperText>
                        </FormControl>

                        <FormControl variant="outlined" style={{ width: '70%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Gender</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={this.state.gender}
                                onChange={this.handleChangeGender.bind(this)}
                            >
                                <MenuItem value={0}>
                                    <em>All</em>
                                </MenuItem>
                                <MenuItem value={'female'}>Female</MenuItem>
                                <MenuItem value={'male'}>Male</MenuItem>
                            </Select>
                            <FormHelperText>Select User Gender</FormHelperText>
                        </FormControl>

                        <FormControl variant="outlined" style={{ width: '70%' }}>
                            <InputLabel id="demo-simple-select-helper-label">Age</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={this.state.age}
                                onChange={this.handleChangeAge.bind(this)}
                            >
                                <MenuItem value={'0-0'}>
                                    <em>All</em>
                                </MenuItem>
                                <MenuItem value={'16-25'}>16-25</MenuItem>
                                <MenuItem value={'25-35'}>25-35</MenuItem>
                                <MenuItem value={'35-45'}>35-45</MenuItem>
                                <MenuItem value={'45-55'}>45-55</MenuItem>
                                <MenuItem value={'55+-p'}>55+</MenuItem>
                            </Select>
                            <FormHelperText>Select User Age Group</FormHelperText>
                        </FormControl>

                        <div style={{ width: '70%' }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Birthday Start"
                                        value={birthdatestart}
                                        onChange={(newValue) => {
                                            this.setState({
                                                birthdatestart: newValue
                                            });
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                            </LocalizationProvider>
                        </div>
                        <div style={{ width: '70%' }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Birthday End"
                                    value={birthdateend}
                                    onChange={(newValue) => {
                                        this.setState({
                                            birthdateend: newValue
                                        });
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </div>

                        <div style={{ width: '70%' }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Order Start"
                                    value={orderdatestart}
                                    onChange={(newValue) => {
                                        this.setState({
                                            orderdatestart: newValue
                                        });
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </div>
                        <div style={{ width: '70%' }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Order End"
                                    value={orderdateend}
                                    onChange={(newValue) => {
                                        this.setState({
                                            orderdateend: newValue
                                        });
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </div>

                        <Button variant="contained" color="primary" style={{ width: '70%' }} onClick={this.searchUsers.bind(this)}>
                            Search
                        </Button>
                        
                    </div>
                </div>

                <div className="row">
                    <div className='col'>
                        <hr />
                        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <h5>For selected customers: </h5>
                            <button className="btn btn-warning" id="user-reports-export-btn" style={{ marginLeft: '20px' }}>Export Selected</button>
                            <button className="btn btn-primary" id="user-reports-coupon-modal-btn" data-toggle="modal" data-target="#couponmodal" style={{ marginLeft: '20px' }}>Create a Coupon</button>
                            <button className="btn btn-success" id="user-reports-email-modal-btn" data-toggle="modal" data-target="#sendemailmodal" style={{ marginLeft: '20px' }}>Send an Email</button>
                        </div>
                        <hr />
                    </div>
                </div>


                <div className="row">
                    <div className="col ur-table">
                        {(show == true) &&
                            <DTable data={data} columns={columnsheadings} />
                        }

                        {(showloader == true) &&
                            <Loader className='loader-center'
                                type="Grid"
                                color="#00BFFF"
                                height={100}
                                width={100}
                            />
                        }
                    </div>
                </div>

                <div className="modal fade" id="couponmodal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Create Coupon</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <form id="create-coupon-user-report-form" method='post'>
                                <div className="modal-body"> 
                                    <div className="form-group">
                                        <label htmlFor="userreports-coupon-emails">Email Addresses</label>
                                        <textarea className="form-control" name="userreports-coupon-emails" id="userreports-coupon-emails" rows="3" required="required"></textarea>
                                        <small id="emailHelp" className="form-text text-muted">Selected Users' Emails.</small>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="userreports-coupon-code">Coupon Code</label>
                                        <input type="text" className="form-control" name="userreports-coupon-code" id="userreports-coupon-code" placeholder="Coupon Code" required="required" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="userreports-coupon-desc">Description</label>
                                        <textarea className="form-control" name="userreports-coupon-desc" id="userreports-coupon-desc" rows="3" required="required"></textarea>
                                        <small id="emailHelp" className="form-text text-muted">Coupon Description.</small>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="userreports-coupon-type">Coupon Type</label>
                                        <select className="form-control" name="userreports-coupon-type" id="userreports-coupon-type" required="required">
                                            <option value="fixed">Fixed Amount</option>
                                            <option value="percentage">Percentage</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="userreports-coupon-amount">Coupon Amount</label>
                                        <input type="number" className="form-control" name="userreports-coupon-amount" id="userreports-coupon-amount" placeholder="Coupon Amount" required="required" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="userreports-coupon-expiry">Expiry Date</label>
                                        <input type="text" className="form-control date-picker" name="userreports-coupon-expiry" id="userreports-coupon-expiry" placeholder="Coupon Expiry Date" required="required" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="userreports-min-amount">Minimum Order Amount Spent</label>
                                        <input type="number" className="form-control" name="userreports-min-amount" id="userreports-min-amount" placeholder="Minimum order amount spent" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="userreports-coupon-limit-coupon">Usage limit per coupon (Default: Unlimited)</label>
                                        <input type="number" className="form-control" name="userreports-coupon-limit-coupon" id="userreports-coupon-limit-coupon" placeholder="Usage limit per coupon" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="userreports-coupon-limit-customer">Usage limit per user (Default: Unlimited)</label>
                                        <input type="number" className="form-control" name="userreports-coupon-limit-customer" id="userreports-coupon-limit-customer" placeholder="Usage limit per user" />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                    <button type="submit" className="btn btn-primary" id="create-coupon-user-reports">Create</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="sendemailmodal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Send Email</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <form id="create-email-user-report-form" method='post'>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label htmlFor="userreports-email-emails">Email Addresses</label>
                                        <textarea className="form-control" name="userreports-email-emails" id="userreports-email-emails" rows="3" required="required"></textarea>
                                        <small id="emailHelp" className="form-text text-muted">Selected Users' Emails.</small>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="userreports-email-subject">Email Subject</label>
                                        <input type="text" className="form-control" name="userreports-email-subject" id="userreports-email-subject" placeholder="Email Subject" required="required" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="userreports-emails-compose">Compose Email</label>
                                        <textarea className="form-control" name="uuserreports-emails-compose" id="userreports-emails-compose" rows="3" required="required"></textarea>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                    <button type="submit" className="btn btn-primary" id="send-email-user-reports">Send Email</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

