import React, { Component } from "react";
import Loader from 'react-loader-spinner';
import Button from '@material-ui/core/Button';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import DateRangePicker, { DateRange } from '@material-ui/lab/DateRangePicker';
import DatePicker from '@material-ui/lab/DatePicker';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import Box from '@material-ui/core/Box';
import DTable from '../dtable.component';
import swal from 'sweetalert';

import AuthService from '../../services/auth/auth.service';
import InfluencerService from '../../services/crm/influencerreports.service';

import UserContext from "../../contexts/UserContext";
import NoPermission from "../extras/nopermission.component";

export default class InfluencerCustomerDetails extends Component {

    static contextType = UserContext;
    constructor(props){
        super(props);

        this.state = {
            user: [],
            data: [],
            show: false,
            showloader: true,
            user_id: 0,
            detailsName: '',
            detailsMobile: '',
            detailsMobile2: '',
            detailsRelavancy: '',
            detailsEmail: '',
            detailsAccountCreationDate: '',
            detailsDiscountCode: '',
            detailsTotalSalesAllTime: '',
            detailsRequirements: '',
            fblink: '',
            fblikes: '',
            instalink: '',
            instafollowers: '',
            youtubelink: '',
            youtubesubscribers: '',
            contactInfo: '',
            lastorderdate: '',
            totalbalance: 0,
            totalOrders: 0,
            total30days: 0,
            total12months: 0,
            totalorders30days: 0,
            totalorders12months: 0,
            history: [],
            credithistory: [],
            allowed: true
        };
    }

    componentDidMount() {
        AuthService.checklogin().then(user => {
            this.setState({
                user: user
            });

            console.log({ user });

            AuthService.getUserPermisions(user)
            .then(response => {
                var userpermissions = response.data;
                console.log({userpermissions});
                    if(userpermissions.includes('influencers') || userpermissions.includes('all')) {
                    console.log('You are allowed!');
                    this.setState({
                        allowed: true
                    });
                    setTimeout(() => {
                        if (typeof this.props.params.id == 'undefined') {
                            this.setState({
                                user_id: 0,
                            });
                        } else {
                            this.setState({
                                user_id: this.props.params.id,
                            });
                            if (this.state.user_id != 0) {
                                this.getData();
                            }
                        }
                    }, 2000);
                } else {
                    console.log('You are not allowed!');
                    this.setState({
                        allowed: false
                    });
                }
            })
        }).catch(error => {
            window.location.href = '/login';
        });
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
        // console.log('handle change');
    }

    getData = () => {
        this.setState({
            showloader: true
        });
        console.log(this.state);
        InfluencerService.getInfluencerDetails(this.state.user_id)
        .then(response => {
            console.log({response});
            if(response.data!=null) {
                this.setState({
                    detailsName: response.data.basic.customerName,
                    detailsMobile: response.data.basic.mobile,
                    detailsMobile2: response.data.basic.mobile2,
                    detailsRelavancy: response.data.basic.relavancy,
                    detailsEmail: response.data.basic.email,
                    detailsAccountCreationDate: response.data.basic.accountdate,
                    detailsDiscountCode: response.data.basic.couponCode,
                    detailsTotalSalesAllTime: response.data.basic.totalsales,
                    detailsRequirements: response.data.basic.requirements,
                    fblink: response.data.basic.fblink,
                    fblikes: response.data.basic.fblikes,
                    instalink: response.data.basic.instalink,
                    instafollowers: response.data.basic.instafollowers,
                    youtubelink: response.data.basic.youtubelink,
                    youtubesubscribers: response.data.basic.youtubesubscribers,
                    contactInfo: response.data.contactInfo,
                    lastorderdate: response.data.basic.lastorderdate,
                    totalbalance: response.data.totalbalance,
                    totalOrders: response.data.totalOrders,
                    total30days: response.data.total30days,
                    total12months: response.data.total12months,
                    totalorders30days: response.data.totalorders30days,
                    totalorders12months: response.data.totalorders12months,
                    history: response.data.history,
                    credithistory: response.data.credithistory,
                });

                if(response.data.contacts.length>0) {
                    this.setState({
                        contacts: response.data.contacts    
                    });
                }

                this.setState({
                    showloader: false
                });
            }
        })
        .catch(error => {
            console.log({error});
            this.setState({
                showloader: false
            });
        });
    }

    changeContact = (index, key, value) => {
        var contactsTemp = this.state.contacts;

        if(key=='name') {
            contactsTemp[index].fullname = value;
        } else if(key=='role') {
            contactsTemp[index].role = value;
        } else if(key=='mobile') {
            contactsTemp[index].mobile = value;
        } else if(key=='landline') {
            contactsTemp[index].landline = value;
        } else if(key=='email') {
            contactsTemp[index].email = value;
        }

        this.setState({
            contacts: contactsTemp
        });
    }


    saveDetails = () => {
        console.log('clicked');

        InfluencerService.saveInfluencerDetails(this.state.user_id, this.state.detailsName, this.state.detailsMobile, this.state.detailsMobile2, this.state.detailsRelavancy, this.state.detailsDiscountCode, this.state.detailsRequirements, this.state.contactInfo, this.state.fblink, this.state.fblikes, this.state.instalink, this.state.instafollowers, this.state.youtubelink, this.state.youtubesubscribers)
        .then(response => {
            console.log({response});
            swal('Details Updated!', 'The Influencer details have been updated', 'info');
        })
        .catch(error => {
            console.log({error});
        });
    }

    render() {

        if(this.state.allowed==false) {
            return (
                <NoPermission />
            );
        }
        
        return (
            <>
             <div className="row">
                <div className='col-12'>
                    <h3><AccountBoxIcon fontSize="large" /> Influencer Customer Details</h3>
                    <hr />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    {(this.state.showloader == true) &&
                        <Loader className='loader-center'
                            type="Grid"
                            color="#00BFFF"
                            height={100}
                            width={100}
                        />
                    }
                </div>
            </div>
            {this.state.showloader==false &&    
            <div>           
            <div className="row" style={{marginBottom: '20px'}}>
                <div className="col" style={{display: 'flex'}}>
                    <span style={{backgroundColor: '#ffe7ba', padding: '10px', fontWeight: 'bold', border: '1px solid grey', flexGrow: '1'}}>Influencer Details:</span>    
                </div>    
            </div>
            <div className="row">
                <div className="col">
                    <div className="form-group row">
                        <label for="detailsName" className="col-sm-3 col-form-label">Customer Name:</label>
                        <div className="col-sm-9">
                        <input type="text" className="form-control" id="detailsName" name="detailsName" value={this.state.detailsName} onChange={this.handleChange.bind(this)} placeholder="Customer Name" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label for="detailsMobile" className="col-sm-3 col-form-label">Mobile:</label>
                        <div className="col-sm-9">
                        <input type="text" className="form-control" id="detailsMobile" name="detailsMobile" value={this.state.detailsMobile} onChange={this.handleChange.bind(this)} placeholder="Mobile" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label for="detailsMobile2" className="col-sm-3 col-form-label">2nd No. (mobile / landline):</label>
                        <div className="col-sm-9">
                        <input type="text" className="form-control" id="detailsMobile2" name="detailsMobile2" value={this.state.detailsMobile2} onChange={this.handleChange.bind(this)} placeholder="2nd No. (mobile / landline)" />
                        </div>
                    </div>
                    
                    <div className="form-group row">
                        <label for="detailsEmail" className="col-sm-3 col-form-label">Email:</label>
                        <div className="col-sm-9">
                        <input type="text" className="form-control" id="detailsEmail" name="detailsEmail" value={this.state.detailsEmail} onChange={this.handleChange.bind(this)} placeholder="Email"  readOnly />
                        </div>
                    </div>

                    
                </div>
                <div className="col">
                    <div className="form-group row">
                        <label for="detailsAccountCreationDate" className="col-sm-3 col-form-label">Account Creation Date:</label>
                        <div className="col-sm-9">
                        <input type="text" className="form-control" id="detailsAccountCreationDate" name="detailsAccountCreationDate" value={this.state.detailsAccountCreationDate} onChange={this.handleChange.bind(this)} placeholder="Account Creation Date"  readOnly />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label for="detailsRelavancy" className="col-sm-3 col-form-label">Relavancy:</label>
                        <div className="col-sm-9">
                        <select name="detailsRelavancy" id="detailsRelavancy" placeholder="Choose Relevancy" className="form-control form-control-select" required="required" onChange={this.handleChange.bind(this)} value={this.state.detailsRelavancy}>
                            <option value="Low">Low</option>
                            <option value="Medium">Medium</option>
                            <option value="High">High</option>
                        </select>
                        </div>
                    </div>
                    
                    <div className="form-group row">
                        <label for="detailsDiscountCode" className="col-sm-3 col-form-label">Discount Coupon Code</label>
                        <div className="col-sm-9">
                        <input type="text" className="form-control" id="detailsDiscountCode" name="detailsDiscountCode" value={this.state.detailsDiscountCode} onChange={this.handleChange.bind(this)} placeholder="Discount Coupon Code" readOnly />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label for="detailsTotalSalesAllTime" className="col-sm-3 col-form-label">Total Sales (all time)</label>
                        <div className="col-sm-9">
                        <input type="text" className="form-control" id="detailsTotalSalesAllTime" name="detailsTotalSalesAllTime" value={this.state.detailsTotalSalesAllTime} onChange={this.handleChange.bind(this)} placeholder="Total Sales (all time)"  readOnly />
                        </div>
                    </div>
                </div>  
            </div>
            <div className="row">
                <div className="col">
                    <div className="form-group row">
                        <label for="fblink" className="col-sm-3 col-form-label">Facebook Account:</label>
                        <div className="col-sm-9">
                        <input type="text" className="form-control" id="fblink" name="fblink" value={this.state.fblink} onChange={this.handleChange.bind(this)} placeholder="Facebook Account" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label for="instalink" className="col-sm-3 col-form-label">Instagram Account:</label>
                        <div className="col-sm-9">
                        <input type="text" className="form-control" id="instalink" name="instalink" value={this.state.instalink} onChange={this.handleChange.bind(this)} placeholder="Instagram Account" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label for="youtubelink" className="col-sm-3 col-form-label">Youtube:</label>
                        <div className="col-sm-9">
                        <input type="text" className="form-control" id="youtubelink" name="youtubelink" value={this.state.youtubelink} onChange={this.handleChange.bind(this)} placeholder="Youtube" />
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="form-group row">
                        <label for="fblikes" className="col-sm-3 col-form-label">No. of Likes:</label>
                        <div className="col-sm-9">
                        <input type="text" className="form-control" id="fblikes" name="fblikes" value={this.state.fblikes} onChange={this.handleChange.bind(this)} placeholder="No. of Likes" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label for="instafollowers" className="col-sm-3 col-form-label">No. of Followers:</label>
                        <div className="col-sm-9">
                        <input type="text" className="form-control" id="instafollowers" name="instafollowers" value={this.state.instafollowers} onChange={this.handleChange.bind(this)} placeholder="No. of Followers" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label for="youtubesubscribers" className="col-sm-3 col-form-label">No. of Subsribers:</label>
                        <div className="col-sm-9">
                        <input type="text" className="form-control" id="youtubesubscribers" name="youtubesubscribers" value={this.state.youtubesubscribers} onChange={this.handleChange.bind(this)} placeholder="No. of Subsribers" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" style={{marginBottom: '20px'}}>
                <div className="col">
                    <label>Requirements / Important Info:</label>
                    <textarea className="form-controm" style={{width: '98%', height: '110px'}} name="detailsRequirements" value={this.state.detailsRequirements} onChange={this.handleChange.bind(this)} placeholder="Enter additional information or requirements"></textarea>
                </div>
            </div>

            <div className="row" style={{marginBottom: '20px'}}>
                <div className="col">
                    <label>Additional Info:</label>
                    <textarea className="form-controm" style={{width: '98%', height: '110px'}} name="contactInfo" value={this.state.contactInfo} onChange={this.handleChange.bind(this)} placeholder="Enter additional information or requirements"></textarea>
                </div>
            </div>

            <div className="row" style={{marginBottom: '20px'}}>
                <div className="col" style={{display:'flex'}}>
                    <button className="btn btn-success btn-sm" style={{flexGrow: '1'}} onClick={this.saveDetails.bind(this)}>Save / Update Details</button>
                </div>
            </div>
            

            <div className="row" style={{marginBottom: '20px'}}>
                <div className="col" style={{display: 'flex'}}>
                    <span style={{backgroundColor: 'rgb(254 210 210)', padding: '10px', fontWeight: 'bold', border: '1px solid grey', flexGrow: '1'}}>Orders & Booking Summary:</span>    
                </div>    
            </div>
            <div className="row" style={{marginBottom: '20px'}}>
                <div className="col">
                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label">Discount Coupon Code:</label>
                        <div className="col-sm-8">
                        <input type="text" className="form-control" value={this.state.detailsDiscountCode}  placeholder="Discount Coupon Code" readOnly />
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label">Influencer Credit Balance:</label>
                        <div className="col-sm-8">
                        <input type="text" className="form-control" value={this.state.totalbalance} placeholder="Corporate Credit Balance" readOnly />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label">Last Order Date:</label>
                        <div className="col-sm-8">
                        <input type="text" className="form-control" value={this.state.lastorderdate} placeholder="Last Order Date" readOnly />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" style={{marginBottom: '20px'}}>
                <div className="col">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Order Details:</th>
                                <th>No. of Orders</th>
                                <th>Total Order Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">Past 30 days</th>
                                <td>{this.state.totalorders30days}</td>
                                <td>{this.state.total30days}</td>
                            </tr>
                            <tr>
                                <th scope="row">Past 12 months</th>
                                <td>{this.state.totalorders12months}</td>
                                <td>{this.state.total12months}</td>
                            </tr>   
                            <tr>
                                <th scope="row">All Time</th>
                                <td>{this.state.totalOrders}</td>
                                <td>{this.state.detailsTotalSalesAllTime}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <h5>Influencer Transactions</h5>
                </div>
            </div>
            <div className="row" style={{marginBottom: '20px'}}>
                <div className="col">
                    <table class="table" style={{textAlign: "center"}}>
                        <thead>
                            <tr style={{backgroundColor: '#ffe7ba'}}>
                                <th>Order ID</th>
                                <th>Amount</th>
                                <th>Customer Name</th>
                                <th>Customer Account Email</th>
                                <th>Status</th>
                                <th>Order Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.history.length>0 && this.state.history.map((entry, index) => (
                            <tr key={index} className={entry.status=='Processing' ? 'green-back':''}>
                                <th scope="row">{entry.order_id}</th>
                                <td>{entry.amount}</td>
                                <td>{entry.customername}</td>
                                <td>{entry.customeremail}</td>
                                <td>{entry.status}</td>
                                <td>{entry.order_date}</td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

             <div className="row">
                <div className="col">
                    <h5>Influencer Credit History</h5>
                </div>
            </div>
            <div className="row" style={{marginBottom: '20px'}}>
                <div className="col">
                    <table class="table" style={{textAlign: "center"}}>
                        <thead>
                            <tr style={{backgroundColor: '#ffe7ba'}}>
                                <th>ID</th>
                                <th>Amount</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.credithistory.length>0 && this.state.credithistory.map((entry, index) => (
                            <tr key={index} >
                                <th scope="row">{entry.id}</th>
                                <td>{entry.amount}</td>
                                <td>{entry.date}</td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            </div>
            }
            </>
        );
    }
}