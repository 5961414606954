import axios from "axios"

const API_URL = "https://deals.mu/scripts/reportsapi/giftcards.php";

class GiftCardsService {
    getBackgroundImages() {
        var params = {
            action: "getgiftcardimages",
        }

        return axios.post(API_URL, {params})
        .then((response) => {
            return response;
        })
        .catch((error) => console.log({error}));
    }

    createOrder(groupName, totalReceipients, totalAmount, couponList, cusEmail, cusName, cusPhone, amountPaid, selectedImage, selectedLogo, termsCond, nameBackgroundColor, codeBackgroundColor, expiryBackgroundColor) {
        var params = {
            action: "createorder",
            groupName: groupName,
            totalReceipients: totalReceipients,
            totalAmount: totalAmount,
            couponList: couponList,
            cusEmail: cusEmail,
            cusName: cusName,
            cusPhone: cusPhone,
            amountPaid: amountPaid,
            selectedImage: selectedImage,
            selectedLogo: selectedLogo,
            termsCond: termsCond,
            nameBackgroundColor: nameBackgroundColor,
            codeBackgroundColor: codeBackgroundColor,
            expiryBackgroundColor: expiryBackgroundColor
        }

        return axios.post(API_URL, {params})
        .then((response) => {
            return response;
        })
        .catch((error) => console.log({error}));
    }

    getResults(page, search, status) {
        var params = {
            action: 'getallcards',
            page: page,
            status: status,
            search: search,
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                return response;
            }).catch(error => {
                return false;
            });
    }

    getAllCards(search, status) {
       return this.getResults(0, search, status)
            .then((response) => {

                console.log({response});
                var result = {
                    data: []
                };
                let promises = [];
                var size = parseFloat(response.data);

                var loopSize = Math.ceil(parseFloat(size / 20));

                for (var i = 1; i <= loopSize; i++) {

                    promises.push(
                        this.getResults(i, search, status)
                            .then(response1 => {

                                result.data = result.data.concat(response1.data);
                            })
                            .catch(error1 => {
                                console.log({ error1 });
                            })
                    );
                }

                return Promise.all(promises).then(() => {
                    console.log({result});
                    return result;
                });

            }).catch(error => {

                return false;
            });
    }

}

export default new GiftCardsService();