import axios from "axios";

const API_URL = "https://deals.mu/scripts/reportsapi/taskresports.php";

class TasksReportsService {

    compare( a, b ) {
        if ( parseInt(a[0]) < parseInt(b[0]) ){
            return 1;
        }
        if ( parseInt(a[0]) > parseInt(b[0]) ){
            return -1;
        }
        return 0;
    }

    getAll(startDate, endDate, company, supplier, type, status, priority, dept, deptstatus) {

        console.log({dept});
       return this.getResults(0, startDate, endDate, company, supplier, type, status, priority, dept, deptstatus)
            .then(async (response) => {

                console.log({response});

                var result = {
                    data: []
                };
                let promises = [];
                var size = parseFloat(response.data);

                var loopSize = Math.ceil(parseFloat(size / 30));
                if(loopSize<1) {
                    loopSize = 1;
                }
                for (var i = 1; i <= loopSize; i++) {

                    promises.push(
                       await this.getResults(i, startDate, endDate, company, supplier, type, status, priority, dept, deptstatus)
                            .then(response1 => {

                                result.data = result.data.concat(response1.data);
                            })
                            .catch(error1 => {
                                console.log({ error1 });
                            })
                    );
                }

                return Promise.all(promises).then(() => {
                    console.log({result});
                    result.data.sort( this.compare );
                    return result;
                });

            }).catch(error => {

                return false;
            });
    }

    getResults(page, startDate, endDate, company, supplier, type, status, priority, dept, deptstatus) {
        var params = {
            action: 'get_all',
            page: page,
            type: type,
            startDate: startDate,
            endDate: endDate,
            company: company,
            supplier: supplier,
            status: status,
            priority: priority,
            dept: dept,
            deptstatus: deptstatus
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {
                console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

    saveTask(taskcompanyname, tasksuppliername, taskproductname, taskdatereceived, createtaskpriority, createtasktype, taskdepartments, taskresourcesdetails, createtaskfiles, createtaskcomments, username, editTaskID, files) {
        var params = {
            action: 'save_task',
            taskcompanyname: taskcompanyname,
            tasksuppliername: tasksuppliername,
            taskproductname: taskproductname,
            taskdatereceived: taskdatereceived,
            createtaskpriority: createtaskpriority,
            createtasktype: createtasktype,
            taskdepartments: taskdepartments,
            taskresourcesdetails: taskresourcesdetails,
            createtaskfiles: createtaskfiles,
            createtaskcomments: createtaskcomments,
            username: username,
            editTaskID: editTaskID,
            files: files
        };

        console.log({params});
        
        return axios
            .post(API_URL, { params })
            .then((response) => {

                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

    editTask(taskID) {
        var params = {
            action: 'edit_task',
            id: taskID
        };
        return axios
            .post(API_URL, { params })
            .then((response) => {

                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }
}

export default new TasksReportsService();