import React, { Component } from "react";
import Loader from 'react-loader-spinner';
import DTable from './dtable.component';
import DatePicker from "react-datepicker";
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faTrophy, faGlobeAfrica, faUser, faCalendar, faStopwatch, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
import swal from 'sweetalert';

import AuthService from '../services/auth/auth.service';
import UtilityService from "../services/utility.service";
import SmsService from "../services/erp/sms.service";

import UserContext from "../contexts/UserContext";
import NoPermission from "./extras/nopermission.component";
export default class SmsMarketing extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            columnsheadings: [],
            options: {
                filterType: 'dropdown',
            },
            show: false,
            error: {
                status: false,
                message: ''
            },
            loadingvalues: true,
            user: [],
            search_btn_text: 'Search',
            loader: false,
            status: '0',
            startdate: '',
            enddate: '',
            minDate: new Date("02-01-2019"),
            rank: '0',
            region: [],
            customertype: '0',
            gender: '0',
            age: '0-0',
            birthdayfrom: '',
            birthdayto: '',
            minBirthdayDate: new Date("02-01-2019"),
            orderstart: '',
            orderend: '',
            minOrderDate: new Date("02-01-2019"),
            fetchedNumbersTotal: 0, 
            fetchedNumber: [],
            showfetched: false,
            fetchbtntext: 'Fetch Numbers',
            phonenumbers: '',
            messageToSend: '',
            sendon: '',
            allowed: true
        };
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleRegionChange = (e) => {
        let value = Array.from(e.target.selectedOptions, option => option.value);
        this.setState({region: value});
    }

    handleAgeChange = (e) => {
        let value = Array.from(e.target.selectedOptions, option => option.value);
        this.setState({age: value});
    }

    componentDidMount() {
        AuthService.checklogin().then(user => {

            this.setState({
                user: user
            });
            // console.log({user});

            AuthService.getUserPermisions(user)
            .then(response => {
                var userpermissions = response.data;
                console.log({userpermissions});
                    if(userpermissions.includes('smsmarketing') || userpermissions.includes('all')) {
                    console.log('You are allowed!');
                    this.setState({
                        allowed: true
                    });
                    this.loadResults(true);
                } else {
                    console.log('You are not allowed!');
                    this.setState({
                        allowed: false
                    });
                }
            })
            
        }).catch(error => {
            window.location.href = '/login';
        });
    }

    setStartDate = (date) => {
        this.setState({
            startdate: date,
            minDate: date,
        });

        if (this.state.enddate != '' && this.state.enddate < date) {
            this.setState({
                enddate:date
            });
        }
        // console.log(this.state);
    }

    setEndDate = (date) => {

        this.setState({
            enddate: date
        });
        // console.log(this.state);
    }

    setBirthdayFromDate = (date) => {
        this.setState({
            birthdayfrom: date,
            minBirthdayDate: date,
        });

        if (this.state.birthdayto != '' && this.state.birthdayto < date) {
            this.setState({
                birthdayto:date
            });
        }
        // console.log(this.state);
    }

    setBirthdayToDate = (date) => {

        this.setState({
            birthdayto: date
        });
        // console.log(this.state);
    }

    setOrderStartDate = (date) => {
        this.setState({
            orderstart: date,
            minOrderDate: date,
        });

        if (this.state.orderend != '' && this.state.orderend < date) {
            this.setState({
                orderend:date
            });
        }
        // console.log(this.state);
    }

    setOrderEndDate = (date) => {

        this.setState({
            orderend: date
        });
        // console.log(this.state);
    }

    setSendOnDate = (date) => {

        this.setState({
            sendon: date
        });
        console.log({date});
    }

    fetchNumber = () => {
        this.setState({
            showfetched: false,
            fetchbtntext: 'Fetching ....',
            phonenumbers: ''
        });
        var birthdayfrom = '';
        if (this.state.birthdayfrom != '' && this.state.birthdayfrom != null) {
            birthdayfrom = UtilityService.format_date(this.state.birthdayfrom);
        }
        var birthdayto = '';
        if (this.state.birthdayto != '' && this.state.birthdayto != null) {
            birthdayto = UtilityService.format_date(this.state.birthdayto);
        }
        var orderstart = '';
        if (this.state.orderstart != '' && this.state.orderstart != null) {
            orderstart = UtilityService.format_date(this.state.orderstart);
        }
        var orderend = '';
        if (this.state.orderend != '' && this.state.orderend != null) {
            orderend = UtilityService.format_date(this.state.orderend);
        }

        SmsService.fetchAllNumbers(this.state.rank, this.state.region, this.state.customertype, this.state.gender, this.state.age, birthdayfrom, birthdayto, orderstart, orderend)
        .then(response => {
            console.log({response});
            this.setState({
                fetchedNumbersTotal: response.data.length,
                fetchedNumber: response.data,
                showfetched: true,
                fetchbtntext: 'Fetch Numbers',
                phonenumbers: response.data.join('|'),
            });
        })
        .catch(error => {
            console.log({error});
        });
    }

    loadResults = (all) => {
        this.setState({
            data: [],
            show: false,
            error: {
                status: false,
                message: ''
            },
            loadingvalues: true,
        });
        setTimeout(()=>{
            var startday = '';
            if (this.state.startdate != '' && this.state.startdate != null) {
                startday = UtilityService.format_date(this.state.startdate);
            }

            var endday = '';
            if (this.state.enddate != '' && this.state.enddate != null) {
                endday = UtilityService.format_date(this.state.enddate);
            }

            SmsService.getSmsAll(startday, endday, this.state.status, this.state.user.user_email)
            .then(response => {
                // console.log({response});
                if(response.data) {

                    this.setState({
                        data: response.data,
                        columnsheadings: [
                            { title: "#" },
                            { title: "Segment" },
                            { title: "Phone Numbers" },
                            { title: "Message" },
                            { title: "Status" },
                            { title: "Already Sent Numbers" },
                            { title: "Created On" },
                            { title: "Send On" },
                            { title: "Created By" },
                            { title: "Action" },
                        ],
                        show: true,
                        error: {
                            status: false,
                            message: ''
                        },
                        loadingvalues: false, 
                    });
                } else {
                    this.setState({
                        data: [],
                        columnsheadings: [],
                        show: false,
                        error: {
                            status: true,
                            message: 'No data found'
                        },
                        loadingvalues: false,
                    });
                }
            })
            .catch(error => {
                this.setState({
                    data: [],
                    columnsheadings: [],
                    show: false,
                    error: {
                        status: true,
                        message: 'No data found'
                    },
                    loadingvalues: false,
                });
            });
        }, 1000);
    }

    saveMessage = () => {
        var birthdayfrom = '';
        if (this.state.birthdayfrom != '' && this.state.birthdayfrom != null) {
            birthdayfrom = UtilityService.format_date(this.state.birthdayfrom);
        }
        var birthdayto = '';
        if (this.state.birthdayto != '' && this.state.birthdayto != null) {
            birthdayto = UtilityService.format_date(this.state.birthdayto);
        }
        var orderstart = '';
        if (this.state.orderstart != '' && this.state.orderstart != null) {
            orderstart = UtilityService.format_date(this.state.orderstart);
        }
        var orderend = '';
        if (this.state.orderend != '' && this.state.orderend != null) {
            orderend = UtilityService.format_date(this.state.orderend);
        }

        var sendon = '';
        if (this.state.sendon != '' && this.state.sendon != null) {
            sendon = UtilityService.format_date_time(this.state.sendon);
        }

        if(this.state.phonenumbers=='' || this.state.messageToSend=='' || this.state.sendon=='' ) {
            swal('Please fill all required Feilds!', 'Please make sure to fill all required feilds', 'error');
        } else if(this.state.messageToSend.length>155) {
            swal('Please Limit the message!', 'Please make sure the ,essage is less than 155 characters', 'error');
        } else {
            SmsService.saveMessage(this.state.rank, this.state.region, this.state.customertype, this.state.gender, this.state.age, birthdayfrom, birthdayto, orderstart, orderend, this.state.phonenumbers, this.state.messageToSend, sendon, this.state.user.user_nicename)
            .then(response => {
                console.log({response});
                this.setState({
                    rank: '0',
                    region: [],
                    customertype: '0',
                    gender: '0',
                    age: '0-0',
                    birthdayfrom: '',
                    birthdayto: '',
                    minBirthdayDate: new Date("02-01-2019"),
                    orderstart: '',
                    orderend: '',
                    minOrderDate: new Date("02-01-2019"),
                    fetchedNumbersTotal: 0, 
                    fetchedNumber: [],
                    showfetched: false,
                    fetchbtntext: 'Fetch Numbers',
                    phonenumbers: '',
                    messageToSend: '',
                    sendon: ''
                });
                
                $('.close').click();
                swal("Message Saved and Scheduled", "SMS has been saved and will be sent on scheduled date. You can send manually with send now button", "success");
                this.loadResults(true);
            })
            .catch(error => {
                console.log({error});
            });
        }
    }

    render() {
        var { data, columnsheadings, options, show, error, loadingvalues, search_btn_text } = this.state;

        if (this.state.allowed == false) {
          return <NoPermission />;
        }
        
        return (
            <>
                <div className="row">
                    <div className='col'>
                        <h2>SMS Marketing Management:</h2>
                        <hr />
                    </div>
                </div>
                <div className="row">
                    <div className='col'>
                        {loadingvalues == true &&
                            <p style={{ color: "#df153b" }}><b><i>Loading SMS History. Please Wait....</i></b></p>
                        }

                         <form action="" method="get" className="booking-reports-search-form" style={{ display: 'flex' }}>
                            <input type="hidden" name="page" value="deals-payment-report" />
                            <div>
                                <DatePicker
                                    dateFormat="dd-MM-yyyy"
                                    selected={this.state.startdate}
                                    onChange={date => this.setStartDate(date)}
                                    placeholderText="Start Date"
                                    className='form-control'
                                />
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                               
                                <DatePicker
                                    dateFormat="dd-MM-yyyy"
                                    selected={this.state.enddate}
                                    onChange={date => this.setEndDate(date)}
                                    placeholderText="End Date"
                                    className='form-control'
                                    minDate = {this.state.minDate}
                                />
                            </div>
                            
                            <div style={{ marginLeft: '10px' }}>
                                <select name="status" className="select-drops form-control" value={this.state.status} onChange={this.handleChange.bind(this)}>
                                    <option value="0">All Status</option>
                                    <option value="Pending">Pending</option>
                                    <option value="In-progress">In-progress</option>
                                    <option value="Sent">Sent</option>
                                    <option value="Disable">Disable</option>
                                </select>
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                <button type="button" className="btn btn-success btn-large booking-search-btn" onClick={this.loadResults}>{search_btn_text}</button>
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                <a href="/sms-marketing" className="btn btn-large btn-warning booking-reset-btn">Reset</a>
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                <button type="button" className="btn btn-large btn-success" data-toggle="modal" data-target="#createMessageModal">Create Message</button>
                            </div>

                        </form>
                        <hr />
                    </div>
                </div>
                <div className="modal fade" id="createMessageModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Create Message</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group row">
                            <label for="rank" className="col-sm-4 col-form-label"><FontAwesomeIcon icon={faTrophy} /> Rank</label>
                            <div className="col-sm-8">
                                <select type="text"  onChange={this.handleChange.bind(this)} className="form-control" id="rank" name="rank" value={this.state.rank} >
                                    <option value="0">Any Ranks</option>
                                    <option value="Diamond">Diamond</option>
                                    <option value="Platinum">Platinum</option>
                                    <option value="Gold">Gold</option>
                                    <option value="Silver">Silver</option>
                                    <option value="Bronze">Bronze</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label for="region" className="col-sm-4 col-form-label"><FontAwesomeIcon icon={faGlobeAfrica} /> Region</label>
                            <div className="col-sm-8">
                                <select type="text" onChange={this.handleRegionChange.bind(this)} className="form-control" id="region" name="region" value={this.state.region} multiple="multiple">
                                    <option value="0">Any Region</option>
                                    <option value="black-river">Black River (Flic en Flac)</option>
                                    <option value="flacq">Flacq</option>
                                    <option value="grand-port">Grand Port</option>
                                    <option value="moka">Moka</option>
                                    <option value="Pamplemousses">Pamplemousses</option>
                                    <option value="plaines-wilhems">Plaines Wilhems</option>
                                    <option value="port-louis">Port Louis</option>
                                    <option value="riviere-du-rempart">Rivière du Rempart</option>
                                    <option value="savanne">Savanne</option>
                                    <option value="rodrigues">Rodrigues</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label for="customertype" className="col-sm-4 col-form-label"><FontAwesomeIcon icon={faUser} /> Customer Type</label>
                            <div className="col-sm-8">
                                <select type="text"  onChange={this.handleChange.bind(this)} className="form-control" id="customertype" name="customertype" value={this.state.customertype} >
                                    <option value="0">All</option>
                                    <option value="Corporate Customer">Corporate Customer</option>
                                    <option value="Has Children">Has Children</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label for="gender" className="col-sm-4 col-form-label"><FontAwesomeIcon icon={faUser} /> Gender</label>
                            <div className="col-sm-8">
                                <select type="text" onChange={this.handleChange.bind(this)} className="form-control" id="gender" name="gender" value={this.state.gender} >
                                    <option value="0">All</option>
                                    <option value="female">Female</option>
                                    <option value="male">Male</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label for="age" className="col-sm-4 col-form-label"><FontAwesomeIcon icon={faUser} /> Age</label>
                            <div className="col-sm-8">
                                <select type="text" onChange={this.handleAgeChange.bind(this)} className="form-control" id="age" name="age" value={this.state.age} multiple="multiple">
                                    <option value="0-0">All</option>
                                    <option value="16-25">16-25</option>
                                    <option value="25-35">25-35</option>
                                    <option value="35-45">35-45</option>
                                    <option value="45-55">45-55</option>
                                    <option value="55+-p">55+</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label for="birthdayfrom" className="col-sm-4 col-form-label"><FontAwesomeIcon icon={faCalendar} /> Birtday From</label>
                            <div className="col-sm-8">
                                <DatePicker
                                    dateFormat="dd-MM-yyyy"
                                    selected={this.state.birthdayfrom}
                                    onChange={date => this.setBirthdayFromDate(date)}
                                    placeholderText="Birthday From"
                                    className='form-control'
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label for="birthdayto" className="col-sm-4 col-form-label"><FontAwesomeIcon icon={faCalendar} /> Birtday To</label>
                            <div className="col-sm-8">
                                <DatePicker
                                    dateFormat="dd-MM-yyyy"
                                    selected={this.state.birthdayto}
                                    onChange={date => this.setBirthdayToDate(date)}
                                    placeholderText="Birthday To"
                                    className='form-control'
                                    minDate = {this.state.minBirthdayDate}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label for="orderstart" className="col-sm-4 col-form-label"><FontAwesomeIcon icon={faCalendar} /> Order Start</label>
                            <div className="col-sm-8">
                                <DatePicker
                                    dateFormat="dd-MM-yyyy"
                                    selected={this.state.orderstart}
                                    onChange={date => this.setOrderStartDate(date)}
                                    placeholderText="Order Start"
                                    className='form-control'
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label for="orderend" className="col-sm-4 col-form-label"><FontAwesomeIcon icon={faCalendar} /> Order End</label>
                            <div className="col-sm-8">
                                <DatePicker
                                    dateFormat="dd-MM-yyyy"
                                    selected={this.state.orderend}
                                    onChange={date => this.setOrderEndDate(date)}
                                    placeholderText="Order End"
                                    className='form-control'
                                    minDate = {this.state.minOrderDate}
                                />
                            </div>
                        </div>

                        <div className="form-group row" style={{textAlign:"right", marginBottom:"20px"}}>
                            <div className="col-12">
                                {this.state.showfetched && 
                                    <span className="show-total-fetched">{this.state.fetchedNumbersTotal} Numbers Available</span>
                                }
                                {this.state.fetchbtntext=='Fetch Numbers' && 
                                    <button type="button" onClick={this.fetchNumber.bind(this)} className="btn btn-primary btn-sm"><FontAwesomeIcon icon={faDownload} /> {this.state.fetchbtntext}</button>
                                }
                                {this.state.fetchbtntext!='Fetch Numbers' && 
                                    <button type="button" onClick={this.fetchNumber.bind(this)} className="btn btn-warning btn-sm"><FontAwesomeIcon icon={faStopwatch} /> {this.state.fetchbtntext}</button>
                                }
                                <br />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="phonenumbers" className="col-sm-4 col-form-label"><FontAwesomeIcon icon={faPhone} /> Phone Numbers (<span style={{color:"red"}}>*</span>)</label>
                            <div className="col-sm-8">
                                <textarea type="text" className="form-control" rows="8" value={this.state.phonenumbers} onChange={this.handleChange.bind(this)} name="phonenumbers" id="phonenumbers"></textarea>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="messageToSend" className="col-sm-4 col-form-label"><FontAwesomeIcon icon={faEnvelope} /> Message to Send (<span style={{color:"red"}}>*</span>)</label>
                            <div className="col-sm-8">
                                <textarea type="text" className="form-control" rows="4" placeholder="Enter the message here" value={this.state.messageToSend} onChange={this.handleChange.bind(this)} name="messageToSend" id="messageToSend" maxlength="155"></textarea>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="sendon" className="col-sm-4 col-form-label"><FontAwesomeIcon icon={faEnvelope} /> Send On (<span style={{color:"red"}}>*</span>)</label>
                            <div className="col-sm-8">
                                <DatePicker
                                    // dateFormat="dd-MM-yyyy"
                                    selected={this.state.sendon}
                                    onChange={date => this.setSendOnDate(date)}
                                    placeholderText="Send On"
                                    className='form-control'
                                    minDate={new Date()}
                                    showTimeSelect
                                    dateFormat="Pp"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" onClick={this.saveMessage.bind(this)} className="btn btn-success">Create</button>
                    </div>
                    </div>
                </div>
                </div>
                <div className="row">
                    <div className='col'>
                        {error.status == true &&
                            <div className="alert alert-danger" role="alert">
                                {error.message}
                            </div>
                        }
                        {(show == true) &&
                            <DTable data={data} columns={columnsheadings} />
                        }
                        {show == false &&
                            <Loader className='loader-center'
                                type="Grid"
                                color="#00BFFF"
                                height={100}
                                width={100}
                            />
                        }
                    </div>
                </div>
                
                <div className="modal fade" id="view-numbers-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Phone Numbers</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <p id="phone-numbers-modal-content" style={{wordWrap: "break-word"}}></p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                    </div>
                </div>
                </div>

                <div className="modal fade" id="view-numbers-already-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Phone Numbers (Already Sent)</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <p id="phone-numbers-already-modal-content" style={{wordWrap: "break-word"}}></p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                    </div>
                </div>
                </div>
            </>
        );
    }
}
