import axios from "axios";
import chalk from 'chalk';

const API_URL = "https://deals.mu/scripts/reportsapi/cms.php";

class CmsService {
    
    getALL() {
        return axios.post(API_URL, {
            action: 'get_all'
        });
    }

    uploadImage(image, alt, album, url, link, sorting, username) {
        return axios.post(API_URL, {
            action: "upload_image",
            image: image,
            alt: alt,
            album: album,
            url: url,
            link: link,
            sorting: sorting,
            username: username
        });
    }

    updateSlide(id, alt, album, url, link, sorting, username) {
        return axios.post(API_URL, {
            action: "update_slide",
            alt: alt,
            album: album,
            url: url,
            link: link,
            sorting: sorting,
            username: username,
            id: id
        });
    }


    delImage(id, album) {
        return axios.post(API_URL,{
            action: "del_image",
            id: id,
            album: album
        });
    }

    updateSort(id, value, album) {
        return axios.post(API_URL, {
            action: 'change_sorting',
            id: id,
            value: value,
            album: album
        })
    }
}

export default new CmsService();