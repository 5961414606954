import React, { Component } from "react";
import axios from "axios";
const API_URL = 'https://deals.mu/scripts/reportsapi/mandrill.php';
export default class Emails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderid: props.orderid,
            email: props.email,
            emails: [],
            btnText: "Fetch Emails",
            disabled: false,
            user: props.user
        }
    }

    getEmails = (e) => {
        e.preventDefault();
        this.setState({
            disabled: true,
            btnText: "Fetching Emails....",
        });
        var params = {
            "email": this.state.email,
            "orderid": this.state.orderid
        }
        axios.post(API_URL, {params})
        .then(response => {
            // console.log("emails", response);
            this.setState({
                disabled: false,
                btnText: "Fetch Emails",
                emails: response.data
            });
        })
    }

    convertDate = (ts) => {
        var date = new Date(ts * 1000);
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var year = date.getFullYear();
        var hour = date.getHours();
        var min = date.getMinutes();
        var sec = date.getSeconds();
        return day + "/" + month + "/" + year + " " + hour + ":" + min + ":" + sec;
    }

    render() {
        return (
            <div className="add-reminder-row shadow-md">
                <div className="row">
                    <div className="col flex justify-between">
                        <h5 style={{marginBottom: "10px", textDecoration: "underline"}} className="orders-h1 font-bold text-base p-2 subsection-heading mb-2">Order Email History</h5>

                        <form onSubmit={e=>this.getEmails(e)} className="form-inline reminder-form">
                            <div className="form-group">
                                <button type="submit" className="btn btn-sm btn-success mt-2 bg-green-700" disabled={this.state.disabled}>{this.state.btnText}</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>From</th>
                                    <th>To</th>
                                    <th>Subject</th>
                                    <th className="text-center">State</th>
                                    <th className="text-center">Timestamp</th>
                                    <th className="text-center">Open</th>
                                    <th className="text-center">Clicks</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.emails.length>0 && this.state.emails.map((email, index) => (
                                    <tr key={index} className="text-sm">
                                        <td>{email.sender}</td>
                                        <td>{email.email}</td>
                                        <td>{email.subject}</td>
                                        <td className="text-center">{email.state}</td>
                                        <td className="text-center">{
                                            this.convertDate(email.ts)
                                        }</td>
                                        <td className="text-center">{email.opens}</td>
                                        <td className="text-center">{email.clicks}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            
        );
    }
}