import React, { Component } from "react";
import Loader from 'react-loader-spinner';
import DTable from './dtable.component';
import swal from 'sweetalert';

import AuthService from '../services/auth/auth.service';
import UtilityService from '../services/utility.service';
import RefundReportsService from '../services/erp/refunds.service';

import UserContext from "../contexts/UserContext";
import NoPermission from "./extras/nopermission.component";
export default class Credits extends Component {
    static contextType = UserContext
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            columnsheadings: [],
            options: {
                filterType: 'dropdown',
            },
            show: false,
            error: {
                status: false,
                message: ''
            },
            loadingvalues: true,
            user: [],
            allowed: true
        };
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    componentDidMount() {
        AuthService.checklogin().then(user => {
            this.setState({
                user: user
            });
           AuthService.getUserPermisions(user)
            .then(response => {
                var userpermissions = response.data;
                console.log({userpermissions});
                    if(userpermissions.includes('credits') || userpermissions.includes('all')) {
                    console.log('You are allowed!');
                    this.setState({
                        allowed: true
                    });
                    this.loadRefunds(true);
                } else {
                    console.log('You are not allowed!');
                    this.setState({
                        allowed: false
                    });
                }
            })

            console.log({user});
        }).catch(error => {
            window.location.href = '/login';
        });
    }

    loadRefunds = (all) => {
        this.setState({
            data: [],
            show: false,
            error: {
                status: false,
                message: ''
            },
            loadingvalues: true,
        });

        RefundReportsService.getRefunds('Credit', '', '', '', '', '', 0, this.state.user.user_email)
            .then(response => {
                console.log({response});
                if (response.data) {

                    this.setState({
                        data: response.data,
                        columnsheadings: [
                            { title: "Date Created" },
                            { title: "Customer Name" },
                            { title: "Order Details" },
                            { title: "Product Name" },
                            { title: "Payment Method & Reference" },
                            { title: "Booking Details" },
                            { title: "Total Selling Amount" },
                            { title: "Fees / Discounts" },
                            { title: "Credit Amount" },
                            { title: "Refund Reason" },
                            { title: "Explanation & Comments" },
                            { title: "Reservation Agent Name" },
                            { title: "Credit Status" },
                            { title: "Date Processed" },
                            { title: "Action" }
                        ],
                        show: true,
                        error: {
                            status: false,
                            message: ''
                        },
                        loadingvalues: false,
                    });
                } else {
                    this.setState({
                        data: [],
                        columnsheadings: [],
                        show: false,
                        error: {
                            status: true,
                            message: 'No data found'
                        },
                        loadingvalues: false,
                    });
                }
            })
            .catch(error => {
                this.setState({
                    data: [],
                    columnsheadings: [],
                    show: false,
                    error: {
                        status: true,
                        message: 'No data found'
                    },
                    loadingvalues: false,
                });
            });
    }


    render() {
        var { data, columnsheadings, options, show, error, loadingvalues } = this.state;

        if (this.state.allowed == false) {
          return <NoPermission />;
        }
        
        return (
            <>
                <div className="row">
                    <div className='col'>
                        <h2>All Credits</h2>
                        <hr />
                    </div>
                </div>
                <div className="row">
                    <div className='col'>
                        {loadingvalues == true &&
                            <p style={{ color: "#df153b" }}><b><i>Loading credits. Please Wait....</i></b></p>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className='col'>
                        {error.status == true &&
                            <div className="alert alert-danger" role="alert">
                                {error.message}
                            </div>
                        }
                        {(show == true) &&
                            <DTable data={data} columns={columnsheadings} />
                        }
                        {show == false &&
                            <Loader className='loader-center'
                                type="Grid"
                                color="#00BFFF"
                                height={100}
                                width={100}
                            />
                        }
                    </div>
                </div>

                {/* Create coupon modal */}
                <div className="modal fade create-coupon-modal" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Create Credit Coupon</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <form className="refund-create-coupon-modal-form">
                                <div className="modal-body">
                                    <h4>Enter the details below:</h4>
                                    <input type="hidden" id="coupon-modal-refund-id" />
                                    <div className="form-group">
                                        <label for="coupon-form-coupon-code" className="col-form-label"><b>Coupon Code:</b></label>
                                        <input type="text" className="form-control" id="coupon-form-coupon-code" required="required" placeholder="Coupon Code" readOnly />
                                    </div>
                                    <div className="form-group">
                                        <label for="coupon-form-customer-name" className="col-form-label"><b>Customer Name:</b></label>
                                        <input type="text" className="form-control" id="coupon-form-customer-name" required="required" placeholder="Customer Name" readOnly />
                                    </div>
                                    <div className="form-group">
                                        <label for="coupon-form-customer-email" className="col-form-label"><b>Customer Email:</b></label>
                                        <input type="text" className="form-control" id="coupon-form-customer-email" required="required" placeholder="Customer Email" readOnly />
                                    </div>
                                    <div className="form-group">
                                        <label for="coupon-form-credit-amount" className="col-form-label"><b>Credit Amount:</b></label>
                                        <input type="text" className="form-control" id="coupon-form-credit-amount" required="required" placeholder="Credit Amount" readOnly />
                                    </div>
                                    <div className="form-group">
                                        <label for="coupon-form-credit-validity" className="col-form-label"><b>Credit Validity:</b></label>
                                        <input type="text" className="form-control date-picker" id="coupon-form-credit-validity" required="required" placeholder="Credit Validity" />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary coupon-refund-close-btn" data-dismiss="modal">Close</button>
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}