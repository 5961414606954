import React, { useState } from 'react';
import Select from 'react-select';
import axios from 'axios';
import AsyncSelect from 'react-select/async';

const APIURL = 'https://deals.mu/scripts/reportsapi/rodrigues_bookings.php';

export default function RodriguesBookingRow({key, row, index, username, allStatus}) {

    const [currentRow, setCurrentRow] = useState(row);
    const [updating, setUpdating] = useState(false);
    const [allOrderIDs, setAllOrderIDs] = useState([]);
    const [bgClass, setBgClass] = useState(row.status.value.toLowerCase().replace(/\s+/g, '-'));
    // console.log({currentRow});

    // remove first index in allStatus
    allStatus = allStatus.slice(1);

    const handleEdit = (id, field, value) => {
        setUpdating(true);
        if(field === 'status') {
            var bgColor = value.value.toLowerCase().replace(/\s+/g, '-');
            setBgClass(bgColor);
        }       

        console.log({id, field, value});
        var params = {
            action: 'editRow',
            id: id,
            field: field,
            value: value,
            username: username
        }
        axios.post(APIURL, {params})
        .then(response => {
            console.log(response.data);
            if (response.data.status === 'success') {
                if(field === 'status') {
                    bgColor = value.value.toLowerCase().replace(/\s+/g, '-');
                    setBgClass(bgColor);
                }
                var tempRow = currentRow;
                tempRow[field] = value;
                tempRow.agent = username;
                tempRow.bgclass = bgColor;
                setCurrentRow(prev => tempRow);
                console.log({tempRow});
                setUpdating(false);
            }
        })
        .catch(error => {
            console.log(error);
        });
    }

    const processAgent = (agent) => {
        const lowercased = agent.toLowerCase();
        const replaced = lowercased.replace(/abz/g, "").replace(/-/g, "");
        const ucfirst = (str) => {
            if (str.length === 0) {
                return str;
            }

            return str.charAt(0).toUpperCase() + str.slice(1);
        };

        return ucfirst(replaced);
    }

    return (
        <>
        <tr key={index} className={(updating===true) ? "row-bg-updating" : "row-bg-"+bgClass}>
            <td>{processAgent(currentRow.agent)}<br />{(updating===true) && <span className="text-success"><b>Updating...</b></span>}</td>
            <td><a target="_blank" style={{textDecoration: "underline", fontWeight: "bold"}} href={`/edit-order/${currentRow.order_id}`} rel="noreferrer">{currentRow.order_id}</a></td>
            <td>{currentRow.customer_name}</td>
            <td>{currentRow.product}</td>
            <td>{currentRow.booking_start_date} - {currentRow.booking_end_date}</td>
            <td>{currentRow.booking_reference_am}</td>
            <td>
                <div style={{width: "200px"}}>
                    <Select
                        options={allStatus}
                        value={currentRow.status}
                        onChange={e => handleEdit(currentRow.id, 'status', e)}
                    />
                </div>
            </td>
            <td>
                <textarea className="form-control mb-10" rows={2} value={currentRow.comments} onChange={e => setCurrentRow({...currentRow, "comments": e.target.value})}></textarea>
                {currentRow.comments=="" && 
                    <button className="btn btn-primary btn-sm" onClick={e => handleEdit(currentRow.id, 'comments', currentRow.comments)}>Update</button>
                }
                {currentRow.comments!="" && 
                    <button className="btn btn-warning btn-sm" onClick={e => handleEdit(currentRow.id, 'comments', currentRow.comments)}>Update</button>
                }
            </td>
            <td>
                <textarea className="form-control mb-10" rows={2} value={currentRow.supervisor_comments} onChange={e => setCurrentRow({...currentRow, "supervisor_comments": e.target.value})}></textarea>
                {currentRow.supervisor_comments=="" && 
                    <button className="btn btn-primary btn-sm" onClick={e => handleEdit(currentRow.id, 'supervisor_comments', currentRow.supervisor_comments)}>Update</button>
                }
                {currentRow.supervisor_comments!="" && 
                    <button className="btn btn-warning btn-sm" onClick={e => handleEdit(currentRow.id, 'supervisor_comments', currentRow.supervisor_comments)}>Update</button>
                }
            </td>
            <td>
                <div style={{fontSize: "14px"}}>
                    <span><i>Added On: {currentRow.added_on}</i></span><br />
                    <span><i>Added By: {currentRow.added_by}</i></span>
                </div>
            </td>
        </tr>
        </>
    );
}
