import React, { Component } from "react";
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { style } from "@mui/system";
import swal from "sweetalert";

import OrdersReportService from "../../services/orders/ordersreport.service";

export default class SupplierConfirmed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderid: props.orderid,
            itemid: props.itemid,
            supplier_confirmed: props.supplier_confirmed,
            rerender: false,
            btntext: "Manually Update",
            disablebtn: false,
            isopen: false,
            openconfirmbyphone: false,
            updtatephonebtntext: "Confirm Booking & Send Email to Supplier >>",
            agentname: "",
            personname: "",
            phonecalltime: "",
            phonecalldate: "",
            updatingbyemail: false,
            confirmed_by: props.confirmed_by,
            confirmation_comments: props.confirmation_comments,
            supplier_confirmed_time: props.supplier_confirmed_time,
            user: props.user,
            travelpackage: props.travelpackage,
            multipleSuppliers: props.multiplesuppliers,
            multipleSelectedCompany: "",
            multipleSelectedSupplier: "",
            travelAlreadyConfirmed: []
        }
    }

    componentDidMount() {

        if(this.state.travelpackage!=false) {
            this.getAlreadyConfirmed();
        }
    }

    updateSupplierConfirmed = (e) => {
        e.preventDefault();
    }

    openConfirmPopup(e) {
        e.preventDefault();
        this.setState({
            isopen: true
        });
    }

    confirmByEmail(e) {
        e.preventDefault();
        this.setState({
            openconfirmbyphone: false,
            updatingbyemail: true,
            disablebtn: true,
        });

        OrdersReportService.updateSupplierConfirmed('email', this.state.orderid, this.state.itemid, this.state.user.user_nicename, "", "", "", "", this.state.multipleSelectedCompany, this.state.multipleSelectedSupplier)
        .then(response => {
            this.setState({
                openconfirmbyphone: false,
                updatingbyemail: false,
                disablebtn: true,
                isopen: false,
                supplier_confirmed: true,
            });
            swal("Confirmed", "Supplier confirmation updated for the booking!", "success");
            this.getAlreadyConfirmed();
        })
        .catch(error => console.log(error));

    }
    

    openConfirmByPhone(e) {
        e.preventDefault();
        this.setState({
            openconfirmbyphone: !this.state.openconfirmbyphone
        });
    }

    updateSupplierConfirmedByPhone = (e) => {
        e.preventDefault();

        this.setState({
            updtatephonebtntext: "Updating booking ....",
            disablebtn: true,
        });

        OrdersReportService.updateSupplierConfirmed('phone', this.state.orderid, this.state.itemid, this.state.user.user_nicename, this.state.phonecalldate, this.state.phonecalltime, this.state.personname, this.state.agentname, this.state.multipleSelectedCompany, this.state.multipleSelectedSupplier)
        .then(response => {
            // console.log({response});
            this.setState({
                openconfirmbyphone: false,
                updatingbyemail: false,
                disablebtn: true,
                isopen: false,
                supplier_confirmed: true
            });
            swal("Confirmed", "Supplier confirmation updated for the booking!", "success");
            this.getAlreadyConfirmed();
        })
        .catch(error => console.log(error));

    }

    getAlreadyConfirmed = () => {
        OrdersReportService.getAlreadyConfirmedTravel(this.state.orderid, this.state.itemid)
        .then(res => {
            // console.log({res});
            this.setState({
                travelAlreadyConfirmed: res.data
            });
        })
        .catch(err => {
            console.log({err});
        });
    }

    updateMultiSuppliers = (e, type) => {
        e.preventDefault();
        if(type=='company') {
            this.setState({
                multipleSelectedCompany: e.target.value,
            })
        } else if(type=='supplier') {
            this.setState({
                multipleSelectedSupplier: e.target.value,
            })
        }
        OrdersReportService.updateMultiSupplier(this.state.orderid, this.state.itemid, type, e.target.value, this.state.user.user_nicename)
        .then(res => {
            // this.checkMulti();
        })
        .catch(error => console.log(error));
    }

    render() {
        const style = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        };
        return (
            <>
            {(this.state.supplier_confirmed==false || this.state.travelpackage!=false) && 
            <>
                {(this.state.travelpackage==false) && 
                    <Chip label={"No"} color="error" />
                }
                <button type="button" className="btn btn-sm btn-link" onClick={e => this.openConfirmPopup(e)} style={{marginTop: "20px"}}>{this.state.btntext}</button>
            </>
            }
            {this.state.supplier_confirmed!=false && this.state.travelpackage==false && 
            <>
                {(this.state.travelpackage==false) && 
                    <Chip label={"Yes"} color="success" />
                }
                
                {this.state.confirmed_by!=false && 
                <>
                    <p style={{marginTop: "15px"}}>Confirmed by Supplier</p>
                    <p style={{marginTop: "5px"}}>Confirmed by: {this.state.confirmed_by}</p>
                    <p style={{marginTop: "5px"}}>Confirmed Time: {this.state.supplier_confirmed_time}</p>
                    {this.state.confirmation_comments!="" && 
                        <p style={{marginTop: "5px"}}>Comments: {this.state.confirmation_comments}</p>
                    }
                </>
                }
                {this.state.confirmed_by==false && 
                    <p style={{marginTop: "15px"}}>Confirmed Manually</p>
                }
            </>
            } 

            {(this.state.travelpackage!=false) && 
                <>
                    {this.state.travelAlreadyConfirmed.length>0 && this.state.travelAlreadyConfirmed.map((entry, index) => (
                        <p key={index} style={{fontSize: "0.8rem"}}>{entry.supplier+" ("+entry.company+")"} {entry.method}</p>
                    ))}
                </>
            }

            <Modal
                hideBackdrop
                open={this.state.isopen}
                onClose={e => {this.setState({isopen: false})}}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{...style, width: 800, textAlign: 'center' }}>
                <h3 id="child-modal-title" style={{marginBottom: "20px"}}>Please Choose How Supplier Confirmed The Booking</h3>
                <p>
                    {this.state.travelpackage!=false && ("companies" in this.state.multipleSuppliers) && ("suppliers" in this.state.multipleSuppliers) &&
                    <div className="form-group">
                        <label for="couponToApply" className="col-form-label"><b>Select Company / Supplier</b></label>
                        <div className="">
                            <select className="form-control" value={this.state.multipleSelectedCompany} onChange={e=>{this.updateMultiSuppliers(e,'company')}}>
                            <option value="">Select Company</option>
                                {("companies" in this.state.multipleSuppliers) && this.state.multipleSuppliers.companies[0].map((company, index)=>(
                                    <option key={index} value={company}>{company}</option>
                                ))}
                            </select>
                            <select className="form-control" value={this.state.multipleSelectedSupplier} onChange={e=>{this.updateMultiSuppliers(e,'supplier')}} style={{marginTop: "10px"}}>
                                <option value="">Select Supplier</option>
                                {("suppliers" in this.state.multipleSuppliers) && this.state.multipleSuppliers.suppliers[0].map((supplier, index2)=>(
                                    <option key={index2} value={supplier}>{supplier}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    }
                </p>
                <p id="child-modal-description" style={{marginBottom: "20px", display: "flex", justifyContent: "space-around"}}>
                    <span className="confirm-buttons-email" onClick={(e) => this.confirmByEmail(e)}>
                        <h2>By Email</h2>
                        <span>Supplier already sent and approved the booking by email</span>
                        {this.state.updatingbyemail==true && 
                            <p style={{    fontSize: "18px", fontWeight: "bold", marginTop: "20px"}}>Updating booking....</p>
                        }
                    </span>
                    <span className="confirm-buttons-phone" onClick={(e) => this.openConfirmByPhone(e)}>
                        <h2>By Phone</h2>
                        <span>Booking Approved over the phone - Confirmation Email should be Sent to Supplier</span>
                    </span>
                </p>
                {this.state.openconfirmbyphone==true && 
                <form onSubmit={(e) => this.updateSupplierConfirmedByPhone(e)} style={{textAlign: "left"}}>

                    <div className="row">
                        <div className="col supplier-confirm-phone-heading">
                            Supplier Phone Booking Record
                        </div>
                    </div>

                    <div className="row">
                        <div className="col supplier-confirm-phone-sub-heading">
                            Since supplier confirmed the booking over the phone, fill in the below details to record this conversation
                            <hr />
                        </div>
                    </div>
                     <div className="form-group row">
                        <label for="phonecalldate" className="col-sm-7 col-form-label"><b>Phone Call Date</b></label>
                        <div className="col-sm-5">
                            <input type="date" className="form-control" onChange={e => {this.setState({phonecalldate: e.target.value})}} id="phonecalldate" placeholder="Choose Date" required="required" value={this.state.phonecalldate} />
                        </div>
                    </div>
                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="phonecalltime" className="col-sm-7 col-form-label"><b>Phone Call Time</b></label>
                        <div className="col-sm-5"> 
                            <input type="time" className="form-control" id="phonecalltime" placeholder="Choose Time" required="required" onChange={e => {this.setState({phonecalltime: e.target.value})}} value={this.state.phonecalltime} />
                        </div>
                    </div>
                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="personname" className="col-sm-7 col-form-label"><b>Name of the Person Confirming the Booking</b></label>
                        <div className="col-sm-5"> 
                            <input type="text" className="form-control" id="personname" placeholder="Person name" required="required" onChange={e => {this.setState({personname: e.target.value})}} value={this.state.personname} />
                        </div>
                    </div>
                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="agentname" className="col-sm-7 col-form-label"><b>Deals Contact Person who made the Call</b></label>
                        <div className="col-sm-5"> 
                            <input type="text" className="form-control" id="agentname" placeholder="Agent Name" required="required" onChange={e => {this.setState({agentname: e.target.value})}} value={this.state.agentname} />
                        </div>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", marginTop: "40px"}}>
                        <button type="submit" className="btn btn-sm btn-success" style={{marginRight: "20px"}} disabled={this.state.disablebtn}>{this.state.updtatephonebtntext}</button><button type="button" className="btn btn-sm btn-danger" onClick={e => {this.setState({isopen: false})}}>Close</button>
                    </div>
                </form>
                }
                {this.state.openconfirmbyphone==false && 
                    <button type="button" className="btn btn-sm btn-danger" onClick={e => {this.setState({isopen: false})}}>Close</button>
                } 
                </Box>
            </Modal>
            </>
        );
    }
}