import React, { useEffect, useState } from 'react';
import axios from 'axios';

const APP_URL = "https://deals.mu/scripts/reportsapi/ordersreport.php";

const PointsCouponsList = ({orderID, reRender}) => {

    const [orderid, setOrderID] = useState(orderID);
    const [data, setData] = useState(null);
    const [reRenderLocal, setReRenderLocal] = useState(reRender);

    useEffect(() => {
        // console.log("order id useEffect", orderid);
        if(orderid > 0) {
            getData();
        }
    }, [orderid, reRenderLocal]);

    // useEffect(() => {
    //      getData();
    // }, [reRenderLocal]);

    const getData = () => {
        setData(null);
        var params = {
            orderid: orderid,
            action: "getPointsCoupons"
        };
        axios.post(APP_URL, {params})
        .then(response => {
            console.log("get points coupons", response);
            setData(response.data);
        })
        .catch(error => {
            console.log(error);
        });
    }

  return (
    <div>
        {data!=null && 
        <>
        <div className="row" style={{margin:"30px 10px 10px 10px", padding: "15px", border: "1px solid #dddddd"}}>
            <div className="col-12">
                <h5 id="modal-title">Requested Points Coupon:</h5>
                <div id="modal-description">
                    <table style={{width: "100%"}}>
                        <thead style={{textAlign: "left"}}>
                            <tr>
                                <th>Date Created</th>
                                <th>Agent</th>
                                <th>Points</th>
                                <th>Email</th>
                                <th>Explanation</th>
                                <th>Amount Wrong?</th>
                                <th>Processed Refund Date</th>
                                <th>Coupon Status</th>
                                <th>Coupon Code</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{data.date_created}</td>
                                <td>{data.agent}</td>
                                <td>{data.points}</td>
                                <td>{data.email}</td>
                                <td>{data.explanation}</td>
                                <td>{data.why_amount_wrong}</td>
                                <td>{data.processed_refund_date}</td>
                                <td>{data.coupon_status}</td>
                                <td>{data.coupon_code}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        </>
        }
    </div>
  )
}

export default PointsCouponsList;