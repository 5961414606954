import React, { Component } from "react";
import Loader from 'react-loader-spinner';
import DTable from './dtable.component';
import Select from 'react-select';
import parse from 'html-react-parser';

import AuthService from '../services/auth/auth.service';
import PaymentReportsService from '../services/erp/paymentreports.service';
import InvoicesService from '../services/erp/invoices.service';
import SuppliersService from '../services/erp/suppliers.service';

import UserContext from "../contexts/UserContext";
import NoPermission from "./extras/nopermission.component";
export default class Suppliers extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            columnsheadings: [],
            suppliers: [{ value: 0, label: 'Loading..' }],
            companies: [{ value: 0, label: 'Loading..' }],
            suppliername: { value: 'All', label: 'Select a Supplier' },
            companyname: { value: 0, label: 'Select a Company' },
            options: {
                filterType: 'dropdown',
            },
            show: false,
            error: {
                status: false,
                message: ''
            },
            loadingvalues: true,
            loader: false,
            user: [],
            search_btn_text: 'Search',
            isDisableSuppliers: true,
            searchButtonDisable: true,
            supplierid: '',
            brn: '',
            vat: '',
            vat_registered: '',
            key_contacts: [],
            bank_details: [],
            contracts: [],
            related_products: [],
            related_comments: [],
            related_commissions: [],
            related_invoices: {
                invoices: [],
                totals: []
            },
            related_payments: [],
            productname: {value: 0, label: 'Select a product'},
            productnamehidden: {value: 0, label: 'Select a product'},
            invoiceproducts: { value: 0, label: 'Select a product' },
            invoiceprices: { value: 'NET Rates', label: 'NET Rates' },
            invoicespricesoptions: [{ value: 'NET Rates', label: 'NET Rates' }, { value: "Public Rates (commisionable)", label: "Public Rates (commisionable)" }],
            allowed: true
        };
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    componentDidMount() {

        PaymentReportsService.getAllSuppliers()
            .then(response => {
                if (response.data.suppliers) {
                    this.setState({
                        suppliers: response.data.suppliers,
                        companies: response.data.companies,
                        loadingvalues: false,
                    });
                }
            })
            .catch(error => {
                console.log({ error });
            });

        AuthService.checklogin().then(user => {

            this.setState({
                user: user
            });

            AuthService.getUserPermisions(user)
            .then(response => {
                var userpermissions = response.data;
                console.log({userpermissions});
                    if(userpermissions.includes('suppliers') || userpermissions.includes('all')) {
                    console.log('You are allowed!');
                    this.setState({
                        allowed: true
                    });
                } else {
                    console.log('You are not allowed!');
                    this.setState({
                        allowed: false
                    });
                }
            })

        }).catch(error => {
            window.location.href = '/login';
        });
    }

    handleChangCompany = (selected) => {

        this.setState({
            isDisableSuppliers: true,
            searchButtonDisable: true,
            suppliername: { value: 'All', label: 'Select a Supplier' },
            supplierid: '',
            brn: '',
            vat: '',
            vat_registered: '',
            key_contacts: [],
            bank_details: [],
            contracts: [],
            related_products: [],
            related_comments: [],
            related_commissions: [],
            related_invoices: {
                invoices: [],
                totals: []
            },
            related_payments: [],
            show: false 
        });

        this.setState({
            companyname: selected,
            suppliers: [{ value: 0, label: 'Loading..' }],
        });

        InvoicesService.fetchSuppliers(selected.value)
            .then(response => {
                console.log(response);
                this.setState({
                    suppliers: response.data,
                    isDisableSuppliers: false
                });
            })
            .catch(error => {
                console.log(error);
            })
    }

    handleChangeSuplier = (selected) => {
        this.setState({
            searchButtonDisable: false,
            suppliername: selected,
            supplierid: '',
            brn: '',
            vat: '',
            vat_registered: '',
            key_contacts: [],
            bank_details: [],
            contracts: [],
            related_products: [],
            related_comments: [],
            related_commissions: [],
            related_invoices: {
                invoices: [],
                totals: []
            },
            related_payments: [],
            show: false 
        });

        this.setState({ commsuppliername: selected });
        this.setState({ commsuppliernamehidden: selected });
        if(selected.value==0 || selected.value=='All') {
            this.setState({
                productname: {value: 0, label: 'Select a product'},
                productnamehidden: {value: 0, label: 'Select a product'},
                invoiceproducts: {value: 0, label: 'Select a product'}
            });
        } else {
            InvoicesService.fetchProducts(this.state.companyname.value, selected.value)
            .then(response => {
                console.log(response);
                this.setState({
                    invoiceproducts: response.data,
                });
            })
            .catch(error => {
                console.log(error);
            })
        }
    }

    handleChangeCommPricesType = selectedOption => {
        this.setState({ invoiceprices: selectedOption });
        // console.log(`Option selected:`, selectedOption);
    };

    handleChangeCommProducts = selectedOption => {
        this.setState({ productname: selectedOption });
        this.setState({ productnamehidden: JSON.stringify(selectedOption) });
        console.log(`Option selected:`, selectedOption);
    };

    searchClick = () => {
        this.setState({
            supplierid: '',
            brn: '',
            vat: '',
            vat_registered: '',
            key_contacts: [],
            bank_details: [],
            contracts: [],
            related_products: [],
            related_comments: [],
            related_commissions: [],
            related_invoices: {
                invoices: [],
                totals: []
            },
            related_payments: [],
            show: false,
            loader: true
        });
        console.log('Search clicked');
        SuppliersService.getBasic(this.state.companyname.value, this.state.suppliername.value)
        .then(response => {
            console.log({response});

            if(response.data.brn !== 'undefined') {
                this.setState({
                    supplierid: response.data.supplierid,
                    brn: response.data.brn,
                    vat: response.data.vat,
                    vat_registered: response.data.vat_register,
                    key_contacts: response.data.key_contacts,
                    bank_details: response.data.bank_details,
                    show: true,
                    loader: false
                });
            } else {
                // error
            }
        })
        .catch(error => {
            console.log({error});
        });

        InvoicesService.fetchProducts(this.state.companyname.value, this.state.suppliername.value)
            .then(response => {
                console.log(response);
                this.setState({ 
                    invoiceproducts: response.data,
                    
                });
            })
            .catch(error => {
                console.log(error);
            })

        SuppliersService.fetchProducts(this.state.companyname.value, this.state.suppliername.value)
        .then(response => {
            // console.log({response});

            if(response.data !== 'undefined') {
                this.setState({
                    related_products: response.data
                });
            } else {
                // error
            }
        })
        .catch(error => {
            console.log({error});
        });

        SuppliersService.fetchComments(this.state.companyname.value, this.state.suppliername.value)
        .then(response => {
            // console.log({response});

            if(response.data !== 'undefined') {
                this.setState({
                    related_comments: response.data
                });
            } else {
                // error
            }
        })
        .catch(error => {
            console.log({error});
        });

        SuppliersService.fetchContracts(this.state.companyname.value, this.state.suppliername.value)
        .then(response => {
            // console.log({response});

            if(response.data !== 'undefined') {
                this.setState({
                    contracts: response.data
                });
            } else {
                // error
            }
        })
        .catch(error => {
            console.log({error});
        });

        SuppliersService.fetchCommissions(this.state.companyname.value, this.state.suppliername.value)
        .then(response => {
            // console.log({response});

            if(response.data !== 'undefined') {
                this.setState({
                    related_commissions: response.data
                });
            } else {
                // error
            }
        })
        .catch(error => {
            console.log({error});
        });

        // SuppliersService.fetchInvoices(this.state.companyname.value, this.state.suppliername.value)
        // .then(response => {
        //     // console.log({response});

        //     if(response.data !== 'undefined') {
        //         this.setState({
        //             related_invoices: response.data
        //         });
        //     } else {
        //         // error
        //     }
        // })
        // .catch(error => {
        //     console.log({error});
        // });

    }

    

    numberWithCommas = (x) => {
        var y =  +(Math.round(x + "e+2")  + "e-2");
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    handleChangeBankAccountComment = (e, index) => {
        e.preventDefault();
        var comment = e.target.value;
        var bank_details_temp = this.state.bank_details;
        bank_details_temp[index].comments = comment;
        this.setState({
            bank_details: bank_details_temp
        });
    }

    render() {
        var { data, columnsheadings, options, show, error, loadingvalues, companies, suppliers, search_btn_text } = this.state;
        var vatRegistered = this.state.vat_registered;
        if(vatRegistered!='' && this.state.vat_registered!=null) {
            vatRegistered = this.state.vat_registered.charAt(0).toUpperCase() + this.state.vat_registered.slice(1);
        }

        if(this.state.allowed==false) {
            return (
                <NoPermission />
            );
        }

        return (
            <>
                <div className="row">
                    <div className='col'>
                        <h2>Supplier Details</h2>
                        <hr />
                    </div>
                </div>
                <div className="row">
                    <div className='col'>
                        {loadingvalues == true &&
                            <p style={{ color: "#df153b" }}><b><i>Loading suppliers. Please Wait....</i></b></p>
                        }
                        <div className="booking-reports-search-form" style={{ display: 'flex' }}>
                            <div style={{ marginLeft: '10px', width: '300px' }}>
                                <Select
                                    value={this.state.companyname}
                                    onChange={this.handleChangCompany}
                                    options={companies}
                                />
                            </div>

                            <div style={{ marginLeft: '10px', width: '300px' }}>
                                <Select
                                    value={this.state.suppliername}
                                    onChange={this.handleChangeSuplier}
                                    options={suppliers}
                                    isDisabled= {this.state.isDisableSuppliers}
                                />
                            </div>
                            
                            <div style={{ marginLeft: '10px' }}>
                                <button className="btn btn-success booking-search-btn comm-search-btn" disabled={this.state.searchButtonDisable} onClick={this.searchClick}>{search_btn_text}</button>
                            </div>
                        </div>

                        <hr />
                    </div>
                </div>
                {this.state.show && 
                <div className="row">
                    <div className='col'>
                       <span><h4 style={{borderBottom: "1px solid #cecdcd", marginBottom:'20px'}}>Supplier details <i style={{fontSize: '14px'}}>(To load this section correctly please select both company and supplier)</i></h4></span>
                       <div className="supplierDetailsContainer">
                           <div className="form-group row" id="supplier-basics-container">
                               <input type="hidden" value={this.state.companyname.supplierid} />
                                <label for="supplier-basics-company" className="col-sm-3 col-form-label">Company Name:</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" disabled="disabled" value={this.state.companyname.value} id="supplier-basics-company" placeholder="Company" />
                                </div>
                            </div>
                            <div className="form-group row" id="supplier-basics-container">
                                <label for="supplier-basics-brn" className="col-sm-3 col-form-label">BRN</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" id="supplier-basics-brn" value={this.state.brn} disabled="disabled" placeholder="BRN" />
                                </div>
                            </div>
                            <div className="form-group row" id="supplier-basics-container">
                                <label for="supplier-basics-supplier" className="col-sm-3 col-form-label">Supplier Name:</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" disabled="disabled" id="supplier-basics-supplier" value={this.state.suppliername.value} placeholder="Supplier Name" />
                                </div>
                            </div>
                            <div className="form-group row" id="supplier-basics-container">
                                <label for="supplier-basics-vat-registered" className="col-sm-3 col-form-label">VAT registered</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" id="supplier-basics-vat-registered" disabled="disabled" value={vatRegistered} placeholder="Vat Registered" />
                                </div>
                            </div>
                            <div className="form-group row" id="supplier-basics-container">
                                <label for="supplier-basics-vat" className="col-sm-3 col-form-label">VAT</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" id="supplier-basics-vat" disabled="disabled" value={this.state.vat} placeholder="Vat" />
                                </div>
                            </div>

                            <div className="form-group row" id="supplier-basics-container" style={{justifySelf: 'end'}}>
                                <button className="btn btn-sm btn-warning edit-info-btn">Edit Info</button>
                            </div>
                       </div>
                    </div>

                    

                    <div className="modal fade edit-company-info-modal" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Edit Supplier Info</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <form className="edit-company-info-modal-form">
                                    <div className="modal-body">
                                        <h4>Update the details below:</h4>
                                       <input type="hidden" value={this.state.supplierid} id="edit-company-info-id" name="edit-company-info-id" />
                                        <input type="hidden" name="edit-company-info-comapny" id="edit-company-info-comapny" value={this.state.companyname.value} />
                                        <input type="hidden" name="edit-company-info-supplier" id="edit-company-info-supplier" value={this.state.suppliername.value} />

                                        <div className="form-group">
                                            <label for="edit-company-info-brn" className="col-form-label"><b>BRN:</b></label>
                                            <input type="text" className="form-control" name="edit-company-info-brn" id="edit-company-info-brn" required="required" placeholder="BRN" />
                                        </div>
                                        <div className="form-group">
                                            <label for="edit-company-info-vat-registered" className="col-form-label"><b>VAT registered:</b></label>
                                            <select className='form-control form-control-select' name="edit-company-info-vat-registered" id="edit-company-info-vat-registered">
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label for="edit-company-info-vat" className="col-form-label"><b>VAT:</b></label>
                                            <input type="text" className="form-control" name="edit-company-info-vat" id="edit-company-info-vat" placeholder="VAT" />
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary edit-company-info-close-btn" data-dismiss="modal">Close</button>
                                        <button type="submit" className="btn btn-primary">Update</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
                }

                {this.state.show && 
                <div className="row">
                    <div className='col'>
                       <span><h4 style={{borderBottom: "1px solid #cecdcd", marginBottom:'20px'}}>Key Contacts</h4></span>
                       {/* {this.state.key_contacts.map((contact, index) => ( */}
                       <table class="table table-bordered">
                           <thead>
                               <tr>
                                   <th>Supplier</th>
                                   <th style={{width: '20%'}}>Name</th>
                                   <th style={{width: '15%'}}>Role</th>
                                   <th style={{width: '12%'}}>Tel</th>
                                   <th>Email</th>
                                   <th>Type</th>
                                   <th style={{width: '13%'}}>Actions</th>
                               </tr>
                           </thead>
                           <tbody>
                               {this.state.key_contacts.map((contact, index) => (
                                <tr key={index}>
                                   <td>{contact.supplier}</td>
                                   <td>{contact.name}</td>
                                   <td>{contact.role}</td>
                                   <td>{contact.tel}</td>
                                   <td>{contact.email}</td>
                                   <td>{ contact.type == '' ? 'Reservation': contact.type.charAt(0).toUpperCase() + contact.type.slice(1) }</td>
                                   <td>
                                       <button className='btn btn-sm btn-warning edit-contact-btn' data-id={contact.id} edit-index-id={index} data-name={contact.name} data-role={contact.role} data-tel={contact.tel} data-email={contact.email} data-exists={contact.exists} data-supplier={contact.supplier} data-company={contact.company} data-type={ contact.type == '' ? 'Reservation': contact.type }>Edit</button>
                                        {contact.exists!='yes' && 
                                            <button className='btn btn-sm btn-danger delete-contact-btn' style={{marginLeft:"20px"}} data-id={contact.id} edit-index-id={index} data-name={contact.name} data-role={contact.role} data-tel={contact.tel} data-email={contact.email} data-exists={contact.exists}>Delete</button>
                                        }
                                        {contact.exists=='yes' && 
                                            <button className='btn btn-sm btn-danger delete-contact-btn' style={{marginLeft:"20px"}} data-id={contact.id} edit-index-id={index} data-name={contact.name} data-role={contact.role} data-tel={contact.tel} data-email={contact.email} data-exists={contact.exists}>Disable</button>
                                        }
                                   </td>
                                 </tr>
                                ))}
                                </tbody>
                                </table>
                       {/* <div className="KeyContactsContainer" id={`contacts-container-${index}`} key={index}>
                           <div className="form-group row" id="contact-input-container">
                                <label for="contacts-name" className="col-sm-3 col-form-label">Name:</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" id="contacts-name" disabled="disabled" value={contact.name} placeholder="Name" />
                                </div>
                            </div>
                            <div className="form-group row" id="contact-input-container">
                                <label for="contacts-role" className="col-sm-3 col-form-label">Role</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" id="contacts-role" disabled="disabled" value={contact.role} placeholder="Role" />
                                </div>
                            </div>
                            <div className="form-group row" id="contact-input-container">
                                <label for="contacts-tel" className="col-sm-3 col-form-label">Tel:</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" id="contacts-tel" disabled="disabled" value={contact.tel} placeholder="Telephone" />
                                </div>
                            </div>
                            <div className="form-group row" id="contact-input-container">
                                <label for="contacts-email" className="col-sm-3 col-form-label">Email:</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" id="contacts-email" disabled="disabled" value={contact.email} placeholder="Email" />
                                </div>
                            </div>
                            <div className="form-group row" id="contact-input-container" style={{justifySelf:'center'}}>
                                <button className='btn btn-sm btn-warning edit-contact-btn' data-id={contact.id} edit-index-id={index} data-name={contact.name} data-role={contact.role} data-tel={contact.tel} data-email={contact.email} data-exists={contact.exists} data-supplier={contact.supplier} data-company={contact.company}>Edit</button>
                                {contact.exists=='no' && 
                                    <button className='btn btn-sm btn-danger delete-contact-btn' style={{marginLeft:"10px"}} data-id={contact.id} edit-index-id={index} data-name={contact.name} data-role={contact.role} data-tel={contact.tel} data-email={contact.email} data-exists={contact.exists}>Delete</button>
                                }
                            </div>
                       </div> */}
                       {/* ))} */}
                       <button className="btn btn-sm btn-primary add-new-contact-btn" data-toggle="modal" data-target=".create-contact-modal" style={{gridColumnEnd: '-1', justifySelf: 'end', float: 'right'}}>Add New Contact</button>

                        <div className="modal fade create-contact-modal" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-md">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Create/Edit Contact</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <form className="create-contact-modal-form">
                                        <div className="modal-body">
                                            <h4>Enter the details below:</h4>
                                            <input type="hidden" name="create-contact-id" id="create-contact-id" value="" />
                                            <input type="hidden" name="create-contact-exists" id="create-contact-exists" value="" />
                                            <input type="hidden" name="create-contact-comapny" id="create-contact-comapny" />
                                            <input type="hidden" name="create-contact-supplier" id="create-contact-supplier" />

                                            <div className="form-group">
                                                <label for="create-contact-name" className="col-form-label"><b>Name:</b></label>
                                                <input type="text" className="form-control" name="create-contact-name" id="create-contact-name" required="required" placeholder="Name" />
                                            </div>
                                            <div className="form-group">
                                                <label for="create-contact-role" className="col-form-label"><b>Role:</b></label>
                                                <input type="text" className="form-control" name="create-contact-role" id="create-contact-role" required="required" placeholder="Role" />
                                            </div>
                                            <div className="form-group">
                                                <label for="create-contact-tel" className="col-form-label"><b>Tel:</b></label>
                                                <input type="text" className="form-control" name="create-contact-tel" id="create-contact-tel" required="required" placeholder="Telephone" />
                                            </div>
                                            <div className="form-group">
                                                <label for="create-contact-email" className="col-form-label"><b>Email:</b></label>
                                                <input type="text" className="form-control" name="create-contact-email" id="create-contact-email" required="required" placeholder="Email" />
                                            </div>

                                            <div className="form-group">
                                                <label for="create-contact-type" className="col-form-label"><b>Type:</b></label>
                                                <select className="form-control form-control-select" id="create-contact-type" name="create-contact-type">
                                                    <option value="reservation">Reservation</option>
                                                    <option value="commercial">Commercial</option>
                                                    <option value="other">Other</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary create-contact-close-btn" data-dismiss="modal">Close</button>
                                            <button type="submit" className="btn btn-primary">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                }

                {this.state.show && 
                <div className="row">
                    <div className='col'>
                       <span><h4 style={{borderBottom: "1px solid #cecdcd", marginBottom:'20px'}}>Bank Details:</h4></span>
                       
                       <table class="table table-bordered">
                           <thead>
                               <tr>
                                   <th>Account Number</th>
                                   <th>Bank Name</th>
                                   <th>Branch Details</th>
                                   <th>Account Holder</th>
                                   <th>Comment</th>
                                   <th>Action</th>
                               </tr>
                           </thead>
                           <tbody>
                               {this.state.bank_details.map((bank, index) => (
                               <tr className={((bank.enabled == 'No') ? " strike":"")} key={index}>
                                   <td>{bank.account_number}</td>
                                   <td>{bank.bank_name}</td>
                                   <td>{bank.branch_details}</td>
                                   <td>{bank.holder_name}</td>
                                   <td>
                                    <textarea className="form-control" onChange={e=>this.handleChangeBankAccountComment(e, index)} id={"bank-acc-comments-"+bank.id} value={(typeof bank.comments!=='undefined') ? bank.comments : ""}></textarea>
                                    <br />
                                    <button type="button" className="btn btn-success btn-sm update-bankacc-comment" data-accid={bank.id}>Update</button>
                                   </td>
                                   <td>
                                    <button className={"btn btn-sm edit-bank-details-btn" + ((bank.enabled == 'No') ? " btn-warning":" btn-danger")} data-id={bank.id} data-supplier={bank.supplier} data-account_number={bank.account_number} data-bank_name={bank.bank_name} data-branch_details={bank.branch_details} data-holder_name={bank.holder_name} data-enabled={bank.enabled}>{((bank.enabled == 'No') ? "Activate":"Archive Bank Details")}</button>
                                   </td>
                                </tr>
                                ))}
                            </tbody>
                        </table>
                       
                       {/* {this.state.bank_details.map((bank, index) => (
                       <div className={"BankDetailsContainer"+ ((bank.enabled == 'No') ? " strike":"")} key={index}>
                           <div className="form-group row" id="commamount-container">
                                <label for="commamount" className="col-sm-3 col-form-label">Bank Account Number:</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" id="commamount" disabled="disabled" value={bank.account_number} placeholder="Bank Account Number" />
                                </div>
                            </div>
                            <div className="form-group row" id="commamount-container">
                                <label for="commamount" className="col-sm-3 col-form-label">Bank Name:</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" id="commamount" disabled="disabled" value={bank.bank_name} placeholder="Bank Name" />
                                </div>
                            </div>
                            <div className="form-group row" id="commamount-container">
                                <label for="commamount" className="col-sm-3 col-form-label">Branch Details:</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" id="commamount" disabled="disabled" value={bank.branch_details} placeholder="Branch Details" />
                                </div>
                            </div>
                            <div className="form-group row" id="commamount-container">
                                <label for="commamount" className="col-sm-3 col-form-label">Account Holder Name:</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" id="commamount" disabled="disabled" value={bank.holder_name} placeholder="Account Holder Name" />
                                </div>
                            </div>

                            <div className="form-group row" id="commamount-container" style={{alignSelf: 'end'}}>
                                <button className="btn btn-warning btn-sm edit-bank-details-btn" data-id={bank.id} data-supplier={bank.supplier} data-account_number={bank.account_number} data-bank_name={bank.bank_name} data-branch_details={bank.branch_details} data-holder_name={bank.holder_name} data-enabled={bank.enabled}>Edit</button>
                            </div>
                       </div>
                       ))} */}
                       <button className="btn btn-sm btn-primary add-bank-details-btn" data-toggle="modal" data-target=".create-bank-modal" style={{gridColumnEnd: '-1', justifySelf: 'end', float: "right"}}>Add New Bank Details</button>

                       <div className="modal fade create-bank-modal" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-md">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Create/Edit Bank Details</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <form className="create-bank-modal-form">
                                        <div className="modal-body">
                                            <h4>Enter the details below:</h4>
                                            <input type="hidden" name="create-bank-comapny" id="create-bank-comapny" value={this.state.companyname.value} />
                                            <input type="hidden" name="create-bank-supplier" id="create-bank-supplier" />
                                            <input type="hidden" name="create-bank-id" id="create-bank-id" />

                                            <div className="form-group" id="create-bank-account-number-container">
                                                <label for="create-bank-account-number" className="col-form-label"><b>Bank Account Number:</b></label>
                                                <input type="text" className="form-control" name="create-bank-account-number" id="create-bank-account-number" required="required" placeholder="Bank Account Number" />
                                            </div>
                                            <div className="form-group" id="create-bank-name-container">
                                                <label for="create-bank-name" className="col-form-label"><b>Bank Name:</b></label>
                                                <input type="text" className="form-control" name="create-bank-name" id="create-bank-name" required="required" placeholder="Bank Name" />
                                            </div>
                                            <div className="form-group" id="create-bank-branch-container">
                                                <label for="create-bank-branch" className="col-form-label"><b>Branch Details:</b></label>
                                                <input type="text" className="form-control" name="create-bank-branch" id="create-bank-branch" required="required" placeholder="Branch Details" />
                                            </div>
                                            <div className="form-group" id="create-bank-holder-container">
                                                <label for="create-bank-holder" className="col-form-label"><b>Account Holder Name:</b></label>
                                                <input type="text" className="form-control" name="create-bank-holder" id="create-bank-holder" required="required" placeholder="Account Holder Name" />
                                            </div>
                                            <div className="form-group" id="create-bank-enabled-container">
                                                <label for="create-bank-enabled" className="col-form-label"><b>Is this Account Active? (Select No to Archive)</b></label>
                                                <select className="form-control form-control-select" id="create-bank-enabled" name="create-bank-enabled">
                                                    <option vlaue="Yes">Yes</option>
                                                    <option vlaue="No">No</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary create-bank-close-btn" data-dismiss="modal">Close</button>
                                            <button type="submit" className="btn btn-primary">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                }

                {this.state.show && 
                <div className="row" style={{marginBottom: '30px'}}>
                    <div className='col'>
                       <span><h4 style={{borderBottom: "1px solid #cecdcd", marginBottom:'20px'}}>Supplier Contracts:</h4><button className="btn btn-sm btn-success upload-contract-modal-btn" data-toggle="modal" data-target=".upload-contract-modal" style={{float: 'right'}}>Upload Contract</button></span>
                       {this.state.contracts.map((contract, index) => (
                       <table class="table table-bordered" key={index}>
                           <thead>
                               <tr>
                                   <th>Upload Date</th>
                                   <th>File Name</th>
                                   <th>Signing Date</th>
                                   <th>Status</th>
                                   <th style={{backgroundColor:'#dad1d1'}}>Valid From</th>
                                   <th style={{backgroundColor:'#dad1d1'}}>Expiration Date</th>
                                   <th style={{backgroundColor:'#dad1d1'}}>Indefinite Validity</th>
                                   <th style={{backgroundColor:'#e1bc8d'}}>Payment Terms</th>
                                   <th>Commission Details</th>
                                   <th>Comment</th>
                                   <th>Agreement</th>
                               </tr>
                           </thead>
                           <tbody>
                               <tr className={((contract.status == 'Archive' || contract.status == 'Expired') ? " strike":"")}>
                                   <td>{contract.upload_date}</td>
                                   <td>
                                       <ol>
                                       {contract.file_name.split(' || ').map((file, index2) => (
                                            <li key={index2}>{file}</li>
                                       ))}
                                       </ol>
                                    </td>
                                   <td>{contract.signing_date}</td>
                                   <td>{contract.status}</td>
                                   <td style={{backgroundColor:'#dad1d1'}}>{contract.valid_from}</td>
                                   <td style={{backgroundColor:'#dad1d1'}}>{contract.expiration_date}</td>
                                   <td style={{backgroundColor:'#dad1d1'}}>{contract.indefinite_validity}</td>
                                   <td style={{backgroundColor:'#e1bc8d'}}>{contract.payment_terms}{(contract.payment_terms_days!='') ? " / "+contract.payment_terms_days+" days":""}</td>
                                   <td>{contract.comm_comment}</td>
                                   <td>{contract.comment}</td>
                                   <td>
                                       <ol>
                                       {contract.agreement.split(' || ').map((file, index2) => (
                                           
                                            <li key={index2}><a href={`https://deals.mu/scripts/reportsapi/supplir_agreements/${file}`} target="_blank">Agreement</a></li>
                                           
                                       ))}
                                       </ol>
                                       <br />
                                       <button className="btn btn-sm btn-warning edit-contract-btn" data-id={contract.id} data-supplier={contract.supplier} data-file_name={contract.file_name} data-file_external_link={contract.file_external_link} data-signing_date={contract.signing_date} data-status={contract.status} data-valid_from={contract.valid_from} data-expiration_date={contract.expiration_date} data-indefinite_validity={contract.indefinite_validity} data-payment_terms={contract.payment_terms} data-payment_terms_days={contract.payment_terms_days} data-comment={contract.comment} data-comm-comment={contract.comm_comment} data-agreement={contract.agreement} data-saved_file={contract.saved_file}>Edit</button> 
                                       <br /><br />
                                       <button className="btn btn-sm btn-danger archive-contract-btn" data-id={contract.id}>Archive</button> 
                                    </td>
                               </tr>
                               
                           </tbody>
                       </table>
                       ))}

                        <div className="modal fade upload-contract-modal" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Create/Edit Contract</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <form className="upload-contract-modal-form" enctype="multipart/form-data">
                                        <div className="modal-body">
                                            <h4>Enter the details below:</h4>
                                            <input type="hidden" name="create-contract-comapny" id="create-contract-comapny" value={this.state.companyname.value} />
                                            <input type="hidden" name="create-contract-supplier" id="create-contract-supplier" />

                                            <input type="hidden" name="create-contract-id" id="create-contract-id" />

                                            <div className="form-group">
                                                <label for="upload-contract-file" className="col-form-label"><b>Upload Contract File:</b><i style={{color: "rgb(223, 21, 59)"}}> (Press CTRL to select multiple files Or drag select with mouse)</i></label>
                                                <input type="file" className="form-control form-control-file" name="upload-contract-file" id="upload-contract-file" placeholder="Upload Contract File" multiple="multiple" />
                                                <input type="hidden" name="create-contract-old-file" id="create-contract-old-file" />
                                            </div>

                                            <div className="form-group">
                                                <label for="upload-contract-file-link" className="col-form-label"><b>Link to Contract:</b></label>
                                                <input type="text" className="form-control" name="upload-contract-file-link" id="upload-contract-file-link" placeholder="Link to Contract" />
                                            </div>

                                            <div className="form-group">
                                                <label for="upload-contract-validity" className="col-form-label"><b>Agreement Validitity:</b></label>
                                                <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridColumnGap: '20px'}}>
                                                    <input type="text" className="form-control date-picker" name="upload-contract-valid-from" id="upload-contract-valid-from" required="required" placeholder="Valid From" />
                                                    <input type="text" className="form-control date-picker" name="upload-contract-valid-to" id="upload-contract-valid-to" placeholder="Expiration Date" />
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" style={{marginTop: '15px'}} name="upload-contract-valid-infinely" id="upload-contract-valid-infinely" />
                                                        <label className="form-check-label" style={{marginLeft: '10px'}} for="upload-contract-valid-infinely">Indefinite Period</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label for="upload-contract-signing-date" className="col-form-label"><b>Signing Date:</b></label>
                                                <input type="text" className="form-control date-picker" name="upload-contract-signing-date" id="upload-contract-signing-date" required="required" placeholder="Signing Date" />
                                            </div>
                                            <div className="form-group">
                                                <label for="upload-contract-payment-term" className="col-form-label"><b>Payment Term:</b></label>
                                                <select className="form-control form-control-select" id="upload-contract-payment-term" name="upload-contract-payment-term" style={{marginBottom: '20px'}}>
                                                    <option value="Pre-payment">Pre-payment</option>
                                                    <option value="Credit Facilities">Credit Facilities</option>
                                                </select>
                                                <input type="number" className="form-control" name="upload-contract-payment-term-days" id="upload-contract-payment-term-days" placeholder="No. of Days" />
                                            </div>

                                            <div className="form-group">
                                                <label for="upload-contract-comm-details" className="col-form-label"><b>Commission Details:</b></label>
                                                <textarea class="form-control" name="upload-contract-comm-details" id="upload-contract-comm-details" rows="5"></textarea>
                                            </div>

                                            <div className="form-group">
                                                <label for="upload-contract-comment" className="col-form-label"><b>Comment:</b></label>
                                                <textarea class="form-control" name="upload-contract-comment" id="upload-contract-comment" rows="5"></textarea>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary upload-contract-close-btn" data-dismiss="modal">Close</button>
                                            <button type="submit" className="btn btn-primary">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                }

                {this.state.show && 
                <div className="row" style={{marginBottom: '30px'}}>
                    <div className='col'>
                       <span><h4 style={{borderBottom: "1px solid #cecdcd", marginBottom:'20px'}}>Supplier Comments and Notes:</h4><button className="btn btn-sm btn-success create-comment-modal-btn" data-toggle="modal" data-target=".create-comment-modal" style={{float: 'right'}}>Add Comment</button></span>
                       <table class="table table-bordered">
                           <thead>
                               <tr>
                                   <th>Created</th>
                                   <th>Comment</th>
                                   <th>Type</th>
                                   <th>Related To</th>
                                   <th>Related Period</th>
                                   <th>Related Link</th>
                                   <th>Edit</th>
                                   <th>Action</th>
                               </tr>
                           </thead>
                           <tbody>
                               {this.state.related_comments.map((comment, index) => (
                               <tr key={index}>
                                   <td>{comment.created_on}</td>
                                   <td>{comment.comment}</td>
                                   <td>{comment.comment_type}</td>
                                   <td><b>Invoice:</b> {comment.related_to_invoice}<br/><b>Order:</b> {comment.related_to_order}</td>
                                   <td>{comment.comment_period}</td>
                                   <td>{comment.file_link}</td>
                                   <td><button data-id={comment.id} data-comment={comment.comment} data-related_to_invoice={comment.related_to_invoice} data-related_to_order={comment.related_to_order} data-comment_type={comment.comment_type} data-comment_period={comment.comment_period} data-supplier={comment.supplier} data-file_link={comment.file_link} className="comment-edit-btn btn btn-sm btn-primary">Edit</button></td>
                                   <td>
                                    
                                    {comment.status != 'Resolved' ? 
                                        <div id={`resolved-btn-container-${comment.id}`}><button data-id={comment.id} className="mark-resolved-btn btn btn-sm btn-success">Mark as Resolved</button></div> 
                                        : comment.status    
                                    }
                                    </td>
                               </tr>
                               ))}
                           </tbody>
                       </table>

                        <div className="modal fade create-comment-modal" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-md">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Add/Edit Supplier Comments and Notes</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <form className="create-comment-modal-form">
                                        <div className="modal-body">
                                            <h4>Enter the details below:</h4>
                                            <input type="hidden" name="create-comment-id" id="create-comment-id" value="" />
                                            <input type="hidden" name="create-comment-comapny" id="create-comment-comapny" value={this.state.companyname.value} />
                                            <input type="hidden" name="create-comment-supplier" id="create-comment-supplier" />

                                            <div className="form-group">
                                                <div class="form-group">
                                                  <label for="create-comment-comment">Comment</label>
                                                  <textarea class="form-control" name="create-comment-comment" id="create-comment-comment" rows="5"></textarea>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label for="create-comment-type" className="col-form-label"><b>Comment type:</b></label>
                                                <select className="form-control form-control-select" id="create-comment-type" name="create-comment-type">
                                                    <option value="General comments">General comments</option>
                                                    <option value="Related to Invoice">Related to Invoice</option>
                                                    <option value="Related to Order">Related to Order</option>
                                                    <option value="Commercial">Commercial</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label for="create-comment-order-number" className="col-form-label"><b>Enter Order Number:</b></label>
                                                <input type="text" className="form-control" name="create-comment-order-number" id="create-comment-order-number" placeholder="Enter Order Number" />
                                            </div>
                                            <div className="form-group">
                                                <label for="create-comment-invoice-number" className="col-form-label"><b>Enter Invoice Number:</b></label>
                                                <input type="text" className="form-control" name="create-comment-invoice-number" id="create-comment-invoice-number" placeholder="Enter Invoice Number" />
                                            </div>
                                            <div className="form-group">
                                                <label for="create-comment-relevant-period" className="col-form-label"><b>Relevant for this Period (month/year):</b></label>
                                                <input type="text" className="form-control" name="create-comment-relevant-period" id="create-comment-relevant-period" placeholder="Relevant for this period (month/year)" />
                                            </div>
                                            <div className="form-group">
                                                <label for="create-comment-file-link" className="col-form-label"><b>Any File Link (optional):</b></label>
                                                <textarea type="text" className="form-control" name="create-comment-file-link" id="create-comment-file-link" placeholder="Any file link (Optional)"></textarea>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary create-comment-close-btn" data-dismiss="modal">Close</button>
                                            <button type="submit" className="btn btn-primary">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                }

                {this.state.show && 
                <div className="row" style={{marginBottom: '30px'}}>
                    <div className='col'>
                       <span><h4 style={{borderBottom: "1px solid #cecdcd", marginBottom:'20px'}}>List of Products:</h4></span>
                       <table class="table table-bordered">
                           <thead>
                               <tr>
                                   <th>Product Name</th>
                                   <th>Link to Website</th>
                               </tr>
                           </thead>
                           <tbody>
                               {this.state.related_products.map((product, index) => (
                               <tr key={index}>
                                   <td>{product.label}</td>
                                   <td><a href={product.link} target="_blank">View</a></td>
                               </tr>
                               ))}
                           </tbody>
                       </table>
                    </div>
                </div>
                }

                {this.state.show && 
                <div className="row" style={{marginBottom: '30px'}}>
                    <div className='col'>
                       <span><h4 style={{borderBottom: "1px solid #cecdcd", marginBottom:'20px'}}>Commission Details:</h4><button style={{float: 'right'}} className="btn btn-warning btn-sm add_commission">Add Commission</button></span>
                       <table class="table table-bordered">
                           <thead>
                               <tr>
                                   <th>#</th>
                                   <th>Date Created</th>
                                   <th>Commission Type</th>
                                   <th>Commission Rate (%)</th>
                                   <th>Price List</th>
                                   <th>Valid From</th>
                                   <th>Valid Until</th>
                                   <th>Commission Source</th>
                                   <th>Applicable for</th>
                                   <th>Any File</th>
                                   <th>Actions</th>
                               </tr>
                           </thead>
                           <tbody>
                               {this.state.related_commissions.map((commission, index) => (
                               <tr key={index}>
                                   <td>{commission.id}</td>
                                   <td>{commission.created}</td>
                                   <td>{commission.type}</td>
                                   <td>{commission.commission}</td>
                                   <td>
                                    {commission.price_list_file!='' && 
                                        <>
                                        <a className="btn btn-primary btn-sm" href={`https://deals.mu/scripts/reportsapi/commission-price-lists/${commission.price_list_file}`} target="_blank">Download File</a>
                                        <br />
                                        </>
                                    } 
                                   <br /> 
                                    {commission.price_list_external && 
                                        <a className="btn btn-warning btn-sm" href={`${commission.price_list_external}`} target="_blank">View File</a>
                                    }
                                   </td>
                                   <td>{commission.from}</td>
                                   <td>{commission.to}</td>
                                   <td>
                                       <textarea id={`commission-source-${commission.id}`} name={`commission-source-${commission.id}`}>{commission.source}</textarea><br /><button data-id={commission.id} className="btn btn-primary btn-sm update-comm-source-btn">Update</button>
                                       
                                    </td>
                                   <td>{commission.product_label}</td>
                                   <td>{commission.file_link}</td>
                                   <td>
                                       {/* <button className="btn btn-sm btn-warning edit-comm-btn" data-id={commission.id}>Edit</button>
                                       <br /><br /> */}
                                       <button className="btn btn-sm btn-danger del-comm-btn" data-id={commission.id}>Delete</button>
                                   </td>
                               </tr>
                               ))}
                           </tbody>
                       </table>

                       
                                <div className="modal fade add-commission-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                                    <div className="modal-dialog modal-lg">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title">Add New Commission</h5>
                                                <button type="button" onClick={this.resetCommission} className="close commission-modal-close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div className='row'>
                                                    <div className='col-12 formSwitcher'>
                                                        <div data-btn-comm-type="commission" className="formSwitcherButtons formSwitcherButtonsSelected">% Commission</div>
                                                        <div data-btn-comm-type="pricelist" className="formSwitcherButtons">Price List</div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <div className="alert alert-danger comm-error" style={{ display: 'none' }} role="alert">
                                                            Fill all mandatory fields marked as <b style={{ color: 'red' }}>*</b>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='row' style={{marginBottom: '20px'}}>
                                                    <div className='col-12'>
                                                        <b>Commission Type = <span id="addcomm-type-text">% Commission</span></b>
                                                    </div>
                                                </div>
                                                
                                                <div className="form-group row">
                                                    <label for="company" className="col-sm-3 col-form-label">Company<b style={{ color: 'red' }}>*</b></label>
                                                    <div className="col-sm-9">
                                                        <input type='text' disabled="disabled" id='commcompanynamehidden' value={this.state.companyname.value} />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label for="supplier" className="col-sm-3 col-form-label">Supplier<b style={{ color: 'red' }}>*</b></label>
                                                    <div className="col-sm-9">
                                                        <input type='text' disabled="disabled" id='commsuppliernamehidden' value={this.state.suppliername.value } />
                                                        {/* <Select
                                                            value={this.state.suppliername}
                                                            onChange={this.handleChangeCommSuplier}
                                                            options={this.state.suppliers}
                                                        /> */}
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label for="supplier" className="col-sm-3 col-form-label">Products<b style={{ color: 'red' }}>*</b></label>
                                                    <div className="col-sm-9">
                                                        <input type='hidden' id='productnamehidden' value={this.state.productnamehidden } />
                                                        <input type='hidden' id='productlabelhidden' value={this.state.productnamehidden } />
                                                        <Select
                                                            value={this.state.productname}
                                                            onChange={this.handleChangeCommProducts}
                                                            options={this.state.invoiceproducts}
                                                            isMulti={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row" style={{display: "none"}}>
                                                    <label for="commtype" className="col-sm-3 col-form-label">Commission Type<b style={{ color: 'red' }}>*</b></label>
                                                    <div className="col-sm-9">
                                                        <select id="commtype" className='form-control'>
                                                            <option value='Commission' selected="selected">Commission</option>
                                                            <option value='Price List'>Price List</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label for="commfrom" className="col-sm-3 col-form-label">Commission Period<b style={{ color: 'red' }}>*</b></label>
                                                    <div className="col-sm-9" style={{ display:'flex' }}>
                                                        <input type="text" className="form-control date-picker" id="commfrom" placeholder="From Date" />
                                                        <input type="text" className="form-control date-picker" id="commto" placeholder="To Date" />
                                                    </div>
                                                </div>
                                                <div className="form-group row" id="add-comm-pricelist-upload" style={{display: "none"}}>
                                                    <label for="commfile" className="col-sm-3 col-form-label">Price List File Upload</label>
                                                    <div className="col-sm-9">
                                                        <input type='hidden' id="commfileuploaded" />
                                                        <input style={{marginBottom: "10px"}} type="file" className="form-control form-control-file" id="commfile" placeholder="Price list file" />
                                                        <input type="text" className="form-control form-control" id="commfilelink" placeholder="Price list file External Link" />
                                                    </div>
                                                </div>
                                                <div className="form-group row" id="commamount-container">
                                                    <label for="commamount" className="col-sm-3 col-form-label">Commission(%)</label>
                                                    <div className="col-sm-9">
                                                        <input type="number" className="form-control" id="commamount" placeholder="Commission Percentage" />
                                                    </div>
                                                </div>

                                                <div className="form-group row create-comission-invoice-prices" style={{display: "none"}}>
                                                    <label for="supplier" className="col-sm-3 col-form-label">Invoice Prices</label>
                                                    <div className="col-sm-9">
                                                        <input type='hidden' id='commsupplierpricetypehidden' value={this.state.invoiceprices.value} />
                                                        <Select
                                                            value={this.state.invoiceprices}
                                                            onChange={this.handleChangeCommPricesType}
                                                            options={this.state.invoicespricesoptions}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label for="commsource" className="col-sm-3 col-form-label">Commission Source</label>
                                                    <div className="col-sm-9">
                                                        {/* <input type="text" className="form-control" id="commsource" placeholder="Commission Source" /> */}

                                                        <select className="form-control form-control-select" id="commsource">
                                                            <option>Contract</option>
                                                            <option>Email</option>
                                                            <option>Others</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label for="commfilelinkComm" className="col-sm-3 col-form-label">Any File Link</label>
                                                    <div className="col-sm-9">
                                                        <textarea id="commfilelinkComm" className="form-control"></textarea>
                                                    </div>
                                                </div>
                                               
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-danger" data-dismiss="modal">Cancel</button>
                                                <button type="button" className="btn btn-success comm-save-btn">Add Commission</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                    </div>
                </div>
                }

                {this.state.show && 
                <div className="row" style={{marginBottom: '30px'}}>
                    <div className='col'>
                       <span><h4 style={{borderBottom: "1px solid #cecdcd", marginBottom:'20px'}}>List of Invoices:</h4></span>
                       <table class="table table-bordered">
                           <thead>
                               <tr>
                                   <th>Invoice #</th>
                                   <th>Supplier</th>
                                   <th>Invoice Date</th>
                                   <th>Booking period</th>
                                   <th>Invoice Status</th>
                                   <th>Price Type</th>
                                   <th>Total Selling Amount</th>
                                   <th>Total Undercharges</th>
                                   <th>Payable Amount</th>
                                   <th style={{backgroundColor:'#f6d9a3'}}>Amount Paid</th>
                                   <th style={{backgroundColor:'#f6d9a3'}}>Invoice Amount</th>
                                   <th>Total Overcharges</th>
                                   <th>Invoice Comments</th>
                                   <th>Actions</th>
                               </tr>
                           </thead>
                           <tbody>
                               {this.state.related_invoices.invoices.map((invoice, index) => (
                               <tr key={index}>
                                   <td>{invoice.invoice_number}</td>
                                   <td>{invoice.supplier}</td>
                                   <td>{invoice.invoice_date}</td>
                                   <td>{invoice.booking_period_from} {'<-->'} {invoice.booking_period_to}</td>
                                   <td>{parse(invoice.status)}</td>
                                   <td>{invoice.invoices_price_type}</td>
                                   <td>Rs. {this.numberWithCommas(invoice.total_selling_amount)}</td>
                                   <td>Rs. {this.numberWithCommas(invoice.undercharge)}</td>
                                   <td>Rs. {this.numberWithCommas(invoice.payable_amount)}</td>
                                   <td style={{backgroundColor:'#f6d9a3'}}>Rs. {this.numberWithCommas(invoice.amount_paid)}</td>
                                   <td style={{backgroundColor:'#f6d9a3'}}>Rs. {this.numberWithCommas(invoice.invoice_amount)}</td>
                                   <td>Rs. {invoice.overcharge}</td>
                                   <td><textarea id={`invoice-comment-${invoice.id}`}>{invoice.comments}</textarea><br /> <button className="btn btn-primary btn-sm invoice-comment-update-btn" data-id={invoice.id}>Update</button></td>
                                   <td>{parse(invoice.actions)}</td>
                               </tr>
                               ))}
                           </tbody>
                       </table>

                       <div className="modal fade invoice-payment-submission-modal" tabindex="-1" data-backdrop="static" data-keyboard="false" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Invoice Payment Submission</h5>
                                        <button type="button" className="close invoice-submiddion-cancel-btn" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className="alert alert-danger error-msg" style={{ display: 'none' }} role="alert">
                                                    Fill all mandatory fields marked as <b style={{ color: 'red' }}>*</b>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label for="payinvoicenumber" className="col-sm-3 col-form-label">Invoice Number</label>
                                            <div className="col-sm-9">
                                                <input type="text" className="form-control" name='payinvoicenumber' id="payinvoicenumber" placeholder="Invoice Number" />
                                                <input type="hidden" className="form-control" name='payinvoiceid' id="payinvoiceid" placeholder="Invoice Number" />
                                                <input type="hidden" className="form-control" name='payinvoicestatus' id="payinvoicestatus" />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label for="payinvoiceamountpaid" className="col-sm-3 col-form-label">Amount Paid<b style={{ color:'red' }}>*</b></label>
                                            <div className="col-sm-9">
                                                <input type="number" className="form-control" name='payinvoiceamountpaid' id="payinvoiceamountpaid" placeholder="Amount Paid" />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label for="supplier" className="col-sm-3 col-form-label">Mode of Payment<b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-9">
                                                <select name="payinvoicemodeofpayment" id="payinvoicemodeofpayment" className="form-select select-drops form-control" style={{maxWidth: '100%'}}>
                                                    <option value='0'>Select Payment Mode</option>
                                                    <option value="Bulk Bank Transfer">Bulk Bank Transfer</option>
                                                    <option value="Manual Bank Transfer">Manual Bank Transfer</option>
                                                    <option value="Cash">Cash</option>
                                                    <option value="Credit Card">Credit Card</option>
                                                    <option value="Credit with Supplier">Credit with Supplier</option>
                                                    <option value="Others">Others</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label for="payinvoicedateofpayment" className="col-sm-3 col-form-label">Date of Payment<b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-9">
                                                <input type="text" className="form-control date-picker" name='payinvoicedateofpayment' id="payinvoicedateofpayment" placeholder="Date of Payment" />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label for="payinvoicepaymentref" className="col-sm-3 col-form-label">Payment Referrence</label>
                                            <div className="col-sm-9">
                                                <input type="text" className="form-control" name='payinvoicepaymentref' id="payinvoicepaymentref" placeholder="Payment Referrence" />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label for="payinvoicecomments" className="col-sm-3 col-form-label">Comments</label>
                                            <div className="col-sm-9">
                                                <textarea className="form-control" id="payinvoicecomments" rows="3"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-danger invoice-submiddion-cancel-btn" data-dismiss="modal">Cancel</button>
                                        <button type="button" className="btn btn-success invoice-submiddion-btn" >Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Edit Invoice  */}
                        <div className="modal fade edit-invoice-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Edit Invoice</h5>
                                        <button type="button" className="close-edit-invoice" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className="alert alert-danger invoice-update-error" style={{display: 'none'}} role="alert">
                                                    Fill all mandatory fields marked as <b style={{ color: 'red' }}>*</b>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label for="company" className="col-sm-3 col-form-label">Company<b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-9">
                                                <input type='text' className='form-control' id='edit-invoice-company' disabled="disabled" />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label for="edit-invoice-supplier" className="col-sm-3 col-form-label">Supplier</label>
                                            <div className="col-sm-9">
                                                <select id="edit-invoice-supplier" className='form-control' multiple="multiple">
                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="edit-invoice-type" className="col-sm-3 col-form-label">Invoice Type</label>
                                            <div className="col-sm-9">
                                                <select id="edit-invoice-type" className='form-control'>
                                                    <option value="E-Invoice">E-Invoice</option>
                                                    <option value="Physical Invoice">Physical Invoice</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <input type='hidden' id='edit-invoice-id' />
                                            <label for="editinvoicedate" className="col-sm-3 col-form-label">Invoice Date<b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-9">
                                            <input type='text' className='form-control date-picker' id='edit-invoice-date' />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <input type='hidden' id='edit-invoice-id' />
                                            <label for="editinvoicdueedate" className="col-sm-3 col-form-label">Invoice Due Date<b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-9">
                                            <input type='text' className='form-control date-picker' id='edit-invoice-due-date' />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label for="editinvoicenumber" className="col-sm-3 col-form-label">Invoice Number<b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-9">
                                                <input type="text" className="form-control" name='invoicenumber' id="edit-invoicenumber" placeholder="Invoice Number" />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label for="bookingperiod" className="col-sm-3 col-form-label">Booking Period<b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-9" style={{ display: 'flex' }}>
                                                <input type='text' className='form-control date-picker col-sm-4' id='edit-invoice-from-date' />
                                                <span className='col-sm-1' ></span>
                                                <input type='text' className='form-control date-picker col-sm-4' id='edit-invoice-to-date' />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label for="invoicefile" className="col-sm-3 col-form-label">Invoice File Upload</label>
                                            <div className="col-sm-9">
                                                <input type="hidden" className="form-control" id="edit-invoice-file-hidden" placeholder="Invoice file" />
                                                <input type="file" className="form-control" id="edit-invoice-file" placeholder="Invoice file" />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label for="edit-invoice-file-external" className="col-sm-3 col-form-label">Link to E-Invoice</label>
                                            <div className="col-sm-9">
                                                <input type="text" className="form-control" id="edit-invoice-file-external" placeholder="Link to E-Invoice" />
                                            </div>
                                        </div>

                                        <div className="form-group row" style={{display: "none"}}>
                                            <label for="edit-invoice-pricetype" className="col-sm-3 col-form-label">Invoice Prices<b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-9">
                                                <select id="edit-invoice-pricetype" className='form-control'>
                                                    <option value="NET Rates">NET Rates</option>
                                                    <option value="Public Rates (commisionable)">Public Rates (commisionable)</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="invoiceamount" className="col-sm-3 col-form-label">Invoice Amount<b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-9">
                                            <input type="number" className="form-control" id='edit-invoice-amount' placeholder="Invoice Amount" />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-danger" data-dismiss="modal">Cancel</button>
                                        <button type="button" className="btn btn-success invoice-edit-submit-btn">Update Invoice</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* //clear modal */}
                        <div className="modal" id="clearInvoiceModal">
                            <div className="modal-dialog">
                                <div className="modal-content">

                                    <div className="modal-header">
                                        <h4 className="modal-title">Clear Invoice</h4>
                                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                                    </div>
                                    <div className="modal-body">
                                        <input type="hidden" className="clear-modal-invoice-number" value="0" />
                                        <h4 style={{ textAlign: 'center', fontSize: '20px', marginBottom: '25px', color:'red' }}>Please select an option below (With Caution!)</h4>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                            <div>    
                                                <button className='btn btn-warning btn-modal-clear-invoice'>Clear Invoice</button>
                                                <p>This will clear the data for this invoice but will keep the items.</p>
                                            </div>
                                            <div>
                                                <select className='form-control form-select modal-toggle-rates-select'>
                                                    <option value='0'>Select Type</option>
                                                    <option value='NET Rates'>NET Rates</option>
                                                    <option value='Public Rates (commisionable)'>Public Rates (commisionable)</option>
                                                </select>
                                                <button className='btn btn-warning btn-modal-clear-toggle-rate-type' style={{ marginTop : '20px' }}>Update Commission Rate Types</button>
                                                <p>This will change the prices type between Net and Public Rates (It will not update the values, You will have to go to invoice report and update them manually and reallocate to the invoice).</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-danger clear-modal-close" data-dismiss="modal">Close</button>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                }

                {this.state.show && 
                <div className="row" style={{marginBottom: '30px'}}>
                    <div className='col'>
                       <span><h4 style={{borderBottom: "1px solid #cecdcd", marginBottom:'20px'}}>Payment Records & Balance:</h4></span>
                       <table class="table table-bordered">
                           <thead>
                               <tr>
                                   <th>Invoice #</th>
                                   <th>Suppler</th>
                                   <th>Invoice Date</th>
                                   <th style={{backgroundColor:'#f2bc78'}}>Amount Paid</th>
                                   <th style={{backgroundColor:'#fbff70'}}>Invoice Amount</th>
                                   <th style={{backgroundColor:'#f2bc78'}}>Invoice Net Amount</th>
                                   <th>Difference</th>
                                   <th>Total Overcharges</th>
                                   <th>Comments</th>
                                   <th>Accumulate Debt Balance</th>
                                   <th>Mode of Payment</th>
                                   <th>Date of payment</th>
                                   <th>Payment Refrence</th>
                               </tr>
                           </thead>
                           <tbody>
                               {this.state.related_invoices.invoices.map((invoice, index) => (
                               <tr key={index}>
                                   <td>{invoice.invoice_number}</td>
                                   <td>{invoice.supplier}</td>
                                   <td>{invoice.invoice_date}</td>
                                   <td style={{backgroundColor:'#f2bc78'}}>Rs. {this.numberWithCommas(invoice.amount_paid)}</td>
                                   <td style={{backgroundColor:'#fbff70'}}>Rs. {this.numberWithCommas(invoice.invoice_amount)}</td>
                                   <td style={{backgroundColor:'#f2bc78'}}>Rs. {this.numberWithCommas(invoice.net_amount)}</td>
                                   <td>Rs. {this.numberWithCommas(invoice.difference)}</td>
                                   <td>Rs. {this.numberWithCommas(invoice.overcharge)}</td>
                                   <td><textarea id={`invoice-second-comment-${invoice.id}`}>{invoice.comments_new}</textarea><br /><button className="btn btn-sm btn-primary invoice-second-comment-update-btn" data-id={invoice.id}>Update</button></td>
                                   <td>Rs. {invoice.balance}</td>
                                   <td>{invoice.mode_of_payment}</td>
                                   <td>{invoice.payment_date}</td>
                                   <td>{invoice.payment_referrence}</td>
                               </tr>
                               ))}
                               <tr>
                                   <td><b>Totals:</b></td>
                                   <td></td>
                                   <td></td>
                                   <td style={{backgroundColor:'#f2bc78'}}><b>Rs. {this.numberWithCommas(this.state.related_invoices.totals.amount_paid)}</b></td>
                                   <td style={{backgroundColor:'#fbff70'}}><b>Rs. {this.numberWithCommas(this.state.related_invoices.totals.invoice_amount)}</b></td>
                                   <td style={{backgroundColor:'#f2bc78'}}><b>Rs. {this.numberWithCommas(this.state.related_invoices.totals.invoice_net_amount)}</b></td>
                                   <td><b>Rs. {this.numberWithCommas(this.state.related_invoices.totals.difference)}</b></td>
                                   <td><b>Rs. {this.numberWithCommas(this.state.related_invoices.totals.overcharges)}</b></td>
                                   <td></td>
                                   <td><b>Rs. {this.numberWithCommas(this.state.related_invoices.totals.accumbalance)}</b></td>
                                   <td></td>
                                   <td></td>
                                   <td></td>
                               </tr>
                           </tbody>
                       </table>
                    </div>
                </div>
                }

                {this.state.show && 
                <div className="row">
                    <div className='col'>
                        {error.status == true &&
                            <div className="alert alert-danger" role="alert">
                                {error.message}
                            </div>
                        }
                        {/* {(show == true) &&
                            <DTable data={data} columns={columnsheadings} />
                        } */}
                        {this.state.loader &&
                            <Loader className='loader-center'
                                type="Grid"
                                color="#00BFFF"
                                height={100}
                                width={100}
                            />
                        }
                    </div>
                </div>
                }
            </>
        );
    }
}