import axios from "axios";
import chalk from 'chalk';

const API_URL = "https://deals.mu/scripts/reportsapi/getpaymentreports.php";

class PaymentReportsService {
    getReport(startdate, enddate, product, supplier, company, status, paymentstatus, invoicefilter, allocate) {
        var params = {
            action: 'report',
            page: 0,
            startdate: startdate,
            enddate: enddate,
            productname: product,
            suppliername: JSON.stringify(supplier),
            companyname: company,
            orderstatus: status,
            paymentstatus: paymentstatus,
            invoicefilter: invoicefilter,
            allocate: allocate
        };
        return axios
            .get(API_URL, { params })
            .then(async (response) => {

                var result = {
                    data : []
                };
                let promises = [];
                var size = response.data;
                // console.log({size});
                var loopSize = Math.ceil(parseFloat(size/15));
                // console.log({loopSize});

                for (var i=1; i<=loopSize; i++) {
                    // console.log({i});
                    promises.push(
                        await this.getResults(startdate, enddate, product, supplier, company, status, paymentstatus, invoicefilter, allocate, i)
                        .then(response1 => {
                            // console.log('response: '+i, response1);
                            result.data = result.data.concat(response1.data);
                        })
                        .catch(error1 => {
                            console.log({error1});
                        })
                    );
                }

                return Promise.all(promises).then(() => {
                    // console.log({result});
                    return result;
                });

                // console.log(response);
                // return response;
                // return result;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

    getResults(startdate, enddate, product, supplier, company, status, paymentstatus, invoicefilter, allocate, page) {
        var params = {
            action: 'report',
            page: page,
            startdate: startdate,
            enddate: enddate,
            productname: product,
            suppliername: JSON.stringify(supplier),
            companyname: company,
            orderstatus: status,
            paymentstatus: paymentstatus,
            invoicefilter: invoicefilter,
            allocate: allocate
        };
        return axios
            .get(API_URL, { params })
            .then((response) => {
                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

    getAllProducts() {
        return axios
            .get(API_URL+'?action=products')
            .then((response) => {

                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

    getAllSuppliers() {
        return axios
            .get(API_URL + '?action=suppliers')
            .then((response) => {

                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

    getAllSuppliersPerProduct(prodID) {
        var params = {
            action: 'get_product_supplier_reverse',
            prodID: prodID
        };
        return axios
            .get(API_URL, { params })
            .then((response) => {
                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

}

export default new PaymentReportsService();