import React, { Component } from "react";
import DatePicker from "react-datepicker";
import Loader from 'react-loader-spinner';
import DTable from './dtable.component';
import Select from 'react-select';
import axios from "axios";
import swal from 'sweetalert';

import AuthService from '../services/auth/auth.service';
import PaymentReportsService from '../services/erp/paymentreports.service';
import UtilityService from '../services/utility.service';
import InvoicesService from '../services/erp/invoices.service';

import "react-datepicker/dist/react-datepicker.css";

import UserContext from "../contexts/UserContext";
import NoPermission from "./extras/nopermission.component";
export default class Invoices extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            columnsheadings: [],
            options: {
                filterType: 'dropdown',
            },
            show: false,
            username: null,
            products: [{ ID: 0, post_title: 'Select a Product' }],
            suppliers: [{ value: 0, label: 'Loading..' }],
            companies: [{ value: 0, label: 'Loading..' }],
            startdate: '',
            suppliername: { value: 0, label: 'Select a Supplier' },
            enddate: '',
            productname: 0,
            companyname: { value: 0, label: 'Select a Company' },
            orderstatus: 0,
            loadingvalues: true,
            error: {
                status: false,
                message: ''
            },
            search_btn_text: 'Search',
            error_msg: false,
            loader: false,
            invoicecompanyname: { value: 0, label: 'Select a Company' },
            invoicesuppliername: null,
            invoicesuppliers: { value: 0, label: 'Select a Supplier' },
            invoicedate: '',
            invoiceduedate: '',
            invoicenumber: '',
            invoicefromdate: '',
            invoicetodate: '',
            invoicefile: '',
            invoiceexternal:'',
            externalfilelinktosheet: '',
            invoiceamount: 0,
            invoiceprices: { value: 'NET Rates', label: 'NET Rates' },
            invoicespricesoptions: [{ value: 'NET Rates', label: 'NET Rates' }, { value: "Public Rates (commisionable)", label: "Public Rates (commisionable)" }],
            all_invoices: [{ value: 0, label: 'Select Invoice' }],
            selected_bulk_invoice: { value: 0, label: 'Select Invoice' },
            selectedFile: '',
            filterInvoice: 0,
            minDate: new Date("02-01-2019"),
            minDateInvoice: new Date("02-01-2019"),
            invoiceType: { value: 'E-Invoice', label: 'E-Invoice' },
            invoiceTypeAll: [{ value: 'E-Invoice', label: 'E-Invoice' }, { value: "Physical Invoice", label: "Physical Invoice" }],
            user: [],
            allowed: true,
            relatedCredits: [],
            disableDuedate: false,
            isItSoa: false
        };
 
        // this.handleChange = this.handleChange.bind(this);
    }

    reset = () => {
        this.setState({
            startdate: '',
            suppliername: { value: 0, label: 'Select a Supplier' },
            enddate: '',
            productname: 0,
            companyname: { value: 0, label: 'Select a Company' },
            orderstatus: 0,
            error: {
                status: false,
                message: ''
            },
            search_btn_text: 'Search',
            error_msg: false,
        });
    }

    resetInvoice = () => {
        this.setState({
            invoicecompanyname: this.state.companies,
            invoicesuppliername: null,
            invoicesuppliers: { value: 0, label: 'Select a Supplier' },
            invoicedate: '',
            invoiceduedate: '',
            invoicenumber: '',
            invoicefromdate: '',
            invoicetodate: '',
            invoicefile: '',
            invoiceamount: 0,
            invoiceprices: { value: 'NET Rates', label: 'NET Rates' },
            selectedFile: '',
            isItSoa: false
        });
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
        // console.log('handle change');
    }

    handleSOAChange(event) {
        this.setState({
            isItSoa: !this.state.isItSoa
        });
    }

    componentDidMount() {
        PaymentReportsService.getAllSuppliers()
            .then(response => {
                // console.log({ response });
                if (response.data.suppliers) {
                    this.setState({
                        suppliers: response.data.suppliers,
                        companies: response.data.companies,
                        products: response.data.products,
                        loadingvalues: false,
                    });
                    // console.log('i am getsupds');
                }
            })
            .catch(error => {
                console.log({ error });
            });
            
        AuthService.checklogin().then(user => {
            // console.log(user.user_display_name);
            this.setState({
                username: user.user_display_name,
                user: user
            });
           AuthService.getUserPermisions(user)
            .then(response => {
                var userpermissions = response.data;
                console.log({userpermissions});
                    if(userpermissions.includes('invoices') || userpermissions.includes('all')) {
                    console.log('You are allowed!');
                    this.setState({
                        allowed: true
                    });
                    this.loadReport(true);
                } else {
                    console.log('You are not allowed!');
                    this.setState({
                        allowed: false
                    });
                }
            })
            
        }).catch(error => {
            // console.log({ error });
            window.location.href = '/login';
        });
    }

    loadReport = (all) => {

        this.setState({
            show: false,
            error: {
                status: false,
                message: ''
            },
            search_btn_text: 'Fetching..'
        });

        var startday = '';
        var endday = '';
        if (this.state.startdate != '' && this.state.enddate!='') {

            startday = UtilityService.format_date(this.state.startdate);
            endday = UtilityService.format_date(this.state.enddate);
        }
        InvoicesService.loadReport(startday, endday, this.state.companyname.value, this.state.suppliername.value, this.state.orderstatus, all, this.state.user.user_email)
            .then(response => {
                console.log({ response });
                if (typeof response.data == 'undefined') {
                    this.setState({
                        error: {
                            status: true,
                            message: 'No data found for the selection, Please retry or change your selections.'
                        },
                        search_btn_text: 'Search'
                    });
                } else if (response.data.length > 0) {
                    this.setState({
                        data: response.data,
                        columnsheadings: [
                            { title: "Invoice #" },
                            { title: "Company Name" },
                            { title: "Supplier" },
                            { title: "Invoice Date" },
                            { title: "Due Date" },
                            { title: "Booking Period" },
                            { title: "Invoice Amount" },
                            { title: "Total Selling Amount" },
                            { title: "Commission Amount" },
                            { title: "Payable Amount    " },
                            { title: "Amount Paid" },
                            { title: "Difference" },
                            { title: "Invoice Status" },
                            { title: "Date of Payment" },
                            { title: "Comments" },
                            { title: "Created By" },
                            { title: "Last Updated" },
                            { title: "Actions" },
                        ],
                        options: {
                            filterType: 'dropdown',
                        },
                        show: true,
                        search_btn_text: 'Search'
                    });
                    // console.log(response.data);
                } else {
                    this.setState({
                        error: {
                            status: true,
                            message: 'No data found for the selection, Please retry or change your selections.'
                        },
                        search_btn_text: 'Search',
                        show: true,
                    });
                }
            });

    }

    searchClick(e) {
        e.preventDefault();
        this.loadReport(false);
        // console.log('The link was clicked.');
    }

    supChange = (selectedOption) => {
        this.setState({ suppliername: selectedOption });
        // console.log(`Option selected:`, selectedOption);
    }

    setStartDate = (date) => {
        this.setState({
            startdate: date,
            minDate: date
        });
        if (this.state.enddate != '' && this.state.enddate < date) {
            this.setState({
                enddate: date
            });
        }
        // console.log(this.state);
    }

    setEndDate = (date) => {

        this.setState({
            enddate: date
        });
        // console.log(this.state);
    }
    handleChangeProduct = selectedOption => {
        this.setState({ productname: selectedOption });
        // console.log(`Option selected:`, selectedOption);
    };

    handleChangeSuplier = selectedOption => {
        this.setState({ suppliername: selectedOption });
        // console.log(`Option selected:`, selectedOption);
    };

    handleChangeInvoiceType = selectedOption => {
        this.setState({ invoiceType: selectedOption });

        // if (this.state.suppliername.length > 1 && this.state.suppliername[0].value==0) {
        //     this.setState({ 
        //         suppliername: [{ value: 0, label: 'Select a Supplier' }] 
        //     }); 
        // }
        console.log({ selectedOption});
    };

    handleChangCompany = selectedOption => {
        this.setState({ companyname: selectedOption });

        InvoicesService.fetchSuppliers(selectedOption.value)
            .then(response => {
                console.log(response);
                this.setState({
                    suppliers: response.data
                });
            })
            .catch(error => {
                console.log(error);
            })
        // console.log(`Option selected:`, selectedOption);
    };

    handleChangeInvoiceSuplier = selectedOption => {

        this.setState({ invoicesuppliername: selectedOption });
    };

    invoicePriceTypeChange = selectedOption => {
        this.setState({ invoiceprices: selectedOption });
    };

    handleChangInvoiceCompany = selectedOption => {
        this.setState({ invoicecompanyname: selectedOption });
        InvoicesService.fetchSuppliers(selectedOption.value)
            .then(response => {
                console.log(response);
                this.setState({
                    invoicesuppliers: response.data
                });
            })
            .catch(error => {
                console.log(error);
            })
    };

    handleInvoiceFileChange = (e) => {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);

        axios.post('https://deals.mu/scripts/reportsapi/invoicesfileupload.php', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                if (response.data.file != '') {
                    this.setState({
                        selectedFile: response.data.file
                    });
                } else {
                    this.setState({
                        selectedFile: ''
                    });
                }
                // console.log(response);
            }).catch(error => {
                console.log(error);
            });

        console.log(e.target.files[0]);

    };

    showinvoices = (e) => {
        this.searchClick(null);
    }

    setInvoiceDate = (date) => {

        InvoicesService.fetchCreditFacilities(this.state.invoicecompanyname.value)
        .then(response => {
            console.log({response});
            if(response.data.length<1) {
                this.setState({
                    disableDuedate: false
                })
            } 
            this.setState({
                relatedCredits: response.data
            });
        })

        this.setState({
            invoicedate: date
        });
    }

    setInvoiceDueDate = (date) => {
        console.log({date});
        this.setState({
            invoiceduedate: date
        });
    }

    setInvoiceFromDate = (date) => {
        this.setState({
            invoicefromdate: date,
            minDateInvoice: date
        });
        if (this.state.invoicetodate != '' && this.state.invoicetodate < date) {
            this.setState({
                invoicetodate: date
            });
        }
    }

    setInvoiceToDate = (date) => {

        this.setState({
            invoicetodate: date
        });
    }

    handleChangeBulkInvoice = selectedOption => {
        this.setState({ selected_bulk_invoice: selectedOption });
    };

    createInvoice = () => {
        console.log('clicked');
        this.setState({
            error_msg: false
        });

        if (this.state.invoicecompanyname.value == 0 || this.state.invoicesuppliername == null || this.state.invoicedate == '' || this.state.invoicenumber == '' || this.state.invoicefromdate == '' || this.state.invoicetodate == '' || this.state.invoiceamount == '' || this.state.invoiceamount == 0 || this.state.invoiceduedate == '') {
            this.setState({
                error_msg: true
            });
        } else {
            InvoicesService.checkInvoiceNumber(this.state.invoicenumber)
            .then(response => {
                console.log({response});
                if(response.data=='exists') {
                    swal('Invoice Number Exists!', 'The invoice number exists already please use another one', 'error');
                } else {
                    InvoicesService.createInvoice(this.state.invoicecompanyname.value, this.state.invoicesuppliername, UtilityService.format_date(this.state.invoicedate), this.state.invoicenumber, UtilityService.format_date(this.state.invoicefromdate), UtilityService.format_date(this.state.invoicetodate), this.state.selectedFile, this.state.invoiceamount, this.state.invoiceprices.value, this.state.invoiceexternal, this.state.invoiceType.value, UtilityService.format_date(this.state.invoiceduedate), this.state.username, this.state.isItSoa, this.state.externalfilelinktosheet )
                    .then(response => {
                        console.log(response);
                        document.querySelector('.close').click();
                        this.resetInvoice();
                        document.querySelector('.booking-search-btn').click();
                    })
                    .catch(error => {
                        console.log(error);
                    });
                }
            })
            .catch(error => {
                console.log({error});
            });
            
        }
    }

    deleteInvoice = (id) => {
        InvoicesService.deleteInvoice(id)
        .then(response => {
            console.log({response});
        })
        .catch(error => {
            console.log({error});
        });
    }

    setAutoDueDate = (e, days, type) => {
        console.log("setAutoDate");
        var value  = e.target.checked;
        // console.log({value});
        console.log({days});
        console.log(this.state.invoicedate);

        if(value==true) {
            if(this.state.invoicedate!='') {

                var invoiceDueDate = new Date();
                if(type.includes('Pre-')) {
                    invoiceDueDate.setDate(this.state.invoicedate.getDate());
                } else {
                    invoiceDueDate.setTime(this.state.invoicedate.getTime() + (parseInt(days)* 24 * 60 * 60 * 1000));
                }

                console.log({invoiceDueDate});

                this.setState({
                    invoiceduedate: invoiceDueDate
                });
            } else {
                this.setState({
                    invoiceduedate: ''
                });
            }
        } else {
            this.setState({
                invoiceduedate: ''
            });
        }
    }

    render() {
        var { data, columnsheadings, options, show, products, suppliers, companies, loadingvalues, error, search_btn_text, invoicesuppliers } = this.state;

        if (this.state.allowed == false) {
          return <NoPermission />;
        }
        
        return (
            <>
                <div className="row">
                    <div className='col'>
                        <h2>Supplier Invoices</h2>
                        <hr />
                    </div>
                </div>
                <div className="row">
                    <div className='col'>
                        {loadingvalues == true &&
                            <p style={{ color: "#df153b" }}><b><i>Loading Companies/Suppliers. Please Wait....</i></b></p>
                        }
                        <form action="#" className="booking-reports-search-form" style={{ display: 'flex' }}>
                            <input type="hidden" className='form-control' name="page" value="deals-payment-report" />
                            <div>
                                <DatePicker
                                    dateFormat="dd-MM-yyyy"
                                    selected={this.state.startdate}
                                    onChange={date => this.setStartDate(date)}
                                    placeholderText="From Date"
                                    className='form-control'
                                />
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                <DatePicker
                                    dateFormat="dd-MM-yyyy"
                                    selected={this.state.enddate}
                                    onChange={date => this.setEndDate(date)}
                                    placeholderText="To Date"
                                    className='form-control'
                                    minDate={this.state.minDate}
                                />
                            </div>
                            
                            <div style={{ marginLeft: '10px', width: '300px' }}>
                                <Select
                                    value={this.state.companyname}
                                    onChange={this.handleChangCompany}
                                    options={companies}
                                />
                            </div>
                            <div style={{ marginLeft: '10px', width: '300px' }}>
                                <Select
                                    value={this.state.suppliername}
                                    onChange={this.handleChangeSuplier}
                                    options={suppliers}
                                />
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                <select name="orderstatus" className="select-drops form-control" value={this.state.orderstatus} onChange={this.handleChange.bind(this)}>
                                    <option value="0">All Status</option>
                                    <option value="Pending">Pending</option>
                                    <option value="Processed">Processed</option>
                                    <option value="Approved">Approved</option>
                                    <option value="Paid">Paid</option>
                                    <option value="Partially Paid">Partially Paid</option>
                                    <option value="Rejected">Rejected</option>
                                </select>
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                <button className="btn btn-success btn-large booking-search-btn invoice-search-btn" onClick={this.searchClick.bind(this)}>{search_btn_text}</button>
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                <a href="/invoices" className="btn btn-warning btn-large booking-reset-btn" onClick={this.reset}>Reset</a>
                            </div>
                            <div style={{ marginLeft: '20px' }}>
                                <button id="new-invoice-btn" className="btn btn-success" data-toggle="modal" data-target=".create-invoice-modal" onClick={(e)=>{e.preventDefault();}}>Create New Invoice</button>
                                <div className="modal fade create-invoice-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                                    <div className="modal-dialog modal-lg">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title">Create New Invoice</h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                {this.state.error_msg &&
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className="alert alert-danger" role="alert">
                                                                Fill all mandatory fields marked as <b style={{ color: 'red' }}>*</b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="form-group row">
                                                    <label for="company" className="col-sm-3 col-form-label">Company<b style={{ color: 'red' }}>*</b></label>
                                                    <div className="col-sm-9">
                                                        <Select
                                                            value={this.state.invoicecompanyname}
                                                            onChange={this.handleChangInvoiceCompany}
                                                            options={companies}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label for="supplier" className="col-sm-3 col-form-label">Supplier<b style={{ color: 'red' }}>*</b></label>
                                                    <div className="col-sm-9">
                                                        <Select
                                                            value={this.state.invoicesuppliername}
                                                            onChange={this.handleChangeInvoiceSuplier}
                                                            options={invoicesuppliers}
                                                            isMulti={true}
                                                            placeholder={"Select Suppliers"}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label for="invoicedate" className="col-sm-3 col-form-label">Invoice Date<b style={{ color: 'red' }}>*</b></label>
                                                    <div className="col-sm-9">
                                                        <DatePicker
                                                            dateFormat="dd-MM-yyyy"
                                                            selected={this.state.invoicedate}
                                                            onChange={date => this.setInvoiceDate(date)}
                                                            placeholderText="Invoice Date"
                                                            className='form-control'
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label for="" className="col-sm-3 col-form-label">Related Credit Facilities</label>
                                                    <div className="col-sm-9">
                                                        {this.state.relatedCredits.length>0 && 
                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Select</th>
                                                                        <th>Supplier</th>
                                                                        <th>Credit Facility</th>
                                                                        <th>Starts From</th>
                                                                        <th>Expires On</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.relatedCredits.map((credit, index) => (
                                                                    <tr key={index}>
                                                                        <td><input type="radio" onClick={(e) => this.setAutoDueDate(e, credit.payment_terms_days, credit.payment_terms)} /></td>
                                                                        <td>{credit.supplier}</td>
                                                                        <td>{credit.payment_terms} / {credit.payment_terms_days} days</td>
                                                                        <td>{credit.valid_from}</td>
                                                                        <td>{credit.expiration_date}</td>
                                                                    </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label for="invoiceduedate" className="col-sm-4 col-form-label">Is It SOA?</label>
                                                    <div className="col-sm-8">
                                                        <input class="form-check-input" type="checkbox" checked={this.state.isItSoa} onChange={this.handleSOAChange.bind(this)}></input>
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label for="invoiceduedate" className="col-sm-3 col-form-label">Due Date<b style={{ color: 'red' }}>*</b></label>
                                                    <div className="col-sm-9">
                                                        <DatePicker
                                                            dateFormat="dd-MM-yyyy"
                                                            selected={this.state.invoiceduedate}
                                                            onChange={date => this.setInvoiceDueDate(date)}
                                                            placeholderText="Invoice Due Date"
                                                            className='form-control'
                                                            disabled = {this.state.disableDuedate}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label for="invoicenumber" className="col-sm-3 col-form-label">Invoice Number<b style={{ color: 'red' }}>*</b></label>
                                                    <div className="col-sm-9">
                                                        <input type="text" className="form-control" value={this.state.invoicenumber} name='invoicenumber' onChange={this.handleChange.bind(this)} id="invoicenumber" placeholder="Invoice Number" />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label for="bookingperiod" className="col-sm-3 col-form-label">Booking Period<b style={{ color: 'red' }}>*</b></label>
                                                    <div className="col-sm-9">
                                                        <DatePicker
                                                            dateFormat="dd-MM-yyyy"
                                                            selected={this.state.invoicefromdate}
                                                            onChange={date => this.setInvoiceFromDate(date)}
                                                            placeholderText="From Date"
                                                            className='form-control'
                                                        />
                                                        <DatePicker
                                                            dateFormat="dd-MM-yyyy"
                                                            selected={this.state.invoicetodate}
                                                            onChange={date => this.setInvoiceToDate(date)}
                                                            placeholderText="To Date"
                                                            className='form-control'
                                                            minDate={this.state.minDateInvoice}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label for="supplier" className="col-sm-3 col-form-label">Invoice Type</label>
                                                    <div className="col-sm-9">
                                                        <Select
                                                            value={this.state.invoiceType}
                                                            onChange={this.handleChangeInvoiceType}
                                                            options={this.state.invoiceTypeAll}
                                                            placeholder={"Select Invoice Type"}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group row" style={{display: "none"}}>
                                                    <label for="invoicefile" className="col-sm-3 col-form-label">Invoice File Upload</label>
                                                    <div className="col-sm-9">
                                                        <input type="file" className="form-control" value={this.state.invoicefile} name='invoicefile' onChange={this.handleInvoiceFileChange} id="invoicefile" placeholder="Invoice file" />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label for="external_link_working_sheet" className="col-sm-3 col-form-label">Link to Working Sheet</label>
                                                    <div className="col-sm-9">
                                                        <textarea className="form-control" value={this.state.externalfilelinktosheet} name='externalfilelinktosheet' onChange={this.handleChange.bind(this)} id="externalfilelinktosheet" placeholder="Link to Working Sheet"></textarea>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label for="external_link" className="col-sm-3 col-form-label">Link to E-Invoice</label>
                                                    <div className="col-sm-9">
                                                        <input type="text" className="form-control" value={this.state.invoiceexternal} name='invoiceexternal' onChange={this.handleChange.bind(this)} id="invoiceexternal" placeholder="Invoice file URL" />
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <label for="invoiceprices" className="col-sm-3 col-form-label">Invoice Prices<b style={{ color: 'red' }}>*</b></label>
                                                    <div className="col-sm-9">
                                                        <Select
                                                            value={this.state.invoiceprices}
                                                            onChange={this.invoicePriceTypeChange}
                                                            options={this.state.invoicespricesoptions}
                                                        />
                                                    </div>
                                                </div>
                                                
                                                <div className="form-group row">
                                                    <label for="invoiceamount" className="col-sm-3 col-form-label">Invoice Amount<b style={{ color: 'red' }}>*</b></label>
                                                    <div className="col-sm-9">
                                                        <input type="number" className="form-control" value={this.state.invoiceamount} name='invoiceamount' onChange={this.handleChange.bind(this)} id="invoiceamount" placeholder="Invoice Amount" />
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-danger" data-dismiss="modal">Cancel</button>
                                                <button type="button" className="btn btn-success invoice-save-btn" onClick={this.createInvoice}>Create Invoice</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </form>

                        <hr />
                    </div>
                </div>
                <div className="row">
                    <div className='col'>
                        {/* <div className="container-fluid"> */}
                            {error.status == true &&
                                <div className="alert alert-danger" role="alert">
                                    {error.message}
                                </div>
                            }
                            {(show == true) &&
                                <DTable data={data} columns={columnsheadings} fixedHeader={true} />
                            }
                            {show == false &&
                                <Loader className='loader-center'
                                    type="Grid"
                                    color="#00BFFF"
                                    height={100}
                                    width={100}
                                />
                            }
                        {/* </div> */}

                        <div className="modal fade invoice-payment-submission-modal" tabindex="-1" data-backdrop="static" data-keyboard="false" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Invoice Payment Submission</h5>
                                        <button type="button" className="close invoice-submiddion-cancel-btn" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className="alert alert-danger error-msg" style={{ display: 'none' }} role="alert">
                                                    Fill all mandatory fields marked as <b style={{ color: 'red' }}>*</b>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label for="payinvoicenumber" className="col-sm-3 col-form-label">Invoice Number</label>
                                            <div className="col-sm-9">
                                                <input type="text" className="form-control" name='payinvoicenumber' id="payinvoicenumber" placeholder="Invoice Number" />
                                                <input type="hidden" className="form-control" name='payinvoiceid' id="payinvoiceid" placeholder="Invoice Number" />
                                                <input type="hidden" className="form-control" name='payinvoicestatus' id="payinvoicestatus" />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label for="payinvoiceamountpaid" className="col-sm-3 col-form-label">Amount Paid<b style={{ color:'red' }}>*</b></label>
                                            <div className="col-sm-9">
                                                <input type="number" className="form-control" name='payinvoiceamountpaid' id="payinvoiceamountpaid" placeholder="Amount Paid" />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label for="supplier" className="col-sm-3 col-form-label">Mode of Payment<b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-9">
                                                <select name="payinvoicemodeofpayment" id="payinvoicemodeofpayment" className="form-select select-drops form-control" style={{maxWidth: '100%'}}>
                                                    <option value='0'>Select Payment Mode</option>
                                                    <option value="Bulk Bank Transfer">Bulk Bank Transfer</option>
                                                    <option value="Manual Bank Transfer">Manual Bank Transfer</option>
                                                    <option value="Cash">Cash</option>
                                                    <option value="Credit Card">Credit Card</option>
                                                    <option value="Credit with Supplier">Credit with Supplier</option>
                                                    <option value="Others">Others</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label for="payinvoicedateofpayment" className="col-sm-3 col-form-label">Date of Payment<b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-9">
                                                <input type="text" className="form-control date-picker" name='payinvoicedateofpayment' id="payinvoicedateofpayment" placeholder="Date of Payment" />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label for="payinvoicepaymentref" className="col-sm-3 col-form-label">Payment Referrence</label>
                                            <div className="col-sm-9">
                                                <input type="text" className="form-control" name='payinvoicepaymentref' id="payinvoicepaymentref" placeholder="Payment Referrence" />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label for="payinvoicecomments" className="col-sm-3 col-form-label">Comments</label>
                                            <div className="col-sm-9">
                                                <textarea className="form-control" id="payinvoicecomments" rows="3"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-danger invoice-submiddion-cancel-btn" data-dismiss="modal">Cancel</button>
                                        <button type="button" className="btn btn-success invoice-submiddion-btn" >Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Edit Invoice  */}
                        <div className="modal fade edit-invoice-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Edit Invoice</h5>
                                        <button type="button" className="close-edit-invoice" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className="alert alert-danger invoice-update-error" style={{display: 'none'}} role="alert">
                                                    Fill all mandatory fields marked as <b style={{ color: 'red' }}>*</b>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label for="company" className="col-sm-3 col-form-label">Company<b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-9">
                                                <input type='text' className='form-control' id='edit-invoice-company' disabled="disabled" />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label for="edit-invoice-supplier" className="col-sm-3 col-form-label">Supplier</label>
                                            <div className="col-sm-9">
                                                <select id="edit-invoice-supplier" className='form-control' multiple="multiple">
                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="edit-invoice-soa" className="col-sm-3 col-form-label">Is it SOA?</label>
                                            <div className="col-sm-9">
                                                <input type='checkbox' className='form-check-input' id='edit-invoice-soa' />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="edit-invoice-type" className="col-sm-3 col-form-label">Invoice Type</label>
                                            <div className="col-sm-9">
                                                <select id="edit-invoice-type" className='form-control'>
                                                    <option value="E-Invoice">E-Invoice</option>
                                                    <option value="Physical Invoice">Physical Invoice</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <input type='hidden' id='edit-invoice-id' />
                                            <label for="editinvoicedate" className="col-sm-3 col-form-label">Invoice Date<b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-9">
                                            <input type='text' className='form-control date-picker' id='edit-invoice-date' />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <input type='hidden' id='edit-invoice-id' />
                                            <label for="editinvoicdueedate" className="col-sm-3 col-form-label">Invoice Due Date<b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-9">
                                            <input type='text' className='form-control date-picker' id='edit-invoice-due-date' />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="editinvoicenumber" className="col-sm-3 col-form-label">Invoice Number<b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-9">
                                                <input type="text" className="form-control" name='invoicenumber' id="edit-invoicenumber" placeholder="Invoice Number" />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label for="bookingperiod" className="col-sm-3 col-form-label">Booking Period<b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-9" style={{ display: 'flex' }}>
                                                <input type='text' className='form-control date-picker col-sm-4' id='edit-invoice-from-date' />
                                                <span className='col-sm-1' ></span>
                                                <input type='text' className='form-control date-picker col-sm-4' id='edit-invoice-to-date' />
                                            </div>
                                        </div>
                                        <div className="form-group row" style={{display: "none"}}>
                                            <label for="invoicefile" className="col-sm-3 col-form-label">Invoice File Upload</label>
                                            <div className="col-sm-9">
                                                <input type="hidden" className="form-control" id="edit-invoice-file-hidden" placeholder="Invoice file" />
                                                <input type="file" className="form-control" id="edit-invoice-file" placeholder="Invoice file" />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="edit-externalfilelinktosheet" className="col-sm-3 col-form-label">Link to Working Sheet</label>
                                            <div className="col-sm-9">
                                                <textarea className="form-control" id="edit-externalfilelinktosheet" placeholder="Link to Working Sheet"></textarea>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="edit-invoice-file-external" className="col-sm-3 col-form-label">Link to E-Invoice</label>
                                            <div className="col-sm-9">
                                                <input type="text" className="form-control" id="edit-invoice-file-external" placeholder="Link to E-Invoice" />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="edit-invoice-pricetype" className="col-sm-3 col-form-label">Invoice Prices<b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-9">
                                                <select id="edit-invoice-pricetype" className='form-control'>
                                                    <option value="NET Rates">NET Rates</option>
                                                    <option value="Public Rates (commisionable)">Public Rates (commisionable)</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="invoiceamount" className="col-sm-3 col-form-label">Invoice Amount<b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-9">
                                            <input type="number" className="form-control" id='edit-invoice-amount' placeholder="Invoice Amount" />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-danger" data-dismiss="modal">Cancel</button>
                                        <button type="button" className="btn btn-success invoice-edit-submit-btn">Update Invoice</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* //clear modal */}
                        <div className="modal" id="clearInvoiceModal">
                            <div className="modal-dialog">
                                <div className="modal-content">

                                    <div className="modal-header">
                                        <h4 className="modal-title">Clear Invoice</h4>
                                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                                    </div>
                                    <div className="modal-body">
                                        <input type="hidden" className="clear-modal-invoice-number" value="0" />
                                        <h4 style={{ textAlign: 'center', fontSize: '20px', marginBottom: '25px', color:'red' }}>Please select an option below (With Caution!)</h4>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                            <div>    
                                                <button className='btn btn-warning btn-modal-clear-invoice'>Clear Invoice</button>
                                                <p>This will clear the data for this invoice but will keep the items.</p>
                                            </div>
                                            <div>
                                                <select className='form-control form-select modal-toggle-rates-select'>
                                                    <option value='0'>Select Type</option>
                                                    <option value='NET Rates'>NET Rates</option>
                                                    <option value='Public Rates (commisionable)'>Public Rates (commisionable)</option>
                                                </select>
                                                <button className='btn btn-warning btn-modal-clear-toggle-rate-type' style={{ marginTop : '20px' }}>Update Commission Rate Types</button>
                                                <p>This will change the prices type between Net and Public Rates (It will not update the values, You will have to go to invoice report and update them manually and reallocate to the invoice).</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-danger clear-modal-close" data-dismiss="modal">Close</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}