import React, { Component } from "react";
import Loader from 'react-loader-spinner';
import DTable from './dtable.component';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import swal from 'sweetalert';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import AuthService from '../services/auth/auth.service';
import NewRefundReportsService from '../services/erp/refunds-new.service';

import UserContext from "../contexts/UserContext";
import NoPermission from "./extras/nopermission.component";
import Pagination from "./pagination.component";
import AddManualRefund from "./refunds/addManualRefund.component";
import RefundsBulkUpdate from "./refunds/refundsBulkUpdate.component";
export default class Refunds extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            columnsheadings: [],
            options: {
                filterType: 'dropdown',
            },
            show: false,
            error: {
                status: false,
                message: ''
            },
            loadingvalues: true,
            user: [],
            allowed: true,
            totalPages: 0,
            currentPage: 0,
            limit: 20,
            filterStatus: '0',
            filterUrgency: '',
            filterType: '',
            filterSearch: '',
            exportBtnText : "Export to Excel",
            exportBtnDisabled : false,
            toupdaterefundid: 0,
            toupdaterefundbank: '',
            toupdaterefundaccountname: '',
            toupdaterefundaccountnum: '',
            isOpenBankDetailsModal: false,
            selectedRefundRows: [],
            isOpenCommentsModal: false,
            refundComments: [],
            refundCurrentComment: "",
            addCommentBtnText: "Add Comment",
            addCommentBtnDisabled: false,
            refundCommentID: 0,
        };
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    componentDidMount() {
        AuthService.checklogin().then(user => {

            this.setState({
                user: user
            });
            console.log({user});

            AuthService.getUserPermisions(user)
            .then(response => {
                var userpermissions = response.data;
                // console.log({userpermissions});
                    if(userpermissions.includes('refunds') || userpermissions.includes('all')) {
                    // console.log('You are allowed!');
                    this.setState({
                        allowed: true
                    });
                    // this.loadRefunds(true);
                    this.loadRefunds1(0);
                } else {
                    // console.log('You are not allowed!');
                    this.setState({
                        allowed: false
                    });
                }
            })
        }).catch(error => {
            window.location.href = '/login';
        });
    }

    loadRefunds = (all) => {
        this.setState({
            data: [],
            show: false,
            error: {
                status: false,
                message: ''
            },
            loadingvalues: true,
        });

        NewRefundReportsService.getRefunds('Refund', '', '', '', '', '', 0, this.state.user.user_email)
        .then(response => {
            // console.log({response});
            if(response.data) {

                this.setState({
                    data: response.data,
                    columnsheadings: [
                        { title: "Date Created" },
                        { title: "Customer Name" },
                        { title: "Order Details" },
                        { title: "Product Name" },
                        { title: "Payment Method & Reference" },
                        { title: "Booking Details" },
                        { title: "Total Selling Amount" },
                        { title: "Fees / Discounts" },
                        { title: "Refund Amount" },
                        { title: "Refund Reason" },
                        { title: "Explanation & Comments" },
                        { title: "Bank Details" },
                        { title: "Reservation Agent Name" },
                        { title: "Refund Status" },
                        { title: "Date Processed" },
                        { title: "Refund Details" }
                    ],
                    show: true,
                    error: {
                        status: false,
                        message: ''
                    },
                    loadingvalues: false, 
                });
            } else {
                this.setState({
                    data: [],
                    columnsheadings: [],
                    show: false,
                    error: {
                        status: true,
                        message: 'No data found'
                    },
                    loadingvalues: false,
                });
            }
        })
        .catch(error => {
            this.setState({
                data: [],
                columnsheadings: [],
                show: false,
                error: {
                    status: true,
                    message: 'No data found'
                },
                loadingvalues: false,
            });
        });
    }

    loadRefunds1 = (page) => {
            this.setState({
                currentPage: page,
                data: [],
                columnsheadings: [],
                show: false,
                loadingvalues: true,
            });

            NewRefundReportsService.getResults(page, 'Refund', '', '', '0', '0', '0', this.state.filterStatus, this.state.user.user_email, this.state.filterType, this.state.filterUrgency, this.state.filterSearch)
            .then(response => {
                // console.log("loadRefunds1", response);
                if(page==0) {
                    console.log("Total Pages", Math.ceil(parseInt(response.data) / this.state.limit));
                    this.setState({
                        totalPages: parseInt(response.data),
                        // currentPage: 1,
                    });
                    this.loadRefunds1(1);
                } else {
                    this.setState({
                        data: response.data,
                        show: true,
                        error: {
                            status: false,
                            message: ''
                        },
                        loadingvalues: false, 
                    });
                }
            }).catch(error => {
                this.setState({
                    data: [],
                    columnsheadings: [],
                    show: false,
                    error: {
                        status: true,
                        message: 'No data found'
                    },
                    loadingvalues: false,
                });
            });
    }

    pageChanged = (page) => {
        this.setState({
            currentPage: page,
        });

        this.loadRefunds1(page);
    }

    exportExcel = () => {
        this.setState({
            loadingvalues: true,
            exportBtnDisabled : true,
            exportBtnText : "Exporting..."
        });

        // if(this.state.selectedRefundRows.length>0) {
        //     var excelDataSelected = [];
        //     var selectedRefundRows = this.state.selectedRefundRows;

        //     for(var i=0; i<this.state.data.length; i++) {
        //         var item = this.state.data[i];
        //         if(selectedRefundRows.includes(item[30])) {
        //             var row = {};
        //             row["Date Created"] = item[18];
        //             row["Type"] = item[17];
        //             row["Customer Name"] = item[1];
        //             row["Order Details"] = item[19];
        //             row["Product Name"] = item[3];
        //             row["Payment Method"] = item[26];
        //             // row["Booking Details"] = item[5];
        //             row["Total Selling Amount"] = item[6];
        //             row["Fees / Discounts"] = item[20];
        //             row["Refund Amount"] = item[8];
        //             row["Refund Reason"] = item[9];
        //             row["Explanation & Comments"] = item[21];
        //             row["Bank"] = item[27];
        //             row["Account Name"] = item[28];
        //             row["Account Num"] = item[29];
        //             row["Reservation Agent Name"] = item[12];
        //             row["Refund Status"] = item[23];
        //             row["Date Processed"] = item[24];
        //             row["Refund Details"] = item[25];

        //             excelDataSelected.push(row);
        //         }
        //     }
        //     console.log({excelDataSelected});

        //     if(excelDataSelected.length>0) {
        //         const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        //         const fileExtension = '.xlsx';
        //         const ws = XLSX.utils.json_to_sheet(excelDataSelected);
        //         const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        //         const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        //         const data = new Blob([excelBuffer], {type: fileType});
        //         FileSaver.saveAs(data, "refund-export-selected-"+Math.random() + fileExtension);
        //     } else {
        //         // swal("No Data", "No data found for selected filters", "error");
        //     }
        //     this.setState({
        //         loadingvalues: false,
        //         exportBtnDisabled : false,
        //         exportBtnText : "Export to Excel"
        //     });

        // } else {
            // console.log("selected rows", this.state.selectedRefundRows);
            NewRefundReportsService.getRefunds('Refund', '', '', '', '', '', 0, this.state.user.user_email, this.state.filterType, this.state.filterUrgency, this.state.filterSearch, this.state.selectedRefundRows)
            .then(response => {
                // console.log("exportExcel", response);
                if(response.data) {
                    var columnsheadings = [
                        "Date Created",
                        "Customer Name",
                        "Order Details",
                        "Product Name",
                        "Payment Method & Reference",
                        "Booking Details",
                        "Total Selling Amount",
                        "Fees / Discounts",
                        "Refund Amount",
                        "Refund Reason",
                        "Explanation & Comments",
                        "Bank Details",
                        "Reservation Agent Name",
                        "Refund Status",
                        "Date Processed",
                        "Refund Details"
                    ];
    
                    var excelData = [];
                    // excelData.push(columnsheadings);
                    for(var i=0; i<response.data.length; i++) {
                        var item = response.data[i];
                        var row = {};
                        row["Date Created"] = item[18];
                        row["Type"] = item[17];
                        row["Customer Name"] = item[1];
                        row["Order Details"] = item[19];
                        row["Product Name"] = item[3];
                        row["Payment Method"] = item[26];
                        // row["Booking Details"] = item[5];
                        row["Total Selling Amount"] = item[6];
                        row["Fees / Discounts"] = item[20];
                        row["Refund Amount"] = item[8];
                        row["Refund Reason"] = item[9];
                        row["Explanation & Comments"] = item[21];
                        row["Bank"] = item[27];
                        row["Account Name"] = item[28];
                        row["Account Num"] = item[29];
                        row["Reservation Agent Name"] = item[12];
                        row["Refund Status"] = item[23];
                        row["Date Processed"] = item[24];
                        row["Refund Details"] = item[25];
    
                        excelData.push(row);
                    }
                    // console.log({excelData})
    
                    if(excelData.length>0) {
                        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                        const fileExtension = '.xlsx';
                        const ws = XLSX.utils.json_to_sheet(excelData);
                        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
                        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                        const data = new Blob([excelBuffer], {type: fileType});
                        FileSaver.saveAs(data, "refund-export-"+Math.random() + fileExtension);
                    } else {
                        // swal("No Data", "No data found for selected filters", "error");
                    }
    
                    this.setState({
                        loadingvalues: false,
                        exportBtnDisabled : false,
                        exportBtnText : "Export to Excel"
                    });
                }
            })
            .catch(error => {
                console.log({error});
            });
        // }

    }

    createManualRefund = () => {
    }

    updateBankDetailsPopup = (id, bank, account_name, account_num) => {
        this.setState({
            toupdaterefundid: id,
            toupdaterefundbank: bank,
            toupdaterefundaccountname: account_name,
            toupdaterefundaccountnum: account_num,
            isOpenBankDetailsModal: true
        });
    }

    updateBankDetails = (e) => {
        e.preventDefault();
        NewRefundReportsService.updateBankDetailsN(this.state.toupdaterefundid, this.state.toupdaterefundbank, this.state.toupdaterefundaccountname, this.state.toupdaterefundaccountnum)
        .then(response => {
            // console.log({response});
                swal("Success", "Bank Details Updated", "success");
                this.setState({
                    isOpenBankDetailsModal: false,
                    toupdaterefundid: 0,
                    toupdaterefundbank: '',
                    toupdaterefundaccountname: '',
                    toupdaterefundaccountnum: '',
                });
                this.loadRefunds1(this.state.currentPage);
        }).catch(error => {
            console.log({error});
            swal("Error", error, "error");
        });
    }

    sendBankDetailsRequest = (refund_id, order_id, email) => {
        // swal are you sure

        swal({
            title: "Are you sure?",
            text: "Once sent, the customer will receive an email to input their bank details.",
            icon: "warning",
            buttons: [
                'No, I am not sure!',
                'Yes, I am sure!'
            ],
            dangerMode: true,
        })
        .then((willSend) => {
            if (willSend) {
                NewRefundReportsService.sendBankDetailsRequestN(refund_id, order_id, email)
                .then(response => {
                    // console.log({response});
                    swal("Success", "Bank Details Request Sent", "success");
                    this.loadRefunds1(this.state.currentPage);
                }).catch(error => {
                    console.log({error});
                    swal("Error", error, "error");
                });
            } else {
                swal("Bank Details Request Cancelled");
            }
        });

    }

    selectRow = (e, refund_id) => {
        // console.log({e, refund_id});
        var selectedRefundRows = this.state.selectedRefundRows;
        if(e.target.checked) {
            selectedRefundRows.push(refund_id);
        } else {
            selectedRefundRows = selectedRefundRows.filter(function(value, index, arr){ 
                return value != refund_id;
            });
        }

        this.setState({
            selectedRefundRows: selectedRefundRows
        });
    }

    selectAllRows = (e) => {
        var selectedRefundRows = [];
        if(e.target.checked) {
            for(var i=0; i<this.state.data.length; i++) {
                selectedRefundRows.push(this.state.data[i][30]);
            }
        } else {
            selectedRefundRows = [];
        }

        this.setState({
            selectedRefundRows: selectedRefundRows
        });
    }

    getComments = (refund_id) => {
    }

    viewComments = (e, refund_id) => {
        e.preventDefault();
         this.setState({
            refundComments: [],
            refundCommentID: refund_id,
        });
        NewRefundReportsService.getRefundComments(refund_id)
        .then(response => {
            this.setState({
                refundComments: response.data,
                isOpenCommentsModal: true
            });
        }).catch(error => {
            console.log({error});
        });
    }

    addComments = (e) => {
        e.preventDefault();

        this.setState({
            addCommentBtnText: "Adding Comment...",
            addCommentBtnDisabled: true
        });

        NewRefundReportsService.addRefundComment(this.state.refundCommentID, this.state.refundCurrentComment, this.state.user.user_nicename)
        .then(response => {
            swal("Success", "Comment Added", "success");
            this.setState({
                addCommentBtnText: "Add Comment",
                addCommentBtnDisabled: false,
                refundCurrentComment: "",
                refundComments: [],
            });
            this.viewComments(e, this.state.refundCommentID);
        }).catch(error => {
            console.log({error});
            swal("Error", error, "error");
            this.setState({
                addCommentBtnText: "Add Comment",
                addCommentBtnDisabled: false
            });
        });
        
    }

    render() {
        var { data, columnsheadings, options, show, error, loadingvalues } = this.state;
        const style = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            height: "auto",
            overflowY: "scroll"
        };

        if (this.state.allowed == false) {
          return <NoPermission />;
        }
        
        return (
            <>
                <div className="row">
                    <div className='col'>
                        <h2>All Refunds</h2>
                        <hr />
                    </div>
                </div>
                <div className="row">
                    <div className='col'>
                        {loadingvalues == true &&
                            <p style={{ color: "#df153b" }}><b><i>Loading refunds. Please Wait....</i></b></p>
                        }
                    </div>
                </div>
                <div className="row" style={{marginBottom: "20px"}}>
                    <div className="col" style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                        <div className="actions_left" style={{display: "flex", alignItems: "center", justifyContent:"flex-start", width:"20%"}}>
                            <button type="button" className="btn btn-primary" disabled={this.state.exportBtnDisabled} onClick={() => this.exportExcel()}>{this.state.exportBtnText}</button>
                            {/* <button type="button" className="btn btn-primary" style={{marginLeft: "10px"}} onClick={(e) => this.openBulkProcessing(e)}>Bulk Processing</button> */}
                            {this.state.data.length>0 && 
                            <div style={{marginLeft: "10px"}}>
                                <RefundsBulkUpdate allRefunds={this.state.data} refundItems={this.state.selectedRefundRows} onChange={()=>this.loadRefunds1(this.state.currentPage)} />
                            </div>
                            }
                        </div>
                        <div className="actions_right" style={{display: "flex", alignItems: "center", justifyContent:"space-between", width:"80%"}}>
                            <select className="form-control" value={this.state.filterStatus} onChange={e => this.setState({filterStatus: e.target.value})} style={{marginRight: "10px"}}>
                                <option value="0">Any Refund Status</option>
                                <option value="Pending">Pending</option>
                                <option value="Approved">Approved</option>
                                <option value="Processed">Processed</option>
                                <option value="Rejected">Rejected</option>
                            </select>
                            <select className="form-control" value={this.state.filterUrgency} onChange={e => this.setState({filterUrgency: e.target.value})} style={{marginRight: "10px"}}>
                                <option value="">Any Refund Urgency</option>
                                <option value="Low">Low</option>
                                <option value="Medium">Medium</option>
                                <option value="High">High</option>
                            </select>
                            <select className="form-control" value={this.state.filterType} onChange={e => this.setState({filterType: e.target.value})} style={{marginRight: "10px"}}>
                                <option value="">Any Refund Type</option>
                                <option value="Auto">Auto</option>
                                <option value="Manual">Manual</option>
                            </select>
                            <input type="text" className="form-control" placeholder="Search Refunds" value={this.state.filterSearch} onChange={e => this.setState({filterSearch: e.target.value})} style={{marginRight: "10px"}} />
                            <button type="button" className="btn btn-success" onClick={(e) => this.loadRefunds1(0)} style={{marginRight: "10px"}}>Search</button>
                            {/* <button type="button" className="btn btn-primary" style={{width: "1000px"}} onClick={() => this.createManualRefund()}>Create Manual Refund</button> */}
                            <AddManualRefund user={this.state.user} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className='col'>
                        {error.status == true &&
                            <div className="alert alert-danger" role="alert">
                                {error.message}
                            </div>
                        }
                        {(show == true) &&
                            // <DTable data={data} columns={columnsheadings} />
                            <>
                                <table id="refunds-table" className="table table-striped table-bordered" style={{ width: '100%' }}>
                                    <thead>
                                        <tr>
                                            <th><input type="checkbox" onClick={e => this.selectAllRows(e)} checked={this.state.selectedRefundRows.length==this.state.data.length ? "checked":""} /></th>
                                            <th>Date Created</th>
                                            <th>Urgency</th>
                                            <th>Customer Name</th>
                                            <th>Order Details</th>
                                            <th>Product Name</th>
                                            <th>Payment Method & Reference</th>
                                            <th>Booking Details</th>
                                            <th>Total Selling Amount</th>
                                            <th>Fees / Discounts</th>
                                            <th>Refund Amount</th>
                                            <th>Refund Reason</th>
                                            <th>Explanation & Comments</th>
                                            <th>Bank Details</th>
                                            <th>Reservation Agent Name</th>
                                            <th>Refund Status</th>
                                            <th>Date Processed</th>
                                            <th>Refund Details</th>
                                            <th>Add Bank Details</th>
                                            <th>Request Bank Details</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.length>0 && data.map((item, index) => (
                                            <tr key={index}>
                                                <td><input type="checkbox" value={item[30]} onClick={e => this.selectRow(e, item[30])} checked={this.state.selectedRefundRows.includes(item[30]) ? "checked":""} /></td>
                                                <td dangerouslySetInnerHTML={{__html: item[0]}}></td>
                                                <td dangerouslySetInnerHTML={{__html: item[16]}}></td>
                                                <td dangerouslySetInnerHTML={{__html: item[1]}}></td>
                                                <td dangerouslySetInnerHTML={{__html: item[2]}}></td>
                                                <td dangerouslySetInnerHTML={{__html: item[3]}}></td>
                                                <td dangerouslySetInnerHTML={{__html: item[4]}}></td>
                                                <td dangerouslySetInnerHTML={{__html: item[5]}}></td>
                                                <td dangerouslySetInnerHTML={{__html: item[6]}}></td>
                                                <td dangerouslySetInnerHTML={{__html: item[7]}}></td>
                                                <td dangerouslySetInnerHTML={{__html: item[8]}}></td>
                                                <td dangerouslySetInnerHTML={{__html: item[9]}}></td>
                                                <td dangerouslySetInnerHTML={{__html: item[10]}}></td>
                                                <td dangerouslySetInnerHTML={{__html: item[11]}}></td>
                                                <td dangerouslySetInnerHTML={{__html: item[12]}}></td>
                                                <td>
                                                    <div dangerouslySetInnerHTML={{__html: item[13]}}></div>
                                                    <br />
                                                    <button className="btn btn-sm btn-primary" onClick={e => this.viewComments(e, item[30])}>View Comments</button>

                                                </td>
                                                <td dangerouslySetInnerHTML={{__html: item[14]}}></td>
                                                <td dangerouslySetInnerHTML={{__html: item[15]}}></td>
                                                <td>
                                                    {(item[27]=="" || item[28]=="" || item[29]=="") && 
                                                    <>
                                                        <button className="btn btn-sm btn-warning" onClick={e => this.updateBankDetailsPopup(item[30], item[27], item[28], item[29])} style={{marginBottom: "10px"}}>Input Bank Details (Manual)</button>
                                                    </>
                                                    }
                                                </td>
                                                <td>
                                                    {(item[27]=="" || item[28]=="" || item[29]=="") && 
                                                    <>
                                                        <button className="btn btn-sm btn-danger" onClick={e => this.sendBankDetailsRequest(item[30], item[19], item[31])}>Send Bank Details Request</button>
                                                        {item[33]!="" && 
                                                            <p style={{marginTop: "5px"}}>Bank details request already sent <b>{item[33]}</b> times</p>
                                                        }
                                                    </>
                                                    }
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                                <Pagination total={this.state.totalPages} limit={this.state.limit} current={this.state.currentPage} onPageChange={(page) => this.pageChanged(page)} />
                                <div style={{marginBottom: "30px"}}></div>
                            </>
                        }
                        {show == false &&
                            <Loader className='loader-center'
                                type="Grid"
                                color="#00BFFF"
                                height={100}
                                width={100}
                            />
                        }
                    </div>
                </div>

                {/* Refund reject modal */}
                <div className="modal fade refund-reject-modal" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-sm">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Reject Refund (<span id="reject-modal-refund-id-label"></span>)</h5>
                                <button type="button" className="close reject-refund-close-btn" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <form className="refund-reject-modal-form">
                                <div className="modal-body">
                                    <h4>Enter the details below:</h4>
                                    <input type="hidden" id="reject-modal-refund-id" />
                                    <div className="form-group">
                                        <label for="reject-form-agent-name" className="col-form-label"><b>Agent Name:</b></label>
                                        <input type="text" className="form-control" id="reject-form-agent-name" required="required" placeholder="Agent Name" />
                                    </div>
                                    <div className="form-group">
                                        <label for="reject-form-reason" className="col-form-label"><b>Reason why the refund was rejected:</b></label>
                                        <textarea className="form-control" id="reject-form-reason" required="required" placeholder="Enter the reason of rejection"></textarea>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary reject-refund-close-btn" data-dismiss="modal">Close</button>
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {/* Refund processed modal */}
                <div className="modal fade refund-processed-modal" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-sm">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Process Refund (<span id="processed-modal-refund-id-label"></span>)</h5>
                                <button type="button" className="close processed-refund-close-btn" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <form className="refund-processed-modal-form">
                                <div className="modal-body">
                                    <h4>Enter the details below:</h4>
                                    <input type="hidden" id="processed-modal-refund-id" />
                                    <div className="form-group">
                                        <label for="processed-form-agent-name" className="col-form-label"><b>Agent Name:</b></label>
                                        <input type="text" className="form-control" id="processed-form-agent-name" required="required" placeholder="Agent Name" />
                                    </div>
                                    <div className="form-group">
                                        <label for="processed-form-refund-method" className="col-form-label"><b>Refund Method:</b></label>
                                        <input type="text" className="form-control" id="processed-form-refund-method" required="required" placeholder="Refund Method" />
                                    </div>
                                    <div className="form-group">
                                        <label for="processed-form-refund-date" className="col-form-label"><b>Refund Date:</b></label>
                                        <input type="text" className="form-control date-picker" id="processed-form-refund-date" required="required" placeholder="Refund Date" />
                                    </div>
                                    <div className="form-group">
                                        <label for="processed-form-refund-ref" className="col-form-label"><b>Refund Reference:</b></label>
                                        <input type="text" className="form-control" id="processed-form-refund-ref" required="required" placeholder="Refund Reference" />
                                    </div>
                                    <div className="form-group">
                                        <label for="processed-form-comment" className="col-form-label"><b>Comment:</b></label>
                                        <textarea className="form-control" id="processed-form-comment" required="required" placeholder="Enter the comment"></textarea>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary processed-refund-close-btn" data-dismiss="modal">Close</button>
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <Modal
                open={this.state.isOpenBankDetailsModal}
                onClose={e => {this.setState({isOpenBankDetailsModal: false})}}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{...style, width: 800, textAlign: 'center' }}>
                <form onSubmit={(e) => this.updateBankDetails(e)} style={{textAlign: "left"}}>
                    <div className="row">
                        <div className="col supplier-confirm-phone-heading">
                            Update Bank Details
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="bankName" className="col-4 col-form-label"><b>Bank Name</b><b style={{marginLeft:"5px", fontWeight: "bold", color: "red"}}>*</b></label>
                        <div className="col-8"> 
                            {/* <input type="text" className="form-control" id="bankName" placeholder="Enter bank name" required="required" onChange={e => {this.setState({bankName: e.target.value})}} value={this.state.bankName} /> */}
                            <select class="form-control" id="bankName" value={this.state.toupdaterefundbank} onChange={e => this.setState({toupdaterefundbank: e.target.value})} required="required">
                                <option value="">Choose Bank Name</option>
                                <option value="ABC Banking Corporation Ltd">ABC Banking Corporation Ltd</option>
                                <option value="Absa Bank (Mauritius) Limited">Absa Bank (Mauritius) Limited</option>
                                <option value="AfrAsia Bank Limited">AfrAsia Bank Limited</option>
                                <option value="BCP Bank (Mauritius) Ltd">BCP Bank (Mauritius) Ltd</option>
                                <option value="Bank One Limited">Bank One Limited</option>
                                <option value="Bank Of Baroda">Bank Of Baroda</option>
                                <option value="Bank Of China">Bank Of China</option>
                                <option value="HSBC">HSBC</option>
                                <option value="Habib Bank Limited">Habib Bank Limited</option>
                                <option value="Investec Bank (Mauritius) Limited">Investec Bank (Mauritius) Limited</option>
                                <option value="Mauritius Commercial Bank (MCB)">Mauritius Commercial Bank (MCB)</option>
                                <option value="MauBank Ltd">MauBank Ltd</option>
                                <option value="SBI (Mauritius) Ltd">SBI (Mauritius) Ltd</option>
                                <option value="SBM Bank (Mauritius) Ltd">SBM Bank (Mauritius) Ltd</option>
                                <option value="Silver Bank Limited">Silver Bank Limited</option>
                                <option value="Standard Bank (Mauritius) Ltd">Standard Bank (Mauritius) Ltd</option>
                                <option value="Standard Chartered Bank (Mauritius) Ltd">Standard Chartered Bank (Mauritius) Ltd</option>
                            </select>
                        </div>
                    </div>

                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="handlingAgentName" className="col-4 col-form-label"><b>Name of Account Owner</b><b style={{marginLeft:"5px", fontWeight: "bold", color: "red"}}>*</b></label>
                        <div className="col-8"> 
                            <input type="text" className="form-control" id="accountowner" placeholder="Enter account owner name" required="required" onChange={e => {this.setState({toupdaterefundaccountname: e.target.value})}} value={this.state.toupdaterefundaccountname} />
                        </div>
                    </div>
                    
                    <div className="form-group row" style={{marginBottom: "20px"}}>
                        <label for="accountNumber" className="col-4 col-form-label"><b>Account Number</b><b style={{marginLeft:"5px", fontWeight: "bold", color: "red"}}>*</b></label>
                        <div className="col-8"> 
                            <input type="text" className="form-control" id="accountNumber" placeholder="Enter account number" required="required" onChange={e => {this.setState({toupdaterefundaccountnum: e.target.value})}} value={this.state.toupdaterefundaccountnum} />
                        </div>
                    </div>

                    <div style={{display: "flex", justifyContent: "space-between", marginTop: "40px"}}>
                        <button type="submit" className="btn btn-sm btn-success" style={{marginRight: "20px"}}>Update</button><button type="button" className="btn btn-sm btn-danger" onClick={e => {this.setState({isOpenBankDetailsModal: false})}}>Close</button>
                    </div>
                </form>
                </Box>
            </Modal>

            {/* comments modal */}
            <Modal
                open={this.state.isOpenCommentsModal}
                onClose={e => {this.setState({isOpenCommentsModal: false})}}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{...style, width: 800, textAlign: 'center' }}>
                <form onSubmit={(e) => this.addComments(e)} style={{textAlign: "left"}}>
                    <div className="row">
                        <div className="col supplier-confirm-phone-heading">
                            Status Comments
                        </div>
                    </div>
                    
                    <div className="form-group" style={{marginBottom: "20px"}}>
                        <label for="accountNumber" className="col-12" style={{paddingLeft: "0px", paddingRight: "0px"}}>
                            <b>Enter Your Comment</b><b style={{marginLeft:"5px", fontWeight: "bold", color: "red"}}>*</b>
                        </label>
                        <div className="col-12" style={{paddingLeft: "0px", paddingRight: "0px"}}> 
                            <textarea type="text" className="form-control" id="accountNumber" placeholder="Enter comment" required="required" onChange={e => {this.setState({refundCurrentComment: e.target.value})}} value={this.state.refundCurrentComment} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                <button type="submit" className="btn btn-sm btn-success" style={{marginRight: "20px"}} disabled={this.state.addCommentBtnDisabled}>{this.state.addCommentBtnText}</button><button type="button" className="btn btn-sm btn-danger" onClick={e => {this.setState({isOpenCommentsModal: false})}}>Close</button>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <hr />
                            <h4>Previous Comments</h4>
                            <hr />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <div className="comments-list">
                                {/* {this.state.refundComments.length==0 && <div className="comment-item">No comments found</div>} */}
                                {this.state.refundComments.length>0 && this.state.refundComments.map((item, index) => (
                                    <div className="comment-item" key={index}>
                                        <div className="comment-date">By {item['added_by']} on {item['added_on']}</div>
                                        <div className="comment-text">{item["comment"]}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </form>
                </Box>
            </Modal>
            {/* end comments modal */}
            </>
        );
    }
}