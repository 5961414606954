import React, { Component } from "react";
import _ from "lodash";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk, faImages, faPlus, faStar, faTimes, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons'
import axios from "axios";
import swal from "sweetalert";
import { ToastContainer, toast } from 'react-toastify';

import AuthService from '../services/auth/auth.service';
import UtilityService from "../services/utility.service";
import NoPermission from "./extras/nopermission.component";
import CmsService from "../services/erp/cms.service";
import { th } from "date-fns/locale";
import EditSlide from "./cms/editslide.component";

export default class SlidersCovers extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loadingvalues: true,
            show: true,
            error: {
                status: false,
                message: ''
            },
            allowed: true, 
            selected_album: '',
            imagestodisplay:[],
            allalbums:[],
            showform: false,
            imageToUpload: [],
            uploadimagealt: '',
            uploadimagelink: '',
            uploadimagealbum: '',
            uploadimageurl: ' ',
            uploadimagesort: '',
            user: null
        }
    }

    componentDidMount() {
        AuthService.checklogin()
            .then(user => {
                if (user) {
                    this.setState({
                        user: user
                    });
                    AuthService.getUserPermisions(user)
                    .then(response => {
                        var userpermissions = response.data;
                        
                        if(userpermissions.includes('cms') || userpermissions.includes('all')) {
                            this.setState({
                                allowed: true
                            });
                            this.getALL();
                        } else {
                            this.setState({
                                allowed: false
                            });
                        }
                    })
                }
            })
            .catch(error => {
                window.location.href = '/login';
            });
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleFilesChange = (e) => {
        this.setState({imageToUpload:e.target.files})
    }

    getALL = () => {
        CmsService.getALL()
        .then(response => {
            console.log({response});
            this.setState({
                data: response.data,
                loadingvalues: false
            });

            if(typeof response.data==='object' && !_.isEmpty(response.data)) {
                this.setState({
                    selected_album: Object.keys(response.data)[0],
                    uploadimagealbum: Object.keys(response.data)[0],
                });
                this.displayImages(Object.keys(response.data)[0]);
                this.setAllAlbums();
            }

        })
        .catch(error => {
            console.log({error});
        });
    }

    displayImages(album) {
        if(!_.isEmpty(this.state.data)) {
            for (var key of Object.keys(this.state.data)) {
                if(key==album) {
                    var values = this.state.data[key];
                    this.setState({
                        imagestodisplay: values
                    });
                }
                
            }
        }
    }

    setAllAlbums() {
        if(!_.isEmpty(this.state.data)) {
            var list = [];
            for (var key of Object.keys(this.state.data)) {
                list.push(key);
            }

            this.setState({
                allalbums: list
            });
        }
    }

    changeAlbum(name) {
        this.setState({
            selected_album: name,
            uploadimagealbum: name
        });

        this.displayImages(name);
    }

    uploadFile = async (file) => {

        const formData = new FormData();

        for (let i = 0; i < this.state.imageToUpload.length; i++) {
            formData.append("file[]", this.state.imageToUpload[i]);
        }

        formData.append('avatar',file)

        return  await axios.post('https://deals.mu/scripts/reportsapi/cmsupload.php', formData,{
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
      }

    formUploadImage(album) {
        this.setState({
            showform: !this.state.showform
        });

        console.log("Upload Image Form");
    }

    async uploadImage(e) {
        e.preventDefault();

        var resFiles = await this.uploadFile(this.state.imageToUpload);
        var imageUploaded = resFiles.data;

        if(imageUploaded=='' || this.state.uploadimagealt=='' || this.state.uploadimagealbum=='' || this.state.uploadimageurl=='' || this.state.uploadimagelink=='' || this.state.uploadimagesort=='') {
            swal('Missing Data!', 'Please! Fill all mandatory inputs!', 'error');
        } else {
            CmsService.uploadImage(imageUploaded, this.state.uploadimagealt, this.state.uploadimagealbum, this.state.uploadimageurl, this.state.uploadimagelink, this.state.uploadimagesort, this.state.user.user_nicename)
            .then(response => {
                console.log({response});
                swal("Uploaded!", "The Image has been Uploaded", 'success');

                this.setState({
                    imageToUpload: [],
                    uploadimagealt : '',
                    uploadimagealbum : '',
                    uploadimageurl : ' ',
                    uploadimagelink : '',
                    uploadimagesort : '',
                });

                CmsService.getALL()
                .then(response => {
                    console.log({response});
                    this.setState({
                        data: response.data,
                        loadingvalues: false
                    });

                    if(typeof response.data==='object' && !_.isEmpty(response.data)) {
                        this.setState({
                            selected_album: this.state.uploadimagealbum,
                            showform: false
                        });
                        this.displayImages(this.state.uploadimagealbum);
                        this.setAllAlbums();
                    }

                })
                .catch(error => {
                    console.log({error});
                });

            })
            .catch(error => {
                swal('Snag!', 'An error occured please try again!', 'error');
            });
        }
        console.log({imageUploaded});
        console.log("upload image");
    }

    delImage(id, album) {
        swal({
        title: "Are you sure?",
        text: "The image will be Deleted!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        buttons: ["No!", "Yes, Delete It!"],
        })
        .then((willDelete) => {
        if (willDelete) {
            CmsService.delImage(id, album)
            .then((response) => {

                swal('Deleted', 'The image has been removed', 'success');
                CmsService.getALL()
                    .then(response => {
                        console.log({response});
                        this.setState({
                            data: response.data,
                            loadingvalues: false
                        });

                        if(typeof response.data==='object' && !_.isEmpty(response.data)) {

                            if(typeof response.data[this.state.selected_album]!=='undefined') {
                                this.displayImages(this.state.selected_album);
                            } else {
                                this.setState({
                                    selected_album: Object.keys(response.data)[0],
                                    uploadimagealbum: Object.keys(response.data)[0],
                                });
                                this.displayImages(Object.keys(response.data)[0]);
                            }
                            this.setAllAlbums();
                        }
                    })
                    .catch(error => {
                        console.log({error});
                    });
            })
            .catch(error=> {
                console.log({error});
            });
        } else {
            // swal("Your imaginary file is safe!");
        }
        });
        
    }

    handleSortChange(value, id, index, album) {

        
        var imagestodisplay = this.state.imagestodisplay;
        imagestodisplay[index].sorting=value;
        this.setState({
            imagestodisplay: imagestodisplay
        })
        CmsService.updateSort(id, value, album)
        .then((response) => {
            toast.clearWaitingQueue();
            toast.success('Sort order updated!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        })

    }

    slideChanged = async (album) => {
        await this.getALL();
        await this.changeAlbum(album);
    }

    render(){
        var {loadingvalues, show, error} = this.state;

        if (this.state.allowed == false) {
          return <NoPermission />;
        }
        
        return(
            <>
            <ToastContainer limit={1} />
            <div className="row">
                <div className="col-12">
                    <h3>Sliders And Covers</h3>
                    {/* <p>Here you will find the Availablity calendars for all your products featured on Deals.mu.</p>
                    <p>You will have a detailed overview on all open / closed dates and room types.</p> */}
                    <hr />
                </div>
            </div>
            <div className="" style={{marginLeft:"25px", marginRight:"25px"}}>
            <div className="row">
                <div className='col'>
                    {loadingvalues == true &&
                        <p style={{ color: "#df153b", textAlign: "center", marginTop:"20px" }}><b><i>Loading Images. Please Wait....</i></b></p>
                    }
                    {show == true && 
                    <>
                        <div className="row">
                            <div className="col-3" style={{display:"flex", flexDirection:"column", backgroundColor:"#027bdf", alignItems:"center"}}>
                                <div className="album-header-main"><FontAwesomeIcon icon={faImages} /> Album Names</div>
                                {this.state.allalbums.length>0 && this.state.allalbums.map((name, index) => (
                                    <div key={index} onClick={e=>this.changeAlbum(name)} className="album-header">{name}</div>
                                ))}
                                
                            </div>
                            <div className="col-9">
                                
                                <div className="album-content">
                                    <div className="row" style={{marginBottom: "20px"}}>
                                        <div className="col-8" style={{fontSize: "18px", color: "#ea2746"}}>
                                            <strong><FontAwesomeIcon icon={faImages} /> Album:</strong> {this.state.selected_album}
                                        </div>
                                        <div className="col-4" style={{textAlign: "right"}}>
                                            <button onClick={()=>this.formUploadImage()} className="btn btn-success" disabled={this.state.selected_album==""}><FontAwesomeIcon icon={faPlus} /> Add Image</button>
                                        </div>
                                    </div>
                                    {this.state.showform==true && 
                                    <div className="row" style={{marginBottom: "20px"}}>
                                        <div className="col-12">
                                            <hr />
                                            <h4>Upload Image</h4>
                                            <form onSubmit={(e)=>this.uploadImage(e)} style={{padding:"20px", backgroundColor: "#def0ff"}}>
                                                <div className="form-group">
                                                    <label for="uploadimage">Image <FontAwesomeIcon icon={faAsterisk} color="red" /></label>
                                                    <input type="file" className="form-control form-control-file" id="uploadimage" aria-describedby="uploadimageHelp"  onChange={this.handleFilesChange} />
                                                    <small id="uploadimageHelp" className="form-text text-muted">Please upload pre optimised image. optimise from https://tinyjpg.com or https://tinypng.com</small>
                                                </div>
                                                <div className="form-group">
                                                    <label for="uploadimagealt">Alternate Text <FontAwesomeIcon icon={faAsterisk} color="red" /></label>
                                                    <input type="text" className="form-control" value={this.state.uploadimagealt} id="uploadimagealt" name="uploadimagealt" onChange={this.handleChange.bind(this)} required />
                                                </div>
                                                <div className="form-group">
                                                    <label for="uploadimagelink">Link Behind the Image <FontAwesomeIcon icon={faAsterisk} color="red" /></label>
                                                    <input type="text" className="form-control" value={this.state.uploadimagelink} id="uploadimagelink" name="uploadimagelink" onChange={this.handleChange.bind(this)} required />
                                                </div>
                                                <div className="form-group">
                                                    <label for="uploadimagealbum">Album <FontAwesomeIcon icon={faAsterisk} color="red" /> (to group Images)</label>
                                                    <input type="text" className="form-control" value={this.state.uploadimagealbum} id="uploadimagealbum" name="uploadimagealbum" onChange={this.handleChange.bind(this)} required />
                                                </div>
                                                <div className="form-group">
                                                    <label for="uploadimageurl">URL (where the image will be shown)</label>
                                                    <input type="text" className="form-control" value={this.state.uploadimageurl} id="uploadimageurl" name="uploadimageurl" onChange={this.handleChange.bind(this)} />
                                                </div>
                                                <div className="form-group">
                                                    <label for="uploadimagesort">Sort Number <FontAwesomeIcon icon={faAsterisk} color="red" /></label>
                                                    <input type="number" className="form-control" value={this.state.uploadimagesort} id="uploadimagesort" name="uploadimagesort" onChange={this.handleChange.bind(this)} required />
                                                </div>

                                                <div className="form-group" style={{display:"flex", justifyContent:"center"}}>
                                                    <button type="submit" className="btn btn-success"><FontAwesomeIcon icon={faUpload} /> Upload</button>
                                                    <button type="button" style={{marginLeft:"20px"}} className="btn btn-danger" onClick={()=>this.formUploadImage()}><FontAwesomeIcon icon={faTimes} /> Cancel</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    }
                                    <table className="table table-striped table-inverse table-responsive" style={{width:"100%", display:"inline-table"}}>
                                        <thead className="thead-inverse">
                                            <tr>
                                                <th>Image</th>
                                                <th>Alt</th>
                                                <th>Placement Page</th>
                                                <th>Link</th>
                                                <th>Sort</th>
                                                <th>Added By</th>
                                                <th>Actions</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.imagestodisplay.length>0 && this.state.imagestodisplay.map((image, index) => (
                                                <tr key={index}>
                                                    <th scope="row"><img src={image.image} alt={image.image_alt} style={{width: "200px"}} /></th>
                                                    <td>{image.image_alt}</td>
                                                    <td>{image.url}</td>
                                                    <td>{image.link}</td>
                                                    <td>
                                                        <input type="number" onChange={(e) => this.handleSortChange(e.target.value ,image.id, index, image.album)} value={image.sorting} style={{width: "55px"}}></input>
                                                    </td>
                                                    <th>{image.added_by} <br /> {image.added_on}</th>
                                                    <td>
                                                        <button onClick={()=>this.delImage(image.id, image.album)} className="btn btn-danger btn-sm"><FontAwesomeIcon icon={faTrash} /> Delete</button>
                                                        <br /><br />
                                                        <EditSlide image={image} username={this.state.user.user_nicename} onChange={()=>this.slideChanged(image.album)} />
                                                    </td>
                                                </tr>
                                                ))}
                                            </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                    }
                    {error.status == true &&
                        <div className="alert alert-danger" role="alert">
                            {error.message}
                        </div>
                    }
                </div>
            </div>
            </div>
            </>
        );
    }
}