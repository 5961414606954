import React, { useEffect, useState } from 'react'
import { Modal, Box } from '@mui/material';
import axios from 'axios';
import swal from 'sweetalert';

const APP_URL = "https://deals.mu/scripts/reportsapi/ordersreport.php";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const AddFee = ({orderID, changedata}) => {

    const [fee, setFee] = useState(0);
    const [open, setOpen] = useState(false);
    const [disableapplybtn, setDisableapplybtn] = useState(true);
    const [applybtntext, setApplybtntext] = useState("Add Fee");
    const [allFees, setAllFees] = useState([]);

    useEffect(() => {
        if(fee > 0) {
            setDisableapplybtn(false);
        } else {
            setDisableapplybtn(true);
        }
    }, [fee]);

    const getFees = () => {
        var params = {
            orderID: orderID,
            action: "get_fees"
        };
        axios.post(APP_URL, {params})
        .then(response => {
            // console.log("get fees response", response);
            setAllFees(response.data);
        })
        .catch(error => {
            console.log("get fees error", error);
        });
    }

    useEffect(() => {
        getFees();
    }, [orderID]);

    const addFees = (e) => {
        e.preventDefault();
        setApplybtntext("Adding Fee...");
        setDisableapplybtn(true);
        var params = {
            orderID: orderID,
            fee: fee,
            action: "add_fee"
        };
        axios.post(APP_URL, {params})
        .then(response => {
            // console.log("add fee response", response);
            setApplybtntext("Fee Added");
            setTimeout(() => {
                setApplybtntext("Add Fee");
                setDisableapplybtn(false);
            }, 2000);
            setOpen(false);
            getFees();
            changedata();
        })
        .catch(error => {
            console.log("add fee error", error);
            setApplybtntext("Error Adding Fee");
            setTimeout(() => {
                setApplybtntext("Add Fee");
                setDisableapplybtn(false);
            }, 2000);
        });
    }

    const deleteFee = (feeID) => {
        var params = {
            feeID: feeID,
            orderID: orderID,
            action: "delete_fee"
        };
        axios.post(APP_URL, {params})
        .then(response => {
            // console.log("delete fee response", response);
            changedata();
            getFees();
            swal("Success", "Fee deleted successfully", "success");
        })
        .catch(error => {
            console.log("delete fee error", error);
        });
    }

  return (
    <div>
        <button type="button" onClick={e=>setOpen(true)} className="btn">Add Fee</button>
        <div style={{marginTop: "10px"}}>
            <table className='table table-responsive'>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Fee Name</th>
                        <th>Amount</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {allFees.length>0 && allFees.map((fee, index) => {
                        return (
                        <tr key={index}>
                            <td>{fee.id}</td>
                            <td>{fee.name}</td>
                            <td>Rs {fee.amount}</td>
                            <td><button type="button" className="btn btn-sm btn-danger" style={{color: "white"}} onClick={(e)=>deleteFee(fee.id)}>Delete Fee</button></td>
                        </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
        <Modal
            open={open}
            onClose={e => {setOpen(false); setFee(0);}}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
        >
            <Box sx={{...style, width: 800, textAlign: 'center' }}>
            <h2 id="child-modal-title">Add Fee to this Order</h2>
            <p id="child-modal-description" style={{marginBottom: "0px"}}>Enter the fee below.</p>
            <form onSubmit={(e) => addFees(e)} style={{textAlign: "left"}}>
                    <div className="form-group">
                    <label for="addfee" className="col-form-label"><b>Fee Amount</b></label>
                    <div className="">
                        <input type="text" className="form-control" onChange={e => {setFee(e.target.value)}} id="addfee" placeholder="Enter fee amount" value={fee} />
                    </div>
                </div>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <button type="submit" className="btn btn-sm btn-success" style={{marginRight: "20px"}} disabled={disableapplybtn}>{applybtntext}</button><button type="button" className="btn btn-sm btn-danger" onClick={e => {setOpen(false); setFee(0);}}>Close</button>
                </div>
            </form>
            </Box>
        </Modal>
    </div>
  )
}

export default AddFee;