import axios from "axios";
import { uniq } from 'lodash';

const API_URL = "https://deals.mu/scripts/reportsapi/suppliers.php";

class SuppliersService {
    getBasic(company, supplier) {
        var params = {
            action: 'basic_details',
            company: company,
            supplier: supplier
        };

        return axios
            .get(API_URL, { params })
            .then((response) => {

                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

    fetchProducts(company, supplier) {
        var params = {
            action: 'fetch_products',
            company: company,
            supplier: supplier
        };

        return axios
            .get(API_URL, { params })
            .then((response) => {

                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

    fetchComments(company, supplier) {
        var params = {
            action: 'fetch_comments',
            company: company,
            supplier: supplier
        };

        return axios
            .get(API_URL, { params })
            .then((response) => {

                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

    fetchContracts(company, supplier) {
        var params = {
            action: 'fetch_contracts',
            company: company,
            supplier: supplier
        };

        return axios
            .get(API_URL, { params })
            .then((response) => {

                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

    fetchCommissions(company, supplier) {
        var params = {
            action: 'fetch_commissions',
            company: company,
            supplier: supplier
        };

        return axios
            .get(API_URL, { params })
            .then((response) => {

                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }

    fetchInvoices(company, supplier) {
        var params = {
            action: 'fetch_invoices',
            company: company,
            supplier: supplier
        };

        return axios
            .get(API_URL, { params })
            .then((response) => {

                // console.log(response);
                return response;
            }).catch(error => {
                // localStorage.removeItem("user");
                return false;
            });
    }
}

export default new SuppliersService();