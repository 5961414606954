import React, { Component } from "react";
import Select from 'react-select';
import Loader from 'react-loader-spinner';
import DTable from './dtable.component';

import AuthService from '../services/auth/auth.service';
import PaymentReportsService from '../services/erp/paymentreports.service';
import ProductLogsService from '../services/erp/productlogs.service';

import UserContext from "../contexts/UserContext";
import NoPermission from "./extras/nopermission.component";
export default class ProductLogs extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            options: {
                filterType: 'dropdown',
            },
            show: false,
            products: [{ ID: 0, post_title: 'Select a Product' }],
            logs: [{ ID: 0, log: 'Select a Log' }],
            productname: 0,
            selectedlog: 0,
            search_btn_text: 'Load Log',
            loadingvalues: true
        };

        // this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });

        if (event.target.name =='productname') {
           
        }
        console.log('handle change');
    }

    componentDidMount() {

        PaymentReportsService.getAllSuppliers()
            .then(response => {
                console.log({ response });
                if (response.data.products) {
                    this.setState({
                        products: response.data.products,
                        loadingvalues: false,
                    });
                    console.log('i am getsupds');
                }
            });
        
        AuthService.checklogin().then(user => {
            // console.log({ user });
            AuthService.getUserPermisions(user)
            .then(response => {
                var userpermissions = response.data;
                console.log({userpermissions});
                    if(userpermissions.includes('productlogs') || userpermissions.includes('all')) {
                    console.log('You are allowed!');
                    this.setState({
                        allowed: true
                    });
                } else {
                    console.log('You are not allowed!');
                    this.setState({
                        allowed: false
                    });
                }
            })
        }).catch(error => {
            // console.log({ error });
            window.location.href = '/login';
        });
    }

    searchClick(e) {
        e.preventDefault();
        console.log('The link was clicked.');
        this.setState({
            show: false,
            search_btn_text: 'Loading..'
        });

        ProductLogsService.get_log_details(this.state.selectedlog)
        .then(response => {
            console.log({response});
            this.setState({
                show: true,
                search_btn_text: 'Load Log'
            });
        })
        .catch(error => {
            return false;
        });
    }

    handleChangeProduct = (selectedoption) => {
        this.setState({
            productname: selectedoption,
            search_btn_text: 'Loading Logs for product..'
        });

        ProductLogsService.get_all_logs(selectedoption.value)
            .then(response => {
                console.log({ response });
                this.setState({
                    logs: response.data,
                    search_btn_text: 'Load Log'
                });
            })
            .catch(error => {
                return false;
            });
    }

    render() {
        var { data, columnsheadings, options, show, products, suppliers, companies, logs, search_btn_text, loadingvalues } = this.state;

        if (this.state.allowed == false) {
          return <NoPermission />;
        }
        
        return (
            <>
                <div className="row">
                    <div className='col'>
                        <h1>Deals Products Change Logs</h1>
                        <p><strong><i>Please select the Product below to retrieve Logs</i></strong> <i> Select the log date and click on load to load that log and see the details.</i></p>
                        <hr />
                    </div>
                </div>
                <div className="row">
                    <div className='col'>
                        {loadingvalues == true &&
                            <p style={{ color: "#df153b" }}><b><i>Loading Products. Please Wait....</i></b></p>
                        }
                        <form action="" method="get" className="booking-reports-search-form" style={{ display: 'flex' }}>
                            
                            <div style={{ marginLeft: '10px', width: '300px' }}>
                                {/* <select name="productname" className="form-select select-drops" value={this.state.productname} onChange={this.handleChange.bind(this)}>
                                    <option value='0'>Select a Product</option>
                                    {
                                        products.map((product, index) => (
                                            <option key={index} value={product.ID}>{product.post_title}</option>
                                        ))

                                    }
                                </select> */}
                                <Select
                                    value={this.state.productname}
                                    onChange={this.handleChangeProduct}
                                    options={products}
                                />
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                <select name="selectedlog" className="form-select select-drops" value={this.state.selectedlog} onChange={this.handleChange.bind(this)}> 
                                    <option value='0'>Select a Log</option>
                                    {
                                        logs.map((log, index) => (
                                            <option key={index} value={log.id}>{log.value}</option>
                                        ))

                                    }
                                </select>
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                <button className="btn btn-success btn-large booking-search-btn" onClick={this.searchClick.bind(this)}>{search_btn_text}</button>
                            </div>

                        </form>

                        <hr />
                    </div>
                </div>
                <div className="row">
                    <div className='col'>
                        <div className="container-fluid">
                            {(show == true) &&
                                <DTable data={data} columns={columnsheadings} />
                            }
                            {show == false &&
                                <Loader className='loader-center'
                                    type="Grid"
                                    color="#00BFFF"
                                    height={100}
                                    width={100}
                                />
                            }
                        </div>
                    </div>
                </div>
            </>
        );
    }
}