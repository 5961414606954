import React, { Component } from "react";
import DatePicker from "react-datepicker";
import Loader from 'react-loader-spinner';
import DTable from './dtable.component';
import Select from 'react-select';
import swal from 'sweetalert';

import AuthService from '../services/auth/auth.service';
import UtilityService from "../services/utility.service";
import ReceivablesService from "../services/erp/receivables.service";

import UserContext from "../contexts/UserContext";
import NoPermission from "./extras/nopermission.component";
export default class Receivables extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            columnsheadings: [],
            options: {
                filterType: 'dropdown',
            },
            show: false,
            error: {
                status: false,
                message: ''
            },
            loadingvalues: true,
            user: [],
            search_btn_text: 'Search',
            minDate: new Date("02-01-2019"),
            startdate: '',
            enddate: '',
            invoiceType: 0,
            invoiceTypeForm: 'Proforma Invoice',
            companyName : '',
            companyBrn : '',
            companyVat : '',
            customerName : '',
            customerEmail : '',
            customerPhone : '',
            customerAddress : '',
            invoiceDate : new Date(),
            dueDate : '',
            paymentDate : '',
            paymentReference : '',
            paymentMethod : '',
            comment : '',
            productDetails : [{
                productDetail: "",
                attributes: [{
                    label: '',
                    value: ''
                }],
                totalAmount: ""
            }],
            saveButton: true,
            allowed: true,
            edittextValue: "0",
            editID: "0"
        };
    }

    componentDidMount() {
        AuthService.checklogin().then(user => {

            this.setState({
                user: user
            });

            AuthService.getUserPermisions(user)
            .then(response => {
                var userpermissions = response.data;
                // console.log({userpermissions});
                    if(userpermissions.includes('receivables') || userpermissions.includes('all')) {
                    console.log('You are allowed!');
                    this.setState({
                        allowed: true
                    });
                    this.loadResults(true);
                } else {
                    console.log('You are not allowed!');
                    this.setState({
                        allowed: false
                    });
                }
            })
            
        }).catch(error => {
            window.location.href = '/login';
        });
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    setStartDate = (date) => {
        this.setState({
            startdate: date,
            minDate: date,
        });

        if (this.state.enddate != '' && this.state.enddate < date) {
            this.setState({
                enddate:date
            });
        }
        // console.log(this.state);
    }

    setEndDate = (date) => {

        this.setState({
            enddate: date
        });
        // console.log(this.state);
    }

    loadResults = (e) => {
        if(e!==true) {

            e.preventDefault();
        }
        this.setState({
            data: [],
            show: false,
            error: {
                status: false,
                message: ''
            },
            loadingvalues: false,
            loader: true,
            search_btn_text: 'Fetching...',
        });

        setTimeout(()=>{
    
            var startday = '';
            if (this.state.startdate != '' && this.state.startdate != null) {
                startday = UtilityService.format_date(this.state.startdate);
            }
    
            var endday = '';
            if (this.state.enddate != '' && this.state.enddate != null) {
                endday = UtilityService.format_date(this.state.enddate);
            }
    
            ReceivablesService.getAll(this.state.invoiceType, startday, endday)
            .then(response => {
                // console.log({response});
                if(response.data) {
                    // console.log({response});
                    this.setState({
                        data: response.data,
                        columnsheadings: [
                            {title: 'Invoice Type'},
                            { title: "Issue Date" },
                            { title: "Invoice No." },
                            { title: "Customer Name" },
                            { title: "Product Description" },
                            { title: "Total Invoice Amount" },
                            { title: "Due Date" },
                            { title: "Payment Date" },
                            { title: "Payment Reference" },
                            { title: "Comment" },
                            { title: "Actions" },
                        ],
                        show: true,
                        error: {
                            status: false,
                            message: ''
                        },
                        loader: false,
                        search_btn_text: 'Search',
                    });
                } else {
                    this.setState({
                        data: [],
                        columnsheadings: [],
                        show: false,
                        error: {
                            status: true,
                            message: 'No data found'
                        },
                        loader: false,
                        search_btn_text: 'Search',
                    });
                }
            })
            .catch(error => {
                this.setState({
                    data: [],
                    columnsheadings: [],
                    show: false,
                    error: {
                        status: true,
                        message: 'No data found'
                    },
                    loader: false,
                    search_btn_text: 'Search',
                });
                console.log({error});
            });
        }, 1000);
    }

    changeProductDetail = (value, index) => {
        var prevVals = this.state.productDetails;
        prevVals[index].productDetail = value;
        this.setState({
            productDetails: prevVals
        });
    }
    
    changeProductDetailAmount = (evt, value, index) => {
        // console.log('yes');
        if (evt.target.validity.valid || value == "") {
            var prevVals = this.state.productDetails;
            prevVals[index].totalAmount = value;
            this.setState({
                productDetails: prevVals
            });
        }
    }

    addMoreProdectDetails = () => {
        var prevVals = this.state.productDetails;
        prevVals.push({
            productDetail: "",
            attributes: [{
                label: '',
                value: ''
            }],
            totalAmount: ""
        });
        this.setState({
            productDetails: prevVals
        });
    }

    removeProductDetail = (index) => {
        var prevVals = this.state.productDetails;
        if(prevVals.length>1 && index!=0) {
            prevVals.splice(index, 1); 
            this.setState({
                productDetails: prevVals
            });
        }
    }

    handleTypeChange = (val) => {
        this.setState({
            invoiceTypeForm: val
        });
    }

    saveInvoice = (e) => {
        e.preventDefault();

        this.setState({
            saveButton: false
        }); 

        // console.log("State: ", this.state);
        // return; 

        ReceivablesService.saveInvoice(this.state.invoiceTypeForm, this.state.companyName, this.state.companyBrn, this.state.companyVat, this.state.customerName, this.state.customerEmail, this.state.customerPhone, this.state.customerAddress, this.state.invoiceDate, this.state.dueDate, this.state.paymentDate, this.state.productDetails, this.state.paymentReference, this.state.paymentMethod, this.state.comment, this.state.editID)
        .then(response =>    {

            // console.log({response});
            swal("Invoice Saved!", "The invoice has been saved", "success");
            this.setState({
                invoiceTypeForm: 'Proforma Invoice',
                companyName : '',
                companyBrn : '',
                companyVat : '',
                customerName : '',
                customerEmail : '',
                customerPhone : '',
                customerAddress : '',
                invoiceDate : new Date(),
                dueDate : '',
                paymentDate : '',
                paymentReference : '',
                paymentMethod : '',
                comment : '',
                productDetails : [{
                    productDetail: "",
                    attributes: [{
                        label: '',
                        value: ''
                    }],
                    totalAmount: ""
                }],
                saveButton: true,
                editID: "0",
                edittextValue: "0",
            });
            document.querySelector('.close').click();
            document.querySelector('.booking-search-btn').click();
            // this.loadResults();
        })
        .catch(error => {
            console.log({error});
        })
    }

     changeProductAttrLabel = (val, parentIndex, index) => {
        var prevVals = this.state.productDetails;
        prevVals[parentIndex].attributes[index].label = val;
        this.setState({
            productDetails: prevVals
        });
    }

    changeProductAttrVal = (val, parentIndex, index) => {
        var prevVals = this.state.productDetails;
        prevVals[parentIndex].attributes[index].value = val;
        this.setState({
            productDetails: prevVals
        });
    }

    addMoreProdectDetailsAttrs = (parentIndex) => {
        var prevVals = this.state.productDetails;
        prevVals[parentIndex].attributes.push({
            label: '',
            value: ''
        });
        this.setState({
            productDetails: prevVals
        });
    }

    removeProductDetailAttrs = (parentIndex, index) => {
        var prevVals = this.state.productDetails;
        if(prevVals[parentIndex].attributes.length>1 && index!=0) {
            prevVals[parentIndex].attributes.splice(index, 1); 
            this.setState({
                productDetails: prevVals
            });
        }
    }

    handleChangeEditValue = (e) => {
        var value = JSON.parse(e.target.value);
        // console.log({value});

        var productDetails = [];

        var product = value.invoice_products;
        var tempAttr = [];
        for(var j=0; j<product.description.length; j++) {
            var attr = product.description[j];
            tempAttr.push({
                label: attr.label,
                value: attr.value
            });
        }
        var temp = {
            productDetail: product.product_name,
            attributes: tempAttr,
            totalAmount: product.amount
        };

        productDetails.push(temp);

        // console.log({productDetails});

        this.setState({
            editID: value.invoice.id,
            invoiceTypeForm: value.invoice.type,
            companyName : value.invoice.company_name,
            companyBrn : value.invoice.brn,
            companyVat : value.invoice.vat,
            customerName : value.invoice.customer_name,
            customerEmail : value.invoice.customer_email,
            customerPhone : value.invoice.customer_phone,
            customerAddress : value.invoice.customer_address,
            invoiceDate : new Date(value.invoice.issue_date),
            dueDate : new Date(value.invoice.due_date),
            paymentDate : new Date(value.invoice.payment_date),
            paymentReference : value.invoice.payment_reference,
            paymentMethod : value.invoice.payment_method,
            comment : value.invoice.comment,
            productDetails : productDetails,
        })
    }

    resetAll = (e) => {
        e.preventDefault();
        this.setState({
            invoiceTypeForm: 'Proforma Invoice',
            companyName : '',
            companyBrn : '',
            companyVat : '',
            customerName : '',
            customerEmail : '',
            customerPhone : '',
            customerAddress : '',
            invoiceDate : new Date(),
            dueDate : '',
            paymentDate : '',
            paymentReference : '',
            paymentMethod : '',
            comment : '',
            productDetails : [{
                productDetail: "",
                attributes: [{
                    label: '',
                    value: ''
                }],
                totalAmount: ""
            }],
            saveButton: true,
            editID: "0",
            edittextValue: "0",
        });
    }

    render() {
        var self = this;

        if (this.state.allowed == false) {
          return <NoPermission />;
        }
        
        return (
        <>
        <div className="row">
            <div className='col'>
                <h2>Receivables Invoices</h2>
                <hr />
            </div>
        </div>
        <div className="row">
            <div className='col'>
               <form action="" method="get" className="invoices-receivables-reports-search-form" style={{ display: 'flex' }}>
                    <input type="hidden" name="page" value="invoices-receivables-report" />
                    <div>
                        <DatePicker
                            dateFormat="dd-MM-yyyy"
                            selected={this.state.startdate}
                            onChange={date => this.setStartDate(date)}
                            placeholderText="From Date"
                            className='form-control'
                        />
                    </div>
                    <div style={{ marginLeft: '10px' }}>
                        
                        <DatePicker
                            dateFormat="dd-MM-yyyy"
                            selected={this.state.enddate}
                            onChange={date => this.setEndDate(date)}
                            placeholderText="To Date"
                            className='form-control'
                            minDate = {this.state.minDate}
                        />
                    </div>
                    <div style={{ marginLeft: '10px' }}>
                        <select name="invoiceType" className="select-drops form-control" value={this.state.invoiceType} onChange={this.handleChange.bind(this)}>
                            <option value="0">All Receivables</option>
                            <option value="Proforma Invoice">Proforma Invoice</option>
                            <option value="VAT invoice">VAT invoice</option>
                        </select>
                    </div>
                    <div style={{ marginLeft: '10px' }}>
                        <button className="btn btn-success btn-large booking-search-btn" onClick={this.loadResults}>{this.state.search_btn_text}</button>
                    </div>
                    <div style={{ marginLeft: '10px' }}>
                        <a href="/receivables" className="btn btn-large btn-warning booking-reset-btn">Reset</a>
                    </div>
                    <div style={{ marginLeft: '10px' }}>
                        <button type="button" className="btn btn-large btn-success create-receivables-btn" data-toggle="modal" data-target=".create-receivables-modal">Create Invoice</button>
                    </div>

                </form>

                <hr /> 
            </div>
        </div>

        {/* Create invoice modal */}
        <div className="modal fade create-receivables-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Create Invoice Receivable</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(e)=> this.resetAll(e)}>
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form className="create-receivables-form" onSubmit={this.saveInvoice.bind(this)}>
                        <input value={this.state.editID} type="hidden" name="editID" onChange={this.handleChange.bind(this)} />
                        <div className="modal-body">
                            <div className="form-group row">
                                <label for="create-receivable-invoice-type" className="col-sm-3">Invoice Type <b style={{color: "red"}}>*</b></label>
                                <div className="col-sm-9">
                                    <select className="form-control form-control-select" name='invoiceTypeForm' id="create-receivable-invoice-type" placeholder="Select Invoice Type" required="required" value={this.state.invoiceTypeForm} onChange={(e) => this.handleTypeChange(e.target.value)}>
                                        <option value="Proforma Invoice">Proforma Invoice</option>
                                        <option value="VAT invoice">VAT invoice</option>
                                    </select>
                                </div>
                            </div>
                             <div className="form-group row">
                                <label for="" className="col-sm-12"><b>Customer Details</b></label>
                                <label for="create-receivable-company-name" className="col-sm-3">Company Name</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" name='companyName' id="create-receivable-company-name" placeholder="Company Name"  value={this.state.companyName} onChange={this.handleChange.bind(this)} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label for="create-receivable-brn" className="col-sm-3">BRN</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" name='companyBrn' id="create-receivable-brn" placeholder="brn"  value={this.state.companyBrn} onChange={this.handleChange.bind(this)} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label for="create-receivable-VAT" className="col-sm-3">VAT</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" name='companyVat' id="create-receivable-VAT" placeholder="VAT" value={this.state.companyVat} onChange={this.handleChange.bind(this)} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label for="create-receivable-full-name" className="col-sm-3">Full Name <b style={{color: "red"}}>*</b></label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" name='customerName' id="create-receivable-full-name" placeholder="Full Name" required="required" value={this.state.customerName} onChange={this.handleChange.bind(this)} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label for="create-receivable-email" className="col-sm-3">Email <b style={{color: "red"}}>*</b></label>
                                <div className="col-sm-9">
                                    <input type="email" className="form-control" name='customerEmail' id="create-receivable-email" placeholder="Email" required="required" value={this.state.customerEmail} onChange={this.handleChange.bind(this)} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label for="create-receivable-phone" className="col-sm-3">Phone <b style={{color: "red"}}>*</b></label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" name='customerPhone' id="create-receivable-phone" placeholder="Phone" required="required" value={this.state.customerPhone} onChange={this.handleChange.bind(this)} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label for="create-receivable-address" className="col-sm-3">Address <b style={{color: "red"}}>*</b></label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" value={this.state.customerAddress} onChange={this.handleChange.bind(this)} name='customerAddress' id="create-receivable-address" placeholder="Address" required="required" />
                                </div>
                            </div>
                            <div>
                            <div className="form-group row">
                                <label for="" className="col-sm-12"><b>Invoice Dates</b></label>
                            </div>
                            <div className="form-group row">
                                <label for="create-receivable-invoice-date" className="col-sm-3">Invoice Date <b style={{color: "red"}}>*</b></label>
                                <div className="col-sm-9">
                                    <DatePicker
                                        dateFormat="dd-MM-yyyy"
                                        selected={this.state.invoiceDate}
                                        onChange={date => this.setState({invoiceDate: date})}
                                        placeholderText="Invoice Date"
                                        className='form-control'
                                        // minDate = {new Date()}
                                    />
                                    {/* <input type="text" className="form-control" name='invoiceDate' id="create-receivable-invoice-date" value={this.state.invoiceDate} onChange={this.handleChange.bind(this)} placeholder="Invoice Date" required="required" /> */}
                                </div>
                            </div>
                            <div className={`form-group row create-receivable-due-date-container ${(this.state.invoiceTypeForm!='Proforma Invoice')? "hideit": ""}`}>
                                <label for="create-receivable-due-date" className="col-sm-3">Due Date <b style={{color: "red"}}>*</b></label>
                                <div className="col-sm-9">
                                    <DatePicker
                                        dateFormat="dd-MM-yyyy"
                                        selected={this.state.dueDate}
                                        onChange={date => this.setState({dueDate: date})}
                                        placeholderText="Due Date"
                                        className='form-control'
                                        // minDate = {new Date()}
                                    />
                                    {/* <input type="text" className="form-control" value={this.state.dueDate} onChange={this.handleChange.bind(this)} name='dueDate' id="create-receivable-due-date" placeholder="Due Date" required="required" /> */}
                                </div>
                            </div>
                            <div className={`form-group row create-receivable-payment-date-container ${(this.state.invoiceTypeForm!='VAT invoice')? "hideit": ""}`}>
                                <label for="create-receivable-payment-date" className="col-sm-3">Date of Payment <b style={{color: "red"}}>*</b></label>
                                <div className="col-sm-9">
                                     <DatePicker
                                        dateFormat="dd-MM-yyyy"
                                        selected={this.state.paymentDate}
                                        onChange={date => this.setState({paymentDate: date})}
                                        placeholderText="Date of Payment"
                                        className='form-control'
                                        // minDate = {new Date()}
                                    />
                                    {/* <input type="text" className="form-control" value={this.state.paymentDate} onChange={this.handleChange.bind(this)} name='paymentDate' id="create-receivable-payment-date" placeholder="Date of Payment" required="required" /> */}
                                </div>
                                
                            </div>
                            <div className={`form-group row ${(this.state.invoiceTypeForm!='VAT invoice')? "hideit": ""}`}>
                                <label for="create-receivable-invoice-type" className="col-sm-3">Payment Method <b style={{color: "red"}}>*</b></label>
                                <div className="col-sm-9">
                                    <select className="form-control form-control-select" name='paymentMethod' id="create-receivable-payment-method" placeholder="Select Payment Method" value={this.state.paymentMethod} onChange={(e) => this.handleChange(e)}>
                                        <option value="Bank Transfer">Bank Transfer</option>
                                        <option value="Cash">Cash</option>
                                        <option value="MCB Juice">MCB Juice</option>
                                        <option value="Credit Card">Credit Card</option>
                                        <option value="Others">Others</option>
                                    </select>
                                </div>
                            </div>
                            <div className={`form-group row ${(this.state.invoiceTypeForm!='VAT invoice')? "hideit": ""}`}>
                                <label for="create-receivable-product-details" className="col-sm-3">Payment Reference</label>
                                <div className="col-sm-9">
                                    <textarea className="form-control" value={this.state.paymentReference} onChange={this.handleChange.bind(this)} name='paymentReference' id="create-receivable-payment-reference" placeholder="Payment Reference" />
                                </div>
                            </div>
                            </div>
                            <div className="form-group row">
                                <label for="create-receivable-product-details" className="col-sm-12"><b>Product Details</b></label>
                            </div>
                            {this.state.productDetails.map((details, index) => (
                                <div key={index} className="product-detail-main-item">
                                    <div className="form-group row">
                                        <label for="create-receivable-product-details" className="col-sm-3">Product Name <b style={{color: "red"}}>*</b></label>
                                        <div className="col-sm-9">
                                            <textarea className="form-control" value={details.productDetail} onChange={(e)=> this.changeProductDetail(e.target.value, index)} name='productDetail' id="create-receivable-product-details" placeholder="Product Name" required="required" />
                                        </div>
                                    </div>
                                    {details.attributes.map((attr, index2) => (
                                    <div className="form-group row">
                                        <label for="create-receivable-product-details" className="col-sm-3">Attribute <b style={{color: "red"}}>*</b></label>
                                        
                                        <div className="col-sm-8" style={{display: "flex"}}>
                                            <input className="form-control" value={attr.label} onChange={(e)=> this.changeProductAttrLabel(e.target.value, index, index2)} name='productDetail' id="create-receivable-product-details-attr-label" placeholder="Attribute Label" required="required" />
                                            <input  style={{marginLeft: '10px'}} className="form-control" value={attr.value} onChange={(e)=> this.changeProductAttrVal(e.target.value, index, index2)} name='productDetail' id="create-receivable-product-details-attr-value" placeholder="Attribute Val" required="required" />
                                        </div>
                                        <div className="col-sm-1"><button  type="button" className="btn btn-sm btn-danger"  onClick={() => this.removeProductDetailAttrs(index, index2)}><i className="fas fa-trash-alt"></i></button></div>
                                    </div>
                                    ))}
                                    <div className="form-group row">
                                        <div className="col-md-12" style={{textAlign: "center"}}>
                                            <button type="button" style={{float: "right"}} className="btn btn-success btn-sm" onClick={() => this.addMoreProdectDetailsAttrs(index)}><i className="fas fa-plus"></i></button>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label for="create-receivable-total-amount" className="col-sm-3">Total Amount <b style={{color: "red"}}>*</b></label>
                                        <div className="col-sm-9">
                                            <input type="text" className="form-control" pattern="[0-9]*" value={details.totalAmount} onChange={(e)=> this.changeProductDetailAmount(e, e.target.value, index)} name='totalAmount' id="create-receivable-total-amount" placeholder="Total Amount" required="required" />

                                            <br /><button type="button" className="btn btn-sm btn-danger" style={{float: "right"}} onClick={() => this.removeProductDetail(index)}>Remove Product</button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div className="form-group row">
                                <div className="col-md-12" style={{textAlign: "center"}}>
                                    <button type="button" className="btn btn-success btn-sm" onClick={this.addMoreProdectDetails.bind(this)}>Add More products</button>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label for="create-receivable-comment" className="col-sm-3">Comment</label>
                                <div className="col-sm-9">
                                    <textarea className="form-control" value={this.state.comment} onChange={this.handleChange.bind(this)} name='comment' id="create-receivable-comment" placeholder="Additional Comment" />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal"  onClick={(e)=> this.resetAll(e)}>Close</button>
                            <button type="submit" className="btn btn-primary" disabled={!this.state.saveButton}>Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <textarea style={{display: "none"}} id="edittextvalue-invoice-receivables" value={this.state.edittextValue} onChange={this.handleChangeEditValue.bind(this)}></textarea>

        <div className="row">
            <div className='col'>
                {this.state.error.status == true &&
                    <div className="alert alert-danger" role="alert">
                        {this.state.error.message}
                    </div>
                }
                {(this.state.show == true) &&
                    <div className="receivables-table"><DTable data={this.state.data} columns={this.state.columnsheadings} sorting={[]} /></div>
                }
                {this.state.loader == true &&
                    <Loader className='loader-center'
                        type="Grid"
                        color="#00BFFF"
                        height={100}
                        width={100}
                    />
                }
            </div>
        </div>

        <div class="modal fade" id="product-details-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Product Description</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                   <p id="product-details-modal-body"></p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="invoice-convert-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Convert to Invoice</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form id="invoice-convert-modal-form">
                        <div class="modal-body">
                            <input type="hidden" id="invoice-convert-id" name="invoice-convert-id" value="" />
                            <div class="form-group">
                                <label for="invoice-convert-payment-method">Payment Method</label>
                                <select class="form-control form-control-select" id="invoice-convert-payment-method" name="invoice-convert-payment-method" required="required">
                                    <option value="Bank Transfer">Bank Transfer</option>
                                    <option value="Cash">Cash</option>
                                    <option value="MCB Juice">MCB Juice</option>
                                    <option value="Credit Card">Credit Card</option>
                                    <option value="Others">Others</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label for="invoice-convert-payment-date">Payment Date</label>
                                <input type="text" class="form-control date-picker" id="invoice-convert-payment-date" name="invoice-convert-payment-date" placeholder="Enter payment date" required="required" />
                            </div>

                            <div class="form-group">
                                <label for="invoice-convert-payment-reference">Payment Reference</label>
                                <textarea class="form-control" id="invoice-convert-payment-reference" name="invoice-convert-payment-reference" placeholder="Enter payment reference" required="required"></textarea>
                            </div>

                            <div class="form-group">
                                <label for="invoice-convert-payment-comment">Comment</label>
                                <textarea class="form-control" id="invoice-convert-payment-comment" name="invoice-convert-payment-comment" placeholder="Comment"></textarea>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="submit" class="btn btn-success">Convert</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        </>
        )
    }
}