import React, { Component } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import OrdersReportService from "../../services/orders/ordersreport.service";

export default class OrderRefundsDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            orderid : props.orderid,
            open: false,
            data: [],
            buttonText : "Refund Details"
        }
    }

    handleOpen = (e) => {
        if(this.state.orderid!='') {
            this.setState({
                buttonText: "loading..."
            })
            OrdersReportService.getOrderRefundDetails(this.state.orderid)
            .then(response => {
                // console.log("Refund details", response);
                this.setState({
                    open: true,
                    data: response.data,
                    buttonText : "Refund Details"
                });
            })
            .catch(error => console.log({error}));
        }
    }

    handleClose = (e) => {
        this.setState({
            open: false
        });
    }

    render() {
        const style = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        };
        return (
            <>
            {/* {this.state.buttonText} */}
            <Button onClick={(e)=> this.handleOpen(e)} style={{color: "black"}}>Refund<br />Details</Button>
            <Modal open={this.state.open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" onClose={()=>this.handleClose}>
                <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2" style={{textDecoration: "underline"}}>
                    Refund(s) Details for #{this.state.orderid}
                </Typography> 
                {this.state.data.length>0 && this.state.data.map((refund, index) => (
                <>
                <Typography key={index} id="modal-modal-description" className="refunds-details-wrapper" sx={{ mt: 2 }} style={{borderBottom: "1px solid black;"}}>
                    <div>
                        <span className="label">ID: </span>
                        <span>{refund.id}</span>
                    </div>
                    <div>
                        <span className="label">Added on: </span>
                        <span>{refund.date}</span>
                    </div>
                    <div>
                        <span className="label">Amount: </span>
                        <span>Rs {refund.amount}</span>
                    </div>
                    <div>
                        <span className="label">Type: </span>
                        <span>{refund.refund_type}</span>
                    </div>
                    <div>
                        <span className="label">Handling Agent: </span>
                        <span>{refund.handling_agent}</span>
                    </div>
                    <div>
                        <span className="label">Refund Reason: </span>
                        <span>{refund.refund_reason_drop}</span>
                    </div>
                    <div style={{flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start"}}>
                        <span className="label">Refund Products: </span>
                        <span>{refund.refund_products}</span>
                    </div>
                    <div style={{flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start"}}>
                        <span className="label">Reason Description: </span>
                        <span>{refund.refund_reason}</span>
                    </div>  
                </Typography>
                <hr /> 
                </>
                ))}
                <div style={{textAlign: "center", marginBottom: "20px", flexDirection: 'column'}}>
                    <button type="button" onClick={e => this.handleClose(e)} className="btn btn-sm btn-danger" style={{width: "100%"}}>Close</button>
                    </div>
                </Box>
            </Modal>
            </>
        )
    }
}